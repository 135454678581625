import { AircraftService } from './aircraft.service';
import { UtilitiesService } from './utilities.service';
import { UserModel } from '../models/user-model';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private utilitiesService: UtilitiesService,
    private aircraftService: AircraftService
    ,
    private router: Router,
  ) { }

  tokenKey: string = environment.tokenKey;
  refreshTokenKey: string = environment.refreshTokenKey;

  signUpUser(userModel: UserModel){
    return this.httpClient.post(environment.apiBaseUrl + '/auth/login', userModel)
  }

  loginUser(emailAddress:string, password: string): Observable<HttpResponse<any>> {
    return this.httpClient.post(environment.apiBaseUrl + '/auth/login', {emailAddress: emailAddress, password: password}, { observe: 'response' })
  }

  loginUserV2(email:string, password: string) {
    return this.httpClient.post(environment.apiBaseUrl + '/v2/auth/login', {email: email, password: password})
  }

  refreshToken(refreshToken: string){
    let requestHeaders = new HttpHeaders().append('refreshToken', refreshToken);
    return this.httpClient.get(environment.apiBaseUrl + '/auth/refreshToken')
  }

  refreshTokenV2(refreshToken: string){
    return this.httpClient.post(environment.apiBaseUrl + '/v2/auth/refresh', {refreshToken: refreshToken})
  }

  forgotPassword(emailAddress:string) {
    return this.httpClient.post(environment.apiBaseUrl + '/auth/forgotPassword', {emailAddress: emailAddress})
  }

  resetPasswordWithCode(resetCode:string, newPassword:string) {
    return this.httpClient.post(environment.apiBaseUrl + '/auth/resetPasswordWithCode', {resetCode: resetCode, newPassword: newPassword})
  }

  logout() {
    this.httpClient.get(environment.apiBaseUrl + '/auth/logout').subscribe(
      (response:any)=>{
        console.log("session logged out.")
      }
    );
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.refreshTokenKey);
    this.userService.setUserAsLoggedOut();
    this.aircraftService.resetUserMenuAircraft();
    return;
  }

  checkAuthUserInfo(loggedIn: boolean = false){
    let currentUser: UserModel = this.userService.getCurrentUserInstance();
    if(!currentUser.emailAddress){
      this.utilitiesService.updateShowMainPreloader(true);
      this.userService.getMe().subscribe(
        (response:any)=>{
          this.utilitiesService.updateShowMainPreloader(false);
          this.userService.updateCurrentUser(response);
        },
        (error: any)=>{
          this.forceLogOutUser()
        }
      )
    }
  }

  forceLogOutUser(){
    this.utilitiesService.updateShowMainPreloader(false);
    this.logout();
    console.log("LOGOUT WITHOUT CIRCULAR DEPENDENCY")
    //todo: loguser out without curcular dependecy between user service and auth service
    this.userService.setUserAsLoggedOut()
    this.utilitiesService.updateShowMainPreloader(false);
    this.router.navigate(['/login']);
  }


  saveToken(token: string){
    localStorage.setItem(this.tokenKey, token);
    return;
  }

  saveRefreshToken(refreshToken:string){
    localStorage.setItem(this.refreshTokenKey, refreshToken);
    return;
  }

  tokenCheck(): boolean {
    let token= localStorage.getItem(this.tokenKey);
    token = !token || token == 'null'? null: token;
    return token !== null &&  token !== 'null' //&& !this.isTokenExpired();
  }

  getToken(): string {
    return localStorage.getItem(this.tokenKey);
  }

  getRefreshToken():string{
    return localStorage.getItem(this.refreshTokenKey);
  }


  isAdminCheck(): boolean {
    let user = this.userService.getCurrentUserInstance();
    return user.isAdmin; //&& !this.isTokenExpired();
  }



  test1() {
    return this.httpClient.post('https://identitytoolkit.googleapis.com/v1/accounts:update?key=['
        + environment.firebase.apiKey + ']', {
          idToken: this.getToken(),
          email: "aaron@crewchiefsystems.com",
          returnSecureToken: true
        }
    );
}

}
