import { Component, OnInit, Input, SimpleChanges} from '@angular/core';
import { UserAircraftModel } from '../../models/user-aircraft-model';
import { UserAircraftCurrentStatusModel } from './../../models/user-aircraft-current-status-model';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-user-aircraft-info-header-widget',
  templateUrl: './user-aircraft-info-header-widget.component.html',
  styleUrls: ['./user-aircraft-info-header-widget.component.scss']
})
export class UserAircraftInfoHeaderWidgetComponent implements OnInit {
  @Input() userAircraft: UserAircraftModel;
  @Input() headerTitle: string;
  @Input() viewStyle: 'inline' | 'list';

  aircraftCurrentStatus: UserAircraftCurrentStatusModel = new UserAircraftCurrentStatusModel;

  constructor(
    private decimalPipe: DecimalPipe
  ) { }

  ngOnInit() {
    this.aircraftCurrentStatus = this.userAircraft.currentStatus? this.userAircraft.currentStatus : new UserAircraftCurrentStatusModel;
    //this.viewStyle = !this.viewStyle? 'inline' : this.viewType;
  }

  ngOnChanges(changes: SimpleChanges){
    //this.viewStyle = changes.viewStyle.currentValue !== 'inline' || changes.viewStyle.currentValue !== 'list'? 'inline' : changes.viewStyle.currentValue;
    if(changes.userAircraft.currentValue){
      this.userAircraft = changes.userAircraft.currentValue;
      this.aircraftCurrentStatus = this.userAircraft.currentStatus? this.userAircraft.currentStatus : new UserAircraftCurrentStatusModel;
    }
  }

  getValueIfNullOrUndefined(val: any){
    return val === null
    ||  val === undefined?
    '--- ---' : this.decimalPipe.transform(val, '1.1-1');
  }
}
