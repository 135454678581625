
<app-preloader-type-a *ngIf="isLoading"></app-preloader-type-a>
<!--NO AIRCRAFT DASHBOARD-->
<div class="m-1" *ngIf="theAircraft.id === null">
  <h5 class="border-bottom mx-2 mb-3">My Aircraft Dashboard</h5>

  <div class="row">
    <div class="col-sm-12 text-center col-md-6 col-lg-4">
      <div class="card border-light mx-sm-1 p-3">
        <div class="text-muted mt-3"><h6><i class="la la-pane"></i></h6></div>
        <div class="mt-2 text-muted"><h4>No Aircraft</h4></div>
        <!--<hr>-->
        <div class="row">
            <div class="col-6">
              <small class="d-block text-muted">&nbsp;</small>
              <div>&nbsp;</div>
            </div>
            <div class="col-6">
              <small class="d-block text-muted">&nbsp;</small>
              <div>&nbsp;</div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="theAircraft.id !== null">
  <app-aircraft-info-header-widget-v2 [headerTitle]="'Aircraft Dashboard'" [theAircraft]="theAircraft">
  </app-aircraft-info-header-widget-v2>


  <!--MAINTENANCE STATUS-->
  <section class="mt-3">
      <app-dashboard-maintenance-status [theAircraft]="theAircraft" [isAdminView]="isAdminView" [aircraftId]="theAircraft.id"></app-dashboard-maintenance-status>
  </section>

  <!--SAFETY STATUS-->
  <section class="mt-3">
    <app-dashboard-safety-status [isAdminView]="isAdminView" [theAircraft]="theAircraft" [aircraftId]="theAircraft.id"></app-dashboard-safety-status>
  </section>

  <!--IFR EQUIPMENT STATUS-->
  <section class="mt-3">
    <app-dashboard-ifr-equipment-status [theAircraft]="theAircraft"></app-dashboard-ifr-equipment-status>
  </section>

  <!--PILOT STATUS-->
  <section class="mt-3">
    <app-pilot-status [theAircraft]="theAircraft" [isAdminView]="isAdminView"></app-pilot-status>
  </section>

  <!--ADMINISTRATIVE STATUS-->
  <section class="mt-3">
    <app-administrative-status [theAircraft]="theAircraft" [isAdminView]="isAdminView"></app-administrative-status>
  </section>

</div>
