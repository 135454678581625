import { UserBaseModel } from './../../../models/user-base-model';
import { UserAircraftModel } from './../../../models/user-aircraft-model';
import { PropellerModel } from './../../../models/propeller-model';
import { EngineModel } from './../../../models/engine-model';
import { AircraftModel } from '../../../models/aircraft-model';
import { ActivatedRoute, Router } from '@angular/router';
import { AircraftService } from './../../../services/aircraft.service';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

//fr ngBootstrap typehead autocomplete
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, tap, switchMap} from 'rxjs/operators';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../services/user.service';
import Swal from 'sweetalert2';
import { NumberToTenths } from '../../../_custom-validators/number-to-tenths-validator';



@Component({
  selector: 'app-add-aircraft',
  templateUrl: './add-aircraft.component.html',
  styleUrls: ['./add-aircraft.component.scss']
})
export class AddAircraftComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private aircraftService: AircraftService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  userData: UserBaseModel = new UserBaseModel;

  createAircraftForm: FormGroup;

  aircraftModelNew: AircraftModel = new AircraftModel;
  aircraftMakeSearchFailed: boolean = false;
  aircraftMakeSearching: boolean = false;
  aircraftModelSearchFailed: boolean = false;
  aircraftModelSearching: boolean = false;

  engineModelNew: EngineModel = new EngineModel;
  engineMakeSearchFailed: boolean = false;
  engineMakeSearching: boolean = false;
  engineModelSearchFailed: boolean = false;
  engineModelSearching: boolean = false;


  propellerModelNew: PropellerModel = new PropellerModel;
  propellerMakeSearchFailed: boolean = false;
  propellerMakeSearching: boolean = false;
  propellerModelSearchFailed: boolean = false;
  propellerModelSearching: boolean = false;

  isWorking: boolean = false;


  ngOnInit() {
    //observe the activated route parameters, and get user id  on change
    this.getUserInfo();

    this.createAircraftForm = this.formBuilder.group({
      'aircraftData': this.formBuilder.group({
        'tailNo': new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(6)]),
        'aircraftMake': new FormControl('', [Validators.required]),
        'aircraftModel': new FormControl('', [Validators.required]),
        'airframeSn': new FormControl('', [Validators.required]),
        'airframeTtis': new FormControl('', [Validators.required, NumberToTenths(false)]),
        'addEngineData': new FormControl(false),
        'addPropellerData': new FormControl(false),
      }),

      'engineData': this.formBuilder.group({
        'engineMake': new FormControl('', [Validators.required]),
        'engineModel': new FormControl('', [Validators.required]),
        'engineSn': new FormControl('', [Validators.required]),
        'initialEngineHours': new FormControl('', [Validators.required, , NumberToTenths(false)]),
        'initialLastEngineOh': new FormControl('', [Validators.required, NumberToTenths(false)]),
        'engineInstalledAirframeTime': new FormControl('', [Validators.required, NumberToTenths(false)])
      }),


      'propellerData': this.formBuilder.group({
        'propellerMake': new FormControl('', [Validators.required]),
        'propellerModel': new FormControl('', [Validators.required]),
        'propellerSn': new FormControl('', [Validators.required]),
        'initLastPropellerOh': new FormControl('', [Validators.required, NumberToTenths(false)]),
        'inititialPropellerHours': new FormControl('', [Validators.required, NumberToTenths(false)]),
        'propellerInstalledAirframeTime': new FormControl('', [Validators.required, NumberToTenths(false)]),
      }),


      'equipmentData': this.formBuilder.group({
        'avionicsEquipments': new FormControl(''),
        'otherEquipments': new FormControl(''),
      }),
    })
  }

  getUserInfo(){
    const userId = +this.route.snapshot.paramMap.get('userId');

    this.userService.getUserById(userId).subscribe(
      (response: any)=>{
        if(response.status.code == 1000){
          this.userData = response.responseData;
        }
      }
    )
  }

  //from getters for validations
  get aircraftMake() { return this.createAircraftForm['controls'].aircraftData['controls'].aircraftMake };
  get tailNo() { return this.createAircraftForm['controls'].aircraftData['controls'].tailNo };
  get airframeTtis() { return this.createAircraftForm['controls'].aircraftData['controls'].airframeTtis };

  get engineMake() { return this.createAircraftForm['controls'].engineData['controls'].engineMake };
  get initialEngineHours() { return this.createAircraftForm['controls'].engineData['controls'].initialEngineHours };
  get propellerMake() { return this.createAircraftForm['controls'].propellerData['controls'].propellerMake};


  //// Type heads search functions for aircraft make, engine make and propeller make
  aircraftMakeTypeHeadSearch = (text$: Observable<string>)=>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.aircraftMakeSearching = true),
      switchMap(term => this.getMakes(term, 'aircraft')
      ),
      tap(() => this.aircraftMakeSearching = false)
  )

  engineMakeTypeHeadSearch = (text$: Observable<string>)=>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.engineMakeSearching = true),
      switchMap(term => this.getMakes(term, 'engine')
      ),
      tap(() => this.engineMakeSearching = false)
  )

  propellerMakeTypeHeadSearch = (text$: Observable<string>)=>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.propellerMakeSearching = true),
      switchMap(term => this.getMakes(term, 'propeller')
      ),
      tap(() => this.propellerMakeSearching = false)
  )


   //AIR CRAFT MODEL TYPEHEAD ///
  aircraftModelTypeHeadSearch = (text$: Observable<string>)=>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.aircraftModelSearching = true),
      switchMap(term => {
        if(this.aircraftModelNew.aircraftMake.id == null) return of([{id: null, modelName: term}]);

        return this.aircraftService.findAircraftModelByMake(
          term, 
          +this.aircraftModelNew.aircraftMake.id,
          this.aircraftModelNew.aircraftMake.makeName 
        ).pipe(
            tap(() => this.aircraftModelSearchFailed = false),
            catchError(() => {
              this.aircraftModelSearchFailed = true;
              return of([]);
            }),
            map((res:any) =>{
              let arr:Array<any> = [];

              //send new term as new Model
              let termModel = null;
              term = term.trim()
              if(term){
                 termModel = {id: null, modelName: term};
                 arr.push(termModel)
              }
              ///////

              res.responseData.result.forEach(element => {
                //set id of newly added item if it's in result
                if(termModel && (element.modelName.toUpperCase() === termModel.modelName.toUpperCase(0))){
                  arr[0].id = element.id;
                  termModel = null;
                }

                arr.push(element);
              });
              return arr;
            }),
          )
        }
      ),
      tap(() => this.aircraftModelSearching = false)
  )

   //ENGINE MODEL TYPEHEAD ///
  engineModelTypeHeadSearch = (text$: Observable<string>)=>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.engineModelSearching = true),
      switchMap(term => {
        if (this.engineModelNew.engineMake.id == null) {
          return of([{id: null, modelName: term}]);
        }

        return this.aircraftService.findEngineModelByMake(
          term, 
          +this.engineModelNew.engineMake.id,
          this.engineModelNew.engineMake.makeName
        ).pipe(
          tap(() => this.engineModelSearchFailed = false),
          catchError(() => {
          this.engineModelSearchFailed = true;
          return of([]);
        }),
        map((res:any) =>{
          let arr:Array<any> = [];

          //send new term as new Model
          let termModel = null;
          term = term.trim()
          if(term){
             termModel = {id: null, modelName: term};
             arr.push(termModel)
          }
          ///////

          res.responseData.result.forEach(element => {
            //set id of newly added item if it's in result
            if(termModel && (element.modelName.toUpperCase() === termModel.modelName.toUpperCase(0))){
              arr[0].id = element.id;
              termModel = null;
            }

            arr.push(element);
          });

          return arr;
          }),
        )
      }
    ),
    tap(() => this.engineModelSearching = false)
  )

  // PROPELLER  MODEL TYPEHEAD ///
  propellerModelTypeHeadSearch = (text$: Observable<string>)=>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.propellerModelSearching = true),
      switchMap(term => {
        if (this.propellerModelNew.propellerMake.id == null) {
          return of([{id: null, modelName: term}]);
        }

        return this.aircraftService.findPropellerModelByMake(
          term,
          +this.propellerModelNew.propellerMake.id,
          this.propellerModelNew.propellerMake.makeName
        ).pipe(
          tap(() => this.propellerModelSearchFailed = false),
          catchError(() => {
            this.propellerModelSearchFailed = true;
            return of([]);
          }),
          map((res:any) =>{
            let arr:Array<any> = [];

            // send new term as new Model
            let termModel = null;
            term = term.trim()
            if(term){
               termModel = {id: null, modelName: term};
               arr.push(termModel)
            }
            ///////

            res.responseData.result.forEach(element => {
              // set id of newly added item if it's in result
              if (termModel && (element.modelName.toUpperCase() === termModel.modelName.toUpperCase(0))) {
                arr[0].id = element.id;
                termModel = null;
              }

              arr.push(element);
            });

            return arr;
          }),
        );
      }
    ),
    tap(() => this.propellerModelSearching = false)
  )
  //


  // format typeheads data received,  before display
  typeHeadMakeFormatter = (makeObj: {id: string, makeName: string}) => {
    return makeObj.makeName? makeObj.makeName : '';
  }

  typeHeadModelFormatter = (modelObj: {id: string, modelName: string}) => {
    return modelObj.modelName? modelObj.modelName : '';
  }


  // On Select typehead(make) options...
  onTypeHeadSelected(ev: NgbTypeaheadSelectItemEvent, mode: 'aircraft' | 'propeller' | 'engine'){
    if (mode === 'aircraft') {
      this.aircraftModelNew.aircraftMake.id = ev.item.id;
      this.aircraftModelNew.aircraftMake.makeName = ev.item.makeName;
    }
    if (mode === 'engine') {
      this.engineModelNew.engineMake.id  = ev.item.id;
      this.engineModelNew.engineMake.makeName = ev.item.makeName;
    }
    if (mode === 'propeller') {
      this.propellerModelNew.propellerMake.id = ev.item.id;
      this.propellerModelNew.propellerMake.makeName = ev.item.makeName;
    }

    this[mode + 'ModelNew'].id = null;
    this[mode + 'ModelNew'].modelName = null;

    this.createAircraftForm['controls'][mode + 'Data']['controls'][mode+"Model"].setValue({id: null, modelName: null });
  }
  // On Select typehead(model) options...
  onTypeHeadModelSelected(ev: NgbTypeaheadSelectItemEvent, mode: 'aircraft' | 'propeller' | 'engine'){
    if (mode === 'aircraft') {
      this.aircraftModelNew.id = ev.item.id;
      this.aircraftModelNew.modelName = ev.item.modelName;
    }
    if (mode === 'engine') {
      this.engineModelNew.id = ev.item.id;
      this.engineModelNew.modelName = ev.item.modelName;
    }
    if (mode === 'propeller') {
      this.propellerModelNew.id = ev.item.id;
      this.propellerModelNew.modelName = ev.item.modelName;
    }
  }


  // get makes for the modes
  getMakes(term, mode: string) {
    return this.aircraftService.findAircraftMakeByName(term).pipe(
      tap(() => this[mode + 'MakeSearchFailed'] = false),
      catchError(() => {
        this[mode + 'MakeSearchFailed'] = true;
        return of([]);
      }),
      map((res: any) => {
        let arr: Array<any> = [];

        // send new term as new Make
        let termMake = null;
        term = term.trim();
        if (term) {
          termMake = {makeId: null, makeName: term};
           arr.push(termMake);
        }
        ///////

        res.responseData.forEach(element => {
          // set id of newly added item if it's in result
          if (termMake && (element.makeName.toUpperCase() === termMake.makeName.toUpperCase(0))) {
            arr[0].makeId = element.makeId;
            termMake = null;
          }

          arr.push(element);
        });
        /*
        if(arr.length < 1 ){
          arr.push({makeId: null, makeName: term.trim()})
        }*/
        return arr;
      }),
    );
  }

  doCreateAircraft() {
    if (!this.createAircraftForm['controls'].aircraftData.valid) {
      Object.values(this.createAircraftForm['controls'].aircraftData['controls']).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      // for(let i in this.createAircraftForm['controls'].aircraftData['controls']) {
      //   this.createAircraftForm['controls'].aircraftData['controls'][i].markAsTouched();
      // };

      Swal.fire('Check important fields!', 'Aircraft Data Form not filled correctly!', 'error');
      return;
    }

    // check engine data form
    if (
      this.createAircraftForm['controls'].aircraftData['controls'].addEngineData.value &&
        (!this.createAircraftForm['controls'].engineData.valid)
     ) {

      Object.values(this.createAircraftForm['controls'].engineData['controls']).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      // for(let i in this.createAircraftForm['controls'].engineData['controls']) {
      //   this.createAircraftForm['controls'].engineData['controls'][i].markAsTouched();
      // };

      Swal.fire('Check important fields!', 'Engine Data Form not filled correctly!', 'error');
      return;
    }

    // check propeller data form
    if (
      this.createAircraftForm['controls'].aircraftData['controls'].addPropellerData.value &&
      (!this.createAircraftForm['controls'].propellerData.valid)
    ) {

      Object.values(this.createAircraftForm['controls'].propellerData['controls']).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      // for(let i in this.createAircraftForm['controls'].propellerData['controls']) {
      //   this.createAircraftForm['controls'].propellerData['controls'][i].markAsTouched();
      // };

      Swal.fire('Check important fields!', 'Propeller Data Form not filled correctly!', 'error');
      return;
    }

    this.isWorking = true;

    let aircraftObj: UserAircraftModel = new UserAircraftModel;

    aircraftObj.user_id = this.userData.id;
    aircraftObj.tailNo = this.createAircraftForm['controls'].aircraftData['controls'].tailNo.value;
    aircraftObj.airframeSn = this.createAircraftForm['controls'].aircraftData['controls'].airframeSn.value;
    aircraftObj.airframeTtis = this.createAircraftForm['controls'].aircraftData['controls'].airframeTtis.value;

    aircraftObj['addEngineData'] = this.createAircraftForm['controls'].aircraftData['controls'].addEngineData.value;;
    aircraftObj['addPropellerData'] = this.createAircraftForm['controls'].aircraftData['controls'].addPropellerData.value;

    if (aircraftObj['addEngineData']) {
      aircraftObj.engineSn = this.createAircraftForm['controls'].engineData['controls'].engineSn.value;
      aircraftObj.initialEngineHours = this.createAircraftForm['controls'].engineData['controls'].initialEngineHours.value;
      aircraftObj.initialLastEngineOh = this.createAircraftForm['controls'].engineData['controls'].initialLastEngineOh.value;
      aircraftObj.engineInstalledAirframeTime = 
        this.createAircraftForm['controls'].engineData['controls'].engineInstalledAirframeTime.value;
    }


    if (aircraftObj['addPropellerData']) {
      aircraftObj.propellerSn = this.createAircraftForm['controls'].propellerData['controls'].propellerSn.value;
      aircraftObj.initLastPropellerOh = this.createAircraftForm['controls'].propellerData['controls'].initLastPropellerOh.value;
      aircraftObj.inititialPropellerHours = this.createAircraftForm['controls'].propellerData['controls'].inititialPropellerHours.value;
      aircraftObj.propellerInstalledAirframeTime = 
        this.createAircraftForm['controls'].propellerData['controls'].propellerInstalledAirframeTime.value;
    }

    aircraftObj.avionicsEquipments = this.createAircraftForm['controls'].equipmentData['controls'].avionicsEquipments.value;
    aircraftObj.otherEquipments = this.createAircraftForm['controls'].equipmentData['controls'].otherEquipments.value;
    aircraftObj.aircraft_model = this.aircraftModelNew;
    aircraftObj.engine_model = this.engineModelNew;
    aircraftObj.propeller_model = this.propellerModelNew;

    this.aircraftService.createNewAircraftForUser(aircraftObj).subscribe(
      (response: any) => {
        this.isWorking = false;
        Swal.fire({
          title: 'Aircraft Created!',
          text: 'Want to view customer or create another Aircraft?',
          type: 'success',
          showCancelButton: true,
          confirmButtonText: 'Yes. View new customer!',
          cancelButtonText: 'No, create another one.'
        }).then((descision) => {
          if (descision.value) {
            this.router.navigate(['/cga/admin/edit-customer', this.userData.id]);
          } else if (descision.dismiss === Swal.DismissReason.cancel) {
            this.aircraftModelNew = new AircraftModel;
            this.engineModelNew = new EngineModel;
            this.propellerModelNew = new PropellerModel;

            this.createAircraftForm.reset({});
          }
        });
      },
      (error) => {
        this.isWorking = false;
        if (error.error && error.error.status.message){
          Swal.fire(
            error.error.status.message,
            error.error.responseData.join(' '),
            'error'
          );
        } else {
          Swal.fire(
            'Oops!',
            'Creat aircraft failed.',
            'error'
          );
        }
      }
    );
  }

};
