import { AlertPopUpsService } from '../../../services/alert-pop-ups.service';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from '../../../services/utilities.service';
import { OrganizationAircraftAndRoleModel } from '../../../models/organization-aircraft-and-role-model';
import { OrganizationService } from '../../../services/organization.service';
import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrganizationBaseModel } from '../../../models/organization-base-model';
import { OrganizationPersonModel } from '../../../models/organization-person-model';
import { Subject, SubscriptionLike } from 'rxjs';
import { UserOrganizationAndRole } from '../../../models/user-organization-and-role';

@Component({
  selector: 'app-organization-profile',
  templateUrl: './organization-profile.component.html',
  styleUrls: ['./organization-profile.component.scss']
})
export class OrganizationProfileComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('orgProfileTabs') orgProfileTabs: NgbTabset;

  constructor(
    private route: ActivatedRoute,
    private orgService: OrganizationService,
    private utilsService: UtilitiesService,
    private popUpService: AlertPopUpsService,
    private _cdRef: ChangeDetectorRef
  ) { }

  isWorking: boolean = false;

  theOrg: OrganizationBaseModel = new OrganizationBaseModel;
  membersList: Array<OrganizationPersonModel> = [];
  aircraftList: Array<OrganizationAircraftAndRoleModel> = [];

  tabChangeEventsSubject: Subject<void> = new Subject();

  urlTabName: string = 'members';



  selectedOrg: UserOrganizationAndRole = new UserOrganizationAndRole;
  selectedOrg$: SubscriptionLike;


  ngOnInit() {
    this.selectedOrg$ = this.orgService.selectedOrg$.subscribe(
      (org: UserOrganizationAndRole) => {
        this.selectedOrg = org;
        //console.log(this.selectedOrg);

        if (this.selectedOrg && this.selectedOrg.organization.id) {
          this.getOrgbyId();
        }
      }
    );
  }


  // ngOnChanges($event) {
  //   if ($event.orgId) {
  //     //this.orgId = $event.orgId.currentValue;

  //     this.getOrgbyId();
  //   }

  // }

  ngAfterViewInit() {
    this.route.queryParams.subscribe(params => {
      if (params['t']) {
        this.urlTabName = params['t'];
      }
      this.doTabUrlChange();
    });

    this._cdRef.detectChanges();
  }

  doTabUrlChange() {
    const prefix = 'tab_';
    if (
      this.urlTabName &&
      (this.urlTabName.toLowerCase() === 'members' ||
      this.urlTabName.toLowerCase() === 'aircraft')
     ) {
      const theTab = prefix + this.urlTabName.toLowerCase();
      if (this.orgProfileTabs) {
        this.orgProfileTabs.select(theTab);
      }
    }
  }

  getOrgbyId() {
    if (!this.selectedOrg.organization.id) {
      return;
    }

    this.isWorking = true;
    this.orgService.getOrganizationV2(this.selectedOrg.organization.id).subscribe(
      (response: any) => {
        this.isWorking = false;
        this.theOrg = response;
        this.getOrgMembers();
      }
    );
  }

  getOrgMembers() {
    this.isWorking = true;
    this.orgService.getUsersByOrganizationV2(this.selectedOrg.organization.id).subscribe(
      (response: any) => {
        this.isWorking = false;
        this.membersList = response;
      },
      (error) => {
        this.isWorking = false;
        this.popUpService.openBasicPopUp(
          `Sorry`,
          `Can not get organization members.`,
          'error'
        );
      }
    );
  }

  formatPhoneNumber(phoneNumber: string) {
    return this.utilsService.formatPhoneNumber(phoneNumber);
  }

  onTabChange() {
    this.tabChangeEventsSubject.next();
  }

  ngOnDestroy () {
    if (this.selectedOrg$) {
      this.selectedOrg$.unsubscribe();
    }
  }

}
