import { RagType } from './rag-type';
import { AirworthinessDirectiveModel } from './airworthiness-directive-model';
import * as moment from "moment";

export class AircraftAirDirectiveModel {
  constructor(){
    this.id = null;
    this.aircraftAirDirectivedId = null;
    this.aircraftId = null;
    this.directiveId = null;
    this.airworthiness_directive = new AirworthinessDirectiveModel;
    this.isRecurring = false;
    this.terminateAction = false;
  }
  id: number;
  aircraftAirDirectivedId: number;
  dueDate: string;
  dueDateAsMoment?: moment.Moment;
  dueHours: number;
  dueHoursAsMoment?: moment.Moment;
  sortingValue?: string;
  sortingMomentValue?: moment.Moment;
  sortingColumnName?: "Date" | "Hours";
  ragStatus?: RagType;
  isRecurring: boolean;
  terminateAction: boolean;
  lastCompliedDate: string;
  lastCompliedHour: number;
  condition: string;
  application: string;
  notApplyReason: string;
  complianceNote: string;
  aircraftId: number;
  directiveId: number;
  airworthiness_directive: AirworthinessDirectiveModel;
}