import { UserAircraftModel } from './user-aircraft-model';

export class MAircraftOilChangesModel{
  constructor(){
    this.id = null;
    this.aircraftId = null;
    this.lastOilChangeHours = null;
    this.aircraft  = new UserAircraftModel;
  }
  id: number;
  aircraftId: number;
  lastOilChangeHours: number;
  aircraft: UserAircraftModel;
}