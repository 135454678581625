import { OrganizationService } from './../../../services/organization.service';
import Swal from 'sweetalert2';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import {
  distinctUntilChanged,
  debounceTime,
  switchMap,
  tap,
  map,
  catchError
} from 'rxjs/operators';
import { FormControl, AbstractControl } from '@angular/forms';
import { SubscriptionLike, Observable, of } from 'rxjs';
import { UserAircraftModel } from './../../../models/user-aircraft-model';
import { FormGroup, Validators } from '@angular/forms';
import { AlertPopUpsService } from './../../../services/alert-pop-ups.service';
import { Router } from '@angular/router';
import { AircraftService } from './../../../services/aircraft.service';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NumberToTenths } from '../../../_custom-validators/number-to-tenths-validator';
import { HasDependantControlValidator } from '../../../_custom-validators/has-dependant-control-validator';
import { DependsOnControlValidator } from '../../../_custom-validators/depends-on-control-validator';
import { AircraftModel } from '../../../models/aircraft-model';
import { EngineModel } from '../../../models/engine-model';
import { PropellerModel } from '../../../models/propeller-model';
import { OrganizationBaseModel } from '../../../models/organization-base-model';
import { MustNotBeSameValueAsControlValidator } from '../../../_custom-validators/must-not-be-same-value-as-control-validator';
import { AircraftV2Model } from '../../../models/airfcraftV2/aircraft-v2.model';
import { EngineV2Model } from '../../../models/airfcraftV2/engine-v2.model';
import { PropellerV2Model } from '../../../models/airfcraftV2/propeller-v2.model';
import { AirframeV2Model } from '../../../models/airfcraftV2/airframe-v2.model';

@Component({
  selector: 'app-add-aircraft-v2',
  templateUrl: './add-aircraft-v2.component.html',
  styleUrls: ['./add-aircraft-v2.component.scss']
})
export class AddAircraftV2Component implements OnInit, OnDestroy {
  constructor(
    private formBuilder: FormBuilder,
    private aircraftService: AircraftService,
    private orgService: OrganizationService,
    private router: Router,
    private popUpService: AlertPopUpsService
  ) {}

  allOrgsAndRoles: Array<OrganizationBaseModel> = [];

  newAircraftForm: FormGroup;

  theAircraft: AircraftV2Model = new AircraftV2Model();

  aircraftMakeSearchFailed: boolean = false;
  aircraftMakeSearching: boolean = false;
  aircraftModelSearchFailed: boolean = false;
  aircraftModelSearching: boolean = false;

  engineMakeSearchFailed: boolean = false;
  engineMakeSearching: boolean = false;
  engineModelSearchFailed: boolean = false;
  engineModelSearching: boolean = false;

  propellerMakeSearchFailed: boolean = false;
  propellerMakeSearching: boolean = false;
  propellerModelSearchFailed: boolean = false;
  propellerModelSearching: boolean = false;

  isWorking: boolean = false;

  aircraftMakeSubscriber$: SubscriptionLike;
  aircraftModelSubscriber$: SubscriptionLike;

  engineMakeSubscriber$: SubscriptionLike;
  engineModelSubscriber$: SubscriptionLike;

  propellerMakeSubscriber$: SubscriptionLike;
  propellerModelSubscriber$: SubscriptionLike;

  ngOnInit() {
    this.newAircraftForm = this.formBuilder.group({
      // aircraft
      ownerOrgId: new FormControl(null, [Validators.required]),
      maintainerOrgId: new FormControl(null),
      tailNo: new FormControl(null, [
        Validators.pattern(/^[a-zA-Z0-9-]{5,8}$/),
        Validators.required,
        Validators.minLength(5)
      ]),
      aircraftMake: new FormControl(''),
      aircraftModel: new FormControl(''),
      airframeSn: new FormControl(''),
      airframeTtisAtSetup: new FormControl(null, [NumberToTenths(true)]),

      // engine
      engineMake: new FormControl(''),
      engineModel: new FormControl(''),
      engineSn: new FormControl(''),
      initialEngineHours: new FormControl(null, [NumberToTenths(true)]),
      initialLastEngineOh: new FormControl(null, [NumberToTenths(true)]),
      engineInstalledAirframeTime: new FormControl(null, [
        NumberToTenths(true)
      ]),

      // propeller
      propellerMake: new FormControl(''),
      propellerModel: new FormControl(''),
      propellerSn: new FormControl(''),
      initLastPropellerOh: new FormControl(null, [NumberToTenths(true)]),
      inititialPropellerHours: new FormControl(null, [NumberToTenths(true)]),
      propellerInstalledAirframeTime: new FormControl(null, [
        NumberToTenths(true)
      ]),

      // equipment
      avionicsEquipments: new FormControl(''),
      otherEquipments: new FormControl('')
    });

    this.newAircraftForm.controls.maintainerOrgId.setValidators([
      MustNotBeSameValueAsControlValidator(
        this.newAircraftForm.controls.ownerOrgId
      )
    ]);

    // update maintainer value and validity on change of Owner Org value
    this.newAircraftForm.controls.ownerOrgId.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.newAircraftForm.controls.maintainerOrgId.updateValueAndValidity();
      });

    this.doExtraUpdateFormValidations('aircraft');
    this.doExtraUpdateFormValidations('engine');
    this.doExtraUpdateFormValidations('propeller');

    this.listAllOrgs();

    // observe the activated route parameters, and get user id  on change
  }

  listAllOrgs() {
    this.isWorking = true;

    this.orgService.listOrganizationsV2().subscribe(
      (response: Array<OrganizationBaseModel>) => {
        this.isWorking = false;
        this.allOrgsAndRoles = response;
      },
      (error: any) => {
        this.isWorking = false;
        this.popUpService.openBasicPopUp(
          'Can not fetch Organizations!',
          'Please reload pageand try again.',
          'error'
        );

        this.router.navigate(['/cga/admin/list-aircraft']);
      }
    );
  }

  doExtraUpdateFormValidations(type: 'aircraft' | 'engine' | 'propeller') {
    // type MAKE AND MODEL EXTRAS
    // Set Validators for type  Makes
    this.newAircraftForm.controls[`${type}Make`].setValidators([
      HasDependantControlValidator(
        this.newAircraftForm.controls[`${type}Model`]
      )
    ]);

    // Set Validators for type Model
    this.newAircraftForm.controls[`${type}Model`].setValidators([
      DependsOnControlValidator(this.newAircraftForm.controls[`${type}Make`])
    ]);

    // Listen to type Make Field for changes, then update Models validity
    this[`${type}MakeSubscriber$`] = this.newAircraftForm.controls[
      `${type}Make`
    ].valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value: any) => {
        this.newAircraftForm.controls[`${type}Model`].updateValueAndValidity();
      });

    // Listen to type Model Field for changes, then update Models validity
    this[`${type}ModelSubscriber$`] = this.newAircraftForm.controls[
      `${type}Model`
    ].valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value: any) => {
        this.newAircraftForm.controls[`${type}Make`].updateValueAndValidity();
      });

    return;
  }

  // from getters for validations
  get ownerOrgId() {
    return this.newAircraftForm.controls.ownerOrgId;
  }
  get maintainerOrgId() {
    return this.newAircraftForm.controls.maintainerOrgId;
  }
  get aircraftMake() {
    return this.newAircraftForm.controls.aircraftMake;
  }
  get aircraftModel() {
    return this.newAircraftForm.controls.aircraftModel;
  }
  get tailNo() {
    return this.newAircraftForm.controls.tailNo;
  }
  get airframeTtisAtSetup() {
    return this.newAircraftForm.controls.airframeTtisAtSetup;
  }

  get initialEngineHours() {
    return this.newAircraftForm.controls.initialEngineHours;
  }
  get engineMake() {
    return this.newAircraftForm.controls.engineMake;
  }
  get engineModel() {
    return this.newAircraftForm.controls.engineModel;
  }
  get engineSn() {
    return this.newAircraftForm.controls.engineSn;
  }
  get initialLastEngineOh() {
    return this.newAircraftForm.controls.initialLastEngineOh;
  }
  get engineInstalledAirframeTime() {
    return this.newAircraftForm.controls.engineInstalledAirframeTime;
  }

  get propellerMake() {
    return this.newAircraftForm.controls.propellerMake;
  }
  get propellerModel() {
    return this.newAircraftForm.controls.propellerModel;
  }
  get propellerSn() {
    return this.newAircraftForm.controls.propellerSn;
  }
  get initLastPropellerOh() {
    return this.newAircraftForm.controls.initLastPropellerOh;
  }
  get inititialPropellerHours() {
    return this.newAircraftForm.controls.inititialPropellerHours;
  }
  get propellerInstalledAirframeTime() {
    return this.newAircraftForm.controls.propellerInstalledAirframeTime;
  }

  //// Type heads search functions for aircraft make, engine make and propeller make
  aircraftMakeTypeHeadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.aircraftMakeSearching = true)),
      switchMap(term => this.getMakes(term, 'aircraft')),
      tap(() => (this.aircraftMakeSearching = false))
    );

  engineMakeTypeHeadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.engineMakeSearching = true)),
      switchMap(term => this.getMakes(term, 'engine')),
      tap(() => (this.engineMakeSearching = false))
    )

  propellerMakeTypeHeadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.propellerMakeSearching = true)),
      switchMap(term => this.getMakes(term, 'propeller')),
      tap(() => (this.propellerMakeSearching = false))
    );

  // get makes for the modes
  getMakes(term: string, mode: string) {
    if (this.theAircraft[mode + '_model_id'] !== null) {
      let newModels = {
        aircraftModelNew: new AircraftModel(),
        engineModelNew: new EngineModel(),
        propellerModelNew: new PropellerModel()
      };

      this.theAircraft[mode + '_model'] = newModels[mode + 'ModelNew'];
      this.theAircraft[mode + '_model_id'] = null;
    }

    this.newAircraftForm.controls[mode + 'Model'].setValue(null);

    if (term.length < 1) {
      return of([]);
    }

    return this.aircraftService.findAircraftMakeByName(term).pipe(
      tap(() => (this[mode + 'MakeSearchFailed'] = false)),
      catchError(() => {
        this[mode + 'MakeSearchFailed'] = true;
        return of([]);
      }),
      map((res: any) => {
        let arr: Array<any> = [];

        // send new term as new Make
        let termMake = null;
        term = term.trim();
        if (term) {
          termMake = { makeId: null, makeName: term };
          arr.push(termMake);
        }
        ///////

        res.responseData.forEach(element => {
          // set id of newly added item if it's in result
          if (
            termMake &&
            element.makeName.toUpperCase() === termMake.makeName.toUpperCase(0)
          ) {
            arr[0].makeId = element.makeId;
            termMake = null;
          }

          arr.push(element);
        });
        /*if(arr.length < 1 ){
          arr.push({makeId: null, makeName: term.trim()})
        }*/
        return arr;
      })
    );
  }

  // On Select typehead(make) options...
  onTypeHeadSelected(
    ev: NgbTypeaheadSelectItemEvent,
    mode: 'aircraft' | 'propeller' | 'engine'
  ) {
    this.theAircraft[mode + '_model'].id = null;
    this.theAircraft[mode + '_model'].modelName = null;

    if (mode === 'aircraft') {
      this.theAircraft.airframe.make.id = ev.item.id;
      this.theAircraft.airframe.make.name = ev.item.makeName;
      this.newAircraftForm.controls.aircraftModel.setValue(null);
    }
    if (mode === 'engine') {
      this.theAircraft.engines[0].make.id = ev.item.id;
      this.theAircraft.engines[0].make.name = ev.item.makeName;
      this.newAircraftForm.controls.engineModel.setValue(null);
    }
    if (mode === 'propeller') {
      this.theAircraft.propellers[0].make.id = ev.item.id;
      this.theAircraft.propellers[0].make.name =
        ev.item.makeName;
      this.newAircraftForm.controls.propellerModel.setValue(null);
    }
  }

  // AIR CRAFT MODEL TYPEHEAD //
  aircraftModelTypeHeadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.aircraftModelSearching = true)),
      switchMap(term => {
        if (this.theAircraft.airframe.make.id == null) {
          return of([{ id: null, modelName: term }]);
        }

        return this.aircraftService
          .findAircraftModelByMake(
            term,
            +this.theAircraft.airframe.make.id,
            this.theAircraft.airframe.make.name
          )
          .pipe(
            tap(() => (this.aircraftModelSearchFailed = false)),
            catchError(() => {
              this.aircraftModelSearchFailed = true;
              return of([]);
            }),
            map((res: any) => {
              let arr: Array<any> = [];

              // send new term as new Model
              let termModel = null;
              term = term.trim();
              if (term) {
                termModel = { id: null, modelName: term };
                arr.push(termModel);
              }
              ///////

              res.responseData.result.forEach(element => {
                // set id of newly added item if it's in result
                if (
                  termModel &&
                  element.modelName.toUpperCase() ===
                    termModel.modelName.toUpperCase(0)
                ) {
                  arr[0].id = element.id;
                  termModel = null;
                }

                arr.push(element);
              });

              return arr;
            })
          );
      }),
      tap(() => (this.aircraftModelSearching = false))
    );

  // ENGINE MODEL TYPEHEAD //
  engineModelTypeHeadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.engineModelSearching = true)),
      switchMap(term => {
        if (this.theAircraft.engines[0].make.id == null) {
          return of([{ id: null, modelName: term }]);
        }

        return this.aircraftService
          .findEngineModelByMake(
            term,
            +this.theAircraft.engines[0].make.id,
            this.theAircraft.engines[0].make.name
          )
          .pipe(
            tap(() => (this.engineModelSearchFailed = false)),
            catchError(() => {
              this.engineModelSearchFailed = true;
              return of([]);
            }),
            map((res: any) => {
              let arr: Array<any> = [];

              // send new term as new Model
              let termModel = null;
              term = term.trim();
              if (term) {
                termModel = { id: null, modelName: term };
                arr.push(termModel);
              }
              ///////

              res.responseData.result.forEach(element => {
                // set id of newly added item if it's in result
                if (
                  termModel &&
                  element.modelName.toUpperCase() ===
                    termModel.modelName.toUpperCase(0)
                ) {
                  arr[0].id = element.id;
                  termModel = null;
                }

                arr.push(element);
              });
              return arr;
            })
          );
      }),
      tap(() => (this.engineModelSearching = false))
    )

  // PROPELLER  MODEL TYPEHEAD //
  propellerModelTypeHeadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.propellerModelSearching = true)),
      switchMap(term => {
        if (this.theAircraft.propellers[0].make.id === null) {
          return of([{ id: null, modelName: term }]);
        }

        return this.aircraftService
          .findPropellerModelByMake(
            term,
            +this.theAircraft.propellers[0].make.id,
            this.theAircraft.propellers[0].make.name
          )
          .pipe(
            tap(() => (this.propellerModelSearchFailed = false)),
            catchError(() => {
              this.propellerModelSearchFailed = true;
              return of([]);
            }),
            map((res: any) => {
              let arr: Array<any> = [];

              // send new term as new Model
              let termModel = null;
              term = term.trim();
              if (term) {
                termModel = { id: null, modelName: term };
                arr.push(termModel);
              }
              ///////

              res.responseData.result.forEach(element => {
                // set id of newly added item if it's in result
                if (
                  termModel &&
                  element.modelName.toUpperCase() ===
                    termModel.modelName.toUpperCase(0)
                ) {
                  arr[0].id = element.id;
                  termModel = null;
                }

                arr.push(element);
              });

              return arr;
            })
          );
      }),
      tap(() => (this.propellerModelSearching = false))
    );
  //

  // format typeheads data received,  before display
  typeHeadMakeFormatter = (makeObj: { id: string; makeName: string }) => {
    return makeObj.makeName ? makeObj.makeName : '';
  }

  typeHeadModelFormatter = (modelObj: { id: string; modelName: string }) => {
    return modelObj.modelName ? modelObj.modelName : '';
  }

  // On Select typehead(model) options...
  onTypeHeadModelSelected(
    ev: NgbTypeaheadSelectItemEvent,
    mode: 'aircraft' | 'propeller' | 'engine'
  ) {
    if (mode === 'aircraft') {
      // this.theAircraft.aircraft_model.id = ev.item.id;
      // this.theAircraft.aircraft_model.modelName = ev.item.modelName;
      this.theAircraft.airframe.model.id = ev.item.id;
    }
    if (mode === 'engine') {
      // this.theAircraft.engine_model.id = ev.item.id;
      // this.theAircraft.engine_model.modelName = ev.item.modelName;
      this.theAircraft.engines[0].model.id = ev.item.id;
    }
    if (mode === 'propeller') {
      // this.theAircraft.propeller_model.id = ev.item.id;
      // this.theAircraft.propeller_model.modelName = ev.item.modelName;
      this.theAircraft.propellers[0].model.id = ev.item.id;
    }
  }

  doCreateAircraft() {
    // scroll to top
    window.scrollTo(0, 0);

    if (!this.newAircraftForm.valid) {

      Object.values(this.newAircraftForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      // for (let i in this.newAircraftForm.controls) {
      //   this.newAircraftForm.controls[i].markAsTouched();
      // }

      this.popUpService.openBasicPopUp(
        'Check important fields!',
        'Aircraft Data Form not filled correctly!',
        'error'
      );
      return;
    }

    // check aircraft model
    if (!this.newAircraftForm.controls.aircraftMake.value) {
      this.theAircraft.airframe = new AirframeV2Model;
    }

    // check engine model
    if (!this.newAircraftForm.controls.engineMake.value) {
      this.theAircraft.engines[0] = new EngineV2Model;
    }

    // check propeller model
    if (!this.newAircraftForm.controls.propellerMake.value) {
      this.theAircraft.propellers[0] = new PropellerV2Model;
    }

    this.isWorking = true;

    this.theAircraft.tailNo = this.newAircraftForm.controls.tailNo.value.toUpperCase();
    this.theAircraft.airframe.serialNumber = this.newAircraftForm.controls.airframeSn.value;
    this.theAircraft.airframe.ttisAtSetup = this.newAircraftForm.controls.airframeTtisAtSetup.value;
    this.theAircraft.engines[0].serialNumber = this.newAircraftForm.controls.engineSn.value;
    this.theAircraft.engines[0].initialHours = this.newAircraftForm.controls.initialEngineHours.value;
    this.theAircraft.engines[0].lastOverhaulHours = this.newAircraftForm.controls.initialLastEngineOh.value;
    this.theAircraft.engines[0].installedAirframeTime = this.newAircraftForm.controls.engineInstalledAirframeTime.value;
    this.theAircraft.propellers[0].serialNumber = this.newAircraftForm.controls.propellerSn.value;
    this.theAircraft.propellers[0].lastOverhaulHours = this.newAircraftForm.controls.initLastPropellerOh.value;
    this.theAircraft.propellers[0].initialHours = this.newAircraftForm.controls.inititialPropellerHours.value;
    this.theAircraft.propellers[0].installedAirframeTime = this.newAircraftForm.controls.propellerInstalledAirframeTime.value;
    this.theAircraft.avionicsEquipments = this.newAircraftForm.controls.avionicsEquipments.value;
    this.theAircraft.otherEquipments = this.newAircraftForm.controls.otherEquipments.value;

    // convert to V1 UserAircraftModel
    // const theUserAircraft: UserAircraftModel = new UserAircraftModel;

    // theUserAircraft.id = this.theAircraft.id;
    // theUserAircraft.tailNo = this.theAircraft.tailNo;
    // theUserAircraft.airframeSn = this.theAircraft.airframe.serialNumber;
    // theUserAircraft.airframeTtis = this.theAircraft.airframe.ttisAtSetup;

    // theUserAircraft.engineSn = this.theAircraft.engines[0].serialNumber;
    // theUserAircraft.initialEngineHours = this.theAircraft.engines[0].initialHours;
    // theUserAircraft.engineInstalledAirframeTime = this.theAircraft.engines[0].installedAirframeTime;
    // theUserAircraft.initialLastEngineOh = this.theAircraft.engines[0].lastOverhaulHours;

    // theUserAircraft.propellerSn = this.theAircraft.propellers[0].serialNumber;
    // theUserAircraft.initLastPropellerOh = this.theAircraft.propellers[0].lastOverhaulHours;
    // theUserAircraft.inititialPropellerHours = this.theAircraft.propellers[0].initialHours;
    // theUserAircraft.propellerInstalledAirframeTime = this.theAircraft.propellers[0].installedAirframeTime;

    // theUserAircraft.avionicsEquipments = this.theAircraft.avionicsEquipments;
    // theUserAircraft.otherEquipments = this.theAircraft.otherEquipments;

    // theUserAircraft.propeller_model_id = this.theAircraft.propellers[0].model.id;
    // theUserAircraft.engine_model_id = this.theAircraft.engines[0].model.id;
    // theUserAircraft.aircraft_model_id = this.theAircraft.airframe.model.id;


    // theUserAircraft.aircraftLogbookState = this.theAircraft.airframe.logbookState;
    // theUserAircraft.engineLogbookState = this.theAircraft.engines[0].logbookState;
    // theUserAircraft.propellerLogbookState = this.theAircraft.propellers[0].logbookState;
    // theUserAircraft.adComplianceReportState = this.theAircraft.adComplianceReportState;
    // theUserAircraft.adminApproved = this.theAircraft.adminApproved;

    // theUserAircraft.registrationDueDate = this.theAircraft.registrationDueDate;
    // theUserAircraft.insuranceDueDate = this.theAircraft.insuranceDueDate;

    // theUserAircraft.engine_model = {
    //   id: this.theAircraft.engines[0].model.id,
    //   modelName: this.theAircraft.engines[0].model.name,
    //   engineMake: {
    //     id: this.theAircraft.engines[0].make.id,
    //     makeName: this.theAircraft.engines[0].make.name
    //   },
    //   make: null,
    //   manufacturer: null,
    // };

    // theUserAircraft.propeller_model = {
    //   id: this.theAircraft.propellers[0].model.id,
    //   modelName: this.theAircraft.engines[0].model.name,
    //   propellerMake: {
    //     id: this.theAircraft.propellers[0].make.id,
    //     makeName: this.theAircraft.propellers[0].make.name
    //   },
    //   make: null,
    //   manufacturer: null,
    // };

    // theUserAircraft.aircraft_model = {
    //   id: this.theAircraft.airframe.model.id,
    //   modelName: this.theAircraft.engines[0].model.name,
    //   aircraftMake: {
    //     id: this.theAircraft.airframe.make.id,
    //     makeName: this.theAircraft.airframe.make.name
    //   },
    //   make: null,
    //   manufacturer: null,
    // };

    const theUserAircraft = this.aircraftService.convertV2AircraftToV1Model(this.theAircraft);

    this.aircraftService
      .updateAircraftV2(this.theAircraft.tailNo, theUserAircraft)
      .subscribe(
        (response: AircraftV2Model) => {
          Swal.fire(
            'Aircraft created!',
            'Please wait while we attach to Owner Organization.',
            'info'
          );

          this.theAircraft = response;

          this.attachOwnerOrgToAircraft();
        },
        (error: any) => {
          this.isWorking = false;
          Swal.fire(
            'Aircraft create failed!',
            'Could not create aircraft.',
            'error'
          );
        }
      );
  }

  attachOwnerOrgToAircraft() {
    this.aircraftService
      .attachOrgAndRoleToAircraftV2(
        this.newAircraftForm.controls.ownerOrgId.value,
        this.theAircraft.id,
        'OWNER'
      )
      .subscribe(
        (response: any) => {
          if (this.newAircraftForm.controls.maintainerOrgId.value) {
            Swal.fire(
              'Aircraft attached to Owner Organization!',
              'Please wait while we attach to Maintenance Organization.',
              'info'
            );
            this.attachMaintainerOrgToAircraft();
          } else {
            this.isWorking = false;
            Swal.fire(
              'Aircraft attached to Owner Organization!',
              'Redirecting to aircraft view.',
              'success'
            );
          }
        },
        (error: any) => {
          this.isWorking = false;

          Swal.fire(
            'Aircraft not attached to Owner Organization!',
            `View aircraft list to see new aircraft (Tail No.: ${this.theAircraft.tailNo})`,
            'error'
          );
          this.goToAdminAircraftList();
        }
      );
  }

  attachMaintainerOrgToAircraft() {
    this.aircraftService
      .attachOrgAndRoleToAircraftV2(
        this.newAircraftForm.controls.maintainerOrgId.value,
        this.theAircraft.id,
        'MAINTAINER'
      )
      .subscribe(
        (response: any) => {
          Swal.fire(
            'Aircraft attached to Maintainer Organization!',
            'Redirecting to aircraft view.',
            'success'
          );
          this.goToViewNewAircraft();
        },
        (error: any) => {
          this.isWorking = false;

          Swal.fire(
            'Aircraft not attached to Maintainer Organization!',
            `View aircraft list to see new aircraft (Tail No.: ${this.theAircraft.tailNo})`,
            'error'
          );
          this.goToAdminAircraftList();
        }
      );
  }

  goToViewNewAircraft() {
    this.isWorking = false;
    this.aircraftService.setSelectedAircraft(this.theAircraft);
    this.router.navigate(['/cga/aircraft-profile']);
  }

  goToAdminAircraftList() {
    this.router.navigate(['/cga/admin/list-aircraft']);
  }

  ngOnDestroy() {
    if (this.aircraftMakeSubscriber$)
      this.aircraftMakeSubscriber$.unsubscribe();
    if (this.aircraftModelSubscriber$)
      this.aircraftModelSubscriber$.unsubscribe();

    if (this.engineMakeSubscriber$) this.engineMakeSubscriber$.unsubscribe();
    if (this.engineModelSubscriber$) this.engineModelSubscriber$.unsubscribe();

    if (this.propellerMakeSubscriber$)
      this.propellerMakeSubscriber$.unsubscribe();
    if (this.propellerModelSubscriber$)
      this.propellerModelSubscriber$.unsubscribe();
  }
}
