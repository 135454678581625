import { VorCheckModel } from './../models/vor-check-model';
import { AircraftFlightLogModel } from './../models/aircraft-flight-log-model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { NavDataUpdateModel } from '../models/nav-data-update-model';

@Injectable({
  providedIn: 'root'
})
export class FlightLogsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getAllSquawkTypes(){
    return this.httpClient.get(environment.apiBaseUrl + '/flightlog/getAllSquawkTypes')
  }

  getLastAircraftFlightLog(aircraftId: number){
    return this.httpClient.get(environment.apiBaseUrl + '/flightlog/getLastAircraftFlightLog?aircraftId=' + aircraftId)
  }

  fetchAllAircraftFlightLogsTillFlightDate(aircraftId: number, flightDate: string, page: number = 1, pageSize: number = 50){
    return this.httpClient.get(environment.apiBaseUrl + '/flightlog/fetchAllAircraftFlightLogsTillFlightDate?aircraftId=' + aircraftId + "&flightDate=" + flightDate+ "&page=" + page + "&pageSize=" + pageSize)
  }

  addFlightLogtoAircraft(flightLog: AircraftFlightLogModel){
    return this.httpClient.post(environment.apiBaseUrl + '/flightlog/addFlightLogtoAircraft', flightLog)
  }

  updateFlightLogOnAircraft(flightLog: AircraftFlightLogModel){
    return this.httpClient.put(environment.apiBaseUrl + '/flightlog/updateFlightLogOnAircraft', flightLog)
  }

  deleteFlightLogOnAircraft(flightLogId: number){
    return this.httpClient.delete(environment.apiBaseUrl + '/flightlog/deleteFlightLogOnAircraft?flightLogId=' + flightLogId)
  }

  //NAV DATA UPDATE
  getAllAircraftNavDataUpdates(aircraftId: number){
    return this.httpClient.get(environment.apiBaseUrl + '/flightlog/getAllAircraftNavDataUpdates?aircraftId=' + aircraftId)
  }

  addAircraftNavDataUpdate(navDataUpdate: NavDataUpdateModel){
    return this.httpClient.post(environment.apiBaseUrl + '/flightlog/addAircraftNavDataUpdate', navDataUpdate)
  }

  updateAircraftNavDataUpdate(navDataUpdate: NavDataUpdateModel){
    return this.httpClient.put(environment.apiBaseUrl + '/flightlog/updateAircraftNavDataUpdate', navDataUpdate)
  }

  //VOR CHECKS
  getAllAircraftVorChecks(aircraftId: number){
    return this.httpClient.get(environment.apiBaseUrl + '/flightlog/getAllAircraftVorChecks?aircraftId=' + aircraftId)
  }

  addAircraftVorCheck(vorCheck: VorCheckModel){
    return this.httpClient.post(environment.apiBaseUrl + '/flightlog/addAircraftVorCheck', vorCheck)
  }

  updateAircraftVorCheck(vorCheck: VorCheckModel){
    return this.httpClient.put(environment.apiBaseUrl + '/flightlog/updateAircraftVorCheck', vorCheck)
  }


}
