<div class="card shadow border-0 mt-3">
  <div class="card-body">
    <app-preloader-type-a *ngIf="isLoading"></app-preloader-type-a>
    
    <ng-container *ngIf="!isLoading">
      <div class="row" *ngIf="!fbLoggedIn">
        <div class="col-12 col-lg-4 col-md-6 m-auto">
          <form [formGroup]="authenticateUploadForm" (ngSubmit)="onAuthSignIn()">
            <h4 class="form_header uppercase">Authenticate user</h4>
            <p>
              Please authenticate using your password to upload files.
            </p>
            
            <div class="form-group">
              <small class="form-text text-muted required">Email Address:</small>
              <input class="form-control" type="text" disabled [value]="currentUser.emailAddress" />
            </div>
            
            <div class="form-group">
              <small class="form-text text-muted required">Password:</small>
              <input class="form-control" type="password" formControlName="pwd" name="pwd" />
            </div>
      
            <div class="text-right mt-2">
              <button class="btn btn-outline-primary" type="submit">Authenticate</button>
            </div>
          </form>
        </div>
      </div>

  
      <section *ngIf="fbLoggedIn">
        <form [formGroup]="uploadLogBookForm" (ngSubmit)="onSubmit()" *ngIf="!isUploading">
          <h4 class="form_header uppercase">Aircraft Log Book upload</h4>
          <div class="form-group">
            <small class="form-text text-muted required">Select Log Book type</small>
            <select class="form-control" name="type" formControlName="type">
              <option *ngFor="let type of logBookTypeOptions.options" [value]="type.value">
                {{type.label}}
              </option>
            </select>
            <small class="form-text text-danger" *ngIf="uploadLogBookForm.get('type').invalid && (uploadLogBookForm.get('type').dirty || uploadLogBookForm.get('type').touched)">Minimum lenght of 3 and max of 6 .</small>

          </div>
    
          <div class="form-group">
            <div class="dropzone" appDropZone
              (hovered)="toggleHover($event)" 
              (dropped)="onDrop($event)"
              [class.hovering]="isHovering"
            >
            Drag and drop file here
            </div>
          </div>
    
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="fileToUploadLabel">or Select Log Book file(PDF).</span>
              </div>
              <div class="custom-file">
                <input type="file" class="custom-file-input" name="uploads" id="fileToUpload" (change)="onDrop($event.target.files)" accept="application/pdf" aria-describedby="fileToUploadLabel">
                <label class="custom-file-label" for="fileToUpload">Choose file</label>
              </div>
            </div>
          </div>
    
          <h6 class="text-muted border-bottom"> Files</h6>

          <div class="mt-3">
            <div class="alert alert-warning" *ngIf="files.length < 1">
              No File selected. Selected file will show here.
            </div>

            <span  *ngFor="let file of files; let i = index" class="badge badge-secondary w-100 mb-2 mr-1">
              {{file.name}}
              <a href="javascript: void(0);" (click)="removeFile(i)" class="btn btn-sm btn-white">
                <i class="fa fa-times"></i>
              </a>
            </span>
          </div>

          <div class="text-right mt-2">
            <button class="btn btn-outline-primary w-100" type="submit" >Upload Log Book</button>
          </div>
        </form>
    
    
        <div class="form-group" *ngIf="isUploading">
          <h5 class="text-muted border-bottom">Tail# {{selectedAircraft.tailNo}}: Uploading {{uploadLogBookForm.get('type').value | uppercase }} file.</h5>
          <div class="p-3 rounded bg-light border">
            <div *ngFor="let file of files">
              <app-uploader-task [tailNo]="this.selectedAircraft.tailNo" [docType]="uploadLogBookForm.get('type').value" [file]="file" (resetFormEvent)="resetUploadForm()"></app-uploader-task>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
  </div>


</div>
