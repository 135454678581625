<!--ORG Profile Header-->
<div class="org-profile-header mt-3">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="org-info d-flex align-items-center">
          <span class="org_avatar mr-2">
            <img
                alt=""
                src="assets/images/avatar_45.png"
            />
          </span>

        <div class="org-title">
          <small class="text-muted" *ngIf="organization && organization.createdAt">
            Account Since {{ organization.createdAt | date }} 
          </small>
          <h5 class="text-primary">
            {{ organization?.organizationName | uppercase }}
          </h5>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6" *ngIf="membersList[0]">
      <div class="owner-info">
        <h5 class="text-primary">
            {{filterUsersForAdmin().user?.firstName}}
            {{filterUsersForAdmin().user?.lastName}}
        </h5>
        <small class="text-muted w-100 d-block mb-2">
          <i class="la la-envelope"></i>
          {{filterUsersForAdmin()?.user?.emailAddress}}
        </small>
        <small class="text-muted w-100 d-block" *ngIf="membersList[0]">
          <i class="la la-phone"></i>
          {{ formatPhoneNumber(filterUsersForAdmin().user.mobilePhone) }}
        </small>
      </div>
    </div>
  </div>
</div>

