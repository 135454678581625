<!-- heading -->
<h4>Global Service Bulletins</h4>

<!-- sub heading-->
<h6 class="mb-0">
  <i class="la la-wrench "></i>
  Manage Service Bulletins
</h6>

<!-- sub sub heading -->
<small class="text-muted mt-1 mb-3">Create and Manage Global Service Bulletins</small>

<!-- bulletin list container -->
<section class="mt-3">
  <div class="card shadow border-0 admin-create-user">
    <!-- header -->
    <div class="card-header clearfix">
      Manage Service Bulletins
      <span class="float-right">
        <button class="btn btn-sm btn-outline-dark rounded" (click)="toggleInCreateNewBulletinMode()">
          <span *ngIf="!inCreateNewBulletinMode">New <i class="la la-plus"></i></span>
          <span *ngIf="inCreateNewBulletinMode">Cancel <i class="la la-times"></i></span>
        </button>
      </span>
    </div>

    <!-- body -->
    <div class="card-body">

      <!--NEW SERVICEBULLETIN-->
      <div class="card mb-3" *ngIf="inCreateNewBulletinMode">
        <!--<div class="card-header">
          New Service Bulletin
        </div>-->
        <div class="card-body">

          <app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

          <form [formGroup]="newServiceBulletinForm" *ngIf="!isWorking" (ngSubmit)="doCreateServiceBulletin()">
            <div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted required">Enter Service Bulletin name.</small>
                    <input type="text" formControlName="bulletinName" class="form-control" id="bulletin-name"
                           aria-describedby="firstname_help" placeholder="Service Bulletin Name">
                    <small class="form-text text-danger"
                           *ngIf="newServiceBulletinForm.get('bulletinName').invalid && (newServiceBulletinForm.get('bulletinName').dirty || newServiceBulletinForm.get('bulletinName').touched)">
                      Enter Bulletin Name
                    </small>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted required">Published Date.</small>
                    <div class="input-group">
                      <input class="form-control" formControlName="publishedDate" placeholder="mm/dd/yyyy"
                             name="publishedDate" ngbDatepicker #d="ngbDatepicker">
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                          <i class="la la-calendar-plus-o"></i>
                        </button>
                      </div>
                    </div>
                    <small class="form-text text-danger"
                           *ngIf="newServiceBulletinForm.get('publishedDate').invalid && (newServiceBulletinForm.get('publishedDate').dirty || newServiceBulletinForm.get('publishedDate').touched)">
                      Enter published date
                    </small>
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-12">
                  <div class="form-group">
                    <small class="form-text text-muted required">Enter Service Bulletin Subject.</small>
                    <input type="text" formControlName="subject" class="form-control" id="bulletinsubject"
                           placeholder="Service Bulletin subject">
                    <small class="form-text text-danger"
                           *ngIf="newServiceBulletinForm.get('subject').invalid && (newServiceBulletinForm.get('subject').dirty || newServiceBulletinForm.get('subject').touched)">
                      Enter Bulletin Subject
                    </small>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12">
                  <div class="form-group">
                    <small class="form-text text-muted required">Bulletin Type:</small>
                    <div class="input-group">
                      <select class="form-control" (change)="onSbTypeChange($event, 'new')" formControlName="adSbType"
                              name="adSbType">
                        <option *ngFor="let type of adSbTypeOptions.options" [value]="type.value"> {{type.label}}
                        </option>
                      </select>
                    </div>
                    <small class="form-text text-danger"
                           *ngIf="newServiceBulletinForm.get('adSbType').invalid && (newServiceBulletinForm.get('adSbType').dirty || newServiceBulletinForm.get('adSbType').touched)">
                      Select type
                    </small>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12">
                  <div class="form-group d-block">
                    <small class="form-text text-muted">
                      Find and add Models
                      <em * ngIf="modelSearching">..Searching...</em>
                    </small>
                    <input type="text" [readOnly]="newServiceBulletinForm.get('adSbType').invalid"
                           formControlName="modelFinder" class="form-control" id="modelFinderNew" #modelFinderNew
                           placeholder="Find and Add Models" [class.is-invalid]="modelSearchFailed"
                           [ngbTypeahead]="modelTypeHeadSearch" [editable]="false" [resultTemplate]="rt"
                           [inputFormatter]="typeHeadModelFormatter" (selectItem)="onTypeHeadSelected($event)">

                    <ng-template #rt let-r="result" let-t="term">
                      <ngb-highlight [result]="getHighlightDisplayName(r)" [term]="t"></ngb-highlight>
                    </ng-template>
                  </div>


                  <div id="new_models" class="border-danger rounded p-3">
                    <span *ngFor="let model of sbNewModels; let i = index;" class="badge badge-secondary mr-3">
                      {{ model.modelName }} <em>* {{getModelManufacturerName(model)}}</em>
                      <a href="javascript: void(0)" (click)="removeModelFromNewList(i)"
                         class="btn-sm btn btn-danger ml-2 mb-1">
                        <i class="la la-trash"></i>
                      </a>
                    </span>
                  </div>

                </div>

                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <small class="form-text text-muted">Mandatory?:</small>
                    <div class="input-group">
                      <select class="form-control" formControlName="mandatory" name="mandatory">
                        <option value="false"
                                [selected]=" (newServiceBulletinForm.get('mandatory').value == 'false') || (!newServiceBulletinForm.get('mandatory').value) ">
                          NO
                        </option>
                        <option value="true"
                                [selected]=" (newServiceBulletinForm.get('mandatory').value == 'true') || newServiceBulletinForm.get('mandatory').value ">
                          YES
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <small class="form-text text-muted">Recurring?:</small>
                    <div class="input-group">
                      <select class="form-control" formControlName="isRecurring" name="isRecurring">
                        <option value="false"
                                [selected]=" (newServiceBulletinForm.get('isRecurring').value == 'false') || (!newServiceBulletinForm.get('isRecurring').value) ">
                          NO
                        </option>
                        <option value="true"
                                [selected]=" (newServiceBulletinForm.get('isRecurring').value == 'true') || newServiceBulletinForm.get('isRecurring').value ">
                          YES
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="text-right mt-2">
              <button class="btn btn-outline-danger mr-3" (click)="toggleInCreateNewBulletinMode()">Cancel</button>
              <button class="btn btn-outline-primary">Create Service Bulletin</button>
            </div>
          </form>
        </div>
      </div>

      <!----ALL SERVICE BULLETINS-->
      <div class="card">
        <!-- header -->
        <div class="card-header">
          Available Service Bulletins
        </div>

        <!-- body -->
        <div class="card-body">
          <!-- filter -->
          <form [formGroup]="serviceBulletinSearchForm">
            <div class="form-group form-inline">
              Filter:
              <input class="form-control ml-2" formControlName="queryString" type="text" name="query"/>
              <span class="ml-3" *ngIf="bulletinsLoading">Loading...</span>
            </div>
          </form>

          <app-preloader-type-a *ngIf="bulletinsLoading"></app-preloader-type-a>

          <!-- bulletin item -->
          <table class="table table-striped" *ngIf="!bulletinsLoading">
            <tbody>
            <tr
              *ngFor="let bulletin of allServiceBulletins; let i = index;">
              <td>
                <!-- number -->
                <h6 class="clearfix mb-1 text-primary">
                  {{ ((page - 1) * pageSize) + (i + 1) }}:
                  <ngb-highlight [result]="bulletin.bulletinName"></ngb-highlight>

                  <!-- is recurring -->
                  <a class="text-info mx-2 h5" *ngIf="bulletin.isRecurring" href="javascript: void(0)"
                     ngbPopover="Recurring Service Bulletin" triggers="mouseenter:mouseleave"
                     popoverTitle="isRecurring">
                    <i class="la la-refresh" popoverClass="bg-dark text-light"></i>
                  </a>

                  <!-- mandatory -->
                  <a class="text-warning mr-2 h5" *ngIf="bulletin.mandatory" href="javascript: void(0)"
                     ngbPopover="Mandatory Service Bulletin" triggers="mouseenter:mouseleave" popoverTitle="Mandatory">
                    <i class="la la-star" popoverClass="bg-dark text-light"></i>
                  </a>

                  <!-- buttons -->
                  <span class="float-right">
                      <!-- edit button -->
                      <button class="btn btn-outline-dark rounded btn-sm mr-3" (click)="loadBulletinForEdit(bulletin)"
                              *ngIf="bulletin.id !== editServiceBulletin.id">
                        <i class="la la-edit"></i>
                      </button>
                      <button class="btn btn-outline-dark rounded btn-sm mr-3" (click)="unLoadBulletinForEdit()"
                              *ngIf="bulletin.id == editServiceBulletin.id">
                        <i class="la la-times"></i>
                      </button>

                    <!-- delete button -->
                      <button class="btn btn-outline-danger rounder btn-sm" (click)="deleteServiceBulletin(bulletin)">
                        <i class="la la-trash"></i>
                      </button>
                    </span>
                </h6>


                <div class="row">
                  <!-- manufacturers -->
                  <div class="col-12 mb-1 pr-2">
                    <small class="text-muted">Manufacturers:</small>
                    {{getBulletinModelManufacturers(bulletin)}}
                  </div>

                  <!-- make -->
                  <div class="col-12 mb-1 pr-2">
                    <small class="text-muted">Make:</small>
                    {{ getBulletinModelMakes(bulletin) }}
                  </div>

                  <!-- subject -->
                  <div class="col-12 mb-1 pr-2">
                    <small class="text-muted">Subject:</small>
                    {{bulletin.subject ? bulletin.subject : '---- '}}
                  </div>

                  <!-- published date -->
                  <div class="col-12 col-sm-12 col-md-4">
                    <small class="d-block text-muted">
                      Published Date
                    </small>

                    {{bulletin.publishedDate | date:'MM/dd/yyyy'}}

                    <!--  'is new' marker -->
                    <small *ngIf="getSixMonthsPrior(bulletin.publishedDate)"  class="badge badge-primary mr-3" style="font-size: x-small">({{ getSixMonthsPrior(bulletin.publishedDate) ? 'new' : '' }})</small>
                  </div>
                  <!-- type -->
                  <div class="col-12 col-sm-12 col-md-4">
                    <small class="d-block text-muted">
                      Type
                    </small>
                    {{bulletin.adSbType | uppercase}}
                  </div>

                  <!-- View SB Document View Link-->
                  <div class="col-12 col-sm-12 col-md-4">
                    <button (click)="openModal(content, bulletin.fileUrl, bulletin.bulletinName)"
                            class="btn btn-danger btn-sm rounded" *ngIf="bulletin.fileUrl">
                      <small class="d-block text-white">
                        <i class="la la-file-pdf-o"></i>
                        View Document
                      </small>
                    </button>

                    <span class="badge badge-light rounded p-2" *ngIf="!bulletin.fileUrl">
                        <small class="d-block text-muted">
                          <i class="la la-file-pdf-o"></i>
                          No Document
                        </small>
                      </span>
                  </div>

                  <!--Models on Bulletin-->
                  <div class="col-12 col-sm-12 col-md-12">
                    <small class="d-block text-muted">
                      Models
                    </small>
                    <!--{{bulletin.ad_sb_manufacturer?.manufacturerName}}-->
                    <ng-container>
                        <span *ngFor="let model of bulletin.aircraftModels; let i = index;"
                              class="badge badge-primary mr-3">
                          {{ model.modelName }}
                        </span>
                    </ng-container>

                    <ng-container>
                        <span *ngFor="let model of bulletin.engineModels; let i = index;"
                              class="rounded badge badge-primary mr-3">
                          {{ model.modelName }}
                        </span>
                    </ng-container>

                    <ng-container>
                        <span *ngFor="let model of bulletin.propellerModels; let i = index;"
                              class="badge badge-primary mr-3">
                          {{ model.modelName }}
                        </span>
                    </ng-container>

                    <ng-container>
                        <span *ngFor="let model of bulletin.applianceModels; let i = index;"
                              class="badge badge-primary mr-3">
                          {{ model.modelName }}
                        </span>
                    </ng-container>
                    
                  </div>
                </div>
                <!--EDIT SERVICEBULLETIN-->
                <div class="card mb-3 border-danger" *ngIf="bulletin.id == editServiceBulletin.id">
                  <div class="card-body">

                    <app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

                    <form [formGroup]="editServiceBulletinForm" *ngIf="!isWorking" #dfff
                          (ngSubmit)="doUpdateServiceBulletin()">
                      <div>
                        <div class="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="form-group">
                              <small class="form-text text-muted required">Update Service Bulletin name.</small>
                              <input type="text" formControlName="bulletinName" class="form-control"
                                     id="bulletin-name" aria-describedby="firstname_help" placeholder="Bulletin Name">
                              <small class="form-text text-danger"
                                     *ngIf="editServiceBulletinForm.get('bulletinName').invalid && (editServiceBulletinForm.get('bulletinName').dirty || editServiceBulletinForm.get('bulletinName').touched)">
                                Enter Bulletin Name
                              </small>
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-6">
                            <div class="form-group">
                              <small class="form-text text-muted required">Published Date.</small>
                              <div class="input-group">
                                <input class="form-control" formControlName="publishedDate" placeholder="mm/dd/yyyy"
                                       name="publishedDate" ngbDatepicker #d="ngbDatepicker">
                                <div class="input-group-append">
                                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                          type="button">
                                    <i class="la la-calendar-plus-o"></i>
                                  </button>
                                </div>
                                <small class="form-text text-danger"
                                       *ngIf="editServiceBulletinForm.get('publishedDate').invalid && (editServiceBulletinForm.get('publishedDate').dirty || editServiceBulletinForm.get('publishedDate').touched)">
                                  Enter published Date
                                </small>
                              </div>
                            </div>
                          </div>

                          <div class="col-12 col-sm-12 col-md-12">
                            <div class="form-group">
                              <small class="form-text text-muted required">Update Service Bulletin Subject.</small>
                              <input type="text" formControlName="subject" class="form-control" id="bulletin-subject"
                                     placeholder="Bulletin subject">
                              <small class="form-text text-danger"
                                     *ngIf="editServiceBulletinForm.get('subject').invalid && (editServiceBulletinForm.get('subject').dirty || editServiceBulletinForm.get('subject').touched)">
                                Enter Bulletin Subject
                              </small>
                            </div>
                          </div>


                          <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                              <small class="form-text text-muted required">Update Bulletin Type:</small>
                              <div class="input-group">
                                <select class="form-control" (change)="onSbTypeChange($event, 'edit')"
                                        formControlName="adSbType" name="adSbType">
                                  <option *ngFor="let type of adSbTypeOptions.options" [value]="type.value">
                                    {{type.label}}</option>
                                </select>
                              </div>
                              <small class="form-text text-danger"
                                     *ngIf="editServiceBulletinForm.get('adSbType').invalid && (editServiceBulletinForm.get('adSbType').dirty || editServiceBulletinForm.get('adSbType').touched)">
                                Select Type
                              </small>
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-12">
                            <div class="form-group d-block">
                              <small class="form-text text-muted">
                                Find and add Models
                                <em * ngIf="modelSearching">..Searching...</em>
                              </small>
                              <input type="text" [readOnly]="editServiceBulletinForm.get('adSbType').invalid"
                                     formControlName="modelFinder" class="form-control" id="modelFinderEdit" #modelFinderEdit
                                     placeholder="Find and add Models" [class.is-invalid]="modelSearchFailed"
                                     [ngbTypeahead]="modelTypeHeadSearch" [editable]="false" [resultTemplate]="rtEdit"
                                     [inputFormatter]="typeHeadModelFormatter"
                                     (selectItem)="onTypeHeadEditSelected($event)">

                              <ng-template #rtEdit let-r="result" let-t="term">
                                <ngb-highlight [result]="getHighlightDisplayName(r)" [term]="t"></ngb-highlight>
                              </ng-template>
                            </div>

                            <div id="new_models" class="border-danger rounded p-3">
                                <span *ngFor="let model of sbEditModels; let i = index;"
                                      class="badge badge-secondary mr-3">
                                  {{ model.modelName }} <em>* {{getModelManufacturerName(model)}}</em>
                                  <a href="javascript: void(0)" (click)="removeModelFromEditList(i)"
                                     class="btn-sm btn btn-danger ml-2 mb-1">
                                    <i class="la la-trash"></i>
                                  </a>
                                </span>
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-3">
                            <div class="form-group">
                              <small class="form-text text-muted">Mandatory?:</small>
                              <div class="input-group">
                                <select class="form-control" formControlName="mandatory" name="mandatory">
                                  <option value="false"
                                          [selected]=" (editServiceBulletinForm.get('mandatory').value == 'false') || (!editServiceBulletinForm.get('mandatory').value) ">
                                    NO
                                  </option>
                                  <option value="true"
                                          [selected]=" (editServiceBulletinForm.get('mandatory').value == 'true') || editServiceBulletinForm.get('mandatory').value ">
                                    YES
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-3">
                            <div class="form-group">
                              <small class="form-text text-muted">Recurring?:</small>
                              <div class="input-group">
                                <select class="form-control" formControlName="isRecurring" name="isRecurring">
                                  <option value="false"
                                          [selected]=" (editServiceBulletinForm.get('isRecurring').value == 'false') || (!editServiceBulletinForm.get('isRecurring').value) ">
                                    NO
                                  </option>
                                  <option value="true"
                                          [selected]=" (editServiceBulletinForm.get('isRecurring').value == 'true') || editServiceBulletinForm.get('isRecurring').value ">
                                    YES
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="text-right mt-2">
                        <button class="btn btn-outline-danger mr-3" (click)="unLoadBulletinForEdit()">Cancel</button>
                        <button type="submit" class="btn btn-outline-primary">Update Service Bulletin</button>
                      </div>
                    </form>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>

          <!--  pagination  -->
          <div class="d-flex justify-content-between p-2">
            <ngb-pagination [collectionSize]="total" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [rotate]="true"
                            [ellipses]="false" [boundaryLinks]="true" (pageChange)="onPaginationChange($event)">
            </ngb-pagination>

            <label>
              <select class="custom-select" style="width: auto" (change)="changePageSize($event)">
                <option [value]="20">20 items per page</option>
                <option [value]="50">50 items per page</option>
                <option [value]="100">100 items per page</option>
              </select>
            </label>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>


<!--PDF VIEWER--->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{viewedPdfSbTitle}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-pdf-viewer [pdfSrc]="viewedPdfSrc"></app-pdf-viewer>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>
