<table class="table spaced_rows table-responsive">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">Phone</th>
      <th scope="col">Pilot Cert.</th>
      <th scope="col">Flight Review</th>
      <th scope="col">Medical</th>
      <th scope="col">Customer since</th>
      <th scope="col">Actions</th>
    </tr>
  </thead>
  <tbody>
    <!--- Results for search by user name or email-->
    <ng-container *ngFor="let member of membersList; let i = index;">
      <!--- Single row for single aircraft owners-->
      <tr>
        <th scope="row">{{i+1}}</th>
        <td class="align-middle">
          <div class="d-flex w-100 align-items-center">
            {{member.firstName}} {{ member?.lastName }}
          </div>
        </td>
        <td>{{ member?.emailAddress }}</td>
        <td>{{ formatPhoneNumber(member.mobilePhone) }}</td>
        <td>{{ member?.pilotLicenseNo }}</td>
        <td>{{ member?.flightReviewDueDate | date }}</td>
        <td>{{ member?.medicalDueDate | date }}</td>
        <td>
          {{ member?.createdAt | date }}
        </td>
        <td>
          <ng-container *ngIf="member.id">
            <a [routerLink]="['/cga/admin/edit-member/', member.emailAddress]" class="btn btn-sm mr-1 btn-orange rounded">
              <i class="la la-user"></i> Edit
            </a>
          </ng-container>
        </td>
      </tr>
    </ng-container>

  </tbody>
</table>
