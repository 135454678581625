import { OrganizationTypeOptionsModel } from './../models/organization-type-options-model';
import { UserMenuAircraftModel } from './../models/user-menu-aircraft-model';
import { AircraftService } from './aircraft.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { OrganizationTypes } from '../models/organization-types';
import { UserOrganizationAndRole } from '../models/user-organization-and-role';
import { OrganizationV2Model } from '../models/organization/organization-v2.model';
import { AircraftV2Model } from '../models/airfcraftV2/aircraft-v2.model';
import { AlertPopUpsService } from './alert-pop-ups.service';

interface AttachedUserBodyReqI {
  firstName: string;
  lastName: string;
  pilotLicenseNo?: string;
  organizationUserRole: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  // Subject and Observables for Selected Organization's Index
  private selectedOrg: UserOrganizationAndRole = new UserOrganizationAndRole();
  private _selectedOrg: BehaviorSubject<
    UserOrganizationAndRole
  > = new BehaviorSubject(this.selectedOrg);
  selectedOrg$: Observable<
    UserOrganizationAndRole
  > = this._selectedOrg.asObservable();

  // Subject and Observables for User Organizations Array
  private userOrganizations: Array<UserOrganizationAndRole> = [];
  private _userOrganizations: BehaviorSubject<
    Array<UserOrganizationAndRole>
  > = new BehaviorSubject(this.userOrganizations);
  userOrganizations$: Observable<
    Array<UserOrganizationAndRole>
  > = this._userOrganizations.asObservable();

  constructor(
    private httpClient: HttpClient,
    private aircraftService: AircraftService,
    private popUpService: AlertPopUpsService
  ) {}

  resetUserMenuOrg() {
    this.userOrganizations = [];
    this.selectedOrg = new UserOrganizationAndRole();

    this._userOrganizations.next(this.userOrganizations);
    this._selectedOrg.next(this.selectedOrg);
  }

  /**
   * Service function for getting this user's fetched Organizations in Menu
   * @param emailAddress
   */
  getUserOrganizationsMenuList(emailAddress: string) {
    if (this.userOrganizations.length > 1) {
      this._userOrganizations.next(this.userOrganizations);
    } else {
      /**TODO: INITIATE A LOADER SERVICE */
      this.getOrganizationsByUserV2(emailAddress).subscribe(
        (response: any) => {
          this.userOrganizations = response;
          this._userOrganizations.next(this.userOrganizations);

          if (this.userOrganizations.length > 0) {
            // get org stored in local storage
            const storedOrgId = localStorage.getItem(environment.selectedOrgKey);
            let storedOrgIndex = null;

            // if stored org exists...
            if (storedOrgId) {
              // find index of stored org in user's org array
              const foundOrg = this.userOrganizations.some(
                (org, i) => {
                  if (+org.organization.id === +storedOrgId) {
                    storedOrgIndex = i;
                    return true;
                  } else {
                    return false;
                  }
                }
              );
            }

            // if found storedOrgIndex...
            if (storedOrgIndex !== null) {
              // set found org index
              this.setSelectedOrganization(this.userOrganizations[storedOrgIndex]);
            } else {
              // else set first org in array since stored or is not in user array
              this.setSelectedOrganization(this.userOrganizations[0]);
            }
          } else {
            this.setSelectedOrganization(new UserOrganizationAndRole());
          }
        },
        (error: any) => {
          /**
           * TODO: HANDLE ERROR
           */
        }
      );
    }
  }

  setSelectedOrganization(
    org: UserOrganizationAndRole,
    updateSelectedAircraft: boolean = true
  ) {
    this.selectedOrg = org;
    this._selectedOrg.next(this.selectedOrg);
    localStorage.setItem(environment.selectedOrgKey, this.selectedOrg.organization.id.toString());

    if (this.selectedOrg.organization && this.selectedOrg.organization.id) {
      this.aircraftService.updateUserAircraftMenuList(this.selectedOrg.organization.id, updateSelectedAircraft);
    }
  }

  /**
   * Run checks if it needs to change selected or, of find and load selected org in list;
   * @param aircraft
   */
  doAircraftOrgSelectCheck(aircraft: AircraftV2Model) {
    if (!this.checkAircraftHasSelectedOrg(aircraft)) {
      this.findAircraftOrgInUserOrgs(aircraft);
    }
  }

  /**
   * Check if the Aircraft is alredy in selected org
   * @param aircraft
   */
  checkAircraftHasSelectedOrg(aircraft: AircraftV2Model) {
    return aircraft.organizations.some(
      (org: OrganizationV2Model) => {
        return (+org.id === +this.selectedOrg.organization.id);
      }
    );
  }

  /**
   * Find aircraft org in user menu organizations and set as selected
   * @param aircraft
   */
  findAircraftOrgInUserOrgs(aircraft: AircraftV2Model) {
    let aircraftModelOrg: OrganizationV2Model;

    console.log('...finding selected aircraft org in menu');

    if (aircraft.id && aircraft.organizations.length < 1) {
      // Aircraft V2 Model has no organizations array property
      console.log('Aircraft model is not attached to any organization');
      this.popUpService.openBasicPopUp(
        'Ooops',
        'This aircraft is not attached to any organization',
        'error'
      );
      return;
    }

    const foundOwnerOrg: boolean =  aircraft.organizations.some(
      (org: OrganizationV2Model) => {
        aircraftModelOrg = org;
        return (org.role.toUpperCase() === 'OWNER');
      }
    );

    if (foundOwnerOrg) {
      this.findAndLoadOrgInlist(aircraftModelOrg);
    } else {
      this.findAndLoadOrgInlist(aircraft.organizations[0]);
    }
  }

  findAndLoadOrgInlist(aircraftModelOrg: OrganizationV2Model) {
    this.userOrganizations.some(
      (org: UserOrganizationAndRole) => {
        if (
            (org.organization && org.organization.id) &&
            (aircraftModelOrg && aircraftModelOrg.id) &&
            (+org.organization.id === +aircraftModelOrg.id)
          ) {
          this.setSelectedOrganization(org, false);
          return true;
        } else {
          return false;
        }
      }
    );
  }

  // Organization CRUD
  /**
   * List All Organizations
   */
  listOrganizationsV2() {
    return this.httpClient.get(environment.apiBaseUrl + `/v2/organization`);
  }

  /**
   * Create an Organization
   */
  createOrganizationV2(orgType: OrganizationTypes, orgName: string) {
    return this.httpClient.post(environment.apiBaseUrl + '/v2/organization', {
      organizationType: orgType,
      organizationName: orgName
    });
  }

  /**
   * Get an Organization by Id
   */
  getOrganizationV2(orgId: number) {
    return this.httpClient.get(
      environment.apiBaseUrl + `/v2/organization/${orgId}`
    );
  }

  /**
   * Update an Organization
   */
  updateOrganizationV2(
    orgId: number,
    orgName: string,
    orgType: OrganizationTypes
  ) {
    return this.httpClient.put(
      environment.apiBaseUrl + `/v2/organization/${orgId}`,
      {
        organizationName: orgName,
        organizationType: orgType
      }
    );
  }

  /**
   * Delete an Organization by Id
   */
  deleteOrganizationV2(orgId: number) {
    return this.httpClient.delete(
      environment.apiBaseUrl + `/v2/organization/${orgId}`
    );
  }

  // Users and Organizations assignment
  /**
   * Get users on an Organization by Id
   */
  getUsersByOrganizationV2(orgId: number) {
    return this.httpClient.get(
      environment.apiBaseUrl + `/v2/organization/${orgId}/user`
    );
  }

  /**
   * Attach user to an Organization
   */
  attachUserToOrganizationV2(
    orgId: number,
    email: string,
    reqBody: AttachedUserBodyReqI
  ) {
    return this.httpClient.put(
      environment.apiBaseUrl + `/v2/organization/${orgId}/user/${email}`,
      reqBody
    );
  }

  /**
   * Detach user from an Organization
   */
  detachUserFromOrganizationV2(orgId: number, email: string) {
    return this.httpClient.delete(
      environment.apiBaseUrl + `/v2/organization/${orgId}/user/${email}`
    );
  }

  /**
   * get user's Organizations
   */
  getOrganizationsByUserV2(emailAddress: string) {
    return this.httpClient.get(
      environment.apiBaseUrl + `/v2/user/${emailAddress}/organization`
    );
  }

  /**EXTRAS */
  getOrgTypeLabelText(type: string) {
    const optionLabels: OrganizationTypeOptionsModel = new OrganizationTypeOptionsModel();

    let typeLabel = '';

    optionLabels.options.some(element => {
      if (element.value === type.toUpperCase()) {
        typeLabel = element.label;
        return true;
      } else {
        return false;
      }
    });

    return typeLabel;
  }

  filterAircraftOrgsForOwnerV2Model(OrgsArray: Array<OrganizationV2Model>) {
    let ownerOrg = new OrganizationV2Model();

    OrgsArray.some((el, i) => {
      if (el.role && el.role.toUpperCase() === 'OWNER') {
        ownerOrg = el;

        return true;
      } else {
        return false;
      }
    });

    return ownerOrg;
  }


  findAircraftOrgbyType(theAircraft: AircraftV2Model, type: string) {
    let foundOrg: OrganizationV2Model = new OrganizationV2Model;

    theAircraft.organizations.some(
      (org) => {
        if (org.role) {
          const isType = org.role.toString().toLowerCase() === type.toLowerCase();

          if (isType) {
            foundOrg = org;
          }

          return isType;

        } else {
          return false;
        }
      }
    );

    return foundOrg;
  }

}
