export const environment = {
  production: false,
  environmentName: 'QA',
  environmentColor: 'success',
  apiBaseUrl: 'https://us-central1-crewchief-demo.cloudfunctions.net/cga-qa-api/api',
  tokenKey: 'qa_id_token',
  refreshTokenKey: 'qa_refresh_token',
  selectedAircraftKey: 'qa_sel_aircraft',
  selectedOrgKey: 'qa_sel_org',
  oldestDate: '1900-01-01',
  buildTime: 'TT1585673132677TT',
  firebase: {
    apiKey: 'AIzaSyBMj65YkswUykGToLxJnkCwTuXy20xx1JM',
    authDomain: 'crewchief-ga-qa.firebaseapp.com',
    databaseURL: 'https://crewchief-ga-qa.firebaseio.com',
    projectId: 'crewchief-ga-qa',
    storageBucket: 'crewchief-ga-qa.appspot.com',
    messagingSenderId: '72311143305',
    appId: '1:72311143305:web:e21849b1f7453695'
  }
};
