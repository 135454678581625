export class NavDataUpdateModel{
  constructor(){
    this.id = null;
    this.equipmentName = null;
    this.aircraftId= null;
    this.editable= false;
  }

  id: number;
  equipmentName: string;
  //dataSet: string;
  cycle: number;
  expireDate: string;
  effectiveDate: string;
  signInitials: string;
  aircraftId: number;
  editable: boolean
}
