import { Router } from '@angular/router';
import { MAircraftAnnualHoursModel } from '../../../../models/m-aircraft-annual-hours-model';
import { NgbDateCustomAndUSAParserFormatter } from './../../../../services/ngb-date-custom-and-usa-parser-formatter';
import { UserService } from './../../../../services/user.service';
import { UserModel } from './../../../../models/user-model';
import { UtilitiesService } from './../../../../services/utilities.service';
import { FullAircraftMaintenanceStatModel } from './../../../../models/full-aicraft-maintenance-stats-model';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AircraftMaintenanceService } from './../../../../services/aircraft-maintenance.service';
import { MAircraftTranspondersModel } from './../../../../models/m-aircraft-transponders-model';
import { MAircraftPitoticsModel } from './../../../../models/m-aircraft-pitotics-model';
import { MAircraftOilChangesModel } from './../../../../models/m-aircraft-oil-changes-model';
import { MAircraftEltsModel } from './../../../../models/m-aircraft-elts-model';
import { MAircraftAltimetersModel } from './../../../../models/m-aircraft-altimeters-model';
import { MAircraft100hoursModel } from './../../../../models/m-aircraft-100hours-model';
import { MAircraftAnnualsModel } from './../../../../models/m-aircraft-annuals-model';
import { Component, OnInit, Input, SimpleChanges, OnDestroy, OnChanges } from '@angular/core';
import { Observable, SubscriptionLike } from 'rxjs';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { NumberToTenths } from '../../../../_custom-validators/number-to-tenths-validator';
import { Location } from '@angular/common';
import { AircraftService } from '../../../../services/aircraft.service';
import { AircraftV2Model } from '../../../../models/airfcraftV2/aircraft-v2.model';

@Component({
  selector: 'app-manage-maintenance',
  templateUrl: './manage-maintenance.component.html',
  styleUrls: ['./manage-maintenance.component.scss'],
  providers: [
    NgbDatepickerConfig,
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomAndUSAParserFormatter}
  ]
})
export class ManageMaintenanceComponent implements OnInit, OnDestroy, OnChanges {
  @Input() aircraftId: number;
  @Input() aircraftTailNo: string;
  @Input() tabChanges: Observable<void>;
  @Input() isCustomerView: boolean;
  @Input() viewSinglePageOnly: boolean;

  constructor(
    private ngbDatePickerConfig: NgbDatepickerConfig,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private aircraftService: AircraftService,
    private maintenanceService: AircraftMaintenanceService,
    private utilitiesService: UtilitiesService,
    private location: Location,
    private router: Router
  ) {
    ngbDatePickerConfig.minDate = {year: 1900, month: 1, day: 1};
   }

  currentUser$: SubscriptionLike;
  currentUser: UserModel = new UserModel;

  tabChangesSubscription$: SubscriptionLike;
  inUpdateMode: boolean;

  mLast100Hours: MAircraft100hoursModel = new MAircraft100hoursModel;
  mLast100HoursWorking: boolean;

  mAltimeters: MAircraftAltimetersModel = new MAircraftAltimetersModel;
  mAltimetersWorking: boolean;

  mAnnuals: MAircraftAnnualsModel = new MAircraftAnnualsModel;
  mAnnualsWorking: boolean;

  mAnnualsHours: MAircraftAnnualHoursModel = new MAircraftAnnualHoursModel;
  mAnnualHoursWorking: boolean;

  mElts: MAircraftEltsModel = new MAircraftEltsModel;
  mEltsWorking: boolean;

  mOilChanges: MAircraftOilChangesModel = new MAircraftOilChangesModel;
  mOilChangesWorking: boolean;

  mPitotics: MAircraftPitoticsModel = new MAircraftPitoticsModel;
  mPitoticsWorking: boolean;

  mTransponders: MAircraftTranspondersModel = new MAircraftTranspondersModel;
  mTranspondersWorking: boolean;

  updateAllMaintenanceStatsForm: FormGroup;

  ngOnInit() {

    this.mLast100HoursWorking = false;
    this.inUpdateMode = false;
    this.mAltimetersWorking = false;
    this.mAnnualsWorking = false;
    this.mAnnualHoursWorking = false;
    this.mOilChangesWorking = false;
    this.mPitoticsWorking = false;
    this.mTranspondersWorking = false;

    if (!this.viewSinglePageOnly) {

      // add tab parameter in url
      const fullUrl: Array<string> = this.router.url.split('?');
      if (fullUrl[0]) {
        this.location.go( fullUrl[0] + '?t=maintenance');
      }
    }

    this.currentUser$ = this.userService.currentUser$.subscribe(
      (user) => {
        this.currentUser = user;
      }
    );

    this.initPage();
  }

  initPage() {
    this.updateAllMaintenanceStatsForm = this.formBuilder.group({
      // 'last100Hours': new FormControl('', [Validators.required, NumberToTenths(false)]),
      'last100Hours': new FormControl('', [ NumberToTenths(true)]),
      'altimeters': new FormControl('', [Validators.required]),
      'annuals': new FormControl('', [Validators.required]),
      'annualHours': new FormControl('', [ NumberToTenths(true)]),
      'elts': new FormControl('', [Validators.required]),
      'oilChanges': new FormControl('', [Validators.required, NumberToTenths(false)]),
      'pitotics': new FormControl(''),
      'transponders': new FormControl('', [Validators.required]),
    });

    if (this.tabChanges) {
      this.tabChangesSubscription$ = this.tabChanges.subscribe(
        () => {
          // do something on Parent tab change
          this.updateAllMaintenanceStatsForm.reset();
        }
      );
    }

    if (this.aircraftId !== null) {
      this.getAllMaintenanceStats();
    }
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.aircraftId  && changes.aircraftId.currentValue) {
      this.aircraftId = changes.aircraftId.currentValue;
      this.initPage();
    }

    if (changes.aircraftTailNo  && changes.aircraftTailNo.currentValue) {
      this.aircraftTailNo = changes.aircraftTailNo.currentValue;
    }
  }

  getAllMaintenanceStats() {
    this.getLastMAircraft100hours();
    this.getLastMAircraftAltimeters();
    this.getLastMAircraftAnnuals();
    this.getLastMAircraftAnnualHours();
    this.getLastMAircraftElts();
    this.getLastMAircraftOilChanges();
    this.getLastMAircraftPitotics();
    this.getLastMAircraftTransponders();
  }

  getLastMAircraft100hours() {
    this.mLast100HoursWorking = true;
    this.maintenanceService.getLastMAircraft100hours(this.aircraftId).subscribe(
      (response: any) => {
        this.mLast100HoursWorking = false;
        this.mLast100Hours = response.responseData !== null ? response.responseData : '';
      },
      (error) => {
        this.mLast100HoursWorking = false;
      }
    );
  }

  getLastMAircraftAltimeters() {
    this.mAltimetersWorking = true;
    this.maintenanceService.getLastMAircraftAltimeters(this.aircraftId).subscribe(
      (response: any) => {
        this.mAltimetersWorking = false;
        this.mAltimeters = response.responseData !== null ? response.responseData : '';
      },
      (error) => {
        this.mAltimetersWorking = false;
      }
    );
  }

  getLastMAircraftAnnuals() {
    this.mAnnualsWorking = true;
    this.maintenanceService.getLastMAircraftAnnuals(this.aircraftId).subscribe(
      (response: any) => {
        this.mAnnualsWorking = false;
        this.mAnnuals = response.responseData !== null ? response.responseData : '';
      },
      (error) => {
        this.mAnnualsWorking = false;
      }
    );
  }

  getLastMAircraftAnnualHours() {
    this.mAnnualHoursWorking = true;
    this.maintenanceService.getLastMAircraftAnnualHours(this.aircraftId).subscribe(
      (response: any) => {
        this.mAnnualHoursWorking = false;
        this.mAnnualsHours = response.responseData !== null ? response.responseData : '';
      },
      (error) => {
        this.mAnnualHoursWorking = false;
      }
    );
  }

  getLastMAircraftElts() {
    this.mEltsWorking = true;
    this.maintenanceService.getLastMAircraftElts(this.aircraftId).subscribe(
      (response: any) => {
        this.mEltsWorking = false;
        this.mElts = response.responseData !== null ? response.responseData : '';
      },
      (error) => {
        this.mEltsWorking = false;
      }
    );
  }

  getLastMAircraftOilChanges() {
    this.mOilChangesWorking = true;
    this.maintenanceService.getLastMAircraftOilChanges(this.aircraftId).subscribe(
      (response: any) => {
        this.mOilChangesWorking = false;
        this.mOilChanges = response.responseData !== null ? response.responseData : '';
      },
      (error) => {
        this.mOilChangesWorking = false;
      }
    );
  }

  getLastMAircraftPitotics() {
    this.mPitoticsWorking = true;
    this.maintenanceService.getLastMAircraftPitotics(this.aircraftId).subscribe(
      (response: any) => {
        this.mPitoticsWorking = false;
        this.mPitotics = response.responseData !== null ? response.responseData : '';
      },
      (error) => {
        this.mPitoticsWorking = false;
      }
    );
  }

  getLastMAircraftTransponders() {
    this.mTranspondersWorking = true;
    this.maintenanceService.getLastMAircraftTransponders(this.aircraftId).subscribe(
      (response: any) => {
        this.mTranspondersWorking = false;
        this.mTransponders = response.responseData !== null ? response.responseData : '';
      },
      (error) => {
        this.mTranspondersWorking = false;
      }
    );
  }

  initPostAllMaintenanceStats() {
    this.updateAllMaintenanceStatsForm.controls['last100Hours'].setValue(this.mLast100Hours ? this.mLast100Hours.last100Hours : null);
    this.updateAllMaintenanceStatsForm.controls['altimeters'].setValue(
      this.mAltimeters.lastAltimeterDate ? this.utilitiesService.parseISOtoDateObj(this.mAltimeters.lastAltimeterDate) : null
    );
    this.updateAllMaintenanceStatsForm.controls['annuals'].setValue(
      this.mAnnuals.lastAnnualDate ? this.utilitiesService.parseISOtoDateObj(this.mAnnuals.lastAnnualDate) : null
    );
    this.updateAllMaintenanceStatsForm.controls['annualHours'].setValue(this.mAnnualsHours ? this.mAnnualsHours.lastAnnualHours : null);
    this.updateAllMaintenanceStatsForm.controls['elts'].setValue(
      this.mElts.lastEltDate ? this.utilitiesService.parseISOtoDateObj(this.mElts.lastEltDate) : null
    );
    this.updateAllMaintenanceStatsForm.controls['oilChanges'].setValue(this.mOilChanges.lastOilChangeHours);
    this.updateAllMaintenanceStatsForm.controls['pitotics'].setValue(
      this.mPitotics.lastPitoticDate ? this.utilitiesService.parseISOtoDateObj(this.mPitotics.lastPitoticDate) : null
    );
    this.updateAllMaintenanceStatsForm.controls['transponders'].setValue(
      this.mTransponders.lastTransponderDate ? this.utilitiesService.parseISOtoDateObj(this.mTransponders.lastTransponderDate) : null
    );
    this.inUpdateMode = true;
  }

  cancelPostAllMaintenanceStats() {
    this.updateAllMaintenanceStatsForm.reset();
    this.inUpdateMode = false;
  }

  postAllMaintenanceStats() {
    const formValid = this.utilitiesService.generalFormValidate(this.updateAllMaintenanceStatsForm);
    if (!formValid) {
      return;
    }

    const aircraftStats = new FullAircraftMaintenanceStatModel;

    aircraftStats.aircraftId = this.aircraftId;
    aircraftStats.last100Hours = this.updateAllMaintenanceStatsForm.controls['last100Hours'].value ?
        this.updateAllMaintenanceStatsForm.controls['last100Hours'].value : null;
    aircraftStats.lastAltimeterDate = this.updateAllMaintenanceStatsForm.controls['altimeters'].value;
    aircraftStats.lastAnnualDate = this.updateAllMaintenanceStatsForm.controls['annuals'].value;
    aircraftStats.lastAnnualHours = this.updateAllMaintenanceStatsForm.controls['annualHours'].value;
    aircraftStats.lastEltDate = this.updateAllMaintenanceStatsForm.controls['elts'].value;
    aircraftStats.lastOilChangeHours = this.updateAllMaintenanceStatsForm.controls['oilChanges'].value;
    aircraftStats.lastPitoticDate = this.updateAllMaintenanceStatsForm.controls['pitotics'].value;
    aircraftStats.lastTransponderDate = this.updateAllMaintenanceStatsForm.controls['transponders'].value;

    this.maintenanceService.postAllMaintenanceStats(aircraftStats).subscribe(
      (response: any) => {
        this.updateSelectedAircraft();

        if (+response.status.code === 1000) {
          this.inUpdateMode = false;
          this.updateAllMaintenanceStatsForm.reset();
          this.getAllMaintenanceStats();
          Swal.fire(
            'Great!', 'Aircraft Maintenance Stats Updated!', 'success'
          );
        } else {
          Swal.fire(
            'Yikes!', 'Aircarft Miantenance stats not updated.', 'error'
          );
        }

      },
      (error: any) => {

        if (error.status.message) {
          Swal.fire(
            error.status.message,
            error.responseData.join(' '),
            'error'
          );
        } else {
          Swal.fire(
            'Oops!',
            'Failed update Aircraft Maintenance Stats.',
            'error'
          );
        }
      }
    );
  }

  updateSelectedAircraft() {
    this.utilitiesService.updateShowMainPreloader(true);

    this.aircraftService.getAircraftV2(this.aircraftTailNo).subscribe(
      (aircraft: AircraftV2Model) => {
        this.utilitiesService.updateShowMainPreloader(false);
        this.aircraftService.setSelectedAircraft(aircraft);
      },
      (error: any) => {
        window.location.reload();
      }
    );
  }

  ngOnDestroy() {
    if (this.tabChangesSubscription$) {
      this.tabChangesSubscription$.unsubscribe();
    }
  }

}
