import { ValidatorFn, AbstractControl } from '@angular/forms';

export function HasDependantControlValidator (otherControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let forbidden: boolean = null;

    forbidden = control.value && !otherControl.value? true : null; 

    if(forbidden){
      otherControl.setErrors({'dependsOnControl': {value: otherControl.value}});
    }
    else{
      if (otherControl.hasError('dependsOnControl')) {
        delete otherControl.errors['dependsOnControl'];
        otherControl.updateValueAndValidity();
      }
    }
    
    return forbidden ? {'hasDependant': {value: control.value}} : null;
  };
}