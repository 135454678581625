import { OptionLabelsAndValuesModel } from './option-labels-and-values-model';

export class AdApplicationOptionsModel {
  constructor(){
    this.applications = [
      { label: 'Applies', value: 'Applies' },
      { label: 'Complied with', value: 'Complied' },
      { label: 'Does not apply', value: 'NotApply' }
    ]
  }
  applications: Array<OptionLabelsAndValuesModel>;
}