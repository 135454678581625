import { UserMenuAircraftModel } from '../../../models/user-menu-aircraft-model';
import { SubscriptionLike, Subject, of } from 'rxjs';
import { AircraftService } from './../../../services/aircraft.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { UserAircraftModel } from '../../../models/user-aircraft-model';
import { switchMap } from 'rxjs/operators';
import { AircraftV2Model } from '../../../models/airfcraftV2/aircraft-v2.model';

@Component({
  selector: 'app-aircraft-maintenance-history',
  templateUrl: './aircraft-maintenance-history.component.html',
  styleUrls: ['./aircraft-maintenance-history.component.scss']
})
export class AircraftMaintenanceHistoryComponent implements OnInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    private aircraftService: AircraftService,
  ) { }

  selectedAircraft: AircraftV2Model = new AircraftV2Model;
  selectedAircraft$: SubscriptionLike;

  aircraftIdParamsSubscriber$: SubscriptionLike;

  ngOnInit() {

    this.selectedAircraft$ = this.aircraftService.selectedAircraft$.subscribe(
      (aircraft: AircraftV2Model) => {
        this.selectedAircraft = aircraft;

      }
    );

    // if(this.aircraftId){
    //   this.theAircraft.id = this.aircraftId;

    //   this.getAircraft();
    // }
    // else{
    //   this.aircraftIdParamsSubscriber$ = this.route.paramMap
    //     .pipe(
    //       switchMap((params: ParamMap)=> of(params.get("aircraftId")))
    //   ).subscribe(
    //     (aircraftId)=>{
    //       this.theAircraft.id = +aircraftId;
    //       this.getAircraft();
    //     }
    //   )
    // }
  }


  ngOnDestroy() {
    if (this.aircraftIdParamsSubscriber$) {
      this.aircraftIdParamsSubscriber$.unsubscribe();
    }
  }
}
