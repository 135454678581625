import { ValidatorFn, AbstractControl } from '@angular/forms';

export function HasDependantIfControlIsControlValidator (otherControl: AbstractControl, ifValue:any): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let forbidden: boolean = null;

    forbidden = (control.value == ifValue) && !otherControl.value? true : null; 

    let errorKey = "hasDependantIfControlIs_" + ifValue;
    let errorObj = {};
    errorObj[errorKey] =  {value: control.value};

    if(!forbidden){
      if (otherControl.hasError('dependsOnControlIfControlIs')) {
        delete otherControl.errors['dependsOnControlIfControlIs'];
        otherControl.updateValueAndValidity();
      }
    }

    return forbidden ? errorObj : null;
    
  };
}