import { AircraftLogBookTypeModel } from '../../../models/aircraft-logbook-type-model';
import { AircraftLogBookService } from './../../../services/aircraft-log-book.service';
import { AircraftLogBookCarouselItemModel } from './../../../models/aircraft-logbook-carousel-item-model';
import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { SwiperOptions } from 'swiper';
import { AircraftV2Model } from '../../../models/airfcraftV2/aircraft-v2.model';
import { SwiperComponent } from 'ngx-useful-swiper';
import { AlertPopUpsService } from '../../../../app/services/alert-pop-ups.service';
import { UtilitiesService } from '../../../../app/services/utilities.service';

@Component({
  selector: 'app-aircraft-logs-carousel',
  templateUrl: './aircraft-logs-carousel.component.html',
  styleUrls: ['./aircraft-logs-carousel.component.scss'],
  providers: [ NgbCarouselConfig ]
})

export class AircraftLogsCarouselComponent implements OnInit, OnChanges {
  @Input() theAircraft: AircraftV2Model;
  @ViewChild('usefulSwiper', { read: false }) usefulSwiper: SwiperComponent;


  config: SwiperOptions = {
    // pagination: { el: '.swiper-pagination', clickable: true },
    slidesPerView: 4,
    spaceBetween: 0,
    centeredSlides: false,
    keyboard: true,
    loop: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    // breakpoints: {
    //   // when window width is >= 320px
    //   320: {
    //     slidesPerView: 1
    //   },
    //   // when window width is >= 480px
    //   480: {
    //     slidesPerView: 2
    //   },
    //   // when window width is >= 640px
    //   640: {
    //     slidesPerView: 3
    //   },
    //   // when window width is >= 820px
    //   820: {
    //     slidesPerView: 4
    //   }
    // }
  };

  constructor(
    private logBookService: AircraftLogBookService,
    private popUpService: AlertPopUpsService,
    private utilsService: UtilitiesService
    ) {}

  logCarouselItems: Array<AircraftLogBookCarouselItemModel> = [];

  ngOnInit() {
    // this.generateCarouselLinks();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.theAircraft && changes.theAircraft.currentValue) {
      this.theAircraft = changes.theAircraft.currentValue;
      this.generateCarouselLinks();
    }
  }

  generateCarouselLinks() {
    this.logCarouselItems = [
      this.logBookService.createLogBookItemModel('aircraft', this.theAircraft.tailNo),

      this.logBookService.createLogBookItemModel('engine', this.theAircraft.tailNo),

      this.logBookService.createLogBookItemModel('propeller', this.theAircraft.tailNo),

      this.logBookService.createLogBookItemModel('adCompliance', this.theAircraft.tailNo)
    ];

    if (this.usefulSwiper) {
     this.usefulSwiper.swiper.updateSlides();
    }
  }

  getLogBookTypeLabelText(type: AircraftLogBookTypeModel) {
    return this.logBookService.getLogBookTypeLabelText(type.toString());
  }

  getLogBookTypeDoclinkOld(item: AircraftLogBookCarouselItemModel) {
    return item.logBookUrl;
  }

  getLogBookTypeDoclink(type: AircraftLogBookTypeModel) {
    const t = this.getLogBookTypeLabelText(type);
    // return this.logBookService.getLogBookTypeDoclink(this.theAircraft.tailNo, t);
    this.utilsService.updateShowMainPreloader(true);

    this.logBookService.getLogBookTypeDoclink(this.theAircraft.tailNo, t).subscribe(
      (response: any) => {
        window.open(response.downloadableUrl, '_blank');
        this.utilsService.updateShowMainPreloader(false);
      },
      (error: any) => {
        this.utilsService.updateShowMainPreloader(false);
        
        const errMessage = error.message ? error.message : `Failed to generate ${type} document download url`;
        this.popUpService.openBasicPopUp('Sorry!', errMessage, 'error');
      }
    );


  }
}
