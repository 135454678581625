import { CustomHttpErrorHandler } from './custom-http-error-handler';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getAllCustomers(page:number = 1, pageSize: number = 50){
    return this.httpClient.get(environment.apiBaseUrl + '/customer/getAllCustomers?page=' + page + '&pageSize=' + pageSize)
  }

  filterCustomerByNameEmailTailSerialOrSn(filterQuery: string, page: number = 0, pageSize: number = 50){
    return this.httpClient.get(environment.apiBaseUrl + '/customer/filterCustomerByNameEmailTailSerialOrSn?q=' + filterQuery + "&page=" + page + "&pageSize=" + pageSize)
  }

  findCustomerByNameOrEmail(nameOrEmail:string){
    return this.httpClient.get(environment.apiBaseUrl + '/customer/findCustomerByNameOrEmail?nameOrEmail=' + nameOrEmail)
  }
}
