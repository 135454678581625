import { AircraftListV2Model } from './../../../../models/aircraft-list-v2-model';
import { Component, OnInit, Input } from '@angular/core';
import { AircraftV2Model } from '../../../../models/airfcraftV2/aircraft-v2.model';
import { AircraftService } from '../../../../services/aircraft.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-aircraft-table-list',
  templateUrl: './aircraft-table-list.component.html',
  styleUrls: ['./aircraft-table-list.component.scss']
})
export class AircraftTableListComponent implements OnInit {
  @Input() aircraftList: Array<AircraftListV2Model>;

  constructor(
    private aircraftService: AircraftService,
    private router: Router

  ) { }

  ngOnInit() {
  }

  viewAsSelectedAircraft(aircraft: AircraftV2Model, toRoute: 'profile' | 'dashboard') {
    this.aircraftService.setSelectedAircraft(aircraft);

    if (toRoute.toLowerCase() === 'profile') {
      this.router.navigate(['/cga/aircraft-profile']);
    }

    if (toRoute.toLowerCase() === 'dashboard') {
      this.router.navigate(['/cga/dashboard']);
    }
  }


}
