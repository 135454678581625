import { SubscriptionLike, of, Observable } from 'rxjs';
import { AircraftService } from '../../../services/aircraft.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { tap, catchError, map, switchMap, distinctUntilChanged, debounceTime } from 'rxjs/operators';

import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertPopUpsService } from '../../../services/alert-pop-ups.service';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { NumberToTenths } from '../../../_custom-validators/number-to-tenths-validator';
import { HasDependantControlValidator } from '../../../_custom-validators/has-dependant-control-validator';
import { DependsOnControlValidator } from '../../../_custom-validators/depends-on-control-validator';
import { AircraftV2Model } from '../../../models/airfcraftV2/aircraft-v2.model';
import { UserModel } from '../../../models/user-model';
import { UserService } from '../../../services/user.service';
import { OrganizationV2Model } from '../../../models/organization/organization-v2.model';
import { OrganizationService } from '../../../services/organization.service';
import { OrganizationPersonModel } from '../../../models/organization-person-model';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-aircraft-profile',
  templateUrl: './aircraft-profile.component.html',
  styleUrls: ['./aircraft-profile.component.scss']
})
export class AircraftProfileComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: FormBuilder,
    private aircraftService: AircraftService,
    private userService: UserService,
    private orgService: OrganizationService,
    private utilsService: UtilitiesService,
    private popUpService: AlertPopUpsService,
  ) {}

  currentUser: UserModel;
  currentUser$: SubscriptionLike;

  selectedAircraft: AircraftV2Model;
  selectedAircraft$: SubscriptionLike;

  editMode: boolean;

  updateAircraftForm: FormGroup;

  theAircraft: AircraftV2Model = new AircraftV2Model();

  aircraftMakeSearchFailed: boolean;
  aircraftMakeSearching: boolean;
  aircraftModelSearchFailed: boolean;
  aircraftModelSearching: boolean;

  engineMakeSearchFailed: boolean;
  engineMakeSearching: boolean;
  engineModelSearchFailed: boolean;
  engineModelSearching: boolean;

  propellerMakeSearchFailed: boolean;
  propellerMakeSearching: boolean;
  propellerModelSearchFailed: boolean;
  propellerModelSearching: boolean;

  isWorking: boolean;

  aircraftMakeSubscriber$: SubscriptionLike;
  aircraftModelSubscriber$: SubscriptionLike;

  engineMakeSubscriber$: SubscriptionLike;
  engineModelSubscriber$: SubscriptionLike;

  propellerMakeSubscriber$: SubscriptionLike;
  propellerModelSubscriber$: SubscriptionLike;


  orgAdminPerson: OrganizationPersonModel = new OrganizationPersonModel;

  ngOnInit() {
    // init values
    this.updateEditMode(false);

    this.selectedAircraft = new AircraftV2Model;

    this.aircraftMakeSearchFailed = false;
    this.aircraftMakeSearching = false;
    this.aircraftModelSearchFailed = false;
    this.aircraftModelSearching = false;

    this.engineMakeSearchFailed = false;
    this.engineMakeSearching = false;
    this.engineModelSearchFailed = false;
    this.engineModelSearching = false;

    this.propellerMakeSearchFailed = false;
    this.propellerMakeSearching = false;
    this.propellerModelSearchFailed = false;
    this.propellerModelSearching = false;

    this.isWorking = false;

    // subscribe to current user
    this.currentUser$ = this.userService.currentUser$.subscribe(
      (user: UserModel) => {
        this.currentUser = user;
      }
    );

    // subscribe to selected aircraft
    this.selectedAircraft$ = this.aircraftService.selectedAircraft$.subscribe(
      (aircraft: AircraftV2Model) => {
        this.selectedAircraft = aircraft;
        this.updateEditMode(false);

        if (this.selectedAircraft.id) {
          this.initAircraft();
        }
      }
    );

    this.updateAircraftForm = this.formBuilder.group({
      // aircraft
      'tailNo': new FormControl(
        '',
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z0-9-]{5,8}$/),
          Validators.minLength(3),
          Validators.maxLength(6)
        ]
      ),
      'aircraftMake': new FormControl(''),
      'aircraftModel': new FormControl(''),
      'airframeSn': new FormControl(''),
      'airframeTtisAtSetup': new FormControl(null, [NumberToTenths(true)]),

      // engine
      'engineMake': new FormControl(''),
      'engineModel': new FormControl(''),
      'engineSn': new FormControl(''),
      'initialEngineHours': new FormControl(null, [NumberToTenths(true)]),
      'initialLastEngineOh': new FormControl(null, [NumberToTenths(true)]),
      'engineInstalledAirframeTime': new FormControl(null, [ NumberToTenths(true)]),

      // propeller
      'propellerMake': new FormControl(''),
      'propellerModel': new FormControl(''),
      'propellerSn': new FormControl(''),
      'initLastPropellerOh': new FormControl(null, [NumberToTenths(true)]),
      'inititialPropellerHours': new FormControl(null, [NumberToTenths(true)]),
      'propellerInstalledAirframeTime': new FormControl(null, [NumberToTenths(true)]),

      // equipment
      'avionicsEquipments': new FormControl(''),
      'otherEquipments': new FormControl('')
    });

    this.doExtraUpdateFormValidations('aircraft');
    this.doExtraUpdateFormValidations('engine');
    this.doExtraUpdateFormValidations('propeller');
  }

  doExtraUpdateFormValidations(type: 'aircraft' | 'engine' | 'propeller') {
    // type MAKE AND MODEL EXTRAS
    // Set Validators for type  Makes
    this.updateAircraftForm.controls[`${type}Make`].setValidators(
      [
        HasDependantControlValidator(this.updateAircraftForm.controls[`${type}Model`])
      ]
    );

    // Set Validators for type Model
    this.updateAircraftForm.controls[`${type}Model`].setValidators(
      [
        DependsOnControlValidator(this.updateAircraftForm.controls[`${type}Make`])
      ]
    );

    // Listen to type Make Field for changes, then update Models validity
    this[`${type}MakeSubscriber$`] = this.updateAircraftForm.controls[`${type}Make`].valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(
      (value: any) => {
        this.updateAircraftForm.controls[`${type}Model`].updateValueAndValidity();
      }
    );

    // Listen to type Model Field for changes, then update Models validity
    this[`${type}ModelSubscriber$`] = this.updateAircraftForm.controls[`${type}Model`].valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(
      (value: any) => {
        this.updateAircraftForm.controls[`${type}Make`].updateValueAndValidity();
      }
    );

    return;
  }

  initAircraft() {
    this.theAircraft = new AircraftV2Model;
    this.theAircraft  = JSON.parse(JSON.stringify(this.selectedAircraft));

    this.theAircraft.organizations.forEach(
      (org: OrganizationV2Model, i) => {
        const sub$: Observable<any> = this.getOrgV2Users(org.id);

        sub$.subscribe(
          (response: Array<OrganizationPersonModel>) => {
            response.some(
              (person: OrganizationPersonModel) => {
                if (person.role === 'ADMIN') {
                  this.theAircraft.organizations[i]['adminEmail'] = person.user.emailAddress;
                  this.theAircraft.organizations[i]['adminName'] = person.user.firstName + ' ' + person.user.lastName  ;
                  this.theAircraft.organizations[i]['adminPhone'] = person.user.mobilePhone;
                  return true;
                }

                return false;
              }
            );
          },
          (error: any) => {
            this.popUpService.openBasicPopUp(
              'Oops!',
              'Can not fetch Aircraft Organization Owner!',
              'error'
            );
          },
        );
      }
    );

    // Aircraft Model Assignment
    if (!(this.theAircraft.airframe && this.theAircraft.airframe.model)) {
      this.theAircraft.airframe.model = {
        id: null,
        name: null
      };
    }

    // Aircraft Make Assignment
    if (
      !(
        this.theAircraft.airframe &&
        this.theAircraft.airframe.make &&
        this.theAircraft.airframe.make.id
      )
    ) {
      this.theAircraft.airframe.make = {
        id: null,
        name: null
      };
    }

    // Engine Model Assignment
    if (
        !(
          this.theAircraft.engines[0] &&
          this.theAircraft.engines[0].model &&
          this.theAircraft.engines[0].model.id
        )
      ) {
      this.theAircraft.engines[0].model =  {
        id: null,
        name: null
      };
    }

    // Engine Make Assignment
    if (
      !(
        this.theAircraft.engines[0] &&
        this.theAircraft.engines[0].make &&
        this.theAircraft.engines[0].make.id
      )
    ) {
      this.theAircraft.engines[0].make =  {
        id: null,
        name: null
      };
    }

    // Propeller Model Assignment
    if (
      !(
        this.theAircraft.propellers[0] &&
        this.theAircraft.propellers[0].model &&
        this.theAircraft.propellers[0].model.id
      )
    ) {
      this.theAircraft.propellers[0].model =  {
        id: null,
        name: null
      };
    }

    // Propeller Make Assignment
    if (
      !(
        this.theAircraft.propellers[0] &&
        this.theAircraft.propellers[0].make &&
        this.theAircraft.propellers[0].make.id
      )
    ) {
      this.theAircraft.propellers[0].make = {
        id: null,
        name: null
      };
    }

    // get owning org
    this.getOwningOrgUsers();
  }

  getOwningOrgUsers() {
    const ownerOrg: OrganizationV2Model = this.findAircraftOrgbyType('OWNER');

    if (ownerOrg.id) {
      const sub$: Observable<any> = this.getOrgV2Users(ownerOrg.id);

      sub$.subscribe(
        (response: Array<OrganizationPersonModel>) => {

          response.some(
            (person: OrganizationPersonModel) => {
              if (person.role === 'ADMIN') {
                this.orgAdminPerson = person;
                return true;
              }

              return false;
            }
          );
        },
        (error: any) => {
          this.popUpService.openBasicPopUp(
            'Oops!',
            'Can not fetch Aircraft Organization Owner!',
            'error'
          );
        },
      );
    }
  }

  getOrgV2Users(orgId: number) {
    return this.orgService.getUsersByOrganizationV2(orgId);
  }

  initUpdateAircraftForm() {
    this.aircraftMakeSearching = false;
    this.aircraftModelSearching = false;

    this.engineModelSearching = false;
    this.engineMakeSearching = false;

    this.propellerMakeSearching = false;
    this.propellerModelSearching = false;

    // Set aircraft data
    this.updateAircraftForm.controls.tailNo.setValue(
      this.theAircraft.tailNo
    );
    this.updateAircraftForm.controls.aircraftMake.setValue(
      this.theAircraft.airframe && this.theAircraft.airframe.make && this.theAircraft.airframe.make.id
        ? {
          id: this.theAircraft.airframe.make ? this.theAircraft.airframe.make.id : null,
          makeName: this.theAircraft.airframe.make ? this.theAircraft.airframe.make.name : null
        }
        : null,
      [Validators.required]
    );
    this.updateAircraftForm.controls.aircraftModel.setValue(
      {
        id: this.theAircraft.airframe.model ? this.theAircraft.airframe.model.id : null,
        modelName: this.theAircraft.airframe.model ? this.theAircraft.airframe.model.name : null
      }
    );
    this.updateAircraftForm.controls.airframeSn.setValue(
      this.theAircraft.airframe.serialNumber
    );
    this.updateAircraftForm.controls.airframeTtisAtSetup.setValue(
      this.theAircraft.airframe.ttisAtSetup
    );


    // Set Engine Data
    this.updateAircraftForm.controls.engineMake.setValue(
      this.theAircraft.engines[0] && this.theAircraft.engines[0].make && this.theAircraft.engines[0].make.id
        ? {
          id: this.theAircraft.engines[0].make ? this.theAircraft.engines[0].make.id : null,
          makeName: this.theAircraft.engines[0].make ? this.theAircraft.engines[0].make.name : null,
        }
        : null
    ),
      this.updateAircraftForm.controls.engineModel.setValue(
        this.theAircraft.engines[0] && this.theAircraft.engines[0].model && this.theAircraft.engines[0].model.id
          ? {
            id: this.theAircraft.engines[0].model ? this.theAircraft.engines[0].model.id : null,
            modelName: this.theAircraft.engines[0].model ? this.theAircraft.engines[0].model.name : null,
          }
          : null
      );
    this.updateAircraftForm.controls.engineSn.setValue(
      this.theAircraft.engines[0] ? this.theAircraft.engines[0].serialNumber : null
    );
    this.updateAircraftForm.controls.initialEngineHours.setValue(
      this.theAircraft.engines[0] ? this.theAircraft.engines[0].initialHours : null
    );
    this.updateAircraftForm.controls.initialLastEngineOh.setValue(
      this.theAircraft.engines[0] ? this.theAircraft.engines[0].lastOverhaulHours : null
    );
    this.updateAircraftForm.controls.engineInstalledAirframeTime.setValue(
      this.theAircraft.engines[0] ? this.theAircraft.engines[0].installedAirframeTime : null
    );


    // Set Propeller Data
    this.updateAircraftForm.controls.propellerMake.setValue(
      this.theAircraft.propellers[0] && this.theAircraft.propellers[0].make && this.theAircraft.propellers[0].make.id
        ? {
          id: this.theAircraft.propellers[0].make ? this.theAircraft.propellers[0].make.id : null,
          makeName : this.theAircraft.propellers[0].make ? this.theAircraft.propellers[0].make.name : null
        }
        : null
    );

    this.updateAircraftForm.controls.propellerModel.setValue(
      this.theAircraft.propellers[0] && this.theAircraft.propellers[0].model && this.theAircraft.propellers[0].model.id
        ? {
          id: this.theAircraft.propellers[0].model ? this.theAircraft.propellers[0].model.id : null,
          modelName : this.theAircraft.propellers[0].model ? this.theAircraft.propellers[0].model.name : null
        }
        : null
    );

    this.updateAircraftForm.controls.propellerSn.setValue(
      this.theAircraft.propellers[0] ? this.theAircraft.propellers[0].serialNumber : null
    );
    this.updateAircraftForm.controls.initLastPropellerOh.setValue(
      this.theAircraft.propellers[0] ? this.theAircraft.propellers[0].lastOverhaulHours : null
    );
    this.updateAircraftForm.controls.inititialPropellerHours.setValue(
      this.theAircraft.propellers[0] ? this.theAircraft.propellers[0].initialHours : null
    );
    this.updateAircraftForm.controls.propellerInstalledAirframeTime.setValue(
      this.theAircraft.propellers[0] ? this.theAircraft.propellers[0].installedAirframeTime : null
    );


    // Set equipment Data
    this.updateAircraftForm.controls.avionicsEquipments.setValue(
      this.theAircraft.avionicsEquipments
    );
    this.updateAircraftForm.controls.otherEquipments.setValue(
      this.theAircraft.otherEquipments
    );
  }

  // from getters for validations
  get aircraftMake() {
    return this.updateAircraftForm.controls.aircraftMake;
  }
  get aircraftModel() {
    return this.updateAircraftForm.controls.aircraftModel;
  }
  get tailNo() {
    return this.updateAircraftForm.controls.tailNo;
  }
  get airframeTtisAtSetup() {
    return this.updateAircraftForm.controls.airframeTtisAtSetup;
  }


  get initialEngineHours() {
    return this.updateAircraftForm.controls.initialEngineHours;
  }
  get engineMake() {
    return this.updateAircraftForm.controls.engineMake;
  }
  get engineModel() {
    return this.updateAircraftForm.controls.engineModel;
  }
  get engineSn() {
    return this.updateAircraftForm.controls.engineSn;
  }
  get initialLastEngineOh() {
    return this.updateAircraftForm.controls.initialLastEngineOh;
  }
  get engineInstalledAirframeTime() {
    return this.updateAircraftForm.controls.engineInstalledAirframeTime;
  }


  get propellerMake() {
    return this.updateAircraftForm.controls.propellerMake;
  }
  get propellerModel() {
    return this.updateAircraftForm.controls.propellerModel;
  }
  get propellerSn() {
    return this.updateAircraftForm.controls.propellerSn;
  }
  get initLastPropellerOh() {
    return this.updateAircraftForm.controls.initLastPropellerOh;
  }
  get inititialPropellerHours() {
    return this.updateAircraftForm.controls.inititialPropellerHours;
  }
  get propellerInstalledAirframeTime() {
    return this.updateAircraftForm.controls.propellerInstalledAirframeTime;
  }

  //// Type heads search functions for aircraft make, engine make and propeller make
  aircraftMakeTypeHeadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.aircraftMakeSearching = true)),
      switchMap(term => this.getMakes(term, 'aircraft')),
      tap(() => (this.aircraftMakeSearching = false))
    )


  engineMakeTypeHeadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.engineMakeSearching = true)),
      switchMap(term => this.getMakes(term, 'engine')),
      tap(() => (this.engineMakeSearching = false))
    )


  propellerMakeTypeHeadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.propellerMakeSearching = true)),
      switchMap(term => this.getMakes(term, 'propeller')),
      tap(() => (this.propellerMakeSearching = false))
    )



  // get makes for the modes
  getMakes(term: string, mode: 'aircraft' | 'engine' | 'propeller') {

    if (mode === 'aircraft') {
      if (this.theAircraft.airframe.model && this.theAircraft.airframe.model.id) {
        this.theAircraft.airframe.model.id = null;
        this.theAircraft.airframe.model.name = null;
      }
    }

    if (mode === 'engine') {
      if (this.theAircraft.engines[0] &&  this.theAircraft.engines[0].model && this.theAircraft.engines[0].model.id) {
        this.theAircraft.engines[0].model.id = null;
        this.theAircraft.engines[0].model.name = null;
      }
    }

    if (mode === 'propeller') {
      if (this.theAircraft.propellers[0] && this.theAircraft.propellers[0].model && this.theAircraft.propellers[0].model.id) {
        this.theAircraft.propellers[0].model.id = null;
        this.theAircraft.propellers[0].model.name = null;
      }
    }

    // if (this.theAircraft[mode + '_model_id'] !== null) {
    //   let newModels = {
    //     aircraftModelNew : new AircraftModel,
    //     engineModelNew : new EngineModel,
    //     propellerModelNew : new PropellerModel,
    //   };

    //   this.theAircraft[mode + '_model']= newModels[mode + 'ModelNew'];
    //   this.theAircraft[mode + '_model_id'] = null;
    // }

    this.updateAircraftForm.controls[mode + 'Model'].setValue(null);

    if (term.length < 1) {
      return of([]);
    }

    return this.aircraftService.findAircraftMakeByName(term).pipe(
      tap(() => (this[mode + 'MakeSearchFailed'] = false)),
      catchError(() => {
        this[mode + 'MakeSearchFailed'] = true;
        return of([]);
      }),
      map((res: any) => {
        let arr: Array<any> = [];

        // send new term as new Make
        let termMake = null;
        term = term.trim();
        if (term) {
          termMake = { makeId: null, makeName: term };
          arr.push(termMake);
        }
        ///////

        res.responseData.forEach(element => {
          // set id of newly added item if it's in result
          if (
            termMake &&
            element.makeName.toUpperCase() === termMake.makeName.toUpperCase(0)
          ) {
            arr[0].makeId = element.makeId;
            termMake = null;
          }

          arr.push(element);
        });
        /*if(arr.length < 1 ){
          arr.push({makeId: null, makeName: term.trim()})
        }*/
        return arr;
      })
    );
  }



  // On Select typehead(make) options...
  onTypeHeadSelected(
    ev: NgbTypeaheadSelectItemEvent,
    mode: 'aircraft' | 'propeller' | 'engine'
  ) {

    // this.theAircraft[mode + '_model'].id = null;
    // this.theAircraft[mode + '_model'].modelName = null;

    if (mode === 'aircraft') {
      this.theAircraft.airframe.make.id = ev.item.id;
      this.theAircraft.airframe.make.name = ev.item.makeName;
      this.updateAircraftForm.controls.aircraftModel.setValue(null);
    }
    if (mode === 'engine') {
      this.theAircraft.engines[0].make.id = ev.item.id;
      this.theAircraft.engines[0].make.name = ev.item.makeName;
      this.updateAircraftForm.controls.engineModel.setValue(null);
    }
    if (mode === 'propeller') {
      this.theAircraft.propellers[0].make.id = ev.item.id;
      this.theAircraft.propellers[0].make.name =
        ev.item.makeName;
        this.updateAircraftForm.controls.propellerModel.setValue(null);
    }

  }


  // AIR CRAFT MODEL TYPEHEAD //
  aircraftModelTypeHeadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.aircraftModelSearching = true)),
      switchMap(term => {

        if (!this.theAircraft.airframe.make.id) {
          return of([{ id: null, modelName: term }]);
        }

        return this.aircraftService
          .findAircraftModelByMake(
            term,
            this.theAircraft.airframe.make.id,
            this.theAircraft.airframe.make.name
          )
          .pipe(
            tap(() => (!this.aircraftModelSearchFailed)),
            catchError(() => {
              this.aircraftModelSearchFailed = true;
              return of([]);
            }),
            map((res: any) => {
              let arr: Array<any> = [];

              // send new term as new Model
              let termModel = null;
              term = term.trim();
              if (term) {
                termModel = { id: null, modelName: term };
                arr.push(termModel);
              }
              ///////

              res.responseData.result.forEach(element => {
                // set id of newly added item if it's in result
                if (
                  termModel &&
                  element.modelName.toUpperCase() ===
                    termModel.modelName.toUpperCase(0)
                ) {
                  arr[0].id = element.id;
                  termModel = null;
                }

                arr.push(element);
              });

              return arr;
            })
          );
      }),
      tap(() => (this.aircraftModelSearching = false))
    )

  // ENGINE MODEL TYPEHEAD //
  engineModelTypeHeadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.engineModelSearching = true)),
      switchMap(term => {
        if (!this.theAircraft.engines[0].make.id) {
          return of([{ id: null, modelName: term }]);
        }

        return this.aircraftService
          .findEngineModelByMake(
            term,
            +this.theAircraft.engines[0].make.id,
            this.theAircraft.engines[0].make.name
          )
          .pipe(
            tap(() => (this.engineModelSearchFailed = false)),
            catchError(() => {
              this.engineModelSearchFailed = true;
              return of([]);
            }),
            map((res: any) => {
              let arr: Array<any> = [];

              // send new term as new Model
              let termModel = null;
              term = term.trim();
              if (term) {
                termModel = { id: null, modelName: term };
                arr.push(termModel);
              }
              ///////

              res.responseData.result.forEach(element => {
                // set id of newly added item if it's in result
                if (
                  termModel &&
                  element.modelName.toUpperCase() ===
                    termModel.modelName.toUpperCase(0)
                ) {
                  arr[0].id = element.id;
                  termModel = null;
                }

                arr.push(element);
              });
              return arr;
            })
          );
      }),
      tap(() => (this.engineModelSearching = false))
    )

  // PROPELLER  MODEL TYPEHEAD //
  propellerModelTypeHeadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.propellerModelSearching = true)),
      switchMap(term => {

        if (!this.theAircraft.propellers[0].make.id) {
          return of([{ id: null, modelName: term }]);
        }

        return this.aircraftService
          .findPropellerModelByMake(
            term,
            +this.theAircraft.propellers[0].make.id,
            this.theAircraft.propellers[0].make.name
          )
          .pipe(
            tap(() => (!this.propellerModelSearchFailed)),
            catchError(() => {
              this.propellerModelSearchFailed = true;
              return of([]);
            }),
            map((res: any) => {
              let arr: Array<any> = [];

              // send new term as new Model
              let termModel = null;
              term = term.trim();
              if (term) {
                termModel = { id: null, modelName: term };
                arr.push(termModel);
              }
              ///////

              res.responseData.result.forEach(element => {
                // set id of newly added item if it's in result
                if (
                  termModel &&
                  element.modelName.toUpperCase() ===
                    termModel.modelName.toUpperCase(0)
                ) {
                  arr[0].id = element.id;
                  termModel = null;
                }

                arr.push(element);
              });

              return arr;
            })
          );
      }),
      tap(() => (this.propellerModelSearching = false))
    )
  //

  // format typeheads data received,  before display
  typeHeadMakeFormatter = (makeObj: { id: string; makeName: string }) => {
    return makeObj.makeName ? makeObj.makeName : '';
  }

  typeHeadModelFormatter = (modelObj: { id: string; modelName: string }) => {
    return modelObj.modelName ? modelObj.modelName : '';
  }

  // On Select typehead(model) options...
  onTypeHeadModelSelected(
    ev: NgbTypeaheadSelectItemEvent,
    mode: 'aircraft' | 'propeller' | 'engine'
  ) {
    if (mode === 'aircraft') {
      // this.theAircraft.aircraft_model.id = ev.item.id;
      // this.theAircraft.aircraft_model.modelName = ev.item.modelName;
      this.theAircraft.airframe.model = {
        id : ev.item.id,
        name : ev.item.modelName
      }
    }
    if (mode === 'engine') {
      // this.theAircraft.engine_model.id = ev.item.id;
      // this.theAircraft.engine_model.modelName = ev.item.modelName;
      this.theAircraft.engines[0].model = {
        id : ev.item.id,
        name : ev.item.modelName
      };
    }
    if (mode === 'propeller') {
      // this.theAircraft.propeller_model.id = ev.item.id;
      // this.theAircraft.propeller_model.modelName = ev.item.modelName;
      this.theAircraft.propellers[0].model = {
        id : ev.item.id,
        name : ev.item.modelName
      };
    }
  }

  doUpdateAircraft() {

    if (!this.updateAircraftForm.valid) {

      Object.values(this.updateAircraftForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      // for(let i in this.updateAircraftForm.controls) {
      //   this.updateAircraftForm.controls[i].markAsTouched();
      // };

      this.popUpService.openBasicPopUp(
        'Check important fields!',
        'Aircraft Data Form not filled correctly!',
        'error'
      );
      return;
    }

    // scroll to top
    window.scroll(0, 0);

    // check aircraft model
    if (!this.updateAircraftForm.controls.aircraftMake.value) {
      this.theAircraft.airframe.model.id = null;
    }

    // check engine model
    if (!this.updateAircraftForm.controls.engineMake.value) {
      this.theAircraft.engines[0].model.id = null;
    }

    // check propeller model
    if (!this.updateAircraftForm.controls.propellerMake.value) {
      this.theAircraft.propellers[0].model.id = null;
    }

    this.isWorking = true;

    this.theAircraft.tailNo = this.updateAircraftForm.controls.tailNo.value.toUpperCase();
    this.theAircraft.airframe.serialNumber = this.updateAircraftForm.controls.airframeSn.value;
    this.theAircraft.airframe.ttisAtSetup = this.updateAircraftForm.controls.airframeTtisAtSetup.value;

    this.theAircraft.engines[0].serialNumber = this.updateAircraftForm.controls.engineSn.value;
    this.theAircraft.engines[0].initialHours = this.updateAircraftForm.controls.initialEngineHours.value;
    this.theAircraft.engines[0].lastOverhaulHours = this.updateAircraftForm.controls.initialLastEngineOh.value;
    this.theAircraft.engines[0].installedAirframeTime = this.updateAircraftForm.controls.engineInstalledAirframeTime.value;

    this.theAircraft.propellers[0].serialNumber = this.updateAircraftForm.controls.propellerSn.value;
    this.theAircraft.propellers[0].lastOverhaulHours = this.updateAircraftForm.controls.initLastPropellerOh.value;
    this.theAircraft.propellers[0].initialHours = this.updateAircraftForm.controls.inititialPropellerHours.value;
    this.theAircraft.propellers[0].installedAirframeTime = this.updateAircraftForm.controls.propellerInstalledAirframeTime.value;

    this.theAircraft.avionicsEquipments = this.updateAircraftForm.controls.avionicsEquipments.value;
    this.theAircraft.otherEquipments = this.updateAircraftForm.controls.otherEquipments.value;

    const theUserAircraft = this.aircraftService.convertV2AircraftToV1Model(this.theAircraft);

    this.aircraftService.updateAircraftV2(this.theAircraft.tailNo, theUserAircraft).subscribe(
      (response: AircraftV2Model) => {
        this.isWorking = false;
        Swal.fire('Nice one!', 'Aircraft updated.', 'success');
        this.updateEditMode(false);
        this.aircraftService.setSelectedAircraft(this.theAircraft);
      },
      error => {
        this.isWorking = false;
        if (error.status.message) {
          Swal.fire(
            error.status.message,
            error.responseData.join(' '),
            'error'
          );
        } else {
          Swal.fire('Oops!', 'Creat aircraft failed.', 'error');
        }
      }
    );
  }

  toggleEditMode() {
    const mode: boolean = !this.editMode;
    this.updateEditMode(mode);


    if (!this.editMode) {
      this.initAircraft();
    }
  }

  updateEditMode(mode: boolean) {
    this.editMode = mode;

    if (this.editMode) {
     this.initUpdateAircraftForm();
    }
  }

  findAircraftOrgbyType(type: string) {
    return this.orgService.findAircraftOrgbyType(this.theAircraft, type);
  }

  formatPhoneNumber(phone: string) {
    return this.utilsService.formatPhoneNumber(phone);
  }

  ngOnDestroy() {
    if (this.currentUser$) {
      this.currentUser$.unsubscribe();
    }

    if (this.selectedAircraft$) {
      this.selectedAircraft$.unsubscribe();
    }

    if (this.aircraftMakeSubscriber$) {
      this.aircraftMakeSubscriber$.unsubscribe();
    }

    if (this.aircraftModelSubscriber$) {
      this.aircraftModelSubscriber$.unsubscribe();
    }

    if (this.engineMakeSubscriber$) {
      this.engineMakeSubscriber$.unsubscribe();
    }

    if (this.engineModelSubscriber$) {
      this.engineModelSubscriber$.unsubscribe();
    }

    if (this.propellerMakeSubscriber$) {
      this.propellerMakeSubscriber$.unsubscribe();
    }

    if (this.propellerModelSubscriber$) {
      this.propellerModelSubscriber$.unsubscribe();
    }
  }
}
