import { ValidatorFn, AbstractControl } from '@angular/forms';

export function MustNotBeSameValueAsControlValidator ( otherControl: AbstractControl, allowNull: Boolean = true): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let isForbidden: boolean = true;

    isForbidden = control.value? null : true;

    if(!otherControl.value){
      isForbidden = allowNull? false : true;
    }
    else if(!control.value){
      isForbidden = allowNull? false : true;
    }
    else{
      isForbidden = otherControl.value &&
        (otherControl.value.toString().toLowerCase() === control.value.toString().toLowerCase())?
      true : null;
    }


    // if(isForbidden) {
    //   control.setErrors({'mustNotBeSameAsControl': {value: otherControl.value}});
    // }
    // else{
    //   if (control.hasError('mustNotBeSameAsControl')) {
    //     delete control.errors['mustNotBeSameAsControl'];
    //     control.updateValueAndValidity();
    //   }
    // }

    return isForbidden ? {'mustNotBeSameAsControl': {value: control.value}} : null;
  };
}
