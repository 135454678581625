<app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

<div *ngIf="!isWorking">
  <section class="mb-3 border-0">
    <div class="form-group">
      <form [formGroup]="filterSearchForm" (ngSubmit)="filterUsersListWithQuery()">
        <div class="input-group">
          <input type="text" formControlName="filterQuery" class="form-control" id="filterQuery"
          placeholder="Enter customer name, email, phone or pilot No">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="submit" id="search_by_name">
              <i class="la la-search"></i>
            </button>
          </div>
        </div>
        <small id="aircraft_help" class="form-text text-muted">
          Customer Name, Email,	Phone	or Pilot Licence No.
        </small>
      </form>
    </div>
  </section>

  <div class="card bg-transparent border-0 all-members-list">
    <div class="card-header card bg-transparent border-0">
      <span *ngIf="filterQuery">Search Results for <b>{{filterQuery}}</b></span>
      <!--<span *ngIf="!filterQuery">All Customers: <b>{{allUserCount}}</b></span> -->
    </div>
      <div class="card-body p-0">
        <div>
          <app-members-table [membersList]="filterResult" *ngIf="filterQuery"></app-members-table>
          <app-members-table [membersList]="allUsers" *ngIf="!filterQuery"></app-members-table>
        </div>

      </div>
  </div>
</div>

