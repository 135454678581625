<!---Manage Air Bulletins -->
<section class="p-3 admin-air-bulletins">
  <div class="card shadow border-0">
    <div class="card-header clearfix">
      &nbsp;
      <span class="float-right">
        <button class="btn btn-sm btn-outline-dark rounded" (click)="openAddServiceBulletinsModal()">
          Open Add Bulletins Workflow
          <i class="la la-plus"></i>
        </button>
        <span class="p-2"></span>
        <button class="btn btn-sm btn-outline-dark rounded" (click)="toggleInAddNewBulletinMode()">
          {{ inAddNewServiceBulletinMode? 'Cancel' : 'Add Bulletin' }}
          <i class="la la-plus" *ngIf="!inAddNewServiceBulletinMode"></i>
          <i class="la la-times" *ngIf="inAddNewServiceBulletinMode"></i>
        </button>
      </span>
    </div>
    <div class="card-body">

      <!--NEW BULLETIN-->
      <div class="card mb-3 bg-light border-info rounded" *ngIf="inAddNewServiceBulletinMode">
        <!--<div class="card-header">
          New Service Bulletin
        </div>-->
        <div class="card-body">

          <app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

          <form [formGroup]="newAircraftServiceBulletinForm" *ngIf="!isWorking"
            (ngSubmit)="addAircraftServiceBulletin()">
            <div>
              <div class="row">
                <div class="col-12 mb-1">
                  <small class="text-muted pr-2">Manufacturers: </small>
                  {{newAircraftServiceBulletin.service_bulletin.id? getBulletinModelManufacturers(newAircraftServiceBulletin.service_bulletin) : '----'}}
                </div>

                <div class="col-12 mb-1" *ngIf="newAircraftServiceBulletin.service_bulletin">
                  <small class="text-muted pr-2">Models: </small>

                  <span *ngFor="let model of getAdSbModels(newAircraftServiceBulletin.service_bulletin); let i = index;"
                    class="badge badge-secondary mr-3">
                    {{model.modelName}}
                  </span>
                </div>

                <div class="col-12 mb-1">
                  <small class="text-muted pr-2">Subject: </small>

                  <!--SB Document View Link-->
                  <ng-container>
                    <button
                      (click)="openModal(content, newAircraftServiceBulletin.service_bulletin.fileUrl, newAircraftServiceBulletin.service_bulletin.bulletinName)"
                      class="mr-2 btn btn-danger btn-sm rounded"
                      *ngIf="newAircraftServiceBulletin.service_bulletin.fileUrl">
                      <small class="d-block text-white">
                        <i class="la la-file-pdf-o"></i>
                        Document
                      </small>
                    </button>

                    <span class="badge badge-light rounded p-2"
                      *ngIf="!newAircraftServiceBulletin.service_bulletin.fileUrl">
                      <small class="d-block text-muted">
                        <i class="la la-file-pdf-o"></i>
                        No Document
                      </small>
                    </span>
                  </ng-container>

                  {{newAircraftServiceBulletin.service_bulletin? newAircraftServiceBulletin.service_bulletin.subject : '----'}}
                </div>

                <div class="col-12 mb-1">
                  <small class="text-muted pr-2">
                    Type:
                    {{newAircraftServiceBulletin.service_bulletin? (newAircraftServiceBulletin.service_bulletin.adSbType | uppercase): '---' }}
                  </small>
                  <small class="text-muted pr-2" *ngIf="newAircraftServiceBulletin.service_bulletin.isRecurring">
                    | <i class="la la-check text-success pl-2"></i> Recurring
                  </small>
                  <small class="text-muted pr-2" *ngIf="newAircraftServiceBulletin.service_bulletin.mandatory">
                    | <i class="la la-check text-success pl-2"></i> Mandatory
                  </small>
                </div>

                <hr class="mb-2 w-100 d-block">

                <div class="col-sm-12 col-lg-3 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted required">
                      Bulletin Name {{newAircraftServiceBulletinSearching? '...searching...' : ''}}
                    </small>
                    <input type="text" formControlName="bulletin" class="form-control" id="bulletin"
                      placeholder="Bulletin Name" [class.is-invalid]="newAircraftServiceBulletinSearchFailed"
                      [ngbTypeahead]="newAircraftServiceBulletinTypeHeadSearch" [editable]="false" [resultTemplate]="rt"
                      [inputFormatter]="typeHeadBulletinFormatter" (selectItem)="onTypeHeadSelected($event)" required>

                    <ng-template #rt let-r="result" let-t="term">
                      <ngb-highlight [result]="r.bulletinName" [term]="t"></ngb-highlight>
                      <small class="text-muted"><em>{{getAdSbTypeText(r)}}</em></small>
                    </ng-template>
                    <small class="form-text text-danger"
                      *ngIf="newAircraftServiceBulletinForm.get('bulletin').invalid && (newAircraftServiceBulletinForm.get('bulletin').touched || newAircraftServiceBulletinForm.get('bulletin').dirty)">
                      Ensure you select Bulletin from the dropdown options
                    </small>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-3 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Published Date</small>
                    <div class="input-group" *ngIf="newAircraftServiceBulletinForm">
                      {{newAircraftServiceBulletinForm.controls["publishedDate"].value? (newAircraftServiceBulletinForm.controls["publishedDate"].value | date:'MM/dd/yyyy') : '-- -- --'}}
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-3 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Due Date</small>
                    <div class="input-group">
                      <input class="form-control" formControlName="dueDate" placeholder="mm/dd/yyyy" name="dueDate"
                        ngbDatepicker #due_date="ngbDatepicker">
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="due_date.toggle()" type="button">
                          <i class="la la-calendar-plus-o"></i>
                        </button>
                      </div>
                    </div>

                    <!-- due now button -->
                    <button class="btn btn-sm btn-info calendar w-100" (click)="dueNowSBNew($event)" type="button">
                      Due Now
                    </button>

                    <small class="form-text text-danger d-block"
                      *ngIf="newAircraftServiceBulletinForm.get('dueDate').invalid && (newAircraftServiceBulletinForm.get('dueDate').touched || newAircraftServiceBulletinForm.get('dueDate').dirty)">
                      Enter Valid Due Date (and/or Due Hours)
                    </small>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-3 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Due Hours</small>
                    <div class="input-group">
                      <input class="form-control" formControlName="dueHours" placeholder="Due Hours" name="dueHours">
                    </div>
                    <small class="form-text text-danger d-block"
                      *ngIf="newAircraftServiceBulletinForm.get('dueHours').invalid && (newAircraftServiceBulletinForm.get('dueHours').touched || newAircraftServiceBulletinForm.get('dueHours').dirty)">
                      Enter Valid Due Hours (and/or Due Date)
                    </small>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-6 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted required">Condition</small>
                    <div class="input-group">
                      <select class="form-control" formControlName="condition" placeholder="mm/dd/yyyy"
                        name="condition">
                        <option *ngFor="let condition of conditionOptions.conditions"
                          [selected]="newAircraftServiceBulletinForm.controls['condition'].value == condition.value"
                          [value]="condition.value"> {{condition.label}}</option>
                      </select>
                    </div>
                    <small class="form-text text-danger d-block"
                      *ngIf="newAircraftServiceBulletinForm.hasError('required', 'condition') && (newAircraftServiceBulletinForm.get('condition').touched || newAircraftServiceBulletinForm.get('condition').dirty)">
                      Select Condition
                    </small>
                    <small class="form-text text-danger d-block"
                      *ngIf="newAircraftServiceBulletinForm.hasError('allControlsNeededIfValueIs', 'condition') &&!(newAircraftServiceBulletinForm.hasError('required', 'condition'))">
                      You need to enter both <b>Due Date</b> and <b>Due Hours</b>
                    </small>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-6 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Frequency</small>
                    <div class="form-check form-check-inline mt-2">
                      <input class="form-check-input" type="checkbox" id="isRecurring" formControlName="isRecurring">
                      <label class="form-check-label" for="isRecurring">Recurring?</label>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="row">
                    <div class="col-sm-12 col-lg-6 col-md-5">
                      <div class="form-group">
                        <small class="form-text text-muted required">Status for Aircraft</small>
                        <div class="input-group">
                          <select class="form-control" formControlName="application" placeholder="Status for Aircraft"
                            name="application">
                            <option *ngFor="let app of applicationOptions.applications" [value]="app.value">
                              {{app.label}}
                            </option>
                          </select>
                        </div>
                        <div
                          *ngIf="newAircraftServiceBulletinForm.controls['application'].touched || newAircraftServiceBulletinForm.controls['application'].dirty">
                          <small class="form-text text-danger d-block"
                            *ngIf="newAircraftServiceBulletinForm.hasError('required', 'application')">
                            Select Status for Aircraft
                          </small>
                          <small class="form-text text-danger d-block"
                            *ngIf="newAircraftServiceBulletinForm.hasError('hasDependantIfControlIs_Performed', 'application')">
                            Fill <b>Performed Date</b> field.
                          </small>
                          <small class="form-text text-danger d-block"
                            *ngIf="newAircraftServiceBulletinForm.hasError('hasDependantIfControlIs_NotApply', 'application')">
                            Fill <b>Not Apply</b> field.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12 col-lg-6 col-md-7">
                      <div class="row" *ngIf="newAircraftServiceBulletinForm.get('application').value == 'Performed'">

                        <div class="col-sm-12 col-lg-7 col-md-7">
                          <div class="form-group">
                            <small class="form-text text-muted required">Performed Date</small>
                            <div class="input-group">
                              <input class="form-control" formControlName="performedDate" placeholder="mm/dd/yyyy"
                                name="performedDate" ngbDatepicker #performed_date="ngbDatepicker">
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="performed_date.toggle()"
                                  type="button">
                                  <i class="la la-calendar-plus-o"></i>
                                </button>
                              </div>
                            </div>
                            <small class="form-text text-danger d-block"
                              *ngIf="newAircraftServiceBulletinForm.get('performedDate').invalid && (newAircraftServiceBulletinForm.get('performedDate').touched || newAircraftServiceBulletinForm.get('performedDate').dirty)">
                              Enter Performed Date
                            </small>
                          </div>
                        </div>

                        <div class="col-sm-12 col-lg-5 col-md-5">
                          <div class="form-group">
                            <small class="form-text text-muted">Performed Hours</small>
                            <div class="input-group">
                              <input class="form-control" formControlName="performedHour" placeholder="e.g 1200.5"
                                name="performedHour">
                            </div>
                            <small class="form-text text-danger d-block"
                              *ngIf="newAircraftServiceBulletinForm.get('performedHour').invalid && (newAircraftServiceBulletinForm.get('performedHour').touched || newAircraftServiceBulletinForm.get('performedHour').dirty)">
                              Hours to tenths e.g 100.5
                            </small>
                          </div>
                        </div>
                      </div>


                      <div class="form-group"
                        *ngIf="newAircraftServiceBulletinForm.get('application').value == 'NotApply'">
                        <small class="form-text text-muted required">Does not apply reason</small>
                        <div class="input-group">
                          <input class="form-control" formControlName="notApplyReason" placeholder="Not Apply Reason"
                            name="notApplyReason">
                        </div>
                        <small class="form-text text-danger d-block"
                          *ngIf="newAircraftServiceBulletinForm.get('notApplyReason').invalid && (newAircraftServiceBulletinForm.get('notApplyReason').touched || newAircraftServiceBulletinForm.get('notApplyReason').dirty)">
                          Enter 'Not Apply' reason (Max 1,000 chars.)
                        </small>
                      </div>
                    </div>

                    <div class="col-12" *ngIf="newAircraftServiceBulletinForm.get('application').value == 'Performed'">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <small class="form-text text-muted">Performance Note</small>
                            <div class="input-group">
                              <textarea class="form-control w-100" formControlName="performanceNote"
                                name="performanceNote" id="performanceNote" placeholder="Performance Note"
                                rows="4"></textarea>
                            </div>
                            <small class="form-text text-danger" *ngIf="newAircraftServiceBulletinForm.get('performanceNote').invalid">
                              Maximum 3,000 characters exceeded.
                            </small>
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="form-group">
                            <small class="form-text text-muted">Terminating Action</small>
                            <div class="form-check form-check-inline mt-2">
                              <input class="form-check-input" type="checkbox" id="isTerminated"
                                formControlName="isTerminated">
                              <label class="form-check-label" for="isTerminated">Check to Terminate</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="text-right mt-2">
              <button class="btn btn-outline-danger mr-3" (click)="inAddNewServiceBulletinMode = false">Cancel</button>
              <button class="btn btn-outline-primary">Add Bulletin</button>
            </div>
          </form>

          <hr class="mt-1">
        </div>
      </div>

      <!--EXISTING BULLETINS-->
      <section *ngIf="!inAddNewServiceBulletinMode">
        <div>
          <!---NO SERVICE BULLETIN-->
          <div class="alert alert-warning text-center" role="alert"
            *ngIf="(!aircraftServiceBulletinSearchForm.get('queryString').value) && allAircraftServiceBulletins.length < 1 && !ServiceBulletinsLoading">
            No Service Bulletin on aircraft.
          </div>
          <app-preloader-type-a
            *ngIf="(!aircraftServiceBulletinSearchForm.get('queryString').value) && (allAircraftServiceBulletins.length < 1) && ServiceBulletinsLoading">
          </app-preloader-type-a>

          <div
            *ngIf="allAircraftServiceBulletins.length > 0 || aircraftServiceBulletinSearchForm.get('queryString').value">
            <form [formGroup]="aircraftServiceBulletinSearchForm">
              <div class="form-group form-inline">
                Filter:
                <input class="form-control ml-2" formControlName="queryString" type="text" name="query" />
                <span class="ml-3" *ngIf="ServiceBulletinsLoading">Searching...</span>
              </div>
            </form>

            <!---NO SERVICE BULLETIN IN FILTER-->
            <div class="alert alert-warning text-center" role="alert"
              *ngIf="!ServiceBulletinsLoading && (allAircraftServiceBulletins.length < 1)">
              No Service Service Bulletin matches your query.
            </div>

            <app-preloader-type-a *ngIf="ServiceBulletinsLoading"></app-preloader-type-a>

            <ng-container *ngIf="!ServiceBulletinsLoading">
              <table class="table table-striped w-100 mb-3 pb-3" *ngIf="allAircraftServiceBulletins.length > 0">
                <tr *ngFor="let bulletin of allAircraftServiceBulletins; index as i">
                  <div class="p-3">
                    <h5 class="clearfix">
                      {{ ((page-1)*pageSize) + (i + 1 )}}:
                      {{bulletin.service_bulletin.bulletinName}}
                      <!-- <ng-container *ngIf="bulletin.isRecurring"> -->
                        <span *ngIf="bulletin.terminateAction" class="text-success"
                          ngbPopover="This is a terminated Service Bulletin." triggers="mouseenter:mouseleave"
                          popoverTitle="TERMINATED">
                          <i class="la la-ban font-weight-bold"></i>
                        </span>
  
                        <span *ngIf="!bulletin.terminateAction && bulletin.isRecurring" class="text-info"
                          ngbPopover="This is a recurring Service Bulletin." triggers="mouseenter:mouseleave"
                          popoverTitle="RECURRING">
                          <i class="la la-refresh font-weight-bold"></i>
                        </span>
                      <!-- </ng-container> -->
  
  
                      <!--SB Document View Link-->
                      <ng-container>
                        <button
                          (click)="openModal(content, bulletin.service_bulletin.fileUrl, bulletin.service_bulletin.bulletinName)"
                          class="mr-2 btn btn-danger btn-sm rounded" *ngIf="bulletin.service_bulletin.fileUrl">
                          <small class="d-block text-white">
                            <i class="la la-file-pdf-o"></i>
                            View Document
                          </small>
                        </button>
  
                        <span class="badge badge-light rounded p-2" *ngIf="!bulletin.service_bulletin.fileUrl">
                          <small class="d-block text-muted">
                            <i class="la la-file-pdf-o"></i>
                            No Document
                          </small>
                        </span>
                      </ng-container>
  
  
                      <span class="float-right">
                        <button class="btn btn-outline-primary rounded mr-3"
                          *ngIf="bulletin.id !== editAircraftServiceBulletin.id"
                          (click)="loadAircraftBulletinForEdit(bulletin)">
                          <i class="la la-edit"></i>
                        </button>
                        <button class="btn btn-outline-primary rounded mr-3"
                          *ngIf="bulletin.id == editAircraftServiceBulletin.id" (click)="unLoadBulletinForEdit()">
                          <i class="la la-times"></i>
                        </button>
  
                        <button class="btn btn-outline-danger rounded" (click)="deleteAircraftServiceBulletin(bulletin)">
                          <i class="la la-trash"></i>
                        </button>
                      </span>
                    </h5>
                    <div class="col-12 mb-1">
                      <small class="text-muted pr-2">Manufacturers: </small>
                      {{bulletin.service_bulletin? getBulletinModelManufacturers(bulletin.service_bulletin) : '----'}}
                    </div>
  
                    <div class="col-12 mb-1">
                      <small class="text-muted pr-2">Subject: </small>
                      {{bulletin.service_bulletin.subject? bulletin.service_bulletin.subject : '----'}}
                    </div>
  
                    <div class="row">
                      <div class="my-2 col-6 col-sm-6 col-md-4 col-lg-2 border-right">
                        <small class="d-block font-weight-bold">Published Date</small>
                        {{ bulletin.service_bulletin.publishedDate? (bulletin.service_bulletin.publishedDate | date:'MM/dd/yyyy') : '-- -- --' }}
                      </div>
                      <div class="my-2 col-6 col-sm-6 col-md-4 col-lg-2 border-right">
                        <small class="d-block font-weight-bold">Due Date</small>
                        {{ bulletin.dueDate | date:'MM/dd/yyyy' }}
                      </div>
                      <div class="my-2 col-6 col-sm-6 col-md-4 col-lg-2 border-right">
                        <small class="d-block font-weight-bold">Due Hours</small>
                        {{ getHoursOrNA(bulletin.dueHours) }}
                      </div>
                      <div class="my-2 col-6 col-sm-6 col-md-4 col-lg-2 border-right">
                        <small class="d-block font-weight-bold">Condition</small>
                        {{ bulletin.condition }}
                      </div>
                      <div class="my-2 col-6 col-sm-6 col-md-4 col-lg-2 border-right">
                        <small class="d-block font-weight-bold">Recurring</small>
                        <!-- <ng-container *ngIf="bulletin.isRecurring"> -->  
                          <span *ngIf="bulletin.terminateAction" class="text-success font-weight-bold"
                            ngbPopover="This is a terminated Service Bulletin." triggers="mouseenter:mouseleave"
                            popoverTitle="TERMINATED">
                            <i class="la la-ban"></i>
                            TERMINATED
                          </span>

                          <ng-container *ngIf="!bulletin.terminateAction">
                            <span *ngIf="bulletin.isRecurring" class="text-info font-weight-bold"
                              ngbPopover="This is a recurring Service Bulletin." triggers="mouseenter:mouseleave"
                              popoverTitle="Recurring">
                              <i class="la la-refresh"></i>
                              YES
                            </span>

                            <span *ngIf="!bulletin.isRecurring" class="text-dark font-weight-bold">
                              NO
                            </span>
                              
                          </ng-container>
                        <!-- </ng-container> -->
  
                      </div>
                      <div class="my-2 col-6 col-sm-6 col-md-4 col-lg-2">
                        <small class="d-block font-weight-bold">Applicability</small>
                        <div *ngIf="bulletin.application">
                          <div class="
                          {{bulletin.terminateAction? 'text-success': '' }}
                          {{bulletin.isRecurring && !bulletin.terminateAction? 'text-info': '' }}
                          {{(bulletin.application == 'Performed') && !bulletin.isRecurring? 'text-success': '' }}
                          {{bulletin.application == 'NotApply'? 'text-primary': '' }}
                          {{bulletin.application == 'Applies'? 'text-info': '' }}
                          ">
                            <span class="font-weight-bold">
                              {{ bulletin.application | uppercase}}
                            </span>
                          </div>
                          <small class="d-block text-pimary"
                            *ngIf="bulletin.lastPerformedDate && (bulletin.application == 'Performed')">
                            {{bulletin.lastPerformedDate | date:'MM/dd/yyyy' }}
                            {{bulletin.lastPerformedHour? (' / ' + bulletin.lastPerformedHour + 'Hrs') : '' }}
                          </small>
                          <small class="d-block text-pimary"
                            *ngIf="bulletin.notApplyReason && (bulletin.application == 'NotApply')">
                            {{bulletin.notApplyReason }}
                          </small>
                        </div>
                      </div>
  
                      <!--Performance note-->
                      <div class="col-12" *ngIf="bulletin.performanceNote">
                        <small class="d-block p-2 text-dark bg-light border-gray border rounded m-2 allow_wrap">
                          <b>NOTE:</b> {{bulletin.performanceNote}}
                        </small>
                      </div>
  
                    </div>
                    <!--Edit AD-->
                    <div class="card my-3 bg-light border-danger rounded"
                      *ngIf="bulletin.id == editAircraftServiceBulletin.id">
                      <div class="card-body">
  
                        <app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>
  
                        <form [formGroup]="editAircraftServiceBulletinForm" *ngIf="!isWorking"
                          (ngSubmit)="updateAircraftServiceBulletin()">
                          <div class="row">
                            <div class="col-sm-12 col-lg-3 col-md-6">
                              <div class="form-group">
                                <small class="form-text text-muted">
                                  Bulletin Name {{editAircraftServiceBulletinSearching? '...searching...' : ''}}
                                </small>
                                {{editAircraftServiceBulletin.service_bulletin.bulletinName}}
                              </div>
                            </div>
  
                            <div class="col-sm-12 col-lg-3 col-md-6">
                              <div class="form-group">
                                <small class="form-text text-muted">Published Date</small>
                                <div class="input-group">
                                  {{ editAircraftServiceBulletin.service_bulletin.publishedDate? (editAircraftServiceBulletin.service_bulletin.publishedDate | date:'MM/dd/yyyy') : '-- -- --'}}
                                </div>
                              </div>
                            </div>
  
                            <div class="col-sm-12 col-lg-3 col-md-6">
                              <div class="form-group">
                                <small class="form-text text-muted">Update Due Date</small>
                                <div class="input-group">
                                  <input class="form-control" formControlName="editDueDate" placeholder="MM/dd/yyyy"
                                    name="edit_duedate" ngbDatepicker #editDueDate="ngbDatepicker">
                                  <div class="input-group-append">
  
                                    <!--  calendar button -->
                                    <button class="btn btn-outline-secondary calendar" (click)="editDueDate.toggle()"
                                      type="button">
                                      <i class="la la-calendar-plus-o"></i>
                                    </button>
                                  </div>
                                </div>
  
                                <!-- due now button -->
                                <button class="btn btn-sm btn-info calendar w-100" (click)="dueNowSBUpdate($event)" type="button">
                                  Due Now
                                </button>
  
                                <small class="form-text text-danger d-block"
                                  *ngIf="editAircraftServiceBulletinForm.get('editDueDate').invalid && (editAircraftServiceBulletinForm.get('editDueDate').touched || editAircraftServiceBulletinForm.get('editDueDate').dirty)">
                                  Enter Valid Due Date (and/or Due Hours)
                                </small>
                              </div>
                            </div>
  
                            <div class="col-sm-12 col-lg-3 col-md-6">
                              <div class="form-group">
                                <small class="form-text text-muted">Update Due Hours</small>
                                <div class="input-group">
                                  <input class="form-control" formControlName="editDueHours" placeholder="Due Hours"
                                    name="edit_dueHours">
                                </div>
                                <small class="form-text text-danger d-block"
                                  *ngIf="editAircraftServiceBulletinForm.get('editDueHours').invalid && (editAircraftServiceBulletinForm.get('editDueHours').touched || editAircraftServiceBulletinForm.get('editDueHours').dirty)">
                                  Enter Valid Due Hours (and/or Due Date)
                                </small>
                              </div>
                            </div>
  
                            <div class="col-sm-12 col-lg-6 col-md-6">
                              <div class="form-group">
                                <small class="form-text text-muted required">Update Condition</small>
                                <div class="input-group">
                                  <select class="form-control" formControlName="editCondition" placeholder="MM/dd/yyyy"
                                    name="edit_condition">
                                    <option *ngFor="let condition of conditionOptions.conditions"
                                      [selected]="condition.value == editAircraftServiceBulletinForm.controls['editCondition'].value"
                                      [value]="condition.value">
                                      {{condition.label}}
                                    </option>
                                  </select>
                                </div>
                                <small class="form-text text-danger d-block"
                                  *ngIf="editAircraftServiceBulletinForm.hasError('required', 'editCondition') && (editAircraftServiceBulletinForm.get('editCondition').touched || editAircraftServiceBulletinForm.get('editCondition').dirty)">
                                  Select Condition
                                </small>
                                <small class="form-text text-danger d-block"
                                  *ngIf="editAircraftServiceBulletinForm.hasError('allControlsNeededIfValueIs', 'editCondition') &&!(editAircraftServiceBulletinForm.hasError('required', 'editCondition'))">
                                  You need to enter both <b>Due Date</b> and <b>Due Hours</b>
                                </small>
                              </div>
                            </div>
  
                            <div class="col-sm-12 col-lg-6 col-md-6">
                              <div class="form-group">
                                <small class="form-text text-muted">Update Frequency</small>
                                <div class="form-check form-check-inline mt-2">
                                  <input class="form-check-input" type="checkbox" id="edit_isRecurring"
                                    formControlName="editIsRecurring">
                                  <label class="form-check-label" for="edit_isRecurring">Recurring?</label>
                                </div>
                              </div>
                            </div>
  
                            <div class="col-sm-12 col-lg-6 col-md-5">
                              <div class="form-group">
                                <small class="form-text text-muted required">Update Status for Aircraft?</small>
                                <div class="input-group">
                                  <select class="form-control" formControlName="editApplication"
                                    placeholder="Status for Aircraft" name="edit_application">
                                    <option *ngFor="let app of applicationOptions.applications"
                                      [selected]="editAircraftServiceBulletinForm.controls['editApplication'].value == app.value"
                                      [ngValue]="app.value">
                                      {{app.label}}
                                    </option>
                                  </select>
                                </div>
                                <div
                                  *ngIf="editAircraftServiceBulletinForm.controls['editApplication'].touched || editAircraftServiceBulletinForm.controls['editApplication'].dirty">
                                  <small class="form-text text-danger d-block"
                                    *ngIf="editAircraftServiceBulletinForm.hasError('required', 'editApplication')">
                                    Select Status for Aircraft
                                  </small>
                                  <small class="form-text text-danger d-block"
                                    *ngIf="editAircraftServiceBulletinForm.hasError('hasDependantIfControlIs_Performed', 'editApplication')">
                                    Fill <b>Performed Date</b> field.
                                  </small>
                                  <small class="form-text text-danger d-block"
                                    *ngIf="editAircraftServiceBulletinForm.hasError('hasDependantIfControlIs_NotApply', 'editApplication')">
                                    Fill <b>Not Apply</b> field.
                                  </small>
                                </div>
                              </div>
                            </div>
  
                            <div class="col-sm-12 col-lg-6 col-md-7">
                              <div class="form-group"
                                *ngIf="editAircraftServiceBulletinForm.get('editApplication').value == 'NotApply'">
                                <small class="form-text text-muted required">Does not apply reason</small>
                                <div class="input-group">
                                  <input class="form-control" formControlName="editNotApplyReason"
                                    placeholder="Not Apply Reason" name="edit_notApplyReason">
                                </div>
                                <small class="form-text text-danger d-block"
                                  *ngIf="editAircraftServiceBulletinForm.get('editNotApplyReason').invalid && (editAircraftServiceBulletinForm.get('editNotApplyReason').touched || editAircraftServiceBulletinForm.get('editNotApplyReason').dirty)">
                                  Enter 'Not Apply' reason (Max 1,000 chars)
                                </small>
                              </div>
  
                              <div class="row"
                                *ngIf="editAircraftServiceBulletinForm.get('editApplication').value == 'Performed'">
                                <div class="col-7">
                                  <div class="form-group">
                                    <small class="form-text text-muted required">Performed Date</small>
                                    <div class="input-group">
                                      <input class="form-control" formControlName="editPerformedDate"
                                        placeholder="MM/dd/yyyy" name="edit_performedDate" ngbDatepicker
                                        #editPerformed_date="ngbDatepicker">
                                      <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar"
                                          (click)="editPerformed_date.toggle()" type="button">
                                          <i class="la la-calendar-plus-o"></i>
                                        </button>
                                      </div>
                                    </div>
                                    <small class="form-text text-danger d-block"
                                      *ngIf="editAircraftServiceBulletinForm.get('editPerformedDate').invalid && (editAircraftServiceBulletinForm.get('editPerformedDate').touched || editAircraftServiceBulletinForm.get('editPerformedDate').dirty)">
                                      Enter Date Performed
                                    </small>
                                  </div>
                                </div>
  
                                <div class="col-5">
                                  <div class="form-group">
                                    <small class="form-text text-muted">Performed Hours</small>
                                    <div class="input-group">
                                      <input class="form-control" formControlName="editPerformedHour"
                                        placeholder="e.g 1200.5" name="editPerformedHour">
                                    </div>
                                    <small class="form-text text-danger d-block"
                                      *ngIf="editAircraftServiceBulletinForm.get('editPerformedHour').invalid && (editAircraftServiceBulletinForm.get('editPerformedHour').touched || editAircraftServiceBulletinForm.get('editPerformedHour').dirty)">
                                      Hours to tenths(e.g 1200.5)
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
  
                            <div class="col-12"
                              *ngIf="editAircraftServiceBulletinForm.get('editApplication').value == 'Performed'">
                              <div class="row">
  
                                <div class="col-12">
                                  <div class="form-group">
                                    <small class="form-text text-muted">Performance Note</small>
                                    <div class="input-group">
                                      <textarea class="form-control w-100" formControlName="editPerformanceNote"
                                        name="editPerformanceNote" id="editPerformanceNote" placeholder="Performance Note"
                                        rows="4"></textarea>
                                    </div>
                                    <small class="form-text text-danger" *ngIf="editAircraftServiceBulletinForm.get('editPerformanceNote').invalid">
                                      Maximum 3,000 characters exceeded.
                                    </small>
                                  </div>
                                </div>
  
                                <div class="col-12">
                                  <div class="form-group">
                                    <small class="form-text text-muted">Terminating Action</small>
                                    <div class="form-check form-check-inline mt-2">
                                      <input class="form-check-input" type="checkbox" id="editIsTerminated"
                                        formControlName="editIsTerminated">
                                      <label class="form-check-label" for="editIsTerminated">Terminate?</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
  
                          </div>
                          <div class="text-right mt-2">
                            <button class="btn btn-outline-danger mr-3" (click)="unLoadBulletinForEdit()">Cancel</button>
                            <button type="submit" class="btn btn-outline-primary">Update Bulletin</button>
                          </div>
                        </form>
  
                        <hr class="mt-1">
                      </div>
                    </div>
                  </div>
                </tr>
              </table>
  
              <div class="d-flex justify-content-between p-2">
                <ngb-pagination [collectionSize]="totalAircraftSb" [(page)]="page" [pageSize]="pageSize"
                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                  (pageChange)="paginationPageChange($event)">
                </ngb-pagination>
              </div>
            </ng-container>
           

          </div>
        </div>
      </section>
    </div>
  </div>
</section>



<!--PDF VIEWER--->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{viewedPdfSbTitle}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-pdf-viewer [pdfSrc]="viewedPdfSrc"></app-pdf-viewer>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>
