import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter, NgZone } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { AircraftLogBookService } from '../../../../../app/services/aircraft-log-book.service';
import { AlertPopUpsService } from '../../../../../app/services/alert-pop-ups.service';
import { UtilitiesService } from '../../../../../app/services/utilities.service';

@Component({
  selector: 'app-uploader-task',
  templateUrl: './uploader-task.component.html',
  styleUrls: ['./uploader-task.component.scss']
})
export class UploaderTaskComponent implements OnInit, OnChanges {
  @Input() file: File;
  @Input() tailNo: string;
  @Input() docType: string;
  @Output() resetFormEvent: EventEmitter<boolean> = new EventEmitter(false);

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadUrl: string;

  constructor(
    private _ngZone: NgZone,
    private logBookService: AircraftLogBookService,
    private utilsService: UtilitiesService,
    private popUpService: AlertPopUpsService,
    private storage: AngularFireStorage,
    private db: AngularFirestore
  ) { }

  ngOnInit() {
    // this.startUpload();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tailNo && changes.tailNo.currentValue) {
      this.tailNo = changes.tailNo.currentValue;
      this.startUpload();
    }
  }

  startUpload() {
    if (this.tailNo.length < 1) {
      return;
    }

    // storage path
    // const path = `${this.tailNo}/${this.docType}/${Date.now()}_${this.file.name}`;
    const path = `${this.tailNo}/${this.docType}/import.pdf`;

    // reference to storage bucket
    const ref = this.storage.ref(path);

    // start upload progress
    this.task = this.storage.upload(path, this.file);

    // monitor progress
    this.percentage = this.task.percentageChanges();

    this.snapshot = this.task.snapshotChanges().pipe(
      tap(console.log),
      // file downkloads url
      finalize ( async () => {
        this.downloadUrl = await ref.getDownloadURL().toPromise();

        this.db.collection('files').add({downloadUrl : this.downloadUrl, path});
        this.utilsService.updateShowMainPreloader(true);
        try {
          this._ngZone.run(
            () => {
              this.utilsService.updateShowMainPreloader(true);
            }
          );

          const tt = await this.finalizeUploadOnServer().toPromise();
          this.popUpService.openBasicPopUp(
              'Upload complete',
              'Document uploaded and processed.',
              'success'
            );
            this._ngZone.run(
              () => {
                this.utilsService.updateShowMainPreloader(false);
                this.resetFormEvent.emit(true);
              }
            );
        } catch (error) {
            this.popUpService.openBasicPopUp(
              'Error encountered',
              'Document uploaded and but final processing failed. Please retry upload',
              'error'
            );
            this._ngZone.run(
              () => {
                this.utilsService.updateShowMainPreloader(false);
                this.resetFormEvent.emit(true);
              }
            );
        }
      })
    );
  }

  isActive(snapshot) {
    if (!snapshot) {
      return true;
    }

    return snapshot.state === 'running'
      && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  finalizeUploadOnServer() {
    return this.logBookService.finalizeUploadOnServer(this.tailNo, this.docType);
  }

  emitResetUploadForm() {
    this.task.cancel();
    this.resetFormEvent.emit(true);
  }

}
