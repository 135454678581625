import { SubscriptionLike } from 'rxjs';
import { UserMenuAircraftModel } from './../../../models/user-menu-aircraft-model';
import { UserAircraftModel } from './../../../models/user-aircraft-model';
import { AircraftService } from './../../../services/aircraft.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AircraftV2Model } from '../../../models/airfcraftV2/aircraft-v2.model';
import { UserService } from '../../../services/user.service';
import { UserModel } from '../../../models/user-model';

@Component({
  selector: 'app-aircraft-dashboard-view',
  templateUrl: './aircraft-dashboard-view.component.html',
  styleUrls: ['./aircraft-dashboard-view.component.scss']
})
export class AircraftDashboardViewComponent implements OnInit, OnDestroy {
  @Input() isAdminView: boolean;

  constructor(
    private route: ActivatedRoute,
    private aircraftService: AircraftService,
    private userService: UserService,
  ) { }

  theAircraft: AircraftV2Model = new AircraftV2Model;

  currentUser: UserModel = new UserModel;
  currentUser$: SubscriptionLike;

  selectedAircraft$: SubscriptionLike;

  isLoading: boolean;

  ngOnInit() {
    this.isLoading = false;
    
    this.theAircraft.id = undefined;

    this.currentUser$ = this.userService.currentUser$.subscribe(
      (user: UserModel) => {
        this.currentUser = user;
      }
    );

    this.selectedAircraft$ = this.aircraftService.selectedAircraft$.subscribe(
      (aircraft: AircraftV2Model) => {
        this.theAircraft = aircraft;

        // if (this.theAircraftV2.id !== undefined) {
        //   this.getAircraft(this.theAircraftV2.id);
        // } else {
        //   // ('NO AIRCRAFT')
        //   this.isLoading = false;
        // }
      }
    );

  }

  // getAircraft(id: number) {
  //   if (id === null) {
  //     return;
  //   }

  //   this.isLoading = true;
  //   this.aircraftService.getAircraftById(id).subscribe(
  //     (response: any) => {
  //       this.isLoading = false;
  //       if (+response.status.code === 1000) {
  //         this.theAircraft = response.responseData;
  //       }
  //     }
  //   );
  // }

  ngOnDestroy() {
    if (this.currentUser$) {
      this.currentUser$.unsubscribe();
    }
    if (this.selectedAircraft$) {
      this.selectedAircraft$.unsubscribe();
    }
  }
}
