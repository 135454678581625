import { UserModel } from './../../models/user-model';
import { DependsOnControlIfControlIsValidator } from '../../_custom-validators/depends-on-control-if-control-is-validator';
import { AuthService } from './../../services/auth.service';
import { UserAircraftModel } from '../../models/user-aircraft-model';
import { AircraftService } from './../../services/aircraft.service';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { UserService } from './../../services/user.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { UserBaseModel } from '../../models/user-base-model';
import { switchMap, distinctUntilChanged } from 'rxjs/operators';
import { NgbTabsetConfig } from '@ng-bootstrap/ng-bootstrap';
import { of, SubscriptionLike } from 'rxjs';
import { MustBeSameAsControValidator } from '../../_custom-validators/must-be-same-as-control-validator';
import Swal from 'sweetalert2';
import { HasDependantIfControlIsControlValidator } from '../../_custom-validators/has-dependant-if-control-is-validator';
import { UtilitiesService } from '../../services/utilities.service';


@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.scss'],
  providers: [NgbTabsetConfig] // add NgbTabsetConfig to the component providers
})
export class EditCustomerComponent implements OnInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private aircraftService: AircraftService,
    private authService: AuthService,
    private config: NgbTabsetConfig,
    private utilsService: UtilitiesService,
  ) {
    config.justify = 'fill';
  }

  currentUser$ : SubscriptionLike;
  currentUser: UserModel = new UserModel;

  paramsSubscription$: SubscriptionLike;
  doPasswordUpdateSubscriber$: SubscriptionLike;

  theUser: UserBaseModel = new UserBaseModel;
  userAircraft: Array<UserAircraftModel> = [];
  userAircraftCount: number = 0;

  editUserForm: FormGroup;

  isInfoEditMode: boolean = false;
  isWorkingPasswordReset: boolean = false;

  ngOnInit() {
    // subscribe to curent user
    this.currentUser$ = this.userService.currentUser$.subscribe(
      (user) => {
        this.currentUser = user;
      }
    );

    // observe the activated route parameters, and get user details on change
    this.paramsSubscription$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => of(params.get('customerId')))
    ).subscribe(
      (id) => {
        this.theUser.id = +id;
        this.getUserDetails();
      }
    )
    ;
    // so the subscription, wrapped in a function
    // this.doGetUserSubscribe(paramIdObserver);

    // create edit formgroup
    this.initEditUserForm();
  }

  initEditUserForm() {
    this.editUserForm = this.formBuilder.group({
      'firstName': new FormControl(
        null,
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z][A-Za-z ,.'-]+$/)
        ]
      ),
      'lastName': new FormControl(
        null,
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z][A-Za-z ,.'-]+$/)
        ]
      ),
      'emailAddress': new FormControl (
        null,
        [
          Validators.required,
          Validators.email
        ]
      ),
      'mobilePhone': new FormControl(
        null,
        [
          // Validators.required,
          // Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
          Validators.pattern(/^\+?[1-9]\d{1,14}$/)
        ]
      ),
      'isAdmin': new FormControl(this.theUser.isAdmin),
      'pilotLicenseNo': new FormControl(
        null,
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(8)
        ]
      ),
      'doPasswordUpdate': new FormControl(null),
      'password1': new FormControl(null),
      'password2': new FormControl(null),
    });


    this.editUserForm.get('password1').setValidators([
      DependsOnControlIfControlIsValidator(
        this.editUserForm.get('doPasswordUpdate'),
        true
      ),
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.[(!@#\$%\^&\*)*]?)(?=.{6,})/),
      MustBeSameAsControValidator(this.editUserForm.get('password2'), false)
    ]);

    this.editUserForm.get('password2').setValidators([
      MustBeSameAsControValidator(this.editUserForm.get('password1'))
    ]);

    this.editUserForm.get('doPasswordUpdate').setValidators([
      HasDependantIfControlIsControlValidator(
          this.editUserForm.get('password1'),
          true
        )
      ]
    );

    this.doPasswordUpdateSubscriber$ = this.editUserForm.get('doPasswordUpdate').valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(
      (value: any) => {
        if (!value) {
          this.editUserForm.get('password1').setValue(null);

          this.editUserForm.get('password2').setValue(null);
        }

        this.editUserForm.get('password1').updateValueAndValidity();
        this.editUserForm.get('password2').updateValueAndValidity();
      }
    );
  }

  getUserDetails() {
    this.userService.getUserById(this.theUser.id).subscribe(
      (response: any) => {
        if (+response.status.code === 1000) {
          this.theUser = response.responseData;

          this.getUserAircrafts();
        }
      },
      (error) => {

      }
    );
  }


  loadEditUserForm() {
    // this.editUserForm.reset();

    // update form fields
    this.editUserForm.controls['firstName'].setValue(this.theUser.firstName);
    this.editUserForm.controls['lastName'].setValue(this.theUser.lastName);
    this.editUserForm.controls['emailAddress'].setValue(this.theUser.emailAddress);
    this.editUserForm.controls['mobilePhone'].setValue(this.theUser.mobilePhone);
    this.editUserForm.controls['isAdmin'].setValue(this.theUser.isAdmin);
    this.editUserForm.controls['pilotLicenseNo'].setValue(this.theUser.pilotLicenseNo);
    this.editUserForm.controls['doPasswordUpdate'].setValue(false);
    this.editUserForm.controls['password1'].setValue(null);
    this.editUserForm.controls['password2'].setValue(null);

    this.isInfoEditMode = true;
  }


  getUserAircrafts() {
    this.aircraftService.getUserAircraft(this.theUser.id).subscribe(
      (response: any) => {
        this.userAircraft = response.responseData.rows;
        this.userAircraftCount = response.responseData.count;
      },
      (error) => {

      }
    );
  }


  doUpdateUser() {
    if (!this.editUserForm.valid) {

      Object.values(this.editUserForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      Swal.fire('Check important fields!', 'Form not filled correctly!', 'error');
      return;
    }

    this.theUser.firstName = this.editUserForm.controls['firstName'].value;
    this.theUser.lastName = this.editUserForm.controls['lastName'].value;
    this.theUser.emailAddress = this.editUserForm.controls['emailAddress'].value;
    this.theUser.mobilePhone = this.editUserForm.controls['mobilePhone'].value;
    this.theUser.isAdmin = this.editUserForm.controls['isAdmin'].value;
    this.theUser.pilotLicenseNo = this.editUserForm.controls['pilotLicenseNo'].value;

    if (this.editUserForm.get('doPasswordUpdate').value === true) {
      this.theUser['newPassword'] = this.editUserForm.get('password1').value;
    }

    this.userService.createOrUpdateUser(this.theUser).subscribe(
      (response: any) => {
        this.isInfoEditMode = false;
        this.getUserDetails();

        Swal.fire(
          'Great!',
          'User details updated',
          'success'
        );
      },
      (error: any) => {
        Swal.fire(
          'Oops!',
          'Update user failed.',
          'error'
        );
      }
    );
  }

  requestPasswordReset() {
    Swal.fire({
      title: 'Confirm sending a password reset link?',
      text: `This will send a password reset link to  ${this.theUser.firstName} ?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes. Go ahead!',
      cancelButtonText: 'No, cancel this.'
    }).then((descision) => {
      if (descision.value) {
        this.isWorkingPasswordReset = true;
        this.authService.forgotPassword(this.theUser.emailAddress).subscribe(
          (response: any) => {
            this.isWorkingPasswordReset = false;
            if (response.status.code === 1000) {
              Swal.fire(
                'Sent!', `Reset password link sent to ${this.theUser.emailAddress}.`, 'success'
              );
            } else {
              Swal.fire(
                'Sorry!', 'Failed to send reset password link.', 'error'
              );
            }

          },
          (error: any) => {
            this.isWorkingPasswordReset = false;
            if (error.status.message) {
              Swal.fire(
                error.status.message,
                error.responseData.join(' '),
                'error'
              );
            } else {
              Swal.fire(
                'Oops!',
                'Failed to send reset password link.',
                'error'
              );
            }
          }
        );
      } else if (descision.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });

  }

  formatPhoneNumber(phoneNumber: string) {
    return this.utilsService.formatPhoneNumber(phoneNumber);
  }

  toggleInEditMode() {
    this.isInfoEditMode = !this.isInfoEditMode;
  }

  ngOnDestroy() {
    if (this.paramsSubscription$) {
      this.paramsSubscription$.unsubscribe();
    }

    if (this.doPasswordUpdateSubscriber$) {
      this.doPasswordUpdateSubscriber$.unsubscribe();
    }

    if (this.currentUser$) {
      this.currentUser$.unsubscribe();
    }
  }

}
