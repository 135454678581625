import { CustomerService } from './../../services/customer.service';
import { AircraftService } from './../../services/aircraft.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserAircraftModel } from '../../models/user-aircraft-model';
import { startWith, tap } from 'rxjs/operators';
import { SubscriptionLike } from 'rxjs';


class BaseSearchModel {
  constructor (){
    this.id = null;
  }
  id: number;
  isAdmin: boolean;
  firstName: string;
  lastName: string;
  pilotLicenseNo: string;
}

class UserSearchModel extends BaseSearchModel {
  constructor (){
    super();
    this.aircraft[0] = new UserAircraftModel;
  }
  aircraft: Array<UserAircraftModel>;
}

class AircraftSearchModel extends BaseSearchModel {
  constructor (){
    super();
    this.aircraft = new UserAircraftModel;
  }
  aircraft: UserAircraftModel;
}

@Component({
  selector: 'app-admin-console',
  templateUrl: './admin-console.component.html',
  styleUrls: ['./admin-console.component.scss']
})
export class AdminConsoleComponent implements OnInit, OnDestroy {

  constructor(
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private aircraftService: AircraftService,
  ) { }

  usernameQuery: string;

  filterSearchForm: FormGroup;
  filterQuery: string;
  allUserResults: Array<UserSearchModel> = [];
  allUserCount: number;

  filterResults: Array<AircraftSearchModel> = [];
  filterResultsLoading: boolean = false;

  filterUserListSubscriber$: SubscriptionLike;

  allPage: number = 1;
  allPageSize: number = 50;

  filterPage: number = 0;
  filterPageSize: number = 50;
  filterResultsCount: number;

  ngOnInit() {
    console.log('DISCONTINUED COMPONENT!!!!!!');

    this.filterSearchForm = this.formBuilder.group({
      'filterQuery': new FormControl(this.filterQuery)
    })

    this.filterUserListSubscriber$ = this.filterSearchForm.controls['filterQuery'].valueChanges.pipe(
      startWith(''),
      tap((x)=> this.filterResultsLoading = true)
    ).subscribe(
      (query)=>{
        this.filterQuery = query;
        if(!query){
          this.getAllCustomers();
        }
        else{
          this.filterSearchByNameEmailTailSerialOrSn()
        }
      }
    );
  }
  
  getAllCustomers(){
    this.customerService.getAllCustomers(this.allPage, this.allPageSize).subscribe(
      (response: any)=>{
        if(response.status.code == 1000){
          this.filterResults = [];
          this.allUserResults = response.responseData.rows;
          this.allUserCount = response.responseData.count;
        }
      }
    )
  }
  
  filterSearchByNameEmailTailSerialOrSn(){
    this.customerService.filterCustomerByNameEmailTailSerialOrSn(this.filterSearchForm.controls['filterQuery'].value, this.filterPage, this.filterPageSize).subscribe(
      (response: any)=>{
        if(response.status.code == 1000){
          this.allUserResults = [];
          this.filterResults = response.responseData.rows;
          this.filterResultsCount = response.responseData.count;
        }
      }
    )
  }

  ngOnDestroy() {
    if(this.filterUserListSubscriber$) this.filterUserListSubscriber$.unsubscribe();
  }

}
