import { Router } from '@angular/router';
import { AirworthinessDirectiveModel } from './../../../../models/airworthiness-directive-model';
import { NgbDateCustomAndUSAParserFormatter } from './../../../../services/ngb-date-custom-and-usa-parser-formatter';
import { AdApplicationOptionsModel } from './../../../../models/ad-application-options-model';
import { AdSbConditionOptionsModel } from './../../../../models/ad-sb-condition-options-model';
import { UtilitiesService } from './../../../../services/utilities.service';
import { AircraftAirDirectiveModel } from '../../../../models/aircraft-air-directive-model';
import { UserAircraftModel } from '../../../../models/user-aircraft-model';
import { SubscriptionLike, of, Observable } from 'rxjs';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { AirworthinessDirectiveService } from '../../../../services/airworthiness-directive.service';
import { AircraftService } from '../../../../services/aircraft.service';
import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { switchMap, startWith, tap, debounceTime, distinctUntilChanged, catchError, map } from 'rxjs/operators';
import { NgbTypeaheadSelectItemEvent, NgbDateAdapter, NgbDateNativeAdapter, NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { OneOfControlsNeededValidator } from '../../../../_custom-validators/one-of-controls-needed-validator';
import { NumberToTenths } from '../../../../_custom-validators/number-to-tenths-validator';
import { DependsOnControlIfControlIsValidator } from '../../../../_custom-validators/depends-on-control-if-control-is-validator';
import { HasDependantIfControlIsControlValidator } from '../../../../_custom-validators/has-dependant-if-control-is-validator';
import { AllControlsNeededIfValueIsValidator } from '../../../../_custom-validators/all-controls-needed-if-value-is-validator';
import { OneOfControlsIsNeededIgnoreIfAnotherControlIsValueValidator } from '../../../../_custom-validators/one-of-controls-is-needed-ignore-if-another-control-is-value-validator';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { DocumentConfigurationUiComponent } from '../../../../shared/document-configuration-ui/document-configuration-ui.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-manage-dashboard-directives',
  templateUrl: './manage-dashboard-directives.component.html',
  styleUrls: ['./manage-dashboard-directives.component.scss'],
  providers: [
    NgbDatepickerConfig,
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomAndUSAParserFormatter}
  ]
})

export class ManageDashboardDirectivesComponent implements OnInit, OnDestroy, OnChanges {
  @Input () aircraftId: number;
  @Input () tabChanges: Observable<void>;
  constructor(
    private ngbDatePickerConfig: NgbDatepickerConfig,
    private aircraftService: AircraftService,
    private airworthinessDirectiveService: AirworthinessDirectiveService,
    private formBuilder: FormBuilder,
    private utilitiesService: UtilitiesService,
    private modalService: NgbModal,
    private location: Location,
    private router: Router
  ) {
    ngbDatePickerConfig.minDate = {year: 1900, month: 1, day: 1};
  }


  viewedPdfSrc: string;
  viewedPdfAdTitle: string;

  isWorking = false;

  tabChangesSubscription$: SubscriptionLike;

  conditionOptions: AdSbConditionOptionsModel = new AdSbConditionOptionsModel;

  applicationOptions: AdApplicationOptionsModel = new AdApplicationOptionsModel;

  theAircraft: UserAircraftModel = new UserAircraftModel;

  allAircraftAirDirectives: Array<AircraftAirDirectiveModel> = [];

  aircraftAsSearchSubscriber$: SubscriptionLike;
  aircraftAirDirectiveSearchForm: FormGroup;
  aircraftAirDirectiveFilter: string;
  aircraftDirectivesLoading = false;

  newAircraftAirDirective: AircraftAirDirectiveModel = new AircraftAirDirectiveModel;
  newAircraftAirDirectiveForm: FormGroup;
  newAircraftAirDirectiveSearchFailed = false;
  newAircraftAirDirectiveSearching = false;
  inAddNewDirectiveMode = false;

  editAircraftAirDirective: AircraftAirDirectiveModel = new AircraftAirDirectiveModel;
  editAircraftAirDirectiveForm: FormGroup;
  editAircraftAirDirectiveSearchFailed = false;
  editAircraftAirDirectiveSearching = false;
  inEditDirectiveMode = false;

  newDueHoursSubscriber$: SubscriptionLike;
  newDueDateSubscriber$: SubscriptionLike;
  newApplicationSubscriber$: SubscriptionLike;
  newTerminatingActionSubscriber$: SubscriptionLike;
  newAirDirectiveFormSubscriber$: SubscriptionLike;

  editDueHoursSubscriber$: SubscriptionLike;
  editDueDateSubscriber$: SubscriptionLike;
  editApplicationSubscriber$: SubscriptionLike;
  editTerminatingActionSubscriber$: SubscriptionLike;
  editAirDirectiveFormSubscriber$: SubscriptionLike;

  totalAircraftAd: number = null;
  page = 1;
  pageSize = 25;

  ngOnInit() {
    // init aircraft object
    this.getAircraft();

    // add tab parameter in url
    const fullUrl: Array<string> = this.router.url.split('?');
    if (fullUrl[0]) {
      this.location.go( fullUrl[0] + '?t=ad');
    }

    this.tabChangesSubscription$ = this.tabChanges.subscribe(
      () => {
        this.inAddNewDirectiveMode = false;
      }
    );

    this.aircraftAirDirectiveSearchForm = this.formBuilder.group({
      'queryString': new FormControl('')
    });

    this.aircraftAsSearchSubscriber$ = this.aircraftAirDirectiveSearchForm.controls['queryString'].valueChanges.pipe(
      startWith(''),
      tap((x) => this.aircraftDirectivesLoading = true)
    ).subscribe(
      (query) => {
        this.resetPage();
        this.fetchAllAircraftAirworthinessDirectiveByName(query);
      }
    );

    this.initNewDirectiveForm();
    this.initEditDirectiveForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.aircraftId && changes.aircraftId.currentValue) {
      this.aircraftId = changes.aircraftId.currentValue;

      this.initNewDirectiveForm();
      this.initEditDirectiveForm();

      this.resetPage();

      this.fetchAllAircraftAirworthinessDirectiveByName();
    }
  }

  resetPage() {
    this.page = 1;
  }

  getHoursOrNA(val) {
    return val === null ? 'N/A' : `${val} Hours`;
  }

  initNewDirectiveForm() {
    this.newAircraftAirDirectiveForm = this.formBuilder.group({
      'directive': new FormControl('', [Validators.required]),
      'effectiveDate': new FormControl(null, [Validators.required]),
      'dueDate': new FormControl(null),
      'dueHours': new FormControl(null),
      'condition': new FormControl('NA'),
      'application': new FormControl('Applies'),
      'isRecurring': new FormControl(''),
      'notApplyReason': new FormControl('', [Validators.maxLength(1000)]),
      'compliedDate': new FormControl(null),
      'compliedHour': new FormControl(''),
      'isTerminated': new FormControl(false),
      'complianceNote': new FormControl('', [Validators.maxLength(3000)]),
    });

    // ?
    this.newAircraftAirDirectiveForm.get('compliedDate').setValidators([
      DependsOnControlIfControlIsValidator(this.newAircraftAirDirectiveForm.get('application'), 'Complied')
    ]);

    // ?
    this.newAircraftAirDirectiveForm.get('application').setValidators([
      Validators.required,
      HasDependantIfControlIsControlValidator(this.newAircraftAirDirectiveForm.get('compliedDate'), 'Complied'),
      HasDependantIfControlIsControlValidator(this.newAircraftAirDirectiveForm.get('notApplyReason'), 'NotApply')
    ]);

    // ?
    this.newAircraftAirDirectiveForm.get('notApplyReason').setValidators([
      DependsOnControlIfControlIsValidator(this.newAircraftAirDirectiveForm.get('application'), 'NotApply')
    ]);

    // ?
    this.newAircraftAirDirectiveForm.get('dueDate').setValidators([
      OneOfControlsIsNeededIgnoreIfAnotherControlIsValueValidator(
        this.newAircraftAirDirectiveForm.get('application'),
        'NotApply',
        [this.newAircraftAirDirectiveForm.get('dueHours')],
        [this.newAircraftAirDirectiveForm.get('isTerminated')]
      )
    ]);

    // ?
    this.newAircraftAirDirectiveForm.get('dueHours').setValidators([
      OneOfControlsIsNeededIgnoreIfAnotherControlIsValueValidator(
        this.newAircraftAirDirectiveForm.get('application'),
        'NotApply',
        [this.newAircraftAirDirectiveForm.get('dueDate')],
        [this.newAircraftAirDirectiveForm.get('isTerminated')]
      ),
      NumberToTenths(true)
    ]);

    // ?
    this.newAircraftAirDirectiveForm.get('compliedHour').setValidators([,
      NumberToTenths(true)
    ]);

    // ?
    this.newAircraftAirDirectiveForm.get('condition').setValidators([
      Validators.required,
      AllControlsNeededIfValueIsValidator(
        [
          this.newAircraftAirDirectiveForm.get('dueDate'),
          this.newAircraftAirDirectiveForm.get('dueHours')
        ],
        'WhichOccursFirst'
      ),
      AllControlsNeededIfValueIsValidator(
        [
          this.newAircraftAirDirectiveForm.get('dueDate'),
          this.newAircraftAirDirectiveForm.get('dueHours')
        ],
        'WhichHappensLast'
      )
    ]);

    // ?
    this.newDueHoursSubscriber$ = this.newAircraftAirDirectiveForm.get('dueHours').valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(
      (value: any) => {
        this.newAircraftAirDirectiveForm.get('condition').updateValueAndValidity();
      }
    );

    // ?
    this.newDueDateSubscriber$ = this.newAircraftAirDirectiveForm.get('dueDate').valueChanges
    .pipe(
      distinctUntilChanged()
    ).subscribe(
      (value: any) => {
        this.newAircraftAirDirectiveForm.get('condition').updateValueAndValidity();
      }
    );

    // ?
    this.newTerminatingActionSubscriber$ = this.newAircraftAirDirectiveForm.get('isTerminated').valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(
      (value: any) => {
        this.newAircraftAirDirectiveForm.get('dueHours').updateValueAndValidity();
        this.newAircraftAirDirectiveForm.get('dueDate').updateValueAndValidity();
      }
    );

    // ?
    this.newApplicationSubscriber$ = this.newAircraftAirDirectiveForm.get('application').valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(
      (value: any) => {
        this.newAircraftAirDirectiveForm.get('dueHours').updateValueAndValidity();
        this.newAircraftAirDirectiveForm.get('dueDate').updateValueAndValidity();

        if (value == 'NotApply') {
          this.newAircraftAirDirectiveForm.get('condition').disable();
          this.newAircraftAirDirectiveForm.get('isRecurring').disable();
          this.newAircraftAirDirectiveForm.get('isRecurring').setValue(false);
        } else {
          this.newAircraftAirDirectiveForm.get('condition').enable();
          this.newAircraftAirDirectiveForm.get('isRecurring').enable();
        }

        if (value !== 'Complied') {
          if (this.newAircraftAirDirectiveForm.get('isTerminated').value) {
            this.newAircraftAirDirectiveForm.get('isTerminated').setValue(false);
          }

          if (this.newAircraftAirDirectiveForm.get('compliedDate').value) {
            this.newAircraftAirDirectiveForm.get('compliedDate').setValue(null);
          }

          if (this.newAircraftAirDirectiveForm.get('compliedHour').value) {
            this.newAircraftAirDirectiveForm.get('compliedHour').setValue(null);
          }

          if (this.newAircraftAirDirectiveForm.get('complianceNote').value) {
            this.newAircraftAirDirectiveForm.get('complianceNote').setValue(null);
          }
        }
      }
    );

    // ?
    this.newAirDirectiveFormSubscriber$ = this.newAircraftAirDirectiveForm.valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(
      (value: any) => {

        if (
          this.newAircraftAirDirectiveForm.get('isTerminated').value &&
            (this.newAircraftAirDirectiveForm.get('condition').value !== 'NA')
        ) {
          this.newAircraftAirDirectiveForm.get('condition').setValue('NA');
        }

        if (
          (this.newAircraftAirDirectiveForm.get('application').value == 'NotApply') &&
            (this.newAircraftAirDirectiveForm.get('condition').value !== 'NA')
        ) {
          this.newAircraftAirDirectiveForm.get('condition').setValue('NA');
        }
      }
    );
  }

  // ?
  toggleInNewDirectiveMode() {
    this.inAddNewDirectiveMode = !this.inAddNewDirectiveMode;

    if (!this.inAddNewDirectiveMode) {
      this.newAircraftAirDirectiveForm.reset();
    }
  }

  //
  initEditDirectiveForm() {
    this.editAircraftAirDirectiveForm = this.formBuilder.group({
      'editDueDate': new FormControl(null),
      'editDueHours': new FormControl(null),
      'editCondition': new FormControl(null),
      'editApplication': new FormControl(null),
      'editIsRecurring': new FormControl(null),
      'editNotApplyReason': new FormControl(null, [Validators.maxLength(1000)]),
      'editCompliedDate': new FormControl(null),
      'editCompliedHour': new FormControl(null),
      'editIsTerminated': new FormControl(false),
      'editComplianceNote': new FormControl(false, [Validators.maxLength(3000)])
    });

    this.editAircraftAirDirectiveForm.get('editCompliedDate').setValidators([
      DependsOnControlIfControlIsValidator(this.editAircraftAirDirectiveForm.get('editApplication'), 'Complied')
    ]);

    this.editAircraftAirDirectiveForm.get('editApplication').setValidators([
      Validators.required,
      HasDependantIfControlIsControlValidator(this.editAircraftAirDirectiveForm.get('editCompliedDate'), 'Complied'),
      HasDependantIfControlIsControlValidator(this.editAircraftAirDirectiveForm.get('editNotApplyReason'), 'NotApply')
    ]);

    this.editAircraftAirDirectiveForm.get('editNotApplyReason').setValidators([
      DependsOnControlIfControlIsValidator(this.editAircraftAirDirectiveForm.get('editApplication'), 'NotApply')
    ]);

    this.editAircraftAirDirectiveForm.get('editDueDate').setValidators([
      OneOfControlsIsNeededIgnoreIfAnotherControlIsValueValidator(
        this.editAircraftAirDirectiveForm.get('editApplication'),
        'NotApply',
        [this.editAircraftAirDirectiveForm.get('editDueHours')],
        [this.editAircraftAirDirectiveForm.get('editIsTerminated')]
      )
    ]);

    this.editAircraftAirDirectiveForm.get('editDueHours').setValidators([
      OneOfControlsIsNeededIgnoreIfAnotherControlIsValueValidator(
        this.editAircraftAirDirectiveForm.get('editApplication'),
        'NotApply',
        [this.editAircraftAirDirectiveForm.get('editDueDate')],
        [this.editAircraftAirDirectiveForm.get('editIsTerminated')]
      ),
      NumberToTenths(true)
    ]);

    this.editAircraftAirDirectiveForm.get('editCompliedHour').setValidators([
      NumberToTenths(true)
    ]);

    this.editAircraftAirDirectiveForm.get('editCondition').setValidators([
      Validators.required,
      AllControlsNeededIfValueIsValidator(
        [
          this.editAircraftAirDirectiveForm.get('editDueDate'),
          this.editAircraftAirDirectiveForm.get('editDueHours')
        ],
        'WhichOccursFirst'
      ),
      AllControlsNeededIfValueIsValidator(
        [
          this.editAircraftAirDirectiveForm.get('editDueDate'),
          this.editAircraftAirDirectiveForm.get('editDueHours')
        ],
        'WhichHappensLast'
      )
    ]);

    this.editDueHoursSubscriber$ = this.editAircraftAirDirectiveForm.get('editDueHours').valueChanges.subscribe(
      (value: any) => {
        this.editAircraftAirDirectiveForm.get('editCondition').updateValueAndValidity();
      }
    );

    this.editDueDateSubscriber$ = this.editAircraftAirDirectiveForm.get('editDueDate').valueChanges.subscribe(
      (value: any) => {
        this.editAircraftAirDirectiveForm.get('editCondition').updateValueAndValidity();
      }
    );

    // subscribe to TerminatingAction: Update Duehours & DueDateon valueChange
    this.editTerminatingActionSubscriber$ = this.editAircraftAirDirectiveForm.get('editIsTerminated').valueChanges.subscribe(
      (value: any) => {
        this.editAircraftAirDirectiveForm.get('editDueHours').updateValueAndValidity();
        this.editAircraftAirDirectiveForm.get('editDueDate').updateValueAndValidity();
      }
    );

    // Subscribe to Application Dropdown for valueChange...
    this.editApplicationSubscriber$ = this.editAircraftAirDirectiveForm.get('editApplication').valueChanges.subscribe(
      (value: any) => {
        this.editAircraftAirDirectiveForm.get('editDueHours').updateValueAndValidity();
        this.editAircraftAirDirectiveForm.get('editDueDate').updateValueAndValidity();

        if (value == 'NotApply') {
          this.editAircraftAirDirectiveForm.get('editCondition').disable();
          this.editAircraftAirDirectiveForm.get('editIsRecurring').disable();
          this.editAircraftAirDirectiveForm.get('editIsRecurring').setValue(false);
        } else {
          this.editAircraftAirDirectiveForm.get('editCondition').enable();
          this.editAircraftAirDirectiveForm.get('editIsRecurring').enable();
        }

        if (value !== 'Complied') {
          if (this.editAircraftAirDirectiveForm.get('editIsTerminated').value) {
            this.editAircraftAirDirectiveForm.get('editIsTerminated').setValue(false);
          }

          if (this.editAircraftAirDirectiveForm.get('editCompliedDate').value) {
            this.editAircraftAirDirectiveForm.get('editCompliedDate').setValue(null);
          }

          if (this.editAircraftAirDirectiveForm.get('editCompliedHour').value) {
            this.editAircraftAirDirectiveForm.get('editCompliedHour').setValue(null);
          }

          if (this.editAircraftAirDirectiveForm.get('editComplianceNote').value) {
            this.editAircraftAirDirectiveForm.get('editComplianceNote').setValue(null);
          }
        }
      }
    );

    // Subscribe to EditAirDirectiveForm for valueChange...
    this.editAirDirectiveFormSubscriber$ = this.editAircraftAirDirectiveForm.valueChanges.subscribe(
      (value: any) => {

        // if isRecurring and isTerminateAction are both true, set condition to NA
        if (
          // this.editAircraftAirDirectiveForm.get('editIsRecurring').value && 
          this.editAircraftAirDirectiveForm.get('editIsTerminated').value && 
          (this.editAircraftAirDirectiveForm.get('editCondition').value !== 'NA')
        ) {
          this.editAircraftAirDirectiveForm.get('editCondition').setValue('NA');
        }

        // if Application is NOTAPPLY, set condition to NA
        if (
          (this.editAircraftAirDirectiveForm.get('editApplication').value == 'NotApply') &&
          (this.editAircraftAirDirectiveForm.get('editCondition').value !== 'NA')
        ) {
          this.editAircraftAirDirectiveForm.get('editCondition').setValue('NA');
        }
      }
    );

  }

  /**
   * @description brings up add directives modal
   */
  openAddAirworthinessDirectivesModal() {
    // open modal with document config component as display argument
    const addAirworthinessDirectivesModal = this.modalService.open(DocumentConfigurationUiComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'document-config-ui',
      size: 'lg' });

    // add instance name
    addAirworthinessDirectivesModal.componentInstance.name = 'Airworthiness Directives Selector';

    // add aircraft variable
    addAirworthinessDirectivesModal.componentInstance.aircraft = this.theAircraft;

    // add the document type
    addAirworthinessDirectivesModal.componentInstance.type = 'airworthiness directives';

    addAirworthinessDirectivesModal.result.then((result) => {
      console.log(`Closed with: ${result}`);
      this.aircraftDirectivesLoading = true;
      this.fetchAllAircraftAirworthinessDirectiveByName();
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
      this.aircraftDirectivesLoading = true;
      this.fetchAllAircraftAirworthinessDirectiveByName();
    });
  }

  getAircraft() {
    this.aircraftService.getAircraftById(this.aircraftId).subscribe(
      (response: any) => {
        if (response.status.code == 1000) {
          this.theAircraft = response.responseData;
        }
      }
    );
  }

  fetchAllAircraftAirworthinessDirectiveByName(query: string = '') {
    this.aircraftDirectivesLoading = true;

    this.airworthinessDirectiveService.fetchAllAircraftAirworthinessDirectiveByName(
      this.aircraftId, query, this.page, this.pageSize
    ).subscribe(
      (response: any) => {
        if (+response.status.code === 1000) {
          this.aircraftDirectivesLoading = false;
          this.allAircraftAirDirectives = response.responseData.rows;
          this.totalAircraftAd = response.responseData.count;
        }
      }
    );
  }

  ////////// ADD DIRECTIVE SEARCH TYPEHEAD ///////////
  newAircraftAirDirectiveTypeHeadSearch = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.newAircraftAirDirectiveSearching = true),
    switchMap(term => {
      return this.airworthinessDirectiveService.findAirworthinessDirectiveByName(term).pipe(
        tap(() => this.newAircraftAirDirectiveSearchFailed = false),
        catchError(() => {
          this.newAircraftAirDirectiveSearchFailed = true;
          return of([]);
        }),
        map((res: any) => {
        const arr: Array<any> = [];
          res.responseData.rows.forEach(element => {
            arr.push(element);
          });
          return arr;
        }),
      );
    }
  ),
  tap(() => this.newAircraftAirDirectiveSearching = false)
  );
  //

  //from getters for validations
  get directive() { return this.newAircraftAirDirectiveForm.controls['directive']; }
  //from getters for validations
  get application() { return this.newAircraftAirDirectiveForm.controls['application']; }

  //On Select typehead(make) options...
  onTypeHeadSelected(ev: NgbTypeaheadSelectItemEvent){
    this.newAircraftAirDirective.airworthiness_directive = ev.item as AirworthinessDirectiveModel;
    this.newAircraftAirDirectiveForm.controls['effectiveDate'].setValue(ev.item.effectiveDate);
  }
  //format typeheads data received,  before display
  typeHeadDirectiveFormatter = (directiveObj: {id: string, directiveName: string, effectiveDate: string, subject: string}) => {
    return directiveObj.directiveName ? directiveObj.directiveName : '';
  };

  addAircraftAirDirective() {
    if (this.newAircraftAirDirectiveForm.controls['application'].value == 'Applies') {
      this.newAircraftAirDirectiveForm.controls['compliedDate'].setValue(null);
      this.newAircraftAirDirectiveForm.controls['notApplyReason'].setValue(null);
    }

    if (this.newAircraftAirDirectiveForm.controls['application'].value == 'Complied') {
      this.newAircraftAirDirectiveForm.controls['notApplyReason'].setValue(null);
    }

    if (this.newAircraftAirDirectiveForm.controls['application'].value == 'NotApply') {
      this.newAircraftAirDirectiveForm.controls['compliedDate'].setValue(null);
    }

    if (this.newAircraftAirDirectiveForm.controls['application'].value !==  'Complied') {
      this.newAircraftAirDirectiveForm.controls['isTerminated'].setValue(false);
      this.newAircraftAirDirectiveForm.controls['compliedDate'].setValue(null);
      this.newAircraftAirDirectiveForm.controls['compliedHour'].setValue(null);
      this.newAircraftAirDirectiveForm.controls['complianceNote'].setValue(null);
    }

    if (!this.newAircraftAirDirectiveForm.valid) {
      Object.values(this.newAircraftAirDirectiveForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      Swal.fire('Check important fields!', 'Form not filled correctly!', 'error');
      return;
    }

    this.isWorking = true;

    this.newAircraftAirDirective.dueDate = this.newAircraftAirDirectiveForm.controls['dueDate'].value;
    this.newAircraftAirDirective.dueHours = this.newAircraftAirDirectiveForm.controls['dueHours'].value;
    this.newAircraftAirDirective.condition = this.newAircraftAirDirectiveForm.controls['condition'].value;
    this.newAircraftAirDirective.application = this.newAircraftAirDirectiveForm.controls['application'].value;
    this.newAircraftAirDirective.isRecurring = this.newAircraftAirDirectiveForm.controls['isRecurring'].value;
    this.newAircraftAirDirective.terminateAction = this.newAircraftAirDirectiveForm.controls['isTerminated'].value;
    this.newAircraftAirDirective.notApplyReason = this.newAircraftAirDirective.application == 'NotApply' ? 
      this.newAircraftAirDirectiveForm.controls['notApplyReason'].value : null;

    this.newAircraftAirDirective.lastCompliedDate = this.newAircraftAirDirective.application == 'Complied' ?
     this.newAircraftAirDirectiveForm.controls['compliedDate'].value : null;

    this.newAircraftAirDirective.lastCompliedHour = this.newAircraftAirDirective.application == 'Complied' ?
      this.newAircraftAirDirectiveForm.controls['compliedHour'].value : null;

    this.newAircraftAirDirective.complianceNote = this.newAircraftAirDirective.application == 'Complied' ?
      this.newAircraftAirDirectiveForm.controls['complianceNote'].value : null;

    this.newAircraftAirDirective.aircraftId = this.aircraftId;


    this.airworthinessDirectiveService.addAircraftAirworthinessDirective(this.newAircraftAirDirective).subscribe(
      (response: any) => {

        this.isWorking = false;
        if (response.status.code == 1000) {
          this.inAddNewDirectiveMode = false;
          this.newAircraftAirDirectiveForm.reset();
          this.editAircraftAirDirective = new AircraftAirDirectiveModel;
          this.resetPage();
          this.fetchAllAircraftAirworthinessDirectiveByName();
          Swal.fire(
            'Great!', 'Airworthiness Directive added to dashboard.', 'success'
          );
        } else {
          Swal.fire(
            'Yikes!', 'Airworthiness Directive not added to dashboard.', 'error'
          );
        }

      },
      (error: any) => {

        this.isWorking = false;
        const errorResponse = error.error ? error.error : error;
        if (errorResponse.status.message) {
          Swal.fire(
            errorResponse.status.message,
            errorResponse.responseData.join(' '),
            'error'
          );
        } else {
          Swal.fire(
            'Oops!',
            'Failed to add Airworthiness Directive to Aircraft Dashboard.',
            'error'
          );
        }
      }
    );
  }



  // Edit
  loadDirectiveForEdit(directive: AircraftAirDirectiveModel) {
    this.editAircraftAirDirective = directive;

    this.editAircraftAirDirectiveForm.get('editDueDate').setValue(
      directive.dueDate ? this.utilitiesService.parseISOtoDateObj(directive.dueDate) : null
    );

    this.editAircraftAirDirectiveForm.get('editDueHours').setValue(
      directive.dueHours === null ? null : directive.dueHours
    );

    this.editAircraftAirDirectiveForm.get('editCondition').setValue(
      (directive.condition ? directive.condition : 'NA'),
      [Validators.required]
    );

    this.editAircraftAirDirectiveForm.get('editApplication').setValue(
      (directive.application ? directive.application : 'Applies'),
      [Validators.required]
    );

    this.editAircraftAirDirectiveForm.get('editIsRecurring').setValue(directive.isRecurring);

    this.editAircraftAirDirectiveForm.get('editIsTerminated').setValue(directive.terminateAction);

    this.editAircraftAirDirectiveForm.get('editNotApplyReason').setValue(directive.notApplyReason);

    this.editAircraftAirDirectiveForm.get('editCompliedDate').setValue(
      directive.lastCompliedDate ? this.utilitiesService.parseISOtoDateObj(directive.lastCompliedDate) : null
    );

    this.editAircraftAirDirectiveForm.get('editCompliedHour').setValue(
      directive.lastCompliedHour ? directive.lastCompliedHour : null
    );

    this.editAircraftAirDirectiveForm.get('editComplianceNote').setValue(
      directive.complianceNote ? directive.complianceNote : null
    );

  }


  //On Select Edit typehead(make) options...
  onEditTypeHeadSelected(ev: NgbTypeaheadSelectItemEvent){
    this.editAircraftAirDirective.airworthiness_directive = ev.item as AirworthinessDirectiveModel;
    this.editAircraftAirDirectiveForm.controls['editEffectiveDate'].setValue(ev.item.effectiveDate);
  }


  unLoadDirectiveForEdit() {
    this.editAircraftAirDirective = new AircraftAirDirectiveModel;
    this.editAircraftAirDirectiveForm.reset();
  }

  updateAircraftAirDirective() {
    if (this.editAircraftAirDirectiveForm.controls['editApplication'].value == 'Applies') {
      this.editAircraftAirDirectiveForm.controls['editCompliedDate'].setValue(null);
      this.editAircraftAirDirectiveForm.controls['editNotApplyReason'].setValue(null);
    }

    if (this.editAircraftAirDirectiveForm.controls['editApplication'].value == 'Complied') {
      this.editAircraftAirDirectiveForm.controls['editNotApplyReason'].setValue(null);
    }

    if (this.editAircraftAirDirectiveForm.controls['editApplication'].value == 'NotApply') {
      this.editAircraftAirDirectiveForm.controls['editDueDate'].setValue(null);
      this.editAircraftAirDirectiveForm.controls['editDueHours'].setValue(null);
      this.editAircraftAirDirectiveForm.controls['editCompliedDate'].setValue(null);
    }

    if (this.editAircraftAirDirectiveForm.controls['editApplication'].value !== 'Complied') {
      this.editAircraftAirDirectiveForm.controls['editIsTerminated'].setValue(false);
      this.editAircraftAirDirectiveForm.controls['editCompliedDate'].setValue(null);
      this.editAircraftAirDirectiveForm.controls['editCompliedHour'].setValue(null);
      this.editAircraftAirDirectiveForm.controls['editComplianceNote'].setValue(null);
    }

    if (!this.editAircraftAirDirectiveForm.valid) {
      Object.values(this.editAircraftAirDirectiveForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      Swal.fire('Check important fields!', 'Form not filled correctly!', 'error');
      return;
    }

    this.isWorking = true;

    this.editAircraftAirDirective.dueDate = this.editAircraftAirDirectiveForm.controls['editDueDate'].value;

    this.editAircraftAirDirective.dueHours = this.editAircraftAirDirectiveForm.controls['editDueHours'].value === null ? null :
      (
        this.editAircraftAirDirectiveForm.controls['editDueHours'].value === '' ?
        null : this.editAircraftAirDirectiveForm.controls['editDueHours'].value
      );


    if (this.editAircraftAirDirective.application.toLowerCase() === 'notapply') {
      this.editAircraftAirDirective.dueHours = null;
    }


    this.editAircraftAirDirective.condition = this.editAircraftAirDirectiveForm.controls['editCondition'].value;
    this.editAircraftAirDirective.isRecurring = this.editAircraftAirDirectiveForm.controls['editIsRecurring'].value;
    this.editAircraftAirDirective.terminateAction = this.editAircraftAirDirectiveForm.controls['editIsTerminated'].value;
    this.editAircraftAirDirective.application = this.editAircraftAirDirectiveForm.controls['editApplication'].value;
    this.editAircraftAirDirective.notApplyReason = this.editAircraftAirDirective.application == 'NotApply' ?
          this.editAircraftAirDirectiveForm.controls['editNotApplyReason'].value : '';
    this.editAircraftAirDirective.lastCompliedDate = this.editAircraftAirDirective.application == 'Complied' ?
          this.editAircraftAirDirectiveForm.controls['editCompliedDate'].value : null;
    this.editAircraftAirDirective.lastCompliedHour = this.editAircraftAirDirective.application == 'Complied' ?
          this.editAircraftAirDirectiveForm.controls['editCompliedHour'].value : null;
    this.editAircraftAirDirective.complianceNote = this.editAircraftAirDirective.application == 'Complied' ?
          this.editAircraftAirDirectiveForm.controls['editComplianceNote'].value : null;
    this.editAircraftAirDirective.aircraftId = this.aircraftId;

    this.airworthinessDirectiveService.updateAircraftAirworthinessDirective(this.editAircraftAirDirective).subscribe(
      (response: any) => {

        this.isWorking = false;
        if (response.status.code == 1000) {
          this.inEditDirectiveMode = false;
          this.editAircraftAirDirectiveForm.reset();
          this.editAircraftAirDirective = new AircraftAirDirectiveModel;

          /**
           * retain page, but filter with current query.
           * This will keep same list in view
          **/
          this.fetchAllAircraftAirworthinessDirectiveByName(
            this.aircraftAirDirectiveSearchForm.controls['queryString'].value
          );
          Swal.fire(
            'Great!', 'Airworthiness Directive updated on dashboard.', 'success'
          );
        } else {
          Swal.fire(
            'Yikes!', 'Airworthiness Directive not updated.', 'error'
          );
        }

      },
      (error: any) => {

        this.isWorking = false;
        const errorResponse = error.error ? error.error : error;
        if (errorResponse.status.message) {
          Swal.fire(
            errorResponse.status.message,
            errorResponse.responseData.join(' '),
            'error'
          );
        } else {
          Swal.fire(
            'Oops!',
            'Failed to update Airworthiness Directive.',
            'error'
          );
        }
      }
    );
  }

  onTabChange() {
    this.inAddNewDirectiveMode = false;
    this.newAircraftAirDirectiveForm.reset();
  }

  deleteAircraftAirworthinessDirective(directive: AircraftAirDirectiveModel) {
    Swal.fire({
      title: 'Are you sure?',
      text: `Please confirm you want to delete directive ${directive.airworthiness_directive.directiveName} ?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes. I know what I\'m doing!',
      cancelButtonText: 'No, take me back.'
    }).then((descision) => {
      if (descision.value) {

        this.airworthinessDirectiveService.deleteAircraftAirworthinessDirective(directive.id).subscribe(
          (response: any) => {
            if (response.status.code == 1000) {
              this.inEditDirectiveMode = false;
              this.editAircraftAirDirective = new AircraftAirDirectiveModel;
              this.resetPage();
              this.fetchAllAircraftAirworthinessDirectiveByName();
              Swal.fire(
                'Deleted!', 'Airworthiness Directive deleted from dashboard.', 'success'
              );
            } else {
              Swal.fire(
                'Yikes!', 'Airworthiness Directive not deleted.', 'error'
              );
            }

          },
          (error: any) => {

            if (error.status.message) {
              Swal.fire(
                error.status.message,
                error.responseData.join(' '),
                'error'
              );
            } else {
              Swal.fire(
                'Oops!',
                'Failed to delete Airworthiness Directive.',
                'error'
              );
            }
          }
        );
      } else if (descision.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });

  }

  paginationPageChange($event) {
    this.page = $event;
    this.fetchAllAircraftAirworthinessDirectiveByName(this.aircraftAirDirectiveSearchForm.get('queryString').value);
  }

  ngOnDestroy() {
    if (this.aircraftAsSearchSubscriber$) { this.aircraftAsSearchSubscriber$.unsubscribe(); }
    if (this.tabChangesSubscription$) { this.tabChangesSubscription$.unsubscribe(); }

    if (this.newDueHoursSubscriber$) { this.newDueHoursSubscriber$.unsubscribe(); }
    if (this.newDueDateSubscriber$) { this.newDueDateSubscriber$.unsubscribe(); }
    if (this.newApplicationSubscriber$) { this.newApplicationSubscriber$.unsubscribe(); }
    if (this.newTerminatingActionSubscriber$) { this.newTerminatingActionSubscriber$.unsubscribe(); }
    if (this.newAirDirectiveFormSubscriber$) { this.newAirDirectiveFormSubscriber$.unsubscribe(); }

    if (this.editDueHoursSubscriber$) { this.editDueHoursSubscriber$.unsubscribe(); }
    if (this.editDueDateSubscriber$) { this.editDueDateSubscriber$.unsubscribe(); }
    if (this.editApplicationSubscriber$) { this.editApplicationSubscriber$.unsubscribe(); }
    if (this.editTerminatingActionSubscriber$) { this.editTerminatingActionSubscriber$.unsubscribe(); }
    if (this.editAirDirectiveFormSubscriber$) { this.editAirDirectiveFormSubscriber$.unsubscribe(); }
  }


  openModal(content, pdfUrl: string, title: string = 'View Directive') {
    this.viewedPdfSrc = pdfUrl;
    this.viewedPdfAdTitle = title;

    this.modalService.open(content, { size: 'lg' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  getDirectiveModelManufacturers(directive: AirworthinessDirectiveModel){
    return this.aircraftService.getSbOrAdModelManufacturers(directive)
  }

  dueNowADNew(event) {
    // get current date time
    const _date = new Date();
    this.newAircraftAirDirectiveForm.get('dueDate').setValue(_date)
  }

  dueNowADUpdate(event) {
    // get current date time
    const _date = new Date();
    this.editAircraftAirDirectiveForm.get('editDueDate').setValue(_date)
  }
}
