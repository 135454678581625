import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'configuredDocuments'
})
export class ConfiguredDocumentsPipe implements PipeTransform {
  /**
   * @description filter configured documents from documents array
   * @param documents 
   * @param args 
   */
  transform(documents: any[], args?: any): any[] {
    // conifured documents array
    const configuredDocuments = [];

    // iterate documents array
    documents.forEach((document) => {
      // check if applicability form is filled out
      if (document.applicabilityForm !== undefined && document.applicabilityForm !== null) {
        // add to configured documents array
        configuredDocuments.push(document);
      }
    });

    // return configured Documents array
    return configuredDocuments;
  }
}
