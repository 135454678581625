import { AlertPopUpsService } from './../../../../services/alert-pop-ups.service';
import { UserService } from './../../../../services/user.service';
import { FlightLogsService } from './../../../../services/flight-logs.service';
import { AircraftDashboardReferencesModel } from './../../../../models/aircraft-dashboard-references-model';
import { UtilitiesService } from './../../../../services/utilities.service';
import { Component, OnInit, Input, OnDestroy, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { RagType } from './../../../../models/rag-type';
import * as moment from 'moment';
import { UserModel } from '../../../../models/user-model';
import { SubscriptionLike } from 'rxjs';
import { AircraftV2Model } from '../../../../models/airfcraftV2/aircraft-v2.model';

@Component({
  selector: 'app-pilot-status',
  templateUrl: './pilot-status.component.html',
  styleUrls: ['./pilot-status.component.scss']
})
export class PilotStatusComponent implements OnInit, OnDestroy, OnChanges {
  @Input() theAircraft: AircraftV2Model;
  @Input() isAdminView: boolean;

  @Output() isCompleted: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    private userService: UserService,
    private utilitiesService: UtilitiesService,
    private flightLogsService: FlightLogsService,
    private popUpsService: AlertPopUpsService
  ) {}

  isLoading: boolean;
  dashReferences: AircraftDashboardReferencesModel;

  todayFlightDate: string = this.utilitiesService.dateToISOString(new Date());
  serverEpochTime: number = new Date().getTime();

  medicalDueLastDueDateMoment: moment.Moment = null;
  medicalDueNextDueDateMoment: moment.Moment = null;
  medicalDueRagStatus: RagType = null;

  flightReviewLastDueDateMoment: moment.Moment = null;
  flightReviewNextDueDateMoment: moment.Moment = null;
  flightReviewRagStatus: RagType = null;

  currentUser: UserModel;
  currentUser$: SubscriptionLike;

  medicalDone: boolean;
  flightReviewDone: boolean;

  ngOnInit() {

    this.currentUser = new UserModel;

    this.currentUser$ = this.userService.currentUser$.subscribe(
      (user: UserModel) => {
        this.currentUser = user;

        this.getReferenceData();
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.theAircraft && changes.theAircraft.currentValue) {
      const newAircraft = changes.theAircraft.currentValue;

      if (newAircraft.id && newAircraft.id !== this.theAircraft.id) {
        this.theAircraft = newAircraft;
        this.getReferenceData();
      }
    }
  }

  getReferenceData() {
    this.isLoading = true;
    this.emitCompleteStatus();

    if (this.currentUser && this.currentUser.emailAddress) {
      this.utilitiesService
        .getAircraftDashboardReferences()
        .subscribe((response: any) => {

          this.isLoading = false;

          if (response.status.code === 1000) {
            this.dashReferences = response.responseData;
            this.todayFlightDate = this.dashReferences.serverNow;
            this.serverEpochTime = this.utilitiesService
              .parseISOtoDateObj(this.dashReferences.serverNow)
              .getTime();

            this.getUserPilotStatuses();

            this.medicalDone = false;
            this.flightReviewDone = false;
          }
        });
    }
  }


  emitCompleteStatus() {
    this.isCompleted.emit(
      this.medicalDone && this.flightReviewDone
    );
  }

  getUserPilotStatuses() {
    if (!(this.currentUser.emailAddress)) {
      // console.log(JSON.stringify(this.theAircraft));
      return;
    }

    this.renderMedicalSection();
    this.renderFlightReviewSection();
  }

  renderMedicalSection() {
    const nextDueDateMoment = this.currentUser.medicalDueDate
      ? moment(this.currentUser.medicalDueDate)
      : null;
      const lastDueDateMoment = this.currentUser.lastMedicalDate
      ? moment(this.currentUser.lastMedicalDate)
      : null;

    this.medicalDueLastDueDateMoment = lastDueDateMoment
      ? lastDueDateMoment.clone()
      : null;
    this.medicalDueNextDueDateMoment = nextDueDateMoment
      ? nextDueDateMoment.clone()
      : null;

    if (!nextDueDateMoment) {
      this.medicalDueRagStatus = RagType.a_red;
      this.medicalDone = true;
      this.emitCompleteStatus();
      return;
    }

    // Warning
    const nextWarningManipulator = moment(nextDueDateMoment);
    nextWarningManipulator.subtract(
      this.dashReferences.pilotStatusMedicalWarningDays,
      'days'
    );
    const nextWarningMoment = moment(nextWarningManipulator);

    // get difference
    const todayMoment = moment(this.todayFlightDate);

    const dueDiff = todayMoment.diff(nextDueDateMoment, 'days');
    const warningDiff = todayMoment.diff(nextWarningMoment, 'days');

    if (dueDiff >= 0) {
      this.medicalDueRagStatus = RagType.a_red;
    } else if (warningDiff >= 0) {
      this.medicalDueRagStatus = RagType.b_amber;
    } else {
      this.medicalDueRagStatus = RagType.c_green;
    }

    this.medicalDone = true;
    this.emitCompleteStatus();
  }

  renderFlightReviewSection() {
    const lastDueDateMoment = this.currentUser.lastFlightReviewDate
      ? moment(this.currentUser.lastFlightReviewDate)
      : null;
      const nextDueDateMoment = this.currentUser.flightReviewDueDate
      ? moment(this.currentUser.flightReviewDueDate)
      : null;

    this.flightReviewNextDueDateMoment = nextDueDateMoment
      ? nextDueDateMoment.clone()
      : null;
    this.flightReviewLastDueDateMoment = lastDueDateMoment
      ? lastDueDateMoment.clone()
      : null;

    if (!nextDueDateMoment) {
      this.flightReviewRagStatus = RagType.a_red;
      this.flightReviewDone = true;
      this.emitCompleteStatus();
      return;
    }

    // Warning
    const nextWarningManipulator = moment(nextDueDateMoment);
    nextWarningManipulator.subtract(
      this.dashReferences.pilotStatusFlightReviewWarningDays,
      'days'
    );
    const nextWarningMoment = moment(nextWarningManipulator);

    // get difference
    const todayMoment = moment(this.todayFlightDate);

    const dueDiff = todayMoment.diff(nextDueDateMoment, 'days');
    const warningDiff = todayMoment.diff(nextWarningMoment, 'days');

    if (dueDiff >= 0) {
      this.flightReviewRagStatus = RagType.a_red;
    } else if (warningDiff >= 0) {
      this.flightReviewRagStatus = RagType.b_amber;
    } else {
      this.flightReviewRagStatus = RagType.c_green;
    }
    
    this.flightReviewDone = true;
    this.emitCompleteStatus();
  }

  getRagColor(val: number) {
    if (val === null) {
      return ' ';
      // return RagType[RagType.a_red];
    }

    return RagType[val];
  }

  getPilotStatusBadgeColor() {
    const statusArray: Array<RagType> = [
      this.medicalDueRagStatus,
      this.flightReviewRagStatus
    ];

    statusArray.sort();

    return this.getRagColor(statusArray[0]);
  }

  formatMomentAsUsDateString(momentObject: moment.Moment) {
    return this.utilitiesService.formatMomentAsUsDateString(momentObject);
  }

  getMomentBetweenDates(
    lowerTimeMoment: moment.Moment,
    higherTimeMoment: moment.Moment
  ) {
    return this.utilitiesService.getMomentBetweenDates(
      lowerTimeMoment,
      higherTimeMoment
    );
  }

  getHoursRemainingBetweenHours(lowerHours: number, higherHours: number) {
    return this.utilitiesService.getHoursRemainingBetweenHours(
      lowerHours,
      higherHours
    );
  }

  ngOnDestroy() {
    if (this.currentUser$) {
      this.currentUser$.unsubscribe();
    }
  }
}
