import { NgbDateCustomAndUSAParserFormatter } from './../../../../../services/ngb-date-custom-and-usa-parser-formatter';
import { FlightOilLogsModel } from './../../../../../models/flight-oil-logs-model';
import { FlightFuelLogsModel } from './../../../../../models/flight-fuel-logs-model';
import { FlightSquawkLogsModel } from './../../../../../models/flight-squawk-logs-model';
import { UtilitiesService } from './../../../../../services/utilities.service';
import { FlightLogsService } from './../../../../../services/flight-logs.service';
import { FlightSquawkType } from './../../../../../models/flight-squawk-type-model';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { AircraftFlightLogModel } from './../../../../../models/aircraft-flight-log-model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NumberToTenths } from '../../../../../_custom-validators/number-to-tenths-validator';
import { GreaterThanControlsValidator } from '../../../../../_custom-validators/greater-than-controls-validator';
import { DependsOnControlValidator } from '../../../../../_custom-validators/depends-on-control-validator';
import { NgbDateStruct, NgbDatepickerConfig, NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import Swal from "sweetalert2";
import { HasDependantControlValidator } from '../../../../../_custom-validators/has-dependant-control-validator';

@Component({
  selector: 'app-edit-flight-log',
  templateUrl: './edit-flight-log.component.html',
  styleUrls: ['./edit-flight-log.component.scss'],
  providers: [
    NgbDatepickerConfig,
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomAndUSAParserFormatter}
  ]
})
export class EditFlightLogComponent implements OnInit {
@Input() flightLog: AircraftFlightLogModel;
@Output() cancelEdit: EventEmitter<AircraftFlightLogModel> = new EventEmitter();
@Output() savedSuccessFlightLog: EventEmitter<AircraftFlightLogModel> = new EventEmitter();

  constructor(
    private flightLogService: FlightLogsService,
    private utilitiesService: UtilitiesService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private formBuilder: FormBuilder
  ) { }


  squawkTypesList: Array<FlightSquawkType> = [];

  editFlightLogForm: FormGroup;
  editSquawksList: FormArray;

  serverTodayDate: Date = new Date();
  serverTodayDateNgbDateStruct: NgbDateStruct;

  isLoading: boolean = false;

  ngOnInit() {
    this.flightLogService.getAllSquawkTypes().subscribe(
      (response:any)=>{
        if(response.status.code == 1000){
          this.squawkTypesList = response.responseData;
        }
      }
    )


    this.utilitiesService.getServerDate().subscribe(
      (response:any)=>{
        if(response.status.code == 1000){
          this.serverTodayDate = new Date(response.responseData); ///enforce server time
          this.serverTodayDateNgbDateStruct = this.ngbDateParserFormatter.parse(response.responseData); /// server time plus 3 minutes as max date in date picker
        }
      }
    )

    this.initEditFlightLogForm();
  }

  initEditFlightLogForm(){

    let currentSquaksFormControls: Array<any> = [];


    this.flightLog.flight_squawk_logs.forEach(
      (squawk)=>{
        let squawkFormGroup: FormGroup = this.formBuilder.group({
          "squawkEntry": [squawk.squawkEntry, Validators.required],
          "squawk_type": [squawk.squawk_type_id, Validators.required],
        })

        squawkFormGroup.get('squawkEntry').setValidators([
          DependsOnControlValidator(squawkFormGroup.get('squawk_type')),
          HasDependantControlValidator(squawkFormGroup.get('squawk_type'))
        ])

        squawkFormGroup.get('squawk_type').setValidators([
          DependsOnControlValidator(squawkFormGroup.get('squawkEntry')),
          HasDependantControlValidator(squawkFormGroup.get('squawkEntry'))
        ])

        currentSquaksFormControls.push(squawkFormGroup);

      }
    )


    this.editFlightLogForm = this.formBuilder.group({
      'editFlightDate': new FormControl(
        new Date(this.flightLog.flightDate),
        [Validators.required]
      ),
      'timeOutHours': new FormControl(this.flightLog.timeOutHours, [Validators.required, NumberToTenths(false)]),
      'timeInHours': new FormControl(this.flightLog.timeInHours),
      'preflight_fuel_gallons': new FormControl(
        this.flightLog.flight_fuel_log? this.flightLog.flight_fuel_log.preflightGallons : null,
        [NumberToTenths(true)]
      ),
      'postflight_fuel_gallons': new FormControl(
        this.flightLog.flight_fuel_log? this.flightLog.flight_fuel_log.postflightGallons : null,
        [NumberToTenths(true)]
      ),
      'preflight_oil_quarts': new FormControl(
        this.flightLog.flight_oil_log? this.flightLog.flight_oil_log.preflightOilQuarts : null,
        [NumberToTenths(true)]
      ),
      'postflight_oil_quarts': new FormControl(
        this.flightLog.flight_oil_log? this.flightLog.flight_oil_log.postflightOilQuarts : null,
        [NumberToTenths(true)]
      ),
      'squawks': this.formBuilder.array(currentSquaksFormControls),
      'notes': new FormControl(this.flightLog.notes),
    })

    this.editFlightLogForm.get('timeInHours').setValidators([
      Validators.required,
      GreaterThanControlsValidator(false, [this.editFlightLogForm.get('timeOutHours')]),
      NumberToTenths(false)
    ])


    this.editSquawksList = this.editFlightLogForm.get('squawks') as FormArray;
  }


  createEditSquawkFormGroups(): FormGroup{
    let formGroupSquawk: FormGroup = this.formBuilder.group({
      "squawkEntry": [null],
      "squawk_type": [null]
    })

    formGroupSquawk.get('squawk_type').setValidators([
      DependsOnControlValidator(formGroupSquawk.get('squawkEntry')),
      HasDependantControlValidator(formGroupSquawk.get('squawkEntry'))
    ])

    formGroupSquawk.get('squawkEntry').setValidators([
      DependsOnControlValidator(formGroupSquawk.get('squawk_type')),
      HasDependantControlValidator(formGroupSquawk.get('squawk_type'))
    ])

    return formGroupSquawk;
  }

  get squawksListFormGroups(){
    this.editSquawksList = this.editFlightLogForm.get('squawks') as FormArray;

    return this.editSquawksList;
  }

  getOneSquawkFormGroup(index): FormGroup {
    this.editSquawksList = this.editFlightLogForm.get('squawks') as FormArray;
    const formGroup = this.editSquawksList.controls[index] as FormGroup;
    return formGroup;
  }

  addNewSquawkLogGroup(){
    this.editSquawksList.push(this.createEditSquawkFormGroups())
  }

  removeNewSquawkLogGroup(index: number){
    this.editSquawksList.removeAt(index);
  }

  getNewSquawksFormGroup(index: number): FormGroup {
    this.editSquawksList = this.editFlightLogForm.get('squawks') as FormArray;
    const formGroup = this.editSquawksList.controls[index] as FormGroup;
    return formGroup;
  }

  cancelEditFlightLog(){
    this.cancelEdit.emit(this.flightLog)
  }

  saveEditFlightLog(){
    if(!this.editFlightLogForm.valid){
      Swal.fire("Edited Log Form contains invalid fields!", 'Check all fields and ensure they have been correctly filled', 'error');
      for(let i in this.editFlightLogForm.controls) {
        this.editFlightLogForm.controls[i].markAsTouched();
      };
      return;
    }

    this.editSquawksList = this.editFlightLogForm.get('squawks') as FormArray;
    if(!this.editSquawksList.valid){
      Swal.fire("Squawk entries contains invalid fields!", 'Check all squawks to ensure corresponding types and descriptions have been entered.', 'error');
      for(let i in this.editSquawksList.controls) {
        this.editSquawksList.controls[i].markAsTouched();
      };
      return;
    }


    this.flightLog.aircraftId = this.flightLog.aircraftId;
    this.flightLog.flightDate =  this.editFlightLogForm.controls['editFlightDate'].value;
    this.flightLog.timeInHours =  this.editFlightLogForm.controls['timeInHours'].value;
    this.flightLog.timeOutHours =  this.editFlightLogForm.controls['timeOutHours'].value;

    let updatedFuelLogs:FlightFuelLogsModel = new FlightFuelLogsModel;
    updatedFuelLogs.preflightGallons = this.editFlightLogForm.controls['preflight_fuel_gallons'].value;
    updatedFuelLogs.postflightGallons = this.editFlightLogForm.controls['postflight_fuel_gallons'].value;
    this.flightLog.flight_fuel_log =  updatedFuelLogs;

    let updatedOilLogs:FlightOilLogsModel = new FlightOilLogsModel;
    updatedOilLogs.preflightOilQuarts = this.editFlightLogForm.controls['preflight_oil_quarts'].value;
    updatedOilLogs.postflightOilQuarts = this.editFlightLogForm.controls['postflight_oil_quarts'].value;
    this.flightLog.flight_oil_log =  updatedOilLogs;

    this.flightLog.flight_squawk_logs = [];

    this.flightLog.notes =  this.editFlightLogForm.controls['notes'].value;

    let i = 0;
    while(i < this.editSquawksList.length){

      const g: FormGroup = this.getNewSquawksFormGroup(i);
      let squawkItem: FlightSquawkLogsModel = new FlightSquawkLogsModel;
      squawkItem.squawkEntry = g.controls['squawkEntry'].value;
      squawkItem.squawk_type_id = g.controls['squawk_type'].value;

      this.flightLog.flight_squawk_logs.push(squawkItem);

      i++;
    }


    this.isLoading = true;

    this.flightLogService.updateFlightLogOnAircraft(this.flightLog).subscribe(
      (response:any)=>{
        this.isLoading = false;
        if(response.status.code == 1000){
          this.savedSuccessFlightLog.emit(this.flightLog);
        }
        else{
          Swal.fire(
            "Yikes!", "New flight log not added not updated.", "error"
          )
        }
      },
      (error: any)=>{
        this.isLoading = false;
        if(error.status.message){
          Swal.fire(
            error.status.message,
            error.responseData.join(' '),
            'error'
          )
        }
        else{
          Swal.fire(
            'Oops!',
            'Failed to add new flight log.',
            'error'
          )
        }
      }
    )

  }



}
