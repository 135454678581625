export class OrganizationV2Model {
  constructor() {
    this.id = null;
    this.name = null;
    this.role = null;
  }

  id: number;
  name: string;
  role: string;
  assignmentDate?: string;
}
