<app-preloader-type-a *ngIf="isCreatingOrg"></app-preloader-type-a>

<!--Create New Organization-->
<section class="card" *ngIf="!isCreatingOrg">
  <div class="card-header">
    <h4>Create New Organization</h4>
  </div>
  <div class="card-body">
    <app-preloader-type-a *ngIf="isCreatingOrg"></app-preloader-type-a>

    <form
      [formGroup]="newOrgForm"
      (ngSubmit)="createNewOrg()"
      *ngIf="!isAddingUser"
    >
      <div class="row">
        <div class="column col-12">
          <div class="form-group">
            <small class="form-text text-muted required">Organization Name</small>
            <div class="input-group">
              <input
                class="form-control"
                formControlName="orgName"
                placeholder="Organization Name"
                name="orgName"
              />
            </div>
            <small
              class="text-danger d-block"
              *ngIf="
                newOrgForm.get('orgName').invalid &&
                (newOrgForm.get('orgName').touched ||
                  newOrgForm.get('orgName').dirty)
              "
            >
              Enter valid Organization Name
            </small>
          </div>
        </div>

        <!--No org type for now-->
        <!-- <div class="column col-12">
          <div class="form-group">
            <small class="form-text text-muted required"
              >Organization Type</small
            >
            <div class="input-group">
              <select
                class="form-control"
                formControlName="orgType"
                placeholder="Select Organization Type"
                name="orgType"
              >
                <option
                  *ngFor="let type of orgTypeOptions.options"
                  [value]="type.value"
                >
                  {{ type.label }}
                </option>
              </select>
            </div>
            <small
              class="text-danger d-block"
              *ngIf="
                newOrgForm.get('orgType').invalid &&
                (newOrgForm.get('orgType').touched ||
                  newOrgForm.get('orgType').dirty)
              "
            >
              Select an organization type
            </small>
          </div>
        </div> -->
      </div>
    </form>
  </div>
  <div class="card-footer">
    <button
      type="button"
      class="btn btn-outline-primary mr-3"
      (click)="createNewOrg()"
    >
      Create Organization
    </button>
    <button
      type="button"
      class="btn btn-outline-danger"
      (click)="closeCreatOrgModal()"
    >
      Cancel
    </button>
  </div>
</section>
<!--End new organization form-->
