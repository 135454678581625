import { UtilitiesService } from '../../../../services/utilities.service';
import { OrganizationPersonModel } from '../../../../models/organization-person-model';
import { OrganizationBaseModel } from '../../../../models/organization-base-model';
import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-organization-header-widget',
  templateUrl: './organization-header-widget.component.html',
  styleUrls: ['./organization-header-widget.component.scss']
})
export class OrganizationHeaderWidgetComponent implements OnInit, OnChanges {
  @Input() organization: OrganizationBaseModel;
  @Input() membersList: Array<OrganizationPersonModel>;

  constructor(
    private utilsService: UtilitiesService
  ) { }

  ngOnInit() {
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.membersList && changes.membersList.currentValue) {
      this.membersList = changes.membersList.currentValue;
    }

    if (changes.organization && changes.organization.currentValue) {
      this.organization = changes.organization.currentValue;
    }
  }

  formatPhoneNumber(phoneNumber: string) {
    return this.utilsService.formatPhoneNumber(phoneNumber);
  }

  filterUsersForAdmin() {
    let adminUser = new OrganizationPersonModel;
    this.membersList.some(
      (el, i) => {
        if ( el.role.toUpperCase() === 'ADMIN') {
          adminUser = el;
          return true;
        } else {
          return false;
        }
      }
    );

    return adminUser;
  }

}
