import { UserAircraftModel } from '../../../../models/user-aircraft-model';
import { Component, OnInit, Input } from '@angular/core';
import { AircraftV2Model } from '../../../../models/airfcraftV2/aircraft-v2.model';

@Component({
  selector: 'app-aircraft-basic-info',
  templateUrl: './aircraft-basic-info.component.html',
  styleUrls: ['./aircraft-basic-info.component.scss']
})
export class AircraftBasicInfoComponent implements OnInit {
  @Input() theAircraft: AircraftV2Model;

  constructor() { }

  ngOnInit() {
  }

}
