<ng-container *ngIf="statusOnly">
  <span class="badge badge-pill px-3 py-2 {{getAdminStatusBadgeColor()}}">
  A
  </span>
</ng-container>

<ng-container *ngIf="!statusOnly">
  <div class="card shadow border-0 aircraft-dashboard-administrative-status">
    <div class="card-header">
      <div class="clearfix">
        <h6 class="float-left mt-1">
          <span class="badge rag_circle {{getAdminStatusBadgeColor()}}">&nbsp;</span>
          Administrative Status <small class="text-muted">@ {{ theAircraft?.airframe?.ttis }} Hours</small>
        </h6>

        <a *ngIf="!isAdminView" [routerLink]="['/cga/dash-dates']" [queryParams]="{viewupdateMode:1}" class="btn-outline-primary btn float-right rounded btn-sm">
          Edit Dates
        </a>
      </div>
    </div>

    <div class="card-body">
      <app-preloader-type-a *ngIf="isLoading"></app-preloader-type-a>

      <div *ngIf="!isLoading">

        <table class="table table-striped w-100 rag_table" >
          <tbody>

              <!---Registration-->
              <tr class="{{getRagColor(registrationRagStatus)}}">

                <td colspan="4" *ngIf="!registrationNextDueDateMoment">
                  <div class="alert alert-danger text-center" role="alert">
                    No Registration Due date set for aircraft. <br />
                    Please update in <a [routerLink]="['/cga/dash-dates']">My Aircraft Dates</a>.
                  </div>
                </td>

                <ng-container  *ngIf="registrationNextDueDateMoment">
                  <td>
                    <small class="text-primary d-block">
                      &nbsp;
                    </small>
                    Registration
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td>
                    <small class="text-primary d-block">
                      Due
                    </small>
                    {{ formatMomentAsUsDateString(registrationNextDueDateMoment) }}
                  </td>
                  <td>
                    <small class="text-primary d-block">
                      Remaining
                    </small>
                    {{getMomentBetweenDates(todayFlightDateMoment, registrationNextDueDateMoment)}}
                  </td>
                </ng-container>
              </tr>

              <!---Insurance-->
              <tr class="{{getRagColor(insuranceRagStatus)}}">

                <td colspan="4" *ngIf="!insuranceNextDueDateMoment">
                  <div class="alert alert-danger text-center" role="alert">
                    No Insurance date set for aircraft. <br/>
                    Please update in <a [routerLink]="['/cga/dash-dates']">My Aircraft Dates</a>.
                  </div>
                </td>

                <ng-container  *ngIf="insuranceNextDueDateMoment">
                  <td>
                    <small class="text-primary d-block">
                      &nbsp;
                    </small>
                    Insurance
                  </td>
                  <td>
                    &nbsp;
                  </td>
                  <td>
                    <small class="text-primary d-block">
                      Due
                    </small>
                    {{ formatMomentAsUsDateString(insuranceNextDueDateMoment) }}
                  </td>
                  <td>
                    <small class="text-primary d-block">
                      Remaining
                    </small>
                    {{getMomentBetweenDates(todayFlightDateMoment, insuranceNextDueDateMoment)}}
                  </td>
                </ng-container>
              </tr>

          </tbody>
        </table>
      </div>

    </div>
  </div>
</ng-container>