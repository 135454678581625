import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { Router } from '@angular/router';
import { UserBaseModel } from '../../../models/user-base-model';
import { MustBeSameAsControValidator } from '../../../_custom-validators/must-be-same-as-control-validator';
import { AlertPopUpsService } from '../../../services/alert-pop-ups.service';
import Swal from 'sweetalert2';
import { UserModel } from '../../../models/user-model';

@Component({
  selector: 'app-new-member',
  templateUrl: './new-member.component.html',
  styleUrls: ['./new-member.component.scss']
})
export class NewMemberComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private popUpService: AlertPopUpsService,
  ) { }

  createUserForm: FormGroup;

  newUser: UserModel = new UserModel;

  emailAddress: string;
  password: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  mobilePhone: string;
  pilotLicenseNo: string;

  isWorking: boolean;

  ngOnInit() {
    this.isWorking = false;

    this.createUserForm = this.formBuilder.group({
      'firstName': new FormControl(this.newUser.firstName, [
        Validators.required,
        Validators.pattern(/^[a-zA-Z][A-Za-z ,.'-]+$/)
      ]),
      'lastName': new FormControl(this.newUser.lastName, [
        Validators.required,
        Validators.pattern(/^[a-zA-Z][A-Za-z ,.'-]+$/)
      ]),
      'emailAddress': new FormControl(this.newUser.emailAddress, [
        Validators.required,
        Validators.email
      ]),
      'mobilePhone': new FormControl(this.newUser.mobilePhone, [
        // Validators.required,
        // Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
        Validators.pattern(/^\+?[1-9]\d{1,14}$/)
      ]),
      'password1': new FormControl(null),
      'password2': new FormControl(null),
      'isCrewChiefAdmin': new FormControl(this.newUser.isAdmin),
      'pilotLicenseNo': new FormControl(this.newUser.pilotLicenseNo, [
        Validators.minLength(4),
        Validators.maxLength(8)
      ]),
    });

    this.createUserForm.get('password1').setValidators([
      Validators.required,
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.[(!@#\$%\^&\*)*]?)(?=.{6,})/),
      MustBeSameAsControValidator(this.createUserForm.get('password2'), false)
    ]);

    this.createUserForm.get('password2').setValidators([
      Validators.required,
      MustBeSameAsControValidator(this.createUserForm.get('password1'))
    ]);
  }

  doCreateUser() {
    if (!this.createUserForm.valid) {

      Object.values(this.createUserForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      this.popUpService.openBasicPopUp(
        'Check important fields!',
        'Form not filled correctly!',
        'error'
      );
      return;
    }
  
    this.isWorking = true;

    this.newUser.firstName = this.createUserForm.controls['firstName'].value;
    this.newUser.lastName = this.createUserForm.controls['lastName'].value;
    this.newUser.emailAddress = this.createUserForm.controls['emailAddress'].value;
    this.newUser.mobilePhone = this.createUserForm.controls['mobilePhone'].value;
    this.newUser.isAdmin = this.createUserForm.controls['isCrewChiefAdmin'].value;
    this.newUser.pilotLicenseNo = this.createUserForm.controls['pilotLicenseNo'].value;
    this.newUser['password'] = this.createUserForm.controls['password1'].value;

    this.userService.createOrUpdateUser(this.newUser).subscribe(
      (response: any) => {
        this.isWorking = false;
        const newMemberEmail = response.emailAddress;
        this.afterCustomerCreate(newMemberEmail);
      },
      (error) => {
        this.isWorking = false;
        this.popUpService.openBasicPopUp(
          'Sorry!',
          'Create new member up failed.',
          'error'
        );
      }
    );
  }

  afterCustomerCreate(email: string){
    this.popUpService.openConfirmPopUp(
      'Customer Created!',
      'Want to view this customer or assign Aircraft?',
      'success',
      true,
      'Yes. View new customer!',
      'No, create another one.'
    ).then((descision) => {
      if (descision.value) {
        this.router.navigate(['/cga/admin/edit-member', email]);
      } else if (descision.dismiss === Swal.DismissReason.cancel) {
        this.newUser = new UserModel;
        this.createUserForm.reset({});
      }
    });
  }
}
