<!-- Sidebar -->
<nav id="page-sidebar" class="bg-darkblue text-white px-3" [ngbCollapse]="isSideBarCollapsed">
  <div class="toggler-box {{isSideBarCollapsed? 'closed' : 'open'}} text-right mb-3 pb-3">


    <!-- <button class="btn btn-sm p-2 {{isSideBarCollapsed? 'w-100 text-center' : ''}}"
     type="button"
      (click)="toggleSideBar()"
       [attr.aria-expanded]="!isSideBarCollapsed"
        aria-controls="sidebar">
      <i [attr.class]="isSideBarCollapsed? 'la la-bars' : 'la la-reply-all'" class="fa"></i>
    </button> -->

    <div class="p-0 text-center">
      <img class="brand-logo full-logo pr-0 d-sm-none d-xs-none d-md-inline d-lg-inline  d-xl-inline d-none" src="assets/images/crew-chief-logo-top.png" alt="">
      <img class="brand-logo mini-logo pr-0 d-md-none d-lg-none d-xs-none d-inline" (click)="toggleSideBar()" src="assets/images/crew-chief-icon-full-color.png" alt="">
    </div>
  </div>


  <!--ORGANIZATION ADMIN-->
  <div class="sidebar-header">
    <h6 class="disply-4 px-3 text-center"><small class="text-white">Organization</small></h6>
  </div>
  <div class="selected_item_name text-orange orange_titles">
    {{selectedOrg?.organization?.organizationName}}
  </div>
  <ul class="navbar-nav mr-auto">
    <ng-container *ngIf="selectedOrg.organization && selectedOrg.organization.id !== null">
      <li class="nav-item" appSideMenuAutoCloseLinks *ngIf="hasOrgAdminOrManagerAccess()">
        <a class="nav-link" [routerLink]="['/cga/organization-profile']" [queryParams]="{ t: 'members' }" routerLinkActive="active">
          <!-- <i class="la la-users"></i> -->
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/Multiple Members Icon - Grey-01.png" alt="">
          </span>
          <span class="desc"> Manage Members</span>
        </a>
      </li>
      
      <li class="nav-item" appSideMenuAutoCloseLinks *ngIf="hasOrgAdminOrManagerAccess()">
        <a class="nav-link" [routerLink]="['/cga/organization-profile']" [queryParams]="{ t: 'aircraft' }" routerLinkActive="active">
          <!-- <i class="la la-user"></i> -->
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/Aircraft Icon - Grey-01.png" alt="">
          </span>
          <span class="desc"> Manage Aircraft </span>
        </a>
      </li>

      <!-- 
        <li class="nav-item" appSideMenuAutoCloseLinks *ngIf="hasOrgAdminOrManagerAccess()">
          <a class="nav-link"  [routerLink]="['/cga/organization-profile']" routerLinkActive="active">
            <span class="menu_icons">
              <img src="assets/images/visual_assets/menu_icons/Org Profile Icon - Grey-01.png" alt="">
            </span>
            <span class="desc"> Org. Profile</span>
          </a>
        </li> 
      -->
    </ng-container>
  </ul>


  <!--AIRCRAFT SHORTCUTS-->
  <div class="sidebar-header">
    <h6 class="disply-4 px-3 text-center"><small class="text-white">Aircraft</small></h6>
  </div>

  <!--AIRCRAFT PICKER-->
  <app-aircraft-picker-widget (selectedAircraftEmmitter)="onMenuSelectAircraft($event)"></app-aircraft-picker-widget>
  <!---->

  <ul class="navbar-nav mr-auto">
    <ng-container *ngIf="menuSelectedAircraft.id !== null">
      <li class="nav-item" appSideMenuAutoCloseLinks *ngIf="currentUser.isAdmin">
        <a class="nav-link"  [routerLink]="['/cga/admin/edit-aircraft-dashboard']" routerLinkActive="active">
          <!-- <i class="la la-plane"></i> -->
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/Dashboard Icon - Grey-01.png" alt="Configure Dashboard">
          </span>
          <span class="desc"> Configure Dashboard </span>
        </a>
      </li>

      <li class="nav-item" appSideMenuAutoCloseLinks>
        <a class="nav-link" [routerLink]="['/cga/aircraft-profile']" routerLinkActive="active">
          <!-- <i class="la la-plane"></i> -->
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/Aircraft Icon - Grey-01.png" alt="Aircraft Profile">
          </span>
          <span class="desc"> Aircraft Profile </span>
        </a>
      </li>

      <li class="nav-item" appSideMenuAutoCloseLinks>
        <a class="nav-link" [routerLink]="['/cga/dashboard']" routerLinkActive="active">
          <!-- <i class="la la-dashboard"></i> -->
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/Dashboard Icon - Grey-01.png" alt="Dashboard">
          </span>
          <span class="desc"> Dashboard </span>
        </a>
      </li>

      <li class="nav-item" appSideMenuAutoCloseLinks>
        <a class="nav-link" [routerLink]="['/cga/all-aircraft-safety-documents']" routerLinkActive="active">
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/Global SB List Icon - Grey-01.png" alt="AD-SB List">
          </span>
          <span class="desc"> AD/SB List </span>
        </a>
      </li>

      <li class="nav-item" appSideMenuAutoCloseLinks>
        <a class="nav-link" [routerLink]="['/cga/view-logs']" routerLinkActive="active">
          <!-- <i class="la la-file-text-o"></i> -->
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/Log Review Icon - Grey-01.png" alt="Log Review">
          </span>
          <span class="desc"> Flight Logs</span>
        </a>
      </li>

      <li class="nav-item" appSideMenuAutoCloseLinks *ngIf="hasAircraftReadAccess()">
        <a class="nav-link" [routerLink]="['/cga/aircraft-flightlog']" routerLinkActive="active">
          <!-- <i class="la la-plane"></i> -->
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/Log Entry - Grey-01.png" alt="Log Entry">
          </span>
          <span class="desc"> New Log Entry</span>
        </a>
      </li>
      <!-- <li class="nav-item" appSideMenuAutoCloseLinks>
        <a class="nav-link" [routerLink]="['/cga/aircraft-maintenance']" routerLinkActive="active">
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/Edit Dates Icon - Grey-01.png" alt="Maintenance History">
          </span>
          <span class="desc"> Primary MX Dates</span>
        </a>
      </li>
      <li class="nav-item" appSideMenuAutoCloseLinks *ngIf="hasAircraftReadAccess()">
        <a class="nav-link" [routerLink]="['/cga/dash-dates']" routerLinkActive="active">
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/Edit Dates Icon - Grey-01.png" alt="Edit Dates">
          </span>
          <span class="desc"> Edit Dates</span>
        </a>
      </li> -->
    </ng-container>
  </ul>


  <ng-container *ngIf="currentUser.isAdmin">
    <!--CREWCHIEF ADMIN-->
    <div class="sidebar-header">
      <h6 class="disply-4 px-3 text-center"><small class="text-white">Crewchief Admin</small></h6>
    </div>

    <ul class="navbar-nav mr-auto">
      <li class="nav-item" appSideMenuAutoCloseLinks>
        <a class="nav-link" [routerLink]="['/cga/admin/list-orgs']" routerLinkActive="active">
          <!-- <i class="la la-dashboard"></i> -->
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/Organization Icon - Grey-01.png" alt="Organizations">
          </span>
          <span class="desc"> Organizations </span>
        </a>
      </li>

      <li class="nav-item" appSideMenuAutoCloseLinks>
        <a class="nav-link" [routerLink]="['/cga/admin/list-aircraft']" routerLinkActive="active">
          <!-- <i class="la la-dashboard"></i> -->
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/Aircraft Icon - Grey-01.png" alt="Aircraft">
          </span>
          <span class="desc"> Aircraft </span>
        </a>
      </li>

      <li class="nav-item" appSideMenuAutoCloseLinks>
        <a class="nav-link" [routerLink]="['/cga/admin/list-members']" routerLinkActive="active">
          <!-- <i class="la la-users"></i> -->
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/Multiple Members Icon - Grey-01.png" alt="Members">
          </span>
          <span class="desc"> Members </span>
        </a>
      </li>

      <!-- <li class="nav-item" appSideMenuAutoCloseLinks>
        <a class="nav-link" [routerLink]="['/cga/admin/console']" routerLinkActive="active">
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/Multiple Members Icon - Grey-01.png" alt="Admin Console Legacy">
          </span>
          <span class="desc"> Admin Console <sup>Legacy</sup> </span>
        </a>
      </li> -->

      <li class="nav-item" appSideMenuAutoCloseLinks>
        <a class="nav-link" href="javascript: void(0);">
          <!-- <i class="la la-dashboard"></i> -->
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/New Organization Icon - Grey-01.png" alt="New Organization">
          </span>
          <span class="desc text-muted"> New Organization </span>
        </a>
      </li>

      <li class="nav-item" appSideMenuAutoCloseLinks>
        <a class="nav-link" [routerLink]="['/cga/admin/new-aircraft-v2']" routerLinkActive="active">
          <!-- <i class="la la-plane"></i> -->
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/New Aircraft Icon - Grey-01.png" alt="New Aircraft">
          </span>
          <span class="desc"> New Aircraft </span>
        </a>
      </li>

      <li class="nav-item" appSideMenuAutoCloseLinks>
        <a class="nav-link" [routerLink]="['/cga/admin/new-member']" routerLinkActive="active">
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/New Member Icon - Grey-01.png" alt="New Member">
          </span>
          <span class="desc"> New Member </span>
        </a>
      </li>

      <li class="nav-item" appSideMenuAutoCloseLinks>
        <a class="nav-link"  [routerLink]="['/cga/admin/new-customer']" routerLinkActive="active">
          <!-- <i class="la la-user"></i> -->
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/New Member Icon - Grey-01.png" alt="New Customer Legacy">
          </span>
          <span class="desc"> New Customer <sup>Legacy</sup> </span>
        </a>
      </li>

      <li class="nav-item" appSideMenuAutoCloseLinks>
        <a class="nav-link" [routerLink]="['/cga/admin/manage-directives']" routerLinkActive="active">
          <!-- <i class="la la-users"></i> -->
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/Global AD List Icon - Grey-01.png" alt="Global AD List">
          </span>
          <span class="desc"> Global AD List </span>
        </a>
      </li>

      <li class="nav-item" appSideMenuAutoCloseLinks>
        <a class="nav-link" [routerLink]="['/cga/admin/manage-bulletins']" routerLinkActive="active">
          <!-- <i class="la la-wrench"></i> -->
          <span class="menu_icons">
            <img src="assets/images/visual_assets/menu_icons/Global SB List Icon - Grey-01.png" alt="Global SB List">
          </span>
          <span class="desc"> Global SB List </span>
        </a>
      </li>
    </ul>
  </ng-container>

  <!--BOTTOM SPACER-->
  <div class="my-3 py-3 d-block">
    &nbsp;
  </div>
</nav>
