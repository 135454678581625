<div class="card shadow border-0 aircraft-all-safety-sb-documents">
  <div class="card-header">
    <div class="clearfix">
      <h6 class="float-left mt-1">
        Service Bulletin Documents <small class="text-muted">@ {{ todayFlightHours }} Hours</small>
      </h6>
    </div>
  </div>

  <div class="card-body">
    <app-preloader-type-a *ngIf="isLoading"></app-preloader-type-a>
    <ng-container *ngIf="!isLoading">
      <div class="alert alert-warning" role="alert" *ngIf="allAircraftSBs.length < 1">
        No Service Bulletin assigned to Aircraft.
      </div>

      <!-- FILTER FORM-->
      <div class="py-2">
        <form [formGroup]="filterSearchForm" (ngSubmit)="filterAircraftSb()">
          <div class="form-group">
            <small class="form-text text-muted d-block clearfix">
              Filter

              <span class="text-danger float-right cursor-pointer" (click)="clearFilter()" *ngIf="sbFilter.value">
                <i class="las la-times-circle"></i>
                Clear Filter
              </span>
            </small>
            <div class="input-group">
              <input class="form-control" type="text"  #sbFilter formControlName="sbFilter"
                placeholder="Filter by bulletin name name, subject, type, application" name="sbFilter">
            </div>
          </div>
        </form>
      </div>

      <div *ngIf="inFilterMode">
        <h4 class="clearfix">
          Filter Matches:
        </h4>
        <table class="table table-striped w-100 rag_table" *ngIf="filterResult.length > 0">
          <tbody>
            <!--FILTERED SBs-->
            <app-preloader-type-a *ngIf="isFilteringSbs"></app-preloader-type-a>
            <ng-container *ngIf="!isFilteringSbs">
              <tr  *ngFor="let sbFiltered of filterResult; let i = index;" 
                class="{{getRowTypeColor(sbFiltered)}}"
              >
                <td>
                  <h5 class="py-2 clearfix font-weight-normal">
                    <span class="badge rag_circle mr-2
                      {{getRowTypeColor(sbFiltered)}}
                    "
                    *ngIf="sbFiltered.sbGroup == 'applies'"
                    >&nbsp;</span>
                    {{ sbFiltered.service_bulletin?.bulletinName }}
  
                    <!--SB Document View Link-->
                    <ng-container>
                      <button (click)="openModal(content, sbFiltered.service_bulletin.fileUrl, sbFiltered.service_bulletin.bulletinName)"
                       target="_blank" class="mr-2 btn btn-danger btn-sm rounded text-white" *ngIf="sbFiltered.service_bulletin.fileUrl">
                        <i class="la la-file-pdf-o"></i>
                        View Document
                      </button>
  
                      <span class="badge badge-light rounded p-2 text-white" *ngIf="!sbFiltered.service_bulletin.fileUrl">
                        <i class="la la-file-pdf-o"></i>
                        No Document
                      </span>
                    </ng-container>
  
                    <span class="text-muted float-right">
                      {{ sbFiltered.service_bulletin?.adSbType | uppercase }}
                    </span>
                  </h5>
  
                  <div class="row">
                    <div class="col-6">
                      <small class="text-dark d-block font-weight-bold">
                        {{sbFiltered.service_bulletin?.subject}}
                      </small>
                      <small class="text-muted d-block mt-2">
                        Effective Date
                      </small>
                      {{sbFiltered.service_bulletin? (getISODateAsDate(sbFiltered.service_bulletin.effectiveDate) | date:'MM/dd/yyyy') : ''}}
  
                      <!-- <span *ngIf="sbFiltered.isRecurring"> -->
                        <span  *ngIf="!sbFiltered.terminateAction && sbFiltered.isRecurring" class="text-info font-weight-bold d-block" ngbPopover="This is a recurring Service Bulletin." triggers="mouseenter:mouseleave" popoverTitle="RECURRING SB">
                          <i class="la la-refresh font-weight-bold"></i> RECURRING
                        </span>
                        <span  *ngIf="sbFiltered.terminateAction" class="text-success font-weight-bold d-block" ngbPopover="This is a terminated Service Bulletin." triggers="mouseenter:mouseleave" popoverTitle="TERMINATED SB">
                          <i class="la la-ban font-weight-bold"></i> TERMINATED
                        </span>
                      <!-- </span> -->
                    </div>

                    <div class="col-6 border-left {{getRowTypeColor(sbFiltered)}} rag-div">
                      <!--rag div for applies-->
                      <ng-container *ngIf="sbFiltered.sbGroup === 'applies'">
                        <div *ngIf="sbFiltered.ragStatus == 0">
                          <span class="d-block" >
                            Overdue
                          </span>
    
                          <small class="d-block">
                            Due:
                            {{ sbFiltered.sortingColumnName == 'Date'? getMomentBetweenDates(sbFiltered.dueDateAsMoment, todayFlightDateMoment) + ' ago' : '' }}
                            {{ sbFiltered.sortingColumnName == 'Hours'? getHoursRemainingBetweenHours(sbFiltered.dueHours, todayFlightHours) + '  ago' : '' }}
    
                            <span class="d-block">
                              <em *ngIf="sbFiltered.sortingColumnName == 'Date'">On {{ formatMomentAsUsDateString(sbFiltered.dueDateAsMoment) }}</em>
                              <em *ngIf="sbFiltered.sortingColumnName == 'Hours'">@{{ sbFiltered.dueHours }}Hrs</em>
                            </span>
    
                            <span class="d-block text-muted"> <em>{{sbFiltered.condition? getConditionDisplayString(sbFiltered.condition) : ''}}</em> </span>
                          </small>
                        </div>
    
                        <div *ngIf="sbFiltered.ragStatus !== 0">
                          Due in:
                          <span *ngIf="sbFiltered.dueHours && sbFiltered.dueDate">
                            {{ getMomentBetweenDates(todayFlightDateMoment, sbFiltered.dueDateAsMoment) }}  or
                            {{  getHoursRemainingBetweenHours(todayFlightHours, sbFiltered.dueHours) }}
                          </span>
    
                          <span *ngIf="!(sbFiltered.dueHours && sbFiltered.dueDate)">
                            {{ sbFiltered.sortingColumnName == 'Date'? getMomentBetweenDates(todayFlightDateMoment, sbFiltered.dueDateAsMoment)  : '' }}
                            {{ sbFiltered.sortingColumnName == 'Hours'? getHoursRemainingBetweenHours(todayFlightHours, sbFiltered.dueHours) : '' }}
                          </span>
    
                          <div>
                            <small class="d-block">
                              <em *ngIf="sbFiltered.sortingColumnName == 'Date'">On {{ formatMomentAsUsDateString(sbFiltered.dueDateAsMoment) }}</em>
                              <em *ngIf="sbFiltered.sortingColumnName == 'Hours'">@{{ sbFiltered.dueHours }}Hrs</em>
                            </small>
    
                            <span class="text-muted d-block">
                              <em>{{sbFiltered.condition? getConditionDisplayString(sbFiltered.condition) : ''}}</em>
                            </span>
                          </div>
    
                        </div>
                      </ng-container>


                      <!--rag div for performed-->
                      <ng-container *ngIf="sbFiltered.sbGroup === 'performed'">
                        
                        Performed With:
                        <small class="text-muted d-block">
                          {{ sbFiltered.lastPerformedDate? 'on ' + (getISODateAsDate(sbFiltered.lastPerformedDate)  | date:'MM/dd/yyyy') : '' }}
                          {{ sbFiltered.lastPerformedHour ? (' / @' + sbFiltered.lastPerformedHour + 'Hrs') : '' }}
                        </small>
                      </ng-container>


                      <!--rag div for notapply-->
                      <ng-container *ngIf="sbFiltered.sbGroup === 'notapply'">
                        Does not apply:<br/>
                        <small class="d-block">
                          Reason:<br/>
                          <small class="d-block">
                            {{ sbFiltered?.notApplyReason }}
                          </small>
                        </small>
                      </ng-container>
                      
                    </div>
                  </div>
  
                  <div class="col-12" *ngIf="sbFiltered.performanceNote">
                    <small class="d-block p-2 text-dark bg-light border-gray border rounded m-2 allow_wrap">
                      <b>NOTE:</b> {{sbFiltered.performanceNote}}
                    </small>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <div *ngIf="!inFilterMode">
        <table class="table table-striped w-100 rag_table" *ngIf="allAircraftSBs.length > 0">
          <tbody>
            <tr  *ngFor="let aircraftSb of allAircraftSBs; let i = index;" 
              class="{{getRowTypeColor(aircraftSb)}}"
            >
              <td>
                <h5 class="py-2 clearfix font-weight-normal">
                  <span class="badge rag_circle mr-2
                    {{getRowTypeColor(aircraftSb)}}
                  "
                  *ngIf="aircraftSb.sbGroup == 'applies'"
                  >&nbsp;</span>
                  {{ aircraftSb.service_bulletin?.bulletinName }}

                  <!--SB Document View Link-->
                  <ng-container>
                    <button (click)="openModal(content, aircraftSb.service_bulletin.fileUrl, aircraftSb.service_bulletin.bulletinName)"
                     target="_blank" class="mr-2 btn btn-danger btn-sm rounded text-white" *ngIf="aircraftSb.service_bulletin.fileUrl">
                      <i class="la la-file-pdf-o"></i>
                      View Document
                    </button>

                    <span class="badge badge-light rounded p-2 text-white" *ngIf="!aircraftSb.service_bulletin.fileUrl">
                      <i class="la la-file-pdf-o"></i>
                      No Document
                    </span>
                  </ng-container>

                  <span class="text-muted float-right">
                    {{ aircraftSb.service_bulletin?.adSbType | uppercase }}
                  </span>
                </h5>

                <div class="row">
                  <div class="col-6">
                    <small class="text-dark d-block font-weight-bold">
                      {{aircraftSb.service_bulletin?.subject}}
                    </small>
                    <small class="text-muted d-block mt-2">
                      Effective Date
                    </small>
                    {{aircraftSb.service_bulletin? (getISODateAsDate(aircraftSb.service_bulletin.effectiveDate) | date:'MM/dd/yyyy') : ''}}

                    <!-- <span *ngIf="aircraftSb.isRecurring"> -->
                      <span  *ngIf="!aircraftSb.terminateAction && aircraftSb.isRecurring" class="text-info font-weight-bold d-block" ngbPopover="This is a recurring Service Bulletin." triggers="mouseenter:mouseleave" popoverTitle="RECURRING SB">
                        <i class="la la-refresh font-weight-bold"></i> RECURRING
                      </span>
                      <span  *ngIf="aircraftSb.terminateAction" class="text-success font-weight-bold d-block" ngbPopover="This is a terminated Service Bulletin." triggers="mouseenter:mouseleave" popoverTitle="TERMINATED SB">
                        <i class="la la-ban font-weight-bold"></i> TERMINATED
                      </span>
                    <!-- </span> -->
                  </div>

                  <div class="col-6 border-left {{getRowTypeColor(aircraftSb)}} rag-div">
                    <!--rag div for applies-->
                    <ng-container *ngIf="aircraftSb.sbGroup === 'applies'">
                      <div *ngIf="aircraftSb.ragStatus == 0">
                        <span class="d-block" >
                          Overdue
                        </span>
  
                        <small class="d-block">
                          Due:
                          {{ aircraftSb.sortingColumnName == 'Date'? getMomentBetweenDates(aircraftSb.dueDateAsMoment, todayFlightDateMoment) + ' ago' : '' }}
                          {{ aircraftSb.sortingColumnName == 'Hours'? getHoursRemainingBetweenHours(aircraftSb.dueHours, todayFlightHours) + '  ago' : '' }}
  
                          <span class="d-block">
                            <em *ngIf="aircraftSb.sortingColumnName == 'Date'">On {{ formatMomentAsUsDateString(aircraftSb.dueDateAsMoment) }}</em>
                            <em *ngIf="aircraftSb.sortingColumnName == 'Hours'">@{{ aircraftSb.dueHours }}Hrs</em>
                          </span>
  
                          <span class="d-block text-muted"> <em>{{aircraftSb.condition? getConditionDisplayString(aircraftSb.condition) : ''}}</em> </span>
                        </small>
                      </div>
  
                      <div *ngIf="aircraftSb.ragStatus !== 0">
                        Due in:
                        <span *ngIf="aircraftSb.dueHours && aircraftSb.dueDate">
                          {{ getMomentBetweenDates(todayFlightDateMoment, aircraftSb.dueDateAsMoment) }}  or
                          {{  getHoursRemainingBetweenHours(todayFlightHours, aircraftSb.dueHours) }}
                        </span>
  
                        <span *ngIf="!(aircraftSb.dueHours && aircraftSb.dueDate)">
                          {{ aircraftSb.sortingColumnName == 'Date'? getMomentBetweenDates(todayFlightDateMoment, aircraftSb.dueDateAsMoment)  : '' }}
                          {{ aircraftSb.sortingColumnName == 'Hours'? getHoursRemainingBetweenHours(todayFlightHours, aircraftSb.dueHours) : '' }}
                        </span>
  
                        <div>
                          <small class="d-block">
                            <em *ngIf="aircraftSb.sortingColumnName == 'Date'">On {{ formatMomentAsUsDateString(aircraftSb.dueDateAsMoment) }}</em>
                            <em *ngIf="aircraftSb.sortingColumnName == 'Hours'">@{{ aircraftSb.dueHours }}Hrs</em>
                          </small>
  
                          <span class="text-muted d-block">
                            <em>{{aircraftSb.condition? getConditionDisplayString(aircraftSb.condition) : ''}}</em>
                          </span>
                        </div>
  
                      </div>
                    </ng-container>


                    <!--rag div for performed-->
                    <ng-container *ngIf="aircraftSb.sbGroup === 'performed'">
                      
                      Performed With:
                      <small class="text-muted d-block">
                        {{ aircraftSb.lastPerformedDate? 'on ' + (getISODateAsDate(aircraftSb.lastPerformedDate)  | date:'MM/dd/yyyy') : '' }}
                        {{ aircraftSb.lastPerformedHour ? (' / @' + aircraftSb.lastPerformedHour + 'Hrs') : '' }}
                      </small>
                    </ng-container>


                    <!--rag div for notapply-->
                    <ng-container *ngIf="aircraftSb.sbGroup === 'notapply'">
                      Does not apply:<br/>
                      <small class="d-block">
                        Reason:<br/>
                        <small class="d-block">
                          {{ aircraftSb?.notApplyReason }}
                        </small>
                      </small>
                    </ng-container>
                    
                  </div>
                </div>

                <div class="col-12" *ngIf="aircraftSb.performanceNote">
                  <small class="d-block p-2 text-dark bg-light border-gray border rounded m-2 allow_wrap">
                    <b>NOTE:</b> {{aircraftSb.performanceNote}}
                  </small>
                </div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </ng-container>

  </div>
</div>


<!--Pagingation-->
<!--
<div class="d-flex justify-content-between p-2">
  <ngb-pagination [collectionSize]="totalSbs" [(page)]="page" [pageSize]="pageSize"
  [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
    (pageChange)="paginationPageChange($event)">
  </ngb-pagination>
</div>-->







<!--PDF VIEWER--->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{viewedPdfSbTitle}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-pdf-viewer [pdfSrc]="viewedPdfSrc"></app-pdf-viewer>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>
