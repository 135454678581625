import { BreadcrumbNavModel } from '../../../models/breadcrumb-nav-model';
import { UserAircraftModel } from './../../../models/user-aircraft-model';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-admin-aircraft-dashboard',
  templateUrl: './admin-aircraft-dashboard.component.html',
  styleUrls: ['./admin-aircraft-dashboard.component.scss']
})
export class AdminAircraftDashboardComponent implements OnInit {
  @Input() aircraftId: number;

  constructor(
    private route: ActivatedRoute,
  ) { }

  theAircraft: UserAircraftModel = new UserAircraftModel;
  navItems: Array<BreadcrumbNavModel> = []


  ngOnInit() {
    console.log('DISCONTINUED COMPONENT!!!!!!!');

    const routeAircraftId = +this.route.snapshot.paramMap.get('aircraftId');

    this.aircraftId = this.aircraftId ? this.aircraftId : routeAircraftId;

    this.navItems = [
      {navLabel: 'Admin Console', navRouterLink: ['/cga/admin/console']},
      {navLabel: 'Aircraft', navRouterLink: ['/cga/admin/edit-aircraft', this.aircraftId]},
      {navLabel: 'Aircraft Dashboard', navRouterLink: null}
    ]
  }
}
