import { Location } from '@angular/common';
import { UserOrganizationAndRole } from '../../../../models/user-organization-and-role';
import { UserService } from '../../../../services/user.service';
import { SubscriptionLike } from 'rxjs';
import Swal from 'sweetalert2';
import { UtilitiesService } from '../../../../services/utilities.service';
import { OrganizationService } from '../../../../services/organization.service';
import { OrganizationPersonModel } from '../../../../models/organization-person-model';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import {
  Component,
  OnInit,
  ElementRef,
  HostListener,
  OnDestroy,
  Input
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationUserRoleOptionsModel } from '../../../../models/organization-user-role-options-model';
import { UserModel } from '../../../../models/user-model';
import { Router } from '@angular/router';
import { OrganizationUserRoleTypes } from '../../../../models/organization-user-role-types';

@Component({
  selector: 'app-people-manager',
  templateUrl: './people-manager.component.html',
  styleUrls: ['./people-manager.component.scss']
})
export class PeopleManagerComponent implements OnInit, OnDestroy {
  // @Input() orgId: number;
  // @Input() orgRole: string;

  currentUser: UserModel;
  private currentUser$: SubscriptionLike;

  allPeople: Array<OrganizationPersonModel> = [];
  page: number;
  pageSize: number;

  orgRoleOptions: OrganizationUserRoleOptionsModel = new OrganizationUserRoleOptionsModel();

  filterQuery: string;
  filterPage: number;
  filterPageSize: number;

  filterPeopleForm: FormGroup;
  addPersonForm: FormGroup;
  updatePersonForm: FormGroup;

  updatedPerson: OrganizationPersonModel = new OrganizationPersonModel();
  isUpdatingUser: boolean;

  isWorking: boolean;
  isAddingUser: boolean;

  selectedOrg: UserOrganizationAndRole = new UserOrganizationAndRole;
  selectedOrg$: SubscriptionLike;

  theOrg: UserOrganizationAndRole = new UserOrganizationAndRole();

  allowedRolesToEdit: Array<string> = ['ADMIN', 'MANAGER'];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private orgService: OrganizationService,
    private utilsService: UtilitiesService,
    private modalService: NgbModal,
    private location: Location,
    private userService: UserService,
    private _eref: ElementRef
  ) {}

  ngOnInit() {
    // init defaults
    this.page = 0;
    this.pageSize = 50;

    this.filterQuery = '';
    this.filterPage = 0;
    this.filterPageSize = 50;

    this.filterQuery = '';
    this.filterPage = 0;
    this.filterPageSize = 50;


    this.isUpdatingUser = false;
    this.isWorking = false;
    this.isAddingUser = false;


    // add tab parameter in url
    const fullUrl: Array<string> = this.router.url.split('?');

    if (fullUrl[0]) {
      this.location.go(fullUrl[0] + '?t=members');
    }

    this.currentUser$ = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });

    this.selectedOrg$ = this.orgService.selectedOrg$.subscribe(
      (org: UserOrganizationAndRole) => {
      this.selectedOrg = org;

      if (this.selectedOrg.organization && this.selectedOrg.organization.id) {
        this.getUsersByOrganization();
      }
    });


    this.filterPeopleForm = this.fb.group({
      filter: new FormControl(this.filterQuery)
    });
  }

  /**
   * Closes edit person form on click away
   */
  @HostListener('click', ['$event'])
  onClick(event: any) {
    const pathRow = `row_${this.updatedPerson.user.emailAddress}`;
    const el = document.getElementById(pathRow);

    if (this.updatedPerson.user.id && event.composedPath().indexOf(el) >= 0) {
      return;
    } else {
      this.updatedPerson = new OrganizationPersonModel();
    }
  }
  /**
   * Get Users in an Organization
   */
  getUsersByOrganization() {
    if (!this.selectedOrg.organization.id) {
      return;
    }

    // if can't manage people
    if (!this.checkIfCanManagePeople) {
      return;
    }

    this.isWorking = true;
    this.orgService.getUsersByOrganizationV2(this.selectedOrg.organization.id).subscribe(
      (response: any) => {
        this.isWorking = false;
        this.allPeople = response;
      },
      (error: any) => {
        this.isWorking = false;
        /**
         *TODO: HANDLE ERROR
         */
      }
    );
  }

  initAddPersonForm() {
    this.addPersonForm = new FormGroup({
      firstName: new FormControl(null, [
        Validators.required,
        Validators.minLength(2)
      ]),
      lastName: new FormControl(null, [
        Validators.required,
        Validators.minLength(2)
      ]),
      personEmail: new FormControl(null, [
        Validators.required,
        Validators.email
      ]),
      personRole: new FormControl('MEMBER')
    });

    this.addPersonForm.get('personRole').setValidators([
      Validators.required,
      Validators.nullValidator
      // TODO: Add Enum validator for roles
    ]);
    return;
  }

  /**
   * Initialize adding person to organization
   * @param content
   */
  initAddPersonModal(content) {
    this.isAddingUser = false;
    this.initAddPersonForm();
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        result => {
          // Close Reason;
        },
        reason => {
          // Dismissed Reason;
        }
      );
  }

  /**
   * Function to add person to organization
   * @param actionType
   */
  doAddPersonToGroup(actionType: 'add' | 'update' = 'add') {
    const theForm =
    actionType && actionType.toLowerCase() === 'add' ? this.addPersonForm : this.updatePersonForm;

    const formValidate: boolean = this.utilsService.generalFormValidate(theForm);

    if (!formValidate) {
      return;
    }

    if (actionType && actionType.toLowerCase() === 'add') {
      this.isAddingUser = true;
    } else {
      this.isUpdatingUser = true;
    }

    // person first name
    const firstName =
    actionType && actionType.toLowerCase() === 'add'
        ? this.addPersonForm.get('firstName').value
        : this.updatedPerson.user.firstName;

    // person last name
    const lastName =
    actionType && actionType.toLowerCase() === 'add'
        ? this.addPersonForm.get('lastName').value
        : this.updatedPerson.user.lastName;

    // person email
    const personEmail =
      actionType && actionType.toLowerCase() === 'add'
        ? this.addPersonForm.get('personEmail').value
        : this.updatedPerson.user.emailAddress;

    // person role
    const personRole =
    actionType && actionType.toLowerCase() === 'add'
        ? this.addPersonForm.get('personRole').value
        : this.updatePersonForm.get('personRole').value;

    // request Body
    const reqBody = {
      firstName: firstName,
      lastName: lastName,
      organizationUserRole: personRole
    };

    this.orgService
      .attachUserToOrganizationV2(this.selectedOrg.organization.id, personEmail, reqBody)
      .subscribe(
        (response: any) => {
          this.isAddingUser = false;
          this.isUpdatingUser = false;

          Swal.fire(
            `Successfully ${actionType}ed`,
            `New user ${actionType}ed`,
            'success'
          ).then(() => {
            this.resetPageAndForms();
          });
        },
        (error: any) => {
          this.isAddingUser = false;
          this.isUpdatingUser = false;

          if (error.status.message) {
            Swal.fire(
              error.status.message,
              error.responseData.join(' '),
              'error'
            );
          } else {
            Swal.fire('Oops!', 'Failed to add new person.', 'error');
          }
        }
      );
  }

  /**
   * Initialize Person Update
   * @param person
   */
  initUpdatePersonForm(person: OrganizationPersonModel) {
    const thePersonRole = person && person.role ? person.role : null;
    this.updatePersonForm = new FormGroup({
      personRole: new FormControl(thePersonRole)
    });

    this.updatePersonForm.get('personRole').setValidators([
      Validators.required,
      Validators.nullValidator
      // TODO: Add Enum validator for roles
    ]);
    return;
  }

  /**
   *
   * @param person Load person selected to be updated
   */
  loadPersonForUpdate(person: OrganizationPersonModel) {
    this.updatedPerson =
      person && person.user && person.user.emailAddress ? person : null;

    if (this.updatedPerson) {
      this.initUpdatePersonForm(person);
    }
  }

  /**
   * Unload person selected to be updated
   */
  unloadPersonForUpdate() {
    this.updatedPerson = new OrganizationPersonModel();
    this.updatePersonForm.reset();
  }

  /**
   *
   * @param person Detatch Person form organization
   */
  detachOrgPerson(person: OrganizationPersonModel) {
    Swal.fire({
      title: `Detach ${person.user.firstName} ${person.user.lastName}?`,
      text: `Please confirm you want to detach ${person.user.firstName} ${person.user.lastName} ?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes. Detach person',
      cancelButtonText: 'No, take me back.'
    }).then(descision => {
      if (descision.value) {
        this.isWorking = true;
        this.orgService
          .detachUserFromOrganizationV2(this.selectedOrg.organization.id, person.user.emailAddress)
          .subscribe(
            (response: any) => {
              this.isWorking = false;
              this.resetPageAndForms();
              Swal.fire(
                'Detach!',
                `${person.user.firstName} ${person.user.lastName} is no longer on the organization.`,
                'success'
              );
            },
            (error: any) => {
              this.isWorking = false;
              if (error.status.message) {
                Swal.fire(
                  error.status.message,
                  error.responseData.join(' '),
                  'error'
                );
              } else {
                Swal.fire(
                  'Oops!',
                  `Failed to detach ${person.user.firstName} ${person.user.lastName} from organization.`,
                  'error'
                );
              }
            }
          );
      } else if (descision.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });
  }

  // TODO
  filterOrgPeopleByNameEmailOrMobile() {
    // TODO
  }

  /**
   * Format Phone number displayed
   * @param number
   */
  formatPhoneNumber(number: string) {
    return this.utilsService.formatPhoneNumber(number);
  }

  /**
   * Reset page variables
   */
  resetPageAndForms() {
    this.modalService.dismissAll();
    this.initAddPersonForm();
    this.updatedPerson = new OrganizationPersonModel();
    this.getUsersByOrganization();
    return;
  }

  /**
   * Check if user has right role to manage people
   */
  checkIfCanManagePeople() {
    return this.allowedRolesToEdit.includes(this.selectedOrg.role.toString().toUpperCase());
  }

  userCanAssignThisRole(roleValue: OrganizationUserRoleTypes) {
    if (
      (this.selectedOrg.role && this.selectedOrg.role.toString().toUpperCase() === 'MANAGER')
    ) {
      return roleValue.toString().toUpperCase() === 'ADMIN' ? false : true;
    } else {
      return true;
    }
  }

  userCanEditThisPerson(personRole: OrganizationUserRoleTypes) {
    if (personRole.toString().toUpperCase() === 'ADMIN') {
      return (this.selectedOrg.role && this.selectedOrg.role.toString().toUpperCase() === 'ADMIN')
        ? true
        : false;
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    if (this.currentUser$) {
      this.currentUser$.unsubscribe();
    }
  }
}
