import { SubscriptionLike } from 'rxjs';
import { AircraftService } from './../../../services/aircraft.service';
import { ActivatedRoute } from '@angular/router';
import { UserAircraftModel } from './../../../models/user-aircraft-model';
import { Component, OnInit, Input } from '@angular/core';
import { NgbTabsetConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserMenuAircraftModel } from '../../../models/user-menu-aircraft-model';
import { AircraftV2Model } from '../../../models/airfcraftV2/aircraft-v2.model';

@Component({
  selector: 'app-view-all-safety-documents',
  templateUrl: './view-all-safety-documents.component.html',
  styleUrls: ['./view-all-safety-documents.component.scss'],
  providers: [NgbTabsetConfig] // add NgbTabsetConfig to the component providers,
})
export class ViewAllSafetyDocumentsComponent implements OnInit {
  @Input() adminView: boolean;

  constructor(
    private route: ActivatedRoute,
    private aircraftService: AircraftService,
  ) { }

  theAircraft: UserAircraftModel = new UserAircraftModel;

  selectedAircraft: AircraftV2Model = new AircraftV2Model;
  selectedAircraft$: SubscriptionLike;

  ngOnInit() {
    this.selectedAircraft$ = this.aircraftService.selectedAircraft$.subscribe(
      (aircraft: AircraftV2Model)=>{
        this.selectedAircraft = aircraft;

        let theId: number = +this.route.snapshot.paramMap.get('aircraftId');

        if(!this.adminView){
          this.theAircraft = new UserAircraftModel;
          theId = this.selectedAircraft.id;
        }

        if(theId !== null){
          this.getAircraftById(theId)
        }

      }
    )

  }

  getAircraftById(id: number){
    this.aircraftService.getAircraftById(id).subscribe(
      (response:any)=>{
        if(response.status.code == 1000){
          this.theAircraft = response.responseData;
        }
      }
    );
  }

}
