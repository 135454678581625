import { CustomHttpErrorHandler } from './custom-http-error-handler';
import { AircraftDashboardReferencesModel } from './../models/aircraft-dashboard-references-model';
import { AircraftServiceBulletinModel } from './../models/aircraft-service-bulletin-model';
import { AircraftAirDirectiveModel } from './../models/aircraft-air-directive-model';
import { AirworthinessDirectiveModel } from './../models/airworthiness-directive-model';
import { HttpClient } from '@angular/common/http';
import { Injectable, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';
import { RagType } from '../models/rag-type';
import * as moment from 'moment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AirworthinessDirectiveService {
  constructor(private httpClient: HttpClient) {}

  getAirworthinessDirectiveById(directiveId: number) {
    return this.httpClient.get(environment.apiBaseUrl +
      '/airworthinessdirectives/getAirworthinessDirectiveById?directiveId=' +
      directiveId);
  }

  findAirworthinessDirectiveByName(directiveName: string, page: number = 1, pageSize: number = 25) {
    page = (+page) < 1 ? 1 : page;
    return this.httpClient.get(
      environment.apiBaseUrl +
      `/airworthinessdirectives/findAirworthinessDirectiveByName?directiveName=${directiveName}&page=${page}&pageSize=${pageSize}`);
  }

  getAllAirworthinessDirectives(page: number = 1, pageSize: number = 25) {
    page = (+page) < 1 ? 1 : page;
    return this.httpClient.get(
      environment.apiBaseUrl + '/airworthinessdirectives/getAllAirworthinessDirectives?page='
      + page + '&pageSize=' +
      pageSize);
  }

  createAirworthinessDirective(airworthinessDirective: AirworthinessDirectiveModel) {
    return this.httpClient.post(
      environment.apiBaseUrl + '/airworthinessdirectives/createAirworthinessDirective',
      airworthinessDirective);
  }

  updateAirworthinessDirective(airworthinessDirective: AirworthinessDirectiveModel) {
    return this.httpClient.put(
      environment.apiBaseUrl + '/airworthinessdirectives/updateAirworthinessDirective',
      airworthinessDirective
    );
  }

  findModelsByType(directiveName: string, page: number = 1, pageSize: number = 25) {
    page = (+page) < 1 ? 1 : page;
    return this.httpClient.get(
      environment.apiBaseUrl +
      `/airworthinessdirectives/findAirworthinessDirectiveByName?directiveName=${directiveName}&page=${page}&pageSize=${pageSize}`);
  }

  deleteGlobalAirworthinessDirective(directiveId: number) {
    return this.httpClient.delete(
      environment.apiBaseUrl +
      '/airworthinessdirectives/deleteGlobalAirworthinessDirective?directiveId=' +
      directiveId);
  }

  ////////////////////////

  getAircraftAirworthinessDirectiveById(aircraftDirectiveId: number) {
    return this.httpClient.get(
      environment.apiBaseUrl +
      '/airworthinessdirectives/getAircraftAirworthinessDirectiveById?id=' +
      aircraftDirectiveId);
  }

  fetchAllAircraftAirworthinessDirectiveByName(aircraftId: number, query, page: number = 1, pageSize: number = 25) {
    page = (+page) < 1 ? 1 : page;
    return this.httpClient.get(
      environment.apiBaseUrl +
      '/airworthinessdirectives/fetchAllAircraftAirworthinessDirectiveByName?aircraftId=' +
      aircraftId + '&query=' + query + '&page=' + page + '&pageSize=' + pageSize);
  }

  addAircraftAirworthinessDirective(aircraftAirworthinessDirective: AircraftAirDirectiveModel) {
    return this.httpClient.post(
      environment.apiBaseUrl + '/airworthinessdirectives/addAircraftAirworthinessDirective',
      aircraftAirworthinessDirective);
  }

  updateAircraftAirworthinessDirective(aircraftAirworthinessDirective: AircraftAirDirectiveModel) {
    return this.httpClient.put(
      environment.apiBaseUrl + '/airworthinessdirectives/updateAircraftAirworthinessDirective',
      aircraftAirworthinessDirective);
  }

  deleteAircraftAirworthinessDirective(directiveId: number) {
    return this.httpClient.delete(
      environment.apiBaseUrl +
      '/airworthinessdirectives/deleteAircraftAirworthinessDirective?directiveId=' +
      directiveId );
  }

  fetchAllAircraftDashboardADs(aircraftId: number, todayFlightHours: number) {
    return this.httpClient.get(
      environment.apiBaseUrl +
      '/airworthinessdirectives/fetchAllAircraftDashboardADs?aircraftId=' +
      aircraftId +
      '&todayFlightHours=' +
      todayFlightHours );
  }

  prepareSortingFieldsOnADsAndSBs (
    el: AircraftAirDirectiveModel | AircraftServiceBulletinModel,
    todayFlightHours: number,
    todayFlightDate: string,
    dashReferences: AircraftDashboardReferencesModel
  ) {
    if (el.dueHours) {
      const dueHoursDiff = el.dueHours - todayFlightHours;

      // safetyHoursToMonthConvertRate(18hours) equivaltent to 30 days
      const convertHourDiffToDays = Math.round(
        dueHoursDiff / dashReferences.safetyHoursToMonthConvertRate
      ) * 30;

      const dueHoursAsDate = moment(todayFlightDate);

      // add equivalent hours days to get estimated hours due date
      dueHoursAsDate.add(convertHourDiffToDays, 'days');

      el.dueHoursAsMoment = dueHoursAsDate;
    } else {
      el.dueHoursAsMoment = moment(environment.oldestDate);
    }

    const dueDateMoment = el.dueDate ? moment(el.dueDate) : moment(environment.oldestDate);
    el.dueDateAsMoment = dueDateMoment.clone(); // clone and get formatted date

    // NA, WhichOccursFirst, WhichHappensLast
    if (el.dueDate && !el.dueHours ) {
      el.sortingColumnName = 'Date';
      el.sortingMomentValue = dueDateMoment;
    } else if (el.dueHours && !el.dueDate) {
      el.sortingColumnName = 'Hours';
      el.sortingMomentValue = el.dueHoursAsMoment;
    } else if (el.condition == 'WhichOccursFirst') {
      const diff = dueDateMoment ? dueDateMoment.diff(el.dueHoursAsMoment, 'seconds') : null;

      if (diff <= 0) {
        el.sortingMomentValue = dueDateMoment;
        el.sortingColumnName = 'Date';
      } else {
        el.sortingMomentValue =  el.dueHoursAsMoment;
        el.sortingColumnName = 'Hours';
      }
    } else if (el.condition == 'WhichHappensLast') {
      const diff = dueDateMoment ? dueDateMoment.diff(el.dueHoursAsMoment, 'seconds') : null;

      if (diff > 0) {
        el.sortingMomentValue = dueDateMoment;
        el.sortingColumnName = 'Date';
      } else {
        el.sortingMomentValue =  el.dueHoursAsMoment;
        el.sortingColumnName = 'Hours';
      }
    } else {
      el.sortingMomentValue = el.dueDate ? dueDateMoment : el.dueHoursAsMoment;
      el.sortingColumnName = el.dueDate ? 'Date' :  'Hours';
    }

    // SET RAG STATUS
    if (el.sortingColumnName == 'Hours') {
      if (((todayFlightHours) >= el.dueHours)) {
        el.ragStatus = RagType.a_red;
      } else if ((todayFlightHours + dashReferences.safetyRefWarningHoursAhead) >= (+el.dueHours)) {
        el.ragStatus = RagType.b_amber;
      } else {
        el.ragStatus = RagType.c_green;
      }
    }

    if (el.sortingColumnName == 'Date') {
      const todayMoment = moment(todayFlightDate);
      // let warningMoment =  dueDateMoment.clone();
      const warningMoment =  todayMoment.clone();

      warningMoment.add(dashReferences.safetyRefWarningDaysAhead, 'days');

      const dueDiff = todayMoment.diff(dueDateMoment, 'seconds');
      const warningDiff = warningMoment.diff(dueDateMoment, 'seconds');

      if ( dueDiff >= 0 ) {
        el.ragStatus = RagType.a_red;
      } else if (warningDiff > 0) {
        el.ragStatus = RagType.b_amber;
      } else {
        el.ragStatus = RagType.c_green;
      }
    }

    return el;

  }

  filterAdSBs(query: string, combinedAds: Array<AircraftAirDirectiveModel | AircraftServiceBulletinModel>) {
    const result = [];
    if (query.trim().length < 1) {
      return result;
    }

    const tokens = query.trim().split(' ');

    const searchCount = {};

    // search for each each token
    tokens.forEach(
      (token) => {
        combinedAds.forEach(
          (ad: any, i) => {

            // console.log(ad);
            if (ad['searchField'] && token.length > 0) {
              if (ad['searchField'].toLowerCase().indexOf(token.toLowerCase()) > -1) {
                if (searchCount[i]) {
                  searchCount[i].count++;
                } else {
                  searchCount[i] = {
                    count: 1,
                    index: i
                  };
                }
              }
            }
          }
        );
      }
    );

    // sort search count by highest count
    const searchCountValues = Object.values(searchCount);
    searchCountValues.sort(
      (a: any, b: any) => (a.count < b.count) ? 1 : -1
    );


    // get filtered items
    searchCountValues.forEach(
      (item: any) => {
        console.log(item.index);
        result.push(combinedAds[item.index]);
      }
    );

    return result;

  }

  cleanUpInvalidDashboardADsandSBs(
    allADsAndSBs: Array<AircraftAirDirectiveModel | AircraftServiceBulletinModel>,
    lookAheadDate: string,
    lookAheadHours: number,
    todayFlightDate: string
  ) {

    allADsAndSBs.forEach(
      (el, i, thisArray) => {
        if (el.sortingColumnName == 'Hours') {
          if ((+el.dueHours ) > (+lookAheadHours)) {
            thisArray.splice(i, 1);
          }
        }

        if (el.sortingColumnName == 'Date') {
          const elDueDateMoment = moment(el.dueDate);
          const lookAheadDateMoment = moment(lookAheadDate);
          const todayFlightDateMoment = moment(todayFlightDate);

          const diff = elDueDateMoment.diff(lookAheadDateMoment, 'seconds');
          const diffToday = todayFlightDateMoment.diff(elDueDateMoment, 'seconds');

          if ((diff > 0) || (diffToday > 0)) {
            thisArray.splice(i, 1);
          }
        }
      }
    );

    return allADsAndSBs;
  }


  sortAllAircraftADsAndSBs(allAircraftADsAndSBs: Array<AircraftAirDirectiveModel | AircraftServiceBulletinModel>) {
    // sort by date
    allAircraftADsAndSBs.sort(
      (a, b) => {
        /*let dateATime = this.utilitiesService.parseISOtoDateObj(a.sortingValue);
        let dateBTime = this.utilitiesService.parseISOtoDateObj(b.sortingValue);
        return  dateBTime.getTime() - dateATime.getTime(); //SORT DESCENDING*/

        // console.log(a.sortingColumnName, a.dueHours, a.dueDate, '--', b.sortingColumnName, b.dueHours, b.dueDate ,'a, b')
        // SORT BY MOMENT VALUE
        const aMoment: moment.Moment = a.sortingMomentValue;
        const bMoment: moment.Moment = b.sortingMomentValue;

        return  bMoment.diff(aMoment, 'seconds'); // SORT DESCENDING
      }
    );


    // sort by RAG
    allAircraftADsAndSBs.sort(
      (a, b) => {
        return  a.ragStatus - b.ragStatus; // SORT DESCENDING
      }
    );
    return allAircraftADsAndSBs;
  }

  getRagColor(val: number) {
    if (val === null) {
      return null;
    }
    return RagType[val];
  }

}
