import { UserOrganizationAndRole } from '../../../models/user-organization-and-role';
import { OrganizationService } from '../../../services/organization.service';
import { SubscriptionLike } from 'rxjs';
import { UserModel } from '../../../models/user-model';
import { AircraftUserAndRoleModel } from '../../../models/aircraft-user-and-role-model';
import { UtilitiesService } from '../../../services/utilities.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AircraftRoleOptionsModel } from '../../../models/aicraft-role-options-model';
import { Component, OnInit, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AircraftService } from '../../../services/aircraft.service';
import { UserAircraftModel } from '../../../models/user-aircraft-model';
import { Router } from '@angular/router';
import { OrganizationAircraftRoleTypes } from '../../../models/organization-aircraft-role-types';
import { OrganizationPersonModel } from '../../../models/organization-person-model';
import { AircraftV2Model } from '../../../models/airfcraftV2/aircraft-v2.model';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-aircraft-associated-members',
  templateUrl: './aircraft-associated-members.component.html',
  styleUrls: ['./aircraft-associated-members.component.scss']
})
export class AircraftAssociatedMembersComponent implements OnInit, OnDestroy {

  currentUser: UserModel;
  private currentUser$: SubscriptionLike;

  selectedAircraft: AircraftV2Model = new AircraftV2Model;
  selectedAircraft$: SubscriptionLike;

  selectedOrg: UserOrganizationAndRole = new UserOrganizationAndRole;
  selectedOrg$: SubscriptionLike;

  allOrgPeople: Array<OrganizationPersonModel> = [];

  allAircraftPeople: Array<AircraftUserAndRoleModel> = [];
  page: number;
  pageSize: number;

  aircraftRoleOptions: AircraftRoleOptionsModel = new AircraftRoleOptionsModel;

  filterQuery: string;
  filterPage: number;
  filterPageSize: number;

  filterPeopleForm: FormGroup;
  addPersonRoleForm: FormGroup;
  updatePersonRoleForm: FormGroup;

  updatedPersonRole: AircraftUserAndRoleModel = new AircraftUserAndRoleModel;
  isUpdatingUser: boolean;

  isFetchingOrgs: boolean;
  isWorking: boolean;
  isAddingUser: boolean;

  isGettingAircraftDetails: boolean;

  theAircraft: UserAircraftModel = new UserAircraftModel;

  allowedRolesToEdit: Array<string> = [
    'ADMIN',
    'MANAGER',
  ];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private aircraftService: AircraftService,
    private orgService: OrganizationService,
    private userService: UserService,
    private utilsService: UtilitiesService,
    private modalService: NgbModal,
    private _eref: ElementRef
  ) { }

  ngOnInit() {
    ////// init values
    this.page = 0;
    this.pageSize = 200;

    this.filterQuery = '';
    this.filterPage = 0;
    this.filterPageSize = 200;
    this.isUpdatingUser = false;

    this.isFetchingOrgs = false;
    this.isWorking = false;
    this.isAddingUser = false;

    this.isGettingAircraftDetails = false;
    ////// End init values

    this.currentUser$ = this.userService.currentUser$.subscribe(
      (user: UserModel) => {
        this.currentUser = user;
      }
    );

    this.selectedAircraft$ = this.aircraftService.selectedAircraft$.subscribe(
      (aircraft: AircraftV2Model) => {
        this.selectedAircraft = aircraft;
        if (this.selectedAircraft.id !== null) {
          this.getUsersByAircraft();
        }
      }
    );

    this.selectedOrg$ = this.orgService.selectedOrg$.subscribe(
      (org: UserOrganizationAndRole) => {
        this.selectedOrg = org;
        if (this.selectedOrg.organization.id) {
          this.getUsersByAircraft();
        }
      }
    );

    this.selectedOrg$ = this.orgService.selectedOrg$.subscribe(
      (org: UserOrganizationAndRole) => {
        this.selectedOrg = org;
      }
    );

    this.filterPeopleForm = this.fb.group({
      'filter': new FormControl(this.filterQuery)
    });

  }


  getUsersByAircraft() {
    if (
      !(this.selectedAircraft.id)
    ) {
      return;
    }

    //this.getAircraftDetailsById();

    this.isWorking = true;
    this.aircraftService.getUsersByAircraftV2(this.selectedAircraft.id).subscribe(
      (response: any) => {
        this.isWorking = false;
        this.allAircraftPeople = response;
      },
      (error: any) => {
        this.isWorking = false;
        /**
          *TODO: HANDLE ERROR
         */
      }
    );
  }


  // getAircraftDetailsById() {
  //   this.isGettingAircraftDetails = true;
  //   this.aircraftService.getAircraftById(this.selectedAircraft.id).subscribe(
  //     (response: any) => {
  //       this.isGettingAircraftDetails = false;
  //       if (+response.status.code === 1000) {
  //         this.theAircraft = response.responseData;
  //       } else {
  //         this.theAircraft = new UserAircraftModel;
  //       }
  //     },
  //     (error: any) => {
  //       this.isGettingAircraftDetails = false;
  //       this.theAircraft = new UserAircraftModel;
  //       /**
  //         *TODO: HANDLE ERROR
  //        */
  //     }
  //   );
  // }

  /**
   * Closes edit person form on click away
   */
  @HostListener('click', ['$event'])
  onClick(event: any) {
    const pathRow = `row_${this.updatedPersonRole.user.emailAddress}`;
    const el = document.getElementById(pathRow);

    if ( this.updatedPersonRole.user.id && (event.composedPath().indexOf( el ) >= 0)) {
      return;
    } else {
      this.updatedPersonRole = new AircraftUserAndRoleModel;
    }
  }

  initAddPersonForm() {
    this.addPersonRoleForm = new FormGroup({
      'personEmail': new FormControl(
        null,
        [
          Validators.required,
          Validators.email
        ]
      ),
      'personRole': new FormControl('INDIVIDUAL_RO'),
    });

    this.addPersonRoleForm.get('personRole').setValidators(
      [
        Validators.required,
        Validators.nullValidator
        // TODO: Add Enum validator for roles
      ]
    );
    return;
  }

  initAddPersonModal(content) {
    this.getUsersOnOrganization();
    this.initAddPersonForm();
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      // Close Reason;
    }, (reason) => {
      // Dismissed Reason;
    });
  }



  getUsersOnOrganization() {
    this.isAddingUser = true;
    this.orgService.getUsersByOrganizationV2(this.selectedOrg.organization.id).subscribe(
      (response: any) => {
        this.isAddingUser = false;
        this.allOrgPeople = response;
      },
      (error: any) => {
        this.isAddingUser = false;
        /**
          *TODO: HANDLE ERROR
         */
      }
    );
  }

  doAddPersonRoleToAircraft(actionType: 'add' | 'update' = 'add') {
    const theForm = actionType && actionType.toLowerCase() === 'add' ? this.addPersonRoleForm : this.updatePersonRoleForm;

    const formValidate: boolean = this.utilsService.generalFormValidate(theForm);

    if (!formValidate) {
      return;
    }

    if (actionType && actionType === 'add') {
      this.isAddingUser = true;
    } else {
      this.isUpdatingUser = true;
    }

    // person email
    const personEmail = actionType && actionType === 'add' ?
      this.addPersonRoleForm.get('personEmail').value
      : this.updatedPersonRole.user.emailAddress;

    // person role
    const personRole = actionType && actionType === 'add' ?
      this.addPersonRoleForm.get('personRole').value
      : this.updatePersonRoleForm.get('personRole').value;


    this.aircraftService.putAircraftUserRoleV2( this.selectedAircraft.id, personEmail, personRole).subscribe(
      (response: any) => {
        this.isAddingUser = false;
        this.isUpdatingUser = false;

        Swal.fire(
          `Successfully ${actionType}ed`,
          `New user ${actionType}ed`,
          'success'
        )
        .then(
          () => {
            this.resetPageAndForms();
          }
        );
      },
      (error: any) => {
        this.isAddingUser = false;
        this.isUpdatingUser = false;

        if (error.message) {
          Swal.fire(
            error.message,
            'Can not add user on aircraft.',
            'error'
          );
        } else {
          Swal.fire(
            'Oops!',
            'Failed to add new person.',
            'error'
          );
        }
      }
    );

  }

  initUpdatePersonForm(person: any) {
    const thePersonRole = person && person.role ? person.role : null;
    this.updatePersonRoleForm = new FormGroup({
      'personRole': new FormControl(thePersonRole),
    });

    this.updatePersonRoleForm.get('personRole').setValidators(
      [
        Validators.required,
        Validators.nullValidator
        // TODO: Add Enum validator for roles
      ]
    );
    return;
  }

  loadPersonForUpdate(person: any) {
    this.updatedPersonRole = person && person.user && person.user.emailAddress ? person : null;

    if (this.updatedPersonRole) {
      this.initUpdatePersonForm(person);
    }
  }

  unloadPersonForUpdate() {
    this.updatedPersonRole = new AircraftUserAndRoleModel;
    this.updatePersonRoleForm.reset();
  }

  detachAircraftPersonRole(person: any) {

    Swal.fire({
      title: `Remove ${person.user.firstName} ${person.user.lastName} from aircraft?`,
      text: `Please confirm you want to remove ${person.user.firstName} ${person.user.lastName} ?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes. Detach person',
      cancelButtonText: 'No, take me back.'
    }).then((descision) => {
      if (descision.value) {
        this.isWorking = true;
        this.aircraftService.deleteAircraftUserRoleV2(this.selectedAircraft.id, person.user.emailAddress).subscribe(
          (response: any) => {
            this.isWorking = false;
            this.resetPageAndForms();
            Swal.fire(
              'Detach!',
              `${person.user.firstName} ${person.user.lastName} is no longer on the aircraft.`, 'success'
            );
          },
          (error: any) => {
            this.isWorking = false;
            if (error.message) {
              Swal.fire(
                error.status.message,
                'Can not remove user from aircraft.',
                'error'
              );
            } else {
              Swal.fire(
                'Oops!',
                `Failed to remove ${person.user.firstName} ${person.user.lastName} from aircraft.`,
                'error'
              );
            }
          }
        );
      } else if (descision.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });
  }

  togglePersonAdd(emailAddress: string) {

  }

  toggleSelectAllPerson() {

  }

  filterAircraftPeopleByNameEmailOrMobile() {

  }

  formatPhoneNumber(number: string) {
    return this.utilsService.formatPhoneNumber(number);
  }

  resetPageAndForms() {
    this.modalService.dismissAll();
    this.initAddPersonForm();
    this.updatedPersonRole = new AircraftUserAndRoleModel;
    this.getUsersByAircraft();
    return;
  }

  // /**
  //  * Check if user has right role to manage people
  //  */
  // checkIfCanManageAircraft(){
  //   if(this.selectedAircraft.role && this.selectedAircraft.role.toString().toUpperCase() === "INDIVIDUAL_RW"){
  //     return true;
  //   }
  //   else{
  //     return false;
  //   };
  // }

    /**
  * Check if user has right role to manage aircraft roles
  */
  checkIfCanManageRoles() {
    return this.allowedRolesToEdit.includes(
      this.selectedOrg
        .role
          .toString()
            .toUpperCase()
    );
  }

  getAircraftRoleLabelText(role: OrganizationAircraftRoleTypes) {
    return this.aircraftService.getAircraftRoleLabelText(role.toString());
  }


  ngOnDestroy() {
    if (this.currentUser$) {
      this.currentUser$.unsubscribe();
    }

    if (this.selectedAircraft$) {
      this.selectedAircraft$.unsubscribe();
    }

    if (this.selectedOrg$) {
      this.selectedOrg$.unsubscribe();
    }
  }
}
