import { startWith, tap } from 'rxjs/operators';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { UserService } from '../../../../services/user.service';
import { UserModel } from '../../../../models/user-model';
import { SubscriptionLike } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertPopUpsService } from '../../../../services/alert-pop-ups.service';
import { UtilitiesService } from '../../../../services/utilities.service';
import { AircraftService } from '../../../../services/aircraft.service';
import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  OnDestroy
} from '@angular/core';
import { AircraftV2Model } from '../../../../models/airfcraftV2/aircraft-v2.model';

interface FilterSearchListI {
  concatText: string;
  index: number;
}

@Component({
  selector: 'app-organization-aircraft-list',
  templateUrl: './organization-aircraft-list.component.html',
  styleUrls: ['./organization-aircraft-list.component.scss']
})
export class OrganizationAircraftListComponent implements OnInit, OnDestroy {
  @Input() orgId: number;
  @Output() aircraftEmmitter: EventEmitter<
    Array<AircraftV2Model>
  > = new EventEmitter();

  constructor(
    private aircraftService: AircraftService,
    private utilsService: UtilitiesService,
    private popUpService: AlertPopUpsService,
    private userService: UserService,
    private location: Location,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  aircraftList: Array<AircraftV2Model> = [];
  isWorking: boolean;
  fetched: boolean;

  filterSearchForm: FormGroup;
  filterQuery: string;
  filterUserListSubscriber$: SubscriptionLike;

  filterResultsLoading: boolean;

  filterResult: Array<AircraftV2Model> = [];
  filterSearchList: Array<FilterSearchListI> = [];

  currentUser: UserModel;
  private currentUser$: SubscriptionLike;

  ngOnInit() {
    this.isWorking = false;
    this.fetched = false;
    this.filterResultsLoading = false;

    this.currentUser$ = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });

    // add tab parameter in url
    const fullUrl: Array<string> = this.router.url.split('?');
    if (fullUrl[0]) {
      this.location.go(fullUrl[0] + '?t=aircraft');
    }

    if (this.orgId) {
      this.getOrgaircraft();
    }

    this.filterSearchForm = this.formBuilder.group({
      filterQuery: new FormControl(this.filterQuery)
    });

    this.filterUserListSubscriber$ = this.filterSearchForm.controls[
      'filterQuery'
    ].valueChanges
      .pipe(
        startWith(''),
        tap(x => (this.filterResultsLoading = true))
      )
      .subscribe((query: string) => {
        this.filterQuery = query;
        if (!query) {
          // this.getAllUsers();
        } else {
          this.filterListWithQuery();
        }
      });
  }

  getOrgaircraft() {
    this.isWorking = true;
    this.aircraftService.getAircraftByOrganizationV2(this.orgId).subscribe(
      (response: any) => {
        this.fetched = true;
        this.isWorking = false;
        this.aircraftList = response;

        this.aircraftList.forEach((el, i) => {
          const c = el.tailNo + ' '
            + el.airframe.model + ' '
            + el.airframe.make + ' ';

          this.filterSearchList.push({
            concatText: c.toLowerCase(),
            index: i
          });
        });

        this.aircraftEmmitter.emit(this.aircraftList);
      },
      error => {
        this.fetched = true;
        this.isWorking = false;
        this.popUpService.openBasicPopUp(
          `Sorry`,
          `Can not get organization aircraft.`,
          'error'
        );
      }
    );
  }

  filterListWithQuery() {
    this.filterResult = [];

    this.filterSearchList.forEach(el => {
      if (el.concatText.includes(this.filterQuery.toLowerCase())) {
        this.filterResult.push(this.aircraftList[el.index]);
      }
    });
  }

  formatPhoneNumber(phoneNumber) {
    return phoneNumber
      ? this.utilsService.formatPhoneNumber(phoneNumber)
      : '--- --- ---- ';
  }

  ngOnDestroy() {
    if (this.currentUser$) {
      this.currentUser$.unsubscribe();
    }
  }
}
