import { UserBaseModel } from './user-base-model';

export class OrganizationPersonModel {
  constructor() {
    this.role = null;
    this.user = new UserBaseModel();
  }
  role: string;
  user: UserBaseModel;
}
