import { OptionLabelsAndValuesModel } from './option-labels-and-values-model';

export class OrganizationUserRoleOptionsModel {
  constructor() {
    this.options = [
      {label: '--Select role--', value: ''},
      {label: 'Admin', value: 'ADMIN'},
      {label: 'Manager', value: 'MANAGER'},
      {label: 'Member', value: 'MEMBER'},
    ];
  }
  options: Array<OptionLabelsAndValuesModel>;
}
