<div class="d-block mb-2" *ngIf="userOrganizations.length > 0">
  <div class="py-0 px-4" id="organizationsListHeader">
    <!-- <i class="la la-building-o text-orange h3 mr-1 pull-left"></i> -->
    <span class="menu_icon">
      <img src="assets/images/visual_assets/menu_icons/Organization Icon - Orange-01.png" alt="">
    </span>
    <span class="desc text-orange h5">
      Organization
      <h6 class="text-paleblue">{{selectedOrg?.organization?.organizationName}}</h6>
    </span>
  </div>

  <div class="border-top border-bottom">
    <small class="my-3 text-muted text-left px-4">
      Select Organization
    </small>
  
    <div class="org-list" aria-labelledby="organizationsMenuList">
      <div class="my-2 cursor-pointer org-list-item px-4 py-1"
      *ngFor="let org of userOrganizations; let i = index"
        (click)="setSelectedOrganization(org)" >
          {{org?.organization?.organizationName}}
          <small class="d-block">
            {{org?.role}}
          </small>
        </div>
    </div>
  </div>

</div>



<div class="d-block mb-2 mt-1" *ngIf="userOrganizations.length < 1">
  <a class="nav-link px-4" href="javascript: void(0);" >
    <i class="la la-plane"></i>
    <span class="desc text-orange h5">
      Organization
      <small class="d-block text-paleblue ellipsis">
        NO ORGANIZATION
      </small>
    </span>
  </a>
</div>
