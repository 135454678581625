import { UtilitiesService } from './../services/utilities.service';
import { environment } from './../../environments/environment';
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { Observable, throwError, ObservableInput, BehaviorSubject, Subject, of, SubscriptionLike } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { retry, catchError, switchMap, map, tap, filter, take } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private utilsService: UtilitiesService,
  ) {}

  private isRefreshing: Boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  //public tokenRefreshed$: Observable<any> = this.refreshTokenSubject.asObservable();

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let self =this;

    req = this.addTokenCookieAndHeaders(req);

    this.utilsService.updateShowActivityPreloader(true);

    let returnedRequest: HttpRequest<any> = null;

    return next.handle(req)
    .pipe(
      //retry(2),
      tap((event: HttpEvent<any>)=>{
        if (event instanceof HttpResponse) {
          this.utilsService.updateShowActivityPreloader(false);
        }
      }),
      catchError((error, caught: Observable<HttpEvent<any>>):ObservableInput<any> => {

        this.utilsService.updateShowActivityPreloader(false);

        if (error instanceof HttpErrorResponse) {
          // The backend returned an unsuccessful response code.
          // error http status via error.status
          // error body via error.error
          // The response body may contain clues as to what went wrong,

          //can't connect to internet or server
          if(error.status == 0){
            let err = {
              status:{
                code:9999,
                message:'Can not connect!'
              },
              responseData:["Server's either down or please check your internet connection"]
            };
            return throwError (err)
          }

          switch (error.status) {
            case 400:
              console.error("400 Error");
              //return Observable.throw(error.error);
              break;

            case 401:
              //refresh token
              console.error("401 Error");
              return this.handle401Refresh(req, next);

            case 403:
              //Forbidden
              console.error("403 Error");
              this.logoutUser();
              //return Observable.throw(error.error, this.tokenManager.retrieveToken());
              break;

            case 404:
              //Record not found
              console.error("404 Error");
              ///return this.handle404Error(error)
              break;

            case 500:
              console.error("500 Error");
              //return this.handle500Error(error)
              break;
          }

        }
        else {
          // A client-side or network error occurred. Handle it accordingly.
          //like is error.error instanceof ErrorEvent
          console.error('An error occurred:', error);
          //console.error('An error occurred:', error.error.message);
        }

        // return an observable with a user-facing error message
        let err = error.error? error.error : error;
        return throwError(err)
      })
    );
  }

  addTokenCookieAndHeaders(req){
    //let idToken = localStorage.getItem(environment.tokenKey);
    let idToken = this.authService.getToken();

    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${idToken}`
      },
      //withCredentials: true
    });

    return req;
  }

  handle401Refresh(req: HttpRequest<any>, next: HttpHandler){
    if (this.isRefreshing) {
      //console.log(`YES! this.isRefreshing value is ${this.isRefreshing}`)
      return this.refreshTokenSubject.pipe(
        filter(val => {
            let isNullVal = val !== null;

            if(isNullVal) {
              this.isRefreshing = false;
            }

            this.utilsService.updateShowMainPreloader(isNullVal);

            return isNullVal;
          }
        ),
        take(1),
        switchMap(token => {
          this.authService.saveToken(token);
          this.utilsService.updateShowMainPreloader(false);
          return next.handle(this.addTokenCookieAndHeaders(req));
        })
      );
    }
    else {
      //console.log(`NO! this.isRefreshing value is ${this.isRefreshing}`)
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      //let refreshToken = localStorage.getItem(environment.refreshTokenKey);
      let refreshToken = this.authService.getRefreshToken();

      this.utilsService.updateShowMainPreloader(true);
      console.log('Trying token refresh...');

      return this.authService.refreshTokenV2(refreshToken).pipe(
        switchMap(
          (response: any) => {
            console.log('... Gotten token refresh.')
            this.utilsService.updateShowMainPreloader(false);
            this.isRefreshing = false;
            this.authService.saveToken(response.token);

            this.refreshTokenSubject.next(response.token);
            return next.handle(this.addTokenCookieAndHeaders(req));
          }
          //error will be handled by different code
        )
      );
    }
  }

  //PAGE NOT FOUND
  handle404Error(error) {
    // const router = this.inj.get(Router); //injected manually
    // console.log("Error 404: Record not found");
    // let errorJson = error.error;

    // if(errorJson.status.message){
    //   console.log(errorJson.status.message);
    //   return Observable.throw(error.error);
    // }

    // router.navigate(['/page404']);
    // return Observable.throw(error.error);
  }


  //SYSTEM ERROR
  handle500Error(error) {
    // const router = this.inj.get(Router); //injected manually

    // console.log("Error 500: Server Error");
    // let errorJson = error.error;

    // if(errorJson.status.message){
    //     console.log(errorJson.status.message);
    //     return Observable.throw(error.error);
    // }
    // router.navigate(['/page500']);
    // return Observable.throw(error.error);
  }

  logoutUser(){
    this.utilsService.updateShowMainPreloader(false);
    this.authService.forceLogOutUser();
    return;
  }
}
