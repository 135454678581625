import { AircraftModel } from './aircraft-model';
import { EngineModel } from './engine-model';
import { PropellerModel } from './propeller-model';
import { ApplianceModel } from './appliance-model';

export class ServiceBulletinModel {
  constructor(){
    this.id = null;
    this.bulletinName = null;
    this.subject = null;
    this.publishedDate = null;
    this.adSbType = null;
    this.mandatory = false;
    this.isRecurring = false;
    this.aircraftModels = [];
    this.engineModels = [];
    this.propellerModels = [];
    this.applianceModels = [];
  }
  id: number;
  bulletinName: string;
  subject: string;
  publishedDate: string;
  adSbType: 'airframe' | 'engine' | 'propeller' | 'appliance';
  mandatory: boolean;
  isRecurring: boolean;
  aircraftModels?: Array<AircraftModel>;
  engineModels?: Array<EngineModel>;
  propellerModels?: Array<PropellerModel>;
  applianceModels?: Array<ApplianceModel>;
  fileUrl: string;
}