import { Injectable } from '@angular/core';
import Swal, { SweetAlertType } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertPopUpsService {
  constructor() {}

  openBasicPopUp(
    mainTitle: string,
    mainText: string,
    type: SweetAlertType = 'success'
  ) {
    return Swal.fire(mainTitle, mainText, type);
  }

  openConfirmPopUp(
    mainTitle: string,
    mainText: string,
    type: SweetAlertType = 'success',
    showCancelButton: boolean = true,
    confirmButtonText: string = 'Yes?',
    cancelButtonText: string = 'No!'
  ) {
    return Swal.fire({
      title: mainTitle,
      text: mainText,
      type: type,
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText
    });
  }
}
