import { AircraftService } from './../../../services/aircraft.service';
import { UserAircraftModel } from './../../../models/user-aircraft-model';
import { UserMenuAircraftModel } from './../../../models/user-menu-aircraft-model';
import { OrganizationService } from './../../../services/organization.service';
import { UserService } from './../../../services/user.service';
import { UserModel } from './../../../models/user-model';
import { UtilitiesService } from './../../../services/utilities.service';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { UserOrganizationAndRole } from '../../../models/user-organization-and-role';
import { AircraftV2Model } from '../../../models/airfcraftV2/aircraft-v2.model';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnDestroy {

  constructor(
    private utilitiesService: UtilitiesService,
    private userService: UserService,
    private aircraftService: AircraftService,
    private orgService: OrganizationService
  ) { }

  currentUser: UserModel;
  private currentUser$: SubscriptionLike;

  isSideBarCollapsed: boolean;
  private isSideBarCollapsed$: SubscriptionLike;


  selectedOrg: UserOrganizationAndRole = new UserOrganizationAndRole;
  selectedOrg$: SubscriptionLike;

  menuSelectedAircraft: AircraftV2Model = new AircraftV2Model;

  windowWidth: number;

  ngOnInit() {
    this.aircraftService.resetUserMenuAircraft();


    this.selectedOrg$ = this.orgService.selectedOrg$.subscribe(
      (org: UserOrganizationAndRole) => {
        this.selectedOrg = org;
      }
    );

    this.isSideBarCollapsed$ = this.utilitiesService.isSideBarCollapsed$.subscribe(
      (val) => {
        this.isSideBarCollapsed = val;
      }
    );

    this.onResize(null);

    this.currentUser$ = this.userService.currentUser$.subscribe(
      (user) => {
        this.currentUser = user;
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  // close size bar on resize when window width is smaller than 760px
  onResize(event) {
    this.windowWidth = window.innerWidth;
    if (this.windowWidth < 768) {
      this.forceCloseSidebar();
    } else {
      this.forceOpenSidebar();
    }
  }


  toggleSideBar() {
    this.utilitiesService.toggleSideBar();
  }

  forceOpenSidebar() {
    if (this.isSideBarCollapsed === true) {
      this.utilitiesService.toggleSideBar();
    }
  }

  forceCloseSidebar() {
    if (!this.isSideBarCollapsed) {
      this.utilitiesService.toggleSideBar();
    }
  }

  hasOrgAdminOrManagerAccess() {
    return this.selectedOrg.role
    && (
      this.selectedOrg.role.toString().toUpperCase() === 'ADMIN' ||
      this.selectedOrg.role.toString().toUpperCase() === 'MANAGER'
    );
  }

  onMenuSelectAircraft($event) {
    this.menuSelectedAircraft = $event ? $event : new AircraftV2Model;
  }

  // hasAircraftReadAccess(){
  //   return this.menuSelectedAircraft.organizationRole
  //   && (this.menuSelectedAircraft.organizationRole.toString().toUpperCase() == 'INDIVIDUAL_RW')
  // }

  hasAircraftReadAccess() {
    if (
      (this.selectedOrg.role && this.selectedOrg.role.toString().toUpperCase() === 'ADMIN') ||
        this.currentUser.isAdmin
    ) {
      return true;
    }

    return this.menuSelectedAircraft.userRole
    && (
      this.menuSelectedAircraft.userRole.toString().toUpperCase() === 'INDIVIDUAL_RW'
    );
  }

  ngOnDestroy() {
    if (this.isSideBarCollapsed$) {
      this.isSideBarCollapsed$.unsubscribe();
    }

    if (this.currentUser$) {
      this.currentUser$.unsubscribe();
    }
  }

}
