<div class="card shadow border-0 aircraft-dashboard-pilot-status">
  <div class="card-header">
    <div class="clearfix">
      <h6 class="float-left mt-1">
        <span class="badge rag_circle {{getPilotStatusBadgeColor()}}">&nbsp;</span>
        Pilot Status <small class="text-muted">@ {{ theAircraft?.airframe?.ttis }} Hours</small>
      </h6>

      <a *ngIf="!isAdminView" [routerLink]="['/cga/profile']" [queryParams]="{viewupdateMode:1}" class="btn-outline-primary btn float-right rounded btn-sm">
        Edit Dates
      </a>
    </div>
  </div>

  <div class="card-body">
    <app-preloader-type-a *ngIf="isLoading"></app-preloader-type-a>

    <div *ngIf="!isLoading">

      <table class="table table-striped w-100 rag_table" >
        <tbody>

            <!---Medical-->
            <tr class="{{getRagColor(medicalDueRagStatus)}}">

              <td>
                <div class="row" *ngIf="!medicalDueNextDueDateMoment">
                  <div class="col-12">
                    <div class="alert alert-danger text-center" role="alert">
                      No Medical Due date set for pilot. <br />
                      Please update in <a [routerLink]="['/cga/dash-dates']">My Dashboard Dates</a>.
                    </div>
                  </div>
                </div>

                <div *ngIf="medicalDueNextDueDateMoment">
                  <h6 class="clearfix">
                    <small class="text-primary d-block">
                      &nbsp;
                    </small>
                    Medical
                  </h6>
                  <div class="row">
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Last Received
                      </small>
                      {{ medicalDueLastDueDateMoment? formatMomentAsUsDateString(medicalDueLastDueDateMoment) : "---- " }}
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Due
                      </small>
                      {{ medicalDueNextDueDateMoment | date:'MM/dd/yyyy' }}
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <ng-container *ngIf="medicalDueNextDueDateMoment">
                        <small class="text-primary d-block">
                          Remaining
                        </small>
                        {{getMomentBetweenDates(todayFlightDate, medicalDueNextDueDateMoment)}}
                      </ng-container>

                      <ng-container *ngIf="!medicalDueNextDueDateMoment">
                        <small class="text-primary d-block">
                          &nbsp;
                        </small>
                        -- --
                      </ng-container>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <!---FLIGHT REVIEW-->
            <tr class="{{getRagColor(flightReviewRagStatus)}}">
              <td>
                <div class="row" *ngIf="!flightReviewNextDueDateMoment">
                  <div class="col-12">
                    <div class="alert alert-danger text-center" role="alert">
                      No Flight Review Due date set for pilot. <br/>
                      Please update in <a [routerLink]="['/cga/dash-dates']">My Dashboard Dates</a>.
                    </div>
                  </div>
                </div>

                <div *ngIf="flightReviewNextDueDateMoment">
                  <h6 class="clearfix">
                    <small class="text-primary d-block">
                      &nbsp;
                    </small>
                    Flight Review
                  </h6>
                  <div class="row">
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Last Received
                      </small>
                      {{ flightReviewLastDueDateMoment? formatMomentAsUsDateString(flightReviewLastDueDateMoment): '----' }}
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Due
                      </small>
                      {{ flightReviewNextDueDateMoment? formatMomentAsUsDateString(flightReviewNextDueDateMoment) : '----' }}
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <ng-container *ngIf="flightReviewNextDueDateMoment">
                        <small class="text-primary d-block">
                          Remaining
                        </small>
                        {{getMomentBetweenDates(todayFlightDate, flightReviewNextDueDateMoment)}}
                      </ng-container>

                      <ng-container *ngIf="!flightReviewNextDueDateMoment">
                        <small class="text-primary d-block">
                          Remaining
                        </small>
                        {{getMomentBetweenDates(todayFlightDate, flightReviewNextDueDateMoment)}}
                      </ng-container>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

        </tbody>
      </table>
    </div>

  </div>
</div>
