<!-- heading -->
<h4>Global Airworthiness Directives</h4>

<!-- sub heading -->
<h6 class="mb-0">
  <i class="la la-certificate "></i>
  Manage Airworthiness Directives
</h6>

<!-- sub sub heading -->
<small class="text-muted mt-1 mb-3">Create and Manage Global Airwothiness Directives</small>

<!-- directive list container -->
<section class="mt-3">
  <div class="card shadow border-0 admin-create-user">
    <!-- header -->
    <div class="card-header clearfix">
      Manage Airworthiness Directives
      <span class="float-right">
        <button class="btn btn-sm btn-outline-dark rounded" (click)="toggleInCreateNewDirectiveMode()">
          <span *ngIf="!inCreateNewDirectiveMode">New <i class="la la-plus"></i></span>
          <span *ngIf="inCreateNewDirectiveMode">Cancel <i class="la la-times"></i></span>
        </button>
      </span>
    </div>

    <!-- body -->
    <div class="card-body">

      <!--NEW SERVICE BULLETIN-->
      <div class="card mb-3" *ngIf="inCreateNewDirectiveMode">
        <!--<div class="card-header">
          New Airworthiness Directive
        </div>-->
        <div class="card-body">
          <app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

          <form [formGroup]="newAirworthinessDirectiveForm" *ngIf="!isWorking" (ngSubmit)="doCreateDirective()">
            <div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted required">Enter Airworthiness Directive name.</small>
                    <input type="text" formControlName="directiveName" class="form-control" id="directive-name" aria-describedby="firstname_help" placeholder="Airworthiness Directive Name">
                    <small class="form-text text-danger" *ngIf="newAirworthinessDirectiveForm.get('directiveName').invalid && (newAirworthinessDirectiveForm.get('directiveName').touched || newAirworthinessDirectiveForm.get('directiveName').dirty)">
                      Enter Directive Name.
                    </small>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted required">Airworthiness Directive Effective Date.</small>
                    <div class="input-group">
                      <input class="form-control" formControlName="effectiveDate"  placeholder="mm/dd/yyyy" name="effectiveDate" ngbDatepicker #d="ngbDatepicker">
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                          <i class="la la-calendar-plus-o"></i>
                        </button>
                      </div>
                    </div>
                    <small class="form-text text-danger" *ngIf="newAirworthinessDirectiveForm.get('effectiveDate').invalid && (newAirworthinessDirectiveForm.get('effectiveDate').touched || newAirworthinessDirectiveForm.get('effectiveDate').dirty)">
                      Enter Effective Date.
                    </small>
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-12">
                  <div class="form-group">
                    <small class="form-text text-muted required">Enter Airworthiness Directive Subject.</small>
                    <input type="text" formControlName="subject" class="form-control" id="directive-name" aria-describedby="subject" placeholder="Airworthiness Directive Subject">
                    <small class="form-text text-danger" *ngIf="newAirworthinessDirectiveForm.get('subject').invalid && (newAirworthinessDirectiveForm.get('subject').touched || newAirworthinessDirectiveForm.get('subject').dirty)">
                      Enter Directive Subject.
                    </small>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12">
                  <div class="form-group">
                    <small class="form-text text-muted required">Airworthiness Directive Type:</small>
                    <div class="input-group">
                      <select class="form-control" (change)="onAdTypeChange($event, 'new')" formControlName="adSbType" name="adSbType">
                        <option *ngFor="let type of adSbTypeOptions.options" [value]="type.value"> {{type.label}}</option>
                      </select>
                    </div>
                    <small class="form-text text-danger" *ngIf="newAirworthinessDirectiveForm.get('adSbType').invalid && (newAirworthinessDirectiveForm.get('adSbType').touched || newAirworthinessDirectiveForm.get('adSbType').dirty)">
                      Select Type
                    </small>
                  </div>
                </div>


                <div class="col-sm-12 col-md-12">
                  <div class="form-group d-block">
                    <small class="form-text text-muted">
                      Find and Add Models
                      <em * ngIf="modelSearching">..Searching...</em>
                    </small>
                    <input type="text" [readOnly]="newAirworthinessDirectiveForm.get('adSbType').invalid" formControlName="modelFinder" class="form-control" id="modelFinder" placeholder="Find Models"
                      [class.is-invalid]="modelSearchFailed" [ngbTypeahead]="modelTypeHeadSearch"  [editable]="false"
                      [resultTemplate]="rtModelFinder" [inputFormatter]="typeHeadModelFormatter" (selectItem)="onTypeHeadSelected($event)"
                    >

                    <ng-template #rtModelFinder let-r="result" let-t="term">
                      <ngb-highlight [result]="getHighlightDisplayName(r)" [term]="t"></ngb-highlight>
                    </ng-template>
                  </div>

                  <div id="new_models" class="border-info rounded p-3">
                    <span *ngFor="let model of adNewModels; let i = index;" class="badge badge-secondary mr-3">
                      {{ model.modelName }}  <em>* {{getModelManufacturerName(model)}}</em>
                      <a href="javascript: void(0)" (click)="removeModelFromNewList(i)" class="btn-sm btn btn-danger ml-2 mb-1">
                        <i class="la la-trash"></i>
                      </a>
                    </span>
                  </div>
                </div>

                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <small class="form-text text-muted">Recurring?:</small>
                    <div class="input-group">
                      <select class="form-control" formControlName="isRecurring" name="isRecurring">
                        <option value="false" [selected]=" (newAirworthinessDirectiveForm.get('isRecurring').value == 'false') || (!newAirworthinessDirectiveForm.get('isRecurring').value) ">
                          NO
                        </option>
                        <option value="true" [selected]=" (newAirworthinessDirectiveForm.get('isRecurring').value == 'true') || newAirworthinessDirectiveForm.get('isRecurring').value ">
                          YES
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="text-right mt-2">
              <button class="btn btn-outline-danger mr-3" (click)="toggleInCreateNewDirectiveMode()">Cancel</button>
              <button class="btn btn-outline-primary">Create Airworthiness Directive</button>
            </div>
          </form>
        </div>
      </div>

      <!----ALL Airworthiness DirectiveS-->
      <div class="card">
        <!-- header -->
        <div class="card-header">
          Available Airworthiness Directives
        </div>

        <!-- body -->
        <div class="card-body">
          <!-- filter -->
          <form [formGroup]="airworthinessDirectiveSearchForm" >
            <div class="form-group form-inline">
              Filter:
              <input class="form-control ml-2" formControlName="queryString" type="text" name="query" />
              <span class="ml-3" *ngIf="directivesLoading">Loading...</span>
            </div>
          </form>

          <app-preloader-type-a *ngIf="directivesLoading"></app-preloader-type-a>

          <!-- directive item -->
          <table class="table table-striped" *ngIf="!directivesLoading">
            <tr *ngFor="let directive of allAirworthinessDirectives; let i = index;">
              <td>
                <!-- number -->
                <h6 class="clearfix mb-1 text-primary">
                {{ ((page - 1) * pageSize) + (i + 1) }}:
                <ngb-highlight [result]="directive.directiveName"></ngb-highlight>

                <!-- is recurring -->
                <a class="text-info mx-2 h5"
                *ngIf="directive.isRecurring"
                  href="javascript: void(0)"  ngbPopover="Recurring Airworthiness Directive"
                  triggers="mouseenter:mouseleave" popoverTitle="isRecurring">
                  <i class="la la-refresh" popoverClass="bg-dark text-light"></i>
                </a>

                <!-- buttons -->
                <span class="float-right">
                  <!-- edit button -->
                  <button class="btn btn-outline-dark rounded btn-sm mr-3"
                          (click)="loadDirectiveForEdit(directive)"
                    *ngIf="directive.id !== editAirworthinessDirective.id">
                    <i class="la la-edit"></i>
                  </button>

                  <button class="btn btn-outline-dark rounded btn-sm mr-3"
                          (click)="unLoadDirectiveForEdit()"
                          *ngIf="directive.id == editAirworthinessDirective.id">
                    <i class="la la-times"></i>
                  </button>

                  <!-- delete button -->
                  <button class="btn btn-outline-danger rounded btn-sm"
                  (click)="deleteAirworthinessDirective(directive)">
                    <i class="la la-trash"></i>
                  </button>
                </span>

              </h6>
              <div class="row">
                <!-- manufacturers -->
                <div class="col-12 mb-1 pr-2">
                  <small class="text-muted">Manufacturers:</small>
                  {{getDirectiveModelManufacturers(directive)}}
                </div>

                <!-- make -->
                <div class="col-12 mb-1 pr-2">
                  <small class="text-muted">Make:</small>
                  {{ getDirectiveModelMakes(directive) }}
                </div>

                <!-- subject -->
                <div class="col-12 mb-1 pr-2">
                  <small class="text-muted">Subject:</small>
                  {{directive.subject? directive.subject : '---- '}}
                </div>

                <!-- effective date -->
                <div class="col-12 col-sm-12 col-md-4">
                  <small class="d-block text-muted">
                    Effective Date
                  </small>
                  <!--  'is new' marker -->
                  {{directive.effectiveDate | date:'MM/dd/yyyy'}}

                  <!--  'is new' marker -->
                  <small *ngIf="getSixMonthsPrior(directive.effectiveDate)"  class="badge badge-primary mr-3" style="font-size: x-small">({{ getSixMonthsPrior(directive.effectiveDate) ? 'new' : 'ass' }})</small>

                </div>

                <!-- type -->
                <div class="col-12 col-sm-12 col-md-4">
                  <small class="d-block text-muted">
                    Type
                  </small>
                  {{directive?.adSbType | uppercase}}
                </div>

                <!--AD Document View Link-->
                <div class="col-12 col-sm-12 col-md-4">
                  <button (click)="openModal(content, directive.fileUrl, directive.directiveName)" class="btn btn-danger btn-sm rounded" *ngIf="directive.fileUrl">
                    <small class="d-block text-white">
                      <i class="la la-file-pdf-o"></i>
                      View Document
                    </small>
                  </button>

                  <span class="badge badge-light rounded p-2" *ngIf="!directive.fileUrl">
                    <small class="d-block text-muted">
                      <i class="la la-file-pdf-o"></i>
                      No Document
                    </small>
                  </span>
                </div>

                <!---MODELS IN AD-->
                <div class="col-12 col-sm-12 col-md-12">
                  <small class="d-block text-muted">
                    Models
                  </small>
                  <!--{{directive.ad_sb_manufacturer?.modelName}}-->
                    <ng-container>
                      <span *ngFor="let model of directive.aircraftModels; let i = index;" class="badge badge-primary mr-3">
                        {{ model.modelName }}
                      </span>
                    </ng-container>

                    <ng-container>
                      <span *ngFor="let model of directive.engineModels; let i = index;" class="badge badge-primary mr-3">
                        {{ model.modelName }}
                      </span>
                    </ng-container>

                    <ng-container>
                      <span *ngFor="let model of directive.propellerModels; let i = index;" class="badge badge-primary mr-3">
                        {{ model.modelName }}
                      </span>
                    </ng-container>

                    <ng-container>
                      <span *ngFor="let model of directive.applianceModels; let i = index;" class="badge badge-primary mr-3">
                        {{ model.modelName }}
                      </span>
                    </ng-container>
                </div>
              </div>
                <!--EDIT AD-->
                <div class="card mb-3 border-danger" *ngIf="directive.id == editAirworthinessDirective.id">
                  <div class="card-body">

                    <app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

                    <form [formGroup]="editAirworthinessDirectiveForm" *ngIf="!isWorking" (ngSubmit)="doUpdateDirective()">
                      <div>
                        <div class="row">

                          <div class="col-sm-12 col-md-6">
                            <div class="form-group">
                              <small class="form-text text-muted required">Update Airworthiness Directive name.</small>
                              <input type="text" formControlName="directiveName" class="form-control" id="directive-name" aria-describedby="firstname_help" placeholder="Airworthiness Directive Name">
                              <small class="form-text text-danger" *ngIf="editAirworthinessDirectiveForm.get('directiveName').invalid && (editAirworthinessDirectiveForm.get('directiveName').touched || editAirworthinessDirectiveForm.get('directiveName').dirty)">
                                Enter directive name
                              </small>
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-6">
                            <div class="form-group">
                              <small class="form-text text-muted required">Airworthiness Directive Effective Date.</small>
                              <div class="input-group">
                                <input class="form-control" formControlName="effectiveDate"  placeholder="mm/dd/yyyy" name="effectiveDate" ngbDatepicker #d="ngbDatepicker">
                                <div class="input-group-append">
                                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                                    <i class="la la-calendar-plus-o"></i>
                                  </button>
                                </div>
                              </div>
                              <small class="form-text text-danger" *ngIf="editAirworthinessDirectiveForm.get('effectiveDate').invalid && (editAirworthinessDirectiveForm.get('effectiveDate').touched || editAirworthinessDirectiveForm.get('effectiveDate').dirty)">
                                Enter deffective date
                              </small>
                            </div>
                          </div>

                          <div class="col-12 col-sm-12 col-md-12">
                            <div class="form-group">
                              <small class="form-text text-muted required">Update Airworthiness Directive Subject.</small>
                              <input type="text" formControlName="subject" class="form-control" id="directive-subject" placeholder="Airworthiness Directive Subject">
                              <small class="form-text text-danger" *ngIf="editAirworthinessDirectiveForm.get('subject').invalid && (editAirworthinessDirectiveForm.get('subject').touched || editAirworthinessDirectiveForm.get('subject').dirty)">
                                Enter directive Subject
                              </small>
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-12">
                            <div class="form-group">
                              <small class="form-text text-muted required">Update Directive Type:</small>
                              <div class="input-group">
                                <select class="form-control" (change)="onAdTypeChange($event, 'edit')" formControlName="adSbType" name="adSbType">
                                  <option *ngFor="let type of adSbTypeOptions.options" [value]="type.value"> {{type.label}}</option>
                                </select>
                              </div>
                              <small class="form-text text-danger" *ngIf="editAirworthinessDirectiveForm.get('adSbType').invalid && (editAirworthinessDirectiveForm.get('adSbType').touched || editAirworthinessDirectiveForm.get('adSbType').dirty)">
                                Select Type
                              </small>
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-12">
                            <div class="form-group d-block">
                              <small class="form-text text-muted">
                                Find and Add Models
                                <em * ngIf="modelSearching">..Searching...</em>
                              </small>
                              <input type="text" [readOnly]="editAirworthinessDirectiveForm.get('adSbType').invalid"  formControlName="modelFinder" class="form-control" id="modelFinder" placeholder="Find Models"
                                [class.is-invalid]="modelSearchFailed" [ngbTypeahead]="modelTypeHeadSearch"  [editable]="false"
                                [resultTemplate]="rtModelFinderEdit" [inputFormatter]="typeHeadModelFormatter" (selectItem)="onTypeHeadEditSelected($event)"
                              >

                              <ng-template #rtModelFinderEdit let-r="result" let-t="term">
                                <ngb-highlight [result]="getHighlightDisplayName(r)" [term]="t"></ngb-highlight>
                              </ng-template>
                            </div>

                            <div id="new_models" class="border-danger rounded p-3">
                              <span *ngFor="let model of adEditModels; let i = index;" class="badge badge-secondary mr-3">
                                {{ model.modelName }}  <em>* {{getModelManufacturerName(model)}}</em>
                                <a href="javascript: void(0)" (click)="removeModelFromEditList(i)" class="btn-sm btn btn-danger ml-2 mb-1">
                                  <i class="la la-trash"></i>
                                </a>
                              </span>
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-3">
                            <div class="form-group">
                              <small class="form-text text-muted">Recurring?:</small>
                              <div class="input-group">
                                <select class="form-control" formControlName="isRecurring" name="isRecurring">
                                  <option value="false" [selected]=" (editAirworthinessDirectiveForm.get('isRecurring').value == 'false') || (!editAirworthinessDirectiveForm.get('isRecurring').value) ">
                                    NO
                                  </option>
                                  <option value="true" [selected]=" (editAirworthinessDirectiveForm.get('isRecurring').value == 'true') || editAirworthinessDirectiveForm.get('isRecurring').value ">
                                    YES
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="text-right mt-2">
                        <button class="btn btn-outline-danger mr-3" (click)="unLoadDirectiveForEdit()">Cancel</button>
                        <button type="submit" class="btn btn-outline-primary">Update Airworthiness Directive</button>
                      </div>
                    </form>

                  </div>
                </div>
              </td>
            </tr>
          </table>

          <div class="d-flex justify-content-between p-2">
            <ngb-pagination
              [collectionSize]="totalADs" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"  (pageChange)="onPaginationChange($event)">
            </ngb-pagination>

            <label>
              <select class="custom-select" style="width: auto" (change)="changePageSize($event)">
                <option [value]="20">20 items per page</option>
                <option [value]="50">50 items per page</option>
                <option [value]="100">100 items per page</option>
              </select>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



<!--PDF VIEWER--->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{viewedPdfAdTitle}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-pdf-viewer [pdfSrc]="viewedPdfSrc"></app-pdf-viewer>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>
