import { OptionLabelsAndValuesModel } from './option-labels-and-values-model';

export class AdSbConditionOptionsModel {
  constructor(){
    this.conditions = [
      { label: 'None', value: 'NA' },
      { label: 'Whichever occurs first', value: 'WhichOccursFirst' },
      { label: 'Whichever happens last', value: 'WhichHappensLast' }
    ]
  }
  conditions: Array<OptionLabelsAndValuesModel>;
}