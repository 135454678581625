<section id="login-form" class="container-fluid vh-100 pt-3">
    <div class="login_form centered-forms align-middle mt-3 ml-auto mr-auto">

      <div class="card shadow border-0 rounded">
        <div class="card-body">
          <div class="text-center my-2">
            <img class="mw-100" src="assets/images/crew-chief-full-color.jpg" alt="Crewchief Logo">
          </div>

          <ng-container *ngIf="actionSuccessful">
            <div class="text-center px-3 py-2">
              Your password has been updated. <br/>
              Your can now proceed to <a [routerLink]="['/login']">login</a> using your new password.
            </div>
          </ng-container>

          <ng-container *ngIf="!actionSuccessful">
            <div *ngIf="authMode == 'resetPassword'">
              <h6 class="text-center text-muted">Reset password</h6>

              <form [formGroup]="resetPasswordForm" (ngSubmit)="doResetPassword()">
                <div class="form-group">
                  <small class="text-muted d-block required">Enter New Password</small>
                  <input type="password" name="password" formControlName="password" class="form-control" id="password" aria-describedby="New Password" placeholder="Enter new password">
                  <small class="d-block text-danger" *ngIf="resetPasswordForm.controls['password'].invalid && (resetPasswordForm.controls['password'].touched || resetPasswordForm.controls['password'].dirty)">
                    Enter a valid password
                  </small>
                </div>

                <div class="form-group">
                  <small class="text-muted d-block required">Re-enter Password</small>
                  <input type="password" name="password2" formControlName="password2" class="form-control" id="password2" aria-describedby="Confirm Password" placeholder="Confirm password">
                  <small class="d-block text-danger" *ngIf="resetPasswordForm.controls['password2'].invalid && (resetPasswordForm.controls['password2'].touched || resetPasswordForm.controls['password2'].dirty)">
                    Passwords do not match
                  </small>
                </div>

                <div class="text-center">
                  <button type="submit" class="btn btn-primary w-100 rounded">Reset Password</button>
                </div>
              </form>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="text-center mt-3">
      Return to <a [routerLink]="['/login']">Login</a>
    </div>
  </section>
