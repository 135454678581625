import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

//your NgbDateFRParserFormater extends from NgbDateParserFormatter
//Is a Injectable that have two functions
@Injectable()
export class NgbDateCustomAndUSAParserFormatter extends NgbDateParserFormatter {
  parse(value: string, formatAsUSADate: boolean = false): NgbDateStruct { //parse receive your string dd/mm/yyyy
      //return a NgbDateStruct
      //calculate year,month and day from "value"
      if(!value){
        return null;
      }

      
      //Check if value is in format MM/DD/YYYY
      let regUSFormatDate: RegExp = /^((0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])[\/](19|20|21)?[0-9]{2})$/;
      if(!regUSFormatDate.test(value)){
        return null;
      }

      let mm: number, dd: number, yy: number;

      if(formatAsUSADate){
        let valArray: Array<string> = value.split('/');
  
        if(valArray.length < 3){
          return null;
        }
  
        mm = +valArray[0];
        dd = +valArray[1];
        yy = +valArray[2];
  
      }
      else{
        let dateObj = new Date(value);

        mm = dateObj.getMonth() + 1;
        dd = dateObj.getDate();
        yy = dateObj.getFullYear();
      }

      return {
        year:yy,
        month:mm,
        day:dd
      }
  }

  format(date: NgbDateStruct): string { //receive a NgbDateStruct
      //return a string
      return date? date.month+'/' + date.day+'/' + date.year : null;
  }
}