import { UserAircraftModel } from './user-aircraft-model';

export class MAircraftTranspondersModel{
  constructor(){
    this.id = null;
    this.aircraftId = null;
    this.lastTransponderDate = null;
    this.aircraft  = new UserAircraftModel;
  }
  id: number;
  aircraftId: number;
  lastTransponderDate: string;
  aircraft: UserAircraftModel;
}