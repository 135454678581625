import { startWith, tap } from 'rxjs/operators';
import { SubscriptionLike } from 'rxjs';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { UtilitiesService } from './../../services/utilities.service';
import { UserBaseModel } from './../../models/user-base-model';
import { AlertPopUpsService } from './../../services/alert-pop-ups.service';
import { UserService } from './../../services/user.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

interface filterSearchListI {
  concatText: string;
  index: number;
}

@Component({
  selector: 'app-all-members',
  templateUrl: './all-members.component.html',
  styleUrls: ['./all-members.component.scss']
})
export class AllMembersComponent implements OnInit, OnDestroy {

  constructor(
    private userService: UserService,
    private popUpService: AlertPopUpsService,
    private utilsService: UtilitiesService,
    private formBuilder: FormBuilder
  ) { }

  allUsers: Array<UserBaseModel> = [];
  isWorking: boolean = false;

  filterSearchForm: FormGroup;
  filterQuery: string;
  filterUserListSubscriber$: SubscriptionLike;

  filterResultsLoading: boolean = false;

  filterResult: Array<UserBaseModel> = [];
  filterSearchList: Array<filterSearchListI> = [];

  ngOnInit() {
    this.getAllUsers();

    this.filterSearchForm = this.formBuilder.group({
      'filterQuery': new FormControl(this.filterQuery)
    })


    this.filterUserListSubscriber$ = this.filterSearchForm.controls['filterQuery'].valueChanges.pipe(
      startWith(''),
      tap((x)=> this.filterResultsLoading = true)
    ).subscribe(
      (query: string)=>{
        this.filterQuery = query;
        if(!query){
          // this.getAllUsers();
        }
        else{
          this.filterUsersListWithQuery()
        }
      }
    );
  }

  getAllUsers(){
    this.isWorking = true;

    this.userService.getAllUsersV2().subscribe(
      (response: any)=>{
        this.isWorking = false;
        this.allUsers = response;

        this.filterSearchList = [];

        this.allUsers.forEach(
          (el, i) => {
            let c = el.emailAddress + ' '
            + el.firstName + ' '
            + el.lastName + ' '
            + el.mobilePhone + ' '
            + el.pilotLicenseNo;

            this.filterSearchList.push(
              {
                concatText: c.toLowerCase(),
                index: i
              }
            )
          }
        )
      },
      (error: any)=>{
        this.isWorking = false;
        this.popUpService.openBasicPopUp(
          "Sorry!",
          "Can not fetch Aircraft list.",
          "error"
        )
      }
    )
  }


  filterUsersListWithQuery(){
    this.filterResult = [];

    this.filterSearchList.forEach(
      el =>{
        if(el.concatText.includes(this.filterQuery.toLowerCase())){
          this.filterResult.push(this.allUsers[el.index]);
        }
      }
    )

  }


  formatPhoneNumber(phoneNumber: string){
    return phoneNumber ? this.utilsService.formatPhoneNumber(phoneNumber) : '--- --- ---- ';
  }


  ngOnDestroy(){
    if(this.filterUserListSubscriber$) this.filterUserListSubscriber$.unsubscribe();
  }
}
