import { AircraftService } from './../../../../services/aircraft.service';
import { UserMenuAircraftModel } from '../../../../models/user-menu-aircraft-model';
import { ActivatedRoute } from '@angular/router';
import { HasDependantControlValidator } from '../../../../_custom-validators/has-dependant-control-validator';
import { UtilitiesService } from './../../../../services/utilities.service';
import { FlightSquawkLogsModel } from './../../../../models/flight-squawk-logs-model';
import { AircraftFlightLogModel } from './../../../../models/aircraft-flight-log-model';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbDatepickerConfig
} from '@ng-bootstrap/ng-bootstrap';
import { FlightLogsService } from './../../../../services/flight-logs.service';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { Observable, SubscriptionLike } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { FlightSquawkType } from './../../../../models/flight-squawk-type-model';
import Swal from 'sweetalert2';
import { GreaterThanControlsValidator } from '../../../../_custom-validators/greater-than-controls-validator';
import { NumberToTenths } from '../../../../_custom-validators/number-to-tenths-validator';
import { DependsOnControlValidator } from '../../../../_custom-validators/depends-on-control-validator';
import { NgbDateCustomAndUSAParserFormatter } from './../../../../services/ngb-date-custom-and-usa-parser-formatter';
import { AircraftV2Model } from '../../../../models/airfcraftV2/aircraft-v2.model';
import { OrganizationService } from '../../../../services/organization.service';
import { UserOrganizationAndRole } from '../../../../models/user-organization-and-role';

// /**
//  * @description checks if time out is equal to or greater than previous time out
//  * @param timeOut
//  * @param previousTimeOut
//  */
// function timeOutValidator(previousTimeOut: string) {
//
//   // check if new time out value >= previous time out value
//   if (Number(timeOut) >= Number(previousTimeOut)) {
//     return null;
//   } else {
//     return {
//       timeOut: 'new time out is smaller than previous time out'
//     };
//   }
// }

@Component({
  selector: 'app-manage-flight-logs',
  templateUrl: './manage-flight-logs.component.html',
  styleUrls: ['./manage-flight-logs.component.scss'],
  providers: [
    NgbDatepickerConfig,
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomAndUSAParserFormatter}
  ]
})
export class ManageFlightLogsComponent implements OnInit, OnChanges, OnDestroy {
  @Input () tabChanges: Observable<void>;
  @Input() inViewOnly: boolean;
  @Input() inAdminView: boolean;
  @Input() aircraftId: number;

  @Output() emmitAircraftRefresh: EventEmitter<boolean> = new EventEmitter();


  constructor(
    private ngbDatePickerConfig: NgbDatepickerConfig,
    private route: ActivatedRoute,
    private flightLogService: FlightLogsService,
    private aircraftService: AircraftService,
    private orgService: OrganizationService,
    private formBuilder: FormBuilder,
    private utilitiesService: UtilitiesService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
  ) {
    ngbDatePickerConfig.minDate = {year: 1900, month: 1, day: 1};
   }

  selectedAircraft: AircraftV2Model = new AircraftV2Model;
  selectedAircraft$: SubscriptionLike;

  selectedOrg: UserOrganizationAndRole = new UserOrganizationAndRole;
  selectedOrg$: SubscriptionLike;

  tabChangesSubscription$: SubscriptionLike;

  isWorking: boolean;

  inNewFlightLogMode: boolean;
  newFlightLogForm: FormGroup;
  newFlightLog: AircraftFlightLogModel;
  newSquawksList: FormArray;

  flightLogSearchSubscription$: SubscriptionLike;
  flightLogSearchForm: FormGroup;
  flightLogSearchLoading: boolean;
  

  allAircraftFlightLogs: Array<AircraftFlightLogModel>;
  allAircraftFlightLogsCount: number;

  page: number;
  pageSize: number;

  squawkTypesList: Array<FlightSquawkType>;

  serverTodayDate: Date = new Date();
  serverTodayDateNgbDateStruct: NgbDateStruct;

  viewedFlightlogSquawk: AircraftFlightLogModel;

  loadedEditFlightLog: AircraftFlightLogModel;

  ngOnInit() {
    this.inNewFlightLogMode = true;
    this.isWorking = false;

    if (this.tabChanges) {
      this.tabChangesSubscription$ = this.tabChanges.subscribe(
        () => {
          this.inNewFlightLogMode = false;
        }
      );
    }

    this.flightLogService.getAllSquawkTypes().subscribe(
      (response: any) => {
        if (+response.status.code === 1000) {
          this.squawkTypesList = response.responseData;
        }
      }
    );


    this.selectedOrg$ = this.orgService.selectedOrg$.subscribe(
      (org: UserOrganizationAndRole) => {
        this.selectedOrg = org;
      }
    );


    this.selectedAircraft$ = this.aircraftService.selectedAircraft$.subscribe(
      (aircraft: AircraftV2Model) => {
        this.selectedAircraft = aircraft;

        if (!this.aircraftId) {
          this.aircraftId = this.selectedAircraft.id;
        }

        if (this.aircraftId !== null && this.aircraftId !== undefined) {
          this.initPageVariables();
        }
      }
    );
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.aircraftId && changes.aircraftId.currentValue) {
      this.aircraftId = changes.aircraftId.currentValue;
      this.initPageVariables();
    }
  }

  initPageVariables() {
    // init flig log search/filter form
    this.flightLogSearchForm = this.formBuilder.group({
      'queryflightDate': new FormControl('')
    });

    this.newFlightLog = new AircraftFlightLogModel;

    this.allAircraftFlightLogs = [];
    this.allAircraftFlightLogsCount = null;

    this.page = 1;
    this.pageSize = 10;

    this.squawkTypesList = [];

    this.serverTodayDate = new Date();

    this.viewedFlightlogSquawk = new AircraftFlightLogModel;

    this.loadedEditFlightLog = new AircraftFlightLogModel;
    

    // hide New Flight Log for admin
    if (this.inViewOnly) {
      this.inNewFlightLogMode = false;
    }

    this.route.queryParams.subscribe(params => {
      if (this.inViewOnly) {
        this.inNewFlightLogMode = false;
      }
    });


    // subscribe to typing on search/filter input
    this.flightLogSearchSubscription$ = this.flightLogSearchForm.controls['queryflightDate'].valueChanges.pipe(
      startWith(''),
      tap((x) => this.flightLogSearchLoading = true)
    ).subscribe(
      (queryflightDate) => {
        this.fetchAllAircraftFlightLogsTillFlightDate(queryflightDate);
        this.flightLogSearchLoading = false;
      }
    );

    this.utilitiesService.getServerDate().subscribe(
      (response: any) => {
        if (+response.status.code === 1000) {
          this.serverTodayDate = new Date(response.responseData); /// enforce server time

          // server time plus 3 minutes as max date in date picker
          this.serverTodayDateNgbDateStruct = this.ngbDateParserFormatter.parse(response.responseData);
        }
      }
    );
    // init new flight log form
    this.initNewFlightLogForm();
  }



  initNewFlightLogForm() {
    this.newFlightLogForm = this.formBuilder.group(
      {
        'newFlightDate': new FormControl(this.serverTodayDate, [Validators.required]),
        'timeOutHours': new FormControl(null),
        'timeInHours': new FormControl(null),
        'preflight_fuel_gallons': new FormControl(null, [NumberToTenths(true)]),
        'postflight_fuel_gallons': new FormControl(null, [NumberToTenths(true)] ),
        'preflight_oil_quarts': new FormControl(null, [NumberToTenths(true)]),
        'postflight_oil_quarts': new FormControl(null, [NumberToTenths(true)]),
        'squawks': this.formBuilder.array([this.createNewSquawkFormGroups()]),
        'notes': new FormControl(null),
      }
    );

    this.newFlightLogForm.get('timeOutHours').setValidators([
      Validators.required,
      NumberToTenths(false)
    ]);

    this.newFlightLogForm.get('timeInHours').setValidators([
      Validators.required,
      GreaterThanControlsValidator(false, [this.newFlightLogForm.get('timeOutHours')]),
      NumberToTenths(false)
    ]);

    if (this.allAircraftFlightLogsCount > 0) {
      this.newFlightLogForm.controls['timeOutHours'].setValue(this.allAircraftFlightLogs[0].timeInHours);
    }

    // this.newFlightLogForm.get('timeOutHours').setValidators([
    //   Validators.required,
    //   timeOutValidator(''),
    //   NumberToTenths(false)
    // ]);

    this.newSquawksList = this.newFlightLogForm.get('squawks') as FormArray;
  }

  toggleFlightLogForEdit(flightLog) {
    if (+this.loadedEditFlightLog.id === +flightLog.id) {
      this.loadedEditFlightLog = new AircraftFlightLogModel;
      return;
    }  else {
      this.loadedEditFlightLog = flightLog;
    }
  }

  toggleInNewFlightLogMode() {
    if (!this.inNewFlightLogMode) {
      this.initNewFlightLogForm();
      this.inNewFlightLogMode = true;
      return;
    } else {
      this.inNewFlightLogMode = false;
    }
  }

  createNewSquawkFormGroups(): FormGroup {
    const formGroupSquawk: FormGroup = this.formBuilder.group(
      {
        'squawkEntry': [null],
        'squawk_type': [null],
      }
    );

    formGroupSquawk.get('squawk_type').setValidators(
      [
        DependsOnControlValidator(formGroupSquawk.get('squawkEntry')),
        HasDependantControlValidator(formGroupSquawk.get('squawkEntry'))
      ]
    );

    formGroupSquawk.get('squawkEntry').setValidators(
      [
        DependsOnControlValidator(formGroupSquawk.get('squawk_type')),
        HasDependantControlValidator(formGroupSquawk.get('squawk_type'))
      ]
    );

    return formGroupSquawk;
  }

  get squawksListFormGroups() {
    this.newSquawksList = this.newFlightLogForm.get('squawks') as FormArray;

    /*
    this.newSquawksList.controls.forEach(
      (squakItem)=>{
      let squawkFormGroup = squakItem as FormGroup;
      squawkFormGroup.controls['squawkEntry'].setValidators([
        DependsOnControlValidator(squawkFormGroup.controls['squawk_type'])
      ]);
      squawkFormGroup.controls['squawk_type'].setValidators([
        DependsOnControlValidator(squawkFormGroup.controls['squawkEntry'])
      ]);
    })
    */

    return this.newSquawksList;
  }

  getOneSquawkFormGroup(index): FormGroup {
    this.newSquawksList = this.newFlightLogForm.get('squawks') as FormArray;
    const formGroup = this.newSquawksList.controls[index] as FormGroup;
    return formGroup;
  }

  addNewSquawkLogGroup() {
    this.newSquawksList.push(this.createNewSquawkFormGroups());
  }

  removeNewSquawkLogGroup(index: number) {
    this.newSquawksList.removeAt(index);
  }

  getNewSquawksFormGroup(index: number): FormGroup {
    this.newSquawksList = this.newFlightLogForm.get('squawks') as FormArray;
    const formGroup = this.newSquawksList.controls[index] as FormGroup;
    return formGroup;
  }

  fetchAllAircraftFlightLogsTillFlightDate(flightDate: any) {
    this.isWorking = true;
    const dateISO = flightDate ? this.utilitiesService.dateToISOString(flightDate) : '';
    this.flightLogService.fetchAllAircraftFlightLogsTillFlightDate(this.aircraftId, dateISO, this.page, this.pageSize).subscribe(
      (response: any) => {
        this.isWorking = false;
        if (response.status.code = 1000) {
          this.allAircraftFlightLogs = response.responseData.rows;
          this.allAircraftFlightLogsCount = response.responseData.count;

          if (this.allAircraftFlightLogsCount > 0 && +this.page === 1) {
            const presetTimeOut = this.allAircraftFlightLogs[0].timeInHours ? this.allAircraftFlightLogs[0].timeInHours : null;
            this.newFlightLogForm.controls['timeOutHours'].setValue(presetTimeOut);
          }
        }
      },
      (error: any) => {
        this.isWorking = false;
      }
    );
  }

  onPaginationPageChange($event) {
    this.page = $event;
    this.fetchAllAircraftFlightLogsTillFlightDate(this.flightLogSearchForm.controls['queryflightDate'].value);
  }

  addNewFlightLog() {
    if (!this.newFlightLogForm.valid) {
      Swal.fire(
        'New Log Update contains invalid fields!',
        'Check all fields and ensure they have been correctly filled',
        'error'
      );

      // for ( const control in this.newFlightLogForm.controls ) {
      //   if (this.newFlightLogForm.controls.hasOwnProperty(control)) {
      //     this.newFlightLogForm.controls[control].markAsTouched();
      //   }
      // }

      Object.values(this.newFlightLogForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );


      return;
    }

    this.newSquawksList = this.newFlightLogForm.get('squawks') as FormArray;
    if (!this.newSquawksList.valid) {
      Swal.fire(
        'Squawk entries contains invalid fields!',
        'Check all squawks to ensure corresponding types and descriptions have been entered.',
        'error'
      );

      // for (const control in this.newSquawksList.controls) {
      //   if (this.newSquawksList.controls.hasOwnProperty(control)) {
      //     this.newSquawksList.controls[control].markAsTouched();
      //   }
      // }

      Object.values(this.newSquawksList.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      return;
    }

    this.isWorking = true;
    window.scrollTo(0, 0);

    this.newFlightLog.aircraftId = this.aircraftId;
    this.newFlightLog.flightDate =  this.newFlightLogForm.controls['newFlightDate'].value;
    this.newFlightLog.timeInHours =  this.newFlightLogForm.controls['timeInHours'].value;
    this.newFlightLog.timeOutHours =  this.newFlightLogForm.controls['timeOutHours'].value;
    this.newFlightLog.flight_fuel_log.preflightGallons =  this.newFlightLogForm.controls['preflight_fuel_gallons'].value;
    this.newFlightLog.flight_fuel_log.postflightGallons =  this.newFlightLogForm.controls['postflight_fuel_gallons'].value;

    this.newFlightLog.flight_oil_log.preflightOilQuarts =  this.newFlightLogForm.controls['preflight_oil_quarts'].value;
    this.newFlightLog.flight_oil_log.postflightOilQuarts =  this.newFlightLogForm.controls['postflight_oil_quarts'].value;

    this.newFlightLog.notes =  this.newFlightLogForm.controls['notes'].value;

    this.newFlightLog.flight_squawk_logs = [];

    let i = 0;
    while (i < this.newSquawksList.length) {

      const g: FormGroup = this.getNewSquawksFormGroup(i);
      const squawkItem: FlightSquawkLogsModel = new FlightSquawkLogsModel;
      squawkItem.squawkEntry = g.controls['squawkEntry'].value;
      squawkItem.squawk_type_id = g.controls['squawk_type'].value;

      this.newFlightLog.flight_squawk_logs.push(squawkItem);

      i++;
    }

    this.flightLogService.addFlightLogtoAircraft(this.newFlightLog).subscribe(
      (response: any) => {
        this.isWorking = false;
        if (+response.status.code === 1000) {
          this.inNewFlightLogMode = false;
          this.newFlightLogForm.reset();
          this.newFlightLog = new AircraftFlightLogModel;
          this.fetchAllAircraftFlightLogsTillFlightDate('');
          Swal.fire(
            'Great!',
            'Added new Flight Log!.',
            'success'
          );
          this.emmitAircraftRefresh.emit();
        } else {
          Swal.fire(
            'Yikes!', 'New flight log not added not updated.', 'error'
          );
        }
      },
      (error: any) => {
        this.isWorking = false;
        if (error.status.message) {
          Swal.fire(
            error.status.message,
            error.responseData.join(' '),
            'error'
          );
        } else {
          Swal.fire(
            'Oops!',
            'Failed to add new flight log.',
            'error'
          );
        }
      }
    );

  }

  roundNumberToTenths(val: number) {
    return this.utilitiesService.roundNumberToTenths(val, 1);
  }

  toggleShowFlightLogSquawk(flightlog: AircraftFlightLogModel) {
    if (+flightlog.id === this.viewedFlightlogSquawk.id) {
      this.viewedFlightlogSquawk = new AircraftFlightLogModel;
      return;
    } else {
      this.viewedFlightlogSquawk = flightlog;
    }
  }

  savedEditedFlightLog(flightLog: AircraftFlightLogModel) {
    this.inNewFlightLogMode = false;
    this.loadedEditFlightLog = new AircraftFlightLogModel;
    this.fetchAllAircraftFlightLogsTillFlightDate('');
    Swal.fire(
      'Great!', 'Added new Flight Log!.', 'success'
    );
  }

  initDeleteFlightLog(flightLog: AircraftFlightLogModel) {
    const flightLogDate = new Date(flightLog.flightDate);
    const flightDateString = flightLogDate.getFullYear() + '-' + (flightLogDate.getMonth() + 1) + '-' + flightLogDate.getDate();
    Swal.fire({
      title: 'Are you sure?',
      text: `Please confirm you want to delete flightlog on ${flightDateString}.`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: `Yes. I know what I'm doing!`,
      cancelButtonText: 'No, take me back.'
    }).then((descision) => {
      if (descision.value) {


        this.flightLogService.deleteFlightLogOnAircraft(flightLog.id).subscribe(
          (response: any) => {
            if (+response.status.code === 1000) {
              // this.inEditDirectiveMode = false;
              this.fetchAllAircraftFlightLogsTillFlightDate('');
              Swal.fire(
                'Deleted!', `Flight Log ${flightDateString} deleted from logs.`, 'success'
              );
              this.emmitAircraftRefresh.emit();
            } else {
              Swal.fire(
                'Yikes!', `Flight Log ${flightDateString} not deleted.`, 'error'
              );
            }

          },
          (error: any) => {

            if (error.status.message) {
              Swal.fire(
                error.status.message,
                error.responseData.join(' '),
                'error'
              );
            } else {
              Swal.fire(
                'Oops!',
                'Failed to delete Airworthiness Directive.',
                'error'
              );
            }
          }
        );
      } else if (descision.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });

  }

  hasEditRole() {
    if (this.inAdminView) {
      return false;
    }

    if (
      (
        // if Pilot
        this.selectedAircraft.userRole &&
        this.selectedAircraft.userRole.toString().toUpperCase() === 'INDIVIDUAL_RW'
      ) ||
      // if ORG ADMIN
      (
        this.selectedOrg.role &&
        this.selectedOrg.role.toString().toUpperCase() === 'ADMIN'
      )
    ) {
      return true;
    }
  }

  ngOnDestroy () {
    if (this.tabChangesSubscription$) {
      this.tabChangesSubscription$.unsubscribe();
    }

    if (this.flightLogSearchSubscription$) {
      this.flightLogSearchSubscription$.unsubscribe();
    }

    if (this.selectedAircraft$) {
      this.selectedAircraft$.unsubscribe();
    }

    if (this.selectedOrg$) {
      this.selectedOrg$.unsubscribe();
    }
  }

}
