import { AicraftUserRoleTypes } from './aircraft-user-role-types';
import { UserBaseModel } from './user-base-model';

export class AircraftUserAndRoleModel {
  constructor(){
    this.role = null;
    this.user = new UserBaseModel;
  }
  role: AicraftUserRoleTypes;
  user: UserBaseModel;
}
