<section id="login-form" class="container-fluid vh-100 pt-3">
  <div class="login_form centered-forms align-middle mt-3 ml-auto mr-auto">

    <div class="card shadow border-0 rounded">
      <div class="card-body">
        <div class="text-center my-2">
          <img class="mw-100" src="assets/images/crew-chief-full-color.jpg" alt="Crewchief Logo">
        </div>

        <h4 class="text-center">Login</h4>

        <form [formGroup]="loginForm" (ngSubmit)="doUserLogin()">
          <div class="form-group">
            <small class="text-muted d-block">Email address</small>
            <input type="email" name="username" formControlName="username" class="form-control" id="username" aria-describedby="emailHelp" placeholder="Enter email">
          </div>
          <div class="form-group">
            <small class="text-muted d-block">Password</small>
            <input type="password" name="password" formControlName="password" class="form-control" id="password" placeholder="Password">
          </div>
          <div class="text-center">
            <button type="submit" class="btn btn-primary w-100 rounded">Log in</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="text-center mt-3">
    Forgot password?
    <a [routerLink]="['/forgotpassword']">Click here</a>
  </div>
</section>
