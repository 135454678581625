import { OrganizationBaseModel } from './organization-base-model';
import { OrganizationUserRoleTypes } from './organization-user-role-types';

export class UserOrganizationAndRole {
  constructor() {
    this.role = null;
    this.organization = new OrganizationBaseModel;
  }
  role?: OrganizationUserRoleTypes;
  organization: OrganizationBaseModel;
}
