<div id="page-wrapper" class="d-flex">
  <app-side-menu></app-side-menu>

  <!---Content-->
  <div id="page-content" class="flex-fill">
    <app-top-menu></app-top-menu>
    <!-- <div class="h6 bg-light mb-0">
      <p class="mb-0 px-3 py-2 text-mute border-bottom">
        Customer Aircraft Management Portal
      </p>
    </div> -->

    <section class="p-3">
      <router-outlet></router-outlet>
    </section>
  </div>
</div>
