import { AuthService } from './auth.service';
import { UtilitiesService } from './utilities.service';
import { UserBaseModel } from './../models/user-base-model';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '../models/user-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { CustomHttpErrorHandler } from './custom-http-error-handler';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private utilitiesService: UtilitiesService
  ) { }

  private currentUser: UserModel = new UserModel;
  private _currentUser = new BehaviorSubject(this.currentUser);

  public currentUser$: Observable<UserModel> = this._currentUser.asObservable();

  getCurrentUserInstance() {
    return this.currentUser;
  }

  setUserAsLoggedOut() {
    this.utilitiesService.updateShowMainPreloader(false);
    this.updateCurrentUser(new UserModel);
  }

  refetchCurrentUser() {
    if (this.currentUser.emailAddress) {
      this.utilitiesService.updateShowMainPreloader(true);

      this.getMe().subscribe(
        (response: any) => {
          this.updateCurrentUser(response);
          this.utilitiesService.updateShowMainPreloader(false);
        },
        (error: any) => {
          this.setUserAsLoggedOut();
        }
      );
    } else {
      this.setUserAsLoggedOut();
    }
  }

  updateCurrentUser(userObj: UserModel) {
    this.currentUser = {... userObj };
    this.currentUser.isLoggedIn = this.currentUser.emailAddress ? true : false;
    this._currentUser.next(this.currentUser);
  }

  /*
  checkUserInfo(userId: number){
    if(!this.currentUser.emailAddress){
      this.utilitiesService.updateShowMainPreloader(true);
      this.getUserById(userId).subscribe(
        (response:any)=>{
          if(response.status.code == 1000){
            this.updateCurrentUser(response.responseData, true);
            this.utilitiesService.updateShowMainPreloader(false);
          }
          else{
            this.utilitiesService.updateShowMainPreloader(false);

            console.log("LOGOUT WITHOUT CIRCULAR DEPENDENCY")
            //todo: loguser out without curcular dependecy between user service and auth service
            this.router.navigate(['/login']);
          }
        }
      )
    }
  }
  */


  getMe() {
    return this.httpClient.get(environment.apiBaseUrl + '/v2/me?enableAuth=true');
  }

  getUserById(userId: number) {
    return this.httpClient.get(environment.apiBaseUrl + '/users/getUserById?userId=' + userId);
  }

  getAllUsersV2() {
    return this.httpClient.get(environment.apiBaseUrl + `/v2/user`);
  }

  getUserV2(email: string) {
    return this.httpClient.get(environment.apiBaseUrl + `/v2/user/${email}`);
  }

  signUpNewUser(newUser: {userData: UserBaseModel, password: string}) {
    return this.httpClient.post(environment.apiBaseUrl + '/auth/signup', newUser);
  }

  updateUserInfo(customer: UserBaseModel) {
    return this.httpClient.put(environment.apiBaseUrl + '/users/updateUserInfo', customer);
  }

  createOrUpdateUser(customer: UserBaseModel) {
    return this.httpClient.put(environment.apiBaseUrl + `/v2/user/${customer.emailAddress}`, customer);
  }

}
