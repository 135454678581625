<app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

<ng-container *ngIf="!isWorking">
  <section class="mb-3 border-0">
    <div class="form-group">
      <form [formGroup]="filterSearchForm" (ngSubmit)="filterAircraftListWithQuery()">
        <div class="input-group">
          <input type="text" formControlName="filterQuery" class="form-control" id="filterQuery"
          placeholder="Enter Tail No. Model or Make">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="submit" id="search_by_name">
              <i class="la la-search"></i>
            </button>
          </div>
        </div>
        <small id="aircraft_help" class="form-text text-muted"> Aircraft Tail No, Model or Make.</small>
      </form>
    </div>
  </section>


  <div class="card border-0 bg-transparent">
    <div class="card-header  border-0 bg-transparent">
      <span *ngIf="filterQuery">Search Results for <b>{{filterQuery}}</b></span>
      <!-- <span *ngIf="!filterQuery">All Customers: <b>{{allUserCount}}</b></span> -->
    </div>
      <div class="card-body p-0">
        <div>
            <app-aircraft-table-list [aircraftList]="allAircraft" *ngIf="!filterQuery"></app-aircraft-table-list>
            <app-aircraft-table-list [aircraftList]="filterResult"  *ngIf="filterQuery"></app-aircraft-table-list>
        </div>

        <!-- <div class="d-flex justify-content-between p-2" *ngIf="!filterQuery">
          <ngb-pagination
            [collectionSize]="allUserCount" [(page)]="allPage" [pageSize]="allPageSize" (pageChange)="getAllCustomers()">
          </ngb-pagination>
        </div>

        <div class="d-flex justify-content-between p-2" *ngIf="filterQuery">
          <ngb-pagination
            [collectionSize]="filterResultsCount" [(page)]="filterPage" [pageSize]="filterPageSize" (pageChange)="filterSearchByNameEmailTailSerialOrSn()">
          </ngb-pagination>
        </div> -->
      </div>
  </div>
</ng-container>
