import { AircraftLogBookTypeOptionsModel } from './../../../models/aircraft-logbook-type-options-model';
import { AircraftLogBookTypeModel } from './../../../models/aircraft-logbook-type-model';
import { AircraftLogBookService } from './../../../services/aircraft-log-book.service';
import { AuthService } from './../../../services/auth.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
import { UserService } from './../../../services/user.service';
import { UserModel } from './../../../models/user-model';
import { SubscriptionLike } from 'rxjs';
import { UtilitiesService } from './../../../services/utilities.service';
import { AircraftV2Model } from './../../../models/airfcraftV2/aircraft-v2.model';
import { AircraftService } from './../../../services/aircraft.service';

@Component({
  selector: 'app-upload-aircraft-log-book',
  templateUrl: './upload-aircraft-log-book.component.html',
  styleUrls: ['./upload-aircraft-log-book.component.scss']
})
export class UploadAircraftLogBookComponent implements OnInit, OnDestroy {

  uploadLogBookForm: FormGroup;
  authenticateUploadForm: FormGroup;
  logBookTypeOptions: AircraftLogBookTypeOptionsModel = new AircraftLogBookTypeOptionsModel();

  isLoading: boolean;
  fbLoggedIn: boolean;
  isHovering: boolean;

  currentUser: UserModel;
  currentUser$: SubscriptionLike;

  selectedAircraft: AircraftV2Model;
  selectedAircraft$: SubscriptionLike;

  isUploading: boolean;

  files: File[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private logBookService: AircraftLogBookService,
    private authService: AuthService,
    private aircraftService: AircraftService,
    private userService: UserService,
    private utilsService: UtilitiesService,
    public fireAuth: AngularFireAuth
  ) { }

  ngOnInit() {
    // subscribe to current user
    this.currentUser$ = this.userService.currentUser$.subscribe(
      (user: UserModel) => {
        this.currentUser = user;
      }
    );

    // subscribe to selected aircraft
    this.selectedAircraft$ = this.aircraftService.selectedAircraft$.subscribe(
      (aircraft: AircraftV2Model) => {
        this.selectedAircraft = aircraft;

        if (this.selectedAircraft.id) {
          // this.initAircraft();
          this.isUploading = false;
          this.files = [];
        }
      }
    );

    this.authenticateUploadForm = this.formBuilder.group({
      pwd: new FormControl(null, [Validators.required])
    });

    this.uploadLogBookForm = this.formBuilder.group({
      type: new FormControl(null, [Validators.required]),
      pdfFile: ['']
    });

    this.isFbLoggedIn();
    // console.log('Current User', this.fireAuth.auth.currentUser);
  }

  resetUploadForm() {
    this.authenticateUploadForm.reset();
    this.isUploading = false;
    this.files = [];
  }

  onAuthSignIn() {
    const validateForm = this.utilsService.generalFormValidate(
      this.authenticateUploadForm
    );
    if (!validateForm) {
      return;
    }

    this.isLoading = true;
    this.fireAuth.auth.signInWithEmailAndPassword(
      this.currentUser.emailAddress, this.authenticateUploadForm.get('pwd').value
    ).then(
      (uu) => {
        // console.log(uu);
        this.isFbLoggedIn();
        this.isLoading = false;
      }
    ).catch (
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  /*
  doFbLogOut() {
    this.isLoading = true;
    this.fireAuth.auth.signOut().then(
      (uu) => {
        console.log(uu);
        this.isFbLoggedIn();
        this.isLoading = false;
      }
    ).catch (
      (error) => {
        console.log(error);
        this.isFbLoggedIn();
        this.isLoading = false;
      }
    );
  }
  */

  async isFbLoggedIn() {
    this.isLoading = true;
    // console.log('isFbLoggedIn curr user', this.fireAuth.auth.currentUser);

    const user = await this.fireAuth.authState.pipe(first()).toPromise();
    // console.log('user', JSON.stringify(user));

    this.isLoading = false;
    if (user) {
      this.fbLoggedIn = true;
      return true;
    } else {
      this.fbLoggedIn = false;
      return false;
    }
  }

  onSubmit() {
    const formValid = this.utilsService.generalFormValidate(this.uploadLogBookForm);

    if (!formValid || this.files.length < 1) {
      return;
    }

    this.isUploading = true;
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    this.files = [];
    /*for (let i = 0; i < files.length; i++) {
      this.files.push(files[i]);
    }*/
    this.files.push(files[0]);
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }


  ngOnDestroy() {
    if (this.currentUser$) {
      this.currentUser$.unsubscribe();
    }
  }

}
