<h4>Add New Aircraft</h4>

<h6 class="mb-0">
  <i class="la la-plane"></i>
  {{ userData?.firstName }} {{ userData?.lastName }} / {{ userData.pilotLicenseNo }}
</h6>
<small class="text-muted mt-1 mb-3">Customer Name / Pilot Certificate Number</small>

<section class="mt-3">
  <div class="card shadow border-0 admin-create-user">
    <div class="card-header clearfix">
      New Aircraft
      <span class="float-right" *ngIf="userData.id">
        <a [routerLink]="['/cga/admin/edit-customer', userData.id]" class="btn btn-outline-dark rounded btn-sm">Cancel</a>
      </span>
    </div>
    <div class="card-body">

      <app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

      <form *ngIf="!isWorking" [formGroup]="createAircraftForm" (ngSubmit)="doCreateAircraft()">
        <div formGroupName="aircraftData">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Tail No.</small>
                <input type="text" formControlName="tailNo" class="form-control" name="tailno" id="tailNo" placeholder="Tail Number">
                <small class="form-text text-danger" *ngIf="tailNo.invalid && (tailNo.dirty || tailNo.touched)">Minimum lenght of 3 and max of 6 .</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Airframe SN.</small>
                <input type="text" formControlName="airframeSn" class="form-control" id="airframsn" placeholder="Aircraft Serial #">
                <small class="form-text text-danger" *ngIf="createAircraftForm['controls'].aircraftData['controls'].airframeSn.invalid && (createAircraftForm['controls'].aircraftData['controls'].airframeSn.dirty || createAircraftForm['controls'].aircraftData['controls'].airframeSn.touched)">Enter Aircraft Serial Number</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Aircraft Make</small>
                <input type="text" formControlName="aircraftMake" class="form-control" id="aircraftmake" placeholder="Aircraft Make"
                  [class.is-invalid]="aircraftMakeSearchFailed" [ngbTypeahead]="aircraftMakeTypeHeadSearch"  [editable]="false"
                  [resultTemplate]="rt" [inputFormatter]="typeHeadMakeFormatter" (selectItem)="onTypeHeadSelected($event, 'aircraft')"
                required>

                <ng-template #rt let-r="result" let-t="term">
                  <ngb-highlight [result]="r.makeName" [term]="t"></ngb-highlight>
                </ng-template>
                <small class="form-text text-danger" *ngIf="createAircraftForm['controls'].aircraftData['controls'].aircraftMake.invalid && (createAircraftForm['controls'].aircraftData['controls'].aircraftMake.dirty || createAircraftForm['controls'].aircraftData['controls'].aircraftMake.touched)">Select Make from dropdown options</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Aircraft Model</small>
                <input type="text" [readonly]="aircraftMake.invalid" formControlName="aircraftModel" class="form-control" id="aircraftmodel" placeholder="Aircraft Model"
                  [class.is-invalid]="aircraftModelSearchFailed" [ngbTypeahead]="aircraftModelTypeHeadSearch"  [editable]="false"
                  [resultTemplate]="rt_aircraftModel" [inputFormatter]="typeHeadModelFormatter" (selectItem)="onTypeHeadModelSelected($event, 'aircraft')"
                required>

                <ng-template #rt_aircraftModel let-r="result" let-t="term">
                  <ngb-highlight [result]="r.modelName" [term]="t"></ngb-highlight>
                </ng-template>
                <small class="form-text text-danger" *ngIf="createAircraftForm['controls'].aircraftData['controls'].aircraftModel.invalid && (createAircraftForm['controls'].aircraftData['controls'].aircraftModel.dirty || createAircraftForm['controls'].aircraftData['controls'].aircraftModel.touched)">Select Model from dropdown options</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Enter Airframe TTIS at Setup.</small>
                <input type="text" formControlName="airframeTtis" class="form-control" id="airframttis" placeholder="Airframe TTIS at Setup">
                <small class="form-text text-danger" *ngIf="airframeTtis.invalid && (airframeTtis.dirty || airframeTtis.touched)">TTIS in hours (digits).</small>
              </div>
            </div>

          </div>

          <hr/>
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted">Check to Enter Engine Data.</small>
                <div class="form-check form-check-inline mt-2">
                  <input class="form-check-input" type="checkbox" id="addEngineData"  formControlName="addEngineData">
                  <label class="form-check-label h6" for="addEngineData">Engine Data</label>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted">Check to Enter Propeller Data.</small>
                <div class="form-check form-check-inline mt-2">
                  <input class="form-check-input" type="checkbox" id="addPropellerData"  formControlName="addPropellerData">
                  <label class="form-check-label h6" for="addPropellerData">Propeller Data</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div formGroupName="engineData" *ngIf="createAircraftForm['controls'].aircraftData['controls'].addEngineData.value">
          <hr />
          <!--ENGINE INFO-->
          <div class="row mt-2">
            <div class="col-sm-12 col-md-12">
              <h6>Engine Info</h6>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Engine Make</small>
                <input type="text" formControlName="engineMake" class="form-control" id="enginemake" placeholder="Engine Make"
                  [class.is-invalid]="engineMakeSearchFailed" [ngbTypeahead]="engineMakeTypeHeadSearch"  [editable]="false"
                  [resultTemplate]="rt_engine" [inputFormatter]="typeHeadMakeFormatter" (selectItem)="onTypeHeadSelected($event, 'engine')"
                required>

                <ng-template #rt_engine let-r="result" let-t="term">
                  <ngb-highlight [result]="r.makeName" [term]="t"></ngb-highlight>
                </ng-template>
                <small class="form-text text-danger" *ngIf="createAircraftForm['controls'].engineData['controls'].engineMake.invalid && (createAircraftForm['controls'].engineData['controls'].engineMake.dirty || createAircraftForm['controls'].engineData['controls'].engineMake.touched)">Select Engine Make from dropdown options</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Engine Model</small>
                <input type="text" [readonly]="engineMake.invalid" formControlName="engineModel" class="form-control" id="enginemodel" placeholder="Engine Model"
                  [class.is-invalid]="engineModelSearchFailed" [ngbTypeahead]="engineModelTypeHeadSearch"  [editable]="false"
                  [resultTemplate]="rt_engineModel" [inputFormatter]="typeHeadModelFormatter" (selectItem)="onTypeHeadModelSelected($event, 'engine')"
                required>

                <ng-template #rt_engineModel let-r="result" let-t="term">
                  <ngb-highlight [result]="r.modelName" [term]="t"></ngb-highlight>
                </ng-template>
                <small class="form-text text-danger" *ngIf="createAircraftForm['controls'].engineData['controls'].engineModel.invalid && (createAircraftForm['controls'].engineData['controls'].engineModel.dirty || createAircraftForm['controls'].engineData['controls'].engineModel.touched)">Select Engine Model from dropdown options</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Engine SN.</small>
                <input type="text" formControlName="engineSn" class="form-control" id="engine_sn" placeholder="Engine S/N">
                <small class="form-text text-danger" *ngIf="createAircraftForm['controls'].engineData['controls'].engineSn.invalid && (createAircraftForm['controls'].engineData['controls'].engineSn.dirty || createAircraftForm['controls'].engineData['controls'].engineSn.touched)">Enter Engine Serial Number</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Enter Engine TT at Install(in engine hours)</small>
                <input type="text" formControlName="initialEngineHours" class="form-control" id="engine_hrs" placeholder="Engine TT at Install">
                <small class="form-text text-danger" *ngIf="initialEngineHours.invalid && (initialEngineHours.dirty || initialEngineHours.touched)">Engine hours at install in tenths e.g. 102.5</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Engine Install Time(in airframe hours)</small>
                <input type="text" formControlName="engineInstalledAirframeTime" class="form-control" id="engineInstalledAirframeTime" placeholder="Engine Install Time">
                <small class="form-text text-danger" *ngIf="createAircraftForm['controls'].engineData['controls'].engineInstalledAirframeTime.invalid && (createAircraftForm['controls'].engineData['controls'].engineInstalledAirframeTime.dirty || createAircraftForm['controls'].engineData['controls'].engineInstalledAirframeTime.touched)">
                  Enter airframe hours at engine install in tenths e.g. 102.5
                </small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Last Engine OH(in engine hours)</small>
                <input type="text" formControlName="initialLastEngineOh" class="form-control" id="initialLastEngineOh" placeholder="Last Engine OH">
                <small class="form-text text-danger" *ngIf="createAircraftForm['controls'].engineData['controls'].initialLastEngineOh.invalid && (createAircraftForm['controls'].engineData['controls'].initialLastEngineOh.dirty || createAircraftForm['controls'].engineData['controls'].initialLastEngineOh.touched)">Enter engine OH hours in tenths e.g. 102.5</small>
              </div>
            </div>
          </div>
        </div>

        <div formGroupName="propellerData" *ngIf="createAircraftForm['controls'].aircraftData['controls'].addPropellerData.value">
          <hr />
          <!--PROPELLER INFO-->
          <div class="row mt-2">
            <div class="sm-12 col-md-12">
              <h6>Propeller Info</h6>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Propeller Make</small>
                <input type="text" formControlName="propellerMake" class="form-control" id="propellermake" placeholder="Propeller Make"
                  [class.is-invalid]="propellerMakeSearchFailed" [ngbTypeahead]="propellerMakeTypeHeadSearch"  [editable]="false"
                  [resultTemplate]="rt_propeller" [inputFormatter]="typeHeadMakeFormatter" (selectItem)="onTypeHeadSelected($event, 'propeller')"
                required>

                <ng-template #rt_propeller let-r="result" let-t="term">
                  <ngb-highlight [result]="r.makeName" [term]="t"></ngb-highlight>
                </ng-template>
                <small class="form-text text-danger" *ngIf="createAircraftForm['controls'].propellerData['controls'].propellerMake.invalid && (createAircraftForm['controls'].propellerData['controls'].propellerMake.dirty || createAircraftForm['controls'].propellerData['controls'].propellerMake.touched)">Enter Propeller Make</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Propeller Model</small>
                <input type="text" [readonly]="propellerMake.invalid" formControlName="propellerModel" class="form-control" id="propellermodel" placeholder="Propeller Model"
                  [class.is-invalid]="propellerModelSearchFailed" [ngbTypeahead]="propellerModelTypeHeadSearch"  [editable]="false"
                  [resultTemplate]="rt_propellerModel" [inputFormatter]="typeHeadModelFormatter" (selectItem)="onTypeHeadModelSelected($event, 'propeller')"
                required>

                <ng-template #rt_propellerModel let-r="result" let-t="term">
                  <ngb-highlight [result]="r.modelName" [term]="t"></ngb-highlight>
                </ng-template>
                <small class="form-text text-danger" *ngIf="createAircraftForm['controls'].propellerData['controls'].propellerModel.invalid && (createAircraftForm['controls'].propellerData['controls'].propellerModel.dirty || createAircraftForm['controls'].propellerData['controls'].propellerModel.touched)">Enter Propeller Model</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Propeller SN.</small>
                <input type="text" formControlName="propellerSn" class="form-control" id="propellersn_oh" placeholder="Propeller S/N">
                <small class="form-text text-danger" *ngIf="createAircraftForm['controls'].propellerData['controls'].propellerSn.invalid && (createAircraftForm['controls'].propellerData['controls'].propellerSn.dirty || createAircraftForm['controls'].propellerData['controls'].propellerSn.touched)">Enter Propeller Serial Number</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Enter Propeller TT at Install(in propeller hours)</small>
                <input type="text" formControlName="inititialPropellerHours" class="form-control" id="inititialPropellerHours" placeholder="Propeller TT at Install">
                <small class="form-text text-danger" *ngIf="createAircraftForm['controls'].propellerData['controls'].inititialPropellerHours.invalid && (createAircraftForm['controls'].propellerData['controls'].inititialPropellerHours.dirty || createAircraftForm['controls'].propellerData['controls'].inititialPropellerHours.touched)">Enter propeller hours at install in tenths e.g. 102.5</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Propeller Install Time(in airframe hours)</small>
                <input type="text" formControlName="propellerInstalledAirframeTime" class="form-control" id="propellerInstalledAirframeTime" placeholder="Propeller Install Time">
                <small class="form-text text-danger" *ngIf="createAircraftForm['controls'].propellerData['controls'].propellerInstalledAirframeTime.invalid && (createAircraftForm['controls'].propellerData['controls'].propellerInstalledAirframeTime.dirty || createAircraftForm['controls'].propellerData['controls'].propellerInstalledAirframeTime.touched)">Enter airframe hours at propeller Install in tenths e.g. 102.5</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Last Propeller OH(in propeller hours)</small>
                <input type="text" formControlName="initLastPropellerOh" class="form-control" id="propeller_oh" placeholder="Last Propeller OH">
                <small class="form-text text-danger" *ngIf="createAircraftForm['controls'].propellerData['controls'].initLastPropellerOh.invalid && (createAircraftForm['controls'].propellerData['controls'].initLastPropellerOh.dirty || createAircraftForm['controls'].propellerData['controls'].initLastPropellerOh.touched)">Enter propeller OH hours in tenths e.g. 102.5</small>
              </div>
            </div>
          </div>
        </div>


        <div formGroupName="equipmentData">
          <hr />
          <!--OTHER AIRCRAFT INFO-->
          <div class="row mt-2">
            <div class="col-sm-12 col-md-12">
              <h6>Other Info</h6>
            </div>
            <div class="col-sm-12 col-md-12">
              <div class="form-group">
                <small id="avionics_eq_oh" class="form-text text-muted">Avionics Equipment</small>
                <textarea name="avionics_eq" class="form-control" formControlName="avionicsEquipments" id="avionics_eq" placeholder="Avionics Equipment" rows="4"></textarea>
              </div>
            </div>

            <div class="col-sm-12 col-md-12">
              <div class="form-group">
                <small id="other_eq_oh" class="form-text text-muted">Other Equipment</small>
                <textarea name="other_eq" class="form-control" formControlName="otherEquipments" id="other_eq" placeholder="Other Equipment" rows="4"></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="text-right mt-2" *ngIf="userData.id">
          <a class="btn btn-outline-dark mr-3" [routerLink]="['/cga/admin/edit-customer', userData.id]">Cancel</a>
          <button type="submit" class="btn btn-outline-dark">Create Aircraft</button>
        </div>
      </form>
    </div>
  </div>
</section>
