import { FlightSquawkType } from './flight-squawk-type-model';

export class FlightSquawkLogsModel{
  constructor(){
    this.id = null;
    this.squawkEntry = null;
    this.squawk_type_id = new FlightSquawkType;
  }
  id: number;  
  squawkEntry: string;
  squawk_type_id: FlightSquawkType ;
}