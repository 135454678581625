<ng-container *ngIf="!viewStyle || (viewStyle.toLocaleLowerCase() !== 'list')">

  <div class="clearfix my-3">

    <!-- <h4 class="pull-left ellipsis">
      {{headerTitle}}
    </h4> -->

    <div class="float-right">
      <ul class="aircraft-thumb-list">
        <!--TAIL NUMBER-->
        <li class="clearfix"  *ngIf="theAircraft && theAircraft.tailNo">
          <span class="iconthumb pull-left">
            <img src="assets/images/visual_assets/main_data_blocks/Tail Number Data Block Image-01.png" alt="Tail No">
          </span>
          <span class="status-desc pull-left ml-2">
            <small class="d-block text-paleblue">
              Tail Number
            </small>
            <span class="h5 text-darkblue">
              {{ theAircraft?.tailNo }}
            </span>
          </span>
        </li>

          <!--MAKE-->
        <li class="clearfix" *ngIf="theAircraft.airframe && theAircraft.airframe.make">
          <span class="iconthumb pull-left">
            <img src="assets/images/visual_assets/main_data_blocks/Make Data Block Image-01.png" alt="Make">
          </span>
          <span class="status-desc pull-left ml-2">
            <small class="d-block text-paleblue">
              Make
            </small>
            <span class="h5 text-darkblue">
              {{ theAircraft?.airframe?.make?.name }}
            </span>
          </span>
        </li>

        <!--MODEL-->
        <li class="clearfix" *ngIf="theAircraft.airframe && theAircraft.airframe.model">
          <span class="iconthumb pull-left">
            <img src="assets/images/visual_assets/main_data_blocks/Model Data Block Image-01.png" alt="Model">
          </span>
          <span class="status-desc pull-left ml-2">
            <small class="d-block text-paleblue">
              Model
            </small>
            <span class="h5 text-darkblue">
              {{ theAircraft?.airframe?.model?.name }}
            </span>
          </span>
        </li>
      </ul>

    </div>

  </div>

  <ul class="aircraft-status-list list-group list-group-horizontal w-100 justify-content-around text-center">
    <li class="list-group-item text-center flex-fill">
      <small class="d-block text-paleblue">
        Airframe TTIS
      </small>
      <h6 class="text-darkblue">
        <span *ngIf="theAircraft.airframe">
          {{ theAircraft.airframe? getValueIfNullOrUndefined(theAircraft.airframe.ttis) : '---' }}
        </span>

        <span *ngIf="!theAircraft.airframe">
          ------
        </span>
      </h6>
    </li>

    <li class="list-group-item text-center flex-fill">
      <small class="d-block text-paleblue">
        Engine TT
      </small>
      <h6 class="text-darkblue">
          <span *ngIf="theAircraft.engines">
            {{ theAircraft.engines[0]? getValueIfNullOrUndefined(theAircraft.engines[0].ttis) : '---' }}
          </span>
  
          <span *ngIf="!theAircraft.engines">
            ------
          </span>
      </h6>
    </li>

    <li class="list-group-item text-center flex-fill">
      <small class="d-block text-paleblue">
        Engine SMOH
      </small>
      <h6 class="text-darkblue">
        <span *ngIf="theAircraft.engines">
          {{ theAircraft.engines[0]? getValueIfNullOrUndefined(theAircraft.engines[0].smoh) : '---' }}
        </span>

        <span *ngIf="!theAircraft.engines">
          ------
        </span>
      </h6>
    </li>

    <li class="list-group-item text-center flex-fill">
      <small class="d-block text-paleblue">
        Propeller TT
      </small>
      <h6 class="text-darkblue">
        <span *ngIf="theAircraft.propellers">
          {{ theAircraft.propellers[0]? getValueIfNullOrUndefined(theAircraft.propellers[0].ttis) : '---' }}
        </span>

        <span *ngIf="!theAircraft.propellers">
          ------
        </span>
      </h6>
    </li>

    <li class="list-group-item text-center flex-fill">
      <small class="d-block text-paleblue">
        Propeller SPOH
      </small>
      <h6 class="text-darkblue">
        <span *ngIf="theAircraft.propellers">
          {{ theAircraft.propellers[0]? getValueIfNullOrUndefined(theAircraft.propellers[0].smoh) : '---' }}
        </span>

        <span *ngIf="!theAircraft.propellers">
          ------
        </span>
      </h6>
    </li>
  </ul>

</ng-container>
