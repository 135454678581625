import { UserService } from './../services/user.service';
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.tokenCheck()) {
        //loggedin
        let user = this.userService.getCurrentUserInstance();

        if(!user.emailAddress){
          console.log("going to check info")
          this.authService.checkAuthUserInfo();
        }
        return true;
    }

    //else "not logged in, redirect to login"
    // navigate to login page
    this.router.navigate(['/login']);
    // todo: save redirect url so after authing we can move them back to the page they requested
    return false;
  }
}
