export class AircraftDashboardReferencesModel{
  constructor(){
    this.safetyRefLookAheadDays = null;
    this.safetyRefLookAheadHours = null;

    this.safetyRefWarningDaysAhead = null;
    this.safetyRefWarningHoursAhead = null;
    
    this.safetyHoursToMonthConvertRate = null;

    this.maintRefAnnualDueYear = null;
    this.maintRefAnnualDueDays = null;
    this.maintRefAnnualWarningDays = null;

    this.maintRefOilChangeDueHours = null;
    this.maintRefOilChangeWarningHours = null;

    this.maintRefEltDueYear = null;
    this.maintRefEltDueDays = null;
    this.maintRefEltWarningDays = null;
    
    this.ifrRefVORCheckDueDays = null;
    this.ifrRefVORCheckWarningDays = null;

    this.ifrRefNavDataUpdateDueDays = null;
    this.ifrRefNavDataUpdateWarningDays = null;
    
    this.ifrRefPitotStaticDueMonths = null,
    this.ifrRefPitotStaticWarningDays = null,

    this.ifrRefTransponderDueMonths = null,
    this.ifrRefTransponderWarningDays = null,

    this.ifrRefAltimeterDueMonths = null
    this.ifrRefAltimeterWarningDays = null;

    this.pilotStatusMedicalWarningDays = null;
  
    this.pilotStatusFlightReviewWarningDays = null;
  
    this.adminRegistrationWarningDays = null;
  
    this.adminInsuranceWarningDays = null;

    this.serverNow = null
  }
  
  safetyRefLookAheadDays: number;
  safetyRefLookAheadHours: number;

  safetyRefWarningDaysAhead: number;
  safetyRefWarningHoursAhead: number;

  safetyHoursToMonthConvertRate: number;

  maintRefAnnualDueYear: number;
  maintRefAnnualDueDays: number;
  maintRefAnnualWarningDays: number;

  maintRef100DueHours: number;
  maintRef100WarningHours: number;

  maintRefOilChangeDueHours: number;
  maintRefOilChangeWarningHours: number;

  maintRefEltDueYear: number;
  maintRefEltDueDays: number;
  maintRefEltWarningDays: number;

  ifrRefVORCheckDueDays: number;
  ifrRefVORCheckWarningDays: number;

  ifrRefNavDataUpdateDueDays: number;
  ifrRefNavDataUpdateWarningDays: number;

  ifrRefPitotStaticDueMonths: number;
  ifrRefPitotStaticWarningDays: number;

  ifrRefTransponderDueMonths: number;
  ifrRefTransponderWarningDays: number;

  ifrRefAltimeterDueMonths: number;
  ifrRefAltimeterWarningDays: number;

  pilotStatusMedicalWarningDays: number;

  pilotStatusFlightReviewWarningDays: number;

  adminRegistrationWarningDays: number;

  adminInsuranceWarningDays: number;

  serverNow: string
}