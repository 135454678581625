import { UserAircraftModel } from './../../../models/user-aircraft-model';
import { SubscriptionLike } from 'rxjs';
import { AircraftService } from './../../../services/aircraft.service';
import { Component, OnInit } from '@angular/core';
import { UserMenuAircraftModel } from '../../../models/user-menu-aircraft-model';

@Component({
  selector: 'app-quick-view-logs',
  templateUrl: './quick-view-logs.component.html',
  styleUrls: ['./quick-view-logs.component.scss']
})
export class QuickViewLogsComponent implements OnInit {


  isLoading: boolean = false;
  constructor(
  ) { }


  ngOnInit() {
  }

}
