import { FlightLogsService } from './../../../../services/flight-logs.service';
import { AircraftFlightLogModel } from './../../../../models/aircraft-flight-log-model';
import { MAircraftEltsModel } from './../../../../models/m-aircraft-elts-model';
import { MAircraftOilChangesModel } from './../../../../models/m-aircraft-oil-changes-model';
import { MAircraft100hoursModel } from './../../../../models/m-aircraft-100hours-model';
import { UserAircraftModel } from './../../../../models/user-aircraft-model';
import { AircraftMaintenanceService } from './../../../../services/aircraft-maintenance.service';
import { AircraftDashboardReferencesModel } from './../../../../models/aircraft-dashboard-references-model';
import { UtilitiesService } from './../../../../services/utilities.service';
import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { MAircraftAnnualsModel } from './../../../../models/m-aircraft-annuals-model';
import { RagType } from './../../../../models/rag-type';
import * as moment from 'moment';
import { AircraftV2Model } from '../../../../models/airfcraftV2/aircraft-v2.model';

@Component({
  selector: 'app-dashboard-maintenance-status',
  templateUrl: './dashboard-maintenance-status.component.html',
  styleUrls: ['./dashboard-maintenance-status.component.scss']
})
export class DashboardMaintenanceStatusComponent implements OnInit, OnChanges {
  @Input() aircraftId: number;
  @Input() theAircraft: AircraftV2Model;
  @Input() isAdminView: boolean;
  @Input() statusOnly: boolean;

  @Output() isCompleted: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    private utilitiesService: UtilitiesService,
    private flightLogsService: FlightLogsService,
    private maintenanceService: AircraftMaintenanceService,
  ) { }

  isLoading: boolean;
  dashReferences: AircraftDashboardReferencesModel;

  todayFlightDateMoment: moment.Moment = moment(new Date());
  serverEpochTime: number = new Date().getTime();

  // lastFlightLogEntry: AircraftFlightLogModel = new AircraftFlightLogModel;
  todayFlightHours: number = null;

  lastAircraftAnnualsDate: string;
  annualsNextDueDateMoment: moment.Moment = null;
  annualRagStatus: RagType = null;

  lastAircraft100Hrs: number;
  _100HoursNextDueHrs: number = null;
  _100HoursRagStatus: RagType = null;

  lastAircraftOilChangesHrs: number;
  oilChangeNextDueHrs: number = null;
  OilChangeRagStatus: RagType = null;

  lastAircraftEltsDate: string;
  eltNextDueDateMoment: moment.Moment = null;
  eltRagStatus: RagType = null;

  finalMaintenanceStatus: string;

  lastAnnualsDone: boolean;
  last100hoursDone: boolean;
  lastOilChangeDone: boolean;
  lastEltsDone: boolean;

  ngOnInit() {
    this.getReferenceData();
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.theAircraft && changes.theAircraft.currentValue) {
      const newAircraft = changes.theAircraft.currentValue;

      if (newAircraft.id && newAircraft.id !== this.theAircraft.id) {
        this.theAircraft = newAircraft;
        this.getReferenceData();
      }
    }
  }


  getReferenceData() {
    this.lastAnnualsDone = false;
    this.last100hoursDone = false;
    this.lastOilChangeDone = false;
    this.lastEltsDone = false;

    this.getMaintenanceBadgeColor();

    this.isLoading = true;

    if (this.theAircraft.id) {
      this.todayFlightHours = this.theAircraft.airframe.ttis;

      this.utilitiesService.getAircraftDashboardReferences().subscribe(
        (response: any) => {

          this.isLoading = false;

          if (+response.status.code === 1000) {
            this.dashReferences = response.responseData;
            this.todayFlightDateMoment = moment(this.dashReferences.serverNow);
            this.serverEpochTime = this.utilitiesService.parseISOtoDateObj(this.dashReferences.serverNow).getTime();

            this.getAllMaintenanceStats();
          }
        }
      );
    }
  }


  getAllMaintenanceStats() {
    this.getLastMAircraftAnnuals();
    this.getLastMAircraft100hours();
    this.getLastMAircraftOilChanges();
    this.getLastMAircraftElts();
  }


  emitCompleteStatus() {
    this.isCompleted.emit(
      this.lastAnnualsDone &&
      this.last100hoursDone && 
      this.lastOilChangeDone &&
      this.lastEltsDone
    );
  }


  getLastMAircraftAnnuals() {

    this.lastAircraftAnnualsDate = this.theAircraft.maintenanceStatus.annuals.date;

    if (!this.lastAircraftAnnualsDate) {
      this.lastAnnualsDone = true;

      this.getMaintenanceBadgeColor();
      return;
    }

    const nextDueDateManipulator = moment(this.lastAircraftAnnualsDate);

    // Get Due date
    nextDueDateManipulator.add(this.dashReferences.maintRefAnnualDueYear, 'year');
    //
    nextDueDateManipulator.endOf('month');

    this.annualsNextDueDateMoment = nextDueDateManipulator.clone();

    // Warning
    const nextWarningManipulator = nextDueDateManipulator.clone();
    nextWarningManipulator.subtract(this.dashReferences.maintRefAnnualWarningDays, 'days');

    const dueDiff = this.todayFlightDateMoment.diff(nextDueDateManipulator, 'seconds');
    const warningDiff = this.todayFlightDateMoment.diff(nextWarningManipulator, 'seconds');

    if ( dueDiff >= 0) {
      this.annualRagStatus = RagType.a_red;
    } else if (warningDiff >= 0) {
      this.annualRagStatus = RagType.b_amber;
    } else {
      this.annualRagStatus = RagType.c_green;
    }

    this.lastAnnualsDone = true;

    this.getMaintenanceBadgeColor();

  }


  getLastMAircraft100hours() {
    this.lastAircraft100Hrs = this.theAircraft.maintenanceStatus.hundredHours.time === null ?
      null : +this.theAircraft.maintenanceStatus.hundredHours.time;

    if (this.lastAircraft100Hrs === null) {
      this.last100hoursDone = true;

      this.getMaintenanceBadgeColor();
      return;
    }
  
    this._100HoursNextDueHrs = this.lastAircraft100Hrs + this.dashReferences.maintRef100DueHours;
    const nextWarningToHours = this.lastAircraft100Hrs + this.dashReferences.maintRef100WarningHours;

    if (this.todayFlightHours >= this._100HoursNextDueHrs) {
      this._100HoursRagStatus = RagType.a_red;
    } else if (this.todayFlightHours >= nextWarningToHours) {
      this._100HoursRagStatus = RagType.b_amber;
    } else {
      this._100HoursRagStatus = RagType.c_green;
    }
    this.last100hoursDone = true;

    this.getMaintenanceBadgeColor();
  }


  getLastMAircraftOilChanges() {
    this.lastAircraftOilChangesHrs = this.theAircraft.maintenanceStatus.oilChange.time === null ?
      null : this.theAircraft.maintenanceStatus.oilChange.time;

    if (this.lastAircraftOilChangesHrs === null) {
      this.lastOilChangeDone = true;
      return;
    }

    this.oilChangeNextDueHrs = this.lastAircraftOilChangesHrs + this.dashReferences.maintRefOilChangeDueHours;
    const nextWarningOilChangeHours = this.lastAircraftOilChangesHrs +
        this.dashReferences.maintRefOilChangeWarningHours;

    if ( this.todayFlightHours >= this.oilChangeNextDueHrs) {
      this.OilChangeRagStatus = RagType.a_red;
    } else if (this.todayFlightHours >= nextWarningOilChangeHours) {
      this.OilChangeRagStatus = RagType.b_amber;
    } else {
      this.OilChangeRagStatus = RagType.c_green;
    }

    this.lastOilChangeDone = true;
  }


  getLastMAircraftElts() {
    this.lastAircraftEltsDate = this.theAircraft.maintenanceStatus.elts.date;

    if (!this.lastAircraftEltsDate) {
      this.lastEltsDone = true;

      this.getMaintenanceBadgeColor();
      return;
    }

    const nextDueDateManipulator = moment(this.lastAircraftEltsDate);

    // Get Due date
    nextDueDateManipulator.add(this.dashReferences.maintRefEltDueYear, 'year');
    //
    nextDueDateManipulator.endOf('month');

    this.eltNextDueDateMoment = nextDueDateManipulator.clone();

    // Warning
    const nextWarningManipulator = nextDueDateManipulator.clone();
    nextWarningManipulator.subtract(this.dashReferences.maintRefEltWarningDays, 'days');

    const dueDiff = this.todayFlightDateMoment.diff(nextDueDateManipulator, 'seconds');
    const warningDiff = this.todayFlightDateMoment.diff(nextWarningManipulator, 'seconds');

    if (dueDiff >= 0) {
      this.eltRagStatus = RagType.a_red;
    } else if (warningDiff >= 0) {
      this.eltRagStatus = RagType.b_amber;
    } else {
      this.eltRagStatus = RagType.c_green;
    }
    this.lastEltsDone = true;

    this.getMaintenanceBadgeColor();
  }


  getRagColor(val: number, forRow: boolean = false) {
    if (val === null) {
      return ' ';
      // return RagType[RagType.a_red];
    }

    return RagType[val];
  }


  getMaintenanceBadgeColor() {
    const statusArray = [
      this.annualRagStatus,
      this.eltRagStatus,
      this.OilChangeRagStatus,
      this._100HoursRagStatus
    ];

    statusArray.sort();

    this.finalMaintenanceStatus = this.getRagColor(statusArray[0]);

    this.emitCompleteStatus();
  }


  formatMomentAsUsDateString(momentObject: moment.Moment) {
    return this.utilitiesService.formatMomentAsUsDateString(momentObject);
  }


  getMomentBetweenDates(lowerTimeMoment: moment.Moment, higherTimeString: moment.Moment) {
    return this.utilitiesService.getMomentBetweenDates(lowerTimeMoment, higherTimeString);
  }


  getHoursRemainingBetweenHours(lowerHours: number, higherHours: number) {
    return this.utilitiesService.getHoursRemainingBetweenHours(lowerHours, higherHours);
  }

}
