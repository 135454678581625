<div ngbDropdown class="d-block mb-2 mt-1" *ngIf="userAircraftList.length > 0">
  <a
    class="nav-link toggle-amber toggle-absolute orange_titles py-1"
    href="javascript: void(0);"
    id="aircraftDropDown"
    ngbDropdownToggle
  >
    <!-- <i class="la la-plane text-orange h3 mr-1 pull-left"></i> -->
    <small class="menu_icons">
      <img
        src="assets/images/visual_assets/menu_icons/Aircraft Icon - Orange-01.png"
        alt=""
      />
    </small>

    <span class="text-orange my-0 align-self-center mx-auto" *ngIf="selectedAircraft.id">
      <strong>{{ selectedAircraft.tailNo }}</strong>
    </span>
    <span class="text-paleblue h6 my-0" *ngIf="!selectedAircraft.id">
      <strong>No Aircraft Selected </strong>
    </span>

  </a>
  <div ngbDropdownMenu aria-labelledby="aircraftDropDown">
    <div class="m-3 text-muted orange_titles">
      Select Aircraft
    </div>

    <div
      class="my-1 cursor-pointer aircraft-list-item"
      ngbDropdownItem
      appSideMenuAutoCloseLinks
      *ngFor="let aircraft of userAircraftList; let i = index"
      (click)="onAircraftSelect(aircraft)"
    >
      <span class="aircraft-list-img my-1 mr-2">
        <img src="assets/images/crew-chief-icon-full-color.png" alt="" />
      </span>
      <span class="h5 ellipsis  aircraft-list-text">
        <!-- <small class="d-block ellipsis">
          {{ selectedOrg?.organization?.organizationName }}
        </small> -->
        <small class="d-block ellipsis">
          {{ aircraft?.airframe?.make?.name }} 
          <span *ngIf="aircraft.airframe && aircraft.airframe.model">
              / {{ aircraft.airframe.model?.name }}
          </span>
        </small>
        {{ aircraft?.tailNo }}
      </span>
    </div>
  </div>
</div>

<div class="d-block mb-2 mt-1" *ngIf="userAircraftList.length < 1">
  <a class="nav-link" href="javascript: void(0);">
    <i class="la la-plane"></i>
    <span class="desc text-orange h5">
      Aircraft
      <small class="d-block text-paleblue">
        NO AIRCRAFT
      </small>
    </span>
  </a>
</div>
