import { OrganizationTypes } from './organization-types';

export class OrganizationBaseModel {
  constructor(){
    this.id = null;
    this.organizationType = null;
    this.organizationName = null;
  }
  id: number;
  organizationType?: OrganizationTypes;
  organizationName?: string;
  createdAt?: string;
  updatedAt?: string;
}
