<section class="mb-3">

  <ng-container>

    <section class="mb-3 border-0 admin-console-search">
      <button class="btn btn-primary w-100" (click)="openCreateOrgModal()">
        <i class="la la-plus-circle"></i> Create New Organization
      </button>
    </section>

    <h4>List of Organizations</h4>

    <div class="card shadow bo rder-0 admin-search-result">
      <!-- <div class="card-header">
        <span *ngIf="filterQuery">Search Results for <b>{{filterQuery}}</b></span>
        <span *ngIf="!filterQuery">Total People: <b>{{allOrgs.length}}</b></span>
      </div> -->

      <div class="card-body">

        <app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

        <ng-container *ngIf="!isWorking">
          <div>
            <form [formGroup]="updateOrgForm" (ngSubmit)="updateOrganization()" *ngIf="!isUpdatingUser">
              <table class="table table-striped table-responsive">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Organization Name</th>

                    <!-- No Org type for now -->
                    <!-- <th scope="col" class="min-w-150">Type</th> -->

                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <!--- Org rows-->
                  <tr *ngFor="let org of allOrgs; let i = index;"
                  id="{{'org_' + org.id}}"
                  >
                    <th scope="row">{{i+1}}</th>
                    <td>
                      <ng-container *ngIf="!orgBeingUpdated(org)">
                        {{org?.organizationName}}

                        <small class="d-block text-muted" *ngIf="org.createdAt">
                          <i class="la la-calendar text-primary"></i>
                          Created: {{org?.createdAt | date}}
                        </small>
                      </ng-container>


                      <ng-container *ngIf="orgBeingUpdated(org)">
                        <div class="form-group mb-0">
                          <div class="input-group">
                            <input type="text" formControlName="orgName" class="form-control" id="orgName"
                              placeholder="Enter Organization Name.">
                          </div>
                        </div>
                      </ng-container>
                    </td>

                    <!--No org type for now-->
                    <!-- <td>
                      <ng-container *ngIf="!orgBeingUpdated(org)">
                        {{ getOrgTypeLabelText(org.organizationType) }}
                        <small class="d-block text-muted" *ngIf="org.updatedAt">
                          Updated: {{org?.updatedAt | date}}
                        </small>
                      </ng-container>

                      <ng-container *ngIf="orgBeingUpdated(org)">
                        <div class="form-group mb-0">
                          <div class="input-group">
                            <select
                            class="form-control"
                            formControlName="orgType" id="orgType" placeholder="Select role">
                              <option *ngFor="let type of orgTypeOptions.options; let i = index" [value]="type.value">
                                {{type.label}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </ng-container>
                    </td> -->

                    <td>
                      <div *ngIf="!isUpdatingOrg">
                        <a (click)="loadOrgForUpdate(org)" class="btn btn-sm btn-outline-primary rounded mr-2"
                        *ngIf="
                        +updatedOrg.id
                        !== +org.id">
                            <i class="la la-edit"></i> Edit Org
                        </a>

                        <ng-container *ngIf="orgBeingUpdated(org)">
                          <a (click)="updateOrganization()" class="btn btn-sm btn-success rounded mr-2">
                              <i class="la la-save"></i> Save
                          </a>

                          <a (click)="unloadUpdatedOrg()" class="btn btn-sm btn-danger rounded mr-2">
                              <i class="la la-times text-white"></i>
                          </a>
                        </ng-container>


                        <a (click)="loadAndViewOrgAsSelectedOrg(org)" href="javascript: void(0);" class="btn btn-sm btn-primary rounded ml-auto">
                          <i class="la la-city"></i> Profile
                        </a>

                        <!--ORG DELETE: TODO-->
                        <!-- <a (click)="deleteOrg(org)" class="btn btn-outline-danger rounded">
                          <i class="la la-trash"></i>
                        </a> -->
                      </div>

                      <app-preloader-type-a *ngIf="isUpdatingOrg"></app-preloader-type-a>
                    </td>
                  </tr>

                </tbody>
              </table>
            </form>
          </div>

          <div class="d-flex justify-content-between p-2">
            <ngb-pagination
              [collectionSize]="allOrgs.length" [(page)]="page" [pageSize]="pageSize" (pageChange)="listOrganizations()">
            </ngb-pagination>
          </div>

        </ng-container>

      </div>
    </div>

  </ng-container>

</section>
