import { AircraftMakeModel } from './../../../models/aircraft-make-model';
import { UserAircraftModel } from './../../../models/user-aircraft-model';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, map } from 'rxjs/operators';
import { AircraftService } from './../../../services/aircraft.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { NumberToTenths } from '../../../_custom-validators/number-to-tenths-validator';
import { UtilitiesService } from '../../../services/utilities.service';
import { AircraftModel } from '../../../models/aircraft-model';
import { EngineModel } from '../../../models/engine-model';
import { PropellerModel } from '../../../models/propeller-model';

@Component({
  selector: 'app-edit-aircraft',
  templateUrl: './edit-aircraft.component.html',
  styleUrls: ['./edit-aircraft.component.scss']
})
export class EditAircraftComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private aircraftService: AircraftService,
    private router: Router,
    private route: ActivatedRoute,
    private utilitiesService: UtilitiesService,
  ) { }

  editMode: boolean = false;

  updateAircraftForm: FormGroup;

  theAircraft: UserAircraftModel = new UserAircraftModel;

  aircraftMakeSearchFailed: boolean = false;
  aircraftMakeSearching: boolean = false;
  aircraftModelSearchFailed: boolean = false;
  aircraftModelSearching: boolean = false;

  engineMakeSearchFailed: boolean = false;
  engineMakeSearching: boolean = false;
  engineModelSearchFailed: boolean = false;
  engineModelSearching: boolean = false;


  propellerMakeSearchFailed: boolean = false;
  propellerMakeSearching: boolean = false;
  propellerModelSearchFailed: boolean = false;
  propellerModelSearching: boolean = false;

  isWorking: boolean = false;

  ngOnInit() {

    console.log('DISCONTINUED COMPONENT!!!!!!');

    this.updateAircraftForm = this.formBuilder.group({
      'tailNo': new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(6)]),
      'aircraftMake': new FormControl('', [Validators.required]),
      'aircraftModel': new FormControl('', [Validators.required]),
      'airframeSn': new FormControl('', [Validators.required]),
      'airframeTtis': new FormControl('', [Validators.required, NumberToTenths(false)]),

      'engineMake': new FormControl('', [Validators.required]),
      'engineModel': new FormControl('', [Validators.required]),
      'engineSn': new FormControl('', [Validators.required]),
      'initialEngineHours': new FormControl('', [Validators.required, NumberToTenths(false)]),
      'initialLastEngineOh': new FormControl('', [Validators.required, NumberToTenths(false)]),
      'engineInstalledAirframeTime': new FormControl('', [Validators.required, NumberToTenths(false)]),

      'propellerMake': new FormControl('', [Validators.required]),
      'propellerModel': new FormControl('', [Validators.required]),
      'propellerSn': new FormControl('', [Validators.required]),
      'initLastPropellerOh': new FormControl('', [Validators.required, NumberToTenths(false)]),
      'inititialPropellerHours': new FormControl('', [Validators.required, NumberToTenths(false)]),
      'propellerInstalledAirframeTime': new FormControl('', [Validators.required, NumberToTenths(false)]),

      'avionicsEquipments': new FormControl(''),
      'otherEquipments': new FormControl(''),
    })

    //observe the activated route parameters, and get user id  on change
    this.getAircraftById();

  }

  getAircraftById(){
    const aircraftId = +this.route.snapshot.paramMap.get('aircraftId');

    this.aircraftService.getAircraftById(aircraftId).subscribe(
      (response: any)=>{
        if(response.status.code == 1000){
          this.theAircraft = response.responseData;

          if(!this.theAircraft.aircraft_model){
            this.theAircraft.aircraft_model = new AircraftModel;
          }

          if(this.theAircraft.aircraft_model && !this.theAircraft.aircraft_model.aircraftMake){
            this.theAircraft.aircraft_model.aircraftMake = new AircraftMakeModel;
          }

          //
          if(!this.theAircraft.engine_model){
            this.theAircraft.engine_model = new EngineModel;
          }

          if(this.theAircraft.engine_model && !this.theAircraft.engine_model.engineMake){
            this.theAircraft.engine_model.engineMake = new AircraftMakeModel;
          }


          //
          if(!this.theAircraft.propeller_model){
            this.theAircraft.propeller_model = new PropellerModel;
          }

          if(this.theAircraft.propeller_model && !this.theAircraft.propeller_model.propellerMake){
            this.theAircraft.propeller_model.propellerMake = new AircraftMakeModel;
          }

          this.initUpdateAircraftForm();
        }
      }
    )
  }

  initUpdateAircraftForm(){
    this.updateAircraftForm.controls['tailNo'].setValue(this.theAircraft.tailNo);
    this.updateAircraftForm.controls['aircraftMake'].setValue(
      this.theAircraft.aircraft_model? this.theAircraft.aircraft_model.aircraftMake: null,
      [Validators.required]
    );
    this.updateAircraftForm.controls['aircraftModel'].setValue(this.theAircraft.aircraft_model);
    this.updateAircraftForm.controls['airframeSn'].setValue(this.theAircraft.airframeSn);
    this.updateAircraftForm.controls['airframeTtis'].setValue(this.theAircraft.airframeTtis);

    this.updateAircraftForm.controls['engineMake'].setValue(
      this.theAircraft.engine_model? this.theAircraft.engine_model.engineMake : null
      ),
    this.updateAircraftForm.controls['engineModel'].setValue(this.theAircraft.engine_model);
    this.updateAircraftForm.controls['engineSn'].setValue(this.theAircraft.engineSn);
    this.updateAircraftForm.controls['initialEngineHours'].setValue(this.theAircraft.initialEngineHours);
    this.updateAircraftForm.controls['initialLastEngineOh'].setValue(this.theAircraft.initialLastEngineOh);
    this.updateAircraftForm.controls['engineInstalledAirframeTime'].setValue(this.theAircraft.engineInstalledAirframeTime);

    this.updateAircraftForm.controls['propellerMake'].setValue(
      this.theAircraft.propeller_model?this.theAircraft.propeller_model.propellerMake : null
    );
    this.updateAircraftForm.controls['propellerModel'].setValue(
      this.theAircraft.propeller_model? this.theAircraft.propeller_model : null
    );
    this.updateAircraftForm.controls['propellerSn'].setValue(this.theAircraft.propellerSn);
    this.updateAircraftForm.controls['initLastPropellerOh'].setValue(this.theAircraft.initLastPropellerOh);
    this.updateAircraftForm.controls['inititialPropellerHours'].setValue(this.theAircraft.inititialPropellerHours);
    this.updateAircraftForm.controls['propellerInstalledAirframeTime'].setValue(this.theAircraft.propellerInstalledAirframeTime);

    this.updateAircraftForm.controls['avionicsEquipments'].setValue(this.theAircraft.avionicsEquipments);
    this.updateAircraftForm.controls['otherEquipments'].setValue(this.theAircraft.otherEquipments);

  }

  //from getters for validations
  get aircraftMake() { return this.updateAircraftForm.controls['aircraftMake'] };
  get aircraftModel() { return this.updateAircraftForm.controls['aircraftModel'] };
  get engineMake() { return this.updateAircraftForm.controls['engineMake'] };
  get engineModel() { return this.updateAircraftForm.controls['engineModel'] };
  get engineSn() { return this.updateAircraftForm.controls['engineSn'] };
  get initialLastEngineOh() { return this.updateAircraftForm.controls['initialLastEngineOh'] };
  get engineInstalledAirframeTime() { return this.updateAircraftForm.controls['engineInstalledAirframeTime'] };
  get propellerMake() { return this.updateAircraftForm.controls['propellerMake'] };
  get propellerModel() { return this.updateAircraftForm.controls['propellerModel'] };
  get propellerSn() { return this.updateAircraftForm.controls['propellerSn'] };
  get initLastPropellerOh() { return this.updateAircraftForm.controls['initLastPropellerOh'] };
  get inititialPropellerHours() { return this.updateAircraftForm.controls['inititialPropellerHours'] };
  get propellerInstalledAirframeTime() { return this.updateAircraftForm.controls['propellerInstalledAirframeTime'] };
  get tailNo() { return this.updateAircraftForm.controls['tailNo'] };
  get airframeTtis() { return this.updateAircraftForm.controls['airframeTtis'] };
  get initialEngineHours() { return this.updateAircraftForm.controls['initialEngineHours'] };


  //// Type heads search functions for aircraft make, engine make and propeller make
  aircraftMakeTypeHeadSearch = (text$: Observable<string>)=>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.aircraftMakeSearching = true),
      switchMap(term => this.getMakes(term, 'aircraft')
      ),
      tap(() => this.aircraftMakeSearching = false)
  )

  engineMakeTypeHeadSearch = (text$: Observable<string>)=>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.engineMakeSearching = true),
      switchMap(term => this.getMakes(term, 'engine')
      ),
      tap(() => this.engineMakeSearching = false)
  )

  propellerMakeTypeHeadSearch = (text$: Observable<string>)=>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.propellerMakeSearching = true),
      switchMap(term => this.getMakes(term, 'propeller')
      ),
      tap(() => this.propellerMakeSearching = false)
  )


  //////////AIR CRAFT MODEL TYPEHEAD///////////
  aircraftModelTypeHeadSearch = (text$: Observable<string>)=>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.aircraftModelSearching = true),
      switchMap(term => {
        if(this.theAircraft.aircraft_model.aircraftMake.id == null) return of([{id: null, modelName: term}]);

        return this.aircraftService.findAircraftModelByMake(
          term, 
          +this.theAircraft.aircraft_model.aircraftMake.id,
          this.theAircraft.aircraft_model.aircraftMake.makeName
        ).pipe(
            tap(() => this.aircraftModelSearchFailed = false),
            catchError(() => {
              this.aircraftModelSearchFailed = true;
              return of([]);
            }),
            map((res:any) =>{
              let arr:Array<any> = [];

              //send new term as new Model
              let termModel = null;
              term = term.trim()
              if(term){
                 termModel = {id: null, modelName: term};
                 arr.push(termModel)
              }
              ///////

              res.responseData.result.forEach(element => {
                //set id of newly added item if it's in result
                if(termModel && (element.modelName.toUpperCase() === termModel.modelName.toUpperCase(0))){
                  arr[0].id = element.id;
                  termModel = null;
                }

                arr.push(element);
              });

              return arr;
            }),
          )
        }
      ),
      tap(() => this.aircraftModelSearching = false)
  )

  //////////ENGINE MODEL TYPEHEAD///////////
  engineModelTypeHeadSearch = (text$: Observable<string>)=>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.engineModelSearching = true),
      switchMap(term => {
        if(this.theAircraft.engine_model.engineMake.id == null) return of([{id: null, modelName: term}]);

        return this.aircraftService.findEngineModelByMake(term, +this.theAircraft.engine_model.engineMake.id, this.theAircraft.engine_model.engineMake.makeName ).pipe(
          tap(() => this.engineModelSearchFailed = false),
          catchError(() => {
          this.engineModelSearchFailed = true;
          return of([]);
        }),
        map((res:any) =>{
          let arr:Array<any> = [];

          //send new term as new Model
          let termModel = null;
          term = term.trim()
          if(term){
             termModel = {id: null, modelName: term};
             arr.push(termModel)
          }
          ///////

          res.responseData.result.forEach(element => {
            //set id of newly added item if it's in result
            if(termModel && (element.modelName.toUpperCase() === termModel.modelName.toUpperCase(0))){
              arr[0].id = element.id;
              termModel = null;
            }

            arr.push(element);
          });
          return arr;
          }),
        )
      }
    ),
    tap(() => this.engineModelSearching = false)
  )

  //////////PROPELLER  MODEL TYPEHEAD///////////
  propellerModelTypeHeadSearch = (text$: Observable<string>)=>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.propellerModelSearching = true),
      switchMap(term => {
        if(this.theAircraft.propeller_model.propellerMake.id == null) return of([{id: null, modelName: term}]);

        return this.aircraftService.findPropellerModelByMake(term, +this.theAircraft.propeller_model.propellerMake.id, this.theAircraft.propeller_model.propellerMake.makeName ).pipe(
          tap(() => this.propellerModelSearchFailed = false),
          catchError(() => {
            this.propellerModelSearchFailed = true;
            return of([]);
          }),
          map((res:any) =>{
           let arr:Array<any> = [];

           //send new term as new Model
           let termModel = null;
           term = term.trim()
           if(term){
              termModel = {id: null, modelName: term};
              arr.push(termModel)
           }
           ///////

            res.responseData.result.forEach(element => {
              //set id of newly added item if it's in result
              if(termModel && (element.modelName.toUpperCase() === termModel.modelName.toUpperCase(0))){
                arr[0].id = element.id;
                termModel = null;
              }

              arr.push(element);
            });

            //console.log(JSON.stringify(arr))

            return arr;
          }),
        )
      }
    ),
    tap(() => this.propellerModelSearching = false)
  )
  //


  //format typeheads data received,  before display
  typeHeadMakeFormatter = (makeObj: {id: string, makeName: string}) => {
    return makeObj.makeName? makeObj.makeName : '';
  }

  typeHeadModelFormatter = (modelObj: {id: string, modelName: string}) => {
    return modelObj.modelName? modelObj.modelName : '';
  }


  //On Select typehead(make) options...
  onTypeHeadSelected(ev: NgbTypeaheadSelectItemEvent, mode: 'aircraft' | 'propeller' | 'engine'){
    if(mode == 'aircraft'){
      this.theAircraft.aircraft_model.aircraftMake.id = ev.item.id;
      this.theAircraft.aircraft_model.aircraftMake.makeName = ev.item.makeName;
    }
    if(mode == 'engine'){
      this.theAircraft.engine_model.engineMake.id = ev.item.id;
      this.theAircraft.engine_model.engineMake.makeName = ev.item.makeName;
    }
    if(mode == 'propeller'){
      this.theAircraft.propeller_model.propellerMake.id = ev.item.id;
      this.theAircraft.propeller_model.propellerMake.makeName = ev.item.makeName;
    }

    this.theAircraft[mode + '_model'].id = null;
    this.theAircraft[mode + '_model'].modelName = null;

    this.updateAircraftForm.get(mode+"Model").setValue({id: null, modelName: null});
  }
  //On Select typehead(model) options...
  onTypeHeadModelSelected(ev: NgbTypeaheadSelectItemEvent, mode: 'aircraft' | 'propeller' | 'engine'){
    if(mode == 'aircraft'){
      this.theAircraft.aircraft_model.id = ev.item.id;
      this.theAircraft.aircraft_model.modelName = ev.item.modelName;
    }
    if(mode == 'engine'){
      this.theAircraft.engine_model.id = ev.item.id;
      this.theAircraft.engine_model.modelName = ev.item.modelName;
    }
    if(mode == 'propeller'){
      this.theAircraft.propeller_model.id = ev.item.id;
      this.theAircraft.propeller_model.modelName = ev.item.modelName;
    }
  }


  //get makes for the modes
  getMakes(term, mode: string) {
    return this.aircraftService.findAircraftMakeByName(term).pipe(
      tap(() => this[mode + 'MakeSearchFailed'] = false),
      catchError(() => {
        this[mode + 'MakeSearchFailed'] = true;
        return of([]);
      }),
      map((res:any) =>{
        let arr:Array<any> = [];

        //send new term as new Make
        let termMake = null;
        term = term.trim()
        if(term){
          termMake = {makeId: null, makeName: term};
           arr.push(termMake)
        }
        ///////

        res.responseData.forEach(element => {
          //set id of newly added item if it's in result
          if(termMake && (element.makeName.toUpperCase() === termMake.makeName.toUpperCase(0))){
            arr[0].makeId = element.makeId;
            termMake = null;
          }

          arr.push(element);
        });
        /*if(arr.length < 1 ){
          arr.push({makeId: null, makeName: term.trim()})
        }*/
        return arr;
      }),
    )
  }

  doUpdateAircraft(){
    if(!this.updateAircraftForm.valid){
      for(let i in this.updateAircraftForm.controls) {
        this.updateAircraftForm.controls[i].markAsTouched();
      };

      Swal.fire('Check important fields!', 'Form not filled correctly!', 'error');
      return;
    }

    this.isWorking = true;

    this.theAircraft.user_id = this.theAircraft.user.id,
    this.theAircraft.tailNo = this.updateAircraftForm.controls['tailNo'].value,
    this.theAircraft.airframeSn = this.updateAircraftForm.controls['airframeSn'].value,
    this.theAircraft.airframeTtis = this.updateAircraftForm.controls['airframeTtis'].value,
    this.theAircraft.engineSn = this.updateAircraftForm.controls['engineSn'].value,
    this.theAircraft.initialEngineHours = this.updateAircraftForm.controls['initialEngineHours'].value,
    this.theAircraft.initialLastEngineOh = this.updateAircraftForm.controls['initialLastEngineOh'].value,
    this.theAircraft.engineInstalledAirframeTime = this.updateAircraftForm.controls['engineInstalledAirframeTime'].value,
    this.theAircraft.propellerSn = this.updateAircraftForm.controls['propellerSn'].value,
    this.theAircraft.initLastPropellerOh = this.updateAircraftForm.controls['initLastPropellerOh'].value,
    this.theAircraft.inititialPropellerHours = this.updateAircraftForm.controls['inititialPropellerHours'].value,
    this.theAircraft.propellerInstalledAirframeTime = this.updateAircraftForm.controls['propellerInstalledAirframeTime'].value,
    this.theAircraft.avionicsEquipments = this.updateAircraftForm.controls['avionicsEquipments'].value,
    this.theAircraft.otherEquipments = this.updateAircraftForm.controls['otherEquipments'].value

    this.aircraftService.updateAircraftForUser(this.theAircraft).subscribe(
      (response: any)=>{
        this.isWorking = false;
        Swal.fire('Nice one!', 'Aircraft updated.', 'success');
        this.editMode = false;
        this.getAircraftById();
      },
      (error)=>{
        this.isWorking = false;
        if(error.status.message){
          Swal.fire(
            error.status.message,
            error.responseData.join(' '),
            'error'
          )
        }
        else{
          Swal.fire(
            'Oops!',
            'Creat aircraft failed.',
            'error'
          )
        }
      }
    )
  }

  toggleEditMode(){
    this.initUpdateAircraftForm();
    this.editMode = !this.editMode;
  }

};
