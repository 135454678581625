import { AlertPopUpsService } from './alert-pop-ups.service';
import { FormGroup, AbstractControl } from '@angular/forms';
import { AirworthinessDirectiveModel } from './../models/airworthiness-directive-model';
import { ServiceBulletinModel } from './../models/service-bulletin-model';
import { UserAircraftModel } from './../models/user-aircraft-model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import * as moment from "moment";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  isSideBarCollapsed: boolean;
  _isSideBarCollapsed = new BehaviorSubject(this.isSideBarCollapsed);
  public isSideBarCollapsed$: Observable<boolean> = this._isSideBarCollapsed.asObservable();

  private showMainPreloader: boolean = false;
  private _showMainPreloader = new BehaviorSubject(this.showMainPreloader);
  public showMainPreloader$: Observable<boolean> = this._showMainPreloader.asObservable();

  private showActivityPreloader: boolean = false;
  private _showActivityPreloader = new BehaviorSubject(this.showActivityPreloader);
  public showActivityPreloader$: Observable<boolean> = this._showActivityPreloader.asObservable();

  topHeaderTitle: string = '';
  _topHeaderTitle = new BehaviorSubject(this.topHeaderTitle);
  public topHeaderTitle$: Observable<string> = this._topHeaderTitle.asObservable();

  constructor(
    private httpClient: HttpClient,
    private alertPopUpService: AlertPopUpsService
  ) {
    this.isSideBarCollapsed = false;
    this._isSideBarCollapsed.next(this.isSideBarCollapsed);
  }

  /***TOP MENU PAGE TITLE */
  setPagetitle(title: string) {
    this.topHeaderTitle = title ? title.toUpperCase() : '';
    this._topHeaderTitle.next(this.topHeaderTitle);
  }
  ////

  // More accurate Date to ISO String Converter
  dateToISOString(dateObj: Date) {
    const tzo = -dateObj.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num) {
        const norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };
    return dateObj.getFullYear() +
      '-' + pad(dateObj.getMonth() + 1) +
      '-' + pad(dateObj.getDate()) +
      'T' + pad(dateObj.getHours()) +
      ':' + pad(dateObj.getMinutes()) +
      ':' + pad(dateObj.getSeconds()) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60);
  }


  // More accurate ISO String to Date Parser
  parseISOtoDateObj(isoDateString: string) {
    if (!isoDateString) {
      return new Date();
    }

    const b = isoDateString.split(/\D+/);
    let utcYear = parseInt(
      b[0]),
      utcMonth = parseInt(b[1]),
      utcDate = (+b[2]),
      utcHours = (+b[3]), 
      utcMinutes = (+b[4]), 
      utcSeconds = (+b[5]),
      utcMillisec = (+b[6]
    );
    return new Date(Date.UTC(utcYear, --utcMonth, utcDate, utcHours, utcMinutes, utcSeconds, utcMillisec));
  }

  toggleSideBar() {
    this.isSideBarCollapsed = !this.isSideBarCollapsed;
    this._isSideBarCollapsed.next(this.isSideBarCollapsed);
  }

  updateShowMainPreloader(val: boolean) {
    this.showMainPreloader = val;
    this._showMainPreloader.next(this.showMainPreloader);
  }

  updateShowActivityPreloader(val: boolean) {
    this.showActivityPreloader = val;
    this._showActivityPreloader.next(this.showActivityPreloader);
  }

  getServerDate() {
    return this.httpClient.get(environment.apiBaseUrl + '/utility/getServerDate');
  }

  getAircraftDashboardReferences() {
    return this.httpClient.get(environment.apiBaseUrl + '/utility/getAircraftDashboardReferences');
  }

  formatMomentAsUsDateString(momentObject: moment.Moment): string {
    return momentObject ? moment(momentObject).format('MM/DD/YYYY') : null;
  }

  getMomentBetweenDates(lowerTimeMoment: moment.Moment, higherTimeMoment: moment.Moment) {
    if (!(lowerTimeMoment && higherTimeMoment)) {
      return null;
    }

    const lowerMoment = moment(lowerTimeMoment).startOf('days');

    const higherMoment = moment(higherTimeMoment).startOf('days');

    const diff: number = higherMoment.diff(lowerMoment, 'hours');

    if (diff <= 0) {
      return 'Overdue';
    } else if (diff => 24) {
      const days = Math.floor(diff / 24);

      const dayText = days > 1 ? ' days' : ' day';

      return days >= 395 ? '> 1 year' : days + dayText;
    } else {
      return diff + ' hours';
    }

  }

  getHoursRemainingBetweenHours(lowerHours: number, higherHours: number) {
    const hours = +higherHours - +lowerHours;

    // hours = Math.round((higherHours - lowerHours)*10/10);

    if (hours <= 0 ) {
      return 'Overdue';
    }

    const hoursText = hours > 1 ? ' hours' : ' hour';

    return this.roundNumberToTenths(hours, 1) + hoursText;
  }


  roundNumberToTenths(value: number, precision: number = 0) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(+value * multiplier) / multiplier;
  }


  getEngineTTHours(theAircraft: UserAircraftModel, todayFlightHours: number) {
    const engineTT = (todayFlightHours - theAircraft.engineInstalledAirframeTime) + (+theAircraft.initialEngineHours);
    return this.roundNumberToTenths(engineTT, 1);
  }

  getPropellerTTHours(theAircraft: UserAircraftModel, todayFlightHours: number) {
    const propellerTT = (todayFlightHours - theAircraft.propellerInstalledAirframeTime) + (+theAircraft.inititialPropellerHours);
    return this.roundNumberToTenths(propellerTT, 1);
  }

  getAdSbTypeText(r: ServiceBulletinModel | AirworthinessDirectiveModel) {
    const adSbType = r.adSbType ? ' : for ' + (r.adSbType.toLowerCase()) + 's' : '';
    return adSbType ? adSbType : '';
  }

  getAdSbModels(obj: ServiceBulletinModel | AirworthinessDirectiveModel): Array<{ modelName: string, manufacturerName: string }> {
    let models: Array<{ modelName: string, manufacturerName: string }> = [];

    if (obj.adSbType == 'airframe') {
      if (!obj.aircraftModels) {
        return [];
      }

      obj.aircraftModels.forEach(
        el => {
          models.push({ modelName: el.modelName, manufacturerName: el.aircraftManufacturer.manufacturerName });
        }
      );
    } else if (obj.adSbType == 'engine') {
      if (!obj.engineModels) {
        return [];
      }

      obj.engineModels.forEach(
        el => {
          models.push({ modelName: el.modelName, manufacturerName: el.engineManufacturer.manufacturerName });
        }
      );
    } else if (obj.adSbType == 'propeller') {
      if (!obj.propellerModels) {
        return [];
      }

      obj.propellerModels.forEach(
        el => {
          models.push({ modelName: el.modelName, manufacturerName: el.propellerManufacturer.manufacturerName })
        }
      );
    } else if (obj.adSbType == 'appliance') {
      if (!obj.applianceModels) {
        return [];
      }

      obj.applianceModels.forEach(
        el => {
          models.push({ modelName: el.modelName, manufacturerName: el.applianceManufacturer.manufacturerName });
        }
      );
    }  else {
      models = [];
    }

    return models;
  }


  /**
* @description adds an item to list
* @param {*} item
* @param {[*]} array
* @param {string} attribute
*/
  addToList(item, array) {
    // add item to array
    array.push(item);
    console.log(`added to list: ${JSON.stringify(item)}`);
  }

  /**
  * @description removes item from list
  * @param {*} item
  * @param {[*]} array
  * @param {string} attribute
  */
  removeFromList(item, array) {
    // iterate array
    for (let i = 0; i < array.length; i++) {
      // check if directive id is matching
// tslint:disable-next-line: max-line-length
      if (item['modelName'] === array[i]['modelName'] && item['makeName'] === array[i]['makeName'] && item['manufacturerName'] === array[i]['manufacturerName']) {
        // remove from array
        array.splice(i, 1);
        console.log(`removed from list: ${JSON.stringify(item)}`);
      }
    }
  }


  /**
   * @param {any[]} list
   * @param {string} attributeName
   * @returns {any[]} sortedList
   */
  sortList(list: any[], attributeName: string): any[] {
    const sortedList = list.sort((a, b) => {
      if (a[attributeName] > b[attributeName]) {
        return 1;
      }

      if (a[attributeName] < b[attributeName]) {
        return -1;
      }
      return 0;
    });
    return sortedList;
  }


  /**
   * @description checks if an item is present in a list
   * @param {*} item
   * @param {[*]} array
   * @param {string} attribute
   */
  checkIfInList(item, array) {
    // flag
    const result = {
      flag: false,
      itemIndex: null
    };

    // loop through array
    array.forEach((element, index) => {
      // check if directive id is matching
      if (
        item['modelName'] === element['modelName'] &&
        item['makeName'] === element['makeName'] &&
        item['manufacturerName'] === element['manufacturerName'] &&
        item['manufacturerId'] === element['manufacturerId'] &&
        item['makeId'] === element['makeId']
      ) {
        // set flag to true
        result.flag = true;
        result.itemIndex = index;
        console.log(`in the list ${index}`);
      }
    });

    // return flag
    return result;
  }


  /**
   * @description check if date is within 6 months of current date
   * @param {any} date
   * @returns {boolean} isSixMonths
   */
  sixMonthsPrior(date: any): boolean {

    // convert to date object
    const dateToTest = new Date(moment(date).format('YYYY-DD-MM'));

    // variable to store bool value of is 6 months
    let isSixMonths = false;

    // get month number of input date
    const monthNumber = dateToTest.getMonth();

    // set date to six months back
    dateToTest.setMonth(dateToTest.getMonth() - 6);

    // check difference
    const difference = ((monthNumber + 12) - dateToTest.getMonth()) % 12;

    // all for cases where monthNumber < 6
    if (difference < 6) {
      // set date to january
      dateToTest.setDate(0);

      // set bool to true
      isSixMonths = true;
    }

    // const _date = moment(date).format('YYYY-MM-DD');
    // console.log(`${_date } is ${isSixMonths ? 'is LESS than six months old' : 'is MORE than six months old' }`);

    return isSixMonths;
  }


  formatPhoneNumber(phoneNumber: string) {
    if (!phoneNumber) {
      return null;
    }

    const phoneNumberTest = parsePhoneNumberFromString(phoneNumber, 'US');

    if (phoneNumberTest) {
      return phoneNumberTest.formatNational(); // '(213) 373-4253'
      // return phoneNumberTest.formatInternational(); //'+1 213 373 4253'
    } else {
      return phoneNumber;
    }
  }

  generalFormValidate(theForm: FormGroup): boolean {
    if (!theForm.valid) {
      this.alertPopUpService.openBasicPopUp(
        'Form contains invalid fields!',
        'Check all fields and ensure they have been correctly filled',
        'error'
      );

      Object.values(theForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      return false;
    }

    return true;
  }
}
