import { ManufacturerModel } from './manufacturer-model';
import { BaseAircraftEnginePropellerApplianceModel } from './base-aircraft-engine-propeller-appliance-model';
import { AircraftMakeModel } from './aircraft-make-model';

export class EngineModel extends BaseAircraftEnginePropellerApplianceModel{
  constructor(){
    super();
    this.id = null;
    this.engineMake = new AircraftMakeModel;
    this.engineManufacturer = new ManufacturerModel;
  }
  
  engineMake: AircraftMakeModel;
  engineManufacturer?: ManufacturerModel;
}