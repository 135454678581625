import { UserAircraftModel } from './user-aircraft-model';

export class MAircraft100hoursModel{
  constructor(){
    this.id = null;
    this.aircraftId = null;
    this.last100Hours = null;
    this.aircraft  = new UserAircraftModel;
  }
  id: number;
  aircraftId: number;
  last100Hours: number;
  aircraft: UserAircraftModel;
}