import { Component, OnInit, Input, SimpleChanges, OnChanges} from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { AircraftV2Model } from '../../models/airfcraftV2/aircraft-v2.model';

@Component({
  selector: 'app-aircraft-info-header-widget-v2',
  templateUrl: './aircraft-info-header-widget-v2.component.html',
  styleUrls: ['./aircraft-info-header-widget-v2.component.scss']
})
export class AircraftInfoHeaderWidgetV2Component implements OnInit, OnChanges {
  @Input() theAircraft: AircraftV2Model;
  @Input() headerTitle: string;
  @Input() viewStyle: 'inline' | 'list';

  constructor(
    private decimalPipe: DecimalPipe
  ) { }

  ngOnInit() {
    // this.viewStyle = !this.viewStyle? 'inline' : this.viewType;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.theAircraft && changes.theAircraft.currentValue) {
      this.theAircraft = changes.theAircraft.currentValue;
    }

    if (changes.headerTitle && changes.headerTitle.currentValue) {
      this.headerTitle = changes.headerTitle.currentValue;
    }

    if (changes.viewStyle && changes.viewStyle.currentValue) {
      this.viewStyle = changes.viewStyle.currentValue;
    }
  }

  getValueIfNullOrUndefined(val: any) {
    return val === null
    ||  val === undefined ?
    '--- ---' : this.decimalPipe.transform(val, '1.1-1');
  }
}
