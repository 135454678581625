import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { OrganizationV2Model } from '../../../../models/organization/organization-v2.model';
import { UtilitiesService } from '../../../../services/utilities.service';
import { AircraftV2Model } from '../../../../models/airfcraftV2/aircraft-v2.model';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { OrganizationService } from '../../../../services/organization.service';
import { AlertPopUpsService } from '../../../../services/alert-pop-ups.service';
import { OrganizationBaseModel } from '../../../../models/organization-base-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OptionLabelsAndValuesModel } from '../../../../models/option-labels-and-values-model';
import { AircraftService } from '../../../../services/aircraft.service';
import { UserModel } from '../../../../models/user-model';
import { SubscriptionLike } from 'rxjs';
import { UserService } from '../../../../services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-aircraft-org-list-table',
  templateUrl: './aircraft-org-list-table.component.html',
  styleUrls: ['./aircraft-org-list-table.component.scss']
})
export class AircraftOrgListTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() theAircraft: AircraftV2Model;

  attachOrgForm: FormGroup;
  isAttachingOrg: boolean;
  allOrgsAndRoles: Array<OrganizationBaseModel>;

  currentUser: UserModel;
  currentUser$: SubscriptionLike;

  orgRoleOptions: Array<OptionLabelsAndValuesModel> = [
    { label: 'Select Org', value: '' },
    { label: 'Maintainer', value: 'MAINTAINER' }
  ];

  constructor(
    private utilsService: UtilitiesService,
    private fb: FormBuilder,
    private orgService: OrganizationService,
    private userService: UserService,
    private aircraftService: AircraftService,
    private popUpService: AlertPopUpsService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.isAttachingOrg = false;
    this.allOrgsAndRoles = [];

    this.currentUser$ = this.userService.currentUser$.subscribe(
      (user: UserModel) => {
        this.currentUser = user;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.theAircraft && changes.theAircraft.currentValue) {
      this.theAircraft = changes.theAircraft.currentValue;
      this.modalService.dismissAll();
      this.allOrgsAndRoles = [];
    }
  }

  initAttachAircraftForm() {
    this.attachOrgForm = this.fb.group({
      attachOrgId: new FormControl(null, [Validators.required]),
      attachOrgRole: new FormControl(null, [Validators.required])
    });
  }

  listAllOrgs() {
    this.isAttachingOrg = true;

    this.orgService.listOrganizationsV2().subscribe(
      (response: Array<OrganizationBaseModel>) => {
        this.isAttachingOrg = false;
        this.allOrgsAndRoles = response;
      },
      (error: any) => {
        this.isAttachingOrg = false;
        this.popUpService.openBasicPopUp(
          'Can not fetch Organizations List!',
          'Please reload page and try again.',
          'error'
        );
      }
    );
  }

  doAttachOrgToAircraft() {
    const isFormValid = this.utilsService.generalFormValidate(this.attachOrgForm);

    if (!isFormValid) {
      return;
    }

    this.isAttachingOrg = true;

    const orgId = this.attachOrgForm.get('attachOrgId').value;
    const orgRole = this.attachOrgForm.get('attachOrgRole').value;

    this.aircraftService.attachOrgAndRoleToAircraftV2(orgId, this.theAircraft.id, orgRole).subscribe(
      () => {
        this.popUpService.openBasicPopUp(
          'Great!',
          'Organization has been attached to aircraft',
          'success'
        );
        this.aircraftService.refreshSelectedAircraft();
        this.modalService.dismissAll();
      },
      (error: any) => {
        this.isAttachingOrg = false;
        this.popUpService.openBasicPopUp(
          'Sorry!',
          'Organization was not attached to aircraft',
          'error'
        );
      }
    );
  }

  formatPhoneNumber(number: string) {
    return this.utilsService.formatPhoneNumber(number);
  }

  initAttachOrgModal(content) {
    this.listAllOrgs();
    this.initAttachAircraftForm();
    this.modalService.open(content, {ariaLabelledBy: 'Attach Org to Aircraft'}).result.then((result) => {
      // Close Reason;
    }, (reason) => {
      // Dismissed Reason;
    });
  }

  detachOrg(org: OrganizationV2Model) {
    const confirmPromise = this.popUpService.openConfirmPopUp(
      'Are you sure?',
      `Are you sure you want to detach ${org.name.toUpperCase()} from tail #${this.theAircraft.tailNo}`,
      'question',
      true,
      'Yes. I know what I am doing',
      'No. Please cancel'
    );

    confirmPromise.then(
      (descision) => {
        if (descision.value) {
          this.aircraftService.deleteOrgAndRoleFromAircraftV2(org.id, this.theAircraft.id).subscribe(
            (response: any) => {
              this.popUpService.openBasicPopUp(
                'Great!',
                'Organization has been detatched from aircraft',
                'success'
              );

              this.aircraftService.refreshSelectedAircraft();
            },
            (error: any) => {
              this.popUpService.openBasicPopUp(
                'Sorry!',
                'Organization was not detached from aircraft',
                'error'
              );
            }
          );
        } else if (descision.dismiss === Swal.DismissReason.cancel) {
          console.log('Canceled detach org.');
        }
      }
    );
  }

  checkIfInAircraftOrgs(org: OrganizationBaseModel) {
    return this.theAircraft.organizations.every(
      (aircraftOrg: OrganizationV2Model) => {
        if (+aircraftOrg.id === +org.id) {
          return false;
        } else {
          return true;
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.currentUser$) {
      this.currentUser$.unsubscribe();
    }
  }

}
