import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { BulkImportsService } from '../../services/bulk-imports.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bulk-bulletin-upload',
  templateUrl: './bulk-bulletin-upload.component.html',
  styleUrls: ['./bulk-bulletin-upload.component.scss']
})
export class BulkBulletinUploadComponent implements OnInit {

  uploadForm: FormGroup;
  uploadFormWithUrl: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private bulkImportService: BulkImportsService
  ) { }

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      csv: ['']
    });

    this.uploadFormWithUrl = this.formBuilder.group({
      'url': new FormControl(null)
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('csv').setValue(file);
    }
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('csv', this.uploadForm.get('csv').value);

    this.bulkImportService.bulkCsvServiceBulletinImport(formData).subscribe(
      (res) => console.log(res),
      (error) => {
        if(error.status && error.status.message){
          Swal.fire(
            error.status.message,
            error.responseData.join(' '),
            'error'
          )
        }
        else{
          Swal.fire(
            'Oops!',
            'Failed to submit and import bulk bulletin csv via file upload.',
            'error'
          )
        }
      }
    );
  }

  onSubmitCsvUrl() {
    this.bulkImportService.bulkCsvServiceBulletinImportUsingUrl(this.uploadFormWithUrl.get('url').value).subscribe(
      (res) => console.log(res),
      (error) => {
        if(error.status && error.status.message){
          Swal.fire(
            error.status.message,
            error.responseData.join(' '),
            'error'
          )
        }
        else{
          Swal.fire(
            'Oops!',
            'Failed to submit and import bulk bulletin csv via url.',
            'error'
          )
        }
      }
    );
  }

}
