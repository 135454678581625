import { ValidatorFn, AbstractControl } from '@angular/forms';

export function AllControlsNeededIfValueIsValidator ([... otherControls]: Array<AbstractControl>, value: any): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let isForbidden: boolean = null;
    let allOtherControlsChecker: boolean = true;

    if(control.value !== value) return null;

    otherControls.forEach(
      otherControl => {

        if(!otherControl.value) {
          allOtherControlsChecker = null;
        }
      }
    )

    if((control.value == value) && !allOtherControlsChecker){
      isForbidden = true;
    }
    
    return isForbidden ? {'allControlsNeededIfValueIs': {value: control.value}} : null;
  };
}