<h6 class="text-muted">
  {{file.name}}
</h6>
<div *ngIf="percentage | async as pct">
  <progress [value]="pct" max="100" class="w-100"></progress>
  {{ pct | number }}%
</div>


<div *ngIf="snapshot | async as snap; else loading">
  Bytes: {{ snap.bytesTransferred }} of {{ snap.totalBytes }} 

  <!-- <div *ngIf="downloadURL as url">
    <h3>Results!</h3>
    <img [src]="url"><br>
    <a [href]="url" target="_blank" rel="noopener">Download!</a>
  </div>  -->

  <div class="btn-group" role="group" aria-label="Basic example">
    <button type="button" class="btn btn-sm btn-primary" (click)="task.pause()" [disabled]="!isActive(snap)">Pause</button>
    <button type="button" class="btn btn-sm btn-danger" (click)="task.cancel()" [disabled]="!isActive(snap)">Cancel</button>
    <button type="button" class="btn btn-sm btn-success" (click)="task.resume()" [disabled]="!(snap?.state === 'paused')">Resume</button>
  </div>

  <button class="btn btn-danger w-100 mt-3" (click)="emitResetUploadForm()" *ngIf="!isActive(snap)">
    Go back to Upload Form
  </button>
</div>
<ng-template #loading>
  <div class="text-center"><em>Loading ...</em></div>
</ng-template>    