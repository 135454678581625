export class AircraftMaintenanceStatusV2Model {
  constructor() {
    this.hundredHours = {
      time: null
    };

    this.altimeters = {
      date: null
    };

    this.annuals = {
      time: null,
      date: null
    };

    this.elts = {
      date: null
    };

    this.oilChange = {
      time: null,
    };

    this.pitotics = {
      date: null
    };

    this.transponders = {
      date: null
    };
  }

  hundredHours: {
    time: number;
  };

  altimeters: {
    date: string
  };

  annuals: {
    time: number
    date: string
  };

  elts: {
    date: string
  };

  oilChange: {
    time: null,
  };

  pitotics: {
    date: string
  };

  transponders: {
    date: string
  };
}
