import { ValidatorFn, AbstractControl } from '@angular/forms';

export function NumberToTenths(allowEmpty: boolean): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (allowEmpty && !control.value) {
      return null; // allow emopty fields, if definedas allowEmpty
    }

    const isToTenths = /^\d+(\.[\d])?$/.test(control.value);
    return isToTenths ? null : {'numberToTenths': {value: control.value}};
  };
}
