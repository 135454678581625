<section class="mb-3 border-0 admin-console-search">
  <div class="form-group">
    <form [formGroup]="filterSearchForm" (ngSubmit)="filterSearchByNameEmailTailSerialOrSn()">
      <div class="input-group">
        <input type="text" formControlName="filterQuery" class="form-control" id="filterQuery" placeholder="Enter name, email, aircraft tail no., serial no.">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="submit" id="search_by_name">
            <i class="la la-search"></i>
          </button>
        </div>
      </div>
      <small id="aircraft_help" class="form-text text-muted">Customer Name, Email, Aircraft Tail No, Serial or Model.</small>
    </form>
  </div>
</section>


<div class="card shadow bo rder-0 admin-search-result">
  <div class="card-header">
    <span *ngIf="filterQuery">Search Results for <b>{{filterQuery}}</b></span> 
    <span *ngIf="!filterQuery">All Customers: <b>{{allUserCount}}</b></span>
  </div>
    <div class="card-body"> 
      <div>
        <table class="table table-striped table-responsive">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Tail #</th>
              <th scope="col">Make</th>
              <th scope="col">Model</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <!--- Results for search by user name or email-->
            <ng-container *ngFor="let user of allUserResults; let i = index;">
              <!--- Single row for single aircraft owners-->
              <tr *ngIf="user.aircraft.length < 2">
                <th scope="row">{{i+1}}</th>
                <td>{{user.firstName}} {{user.lastName}}</td>
                <td>
                  <a *ngFor="let aircraft of user.aircraft" [routerLink]="['/cga/admin/edit-aircraft', aircraft.id]" class="btn btn-sm btn-outline-dark rounded">
                    <i class="la la-plane"></i>
                  </a>
                  {{user.aircraft[0]? user.aircraft[0].tailNo : '-'}}
                </td>
                <td>
                  {{user.aircraft[0]? (user.aircraft[0].aircraft_model?.aircraftMake?.makeName) : '-'}}
                </td>
                <td>
                  {{user.aircraft[0]? user.aircraft[0].aircraft_model?.modelName : '-'}}
                </td>
                <td>
                  <a [routerLink]="['/cga/admin/edit-customer', user.id]" class="btn btn-sm btn-outline-dark rounded mr-2">
                    <i class="la la-user"></i>
                  </a>
                  <a  *ngFor="let aircraft of user.aircraft"  [routerLink]="['/cga/admin/edit-aircraft-dashboard', aircraft.id ]" class="btn btn-sm btn-outline-dark rounded">
                    <i class="fa fa-dashboard"></i>
                  </a>
                </td>
              </tr>
              
              <!---Multi row for multi aircraft owners-->
              <ng-container *ngIf="user.aircraft.length > 1" >
                <tr>
                  <th scope="row">{{i+1}}</th>
                  <td>{{user.firstName}} {{user.lastName}}</td>
                  <td>{{user.aircraft.length}} Aircraft <i class="la la-angle-down"></i></td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>
                    <a [routerLink]="['/cga/admin/edit-customer', user.id]" class="btn btn-sm btn-outline-dark rounded mr-2">
                      <i class="la la-user"></i>
                    </a>
                  </td>
                </tr>
                <tr *ngFor="let aircraft of user.aircraft; let i2 = index">
                  <th scope="row">&nbsp;</th>
                  <td>&nbsp;</td>
                  <td>
                    <a [routerLink]="['/cga/admin/edit-aircraft', aircraft.id]" class="btn btn-sm btn-outline-dark rounded">
                      <i class="la la-plane"></i>
                    </a>
                    {{aircraft.tailNo}}
                  </td>
                  <td>
                    {{aircraft.aircraft_model?.aircraft_make?.makeName}}
                  </td>
                  <td>
                    {{aircraft.aircraft_model?.modelName}}
                  </td>
                  <td>
                    <a [routerLink]="['/cga/admin/edit-aircraft-dashboard', aircraft.id ]" class="btn btn-sm btn-outline-dark rounded">
                      <i class="la la-dashboard"></i>
                    </a>
                  </td>
                </tr>
              </ng-container>
            </ng-container>


            <!--- Results for search by Aircraft Model, TailNo. or SerialNo.-->
            <ng-container *ngFor="let aircraftUser of filterResults; let i = index;">
              <tr >
                <th scope="row">{{i+1}}</th>
                <td>{{aircraftUser.firstName}} {{aircraftUser.lastName}}</td>
                <td>
                  <a *ngIf="aircraftUser.aircraft" [routerLink]="['/cga/admin/edit-aircraft', aircraftUser.aircraft.id]" class="btn btn-sm btn-outline-dark rounded">
                    <i class="la la-plane"></i>
                  </a>
                  {{aircraftUser?.aircraft?.tailNo}}
                </td>
                <td>
                  {{aircraftUser?.aircraft?.aircraft_model?.aircraftMake?.makeName }}
                </td>
                <td>
                  {{aircraftUser?.aircraft?.aircraft_model?.modelName }}
                </td>
                <td>
                  <a [routerLink]="['/cga/admin/edit-customer', aircraftUser.id]" class="btn btn-sm btn-outline-dark rounded mr-2">
                    <i class="la la-user"></i>
                  </a>
                  
                  <a *ngIf="aircraftUser.aircraft" [routerLink]="['/cga/admin/edit-aircraft-dashboard', aircraftUser.aircraft.id ]" class="btn btn-sm btn-outline-dark rounded">
                    <i class="la la-dashboard"></i>
                  </a>
                </td>
              </tr>
            </ng-container>

          </tbody>
        </table>
      </div>
      
      <div class="d-flex justify-content-between p-2" *ngIf="!filterQuery">
        <ngb-pagination
          [collectionSize]="allUserCount" [(page)]="allPage" [pageSize]="allPageSize" (pageChange)="getAllCustomers()">
        </ngb-pagination>
      </div>
      
      <div class="d-flex justify-content-between p-2" *ngIf="filterQuery">
        <ngb-pagination
          [collectionSize]="filterResultsCount" [(page)]="filterPage" [pageSize]="filterPageSize" (pageChange)="filterSearchByNameEmailTailSerialOrSn()">
        </ngb-pagination>
      </div>
    </div>
</div>