<!---Manage Air Directives -->
<section class="p-3 admin-air-directives">
  <div class="card shadow border-0">
    <!-- header -->
    <div class="card-header clearfix">
      &nbsp;
      <span class="float-right">
        <button class="btn btn-sm btn-outline-dark rounded" (click)="openAddAirworthinessDirectivesModal()">
          Open Add Directives Workflow
          <i class="la la-plus"></i>
        </button>
        <span class="p-2"></span>

        <button class="btn btn-sm btn-outline-dark rounded" (click)="toggleInNewDirectiveMode()">
          {{ inAddNewDirectiveMode ? 'Cancel' : 'Add Directive' }}
          <i class="la la-plus" *ngIf="!inAddNewDirectiveMode"></i>
          <i class="la la-times" *ngIf="inAddNewDirectiveMode"></i>
        </button>

      </span>
    </div>

    <!-- body -->
    <div class="card-body">

      <!--NEW DIRECTIVE-->
      <div class="card mb-3 bg-light border-info rounded" *ngIf="inAddNewDirectiveMode">
        <!--<div class="card-header">
          New Airworthiness Directive
        </div>-->
        <div class="card-body">

          <app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

          <form [formGroup]="newAircraftAirDirectiveForm" *ngIf="!isWorking" (ngSubmit)="addAircraftAirDirective()">
            <div>
              <div class="col-12 mb-1">
                <small class="text-muted pr-2">Manufacturers: </small>
                {{newAircraftAirDirective.airworthiness_directive.id? getDirectiveModelManufacturers(newAircraftAirDirective.airworthiness_directive) : '----'}}
              </div>

              <div class="row">
                <div class="col-12 mb-1">
                  <small class="text-muted pr-2">Subject: </small>


                  <!--AD Document View Link-->
                  <ng-container>
                    <button
                      (click)="openModal(content, newAircraftAirDirective.airworthiness_directive.fileUrl, newAircraftAirDirective.airworthiness_directive.directiveName)"
                      class="mr-2 btn btn-danger btn-sm rounded"
                      *ngIf="newAircraftAirDirective.airworthiness_directive.fileUrl">
                      <small class="d-block text-white">
                        <i class="la la-file-pdf-o"></i>
                        View Document
                      </small>
                    </button>

                    <span class="badge badge-light rounded p-2"
                      *ngIf="!newAircraftAirDirective.airworthiness_directive.fileUrl">
                      <small class="d-block text-muted">
                        <i class="la la-file-pdf-o"></i>
                        No Document
                      </small>
                    </span>
                  </ng-container>

                  {{newAircraftAirDirective.airworthiness_directive? newAircraftAirDirective.airworthiness_directive.subject : '----'}}
                </div>

                <div class="col-sm-12 col-lg-3 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted required">
                      Directive Name {{newAircraftAirDirectiveSearching? '...searching...' : ''}}
                    </small>
                    <input type="text" formControlName="directive" class="form-control" id="directive"
                      placeholder="Directive Name" [class.is-invalid]="newAircraftAirDirectiveSearchFailed"
                      [ngbTypeahead]="newAircraftAirDirectiveTypeHeadSearch" [editable]="false" [resultTemplate]="rt"
                      [inputFormatter]="typeHeadDirectiveFormatter" (selectItem)="onTypeHeadSelected($event)" required>

                    <ng-template #rt let-r="result" let-t="term">
                      <ngb-highlight [result]="r.directiveName" [term]="t"></ngb-highlight>
                    </ng-template>
                    <small class="form-text text-danger"
                      *ngIf="newAircraftAirDirectiveForm.get('directive').invalid && (newAircraftAirDirectiveForm.get('directive').touched || newAircraftAirDirectiveForm.get('directive').dirty)">
                      Ensure you select Directive from the dropdown options
                    </small>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-3 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Effective Date</small>
                    <div class="input-group">
                      {{newAircraftAirDirectiveForm?.controls["effectiveDate"]?.value | date:'MM/dd/yyyy'}}
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-3 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Due Date</small>
                    <div class="input-group">
                      <input class="form-control" formControlName="dueDate" placeholder="MM/dd/yyyy" name="dueDate"
                        ngbDatepicker #due_date="ngbDatepicker">
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="due_date.toggle()" type="button">
                          <i class="la la-calendar-plus-o"></i>
                        </button>
                      </div>
                    </div>


                    <!-- due now button -->
                    <button class="btn btn-sm btn-info calendar w-100" (click)="dueNowADNew($event)" type="button">
                      Due Now
                    </button>


                    <small class="form-text text-danger d-block"
                      *ngIf="newAircraftAirDirectiveForm.get('dueDate').invalid && (newAircraftAirDirectiveForm.get('dueDate').touched || newAircraftAirDirectiveForm.get('dueDate').dirty)">
                      Enter Valid Due Date (and/or Due Hours)
                    </small>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-3 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Due Hours</small>
                    <div class="input-group">
                      <input class="form-control" formControlName="dueHours" placeholder="Due Hours" name="dueHours">
                    </div>
                    <small class="form-text text-danger d-block"
                      *ngIf="newAircraftAirDirectiveForm.get('dueHours').invalid && (newAircraftAirDirectiveForm.get('dueHours').touched || newAircraftAirDirectiveForm.get('dueHours').dirty)">
                      Enter Valid Due Hours (and/or Due Date)
                    </small>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-6 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted required">Condition</small>
                    <div class="input-group">
                      <select class="form-control" formControlName="condition" placeholder="Condition" name="condition">
                        <option *ngFor="let condition of conditionOptions.conditions"
                          [selected]="newAircraftAirDirectiveForm.controls['condition'].value == condition.value"
                          [value]="condition.value"> {{condition.label}}</option>
                      </select>
                    </div>
                    <small class="form-text text-danger d-block"
                      *ngIf="newAircraftAirDirectiveForm.hasError('required', 'condition') && (newAircraftAirDirectiveForm.get('condition').touched || newAircraftAirDirectiveForm.get('condition').dirty)">
                      Select Condition
                    </small>
                    <small class="form-text text-danger d-block"
                      *ngIf="newAircraftAirDirectiveForm.hasError('allControlsNeededIfValueIs', 'condition') &&!(newAircraftAirDirectiveForm.hasError('required', 'condition'))">
                      You need to enter both <b>Due Date</b> and <b>Due Hours</b>
                    </small>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-6 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Frequency</small>
                    <div class="form-check form-check-inline mt-2">
                      <input class="form-check-input" type="checkbox" id="isRecurring" formControlName="isRecurring">
                      <label class="form-check-label" for="isRecurring">Recurring?</label>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="row">
                    <div class="col-sm-12 col-lg-6 col-md-5">
                      <div class="form-group">
                        <small class="form-text text-muted required">Status for Aircraft</small>
                        <div class="input-group">
                          <select class="form-control" formControlName="application" placeholder="Status for Aircraft"
                            name="application">
                            <option *ngFor="let app of applicationOptions.applications" [value]="app.value">
                              {{app.label}}
                            </option>
                          </select>
                        </div>
                        <div
                          *ngIf="newAircraftAirDirectiveForm.controls['application'].touched || newAircraftAirDirectiveForm.controls['application'].dirty">
                          <small class="form-text text-danger d-block"
                            *ngIf="newAircraftAirDirectiveForm.hasError('required', 'application')">
                            Select Status for Aircraft
                          </small>
                          <small class="form-text text-danger d-block"
                            *ngIf="newAircraftAirDirectiveForm.hasError('hasDependantIfControlIs_Complied', 'application')">
                            Fill <b>Complied Date</b> field.
                          </small>
                          <small class="form-text text-danger d-block"
                            *ngIf="newAircraftAirDirectiveForm.hasError('hasDependantIfControlIs_NotApply', 'application')">
                            Fill <b>Not Apply</b> field.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12 col-lg-6 col-md-7">
                      <div class="row" *ngIf="newAircraftAirDirectiveForm.get('application').value == 'Complied'">

                        <div class="col-sm-12 col-lg-7 col-md-7">
                          <div class="form-group">
                            <small class="form-text text-muted required">Complied Date</small>
                            <div class="input-group">
                              <input class="form-control" formControlName="compliedDate" placeholder="mm/dd/yyyy"
                                name="compliedDate" ngbDatepicker #complied_date="ngbDatepicker">
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="complied_date.toggle()"
                                  type="button">
                                  <i class="la la-calendar-plus-o"></i>
                                </button>
                              </div>
                            </div>
                            <small class="form-text text-danger d-block"
                              *ngIf="newAircraftAirDirectiveForm.get('compliedDate').invalid && (newAircraftAirDirectiveForm.get('compliedDate').touched || newAircraftAirDirectiveForm.get('compliedDate').dirty)">
                              Enter Complied Date
                            </small>
                          </div>
                        </div>

                        <div class="col-sm-12 col-lg-5 col-md-5">
                          <div class="form-group">
                            <small class="form-text text-muted">Complied Hours</small>
                            <div class="input-group">
                              <input class="form-control" formControlName="compliedHour" placeholder="e.g 1200.5"
                                name="compliedHour">
                            </div>
                            <small class="form-text text-danger d-block"
                              *ngIf="newAircraftAirDirectiveForm.get('compliedHour').invalid && (newAircraftAirDirectiveForm.get('compliedHour').touched || newAircraftAirDirectiveForm.get('compliedHour').dirty)">
                              Hours to tenths e.g 100.5
                            </small>
                          </div>
                        </div>
                      </div>


                      <div class="form-group"
                        *ngIf="newAircraftAirDirectiveForm.get('application').value == 'NotApply'">
                        <small class="form-text text-muted required">Does not apply reason</small>
                        <div class="input-group">
                          <input class="form-control" formControlName="notApplyReason" placeholder="Not Apply Reason"
                            name="notApplyReason">
                        </div>
                        <small class="form-text text-danger d-block"
                          *ngIf="newAircraftAirDirectiveForm.get('notApplyReason').invalid && (newAircraftAirDirectiveForm.get('notApplyReason').touched || newAircraftAirDirectiveForm.get('notApplyReason').dirty)">
                          Enter 'Not Apply' reason (Max 1,000 chars.)
                        </small>
                      </div>
                    </div>

                    <div class="col-12" *ngIf="newAircraftAirDirectiveForm.get('application').value == 'Complied'">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <small class="form-text text-muted">Compliance Note</small>
                            <div class="input-group">
                              <textarea class="form-control w-100" formControlName="complianceNote"
                                name="complianceNote" id="complianceNote" placeholder="Compliance Note"
                                rows="4"></textarea>
                            </div>
                            <small class="form-text text-danger" *ngIf="newAircraftAirDirectiveForm.get('complianceNote').invalid">
                              Maximum 3,000 characters exceeded.
                            </small>
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="form-group">
                            <small class="form-text text-muted">Terminating Action</small>
                            <div class="form-check form-check-inline mt-2">
                              <input class="form-check-input" type="checkbox" id="isTerminated"
                                formControlName="isTerminated">
                              <label class="form-check-label" for="isTerminated">Check to Terminate</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="text-right mt-2">
              <button class="btn btn-outline-danger mr-3" (click)="inAddNewDirectiveMode = false">Cancel</button>
              <button class="btn btn-outline-primary">Add Directive</button>
            </div>
          </form>

          <hr class="mt-1">
        </div>
      </div>

      <!--EXISTING BULLETINS-->
      <section *ngIf="!inAddNewDirectiveMode">
        <div>
          <!---NO SERVICE BULLETIN-->
          <div class="alert alert-warning text-center" role="alert"
            *ngIf="(!aircraftAirDirectiveSearchForm.get('queryString').value) && allAircraftAirDirectives.length < 1 && !aircraftDirectivesLoading">
            No Airworthiness Directive on aircraft.
          </div>

          <!-- preloader -->
          <app-preloader-type-a
            *ngIf="(!aircraftAirDirectiveSearchForm.get('queryString').value) && (allAircraftAirDirectives.length < 1) && aircraftDirectivesLoading">
          </app-preloader-type-a>

          <!-- filter -->
          <div *ngIf="allAircraftAirDirectives.length > 0 || aircraftAirDirectiveSearchForm.get('queryString').value">
            <form [formGroup]="aircraftAirDirectiveSearchForm">
              <div class="form-group form-inline">
                Filter:
                <input class="form-control ml-2" formControlName="queryString" type="text" name="query" />
                <span class="ml-3" *ngIf="aircraftDirectivesLoading">Searching...</span>
              </div>
            </form>

            <!---NO SERVICE BULLETIN IN FILTER-->
            <div class="alert alert-warning text-center" role="alert"
              *ngIf="!aircraftDirectivesLoading && (allAircraftAirDirectives.length < 1)">
              No Service Airworthiness Directive matches your query.
            </div>

            <!-- preloader -->
            <app-preloader-type-a *ngIf="aircraftDirectivesLoading"></app-preloader-type-a>

            <ng-container *ngIf="!aircraftDirectivesLoading">
              <!-- service bulletin list -->
              <table class="table table-striped w-100 mb-3 pb-3" *ngIf="allAircraftAirDirectives.length > 0">
                <tr *ngFor="let directive of allAircraftAirDirectives; index as i">
                  <div class="p-3">
                    <h5 class="clearfix">
                      {{ ((page-1)*pageSize) + (i + 1 )}}:
                      {{directive.airworthiness_directive.directiveName}}
                      <!-- <ng-container *ngIf="directive.isRecurring"> -->
                        <span *ngIf="directive.terminateAction" class="text-success"
                          ngbPopover="This is a terminated FAAA irworthiness Directive." triggers="mouseenter:mouseleave"
                          popoverTitle="TERMINATED">
                          <i class="la la-ban font-weight-bold"></i>
                        </span>

                        <span *ngIf="!directive.terminateAction && directive.isRecurring" class="text-info"
                          ngbPopover="This is a recurring FAAA irworthiness Directive." triggers="mouseenter:mouseleave"
                          popoverTitle="RECURRING">
                          <i class="la la-refresh font-weight-bold"></i>
                        </span>
                      <!-- </ng-container> -->


                      <!--AD Document View Link-->
                      <ng-container>
                        <button
                          (click)="openModal(content, directive.airworthiness_directive.fileUrl, directive.airworthiness_directive.directiveName)"
                          class="mr-2 btn btn-danger btn-sm rounded" *ngIf="directive.airworthiness_directive.fileUrl">
                          <small class="d-block text-white">
                            <i class="la la-file-pdf-o"></i>
                            View Document
                          </small>
                        </button>

                        <span class="badge badge-light rounded p-2" *ngIf="!directive.airworthiness_directive.fileUrl">
                          <small class="d-block text-muted">
                            <i class="la la-file-pdf-o"></i>
                            No Document
                          </small>
                        </span>
                      </ng-container>

                      <!-- buttons  -->
                      <span class="float-right">
                        <!-- edit button -->
                        <button class="btn btn-outline-primary rounded mr-3"
                          *ngIf="directive.id !== editAircraftAirDirective.id" (click)="loadDirectiveForEdit(directive)">
                          <i class="la la-edit"></i>
                        </button>

                        <button class="btn btn-outline-primary rounded mr-3"
                          *ngIf="directive.id == editAircraftAirDirective.id" (click)="unLoadDirectiveForEdit()">
                          <i class="la la-times"></i>
                        </button>

                        <!-- delete button -->
                        <button class="btn btn-outline-danger rounded"
                          (click)="deleteAircraftAirworthinessDirective(directive)">
                          <i class="la la-trash"></i>
                        </button>
                      </span>

                    </h5>
                    <div class="col-12 mb-1">
                      <small class="text-muted pr-2">Manufacturers: </small>
                      {{directive.airworthiness_directive? getDirectiveModelManufacturers(directive.airworthiness_directive) : '----'}}
                    </div>

                    <div class="col-12 mb-1">
                      <small class="text-muted pr-2">Subject: </small>
                      {{directive.airworthiness_directive.subject? directive.airworthiness_directive.subject : '----'}}
                    </div>

                    <div class="row">
                      <div class="my-2 col-6 col-sm-6 col-md-4 col-lg-2 border-right">
                        <small class="d-block font-weight-bold">Effective Date</small>
                        {{ directive.airworthiness_directive.effectiveDate? (directive.airworthiness_directive.effectiveDate | date:'MM/dd/yyyy') : '-- -- --' }}
                      </div>
                      <div class="my-2 col-6 col-sm-6 col-md-4 col-lg-2 border-right">
                        <small class="d-block font-weight-bold">Due Date</small>
                        {{ directive.dueDate | date:'MM/dd/yyyy' }}
                      </div>
                      <div class="my-2 col-6 col-sm-6 col-md-4 col-lg-2 border-right">
                        <small class="d-block font-weight-bold">Due Hours</small>
                        {{ getHoursOrNA(directive.dueHours) }}
                      </div>
                      <div class="my-2 col-6 col-sm-6 col-md-4 col-lg-2 border-right">
                        <small class="d-block font-weight-bold">Condition</small>
                        {{ directive.condition }}
                      </div>
                      <div class="my-2 col-6 col-sm-6 col-md-4 col-lg-2 border-right">
                        <small class="d-block font-weight-bold">Recurring</small>

                          <span *ngIf="directive.terminateAction" class="text-success font-weight-bold"
                            ngbPopover="This is a terminated FAA Airworthiness Directive."
                            triggers="mouseenter:mouseleave" popoverTitle="TERMINATED">
                            <i class="la la-ban"></i>
                            TERMINATED
                          </span>

                          <ng-container *ngIf="!directive.terminateAction">
                            <span *ngIf="directive.isRecurring" class="text-info font-weight-bold"
                              ngbPopover="This is a recurring FAA Airworthiness Directive." triggers="mouseenter:mouseleave"
                              popoverTitle="Recurring">
                              <i class="la la-refresh text-info"></i>
                              YES
                            </span>
                            <span *ngIf="!directive.isRecurring" class="text-dark font-weight-bold">
                              NO
                            </span>
                          </ng-container>

                      </div>
                      <div class="my-2 col-6 col-sm-6 col-md-4 col-lg-2">
                        <small class="d-block font-weight-bold">Applicability</small>
                        <div *ngIf="directive.application">
                          <div class="
                          {{(directive.application == 'Complied') && (directive.terminateAction)? 'text-success': '' }}
                          {{(directive.application == 'Complied') && (directive.isRecurring && !directive.terminateAction)? 'text-info': '' }}
                          {{(directive.application == 'Complied') && !directive.isRecurring? 'text-success': '' }}
                          {{directive.application == 'NotApply'? 'text-primary': '' }}
                          {{directive.application == 'Applies'? 'text-info': '' }}
                          ">
                            <span class="font-weight-bold">
                              {{ directive.application | uppercase}}
                            </span>
                          </div>
                          <small class="d-block text-pimary"
                            *ngIf="directive.lastCompliedDate && (directive.application == 'Complied')">
                            {{directive.lastCompliedDate | date:'MM/dd/yyyy' }}
                            {{directive.lastCompliedHour? (' / ' + directive.lastCompliedHour + 'Hrs') : '' }}
                          </small>
                          <small class="d-block text-pimary"
                            *ngIf="directive.notApplyReason && (directive.application == 'NotApply')">
                            {{directive.notApplyReason }}
                          </small>
                        </div>
                      </div>

                      <!--Compliance note-->
                      <div class="col-12" *ngIf="directive.complianceNote">
                        <small class="d-block p-2 text-dark bg-light border-gray border rounded m-2 allow_wrap">
                          <b>NOTE:</b> {{directive.complianceNote}}
                        </small>
                      </div>

                    </div>
                    <!--Edit AD-->
                    <div class="card my-3 bg-light border-danger rounded"
                      *ngIf="directive.id == editAircraftAirDirective.id">
                      <div class="card-body">

                        <app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

                        <form [formGroup]="editAircraftAirDirectiveForm" *ngIf="!isWorking"
                          (ngSubmit)="updateAircraftAirDirective()">
                          <div class="row">
                            <div class="col-sm-12 col-lg-3 col-md-6">
                              <div class="form-group">
                                <small class="form-text text-muted">
                                  Directive Name {{editAircraftAirDirectiveSearching? '...searching...' : ''}}
                                </small>
                                {{editAircraftAirDirective.airworthiness_directive.directiveName}}
                              </div>
                            </div>

                            <div class="col-sm-12 col-lg-3 col-md-6">
                              <div class="form-group">
                                <small class="form-text text-muted">Effective Date</small>
                                <div class="input-group">
                                  {{ editAircraftAirDirective.airworthiness_directive.effectiveDate | date:'MM/dd/yyyy'}}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-lg-3 col-md-6">
                              <div class="form-group">
                                <small class="form-text text-muted">Update Due Date</small>
                                <div class="input-group">
                                  <input class="form-control" formControlName="editDueDate" placeholder="MM/dd/yyyy"
                                    name="edit_duedate" ngbDatepicker #editDueDate="ngbDatepicker">
                                  <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="editDueDate.toggle()"
                                      type="button">
                                      <i class="la la-calendar-plus-o"></i>
                                    </button>
                                  </div>
                                </div>


                                <!-- due now button -->
                                <button class="btn btn-sm btn-info calendar w-100" (click)="dueNowADUpdate($event)" type="button">
                                  Due Now
                                </button>

                                <small class="form-text text-danger d-block"
                                  *ngIf="editAircraftAirDirectiveForm.get('editDueDate').invalid && (editAircraftAirDirectiveForm.get('editDueDate').touched || editAircraftAirDirectiveForm.get('editDueDate').dirty)">
                                  Enter Valid Due Date (and/or Due Hours)
                                </small>
                              </div>
                            </div>

                            <div class="col-sm-12 col-lg-3 col-md-6">
                              <div class="form-group">
                                <small class="form-text text-muted">Update Due Hours</small>
                                <div class="input-group">
                                  <input class="form-control" formControlName="editDueHours" placeholder="Due Hours"
                                    name="edit_dueHours">
                                </div>
                                <small class="form-text text-danger d-block"
                                  *ngIf="editAircraftAirDirectiveForm.get('editDueHours').invalid && (editAircraftAirDirectiveForm.get('editDueHours').touched || editAircraftAirDirectiveForm.get('editDueHours').dirty)">
                                  Enter Valid Due Hours (and/or Due Date)
                                </small>
                              </div>
                            </div>

                            <div class="col-sm-12 col-lg-6 col-md-6">
                              <div class="form-group">
                                <small class="form-text text-muted required">Update Condition</small>
                                <div class="input-group">
                                  <select class="form-control" formControlName="editCondition" placeholder="Condition"
                                    name="edit_condition">
                                    <option *ngFor="let condition of conditionOptions.conditions"
                                      [selected]="condition.value == editAircraftAirDirectiveForm.controls['editCondition'].value"
                                      [value]="condition.value">
                                      {{condition.label}}
                                    </option>
                                  </select>
                                </div>
                                <small class="form-text text-danger d-block"
                                  *ngIf="editAircraftAirDirectiveForm.hasError('required', 'editCondition') && (editAircraftAirDirectiveForm.get('editCondition').touched || editAircraftAirDirectiveForm.get('editCondition').dirty)">
                                  Select Condition
                                </small>
                                <small class="form-text text-danger d-block"
                                  *ngIf="editAircraftAirDirectiveForm.hasError('allControlsNeededIfValueIs', 'editCondition') &&!(editAircraftAirDirectiveForm.hasError('required', 'editCondition'))">
                                  You need to enter both <b>Due Date</b> and <b>Due Hours</b>
                                </small>
                              </div>
                            </div>

                            <div class="col-sm-12 col-lg-6 col-md-6">
                              <div class="form-group">
                                <small class="form-text text-muted">Update Frequency</small>
                                <div class="form-check form-check-inline mt-2">
                                  <input class="form-check-input" type="checkbox" id="edit_isRecurring"
                                    formControlName="editIsRecurring">
                                  <label class="form-check-label" for="edit_isRecurring">Recurring?</label>
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-lg-6 col-md-5">
                              <div class="form-group">
                                <small class="form-text text-muted required">Update Status for Aircraft?</small>
                                <div class="input-group">
                                  <select class="form-control" formControlName="editApplication"
                                    placeholder="Status for Aircraft" name="edit_application">
                                    <option *ngFor="let app of applicationOptions.applications"
                                      [selected]="editAircraftAirDirectiveForm.controls['editApplication'].value == app.value"
                                      [ngValue]="app.value">
                                      {{app.label}}
                                    </option>
                                  </select>
                                </div>
                                <div
                                  *ngIf="editAircraftAirDirectiveForm.controls['editApplication'].touched || editAircraftAirDirectiveForm.controls['editApplication'].dirty">
                                  <small class="form-text text-danger d-block"
                                    *ngIf="editAircraftAirDirectiveForm.hasError('required', 'editApplication')">
                                    Select Status for Aircraft
                                  </small>
                                  <small class="form-text text-danger d-block"
                                    *ngIf="editAircraftAirDirectiveForm.hasError('hasDependantIfControlIs_Complied', 'editApplication')">
                                    Fill <b>Complied Date</b> field.
                                  </small>
                                  <small class="form-text text-danger d-block"
                                    *ngIf="editAircraftAirDirectiveForm.hasError('hasDependantIfControlIs_NotApply', 'editApplication')">
                                    Fill <b>Not Apply</b> field.
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-lg-6 col-md-7">
                              <div class="form-group"
                                *ngIf="editAircraftAirDirectiveForm.get('editApplication').value == 'NotApply'">
                                <small class="form-text text-muted required">Does not apply reason</small>
                                <div class="input-group">
                                  <input class="form-control" formControlName="editNotApplyReason"
                                    placeholder="Not Apply Reason" name="edit_notApplyReason">
                                </div>
                                <small class="form-text text-danger d-block"
                                  *ngIf="editAircraftAirDirectiveForm.get('editNotApplyReason').invalid && (editAircraftAirDirectiveForm.get('editNotApplyReason').touched || editAircraftAirDirectiveForm.get('editNotApplyReason').dirty)">
                                  Enter 'Not Apply' reason (Max 1,000 chars.)
                                </small>
                              </div>

                              <div class="row"
                                *ngIf="editAircraftAirDirectiveForm.get('editApplication').value == 'Complied'">
                                <div class="col-7">
                                  <div class="form-group">
                                    <small class="form-text text-muted required">Complied Date</small>
                                    <div class="input-group">
                                      <input class="form-control" formControlName="editCompliedDate"
                                        placeholder="MM/dd/yyyy" name="edit_compliedDate" ngbDatepicker
                                        #editComplied_date="ngbDatepicker">
                                      <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar"
                                          (click)="editComplied_date.toggle()" type="button">
                                          <i class="la la-calendar-plus-o"></i>
                                        </button>
                                      </div>
                                    </div>
                                    <small class="form-text text-danger d-block"
                                      *ngIf="editAircraftAirDirectiveForm.get('editCompliedDate').invalid && (editAircraftAirDirectiveForm.get('editCompliedDate').touched || editAircraftAirDirectiveForm.get('editCompliedDate').dirty)">
                                      Enter Date Complied
                                    </small>
                                  </div>
                                </div>

                                <div class="col-5">
                                  <div class="form-group">
                                    <small class="form-text text-muted">Complied Hours</small>
                                    <div class="input-group">
                                      <input class="form-control" formControlName="editCompliedHour"
                                        placeholder="e.g 1200.5" name="editCompliedHour">
                                    </div>
                                    <small class="form-text text-danger d-block"
                                      *ngIf="editAircraftAirDirectiveForm.get('editCompliedHour').invalid && (editAircraftAirDirectiveForm.get('editCompliedHour').touched || editAircraftAirDirectiveForm.get('editCompliedHour').dirty)">
                                      Hours to tenths(e.g 1200.5)
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-12"
                              *ngIf="editAircraftAirDirectiveForm.get('editApplication').value == 'Complied'">
                              <div class="row">

                                <div class="col-12">
                                  <div class="form-group">
                                    <small class="form-text text-muted">Compliance Note</small>
                                    <div class="input-group">
                                      <textarea class="form-control w-100" formControlName="editComplianceNote"
                                        name="editComplianceNote" id="editComplianceNote" placeholder="Compliance Note"
                                        rows="4"></textarea>
                                    </div>
                                    <small class="form-text text-danger" *ngIf="editAircraftAirDirectiveForm.get('editComplianceNote').invalid">
                                      Maximum 3,000 characters exceeded.
                                    </small>
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div class="form-group">
                                    <small class="form-text text-muted">Terminating Action</small>
                                    <div class="form-check form-check-inline mt-2">
                                      <input class="form-check-input" type="checkbox" id="editIsTerminated"
                                        formControlName="editIsTerminated">
                                      <label class="form-check-label" for="editIsTerminated">Terminate?</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div class="text-right mt-2">
                            <button class="btn btn-outline-danger mr-3" (click)="unLoadDirectiveForEdit()">Cancel</button>
                            <button type="submit" class="btn btn-outline-primary">Update Directive</button>
                          </div>
                        </form>

                        <hr class="mt-1">
                      </div>
                    </div>
                  </div>
                </tr>
              </table>

              <div class="d-flex justify-content-between p-2">
                <ngb-pagination [collectionSize]="totalAircraftAd" [(page)]="page" [pageSize]="pageSize"
                  (pageChange)="paginationPageChange($event)">
                </ngb-pagination>
              </div>
            </ng-container>

          </div>
        </div>
      </section>
    </div>

  </div>
</section>


<!--PDF VIEWER--->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{viewedPdfAdTitle}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-pdf-viewer [pdfSrc]="viewedPdfSrc"></app-pdf-viewer>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>
