import { SbApplicationOptionsModel } from './../../models/sb-application-options-model';
/**
 * @author Michael Lungu
 * @description SD/AD Document Configuration Modal
 */

import {Component, OnInit, Input, Renderer2} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {Observable, of, SubscriptionLike} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, tap, switchMap} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AircraftService} from '../../services/aircraft.service';
import {UtilitiesService} from '../../services/utilities.service';
import {NgbDateCustomAndUSAParserFormatter} from '../../services/ngb-date-custom-and-usa-parser-formatter';
import {NgbDateAdapter, NgbDateNativeAdapter, NgbDatepickerConfig, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {AdSbConditionOptionsModel} from '../../models/ad-sb-condition-options-model';
import {AdApplicationOptionsModel} from '../../models/ad-application-options-model';
import Swal from 'sweetalert2';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../environments/environment';
import {DependsOnControlIfControlIsValidator} from '../../_custom-validators/depends-on-control-if-control-is-validator';
import {HasDependantIfControlIsControlValidator} from '../../_custom-validators/has-dependant-if-control-is-validator';
import {OneOfControlsIsNeededIgnoreIfAnotherControlIsValueValidator} from '../../_custom-validators/one-of-controls-is-needed-ignore-if-another-control-is-value-validator';
import {NumberToTenths} from '../../_custom-validators/number-to-tenths-validator';
import {AllControlsNeededIfValueIsValidator} from '../../_custom-validators/all-controls-needed-if-value-is-validator';

@Component({
  selector: 'app-document-configuration-ui',
  templateUrl: './document-configuration-ui.component.html',
  styleUrls: ['./document-configuration-ui.component.scss'],
  providers: [
    NgbDatepickerConfig,
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomAndUSAParserFormatter}
  ]
})

export class DocumentConfigurationUiComponent implements OnInit {
  // air craft input variable
  @Input() public aircraft;
  @Input() public type;
  newServiceBulletinFormSubscriber$: any;

  // pagination attributes
  collectionSize: number;
  page = 1;
  pageSize = 10;

  _collectionSize: number;
  _pageSize = 10;
  _page = 1;


  // constructor
  constructor(
    private renderer: Renderer2,
    private ngbDatePickerConfig: NgbDatepickerConfig,
    private aircraftService: AircraftService,
    private utilitiesService: UtilitiesService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal) {
    ngbDatePickerConfig.minDate = {
      year: 1900, month: 1, day: 1
    };
  }

  // condition options for config form
  conditionOptions: AdSbConditionOptionsModel = new AdSbConditionOptionsModel;

  // application options for config form
  adApplicationOptions: AdApplicationOptionsModel = new AdApplicationOptionsModel; //For directives
  sbApplicationOptions: SbApplicationOptionsModel = new SbApplicationOptionsModel; //For bulletins

  // editor object
  editor = {
    // type of document
    type: 'airworthiness_directives',
    // current set of ADs/SBs being configured
    documents: [],
    // array index of currently open document
    currentDocumentIndex: 0,
    // current document
    currentDocument: null,
    // attachment logs
    attachmentLogs: [],
    // number of configured documents
    configuredDocumentCount: 0
  };

  fetchingModels = false;
  fetchingDocuments = false;

  // set form objects
  applicabilityForm: FormGroup;

  // form subscribers
  newDueHoursSubscriber$: SubscriptionLike;
  newDueDateSubscriber$: SubscriptionLike;
  newApplicationSubscriber$: SubscriptionLike;
  newTerminatingActionSubscriber$: SubscriptionLike;
  newAirDirectiveFormSubscriber$: SubscriptionLike;

  // form subscribers
  editDueHoursSubscriber$: SubscriptionLike;
  editDueDateSubscriber$: SubscriptionLike;
  editApplicationSubscriber$: SubscriptionLike;
  editTerminatingActionSubscriber$: SubscriptionLike;
  editAirDirectiveFormSubscriber$: SubscriptionLike;

  // aircraft variables
  lastAnnualDate: any;
  ttLastAnnualDate: any;
  engineTTHours: any;
  propellerTTHours: any;

  // make attributes
  make = '';
  searchingMakes = false;
  searchFailedMakes = false;

  // model attributes
  // model = 'm20g';
  model = '';
  MODELS = [];
  // short list
  shortList = [];
  // shortList = [
  //   { 'distinct_field': '_MAKE_MANUF_5', 'modelName': 'A23-24', 'manufacturerId': 5, 'makeId': null, 'makeName': null, 'manufacturerName': 'Beechcraft Corporation', 'isSelected': false },
  //   { 'distinct_field': '_MAKE_MANUF_5', 'modelName': 'A23', 'manufacturerId': 5, 'makeId': null, 'makeName': null, 'manufacturerName': 'Beechcraft Corporation', 'isSelected': false },
  //   { 'distinct_field': '_MAKE_MANUF_11', 'modelName': 'A23-24', 'manufacturerId': 11, 'makeId': null, 'makeName': null, 'manufacturerName': 'Hawker Beechcraft Corporation', 'isSelected': false },
  //   { 'distinct_field': '_MAKE_MANUF_11', 'modelName': 'A23', 'manufacturerId': 11, 'makeId': null, 'makeName': null, 'manufacturerName': 'Hawker Beechcraft Corporation', 'isSelected': false }
  // ];
  searchingModels = false;
  searchFailedModels = false;

  // manufacturer attributes
  manufacturer = '';
  searchingManufacturers = false;
  searchFailedManufacturers = false;

  // directive attributes
  directive = '';
  directives = [];
  searchingDirectives = false;
  searchFailedDirectives = false;


  // bulletin attributes
  bulletin = '';
  bulletins = [];
  searchingBulletins = false;
  searchingFailedBulletins = false;


  // active page for conifguration screen
  activePage = 1; // default to 1
  models: any[];
  searchingModelsByManufacturer: boolean;
  searchFailedModelsByManufacturer: boolean;

  // confirm short list pop up variables
  confirmShortListResult = {
    title: '',
    content: ''
  };

  ngOnInit() {

    // initialize config form
    this.initNewDirectiveForm();

    // check if editor object exists in local storage
    if (localStorage['editor'] !== undefined) {
      // load editor document
      this.editor = JSON.parse(localStorage['editor']);

      // load page 2
      this.activePage = 2;

      console.log(`found unfinished work flow, loading...`);
    }

  }

  ngOnDestroy() {
    // unsubscribe from form subscribers
    if (this.newDueHoursSubscriber$) {
      this.newDueHoursSubscriber$.unsubscribe();
    }
    if (this.newDueDateSubscriber$) {
      this.newDueDateSubscriber$.unsubscribe();
    }
    if (this.newApplicationSubscriber$) {
      this.newApplicationSubscriber$.unsubscribe();
    }
    if (this.newTerminatingActionSubscriber$) {
      this.newTerminatingActionSubscriber$.unsubscribe();
    }
    if (this.newAirDirectiveFormSubscriber$) {
      this.newAirDirectiveFormSubscriber$.unsubscribe();
    }
    if (this.newServiceBulletinFormSubscriber$) {
      this.newServiceBulletinFormSubscriber$.unsubscribe();
    }
  }

  /**
   * @description
   * @param {string} term
   * @param {string} type
   * @param {*} additionalParams
   */
  search(term: string, type: string, additionalParams: {}) {
    // request url
    let url = environment.apiBaseUrl;

    // request params
    const params = {
      q: term,
      pageSize: '1000',
      page: '1'
    };

    // if type === models
    if (type === 'models') {

      // populate params
      const _params = {};

      _params['pageSize'] = 1000;
      _params['page'] = 1;

      // check if model is null
      if (this.model !== '') {
        _params['modelName'] = this.model;
      }

      // check if make is null
      if (this.make !== '') {
        _params['makeName'] = this.make;
      }

      // check if manufacturer is null
      if (this.manufacturer !== '') {
        _params['manufacturerName'] = this.manufacturer;
      }

      // get call get models with ('input') as parameter
      return this.http.get(`${environment.apiBaseUrl}/allmodels/findAllDistinctModelsByName`, {
        params: _params
      }).pipe(
        map(response => {
          const list = [];
          response['responseData'].rows.forEach(model => {
            list.push(model.modelName);
          });
          return list;
        })
      );

    } else {
      // check type
      if (type === 'makes') {
        // set get makes url
        url += '/aircraft/findAircraftMakeByName';
      } else if (type === 'manufacturers') {
        // set get manufacturers url
        url += '/manufacturers/findManufacturerByName';
      }

      try {
        // get makes by query string
        return this.http
          .get(url, {
            params: params
          }).pipe(
            map(response => {
              // temp list
              const list = [];

              // check type
              switch (type) {
                // makes
                case 'makes':
                  // iterate response data
                  response['responseData'].forEach(make => {
                    // push only make to array
                    list.push(make.makeName);
                  });
                  break;
                // models
                case 'models':
                  // iterate response data
                  response['responseData'].rows.forEach(model => {
                    // push only model to array
                    list.push(model.modelName);
                  });
                  break;
                // manufacturers
                case 'manufacturers':
                  // iterate response data
                  response['responseData'].rows.forEach(manufacturer => {
                    // push to array
                    list.push(manufacturer.manufacturerName);
                  });
                  break;
                default:
                  response['responseData'].rows.forEach(model => {
                    // push to array
                    list.push(model.modelName);
                  });
              }
              return list;
            })
          );
      } catch (error) {
        console.log(error.message);
        return error;
      }
    }
  }

  // search Makes
  searchMakes = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => this.searchingMakes = true),
      switchMap(term =>
        this.search(term, 'makes', null).pipe(
          tap(() => this.searchFailedMakes = false),
          catchError(() => {
            this.searchFailedMakes = true;
            return of([]);
          }))
      ),
      tap(() => this.searchingMakes = false)
    )

  // search manufacturer by string handler
  searchModels = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => this.searchingModels = true),
      switchMap(term =>
        this.search(term, 'models', null).pipe(
          tap(() => this.searchFailedModels = false),
          catchError(() => {
            this.searchFailedModels = true;
            return of([]);
          }))
      ),
      tap(() => this.searchingModels = false)
    )

  // search manufacturer by string handler
  searchManufacturers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => this.searchingManufacturers = true),
      switchMap(term =>
        this.search(term, 'manufacturers', null).pipe(
          tap(() => this.searchFailedManufacturers = false),
          catchError(() => {
            this.searchFailedManufacturers = true;
            return of([]);
          }))
      ),
      tap(() => this.searchingManufacturers = false)
    )

  /**
   * @description gets models matching model name || make name || manufacturer name
   */
  getModels() {
    // show notification
    Swal.fire('Please Wait', 'Searching for models...', 'info');

    console.log('searching for models');

    // empty models array
    this.MODELS = [];

    // populate params
    const _params = {};

    _params['pageSize'] = 1000;
    _params['page'] = 1;

    // check if model is null
    if (this.model !== '') {
      _params['modelName'] = this.model;
    }

    // check if make is null
    if (this.make !== '') {
      _params['makeName'] = this.make;
    }

    // check if manufacturer is null
    if (this.manufacturer !== '') {
      _params['manufacturerName'] = this.manufacturer;
    }

    this.http.get(`${environment.apiBaseUrl}/allmodels/findAllDistinctModelsByName`, {
      params: _params
    })
      .subscribe((res) => {
        // get models from response
        this.MODELS = res['responseData'].rows;

        // iterate models
        this.MODELS.forEach((model) => {
          model['isSelected'] = false;

          // check if short list is empty
          if (this.shortList.length > 0) {
            // check if model is in Short list already
            const _flag = this.utilitiesService.checkIfInList(model, this.shortList);

            // check if is in list
            if (_flag.flag) {
              model['isSelected'] = true;
            }
          }
        });

        // close notification
        Swal.close();
      }, (err) => {
        console.log(err);
        Swal.fire('An error has occurred', err['message'], 'error');
      });
  }

  /**
   * @description gets a list of directives fitting specific search criteria
   */
  getAirworthinessDirectives() {
    // check type
    if (this.type === 'airworthiness directives') {
      console.log('searching for directives');

      // show message
      Swal.fire(`Please Wait`, `Searching for directives...`, 'info');

      // clear directives array
      this.directives = [];
    } else {
      console.log('searching for bulletins');

      // show message
      Swal.fire('Please Wait', 'Searching for bulletins...', 'info');

      // clear bulletins array
      this.bulletins = [];
    }

    // iterate short list
    // this.shortList.forEach((model) => {
    //   // remove unnecessary fields
    //   delete model['isSelected'];
    //   delete model['distinct_field'];
    // });

    // create url
    const _url = this.type === 'service bulletins' ? '/serviceBulletins/filterModelsServiceBulletins' : '/airworthinessDirectives/filterModelsAirworthinessDirectives';

    // make http post request
    this.http.post(`${environment.apiBaseUrl + _url}?pageSize=1000&page=1`,
      this.shortList)
      .subscribe((res: any) => {
        // handle results
        // check if there are documents at all
        if (res.responseData.rows !== undefined && res.responseData.rows !== null) {
          // iterate results
          res.responseData.rows.forEach((document) => {
            // add a bool to determine if a document has been configured
            document['configured'] = false;
          });

          console.log(res['responseData'].rows);
        }

        // check type
        if (this.type === 'airworthiness directives') {
          // get directives
          this.directives = this.utilitiesService.sortList(res.responseData.rows, 'directive_name');

          // check if any directives have been returned
          if (this.directives[0] !== undefined && this.directives[0] !== null) {
            // no need to de-dupe; de-dupe already done in backend; copy to editor
            this.editor.documents = this.directives;

            // change active page
            this.activePage = 2;

            // set current directive to the first oen in result set
            this.editor.currentDocumentIndex = 0;

            // set current document
            this.editor.currentDocument = this.editor.documents[this.editor.currentDocumentIndex];
          }
        } else {

          // get bulletins
          this.bulletins = this.utilitiesService.sortList(res.responseData.rows, 'bulletin_name');

          // check if any bulletins have been found
          if (this.bulletins[0] !== undefined && this.bulletins[0] !== null) {
            // no need to de-dupe; de-dupe already done in backend; copy to editor
            this.editor.documents = this.bulletins;

            // change active page
            this.activePage = 2;

            // set current directive to the first oen in result set
            this.editor.currentDocumentIndex = 0;

            // set current document
            this.editor.currentDocument = this.editor.documents[this.editor.currentDocumentIndex];
          }
        }

        // set found message
        this.confirmShortListResult.content = `Found ${this.editor.documents.length} documents`;

        // hide notification
        Swal.close();

        // set pop over title
        this.confirmShortListResult.title = `Document search complete`;

        // show pop over
        Swal.fire(this.confirmShortListResult.title, this.confirmShortListResult.content, 'success');
      }, (err) => {
        console.log(err);
        Swal.fire('An error has occurred', err['message'], 'error');
      });
  }


  /**
   * @description adds a model to short list
   * @param {*} model
   * @param {*} event
   */
  addToShortList(model, ) {
    this.utilitiesService.addToList(model, this.shortList);

    const modelIndex = this.utilitiesService.checkIfInList(model, this.MODELS);
    if(modelIndex.flag){
      this.MODELS[modelIndex.itemIndex].isSelected = true;
    }

    // // check if directive is in short list
    // if (inList.flag) {
    //   if(doAll == 'addAll') return;
    //   // remove from short list
    //   this.utilitiesService.removeFromList(model, this.shortList);
    //   //  toggle isSelected property of model
    //   this.MODELS[index].isSelected = false;
    // } else {
    //   if(doAll == 'clearAll') return;
    //   // add to short list
    //   this.utilitiesService.addToList(model, this.shortList);
    //   //  toggle isSelected property of model
    //   this.MODELS[index].isSelected = true;
    // }
  }

  removeFromShortList(model){
    const modelIndex = this.utilitiesService.checkIfInList(model, this.MODELS);

    this.utilitiesService.removeFromList(model, this.shortList);

    if(modelIndex.flag){
      this.MODELS[modelIndex.itemIndex].isSelected = false;
    }
  }

  addAllToShortList() {
    // loop through models
    this.MODELS.forEach((model, index) => {
      // add model to short list
      this.addToShortList(model);
    });
  }

  clearAllFromShortList() {
    // loop through models
    this.MODELS.forEach((model, index) => {
      // add model to short list
      this.removeFromShortList(model);
    });
  }

  /**
   * @description empties short list and shows toast
   */
  emptyShortList() {
    this.shortList = [];
  }

  /**
   * @description empties model list and shows toast
   */
  emptyModelList() {
    // clear array
    this.MODELS = [];

    // reset variables
    this.make = '';
    this.model = '';
    this.manufacturer = '';
  }


  /**
   * @description changes page to page 2
   */
  confirmShortList() {
    // get count of short list
    const isEmpty = this.shortList.length === 0;

    //  check if short list is empty
    if (isEmpty) {
      console.log('short list is empty');

      // set message
      this.confirmShortListResult.title = 'short list is empty';
      this.confirmShortListResult.content = 'Please try adding more models';
    } else {
      console.log('short list confirmed...');

      // set notification details
      this.confirmShortListResult.content = 'Searching...';
      this.confirmShortListResult.title = 'Fetching airworthiness directives...';

      // get air worthiness directives
      this.getAirworthinessDirectives();
    }
  }

  /**
   * @description blurs html element that triggered event
   * @param {*} event
   */
  blurHTMLElement(event) {

    // check which type head called the event
    if (event.target['id'] === 'typeahead-http-manufacturer') {
      // focus on make
      this.renderer.selectRootElement('#typeahead-http-make').focus();
    } else if (event.target['id'] === 'typeahead-http-make') {
      // focus on model
      this.renderer.selectRootElement('#typeahead-http-model').focus();
    } else {
      // get models list
      this.getModels();
    }

    console.log(event.target['id']);
    event.target.blur();
  }

  /**
   * @description changes current document
   * @param {number} num
   */
  changeDocument(num: number) {

    // add shift number to current document index
    this.editor.currentDocumentIndex += num;

    // check if new current document index is more than the document list length
    // assign 0 if greater
    this.editor.currentDocumentIndex = this.editor.currentDocumentIndex > this.editor.documents.length - 1 ? 0 : this.editor.currentDocumentIndex;

    // check if new current document index is less than 0
    // assign last index if greater
    this.editor.currentDocumentIndex = this.editor.currentDocumentIndex < 0 ? this.editor.documents.length - 1 : this.editor.currentDocumentIndex;

    // set current document
    this.editor.currentDocument = this.editor.documents[this.editor.currentDocumentIndex];

    // reset applicaility form
    this.applicabilityForm.reset();

    // check if document is configured
    if (this.editor.documents[this.editor.currentDocumentIndex].applicabilityForm !== undefined) {
      this.applicabilityForm.patchValue(this.editor.documents[this.editor.currentDocumentIndex].applicabilityForm);
    } else {
      // set defaults
      this.applicabilityForm.controls['condition'].setValue('NA');
      this.applicabilityForm.controls['application'].setValue('Applies');
    }
  }

  /**
   * @description closes the editor and saves the editor object to local storage
   */
  saveStateAndExit() {
    console.log(`Written to local storage: ${JSON.stringify(this.editor)}`);

    // exit editor
    this.closeEditor(true);
  }


  /**
   * @description closes editor
   */
  closeEditor(save: boolean) {
    console.log('close editor');

    // check if saving
    if (save) {
      // save to local storage
      localStorage.setItem('editor', JSON.stringify(this.editor));
    } else {
      console.log('deleting local storage...');

      // clear local storage
      localStorage.removeItem('editor');
    }


    // close active modal
    this.activeModal.close();
  }

  /**
   * @description saves current document configuration
   */
  confirmDocument() {
    // perform validation
    if (this.type === 'airworthiness directives') {
      if (this.applicabilityForm.controls['application'].value == 'Applies') {
        this.applicabilityForm.controls['lastCompliedDate'].setValue(null);
        this.applicabilityForm.controls['notApplyReason'].setValue(null);
      }

      if (this.applicabilityForm.controls['application'].value == 'Complied') {
        this.applicabilityForm.controls['notApplyReason'].setValue(null);
      }

      if (this.applicabilityForm.controls['application'].value == 'NotApply') {
        this.applicabilityForm.controls['lastCompliedDate'].setValue(null);
      }

      if (this.applicabilityForm.controls['application'].value !== 'Complied') {
        this.applicabilityForm.controls['terminateAction'].setValue(false);
        this.applicabilityForm.controls['lastCompliedDate'].setValue(null);
        this.applicabilityForm.controls['lastCompliedHour'].setValue(null);
        this.applicabilityForm.controls['complianceNote'].setValue(null);
      }
    } else {
      if (this.applicabilityForm.controls['application'].value == 'Applies') {
        this.applicabilityForm.controls['lastPerformedDate'].setValue(null);
        this.applicabilityForm.controls['notApplyReason'].setValue(null);
      }

      if (this.applicabilityForm.controls['application'].value == 'Performed') {
        this.applicabilityForm.controls['notApplyReason'].setValue(null);
      }

      if (this.applicabilityForm.controls['application'].value == 'NotApply') {
        this.applicabilityForm.controls['lastPerformedDate'].setValue(null);
      }
      // bug site
      if (this.applicabilityForm.controls['application'].value != 'Performed') {
        this.applicabilityForm.controls['terminateAction'].setValue(false);
        this.applicabilityForm.controls['lastPerformedDate'].setValue(null);
        this.applicabilityForm.controls['lastPerformedHour'].setValue(null);
        this.applicabilityForm.controls['performanceNote'].setValue(null);
      }
    }


    // check if form is valid
    if (this.applicabilityForm.invalid) {
      // string for errors
      let errors = '<br>';

      // iterate controls
      for (const control in this.applicabilityForm.controls) {
        // check if control is invalid
        if (this.applicabilityForm.controls[control].invalid) {
          // mark as dirty
          this.applicabilityForm.controls[control].markAsDirty();

          let _control = '';

          [...control.split(/(?=[A-Z])/)].forEach((word => {
            _control += `${word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()} `;
          }));

          // add to errors array
          errors += `<span style="color:red; font-weight: bold;">${_control}</span><br>`;
        }
      }

      Swal.fire('Check important fields!', `Affected fields: ${errors}`, 'error');
      return;
    } else {

      console.log(`save configuration for document: ${this.editor.currentDocumentIndex}`);

      // add document config form to current document object
      this.editor.documents[this.editor.currentDocumentIndex]['applicabilityForm'] = this.applicabilityForm.value;

      // check if the document is already configured
      if (!this.editor.currentDocument['configured']) {
        // set configured to true
        this.editor.documents[this.editor.currentDocumentIndex]['configured'] = true;
      }


      // clear form
      this.applicabilityForm.reset();

      this.updateConfiguredDocumentsCount();

      // navigate to next document
      this.changeDocument(1);

      return;
    }
  }

  /**
   * Reset deocument to blank state
   *
   */
  resetDocument(){
    this.editor.currentDocument.applicabilityForm = undefined;
    this.editor.currentDocument['configured'] = false;
    this.editor.documents[this.editor.currentDocumentIndex]['configured'] = false;
    this.applicabilityForm.reset();
    this.updateConfiguredDocumentsCount()
  }

  /**
   * @description removes document from documents list
   */
  discardDocument(event) {
    event.preventDefault();

    Swal.fire({
      title: 'Warning',
      text: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Go ahead!'
    })
      .then((result) => {
        // check if value if positive
        if (result.value) {
          console.log(`remove document from short list: ${JSON.stringify(this.editor.currentDocumentIndex)}`);
          // store previous index
          const previousDocumentIndex = this.editor.currentDocumentIndex > 0? this.editor.currentDocumentIndex  - 1 : 0 ;

          if (this.editor.documents.length > 1) {
            // check if document is configured
            if (this.editor.documents[this.editor.currentDocumentIndex]['applicabilityForm'] !== undefined) {
              console.log('deleting configured document');
            }

            // remove current document from index
            this.editor.documents.splice(this.editor.currentDocumentIndex, 1);

            this.editor.currentDocumentIndex = this.editor.documents.length <= this.editor.currentDocumentIndex?  previousDocumentIndex : this.editor.currentDocumentIndex;
            this.changeDocument(0);

            // update configured document count
            this.updateConfiguredDocumentsCount();
          } else {
            Swal.fire('Error', 'Cannot delete the only document. Try restarting workflow instead and using different search parameters...', 'error');
          }
        }
      });

  }

  /**
   * @description go to confirm configuration page
   */
  confirmDocumentConfiguration() {
    // check if configured document count is 0
    if (this.editor.configuredDocumentCount > 0) {
      // check if configured documents is less than total documents
      if (this.editor.configuredDocumentCount === this.editor.documents.length) {
        // chnage active page
        this.activePage = 3;
      } else {
        Swal.fire({
          title: 'Warning',
          text: `You have only configured ${this.editor.configuredDocumentCount} out of ${this.editor.documents.length} documents. Do you want to proceed?`,
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Go ahead!'
        })
          .then((result) => {
            if (result.value) {
              // change active page
              this.activePage = 3;
            }
          });
      }
    } else {
      Swal.fire('Cannot Proceed', 'You have not configured any documents', 'error');
    }
  }

  /**
   * @description go back to document configuration page
   */
  backToDocumentConfiguration() {
    // go nback to config page
    this.activePage = 2;
  }

  AttachDocuments() {
    // loop thru documents array
    this.editor.documents.forEach((document) => {
      // get applicability form
      const request = document['applicabilityForm'];

      if (request !== undefined) {
        // log object
        const log = {
          message: null,
          success: null,
          reason: null
        };

        if (this.type === 'airworthiness directives') {
          // add required attributes to document
          request['aircraftId'] = this.aircraft.id;
          request['directiveId'] = document.directive_id;

          // remove unnecessary attributes
          delete request['effectiveDate'];
        } else {
          // add required attributes to document
          request['aircraftId'] = this.aircraft.id;
          request['bulletinId'] = document.bulletin_id;
        }

        // write config
        // this.airworthinessDirectiveService.addAircraftAirworthinessDirective(request)
        const _url = this.type === 'airworthiness directives' ? '/airworthinessDirectives/addAircraftAirworthinessDirective' : '/servicebulletins/addAircraftServiceBulletin';

        console.log(request);

        this.http.post(environment.apiBaseUrl + _url, request)
          .subscribe((res) => {
            console.log(res);
            log.reason = res['status']['message'];
            log.message = this.type === 'airworthiness directives' ? `Airworthiness Directive: "${document.directive_name}", attached to: ${this.aircraft.tailNo}.` : `Service Bulletin: "${document.bulletin_name}", attached to: ${this.aircraft.tailNo}.`;

            // check if status code is 1000
            if (res['status']['code'] == 1000) {
              // check document type
              log.success = true;
            } else {
              // tslint:disable-next-line:max-line-length
              log.message = this.type === 'airworthiness directives' ? `Failed to attach Airworthiness Directive: "${document.directive_name}", to: ${this.aircraft.tailNo} .` : `Failed to attach Service Bulletin: "${document.bulletin_name}", to aircraft: ${this.aircraft.tailNo} .`;
              log.success = false;
            }

          }, (error) => {

            log.message = this.type === 'airworthiness directives' ? `Failed to attach Airworthiness Directive: "${document.directive_name}", to: ${this.aircraft.tailNo} .` : `Failed to attach Service Bulletin: "${document.bulletin_name}", to aircraft: ${this.aircraft.tailNo}`;
            log.success = false;

            const errorResponse = error.error ? error.error : error;
            if (errorResponse.status.message) {
              log.reason = errorResponse['responseData'][0];
            } else {
              // create log
              log.reason = 'Unknown error';
            }
          });

        // add log to attachment logs array
        this.editor.attachmentLogs.push(log);
      }
    });
    // change active page to 4
    this.activePage = 4;
  }

  /**
   * @description AD/SB configuration form validations (copied from biola's code
   */
  initNewDirectiveForm() {
    // check document type
    if (this.type == 'airworthiness directives') {
      // applicability forms
      this.applicabilityForm = this.formBuilder.group({
        // 'effectiveDate': new FormControl(null, [Validators.required]),
        'dueDate': new FormControl(),
        'dueHours': new FormControl(),
        'condition': new FormControl('NA'),
        'application': new FormControl('Applies'),
        'isRecurring': new FormControl(),
        'notApplyReason': new FormControl(),
        'lastCompliedDate': new FormControl(),
        'lastCompliedHour': new FormControl(),
        'terminateAction': new FormControl(false),
        'complianceNote': new FormControl(),
      });

      // ?
      this.applicabilityForm.get('lastCompliedDate').setValidators([
        DependsOnControlIfControlIsValidator(this.applicabilityForm.get('application'), 'Complied')
      ]);

      // ?
      this.applicabilityForm.get('application').setValidators([
        Validators.required,
        HasDependantIfControlIsControlValidator(this.applicabilityForm.get('lastCompliedDate'), 'Complied'),
        HasDependantIfControlIsControlValidator(this.applicabilityForm.get('notApplyReason'), 'NotApply')
      ]);

      // ?
      this.applicabilityForm.get('notApplyReason').setValidators([
        DependsOnControlIfControlIsValidator(this.applicabilityForm.get('application'), 'NotApply')
      ]);

      // ?
      this.applicabilityForm.get('dueDate').setValidators([
        OneOfControlsIsNeededIgnoreIfAnotherControlIsValueValidator(
          this.applicabilityForm.get('application'),
          'NotApply',
          [this.applicabilityForm.get('dueHours')],
          [this.applicabilityForm.get('terminateAction')]
        )
      ]);

      // ?
      this.applicabilityForm.get('dueHours').setValidators([
        OneOfControlsIsNeededIgnoreIfAnotherControlIsValueValidator(
          this.applicabilityForm.get('application'),
          'NotApply',
          [this.applicabilityForm.get('dueDate')],
          [this.applicabilityForm.get('terminateAction')]
        ),
        NumberToTenths(true)
      ]);

      // ?
      this.applicabilityForm.get('lastCompliedHour').setValidators([,
        NumberToTenths(true)
      ]);

      // ?
      this.applicabilityForm.get('condition').setValidators([
        Validators.required,
        AllControlsNeededIfValueIsValidator(
          [
            this.applicabilityForm.get('dueDate'),
            this.applicabilityForm.get('dueHours')
          ],
          'WhichOccursFirst'
        ),
        AllControlsNeededIfValueIsValidator(
          [
            this.applicabilityForm.get('dueDate'),
            this.applicabilityForm.get('dueHours')
          ],
          'WhichHappensLast'
        )
      ]);

      // ?
      this.newDueHoursSubscriber$ = this.applicabilityForm.get('dueHours').valueChanges
        .pipe(
          distinctUntilChanged()
        )
        .subscribe(
          (value: any) => {
            this.applicabilityForm.get('condition').updateValueAndValidity();
          }
        );

      // ?
      this.newDueDateSubscriber$ = this.applicabilityForm.get('dueDate').valueChanges
        .pipe(
          distinctUntilChanged()
        ).subscribe(
          (value: any) => {
            this.applicabilityForm.get('condition').updateValueAndValidity();
          }
        );

      // ?
      this.newTerminatingActionSubscriber$ = this.applicabilityForm.get('terminateAction').valueChanges
        .pipe(
          distinctUntilChanged()
        )
        .subscribe(
          (value: any) => {
            this.applicabilityForm.get('dueHours').updateValueAndValidity();
            this.applicabilityForm.get('dueDate').updateValueAndValidity();
          }
        );

      // ?
      this.newApplicationSubscriber$ = this.applicabilityForm.get('application').valueChanges
        .pipe(
          distinctUntilChanged()
        )
        .subscribe(
          (value: any) => {
            this.applicabilityForm.get('dueHours').updateValueAndValidity();
            this.applicabilityForm.get('dueDate').updateValueAndValidity();

            if (value == 'NotApply') {
              this.applicabilityForm.get('condition').disable();
              this.applicabilityForm.get('isRecurring').disable();
              this.applicabilityForm.get('isRecurring').setValue(false);
            } else {
              this.applicabilityForm.get('condition').enable();
              this.applicabilityForm.get('isRecurring').enable();
            }

            if (value !== 'Complied') {
              this.applicabilityForm.get('terminateAction').value ? this.applicabilityForm.get('terminateAction').setValue(false) : null;
              this.applicabilityForm.get('lastCompliedDate').value ? this.applicabilityForm.get('lastCompliedDate').setValue(null) : null;
              this.applicabilityForm.get('lastCompliedHour').value ? this.applicabilityForm.get('lastCompliedHour').setValue(null) : null;
              this.applicabilityForm.get('complianceNote').value ? this.applicabilityForm.get('complianceNote').setValue(null) : null;
            }
          }
        );

      // ?
      this.newAirDirectiveFormSubscriber$ = this.applicabilityForm.valueChanges
        .pipe(
          distinctUntilChanged()
        )
        .subscribe(
          (value: any) => {

            if (this.applicabilityForm.get('terminateAction').value && (this.applicabilityForm.get('condition').value !== 'NA')) {
              this.applicabilityForm.get('condition').setValue('NA');
            }

            if ((this.applicabilityForm.get('application').value == 'NotApply') && (this.applicabilityForm.get('condition').value !== 'NA')) {
              this.applicabilityForm.get('condition').setValue('NA');
            }
          }
        );

    } else {
      // applicability forms
      this.applicabilityForm = this.formBuilder.group({
        'dueDate': new FormControl(null),
        'dueHours': new FormControl(null),
        'condition': new FormControl('NA'),
        'application': new FormControl('Applies'),
        'isRecurring': new FormControl(''),
        'notApplyReason': new FormControl(''),
        'lastPerformedDate': new FormControl(null), // *
        'lastPerformedHour': new FormControl(''), // *
        'terminateAction': new FormControl(false),
        'performanceNote': new FormControl(''), // *
      });

      this.applicabilityForm.get('lastPerformedDate').setValidators([
        DependsOnControlIfControlIsValidator(this.applicabilityForm.get('application'), 'Performed')
      ]);

      this.applicabilityForm.get('application').setValidators([
        Validators.required,
        HasDependantIfControlIsControlValidator(this.applicabilityForm.get('lastPerformedDate'), 'Performed'),
        HasDependantIfControlIsControlValidator(this.applicabilityForm.get('notApplyReason'), 'NotApply')
      ]);

      this.applicabilityForm.get('notApplyReason').setValidators([
        DependsOnControlIfControlIsValidator(this.applicabilityForm.get('application'), 'NotApply')
      ]);

      this.applicabilityForm.get('dueDate').setValidators([
        OneOfControlsIsNeededIgnoreIfAnotherControlIsValueValidator(
          this.applicabilityForm.get('application'),
          'NotApply',
          [this.applicabilityForm.get('dueHours')],
          [this.applicabilityForm.get('terminateAction')]
        )
      ]);

      this.applicabilityForm.get('dueHours').setValidators([
        OneOfControlsIsNeededIgnoreIfAnotherControlIsValueValidator(
          this.applicabilityForm.get('application'),
          'NotApply',
          [this.applicabilityForm.get('dueDate')],
          [this.applicabilityForm.get('terminateAction')]
        ),
        NumberToTenths(true)
      ]);

      this.applicabilityForm.get('lastPerformedHour').setValidators([,
        NumberToTenths(true)
      ]);

      this.applicabilityForm.get('condition').setValidators([
        Validators.required,
        AllControlsNeededIfValueIsValidator(
          [
            this.applicabilityForm.get('dueDate'),
            this.applicabilityForm.get('dueHours')
          ],
          'WhichOccursFirst'
        ),
        AllControlsNeededIfValueIsValidator(
          [
            this.applicabilityForm.get('dueDate'),
            this.applicabilityForm.get('dueHours')
          ],
          'WhichHappensLast'
        )
      ]);

      this.newDueHoursSubscriber$ = this.applicabilityForm.get('dueHours').valueChanges
        .pipe(
          distinctUntilChanged()
        )
        .subscribe(
          (value: any) => {
            this.applicabilityForm.get('condition').updateValueAndValidity();
          }
        );

      this.newDueDateSubscriber$ = this.applicabilityForm.get('dueDate').valueChanges
        .pipe(
          distinctUntilChanged()
        ).subscribe(
          (value: any) => {
            this.applicabilityForm.get('condition').updateValueAndValidity();
          }
        );

      this.newTerminatingActionSubscriber$ = this.applicabilityForm.get('terminateAction').valueChanges
        .pipe(
          distinctUntilChanged()
        )
        .subscribe(
          (value: any) => {
            this.applicabilityForm.get('dueHours').updateValueAndValidity();
            this.applicabilityForm.get('dueDate').updateValueAndValidity();
          }
        );

      this.newApplicationSubscriber$ = this.applicabilityForm.get('application').valueChanges
        .pipe(
          distinctUntilChanged()
        )
        .subscribe(
          (value: any) => {
            this.applicabilityForm.get('dueHours').updateValueAndValidity();
            this.applicabilityForm.get('dueDate').updateValueAndValidity();

            if (value == 'NotApply') {
              this.applicabilityForm.get('condition').disable();
              this.applicabilityForm.get('isRecurring').disable();
              this.applicabilityForm.get('isRecurring').setValue(false);
            } else {
              this.applicabilityForm.get('condition').enable();
              this.applicabilityForm.get('isRecurring').enable();
            }

            if (value !== 'Performed') {
              this.applicabilityForm.get('terminateAction').value ? this.applicabilityForm.get('terminateAction').setValue(false) : null;
              this.applicabilityForm.get('lastPerformedDate').value ? this.applicabilityForm.get('lastPerformedDate').setValue(null) : null;
              this.applicabilityForm.get('lastPerformedHour').value ? this.applicabilityForm.get('lastPerformedHour').setValue(null) : null;
              this.applicabilityForm.get('performanceNote').value ? this.applicabilityForm.get('performanceNote').setValue(null) : null;
            }
          }
        );

      this.newServiceBulletinFormSubscriber$ = this.applicabilityForm.valueChanges
        .pipe(
          distinctUntilChanged()
        )
        .subscribe(
          (value: any) => {

            if (this.applicabilityForm.get('terminateAction').value && (this.applicabilityForm.get('condition').value !== 'NA')) {
              this.applicabilityForm.get('condition').setValue('NA');
            }

            if ((this.applicabilityForm.get('application').value == 'NotApply') && (this.applicabilityForm.get('condition').value !== 'NA')) {
              this.applicabilityForm.get('condition').setValue('NA');
            }
          }
        );
    }
  }


  /**
   * @description returns the number of configured documents
   */
  updateConfiguredDocumentsCount() {
    // variable to store configured documents count
    this.editor.configuredDocumentCount = 0;

    // iterate directives array
    this.editor.documents.forEach((document) => {
      //  check if configured
      if (document['configured'] === true) {
        this.editor.configuredDocumentCount += 1;
      }
    });

    console.log(`configured document count: ${this.editor.configuredDocumentCount}`);
  }

  makeClean(event) {
    // console.log(event.target)
    if (event.target.value === '') {
      console.log(this.applicabilityForm.controls[event.target['name']]);
    }
  }

  /**
   * @description sets due date to today's date for the current document config
   */
  dueNow() {
    // get current datetime
    const date = new Date();

    // set due date to current date
    this.applicabilityForm.controls['dueDate'].setValue(date);
  }



  getSixMonthsPrior(dateString: string){
    return this.utilitiesService.sixMonthsPrior(dateString)
  }
}

// TODO: check if fields are filled in on blur, if not, reset dirty status to false
// add a bage to show configured documents on the work flow



