<h1>Bulk bulletin upload</h1>
<div>
  <form [formGroup] = "uploadForm" (ngSubmit)="onSubmit()">
    <div>
      <input type="file" name="uploads" (change)="onFileSelect($event)" />
    </div>
    <div>
      <button type="submit">Upload</button>
    </div>
  </form>
</div>


<h1>Bulk bulletin upload URL</h1>
<div>
  <form [formGroup] = "uploadFormWithUrl" (ngSubmit)="onSubmitCsvUrl()">
    <div>
      <input type="text" name="url" formControlName="url" />
    </div>
    <div>
      <button type="submit">Upload With Url</button>
    </div>
  </form>
</div>
