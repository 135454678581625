import { AuthService } from '../../../../services/auth.service';
import { UserAircraftModel } from '../../../../models/user-aircraft-model';
import { AircraftService } from '../../../../services/aircraft.service';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl
} from '@angular/forms';
import { UtilitiesService } from '../../../../services/utilities.service';
import { UserService } from '../../../../services/user.service';
import { SubscriptionLike } from 'rxjs';
import { Component, OnInit, OnDestroy, Input, Output, SimpleChanges, OnChanges, EventEmitter } from '@angular/core';
import { UserModel } from '../../../../models/user-model';
import { DependsOnControlIfControlIsValidator } from '../../../../_custom-validators/depends-on-control-if-control-is-validator';
import { MustBeSameAsControValidator } from '../../../../_custom-validators/must-be-same-as-control-validator';
import { HasDependantIfControlIsControlValidator } from '../../../../_custom-validators/has-dependant-if-control-is-validator';
import Swal from 'sweetalert2';
import { AlertPopUpsService } from '../../../../services/alert-pop-ups.service';

@Component({
  selector: 'app-view-edit-member-profile',
  templateUrl: './view-edit-member-profile.component.html',
  styleUrls: ['./view-edit-member-profile.component.scss']
})
export class ViewEditMemberProfileComponent implements OnInit, OnDestroy, OnChanges {
  @Input() userProfile: UserModel;
  @Output() refetchUserEmitter: EventEmitter<boolean> = new EventEmitter();

  currentUser$: SubscriptionLike;
  currentUser: UserModel = new UserModel();

  isInEditMode: boolean;

  editUserForm: FormGroup;

  userAircraft: Array<UserAircraftModel> = [];

  userAircraftCount: number;

  doPasswordUpdateSubscriber$: SubscriptionLike;

  isWorking: boolean;

  // isWorkingPasswordReset: boolean = false;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private aircraftService: AircraftService,
    private utilsService: UtilitiesService,
    private popUpService: AlertPopUpsService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isInEditMode = false;
    this.isWorking = false;

    this.currentUser$ = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });

    this.initEditForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if  (changes.userProfile && changes.userProfile.currentValue) {
      this.userProfile = changes.userProfile.currentValue;
      this.cancelEditForm();
    }
  }

  initEditForm() {
    // create edit formgroup
    this.editUserForm = this.formBuilder.group({
      firstName: new FormControl(
        null,
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z][A-Za-z ,.'-]+$/)
        ]
      ),
      lastName: new FormControl(
        null,
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z][A-Za-z ,.'-]+$/)
        ]
      ),
      emailAddress: new FormControl(
        null,
        [
          Validators.required,
          Validators.email
        ]
      ),
      mobilePhone: new FormControl(
        null,
        [
          // Validators.required,
          // Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
          Validators.pattern(/^\+?[1-9]\d{1,14}$/)
        ]
      ),
      pilotLicenseNo: new FormControl(
        null,
        [
          Validators.minLength(4),
          Validators.maxLength(8)
        ]
      ),
      isCrewChiefAdmin: new FormControl(
        false
      ),
      doPasswordUpdate: new FormControl(null),
      password1: new FormControl(null),
      password2: new FormControl(null)
    });

    this.editUserForm
      .get('password1')
      .setValidators([
        DependsOnControlIfControlIsValidator(
          this.editUserForm.get('doPasswordUpdate'),
          true
        ),
        Validators.pattern(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.[(!@#\$%\^&\*)*]?)(?=.{6,})/
        ),
        MustBeSameAsControValidator(this.editUserForm.get('password2'), false)
      ]);

    this.editUserForm
      .get('password2')
      .setValidators([
        MustBeSameAsControValidator(this.editUserForm.get('password1'))
      ]);

    this.editUserForm
      .get('doPasswordUpdate')
      .setValidators([
        HasDependantIfControlIsControlValidator(
          this.editUserForm.get('password1'),
          true
        )
      ]);

    this.doPasswordUpdateSubscriber$ = this.editUserForm
      .get('doPasswordUpdate')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((value: any) => {
        if (!value) {
          this.editUserForm.get('password1').setValue(null);

          this.editUserForm.get('password2').setValue(null);
        }

        this.editUserForm.get('password1').updateValueAndValidity();
        this.editUserForm.get('password2').updateValueAndValidity();
      });
  }

  loadEditForm(editedProfile: UserModel) {
    this.isInEditMode = true;
    // create edit formgroup
    this.editUserForm.controls['firstName'].setValue(editedProfile.firstName);
    this.editUserForm.controls['lastName'].setValue(editedProfile.lastName);
  
    this.editUserForm.controls['emailAddress'].setValue(editedProfile.emailAddress);
    this.editUserForm.controls['mobilePhone'].setValue(editedProfile.mobilePhone);
    this.editUserForm.controls['pilotLicenseNo'].setValue(editedProfile.pilotLicenseNo);

    this.editUserForm.controls['isCrewChiefAdmin'].setValue(editedProfile.isAdmin);

    this.editUserForm.controls['doPasswordUpdate'].setValue(false);

    this.editUserForm.controls['password1'].setValue(null);
    this.editUserForm.controls['password2'].setValue(null);
  }

  cancelEditForm() {
    if (this.editUserForm) {
      this.editUserForm.reset();
    }
    this.isInEditMode = false;
  }

  refetchUser() {
    // this.isWorking = true;
    this.refetchUserEmitter.emit(true);
  }

  getUserAircrafts() {
    this.aircraftService.getUserAircraft(this.userProfile.id).subscribe(
      (response: any) => {
        this.userAircraft = response.responseData.rows;
        this.userAircraftCount = response.responseData.count;
      },
      error => {}
    );
  }

  doUpdateUser() {
    if (!this.editUserForm.valid) {

      Object.values(this.editUserForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      Swal.fire(
        'Check important fields!',
        'Form not filled correctly!',
        'error'
      );
      return;
    }

    this.userProfile.firstName = this.editUserForm.controls['firstName'].value;
    this.userProfile.lastName = this.editUserForm.controls['lastName'].value;
    this.userProfile.emailAddress = this.editUserForm.controls[
      'emailAddress'
    ].value;
    this.userProfile.mobilePhone = this.editUserForm.controls[
      'mobilePhone'
    ].value;
    this.userProfile.pilotLicenseNo = this.editUserForm.controls[
      'pilotLicenseNo'
    ].value;
    this.userProfile.isAdmin = this.editUserForm.controls[
      'isCrewChiefAdmin'
    ].value;

    if (this.editUserForm.get('doPasswordUpdate').value === true) {
      this.userProfile['newPassword'] = this.editUserForm.get(
        'password1'
      ).value;
    }

    this.isWorking = true;

    this.userService.createOrUpdateUser(this.userProfile).subscribe(
      (response: any) => {
        this.isWorking = false;

        this.isInEditMode = false;
        this.refetchUser();

        Swal.fire('Great!', 'Profile Updated', 'success').then(() => {
          this.userService.refetchCurrentUser();
        });
      },
      (error: any) => {
        this.isWorking = false;
        Swal.fire('Oops!', 'Profile update failed.', 'error');
      }
    );
  }

  requestPasswordReset() {
    this.popUpService.openConfirmPopUp(
      'Confirm sending a password reset link?',
      `This will send a password reset link to  ${this.userProfile.firstName} ?`,
      'warning',
      true,
      'Yes. Go ahead!',
      'No, cancel this.'
    )
    .then((descision) => {
      if (descision.value) {
        this.isWorking = true;
        this.authService.forgotPassword(this.userProfile.emailAddress).subscribe(
          (response: any) => {
            this.isWorking = false;
            if (+response.status.code === 1000) {
              this.popUpService.openBasicPopUp(
                'Sent!',
                `Reset password link sent to ${this.userProfile.emailAddress}.`,
                'success'
              );
            } else {
              this.popUpService.openBasicPopUp(
                'Sorry!',
                'Failed to send reset password link.',
                'error'
              );
            }
          },
          (error: any) => {
            this.isWorking = false;
            if (error.status.message) {
              Swal.fire(
                error.status.message,
                error.responseData.join(' '),
                'error'
              );
            } else {
              this.popUpService.openBasicPopUp(
                'Oops!',
                'Failed to send reset password link.',
                'error'
              );
            }
          }
        );
      } else if (descision.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });

  }

  formatPhoneNumber(phoneNumber: string) {
    return this.utilsService.formatPhoneNumber(phoneNumber);
  }

  ngOnDestroy() {
    if (this.doPasswordUpdateSubscriber$) {
      this.doPasswordUpdateSubscriber$.unsubscribe();
    }

    if (this.currentUser$) {
      this.currentUser$.unsubscribe();
    }
  }
}
