import { Component, OnInit, Input } from '@angular/core';
import { BreadcrumbNavModel } from '../../../app/models/breadcrumb-nav-model';

@Component({
  selector: 'app-breadcrumbs-nav',
  templateUrl: './breadcrumbs-nav.component.html',
  styleUrls: ['./breadcrumbs-nav.component.scss']
})
export class BreadcrumbsNavComponent implements OnInit {
  @Input() navItems: Array<BreadcrumbNavModel>;

  constructor() { }

  ngOnInit() {
  }

}
