import { OrganizationBaseModel } from './organization-base-model';
import { UserAircraftCurrentStatusModel } from './user-aircraft-current-status-model';
import { AircraftModel } from './aircraft-model';
import { PropellerModel } from './propeller-model';
import { UserBaseModel } from './user-base-model';
import { EngineModel } from './engine-model';

export class UserAircraftModel{
  constructor(){
    this.id = null;
    this.user_id = null;
    this.user = new UserBaseModel;
    this.aircraft_model = new AircraftModel;
    this.engine_model = new EngineModel;
    this.propeller_model = new PropellerModel;
    this.currentStatus = new UserAircraftCurrentStatusModel;
  }

  id: number;
  tailNo: string;
  airframeSn: string;
  airframeTtis: number;
  engineSn: string;
  initialEngineHours: number;
  engineInstalledAirframeTime: number;
  initialLastEngineOh: number;

  propellerSn: string;
  initLastPropellerOh: number;
  inititialPropellerHours: number;
  propellerInstalledAirframeTime: number;


  avionicsEquipments: string;
  otherEquipments: string;
  createdAt: string;
  updatedAt: string;
  user_id: number;
  propeller_model_id: number;
  engine_model_id: number;
  aircraft_model_id: number;


  aircraftLogbookState: string;
  engineLogbookState: string;
  propellerLogbookState: string;
  adComplianceReportState: string;
  adminApproved: boolean;


  registrationDueDate?: string;
  insuranceDueDate?: string;


  user?: UserBaseModel;

  engine_model: EngineModel;
  propeller_model: PropellerModel;
  aircraft_model: AircraftModel;

  currentStatus: UserAircraftCurrentStatusModel;

  organizations?: Array<OrganizationBaseModel>;
}
