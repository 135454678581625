


<ng-container>
  <ng-container *ngIf="this.selectedOrg.organization.id !== null">
    <ng-container *ngIf="!checkIfCanManagePeople()">
      <div class="alert alert-warning">
        You do not have the rights needed to manage members of this organization
      </div>
    </ng-container>

    <ng-container *ngIf="checkIfCanManagePeople()">
      <section class="mb-3 border-0 admin-console-search">
        <button class="btn btn-primary w-100" (click)="initAddPersonModal(addPersonModalContent)">
          <i class="la la-plus-circle"></i> Add Person to Organization
        </button>
      </section>


      <div class="card border-0 bg-transparent">
        <div class="card-header">
          <span *ngIf="filterQuery">Search Results for <b>{{filterQuery}}</b></span>
          <span *ngIf="!filterQuery">Total People: <b>{{allPeople.length}}</b></span>
        </div>

        <div class="card-body p-0">

          <!-- <div class="form-group" [hidden]="isWorking && !filterQuery">
            <form [formGroup]="filterPeopleForm" (ngSubmit)="filterOrgPeopleByNameEmailOrMobile()">
              <div class="input-group">
                <input type="text" formControlName="filter" class="form-control" id="filter" placeholder="Filter by person's name, email, or mobile no.">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="submit" id="search_by_name">
                    <i class="la la-search"></i>
                  </button>
                </div>
              </div>
              <small id="aircraft_help" class="form-text text-muted">Person's Name, Email, Aircraft Tail No, or Mobile phone no.</small>
            </form>
          </div> -->

          <app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

          <ng-container *ngIf="!isWorking">
            <div>
              <table class="table table-responsive spaced_rows">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Mobile #</th>
                    <th scope="col">Medical Due</th>
                    <th scope="col">Flight Review Due</th>
                    <th scope="col">Role</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <!--- People rows-->
                  <tr *ngFor="let person of allPeople; let i = index;"
                  id="{{'row_' + person.user.emailAddress}}">
                    <th scope="row">{{i+1}}</th>
                    <td>
                      {{person.user?.firstName}} {{person.user?.lastName}}
                      <small class="d-block text-muted">
                        <i class="la la-envelope text-primary"></i>
                        {{person.user?.emailAddress}}
                      </small>
                    </td>
                    <td>
                      {{person.user? formatPhoneNumber(person.user.mobilePhone) : ''}}
                    </td>
                    <td>
                      {{person?.user?.medicalDueDate | date}}
                    </td>
                    <td>
                      {{person?.user?.flightReviewDueDate | date}}
                    </td>
                    <td>
                      <ng-container *ngIf="
                      +updatedPerson.user.id
                      !== +person.user.id">
                        {{person?.role }}
                        <small class="d-block text-muted">
                          Added: {{person.user?.createdAt | date}}
                        </small>
                      </ng-container>

                      <ng-container *ngIf="
                      updatedPerson.user.id
                      && +updatedPerson.user.id
                      === +person.user.id">
                        <form [formGroup]="updatePersonForm" (ngSubmit)="doAddPersonToGroup('update')" *ngIf="!isUpdatingUser">
                          <div class="form-group mb-0">
                            <div class="input-group">
                              <select
                              class="form-control
                              {{
                                (updatePersonForm.get('personRole').invalid
                                  && (updatePersonForm.get('personRole').touched
                                    || updatePersonForm.get('personRole').dirty
                                    )
                                )
                                ? 'border-danger'
                                : 'border-success'}}"
                              formControlName="personRole" id="personRole"  placeholder="Select role">
                                <ng-container *ngFor="let role of orgRoleOptions.options; let i = index">
                                  <option [value]="role.value" *ngIf="userCanAssignThisRole(role.value)">
                                    {{role.label}}
                                  </option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </form>
                      </ng-container>
                    </td>
                    <td>
                      <ng-container *ngIf="person.user.emailAddress !== currentUser.emailAddress">

                        <div *ngIf="!userCanEditThisPerson(person.role)">
                          <button class="btn btn-outline-dark rounded w-100" disabled>
                              <i class="la la-unlock"></i> ADMIN
                          </button>
                        </div>

                        <div *ngIf="userCanEditThisPerson(person.role)">
                          <div *ngIf="!isUpdatingUser">
                            <!--Edit button-->
                            <a (click)="loadPersonForUpdate(person)" class="btn btn-sm btn-outline-primary rounded mr-2"
                            *ngIf="
                            +updatedPerson.user.id
                            !== +person.user.id">
                                <i class="la la-edit"></i> Edit Person
                            </a>

                            <!--Save/cancel button-->
                            <ng-container *ngIf="
                              updatedPerson.user.id
                              && +updatedPerson.user.id
                              === +person.user.id">
                              <a (click)="doAddPersonToGroup('update')" class="btn btn-sm btn-success rounded mr-2">
                                  <i class="la la-save"></i> Save
                              </a>

                              <a (click)="unloadPersonForUpdate()" class="btn btn-danger rounded mr-2">
                                  <i class="la la-times text-white"></i>
                              </a>
                            </ng-container>

                            <!--Detach button-->
                            <a (click)="detachOrgPerson(person)" class="btn btn-outline-danger rounded">
                              <i class="la la-trash"></i>
                            </a>
                          </div>

                          <app-preloader-type-a *ngIf="isUpdatingUser &&
                            (+updatedPerson.user.id
                            == +person.user.id)
                          "></app-preloader-type-a>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="person.user.emailAddress.toLowerCase() === currentUser.emailAddress.toLowerCase()">
                        <button class="btn btn-outline-dark rounded w-100" disabled>
                            <i class="la la-unlock"></i> YOU
                        </button>
                      </ng-container>

                    </td>
                  </tr>

                </tbody>
              </table>
            </div>

            <div class="d-flex justify-content-between p-2">
              <ngb-pagination
                [collectionSize]="allPeople.length" [(page)]="page" [pageSize]="pageSize" (pageChange)="getUsersByOrganization()">
              </ngb-pagination>
            </div>

          </ng-container>

        </div>
      </div>


      <!--Add person to organization modal-->
      <ng-template #addPersonModalContent let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Add Person to Organization</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <app-preloader-type-a *ngIf="isAddingUser"></app-preloader-type-a>

          <form [formGroup]="addPersonForm" (ngSubmit)="doAddPersonToGroup()" *ngIf="!isAddingUser">
            <div class="row">
              <div class="column col-md-6 col-sm-12">
                <div class="form-group">
                  <small class="form-text text-muted required">First Name</small>
                  <div class="input-group">
                    <input class="form-control" formControlName="firstName"  placeholder="First Name" name="firstName">
                  </div>
                  <small class="text-danger d-block" *ngIf="addPersonForm.get('firstName').invalid && (addPersonForm.get('firstName').touched || addPersonForm.get('firstName').dirty)">
                    Enter valid first Name
                  </small>
                </div>
              </div>

              <div class="column col-md-6 col-sm-12">
                <div class="form-group">
                  <small class="form-text text-muted required">Last Name</small>
                  <div class="input-group">
                    <input class="form-control" formControlName="lastName"  placeholder="First Name" name="firstName">
                  </div>
                  <small class="text-danger d-block" *ngIf="addPersonForm.get('lastName').invalid && (addPersonForm.get('lastName').touched || addPersonForm.get('lastName').dirty)">
                    Enter valid last Name
                  </small>
                </div>
              </div>
            </div>

            <div class="form-group">
              <small class="form-text text-muted required">New Person Email</small>
              <div class="input-group">
                <input class="form-control" formControlName="personEmail"  placeholder="Email address" name="personEmail">
              </div>
              <small class="text-danger d-block" *ngIf="addPersonForm.get('personEmail').invalid && (addPersonForm.get('personEmail').touched || addPersonForm.get('personEmail').dirty)">
                Enter valid email address
              </small>
            </div>


            <div class="row">

              <div class="column col-md-12 col-sm-12">
                <div class="form-group">
                  <small class="form-text text-muted required">Select Role</small>
                  <div class="input-group">
                    <select class="form-control" formControlName="personRole" id="personRole"  placeholder="Select role">
                      <option *ngFor="let role of orgRoleOptions.options; let i = index" [value]="role.value">
                        {{role.label}}
                      </option>
                    </select>
                  </div>
                  <small class="text-danger d-block" *ngIf="addPersonForm.get('personRole').invalid && (addPersonForm.get('personRole').touched || addPersonForm.get('personRole').dirty)">
                    Select a role
                  </small>
                </div>
              </div>
            </div>
          </form>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary" (click)="doAddPersonToGroup()">Add Person</button>
          <button type="button" class="btn btn-outline-danger" (click)="modal.close('Save click')">Cancel</button>
        </div>
      </ng-template>
      <!--End Add person to organization modal-->
    </ng-container>
  </ng-container>
</ng-container>
