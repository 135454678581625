import { RagType } from './rag-type';
import { ServiceBulletinModel } from './service-bulletin-model';
import * as moment from "moment";

export class AircraftServiceBulletinModel {
  constructor(){
    this.id = null;
    this.aircraftServiceBulletinId = null;
    this.aircraftId = null;
    this.bulletinId = null;
    this.service_bulletin = new ServiceBulletinModel;
    this.isRecurring = false;
    this.terminateAction = false;
  }
  id: number;
  aircraftServiceBulletinId: number;
  dueDate: string;
  dueDateAsMoment?: moment.Moment;
  dueHours: number;
  dueHoursAsMoment?: moment.Moment;
  sortingValue?: string;
  sortingMomentValue?: moment.Moment;
  sortingColumnName?: "Date" | "Hours";
  ragStatus?: RagType;
  isRecurring: boolean;
  terminateAction: boolean;
  lastPerformedDate: string;
  lastPerformedHour: number;
  condition: string;
  application: string;
  notApplyReason: string;
  performanceNote: string;
  aircraftId: number;
  bulletinId: number;
  service_bulletin: ServiceBulletinModel;
}