<app-user-aircraft-info-header-widget [headerTitle]="'Edit Aircraft'" [userAircraft]="theAircraft">
  </app-user-aircraft-info-header-widget>

<div class="card shadow border-0 admin-create-user mt-3">
  <div class="card-header border-0 clearfix">
    Edit Aircraft
    <div class="float-right">
      <span *ngIf="theAircraft.user_id">
        <a [routerLink]="['/cga/admin/edit-customer', theAircraft.user_id]" class="btn btn-outline-dark rounded btn-sm mr-3 ">View Customer</a>
      </span>

      <button *ngIf="!editMode" class="btn btn-outline-danger rounded btn-sm" (click)="toggleEditMode()">
        <i class="la la-edit"></i> Edit Aircraft
      </button>
      <button *ngIf="editMode" class="btn btn-outline-danger rounded btn-sm" (click)="toggleEditMode()">
          <i class="la la-times-circle"></i> Cancel
      </button>
    </div>
  </div>
  <div class="card-body">
    <app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

    <ng-container *ngIf="!isWorking">
      <div class="row mb-3" *ngIf="theAircraft.id">
        <div class="col-md-6 col-sm-12">
          <div class="mb-3">
            <a [routerLink]="['/cga/admin/view-aircraft-dashboard/', theAircraft.id ]" class="btn btn-sm btn-primary w-100">
              <i class="la la-dashboard"></i> View Dashboard
            </a>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <div class="mb-3">
            <a [routerLink]="['/cga/admin/edit-aircraft-dashboard', theAircraft.id ]" class="btn btn-sm btn-outline-primary w-100">
              <i class="la la-gears"></i> Configure Dashboard
            </a>
          </div>
        </div>

        <div class="col-md-12 col-sm-12">
          <a [routerLink]="['/cga/admin/view-aircraft-flightlog',  theAircraft.tailNo]" class="btn btn-sm btn-outline-primary w-100">
            <i class="la la-edit"></i> View FlightLogs
          </a>
        </div>
      </div>


      <app-aircraft-basic-info [theAircraft]="theAircraft" *ngIf="!editMode"></app-aircraft-basic-info>

      <form [formGroup]="updateAircraftForm" (ngSubmit)="doUpdateAircraft()" *ngIf="editMode">
        <div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="form-group" *ngIf="editMode">
                  <small class="form-text text-muted required">Enter Tail No.</small>
                  <input type="text" formControlName="tailNo" class="form-control" name="tailno" id="tailNo" placeholder="Tail Number">
                  <small class="form-text text-danger" *ngIf="tailNo.invalid && (tailNo.dirty || tailNo.touched)">Minimum lenght of 3 and max of 6 .</small>
                </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group" *ngIf="editMode">
                <small class="form-text text-muted required">Enter Aircraft S/N.</small>
                <input type="text" formControlName="airframeSn" class="form-control" id="airframsn" placeholder="Aircraft Serial #">
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group" *ngIf="editMode">
                <small class="form-text text-muted required">Enter Aircraft Make {{aircraftMakeSearching? '... Searching': 'NO'}}</small>
                <input type="text" formControlName="aircraftMake" class="form-control" id="aircraftmake" placeholder="Aircraft Make"
                  [class.is-invalid]="aircraftMakeSearchFailed" [ngbTypeahead]="aircraftMakeTypeHeadSearch"  [editable]="false"
                  [resultTemplate]="rt" [inputFormatter]="typeHeadMakeFormatter" (selectItem)="onTypeHeadSelected($event, 'aircraft')"
                required>

                <ng-template #rt let-r="result" let-t="term">
                  <ngb-highlight [result]="r.makeName" [term]="t"></ngb-highlight>
                </ng-template>
                <small class="form-text text-danger" *ngIf="aircraftMake.invalid">
                  Ensure you select from the dropdown options
                </small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group" *ngIf="editMode">
                <small class="form-text text-muted required">Select Aircraft Model {{aircraftModelSearching? '... Searching': 'NO'}}</small>
                <input type="text" [readonly]="aircraftMake.invalid" formControlName="aircraftModel" class="form-control" id="aircraftmodel" placeholder="Aircraft Model"
                  [class.is-invalid]="aircraftModelSearchFailed" [ngbTypeahead]="aircraftModelTypeHeadSearch"  [editable]="false"
                  [resultTemplate]="rt_aircraftModel" [inputFormatter]="typeHeadModelFormatter" (selectItem)="onTypeHeadModelSelected($event, 'aircraft')"
                required>

                <ng-template #rt_aircraftModel let-r="result" let-t="term">
                  <ngb-highlight [result]="r.modelName" [term]="t"></ngb-highlight>
                </ng-template>
                <small class="form-text text-danger" *ngIf="aircraftModel.invalid">
                  Ensure you select from the dropdown options
                </small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group" *ngIf="editMode">
                <small class="form-text text-muted required">Enter Airframe TTIS at Setup.</small>
                <input type="text" formControlName="airframeTtis" class="form-control" id="airframttis" placeholder="Airframe TTIS at Setup">
                <small class="form-text text-danger" *ngIf="airframeTtis.invalid && (airframeTtis.dirty || airframeTtis.touched)">TTIS in hours (digits).</small>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col-sm-12 col-md-12">
              <h5>Engine Information</h5>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group" *ngIf="editMode">
                <small class="form-text text-muted required">Select Engine Make</small>
                <input type="text" formControlName="engineMake" class="form-control" id="enginemake" placeholder="Engine Make"
                  [class.is-invalid]="engineMakeSearchFailed" [ngbTypeahead]="engineMakeTypeHeadSearch"  [editable]="false"
                  [resultTemplate]="rt_engine" [inputFormatter]="typeHeadMakeFormatter" (selectItem)="onTypeHeadSelected($event, 'engine')"
                required>

                <ng-template #rt_engine let-r="result" let-t="term">
                  <ngb-highlight [result]="r.makeName" [term]="t"></ngb-highlight>
                </ng-template>
                <small class="form-text text-danger" *ngIf="engineMake.invalid">Ensure you select Make from dropdown options</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group" *ngIf="editMode">
                <small class="form-text text-muted required">Select Engine Model</small>
                <input type="text" [readonly]="engineMake.invalid" formControlName="engineModel" class="form-control" id="enginemodel" placeholder="Engine Model"
                  [class.is-invalid]="engineModelSearchFailed" [ngbTypeahead]="engineModelTypeHeadSearch"  [editable]="false"
                  [resultTemplate]="rt_engineModel" [inputFormatter]="typeHeadModelFormatter" (selectItem)="onTypeHeadModelSelected($event, 'engine')"
                required>

                <ng-template #rt_engineModel let-r="result" let-t="term">
                  <ngb-highlight [result]="r.modelName" [term]="t"></ngb-highlight>
                </ng-template>
                <small class="form-text text-danger" *ngIf="engineModel.invalid">Ensure you select Model from dropdown options</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group" *ngIf="editMode">
                <small class="form-text text-muted required">Enter Engine Serial No.</small>
                <input type="text" formControlName="engineSn" class="form-control" id="engine_sn" placeholder="Engine S/N">
                <small class="form-text text-danger" *ngIf="engineSn.invalid && (engineSn.dirty || engineSn.touched)">Enter Valid Engine S/N</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group" *ngIf="editMode">
                <small class="form-text text-muted required">Enter Engine TT at Install(in engine hours)</small>
                <input type="text" formControlName="initialEngineHours" class="form-control" id="engine_hrs" placeholder="Engine TT at Install(engine hours)">
                <small class="form-text text-danger" *ngIf="initialEngineHours.invalid && (initialEngineHours.dirty || initialEngineHours.touched)">Engine hours at install in tenths e.g. 102.5.</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group" *ngIf="editMode">
                <small class="form-text text-muted required">Enter Engine Install Time(in airframe hours)</small>
                <input type="text" formControlName="engineInstalledAirframeTime" class="form-control" id="engineInstalledAirframeTime" placeholder="Engine Install Time">
                <small class="form-text text-danger" *ngIf="engineInstalledAirframeTime.invalid && (engineInstalledAirframeTime.dirty || engineInstalledAirframeTime.touched)">Enter airframe hours at propeller install in tenths e.g. 102.5</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group" *ngIf="editMode">
                <small class="form-text text-muted required">Enter Last Engine Overhaul(in engine hours)</small>
                <input type="text" formControlName="initialLastEngineOh" class="form-control" id="initialLastEngineOh" placeholder="Last Engine OH">
                <small class="form-text text-danger" *ngIf="initialLastEngineOh.invalid && (initialLastEngineOh.dirty || initialLastEngineOh.touched)">Enter engine OH hours in tenths e.g. 102.5</small>
              </div>
            </div>
        </div>

          <div class="row">
            <div class="sm-12 col-md-12">
              <h5>Propeller Information</h5>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group" *ngIf="editMode">
                <small class="form-text text-muted required">Enter Propeller Make</small>
                <input type="text" formControlName="propellerMake" class="form-control" id="propellermake" placeholder="Propeller Make"
                  [class.is-invalid]="propellerMakeSearchFailed" [ngbTypeahead]="propellerMakeTypeHeadSearch"  [editable]="false"
                  [resultTemplate]="rt_propeller" [inputFormatter]="typeHeadMakeFormatter" (selectItem)="onTypeHeadSelected($event, 'propeller')"
                required>

                <ng-template #rt_propeller let-r="result" let-t="term">
                  <ngb-highlight [result]="r.makeName" [term]="t"></ngb-highlight>
                </ng-template>
                <small class="form-text text-danger" *ngIf="propellerMake.invalid">Ensure you select a make form the dropdown options</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group" *ngIf="editMode">
                <small class="form-text text-muted required">Enter Propeller Model</small>
                <input type="text" [readonly]="propellerMake.invalid" formControlName="propellerModel" class="form-control" id="propellermodel" placeholder="Propeller Model"
                  [class.is-invalid]="propellerModelSearchFailed" [ngbTypeahead]="propellerModelTypeHeadSearch"  [editable]="false"
                  [resultTemplate]="rt_propellerModel" [inputFormatter]="typeHeadModelFormatter" (selectItem)="onTypeHeadModelSelected($event, 'propeller')"
                required>

                <ng-template #rt_propellerModel let-r="result" let-t="term">
                  <ngb-highlight [result]="r.modelName" [term]="t"></ngb-highlight>
                </ng-template>
                <small class="form-text text-danger" *ngIf="propellerModel.invalid">Ensure you select a model form the drop down options</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group" *ngIf="editMode">
                <small class="form-text text-muted required">Enter Propeller Serial No.</small>
                <input type="text" formControlName="propellerSn" class="form-control" id="propellersn_oh" placeholder="Propeller S/N">
                <small class="form-text text-danger" *ngIf="propellerSn.invalid && (propellerSn.dirty || propellerSn.touched)">Enter valid propeller serial number</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group" *ngIf="editMode">
                <small class="form-text text-muted required">Enter Propeller TT at Install(in propeller hours)</small>
                <input type="text" formControlName="inititialPropellerHours" class="form-control" id="inititialPropellerHours" placeholder="Propeller TT at Install">
                <small class="form-text text-muted" *ngIf="inititialPropellerHours.invalid && (inititialPropellerHours.dirty || inititialPropellerHours.touched)">Enter propeller hours at install in tenths e.g. 102.5</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group" *ngIf="editMode">
                <small class="form-text text-muted required">Propeller Install Time(in airframe hours)</small>
                <input type="text" formControlName="propellerInstalledAirframeTime" class="form-control" id="propellerInstalledAirframeTime" placeholder="Propeller Install Time">
                <small class="form-text text-muted" *ngIf="propellerInstalledAirframeTime.invalid && (propellerInstalledAirframeTime.dirty || propellerInstalledAirframeTime.touched)">Enter airframe hours at propeller install tenths e.g. 102.5</small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group" *ngIf="editMode">
                <small class="form-text text-muted required">Enter Last Propeller Overhaul (in propeller hours)</small>
                <input type="text" formControlName="initLastPropellerOh" class="form-control" id="propeller_oh" placeholder="Last Propeller OH">
                <small class="form-text text-muted" *ngIf="initLastPropellerOh.invalid && (initLastPropellerOh.dirty || initLastPropellerOh.touched)">Enter propeller OH hours in tenths e.g. 102.5</small>
              </div>
            </div>

            <hr />

          </div>

          <div class="row">
            <div class="col-sm-12 col-md-12">
              <h5>Other Info</h5>
            </div>
            <div class="col-sm-12 col-md-12">
              <div class="form-group" *ngIf="editMode">
                <small id="avionics_eq_oh" class="form-text text-muted">Enter Avionics Equipment</small>
                <textarea name="avionics_eq" class="form-control" formControlName="avionicsEquipments" id="avionics_eq" placeholder="Avionics Equipment" rows="4"></textarea>
              </div>
            </div>

            <div class="col-sm-12 col-md-12">
              <div class="form-group" *ngIf="editMode">
                <small id="other_eq_oh" class="form-text text-muted">Enter Other Equipment</small>
                <textarea name="other_eq" class="form-control" formControlName="otherEquipments" id="other_eq" placeholder="Other Equipment" rows="4"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right mt-2" *ngIf="editMode">
          <button class="btn btn-outline-dark mr-3"  (click)="toggleEditMode()">Cancel</button>
          <button type="submit" class="btn btn-outline-dark">Update Aircraft</button>
        </div>
      </form>
    </ng-container>

  </div>
</div>
