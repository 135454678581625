<section *ngIf="this.selectedAircraft.id" class="mb-3">

  <!--CAN'T MANAGE AIRCRAFT-->
  <ng-container *ngIf="!checkIfCanManageRoles()">
    <div class="alert alert-warning">
      You do not have the rights needed to manage members on this aircraft.
    </div>
  </ng-container>


  <!--CAN MANAGE AIRCRAFT-->
  <ng-container *ngIf="checkIfCanManageRoles()">

    <div >
      <div class=" bg-transparent border-0 form_header mt-6 mb-0 pb-0 clearfix">

        <h5 class="uppercase form_header float-left">
          Associated Members
        </h5>

        <span class="float-right">
          <button (click)="initAddPersonModal(addPersonModalContent)"
            class="btn btn-outline-primary rounded btn-sm"
          >
            <i class="la la-user-plus"></i>
          </button>
          Add Person
        </span>
      </div>

      <div class="card rounded-0 shadow p-3">

        <div class="w-100 justify-content-between d-flex align-items-center">
          <span *ngIf="filterQuery">Search Results for <b>{{filterQuery}}</b></span>
          <span *ngIf="!filterQuery">Total People: <b>{{allAircraftPeople.length}}</b></span>
        </div>

        <!-- <div class="form-group" [hidden]="isWorking && !filterQuery">
          <form [formGroup]="filterPeopleForm" (ngSubmit)="filterAircraftPeopleByNameEmailOrMobile()">
            <div class="input-group">
              <input type="text" formControlName="filter" class="form-control" id="filter" placeholder="Filter by person's name, email, or mobile no.">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="submit" id="search_by_name">
                  <i class="la la-search"></i>
                </button>
              </div>
            </div>
            <small id="aircraft_help" class="form-text text-muted">Person's Name, Email, Aircraft Tail No, or Mobile phone no.</small>
          </form>
        </div> -->

        <app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

        <ng-container *ngIf="!isWorking">
          <div>
            <table class="table table-striped spaced_rows table-responsive">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Mobile #</th>
                  <th scope="col">Flight Review</th>
                  <th scope="col">Medical</th>
                  <th scope="col">Role</th>
                  <th scope="col">Since</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <!--- People rows-->
                <tr *ngFor="let person of allAircraftPeople; let i = index;"
                id="{{'row_' + person.user.emailAddress}}"
                >
                  <th scope="row">{{i+1}}</th>
                  <td>
                    {{person.user?.firstName}} {{person.user?.lastName}}
                    <small class="d-block text-muted">
                      <i class="la la-envelope text-primary"></i>
                      {{person.user?.emailAddress}}
                    </small>
                  </td>
                  <td>
                    {{person.user?.mobilePhone}}
                  </td>
                  <td>
                    {{person?.user?.flightReviewDueDate | date}}
                  </td>
                  <td>
                    {{person?.user?.medicalDueDate | date}}
                  </td>
                  <td>
                    <ng-container *ngIf="
                    +updatedPersonRole.user.id
                    !== +person.user.id">
                      {{ getAircraftRoleLabelText(person.role) }}
                      <small class="d-block text-muted">
                        Added: {{person.user?.createdAt | date}}
                      </small>
                    </ng-container>

                    <ng-container *ngIf="
                    updatedPersonRole.user.id
                    && +updatedPersonRole.user.id
                    === +person.user.id">
                      <form [formGroup]="updatePersonRoleForm" (ngSubmit)="doAddPersonRoleToAircraft('update')" *ngIf="!isUpdatingUser">
                        <div class="form-group mb-0">
                          <div class="input-group">
                            <select
                            class="form-control
                            {{
                              (updatePersonRoleForm.get('personRole').invalid
                                && (updatePersonRoleForm.get('personRole').touched
                                  || updatePersonRoleForm.get('personRole').dirty
                                  )
                              )
                              ? 'border-danger'
                              : 'border-success'}}"
                            formControlName="personRole" id="personRole"  placeholder="Select role">
                              <option *ngFor="let role of aircraftRoleOptions.options; let i = index" [value]="role.value">
                                {{role.label}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </form>
                    </ng-container>
                  </td>
                  <td>
                    {{person?.user?.createdAt | date}}
                  </td>
                  <td>
                    <div *ngIf="!isUpdatingUser">
                      <a (click)="loadPersonForUpdate(person)" class="btn btn-outline-primary rounded mr-2"
                      *ngIf="
                      +updatedPersonRole.user.id
                      !== +person.user.id">
                          <i class="la la-edit"></i> Edit Person
                      </a>

                      <ng-container *ngIf="
                        updatedPersonRole.user.id
                        && +updatedPersonRole.user.id
                        === +person.user.id">
                        <a (click)="doAddPersonRoleToAircraft('update')" class="btn btn-success rounded mr-2">
                            <i class="la la-save"></i> Save
                        </a>

                        <a (click)="unloadPersonForUpdate()" class="btn btn-danger rounded mr-2">
                            <i class="la la-times text-white"></i>
                        </a>
                      </ng-container>

                      <a (click)="detachAircraftPersonRole(person)" class="btn btn-outline-danger rounded">
                        <i class="la la-trash"></i>
                      </a>
                    </div>

                    <app-preloader-type-a *ngIf="isUpdatingUser"></app-preloader-type-a>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>

          <!-- <div class="d-flex justify-content-between p-2">
            <ngb-pagination
              [collectionSize]="allAircraftPeople.length" [(page)]="page" [pageSize]="pageSize" (pageChange)="getUsersByAircraft()">
            </ngb-pagination>
          </div> -->

        </ng-container>

      </div>
    </div>


    <!--Add person to organization modal-->
    <ng-template #addPersonModalContent let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add Person to Aircraft</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <app-preloader-type-a *ngIf="isAddingUser"></app-preloader-type-a>

        <form [formGroup]="addPersonRoleForm" (ngSubmit)="doAddPersonRoleToAircraft()" *ngIf="!isAddingUser">
          <div class="form-group">
            <small class="form-text text-muted required">Organization Persons</small>
            <div class="input-group">
              <select class="form-control" formControlName="personEmail" id="personEmail"  placeholder="Select Person">
                <option value=''>Select Person from list</option>
                <option *ngFor="let person of allOrgPeople; let i = index" [value]="person.user.emailAddress">
                  {{person.user.emailAddress}}
                </option>
              </select>
            </div>
            <small class="text-danger d-block" *ngIf="addPersonRoleForm.get('personEmail').invalid && (addPersonRoleForm.get('personEmail').touched || addPersonRoleForm.get('personEmail').dirty)">
              Enter valid email address
            </small><small class="text-center text-muted">
              <i class="la la-info-circle"></i>
              Add more people to your organization to see them on this list.
            </small>
          </div>

          <div class="form-group">
            <small class="form-text text-muted required">Select Role</small>
            <div class="input-group">
              <select class="form-control" formControlName="personRole" id="personRole"  placeholder="Select role">
                <option *ngFor="let role of aircraftRoleOptions.options; let i = index" [value]="role.value">
                  {{role.label}}
                </option>
              </select>
            </div>
            <small class="text-danger d-block" *ngIf="addPersonRoleForm.get('personRole').invalid && (addPersonRoleForm.get('personRole').touched || addPersonRoleForm.get('personRole').dirty)">
              Select a role
            </small>
          </div>
        </form>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="doAddPersonRoleToAircraft()">Add Person</button>
        <button type="button" class="btn btn-outline-danger" (click)="modal.close('Save click')">Cancel</button>
      </div>
    </ng-template>
    <!--End Add person to organization modal-->
  </ng-container>

</section>
