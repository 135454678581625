<app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>


<section  *ngIf="!isWorking">

    <section class="mb-3 border-0">
      <div class="form-group">
        <form [formGroup]="filterSearchForm" (ngSubmit)="filterListWithQuery()">
          <div class="input-group">
            <input type="text" formControlName="filterQuery" class="form-control" id="filterQuery"
            placeholder="Filter by Aircraft Tail No">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" type="submit" id="search_by_tail no">
                <i class="la la-search"></i>
              </button>
            </div>
          </div>
          <small id="aircraft_help" class="form-text text-muted">
            Aircraft Tail No.
          </small>
        </form>
      </div>
    </section>

  <div class="card-header card bg-transparent border-0">
    <span *ngIf="filterQuery">Search Results for <b>{{filterQuery}}</b></span>
    <!--<span *ngIf="!filterQuery">All Customers: <b>{{allUserCount}}</b></span> -->
  </div>

  <div class="alert alert-warning text-center"  *ngIf="fetched && aircraftList.length < 1">
    No aircraft in organization
  </div>
  <app-organization-aircraft-table [aircraftList]="filterResult" *ngIf="filterQuery"></app-organization-aircraft-table>
  <app-organization-aircraft-table [aircraftList]="aircraftList" *ngIf="!filterQuery"></app-organization-aircraft-table>
</section>
