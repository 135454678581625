import { FullAircraftMaintenanceStatModel } from './../models/full-aicraft-maintenance-stats-model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AircraftMaintenanceService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getLastMAircraft100hours(aircraftId: number){
    return this.httpClient.get(environment.apiBaseUrl + '/aircraftmaintenance/getLastMAircraft100hours?aircraftId='+ aircraftId)
  }

  getLastMAircraftAltimeters(aircraftId: number){
    return this.httpClient.get(environment.apiBaseUrl + '/aircraftmaintenance/getLastMAircraftAltimeters?aircraftId='+ aircraftId)
  }

  getLastMAircraftAnnuals(aircraftId: number){
    return this.httpClient.get(environment.apiBaseUrl + '/aircraftmaintenance/getLastMAircraftAnnuals?aircraftId='+ aircraftId)
  }

  getLastMAircraftAnnualHours(aircraftId: number){
    return this.httpClient.get(environment.apiBaseUrl + '/aircraftmaintenance/getLastMAircraftAnnualHours?aircraftId='+ aircraftId)
  }

  getLastMAircraftElts(aircraftId: number){
    return this.httpClient.get(environment.apiBaseUrl + '/aircraftmaintenance/getLastMAircraftElts?aircraftId='+ aircraftId)
  }

  getLastMAircraftOilChanges(aircraftId: number){
    return this.httpClient.get(environment.apiBaseUrl + '/aircraftmaintenance/getLastMAircraftOilChanges?aircraftId='+ aircraftId)
  }

  getLastMAircraftPitotics(aircraftId: number){
    return this.httpClient.get(environment.apiBaseUrl + '/aircraftmaintenance/getLastMAircraftPitotics?aircraftId='+ aircraftId)
  }

  getLastMAircraftTransponders(aircraftId: number){
    return this.httpClient.get(environment.apiBaseUrl + '/aircraftmaintenance/getLastMAircraftTransponders?aircraftId='+ aircraftId)
  }

  postAllMaintenanceStats(aircraftMaintenanceStat: FullAircraftMaintenanceStatModel){
    return this.httpClient.post(environment.apiBaseUrl + '/aircraftmaintenance/postAllMaintenanceStats', aircraftMaintenanceStat)
  }
}
