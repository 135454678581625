import { UtilitiesService } from './../../../services/utilities.service';
import { UserBaseModel } from './../../../models/user-base-model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-members-table',
  templateUrl: './members-table.component.html',
  styleUrls: ['./members-table.component.scss']
})
export class MembersTableComponent implements OnInit {
  @Input() membersList: Array<UserBaseModel>;
  @Input() isAdminView: Boolean;

  constructor(
    private utilsService: UtilitiesService
  ) { }

  ngOnInit() {
  }

  formatPhoneNumber(phoneNumber: string){
    return phoneNumber ? this.utilsService.formatPhoneNumber(phoneNumber) : '--- --- ---- ';
  }


}
