import { AircraftService } from './../../../../services/aircraft.service';
import { FlightLogsService } from './../../../../services/flight-logs.service';
import { AircraftDashboardReferencesModel } from './../../../../models/aircraft-dashboard-references-model';
import { UtilitiesService } from './../../../../services/utilities.service';
import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { RagType } from './../../../../models/rag-type';
import * as moment from 'moment';
import { AircraftV2Model } from '../../../../models/airfcraftV2/aircraft-v2.model';

@Component({
  selector: 'app-administrative-status',
  templateUrl: './administrative-status.component.html',
  styleUrls: ['./administrative-status.component.scss']
})
export class AdministrativeStatusComponent implements OnInit, OnChanges {
  @Input() theAircraft: AircraftV2Model;
  @Input() isAdminView: boolean;
  @Input() statusOnly: boolean;

  @Output() isCompleted: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    private utilitiesService: UtilitiesService
  ) { }

  isLoading: boolean;
  dashReferences: AircraftDashboardReferencesModel;

  todayFlightDateMoment: moment.Moment = moment(new Date());
  serverEpochTime: number = new Date().getTime();

  registrationLastDueDateMoment: moment.Moment = null;
  registrationNextDueDateMoment: moment.Moment = null;
  registrationRagStatus: RagType = null;

  insuranceLastDueDateMoment: moment.Moment = null;
  insuranceNextDueDateMoment: moment.Moment = null;
  insuranceRagStatus: RagType = null;

  registrationDone: boolean;
  insuranceDone: boolean;


  ngOnInit() {
    this.isLoading = false;
    this.generateAdminStatus();
  }

  generateAdminStatus() {
    this.registrationDone = false;
    this.insuranceDone = false;

    this.emitCompleteStatus();
    if (this.theAircraft.id) {
      this.utilitiesService.getAircraftDashboardReferences().subscribe(
        (response: any) => {
          this.isLoading = true;

          this.dashReferences = response.responseData;
          this.todayFlightDateMoment = moment(this.dashReferences.serverNow);
          this.serverEpochTime = this.utilitiesService.parseISOtoDateObj(this.dashReferences.serverNow).getTime();

          this.isLoading = false;
          this.renderRegistrationSection();
          this.renderInsuranceSection();
          this.emitCompleteStatus();
        }
      );
    }
  }


  emitCompleteStatus() {
    this.isCompleted.emit(
      this.registrationDone && this.insuranceDone
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.theAircraft && changes.theAircraft.currentValue) {
      const newAircraft = changes.theAircraft.currentValue;

      if (newAircraft.id && newAircraft.id !== this.theAircraft.id) {
        this.theAircraft = newAircraft;
        this.generateAdminStatus();
      }
    }
  }

  renderRegistrationSection() {
    if (!this.theAircraft.registrationDueDate) {
      this.registrationDone = true;
      this.emitCompleteStatus();
      return;
    }

    const nextDueDateMoment = moment(this.theAircraft.registrationDueDate);
    const lastDueDateMoment = this.theAircraft.registrationDueDate ? moment(this.theAircraft.registrationDueDate) : null;

    this.registrationNextDueDateMoment = nextDueDateMoment.clone();
    this.registrationLastDueDateMoment = lastDueDateMoment ? lastDueDateMoment.clone() : null;

    // Warning
    const nextWarningManipulator = moment(nextDueDateMoment);
    nextWarningManipulator.subtract(this.dashReferences.adminRegistrationWarningDays, 'days');
    const nextWarningMoment = moment(nextWarningManipulator);

    // get difference
    const dueDiff = this.todayFlightDateMoment.diff(nextDueDateMoment, 'days');
    const warningDiff = this.todayFlightDateMoment.diff(nextWarningMoment, 'days');

    if ( dueDiff >= 0) {
      this.registrationRagStatus = RagType.a_red;
    } else if (warningDiff >= 0) {
      this.registrationRagStatus = RagType.b_amber;
    }  else {
      this.registrationRagStatus = RagType.c_green;
    }
    this.registrationDone = true;
    this.emitCompleteStatus();

  }

  renderInsuranceSection() {
    if (!this.theAircraft.insuranceDueDate) {
      this.insuranceDone = true;
      this.emitCompleteStatus();
      return;
    }

    const nextDueDateMoment = moment(this.theAircraft.insuranceDueDate);
    const lastDueDateMoment = this.theAircraft.insuranceDueDate ? moment(this.theAircraft.insuranceDueDate) : null;

    this.insuranceNextDueDateMoment = nextDueDateMoment.clone();
    this.insuranceLastDueDateMoment = lastDueDateMoment ? lastDueDateMoment.clone() : null;

    // Warning
    const nextWarningManipulator = moment(nextDueDateMoment);
    nextWarningManipulator.subtract(this.dashReferences.adminInsuranceWarningDays, 'days');
    const nextWarningMoment = moment(nextWarningManipulator);

    // get difference

    const dueDiff = this.todayFlightDateMoment.diff(nextDueDateMoment, 'days');
    const warningDiff = this.todayFlightDateMoment.diff(nextWarningMoment, 'days');

    if ( dueDiff >= 0) {
      this.insuranceRagStatus = RagType.a_red;
    }  else if (warningDiff >= 0) {
      this.insuranceRagStatus = RagType.b_amber;
    } else {
      this.insuranceRagStatus = RagType.c_green;
    }

    this.insuranceDone = true;
    this.emitCompleteStatus();
  }

  getRagColor(val: number) {
    if (val === null) {
      return ' ';
      // return RagType[RagType.a_red];
    }

    return RagType[val];
  }

  getAdminStatusBadgeColor() {
    this.emitCompleteStatus();

    const statusArray: Array<RagType> = [
      this.registrationRagStatus,
      this.insuranceRagStatus
    ];

    statusArray.sort();

    return this.getRagColor(statusArray[0]);
  }


  formatMomentAsUsDateString(momentObject: moment.Moment) {
    return this.utilitiesService.formatMomentAsUsDateString(momentObject);
  }


  getMomentBetweenDates(lowerTimeMoment: moment.Moment, higherTimeMoment: moment.Moment) {
    return this.utilitiesService.getMomentBetweenDates(lowerTimeMoment, higherTimeMoment);
  }

  getHoursRemainingBetweenHours(lowerHours: number, higherHours: number) {
    return this.utilitiesService.getHoursRemainingBetweenHours(lowerHours, higherHours);
  }

}
