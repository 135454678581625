

<section class="mt-3 dash-dates">

  <app-preloader-type-a *ngIf="isLoading"></app-preloader-type-a>

  <div *ngIf="!isLoading">
    <div class="clearfix form_header">
      <h5 class="form_header uppercase float-left">
        Aircraft Dates
      </h5>

      <span class="float-right">
        <ng-container *ngIf="!inUpdateMode">
          <button class="btn btn-sm btn-outline-primary rounded" (click)="loadForUpdateDashDatesForm()">
            <i class="la la-edit"></i>
          </button>
          Update Dates  
        </ng-container>

        <ng-container *ngIf="inUpdateMode" >
          <button class="btn btn-sm btn-outline-danger rounded" (click)="cancelPostUserPilotSTatus()">
            <i class="la la-times"></i>
          </button>
          Cancel
        </ng-container>
      </span>
    </div>

    <div class="card my-3 shadow  rounded-0 {{inUpdateMode? 'border-danger': ''}} rounded">
      <div class="card-body">
        <div class="card-body">
          <form [formGroup]="pilotDatesForm" (ngSubmit)="postUserPilotSTatus()">
            <div>
              <div class="row">
                <div class="col-sm-12 col-lg-6 col-md-12">
                  <div class="form-group">
                    <small class="form-text text-muted {{inUpdateMode? 'required' : ''}}">
                      <i class="la la-registered"></i> Registration Due
                    </small>
                    <ng-container *ngIf="inUpdateMode">
                      <div class="input-group">
                        <input class="form-control" formControlName="registrationDueDate"  placeholder="mm/dd/yyyy" name="registrationDueDate" ngbDatepicker #regDueDate="ngbDatepicker">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="regDueDate.toggle()" type="button">
                            <i class="la la-calendar-plus-o"></i>
                          </button>
                        </div>
                      </div>

                      <small class="form-text text-danger d-block" *ngIf="pilotDatesForm.get('registrationDueDate').invalid && (pilotDatesForm.get('registrationDueDate').touched || pilotDatesForm.get('registrationDueDate').dirty)">
                        Enter a Registration Due
                      </small>
                    </ng-container>

                    <ng-container *ngIf="!inUpdateMode">
                      <div>
                        <h5 class="ml-3">{{ selectedAircraft.registrationDueDate? (selectedAircraft.registrationDueDate | date: 'MM/dd/yyyy') : "--" }}</h5>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-6 col-md-12">
                  <div class="form-group">
                    <small class="form-text text-muted {{inUpdateMode? 'required' : ''}}">
                      <i class="la la-bank"></i> Insurance Due
                    </small>
                    <ng-container *ngIf="inUpdateMode">
                      <div class="input-group">
                        <input class="form-control" formControlName="insuranceDueDate"  placeholder="mm/dd/yyyy" name="insuranceDueDate" ngbDatepicker #insuranceDate="ngbDatepicker">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="insuranceDate.toggle()" type="button">
                            <i class="la la-calendar-plus-o"></i>
                          </button>
                        </div>
                      </div>
                      <small class="form-text text-danger d-block" *ngIf="pilotDatesForm.get('insuranceDueDate').invalid && (pilotDatesForm.get('insuranceDueDate').touched || pilotDatesForm.get('insuranceDueDate').dirty)">
                        Enter Insurance Due
                      </small>
                    </ng-container>

                    <ng-container *ngIf="!inUpdateMode">
                      <div>
                      <h5 class="ml-3">{{ selectedAircraft.insuranceDueDate ? (selectedAircraft.insuranceDueDate | date:'MM/dd/yyyy') : "--"  }}</h5>
                      </div>
                    </ng-container>
                  </div>
                </div>

              </div>
            </div>
            <div class="text-right mt-2" *ngIf="inUpdateMode">
              <button class="btn btn-outline-danger mr-3" (click)="cancelPostUserPilotSTatus()">Cancel</button>
              <button type="submit" class="btn btn-outline-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
