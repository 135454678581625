import { AirframeV2Model } from './airframe-v2.model';
import { EngineV2Model } from './engine-v2.model';
import { PropellerV2Model } from './propeller-v2.model';
import { OrganizationV2Model } from './../organization/organization-v2.model';
import { AircraftMaintenanceStatusV2Model } from './aircraft-maintenance-status-v2.model';

export class AircraftV2Model {
  constructor() {
    this.id = null;
    this.tailNo = null;

    this.airframe = new AirframeV2Model();
    this.engines = [];
    this.propellers = [];
    this.organizations = [];

    this.maintenanceStatus = new AircraftMaintenanceStatusV2Model;
  }

  organizationRole?: string;
  userRole?: string;
  id: number;
  tailNo: string;

  avionicsEquipments: string;
  otherEquipments: string;

  adComplianceReportState: string;

  adminApproved: boolean;

  registrationDueDate: string;
  insuranceDueDate: string;

  createdAt: string;

  airframe: AirframeV2Model;
  engines: Array<EngineV2Model>;
  propellers: Array<PropellerV2Model>;
  organizations: Array<OrganizationV2Model>;
  lastFlightLogDate: string;


  maintenanceStatus: AircraftMaintenanceStatusV2Model;
}
