import { AlertPopUpsService } from './../../services/alert-pop-ups.service';
import { UtilitiesService } from './../../services/utilities.service';
import { UserService } from './../../services/user.service';
import { UserModel } from '../../models/user-model';
import { HttpResponse } from '@angular/common/http';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private utilitiesService: UtilitiesService,
    private popUpService: AlertPopUpsService,
    private router: Router
  ) {}

  loginForm: FormGroup;
  username: string;
  password: string;

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: new FormControl(this.username, [Validators.required]),
      password: new FormControl(this.password, [Validators.required])
    });
  }

  loginUser(emailAddress, password) {
    const formValidate = this.utilitiesService.generalFormValidate(this.loginForm);

    if (!formValidate) {
      return;
    }

    this.updatePreloader(true);

    this.authService.loginUserV2(emailAddress, password).subscribe(
      (response: any) => {

        const token = response.token;
        const refreshToken = response.refreshToken;

        this.authService.saveToken(token);
        this.authService.saveRefreshToken(refreshToken);

        // this.authService.checkAuthUserInfo(true);

        this.getLoggedInUser();

        // if (userObj.isAdmin) {
        //   this.router.navigate(["/cga/admin"]);
        // } else {
        //   this.router.navigate(["/cga"]);
        // }

        // this.popUpService.openBasicPopUp(
        //   "Success!", "You are now logged in!", "success"
        // ).then(
        //   () => {}
        // );
      },
      (error: any) => {
        this.updatePreloader(false);
        if (error.status && error.status.message) {
          this.popUpService.openBasicPopUp(
            error.status.message,
            error.responseData.join(' '),
            'error'
          );
        } else {
          this.popUpService.openBasicPopUp(
            'Oops!', 'Login Failed.', 'error'
          );
        }
      }
    );
  }

  doUserLogin() {
    this.username = this.loginForm.controls['username'].value;
    this.password = this.loginForm.controls['password'].value;

    this.loginUser(this.username, this.password);
  }

  getLoggedInUser() {
    this.updatePreloader(true);
    this.userService.getMe().subscribe(
      (response: UserModel) => {
        this.userService.updateCurrentUser(response);
        this.updatePreloader(false);
        if (response.isAdmin) {
          this.router.navigate(['/cga/admin']);
        } else {
          this.router.navigate(['/cga']);
        }
      },
      (error: any) => {
        this.updatePreloader(false);
        this.popUpService.openBasicPopUp(
          'Oops!', 'Some issue fetching user.', 'error'
        );
      }
    );
  }

  updatePreloader(val: boolean) {
    this.utilitiesService.updateShowMainPreloader(val);
  }
}
