import { NameAndIdModel } from './name-and-id.model';

export class AirframeV2Model {
  constructor() {
    this.ttis = null;

    this.ttisAtSetup = null;

    this.make = new NameAndIdModel;

    this.model = new NameAndIdModel;
  }

  serialNumber: string;
  logbookState: string;
  ttis: number;
  ttisAtSetup?: number;
  make: NameAndIdModel;
  model: NameAndIdModel;
}
