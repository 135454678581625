
<table class="table spaced_rows table-responsive">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Tail Number</th>
        <th scope="col">Make</th>
        <th scope="col">Model</th>
        <th scope="col">Airframe Sn.</th>
        <th scope="col">Engine Sn.</th>
        <th scope="col">Propeller Sn.</th>
        <!-- <th scope="col">Customer Name</th> -->
        <th scope="col">Actions</th>
      </tr>
    </thead>

    <tbody>
      <!--- Results for search by user name or email-->
      <ng-container *ngFor="let aircraft of aircraftList; let i = index;">
        <!--- Single row for single aircraft owners-->
        <tr>
          <th scope="row">{{i+1}}</th>
          <td class="align-middle">
            <div class="d-flex w-100 align-items-center">
              {{aircraft.tailNo}}
            </div>
          </td>
          <td>{{ aircraft.airframe?.make?.name }}</td>
          <td>{{ aircraft.airframe?.model?.name }}</td>
          <td>{{ aircraft.airframe?.serialNumber }}</td>
          <td>{{ aircraft.engines[0]?.serialNumber}}</td>
          <td>{{ aircraft.propellers[0]?.serialNumber }}</td>
          <td>
            <a href="javascript: void(0);" (click)="viewAsSelectedAircraft(aircraft, 'profile')" class="btn btn-sm mr-1 btn-orange rounded">
              <i class="la la-plane"></i> Profile
            </a>

            <a href="javascript: void(0);" (click)="viewAsSelectedAircraft(aircraft, 'dashboard')" class="btn btn-sm mr-1 btn-danger rounded">
              <i class="la la-dashboard"></i> Dashboard
            </a>
          </td>
        </tr>
      </ng-container>

    </tbody>

  </table>
