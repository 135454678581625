<!---Manage Nav Data Updates -->
<section class="p-3 nav-data-updates">
  <div class="card shadow border-0">
    <div class="card-header clearfix">
      &nbsp;
      <span class="float-right" *ngIf="hasEditRole()">
        <button class="btn btn-sm btn-outline-dark rounded" (click)="toggleInNewNavDataUpdate()">
          {{ inNewNavDataUpdateMode? 'View All Nav Data' : 'New Nav Data Update' }}
          <i class="la la-plus" *ngIf="!inNewNavDataUpdateMode"></i>
          <i class="la la-th-list"  *ngIf="inNewNavDataUpdateMode"></i>
        </button>
      </span>
    </div>
    <div class="card-body">

      <app-preloader-type-a *ngIf="isFetchingNavUpdates"></app-preloader-type-a>
      
      <div *ngIf="!isFetchingNavUpdates">
        <!--NEW Nav Data Update-->
        <div class="card mb-3 bg-light border-info rounded" *ngIf="inNewNavDataUpdateMode">
          <!--<div class="card-header">
            New Nav Data Update
          </div>-->
          <div class="card-body">
            <h4>New Nav Data Update</h4>
            <form [formGroup]="newNavDataUpdateForm" (ngSubmit)="addNewNavDataUpdateForm()">
              <div>
                <div class="row">

                  <div class="col-sm-12 col-lg-8 col-md-12">
                    <div class="form-group">
                      <small class="form-text text-muted required">Equipment Name</small>
                      <div class="input-group">
                        <input class="form-control" formControlName="equipmentName"  placeholder="Equipment Name" name="equipmentName">
                      </div>
                      <small class="text-danger d-block" *ngIf="newNavDataUpdateForm.get('equipmentName').invalid && (newNavDataUpdateForm.get('equipmentName').touched || newNavDataUpdateForm.get('equipmentName').dirty)">Equipment Name is required.</small>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-4 col-md-6">
                    <div class="form-group">
                      <small class="form-text text-muted required">Cycle</small>
                      <div class="input-group">
                        <input class="form-control" formControlName="cycle"  placeholder="Cycle" name="cycle">
                      </div>
                      <small class="text-danger d-block" *ngIf="newNavDataUpdateForm.get('cycle').invalid && (newNavDataUpdateForm.get('cycle').touched || newNavDataUpdateForm.get('cycle').dirty)">Enter valid cycle value e.g. 2106</small>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-4 col-md-6">
                    <div class="form-group">
                      <small class="form-text text-muted required">Effective Date</small>
                      <div class="input-group">
                        <input class="form-control" formControlName="effectiveDate"  placeholder="mm/dd/yyyy" name="effectiveDate" ngbDatepicker #effectiveDate="ngbDatepicker">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="effectiveDate.toggle()" type="button">
                            <i class="la la-calendar-plus-o"></i>
                          </button>
                        </div>
                      </div>
                      <small class="text-danger d-block" *ngIf="newNavDataUpdateForm.get('effectiveDate').invalid && (newNavDataUpdateForm.get('effectiveDate').touched || newNavDataUpdateForm.get('effectiveDate').dirty)">Enter valid effective date</small>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-4 col-md-6">
                    <div class="form-group">
                      <small class="form-text text-muted required">Expire Date</small>
                      <div class="input-group">
                        <input class="form-control" formControlName="expireDate"  placeholder="mm/dd/yyyy" name="expireDate" ngbDatepicker #expireDate="ngbDatepicker">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="expireDate.toggle()" type="button">
                            <i class="la la-calendar-plus-o"></i>
                          </button>
                        </div>
                      </div>
                      <small class="text-danger d-block" *ngIf="newNavDataUpdateForm.get('expireDate').invalid && (newNavDataUpdateForm.get('expireDate').touched || newNavDataUpdateForm.get('expireDate').dirty)">Enter valid expire date</small>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-4 col-md-6">
                    <div class="form-group">
                      <small class="form-text text-muted required">Sign Initials</small>
                      <div class="input-group">
                        <input class="form-control" formControlName="signInitials"  placeholder="Sign Initials" name="signInitials">
                      </div>
                      <small class="text-danger d-block" *ngIf="newNavDataUpdateForm.get('signInitials').invalid && (newNavDataUpdateForm.get('signInitials').touched || newNavDataUpdateForm.get('signInitials').dirty)">Sign in initials.</small>
                    </div>
                  </div>

                </div>
              </div>
              <hr class="mt-1">
              <div class="text-right mt-2">
                <button class="btn btn-outline-danger mr-3" (click)="inNewNavDataUpdateMode = false">Cancel</button>
                <button class="btn btn-outline-primary">Add Nav Data Update</button>
              </div>
            </form>

          </div>
        </div>

        <section *ngIf="!inNewNavDataUpdateMode">
          <!--EXISTING NAV DATA UPDATED-->

          <table class="table table-striped w-100">
            <tr *ngFor="let navData of allNavDataUpdates; index as i">
              <td>
                <div>
                  <h6 class="clearfix">
                    {{ i + 1 }}:
                    {{navData?.equipmentName}}

                    <span class="float-right" *ngIf="navData.editable && hasEditRole()">
                      <button class="btn btn-outline-primary rounded" *ngIf="editNavDataUpdate.id !== navData.id" (click)="loadNavDataUpdateForEdit(navData)">
                        <i class="la la-edit"></i>
                      </button>
                      <button class="btn btn-outline-primary rounded" *ngIf="editNavDataUpdate.id == navData.id" (click)="unloadNavDataUpdateForEdit()">
                        <i class="la la-times"></i>
                      </button>
                    </span>
                  </h6>
                  <div class="row">
                    <div class="col-6 col-sm-6 col-md-3 my-2">
                      <small class="text-muted d-block">
                        Cylce
                      </small>
                      {{navData.cycle }}
                    </div>

                    <div class="col-6 col-sm-6 col-md-3 my-2">
                      <small class="text-muted d-block">
                        Effective Date
                      </small>
                      {{navData?.effectiveDate | date:'MM/dd/yyyy'}}
                    </div>

                    <div class="col-6 col-sm-6 col-md-3 my-2">
                      <small class="text-muted d-block">
                        Expire Date
                      </small>
                      {{navData?.expireDate | date:'MM/dd/yyyy'}}
                    </div>
                    <div class="col-6 col-sm-6 col-md-3 my-2">
                      <small class="text-muted d-block">
                        Initials
                      </small>
                      {{navData?.signInitials}}
                    </div>
                  </div>
                </div>

                <!--EDIT Nav Data Update-->
                <div class="card mb-3 bg-light border-danger rounded" *ngIf="editNavDataUpdate.id == navData.id" >
                  <div class="card-body">
                    <h4>Edit Nav Data Update {{navData.equipmentName}}</h4>
                    <form [formGroup]="editNavDataUpdateForm" (ngSubmit)="doUpdateNavDataUpdate()">
                      <div>
                        <div class="row">
                          <div class="col-sm-12 col-lg-8 col-md-12">
                            <div class="form-group">
                              <small class="form-text text-muted required">Edit Equipment Name</small>
                              <div class="input-group">
                                <input class="form-control" formControlName="equipmentName"  placeholder="Equipment Name" name="equipmentName">
                              </div>
                              <small class="text-danger d-block" *ngIf="editNavDataUpdateForm.get('equipmentName').invalid && (editNavDataUpdateForm.get('equipmentName').touched || editNavDataUpdateForm.get('equipmentName').dirty)">Equipment Name is required.</small>
                            </div>
                          </div>

                          <div class="col-sm-12 col-lg-4 col-md-6">
                            <div class="form-group">
                              <small class="form-text text-muted required">Edit Cycle</small>
                              <div class="input-group">
                                <input class="form-control" formControlName="cycle"  placeholder="Cycle" name="cycle">
                              </div>
                              <small class="text-danger d-block" *ngIf="editNavDataUpdateForm.get('cycle').invalid && (editNavDataUpdateForm.get('cycle').touched || editNavDataUpdateForm.get('cycle').dirty)">Enter valid cycle value e.g. 2106</small>
                            </div>
                          </div>

                          <div class="col-sm-12 col-lg-4 col-md-6">
                            <div class="form-group">
                              <small class="form-text text-muted required">Edit Effective Date</small>
                              <div class="input-group">
                                <input class="form-control" formControlName="effectiveDate"  placeholder="mm/dd/yyyy" name="effectiveDate" ngbDatepicker #effectiveDate="ngbDatepicker">
                                <div class="input-group-append">
                                  <button class="btn btn-outline-secondary calendar" (click)="effectiveDate.toggle()" type="button">
                                    <i class="la la-calendar-plus-o"></i>
                                  </button>
                                </div>
                                <small class="text-danger d-block" *ngIf="editNavDataUpdateForm.get('effectiveDate').invalid && (editNavDataUpdateForm.get('effectiveDate').touched || editNavDataUpdateForm.get('effectiveDate').dirty)">
                                  Enter valid Effective value.
                                </small>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-12 col-lg-4 col-md-6">
                            <div class="form-group">
                              <small class="form-text text-muted required">Edit Expire Date</small>
                              <div class="input-group">
                                <input class="form-control" formControlName="expireDate"  placeholder="mm/dd/yyyy" name="expireDate" ngbDatepicker #expireDate="ngbDatepicker">
                                <div class="input-group-append">
                                  <button class="btn btn-outline-secondary calendar" (click)="expireDate.toggle()" type="button">
                                    <i class="la la-calendar-plus-o"></i>
                                  </button>
                                </div>
                                <small class="text-danger d-block" *ngIf="editNavDataUpdateForm.get('expireDate').invalid && (editNavDataUpdateForm.get('expireDate').touched || editNavDataUpdateForm.get('expireDate').dirty)">
                                  Enter valid expire value.
                                </small>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-12 col-lg-4 col-md-6">
                            <div class="form-group">
                              <small class="form-text text-muted required">Edit Sign Initials</small>
                              <div class="input-group">
                                <input class="form-control" formControlName="signInitials"  placeholder="Sign Initials" name="signInitials">
                              </div>
                              <small class="text-danger d-block" *ngIf="editNavDataUpdateForm.get('signInitials').invalid && (editNavDataUpdateForm.get('signInitials').touched || editNavDataUpdateForm.get('signInitials').dirty)">Sign in initials.</small>
                            </div>
                          </div>

                        </div>
                      </div>
                      <hr class="mt-1">
                      <div class="text-right mt-2">
                        <button class="btn btn-outline-danger mr-3" (click)="unloadNavDataUpdateForEdit()">Cancel</button>
                        <button class="btn btn-outline-primary">Save Nav Data Update</button>
                      </div>
                    </form>

                  </div>
                </div>
              </td>
            </tr>
          </table>
        
        </section>
      </div>

    </div>
  </div>
</section>
