import { Component, OnInit } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { UserModel } from '../../../../models/user-model';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-user-edit-own-profile',
  templateUrl: './user-edit-own-profile.component.html',
  styleUrls: ['./user-edit-own-profile.component.scss']
})
export class UserEditOwnProfileComponent implements OnInit {

  constructor(
    private userService: UserService
  ) { }

  currentUser$: SubscriptionLike;
  currentUser: UserModel = new UserModel();

  ngOnInit() {
    this.currentUser$ = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });
  }

  doProfileRefetchEvent($event) {
    if ($event) {
      this.userService.refetchCurrentUser();
    }
  }

}
