<ng-container *ngIf="!viewStyle || (viewStyle.toLocaleLowerCase() !== 'list')">

  <div class="clearfix my-3">

    <!-- <h4 class="pull-left ellipsis">
      {{headerTitle}}
    </h4> -->

    <div class="float-right">
      <ul class="aircraft-thumb-list">
        <!--TAIL NUMBER-->
        <li class="clearfix"  *ngIf="userAircraft && userAircraft.tailNo">
          <span class="iconthumb pull-left">
            <img src="assets/images/visual_assets/main_data_blocks/Tail Number Data Block Image-01.png" alt="Tail No">
          </span>
          <span class="status-desc pull-left ml-2">
            <small class="d-block text-paleblue">
              Tail Number
            </small>
            <span class="h5 text-darkblue">
              {{ userAircraft?.tailNo }}
            </span>
          </span>
        </li>

          <!--MAKE-->
        <li class="clearfix" *ngIf="userAircraft.aircraft_model && userAircraft.aircraft_model.aircraftMake.makeName">
          <span class="iconthumb pull-left">
            <img src="assets/images/visual_assets/main_data_blocks/Make Data Block Image-01.png" alt="Make">
          </span>
          <span class="status-desc pull-left ml-2">
            <small class="d-block text-paleblue">
              Make
            </small>
            <span class="h5 text-darkblue">
              {{ userAircraft?.aircraft_model?.aircraftMake?.makeName }}
            </span>
          </span>
        </li>

        <!--MODEL-->
        <li class="clearfix" *ngIf="userAircraft.aircraft_model && userAircraft.aircraft_model.modelName">
          <span class="iconthumb pull-left">
            <img src="assets/images/visual_assets/main_data_blocks/Model Data Block Image-01.png" alt="Model">
          </span>
          <span class="status-desc pull-left ml-2">
            <small class="d-block text-paleblue">
              Model
            </small>
            <span class="h5 text-darkblue">
              {{ userAircraft?.aircraft_model?.modelName }}
            </span>
          </span>
        </li>
      </ul>

    </div>

  </div>

  <ul class="aircraft-status-list list-group list-group-horizontal w-100 justify-content-around text-center">
    <li class="list-group-item text-center flex-fill">
      <small class="d-block text-paleblue">
        Airframe TTIS
      </small>
      <h6 class="text-darkblue">
        {{ getValueIfNullOrUndefined(aircraftCurrentStatus.airframeTTIS) }}
      </h6>
    </li>

    <li class="list-group-item text-center flex-fill">
      <small class="d-block text-paleblue">
        Engine TT
      </small>
      <h6 class="text-darkblue">
        {{ getValueIfNullOrUndefined(aircraftCurrentStatus.engineTTIS) }}
      </h6>
    </li>

    <li class="list-group-item text-center flex-fill">
      <small class="d-block text-paleblue">
        Engine SMOH
      </small>
      <h6 class="text-darkblue">
        {{ getValueIfNullOrUndefined(aircraftCurrentStatus.engineSMOH) }}
      </h6>
    </li>

    <li class="list-group-item text-center flex-fill">
      <small class="d-block text-paleblue">
        Propeller TT
      </small>
      <h6 class="text-darkblue">
        {{ getValueIfNullOrUndefined(aircraftCurrentStatus.propellerTTIS) }}
      </h6>
    </li>

    <li class="list-group-item text-center flex-fill">
      <small class="d-block text-paleblue">
        Propeller SPOH
      </small>
      <h6 class="text-darkblue">
        {{ getValueIfNullOrUndefined(aircraftCurrentStatus.propellerSMOH) }}
      </h6>
    </li>
  </ul>

</ng-container>


<ng-container *ngIf="viewStyle === 'list'">
  <div class="row p-2">
    <!-- airframe: col 1-->
    <div class="col-md">
      <b>Airframe</b>
      <ul>
        <li><b>Make:</b>
          {{ userAircraft.aircraft_model &&
             userAircraft.aircraft_model.aircraftMake.makeName ?
             userAircraft?.aircraft_model?.aircraftMake?.makeName : ''  }}
        </li>
        <li><b>Model:</b>
          {{ userAircraft.aircraft_model &&
             userAircraft?.aircraft_model.modelName ?
             userAircraft.aircraft_model.modelName : ''  }}
        </li>
        <li><b>Serial No:</b> {{ userAircraft?.airframeSn ? userAircraft?.airframeSn : 'N/A' }}</li>
        <!-- <li><b>TT:</b> {{ todayFlightHours ? '@' + todayFlightHours + 'Hrs' : 'N/A' }}</li> -->
        <li><b>TT: </b> {{ getValueIfNullOrUndefined(aircraftCurrentStatus.airframeTTIS) }}</li>
        <li><b>Last Annual Date(mm/dd/yyyy): </b> {{ aircraftCurrentStatus.lastAnnuals ? (aircraftCurrentStatus.lastAnnuals | date: 'M/d/y') : '--' }}</li>
        <li><b>TT at Last Annual Date:</b> {{ getValueIfNullOrUndefined(aircraftCurrentStatus.lastAnnualsHours) }}</li>
      </ul>
    </div>

    <!-- engine: col 3 -->
    <div class="col-md">
      <b>Engine</b>
      <ul>
        <li>
          <b>Make:</b>
          {{ userAircraft?.engine_model?.engineMake?.makeName ? userAircraft?.engine_model?.engineMake?.makeName : '' }}
        </li>
        <li><b>Model: </b> {{
          userAircraft.engine_model && userAircraft?.engine_model.modelName ?
           userAircraft?.engine_model?.modelName : '' }}</li>
        <li><b>Serial No: </b> {{ userAircraft.engineSn ? userAircraft.engineSn : '--' }}</li>
        <li><b>TT: </b> {{ getValueIfNullOrUndefined(aircraftCurrentStatus.engineTTIS) }}</li>
        <li><b>SMOH: </b> {{ getValueIfNullOrUndefined(aircraftCurrentStatus.engineSMOH) }}</li>
      </ul>
    </div>

    <!-- propeller: col 4 -->
    <div class="col-md">
      <b>Propeller</b>
      <ul>
        <li>
          <b>Make:</b>
          {{ userAircraft.propeller_model &&
             userAircraft.propeller_model.propellerMake?.makeName ?
              userAircraft?.propeller_model?.propellerMake?.makeName : '' }}
        </li>
        <li><b>Model:</b> {{ userAircraft.propeller_model &&
          userAircraft.propeller_model.modelName ?
          userAircraft?.propeller_model?.modelName : '' }}
        </li>
        <li><b>Serial No:</b> {{ userAircraft.propellerSn ? userAircraft?.propellerSn : 'N/A' }}</li>
        <li><b>TT:</b> {{ getValueIfNullOrUndefined(aircraftCurrentStatus.propellerTTIS) }}</li>
        <li><b>SMOH:</b> {{ getValueIfNullOrUndefined(aircraftCurrentStatus.propellerSMOH) }}</li>
      </ul>
    </div>
  </div>
</ng-container>
