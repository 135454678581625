<!-- header -->
<div class="modal-header">
  <!--heading  -->
  <h4 class="display-6">Add {{ type }} to aircraft</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<!-- body -->
<div class="modal-body">
  <!--  search page -->
  <div class="p-2" *ngIf="activePage === 1">
    <!-- Aircraft details container -->
    <div class="card mb-3 bg-light border-info rounded">
      <app-user-aircraft-info-header-widget [userAircraft]="aircraft" [headerTitle]="'details'" [viewStyle]="'list'"></app-user-aircraft-info-header-widget>
    </div>

    <!-- Document search form -->
    <div class="card mb-3 border-info rounded">
      <!-- document input containers -->
      <div class="card-header">
        <div class="row">
          <!-- search Manufacturer -->
          <div class="col">
            <label for="typeahead-http"><b>Search for a Manufacturer:</b></label>
            <input id="typeahead-http-manufacturer" type="text" class="form-control" [class.is-invalid]="searchFailedManufacturers"
              [(ngModel)]=manufacturer [ngbTypeahead]=searchManufacturers placeholder="Manufacturer search"
              (keyup.enter)="blurHTMLElement($event)" />
            <span *ngIf="searchingManufacturers">searching...</span>
            <div class="invalid-feedback" *ngIf="searchFailedManufacturers">Sorry, suggestions could not be loaded.
            </div>
            <pre>Manufacturer: {{ manufacturer | json }}</pre>
          </div>

          <!--  make -->
          <div class="col">
            <label for="typeahead-http"><b>Search for a Make:</b></label>
            <input id="typeahead-http-make" type="text" class="form-control" [class.is-invalid]="searchFailedMakes"
              [(ngModel)]=make [ngbTypeahead]=searchMakes placeholder="Make search"
              (keyup.enter)="blurHTMLElement($event)" />
            <span *ngIf="searchingMakes">searching...</span>
            <div class="invalid-feedback" *ngIf="searchFailedMakes">Sorry, suggestions could not be loaded.</div>
            <pre>Make: {{ make | json }}</pre>
          </div>

          <!-- model -->
          <div class="col">
            <label for="typeahead-http"><b>Search for a Model:</b></label>
            <input id="typeahead-http-model" type="text" class="form-control" [class.is-invalid]="searchFailedModels"
              [(ngModel)]=model [ngbTypeahead]=searchModels placeholder="Model search"
              (keyup.enter)="blurHTMLElement($event)" />
            <span *ngIf="searchingModels">searching...</span>
            <div class="invalid-feedback" *ngIf="searchFailedModels">Sorry, suggestions could not be
              loaded.
            </div>
            <pre>Model: {{ model | json }}</pre>
          </div>
        </div>

      </div>

      <!-- fetch button -->
      <div class="card-body">
        <div class="row">
          <span class="col">
            <button class="btn btn-primary w-100" (click)="getModels('')">FETCH MODEL LIST</button>
          </span>

          <span class="col">
            <button class="btn btn-danger w-100" [disabled]="MODELS?.length === 0" (click)="emptyModelList()">CLEAR
              MODEL
              LIST</button>
          </span>
        </div>
      </div>

      <!--      model list and confirmed list -->
      <div class="card-footer">
        <div class="model-list-container">
          <div class="row">
            <!-- document list browser -->
            <div *ngIf="MODELS?.length > 0" class="col-sm-12 {{shortList?.length > 0? 'col-md-7' : 'col-md-12'}}">

              <!-- results count -->
              <div class="row w-100 mb-1">
                <div class="col">
                  <h5 class="display-8">Matches ({{ MODELS?.length }}
                    {{ MODELS?.length > 1 || MODELS?.length == 0 ? 'results' : 'result' }})</h5>
                </div>

                <!-- select all button -->
                <div class="col">
                    <button class="btn btn-sm btn-warning mr-1" (click)="addAllToShortList()">Check All</button>
                    <button class="btn btn-sm btn-secondary ml-1" (click)="clearAllFromShortList()">Uncheck All</button>
                </div>
              </div>

              <!-- table  -->
              <table class="browser-list table table-hover shadow p-4 mb-2">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Manufacturer</th>
                    <th scope="col">Make</th>
                    <th scope="col">Model</th>
                    <th scope="col">Select <sup style="color:red">*</sup></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let model of MODELS  | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i = index"
                    class="model-entry">
                    <td>{{ model?.manufacturerName ? model?.manufacturerName : '' }}</td>
                    <td>{{ model?.makeName ? model?.makeName : '' }}</td>
                    <td>{{ model?.modelName ? model?.modelName : '' }}</td>
                    <td>
                      <label>
                        <input type="checkbox" [checked]="model.isSelected" class="form-check-input"
                          (change)="addToShortList(model)" *ngIf="!model.isSelected" />

                          <input type="checkbox" [checked]="model.isSelected" class="form-check-input"
                            (change)="removeFromShortList(model)"  *ngIf="model.isSelected" />
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- pagination -->
              <div class="d-flex justify-content-between">
                <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="MODELS.length"
                  [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                ></ngb-pagination>

                <label>
                  <select class="custom-select-sm" style="width: auto" [(ngModel)]="pageSize">
                    <option [ngValue]="10">10 items per page</option>
                    <option [ngValue]="20">20 items per page</option>
                    <option [ngValue]="50">50 items per page</option>
                  </select>
                </label>
              </div>

            </div>

            <!-- short list -->
            <div *ngIf="shortList?.length > 0" class="col-sm-12 col-md-5">
              <h5 class="display-8">Short List ({{ shortList?.length }}
                {{ shortList?.length > 1 || shortList?.length == 0 ? 'models' : 'model' }})</h5>
              <table class="short-list table table-hover shadow p-4 mb-4 col-md m-2">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Manufacturer</th>
                    <th scope="col">Model</th>
                    <th scope="col">Make</th>
                    <th scope="col">Remove?</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let model of shortList | slice: (_page-1) * _pageSize : (_page-1) * _pageSize + _pageSize; let i = index"
                    class="model-entry">
                    <td>{{ model?.manufacturerName ? model?.manufacturerName : '' }}</td>
                    <td>{{ model?.modelName ? model?.modelName : '' }}</td>
                    <td>{{ model?.makeName ? model?.makeName : '' }}</td>
                    <td>
                      <!-- remove button -->
                      <button (click)="removeFromShortList(model)">remove</button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- pagination -->
              <div class="d-flex justify-content-between">
                <ngb-pagination [(page)]="_page" [pageSize]="_pageSize" [collectionSize]="shortList.length"
                  [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                >
                </ngb-pagination>

                <label>
                  <select class="custom-select-sm" style="width: auto" [(ngModel)]="_pageSize">
                    <option [ngValue]="10">10 items per page</option>
                    <option [ngValue]="20">20 items per page</option>
                    <option [ngValue]="50">50 items per page</option>
                  </select>
                </label>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- configuration page -->
  <div *ngIf="activePage === 2" class="row">
    <!-- left -->
    <div class="col">
      <!-- document navigator -->
      <div class="card mb-3 bg-light border-info rounded">
        <!-- controls -->
        <div class="card-header">
          <div class="row">
            Aircraft Information
          </div>
        </div>

        <!-- Aircraft details container -->
        <div class="card-body">
          <div class="row columns">
            <app-user-aircraft-info-header-widget [userAircraft]="aircraft" [headerTitle]="'details'" [viewStyle]="'list'"></app-user-aircraft-info-header-widget>
          </div>
        </div>
      </div>

      <!-- applicability configuration -->
      <div class="card mb-3 bg-light border-info rounded">

        <form [formGroup]="applicabilityForm" (ngSubmit)="confirmDocument()">
          <!--header  -->
          <div class="card-header
          {{ editor.currentDocument.applicabilityForm !== undefined? 'bg-success text-white' : ''}}
          ">
            <!--DOCUMENT NAVIGATOR-->
            <!-- previous -->
            <span class="col-3">
              <fa-icon [icon]="['fas', 'arrow-circle-left']" size="xl" (click)="changeDocument(-1)"></fa-icon>
            </span>

            <!-- info -->
            <span class="col">No. {{ editor.currentDocumentIndex + 1 }} of {{ editor.documents.length }}
              ({{ editor.configuredDocumentCount }}
              completed, {{ editor.documents.length - editor.configuredDocumentCount }}
              remaining.)</span>

            <!-- next -->
            <span class="col-3">
              <fa-icon class="float-right" [icon]="['fas', 'arrow-circle-right']" size="xl" (click)="changeDocument(1)">
              </fa-icon>
            </span>
          </div>

          <!-- form body -->
          <div class="card-body
            {{ editor.currentDocument.applicabilityForm !== undefined? 'bg-palegreen' : ''}}
          ">
            <div>
              <!--CONFIGURED SIGN-->
              <small *ngIf="editor.currentDocument.applicabilityForm !== undefined"
                class="d-block text-success text-left">
                <i class="la la-check-circle"></i>
                Configured
              </small>

              <!--DOC NAME-->
              <h3>
                {{ type === 'airworthiness directives' ? 'Directive' : 'Bulletin' }}
                Name:
                {{ type === 'airworthiness directives' ? editor.currentDocument.directive_name : editor.currentDocument.bulletin_name }}
              </h3>

              <div class="row">
                <div class="col-12 mb-1">
                  <small class="text-muted pr-2">Subject:
                    {{ editor.currentDocument.subject }}</small>
                </div>

                <!--  effective date / published date -->
                <div class="col-sm-12 col-lg-4 col-md-6">
                  <div class="form-group">
                    <small
                      class="form-text text-muted">{{ editor.currentDocument.published_date ? 'Published Date' : 'Effective Date' }}</small>
                    <div class="input-group" *ngIf="type !== 'airworthiness directives'">
                      {{  editor.currentDocument.published_date | date:'MM/dd/yyyy'}}
                    </div>
                    <div class="input-group" *ngIf="type === 'airworthiness directives'">
                      {{  editor.currentDocument.effective_date | date:'MM/dd/yyyy'}}
                    </div>

                    <!-- 'is new' marker -->
                    <!-- TODO: add ngif -->
                    <div class="input-group badge badge-primary mr-3">
                      {{ type === 'airworthiness directives' ? getSixMonthsPrior(editor.currentDocument.effective_date) ? 'new' : '' : getSixMonthsPrior(editor.currentDocument.published_date) ? 'new' : '' }}
                    </div>
                  </div>
                </div>

                <!--  due date -->
                <div class="col-sm-12 col-lg-4 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Due Date</small>
                    <div class="input-group">
                      <input class="form-control" formControlName="dueDate" placeholder="mm/dd/yyyy" name="dueDate"
                        ngbDatepicker #due_date="ngbDatepicker" (blur)="makeClean($event)" />
                      <div class="input-group-append">
                        <!-- calendar button -->
                        <button class="btn btn-outline-secondary calendar" (click)="due_date.toggle()" type="button">
                          <i class="la la-calendar-plus-o"></i>
                        </button>
                      </div>
                    </div>

                    <!-- due now button -->
                    <button class="btn btn-sm w-100 btn-info calendar" (click)="dueNow()" type="button">
                      Due Now
                    </button>

                    <small class="form-text text-danger d-block"
                      *ngIf="applicabilityForm.get('dueDate').invalid && (applicabilityForm.get('dueDate').touched || applicabilityForm.get('dueDate').dirty)">
                      Enter Valid Due Date (and/or Due Hours)
                    </small>
                  </div>
                </div>

                <!--  due hours -->
                <div class="col-sm-12 col-lg-4 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Due Hours</small>
                    <div class="input-group">
                      <label>
                        <input class="form-control" formControlName="dueHours" placeholder="e.g 1200.5"
                          name="dueHours" />
                      </label>
                    </div>
                    <small class="form-text text-danger d-block"
                      *ngIf="applicabilityForm.get('dueHours').invalid && (applicabilityForm.get('dueHours').touched || applicabilityForm.get('dueHours').dirty)">
                      Enter Valid Due Hours (and/or Due Date)
                    </small>
                  </div>
                </div>

                <!--  condition -->
                <div class="col-sm-12 col-lg-6 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted required">Condition</small>
                    <div class="input-group">
                      <select class="form-control" formControlName="condition" placeholder="Condition" name="condition">
                        <option *ngFor="let condition of conditionOptions.conditions"
                          [selected]="applicabilityForm.controls['condition'].value == condition.value"
                          [value]="condition.value"> {{condition.label}}</option>
                      </select>
                    </div>
                    <small class="form-text text-danger d-block"
                      *ngIf="applicabilityForm.hasError('required', 'condition') && (applicabilityForm.get('condition').touched || applicabilityForm.get('condition').dirty)">
                      Select Condition
                    </small>
                    <small class="form-text text-danger d-block"
                      *ngIf="applicabilityForm.hasError('allControlsNeededIfValueIs', 'condition') &&!(applicabilityForm.hasError('required', 'condition'))">
                      You need to enter both <b>Due Date</b> and <b>Due Hours</b>
                    </small>
                  </div>
                </div>

                <!-- frequency -->
                <div class="col-sm-12 col-lg-6 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Frequency</small>
                    <div class="form-check form-check-inline mt-2">
                      <input class="form-check-input" type="checkbox" id="isRecurring" formControlName="isRecurring" />
                      <label class="form-check-label" for="isRecurring">Recurring?</label>
                    </div>
                  </div>
                </div>

                <!-- status for aircraft -->
                <div class="col-12">
                  <div class="row">
                    <div class="col-sm-12 col-lg-6 col-md-5">
                      <div class="form-group">
                        <small class="form-text text-muted required">Status for Aircraft</small>
                        <div class="input-group">
                          <label>
                            <select class="form-control" formControlName="application" placeholder="Status for Aircraft"
                              name="application">
                              <!--Directive Options-->
                              <ng-container *ngIf="type === 'airworthiness directives'">
                                <option *ngFor="let app of adApplicationOptions.applications" [value]="app.value" >
                                  {{app.label}}
                                </option>
                              </ng-container>

                              <!--Bulletin Options-->
                              <ng-container *ngIf="type !== 'airworthiness directives'">
                                <option *ngFor="let app of sbApplicationOptions.applications" [value]="app.value" >
                                  {{app.label}}
                                </option>
                              </ng-container>
                            </select>
                          </label>
                        </div>
                        <div
                          *ngIf="applicabilityForm.controls['application'].touched || applicabilityForm.controls['application'].dirty">
                          <small class="form-text text-danger d-block"
                            *ngIf="applicabilityForm.hasError('required', 'application')">
                            Select Status for Aircraft
                          </small>
                          <small class="form-text text-danger d-block"
                            *ngIf="applicabilityForm.hasError('hasDependantIfControlIs_Complied', 'application') || applicabilityForm.hasError('hasDependantIfControlIs_Performed', 'application')">
                            Fill <b>{{type === 'airworthiness directives'? 'Complied' : 'Performed'}} Date</b> field.
                          </small>
                          <small class="form-text text-danger d-block"
                            *ngIf="applicabilityForm.hasError('hasDependantIfControlIs_NotApply', 'application')">
                            Fill <b>Not Apply</b> field.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12 col-lg-6 col-md-7">
                      <div class="row" *ngIf="applicabilityForm.get('application').value == 'Complied' || applicabilityForm.get('application').value == 'Performed'">
                        <div class="col-sm-12 col-lg-7 col-md-7">

                          <!-- complied date -->
                          <div *ngIf="applicabilityForm.contains('lastCompliedDate')" class="col form-group">
                            <small class="form-text text-muted required">Complied Date</small>
                            <div class="input-group">
                              <label>
                                <input class="form-control" formControlName="lastCompliedDate" placeholder="yyyy-mm-dd"
                                  name="lastCompliedDate" ngbDatepicker #complied_date="ngbDatepicker" />
                              </label>
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="complied_date.toggle()"
                                  type="button">
                                  <i class="la la-calendar-plus-o"></i>
                                </button>
                              </div>
                            </div>
                            <small class="form-text text-danger d-block"
                              *ngIf="applicabilityForm.get('lastCompliedDate').invalid && (applicabilityForm.get('lastCompliedDate').touched || applicabilityForm.get('lastCompliedDate').dirty)">
                              Enter Complied Date
                            </small>
                          </div>

                          <!-- lastPerformedDate -->
                          <div *ngIf="applicabilityForm.contains('lastPerformedDate')" class="col form-group">
                            <small class="form-text text-muted required">Last Performed Date</small>
                            <div class="input-group">
                              <label>
                                <input class="form-control" formControlName="lastPerformedDate" placeholder="yyyy-mm-dd"
                                  name="lastPerformedDate" ngbDatepicker #last_performed_date="ngbDatepicker" />
                              </label>
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar"
                                  (click)="last_performed_date.toggle()" type="button">
                                  <i class="la la-calendar-plus-o"></i>
                                </button>
                              </div>
                            </div>
                            <small class="form-text text-danger d-block"
                              *ngIf="applicabilityForm.get('lastPerformedDate').invalid && (applicabilityForm.get('lastPerformedDate').touched || applicabilityForm.get('lastPerformedDate').dirty)">
                              Enter Last Performed Date
                            </small>
                          </div>
                        </div>

                        <!-- complied hour -->
                        <div *ngIf="applicabilityForm.contains('lastCompliedHour')" class="col form-group">
                          <small class="form-text text-muted">Compiled Hour</small>
                          <div class="input-group">
                            <label>
                              <input type="number" class="form-control" formControlName="lastCompliedHour"
                                placeholder="e.g 1200.5" name="lastCompliedHour" />
                            </label>
                          </div>
                          <small class="form-text text-danger d-block"
                            *ngIf="applicabilityForm.get('lastCompliedHour').invalid && (applicabilityForm.get('lastCompliedHour').touched || applicabilityForm.get('lastCompliedHour').dirty)">
                            Hours to tenths e.g 100.5
                          </small>
                        </div>


                        <!--last performed hours -->
                        <div *ngIf="applicabilityForm.contains('lastPerformedHour')" class="col form-group">
                          <small class="form-text text-muted">Last Performed Hour</small>
                          <div class="input-group">
                            <label>
                              <input type="number" class="form-control" formControlName="lastPerformedHour"
                                placeholder="e.g 1200.5" name="lastPerformedHour" />
                            </label>
                          </div>
                          <small class="form-text text-danger d-block"
                            *ngIf="applicabilityForm.get('lastPerformedHour').invalid && (applicabilityForm.get('lastPerformedHour').touched || applicabilityForm.get('lastPerformedHour').dirty)">
                            Hours to tenths e.g 100.5
                          </small>
                        </div>

                      </div>

                      <!-- does not apply reason -->
                      <div class="form-group" *ngIf="applicabilityForm.get('application').value == 'NotApply'">
                        <small class="form-text text-muted required">Does not apply reason</small>
                        <div class="input-group">
                          <label>
                            <input class="form-control" formControlName="notApplyReason" placeholder="Not Apply Reason"
                              name="notApplyReason">
                          </label>
                        </div>
                        <small class="form-text text-danger d-block"
                          *ngIf="applicabilityForm.get('notApplyReason').invalid && (applicabilityForm.get('notApplyReason').touched || applicabilityForm.get('notApplyReason').dirty)">
                          Enter 'Not Apply' reason
                        </small>
                      </div>
                    </div>

                    <!-- compliance/performance note -->
                    <div class="col-12" *ngIf="applicabilityForm.get('application').value == 'Complied' || applicabilityForm.get('application').value == 'Performed'">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <small class="form-text text-muted">
                              {{ type === 'airworthiness directives' ? 'Compliance Note' : 'Performance Note' }}</small>
                            <div class="input-group">
                              <textarea *ngIf="type === 'airworthiness directives'" class="form-control w-100"
                                formControlName="complianceNote" name="complianceNote" id="complianceNote"
                                placeholder="Compliance Note" rows="4"></textarea>
                              <textarea *ngIf="type !== 'airworthiness directives'" class="form-control w-100"
                                formControlName="performanceNote" name="performanceNote" id="performanceNote"
                                placeholder="Performance Note" rows="4"></textarea>
                            </div>
                          </div>
                        </div>

                        <!-- terminating action -->
                        <div class="col-12">
                          <div class="form-group">
                            <small class="form-text text-muted">Terminating Action</small>
                            <div class="form-check form-check-inline mt-2">
                              <input class="form-check-input" type="checkbox" id="terminateAction"
                                formControlName="terminateAction" />
                              <label class="form-check-label" for="terminateAction">Check to Terminate</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>


          </div>
          <!-- footer -->
          <div class="card-footer">
            <div class="row">
              <!-- add directive button -->
              <div class="col" *ngIf="editor.currentDocument.applicabilityForm === undefined">
                <button class="btn btn-outline-primary w-100">
                  <i class="la la-plus-circle"></i>
                  Add Doc
                </button>
              </div>

              <!-- Un-add directive button -->
              <div class="col" *ngIf="editor.currentDocument.applicabilityForm !== undefined">
                <a href="javascript: void(0);" class="btn btn-outline-primary w-100" (click)="resetDocument()">
                  <i class="la la-minus-circle"></i>
                  Reset
                </a>
              </div>

              <!-- discard document button -->
              <div class="col">
                <button class="btn btn-outline-danger w-100" (click)="discardDocument($event)">
                  <i class="la la-trash"></i>
                  Discard Doc
                </button>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>

    <!-- right -->
    <div class="col" id="pdf-viewer">
      <!-- pdf -->
      <ngx-extended-pdf-viewer *ngIf="editor.currentDocument.file_url" [src]="editor.currentDocument.file_url"
        useBrowserLocale="true" class="w-100 h-100" backgroundColor="info"></ngx-extended-pdf-viewer>

      <!-- no document notification -->
      <div class="w-100 h-100 d-flex bg-warning justify-content-center align-content-conter"
        *ngIf="!editor.currentDocument.file_url">
        <h1>No Document Found!</h1>
      </div>
    </div>

  </div>

  <!-- confirmation page -->
  <div *ngIf="activePage === 3" class="card mb-3">
    <!-- confirmation table -->
    <table class="table table-hover shadow p-4 mb-2">
      <thead class="thead-dark">
        <tr>
          <th scope="col">{{ type === 'airworthiness directives' ? 'Directive Name' : 'Bulletin Name' }}</th>

          <th scope="col">Type</th>
          <th scope="col">Make</th>
          <th scope="col">{{ type === 'airworthiness directives' ? 'Effective Date' : 'Published Date' }}</th>
          <!--        <th scope="col" *ngIf="type === 'airworthiness directives'">Mandatory?</th>-->
          <th scope="col">Recurring?</th>
          <th scope="col">Mandatory?</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let document of editor.documents | configuredDocuments; let i = index" class="model-entry">
          <td *ngIf="type === 'airworthiness directives'">
            {{ document?.directive_name ? document?.directive_name : 'N/A' }}</td>
          <td *ngIf="type !== 'airworthiness directives'">
            {{ document?.bulletin_name ? document?.bulletin_name : 'N/A' }}</td>

          <td>{{ document?.ad_sb_type ? document?.ad_sb_type : '' }}</td>
          <td>{{ document?.make_name ? document?.make_name : '' }}</td>

          <!-- effective date -->
          <td *ngIf="type === 'airworthiness directives'">{{ document?.effective_date | date:'MM/dd/yyyy' }}</td>

          <!-- published date -->
          <td *ngIf="type !== 'airworthiness directives'">{{ document?.published_date | date:'MM/dd/yyyy' }}</td>

          <!--  is recurring  -->
          <td>
            <label>
              <input type="checkbox" [ngModel]="document?.applicabilityForm.isRecurring" class="form-check-input"
                disabled />
            </label>
          </td>

          <!--  mandatory -->
          <td>
            <label>
              <input type="checkbox" [ngModel]="type === 'airworthiness directives'" class="form-check-input"
                disabled />
            </label>
          </td>

        </tr>
      </tbody>
    </table>
  </div>

  <!-- document attachment results page -->
  <div *ngIf="activePage === 4" class="row">
    <table class="table table-hover shadow p-4 mb-2">
      <thead class="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Success?</th>
          <th scope="col">Message</th>
          <th scope="col">Reason<sup style="color:red">*</sup></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let log of editor.attachmentLogs; let i = index" class="model-entry">
          <td>{{ i }}</td>
          <td>{{ log.success !== null ? log.success : 'attaching...' }}</td>
          <td>{{ log.message ? log.message : 'attaching...' }}</td>
          <td>{{ log.reason ? log.reason : 'attaching...' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- footer -->
<div class="modal-footer">
  <!-- page 1 footer buttons -->
  <div class="row w-100" *ngIf="activePage === 1">
    <!-- confirm short list button  -->
    <div class="col">
      <button class="btn btn-success w-100" [disabled]="shortList?.length === 0" (click)="confirmShortList()">CONFIRM
        SHORT LIST
      </button>
    </div>
    <!-- clear list button  -->
    <div class="col">
      <button class="btn btn-danger w-100" [disabled]="shortList?.length === 0" (click)="emptyShortList()">CLEAR SHORT
        LIST
      </button>
    </div>
  </div>

  <!-- page 2 footer buttons -->
  <div class="row w-100" *ngIf="activePage === 2">
    <!-- save state and exit config form -->
    <div class="col">
      <button class="btn btn-warning  w-100" (click)="saveStateAndExit()">EXIT & SAVE</button>
    </div>
    <!-- save state and exit config form -->
    <div class="col">
      <button class="btn btn-danger  w-100" (click)="closeEditor(false)">EXIT</button>
    </div>
    <!-- confirm document configuration -->
    <div class="col">
      <button class="btn btn-success w-100" [disabled]="editor.configuredDocumentCount === 0"
        (click)="confirmDocumentConfiguration()">CONFIRM
      </button>
    </div>
  </div>

  <!-- page 3 footer buttons -->
  <div class="row w-100" *ngIf="activePage === 3">
    <!-- back button -->
    <div class="col">
      <button class="btn btn-warning w-100" (click)="backToDocumentConfiguration()">Back</button>
    </div>
    <!-- attach button -->
    <div class="col">
      <button class="btn btn-primary w-100" (click)="AttachDocuments()">Attach</button>
    </div>
  </div>

  <!-- page 4 footer buttons -->
  <div class="row w-100" *ngIf="activePage === 4">
    <!-- clear list button  -->
    <div class="col">
      <button class="btn btn-success w-100 mr-4" (click)="closeEditor(false)">FINISH</button>
    </div>
  </div>
</div>
