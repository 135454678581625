import { UserBaseModel } from './user-base-model';

export class AircraftAdminDates{
  constructor(){
    this.registrationDueDate = null;
    this.insuranceDueDate = null;
  }

  registrationDueDate: string;
  insuranceDueDate: string;
}
