<h4>Add New User</h4>

<h6 class="mb-0">
  <i class="la la-user"></i>
  {{ createUserForm.get('firstName').value? createUserForm.get('firstName').value : '-----' }}
  {{ createUserForm.get('lastName').value? createUserForm.get('lastName').value: '-----'  }} /
  {{ createUserForm.get('pilotLicenseNo').value? createUserForm.get('pilotLicenseNo').value: '-----'  }}
</h6>
<small class="text-muted mt-1 mb-3">Customer Name / Pilot Certificate Number</small>

<section class="mt-3">
  <div class="card shadow border-0 admin-create-user">
    <div class="card-header">
      Create New Customer
    </div>
    <div class="card-body">
      <form [formGroup]="createUserForm" (ngSubmit)="doCreateUser()">
        <div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Enter customer's first name.</small>
                <input type="text" formControlName="firstName" class="form-control" id="firstname" aria-describedby="firstname_help" placeholder="First Name">
                <small class="form-text text-danger" *ngIf="createUserForm.get('firstName').invalid && (createUserForm.get('firstName').dirty || createUserForm.get('firstName').touched)">
                  Enter valid first name.
                </small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Enter customer's last name.</small>
                <input type="text" formControlName="lastName" class="form-control" id="lastname" aria-describedby="lastname_help" placeholder="Last Name">
                <small class="form-text text-danger" *ngIf="createUserForm.get('lastName').invalid && (createUserForm.get('lastName').dirty || createUserForm.get('lastName').touched)">
                  Enter valid last name.
                </small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Enter customer's email address.</small>
                <input type="text" formControlName="emailAddress" class="form-control" id="emailAddress" aria-describedby="email_help" placeholder="Email Address">
                <div>
                <small class="form-text text-danger" *ngIf="createUserForm.get('emailAddress').invalid && (createUserForm.get('emailAddress').dirty || createUserForm.get('emailAddress').touched)">
                  Enter valid email address.
                </small>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted">Phone Number (with country code).</small>
                <input type="text" formControlName="mobilePhone" class="form-control" id="mobilePhone" placeholder="Phone Number">
                <small class="form-text text-danger" *ngIf="createUserForm.get('mobilePhone').invalid && (createUserForm.get('mobilePhone').dirty || createUserForm.get('mobilePhone').touched)">
                  Enter a valid phone number with country code<b> e.g +1 201 XXX XXXX</b>.
                </small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Password (min. of 6 characters and numbers).</small>
                <input type="password" formControlName="password1" class="form-control" id="password1" placeholder="Password">
                <div *ngIf="createUserForm.get('password1').dirty || createUserForm.get('password1').touched">
                  <small class="form-text text-danger" *ngIf="createUserForm.hasError('required', 'password1')">
                    Password is required.
                  </small>
                  <div *ngIf="!createUserForm.hasError('required', 'password1')">
                    <small class="form-text text-danger" *ngIf="createUserForm.hasError('pattern', 'password1')">
                      Minimum of 6 characters; Must have a number, an uppercase letter, and a lowercase letter.
                    </small>
                    <small class="form-text text-danger" *ngIf="createUserForm.hasError('mustBeSame', 'password1') && !(createUserForm.hasError('pattern', 'password1'))">
                      Password and Confirm password must be the same.
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small id="mobilephone_help" class="form-text text-muted required">Confirm Password.</small>
                <input type="password" formControlName="password2" class="form-control" id="password2" placeholder="Confirm Password">
                <small class="form-text text-danger" *ngIf="createUserForm.hasError('required', 'password2') && (createUserForm.get('password2').dirty || createUserForm.get('password2').touched)">
                  Confirm Password Required
                </small>
                <small class="form-text text-danger" *ngIf="createUserForm.hasError('mustBeSame', 'password2') && !(createUserForm.hasError('required', 'password2') )">
                  <b>Confirm Password</b> must be same as <b>Password</b>.
                </small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted required">Customer's Pilot Certificate #.</small>
                <input type="text" formControlName="pilotLicenseNo" class="form-control" id="pilot_certificate" aria-describedby="pilot_cert_help" placeholder="Pilot Certificate #">
                <small class="form-text text-danger" *ngIf="createUserForm.get('pilotLicenseNo').invalid && (createUserForm.get('pilotLicenseNo').dirty || createUserForm.get('pilotLicenseNo').touched)">
                  Minimum of 4 characters, maximum of 8 characters
                </small>
              </div>
            </div>

            <div class="col-sm-12 col-md-6">
              <div class="form-group">
                <small class="form-text text-muted">Role</small>
                <div class="form-check py-2">
                  <input class="form-check-input" formControlName="isAdmin"  type="checkbox" id="isadmin">
                  <label class="form-check-label" for="isadmin">
                    Create user as admin?
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right mt-2">
          <a class="btn btn-outline-danger mr-3" [routerLink]="['/cga/admin/console']">Cancel</a>
          <button class="btn btn-outline-primary">Create Customer</button>
        </div>
      </form>
    </div>
  </div>
</section>
