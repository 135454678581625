import { distinctUntilChanged } from 'rxjs/operators';
import { PilotDatesModel } from '../../../../models/pilot-dates-model';
import { SubscriptionLike } from 'rxjs';
import { PilotStatusService } from '../../../../services/pilot-status.service';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../services/user.service';
import { NgbDateNativeAdapter, NgbDateAdapter, NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomAndUSAParserFormatter } from '../../../../services/ngb-date-custom-and-usa-parser-formatter';
import { Component, OnInit, Input, OnDestroy, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { UserModel } from '../../../../models/user-model';
import Swal from 'sweetalert2';
import { DependsOnControlValidator } from '../../../../_custom-validators/depends-on-control-validator';
import { HasDependantControlValidator } from '../../../../_custom-validators/has-dependant-control-validator';
import { GreaterThanControlsValidator } from '../../../../_custom-validators/greater-than-controls-validator';
import { LessThanControlsValidator } from '../../../../_custom-validators/less-than-controls-validator';

@Component({
  selector: 'app-pilot-dates',
  templateUrl: './pilot-dates.component.html',
  styleUrls: ['./pilot-dates.component.scss'],
  providers: [
    NgbDatepickerConfig,
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomAndUSAParserFormatter}
  ]
})
export class PilotDatesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() userProfile: UserModel;
  @Output() refetchUserEmitter: EventEmitter<boolean> = new EventEmitter();
  @Input() viewupdateMode: boolean;

  constructor(
    private ngbDatePickerConfig: NgbDatepickerConfig,
    private userService: UserService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private pilotDatesService: PilotStatusService
  ) {
    ngbDatePickerConfig.minDate = {year: 1900, month: 1, day: 1};
  }
  isLoading: boolean;

  inUpdateMode: boolean;

  pilotDatesForm: FormGroup;

  pilotDates: PilotDatesModel = new PilotDatesModel;

  medicalDueDateSubscriber$: SubscriptionLike;

  lastMedicalDateSubscriber$: SubscriptionLike;

  lastFlightReviewDateSubscriber$: SubscriptionLike;

  flightReviewDueDateSubscriber$: SubscriptionLike;

  ngOnInit() {
    this.isLoading = true;
    this.inUpdateMode = false;

    this.initDashDatesForm();

    if (this.userProfile.emailAddress) {
      this.getUserPilotDates();
    }

    this.route.queryParams.subscribe(params => {
      if (params['viewupdateMode']) {
        this.viewupdateMode = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userProfile && changes.userProfile.currentValue) {
      this.userProfile = changes.userProfile.currentValue;

      if (this.userProfile.emailAddress) {
        this.inUpdateMode = false;
        this.getUserPilotDates();
      }
  
    }
  }

  getUserPilotDates() {
    this.isLoading = true;
    this.userService.getUserV2(this.userProfile.emailAddress).subscribe(
      (response: any) => {
        this.isLoading = false;

        this.pilotDates.medicalDueDate = response.medicalDueDate;
        this.pilotDates.lastMedicalDate = response.lastMedicalDate;
        this.pilotDates.flightReviewDueDate = response.flightReviewDueDate;
        this.pilotDates.lastFlightReviewDate = response.lastFlightReviewDate;

        if (this.viewupdateMode) {
          this.loadForUpdateDashDatesForm();
        }

      },
      (error: any) => {
        this.isLoading = false;
      }
    );
  }

  initDashDatesForm(){
    this.pilotDatesForm = this.formBuilder.group({
      'lastMedicalDate': new FormControl (null),
      'medicalDueDate': new FormControl (null),

      'lastFlightReviewDate': new FormControl (null),
      'flightReviewDueDate': new FormControl (null)
    });

    // Validations
    this.pilotDatesForm.get('lastMedicalDate').setValidators([
      Validators.required,
      DependsOnControlValidator(this.pilotDatesForm.get('medicalDueDate')),
      HasDependantControlValidator(this.pilotDatesForm.get('medicalDueDate')),
      LessThanControlsValidator(false, [this.pilotDatesForm.get('medicalDueDate')])
    ]);

    this.pilotDatesForm.get('medicalDueDate').setValidators([
      Validators.required,
      DependsOnControlValidator(this.pilotDatesForm.get('lastMedicalDate')),
      HasDependantControlValidator(this.pilotDatesForm.get('lastMedicalDate')),
      GreaterThanControlsValidator(false, [this.pilotDatesForm.get('lastMedicalDate')])
    ]);

    this.pilotDatesForm.get('lastFlightReviewDate').setValidators([
      Validators.required,
      DependsOnControlValidator(this.pilotDatesForm.get('flightReviewDueDate')),
      HasDependantControlValidator(this.pilotDatesForm.get('flightReviewDueDate')),
      LessThanControlsValidator(false, [this.pilotDatesForm.get('flightReviewDueDate')])
    ]);

    this.pilotDatesForm.get('flightReviewDueDate').setValidators([
      Validators.required,
      DependsOnControlValidator(this.pilotDatesForm.get('lastFlightReviewDate')),
      HasDependantControlValidator(this.pilotDatesForm.get('lastFlightReviewDate')),
      GreaterThanControlsValidator(false, [this.pilotDatesForm.get('lastFlightReviewDate')])
    ]);

    ///////////


    // --subscribe to medical date inputs
    this.lastMedicalDateSubscriber$ = this.pilotDatesForm.get('lastMedicalDate').valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(
      (value: any) => {
        this.pilotDatesForm.get('medicalDueDate').updateValueAndValidity();
      }
    );

    this.medicalDueDateSubscriber$ = this.pilotDatesForm.get('medicalDueDate').valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(
      (value: any) => {
        this.pilotDatesForm.get('lastMedicalDate').updateValueAndValidity();
      }
    );


    // --subscribe to flight review inputs
    this.lastFlightReviewDateSubscriber$ = this.pilotDatesForm.get('lastFlightReviewDate').valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(
      (value: any) => {
        this.pilotDatesForm.get('flightReviewDueDate').updateValueAndValidity();
      }
    );
    this.flightReviewDueDateSubscriber$ = this.pilotDatesForm.get('flightReviewDueDate').valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(
      (value: any) => {
        this.pilotDatesForm.get('lastFlightReviewDate').updateValueAndValidity();
      }
    );
  }


  loadForUpdateDashDatesForm() {
    this.pilotDatesForm.get('lastMedicalDate').setValue(
      this.pilotDates.lastMedicalDate ? new Date(this.pilotDates.lastMedicalDate) : null
      ),
    this.pilotDatesForm.get('medicalDueDate').setValue(
      this.pilotDates.medicalDueDate ? new Date(this.pilotDates.medicalDueDate) : null
      ),
    this.pilotDatesForm.get('lastFlightReviewDate').setValue(
      this.pilotDates.lastFlightReviewDate ? new Date(this.pilotDates.lastFlightReviewDate) : null
      ),
    this.pilotDatesForm.get('flightReviewDueDate').setValue(
      this.pilotDates.flightReviewDueDate ? new Date(this.pilotDates.flightReviewDueDate) : null
      );

    this.inUpdateMode = true;
  }


  cancelPostUserPilotDates() {
    this.pilotDatesForm.reset();
    this.inUpdateMode = false;
  }

  postUserPilotDates() {
    if (!this.pilotDatesForm.valid) {
      Swal.fire(
        'Pilot dates form contains invalid fields!',
        'Check all fields and ensure they have been correctly filled.',
        'error');

      Object.values(this.pilotDatesForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );
      return;
    }

    this.isLoading = true;


    this.pilotDates.firstName = this.userProfile.firstName;
    this.pilotDates.lastName = this.userProfile.lastName;
    this.pilotDates.pilotLicenseNo = this.userProfile.pilotLicenseNo;

    this.pilotDates.lastMedicalDate = this.pilotDatesForm.get('lastMedicalDate').value;
    this.pilotDates.medicalDueDate = this.pilotDatesForm.get('medicalDueDate').value;
    this.pilotDates.lastFlightReviewDate = this.pilotDatesForm.get('lastFlightReviewDate').value;
    this.pilotDates.flightReviewDueDate = this.pilotDatesForm.get('flightReviewDueDate').value;

    this.pilotDatesService.updateUserPilotDatesV2(this.userProfile.emailAddress, this.pilotDates).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.inUpdateMode = false;
        this.getUserPilotDates();

        Swal.fire(
          'Great!',
          'Pilot dates updated',
          'success'
        );
      },
      (error) => {
        this.isLoading = false;

        if (error.status.message) {
          Swal.fire(
            error.status.message,
            error.responseData.join(' '),
            'error'
          );
        } else {
          Swal.fire(
            'Oops!',
            'Update Pilot Dash Dates failed.',
            'error'
          );
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.medicalDueDateSubscriber$) {
      this.medicalDueDateSubscriber$.unsubscribe();
    }

    if (this.lastMedicalDateSubscriber$) {
      this.lastMedicalDateSubscriber$.unsubscribe();
    }

    if (this.lastFlightReviewDateSubscriber$) {
      this.lastFlightReviewDateSubscriber$.unsubscribe();
    }

    if (this.flightReviewDueDateSubscriber$) {
      this.flightReviewDueDateSubscriber$.unsubscribe();
    }
  }

}
