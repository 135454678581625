<ng-container>
  <section class="mt-3">
    <div class="form_header clearfix">
      <h5 class="uppercase form_header float-left">
        Aircraft Maintenance History
      </h5>
    </div>

    <div class="card shadow border-0 mt-3 rounded-0 p-3">
      <div class="card-body p-3">
          <div>
            <div class="row">

              <div class="col-sm-12 col-lg-6 col-md-12">
                <h6 class="text-muted">
                  <i class="la la-calendar-check-o"></i>
                  Last Annual Date
                </h6>
            
                <h5>
                  {{ 
                    selectedAircraft.maintenanceStatus && 
                    selectedAircraft.maintenanceStatus.annuals &&
                    selectedAircraft.maintenanceStatus.annuals.date
                    ? (selectedAircraft.maintenanceStatus.annuals.date | date:'MM/dd/yyyy') : "--"  
                  }}
                </h5>
              </div>

              <div class="col-sm-12 col-lg-6 col-md-12">
                <h6 class="text-muted">
                  <i class="la la-hourglass-end"></i>
                  Last Annual Hours
                </h6>

                <h5>
                  {{ 
                    selectedAircraft.maintenanceStatus && 
                    selectedAircraft.maintenanceStatus.annuals &&
                    selectedAircraft.maintenanceStatus.annuals.time
                    ? selectedAircraft.maintenanceStatus.annuals.time  : "--"  
                  }}
                  <small>Hours</small>
                </h5>
              </div>

              <div class="col-sm-12 col-lg-6 col-md-12">
                <h6 class="text-muted">
                  <i class="la la-hourglass-end"></i>
                  Last 100 Hour
                </h6>
                
                <h5>
                  {{ 
                    selectedAircraft.maintenanceStatus && 
                    selectedAircraft.maintenanceStatus.hundredHours &&
                    selectedAircraft.maintenanceStatus.hundredHours.time
                    ? selectedAircraft.maintenanceStatus.hundredHours.time : "--"  
                  }}
                  <small>Hours</small>
                </h5>
              </div>

              <div class="col-sm-12 col-lg-6 col-md-12">
                <h6 class="text-muted">
                  <i class="la la-filter"></i>
                  Last Oil Change (Hrs)
                </h6>

                <h5>
                  {{ 
                    selectedAircraft.maintenanceStatus && 
                    selectedAircraft.maintenanceStatus.oilChange &&
                    selectedAircraft.maintenanceStatus.oilChange.time
                    ? (selectedAircraft.maintenanceStatus.oilChange.time) : "--"  
                  }}
                  <small>Hours</small>
                </h5>
              </div>

              <div class="col-sm-12 col-lg-6 col-md-12">
                <h6 class="text-muted">
                  <i class="la la-warning"></i>
                  Last ELT Inspection
                </h6>

                <h5>
                  {{ 
                    selectedAircraft.maintenanceStatus && 
                    selectedAircraft.maintenanceStatus.elts &&
                    selectedAircraft.maintenanceStatus.elts.date
                    ? (selectedAircraft.maintenanceStatus.elts.date | date:'MM/dd/yyyy') : "--"  
                  }}
                </h5>
              </div>

              <div class="col-sm-12 col-lg-6 col-md-12">
                <h6 class="text-muted">
                  <i class="la la-dot-circle-o"></i>
                  Last Pitot-static Inspection 
                </h6>
              
                <h5>
                  {{ 
                    selectedAircraft.maintenanceStatus && 
                    selectedAircraft.maintenanceStatus.pitotics &&
                    selectedAircraft.maintenanceStatus.pitotics.date
                    ? (selectedAircraft.maintenanceStatus.pitotics.date | date:'MM/dd/yyyy') : "--"  
                  }}
                </h5>
              </div>

              <div class="col-sm-12 col-lg-6 col-md-12">
                <h6 class="text-muted">
                  <i class="la la-headphones"></i>
                  Last Transponder Inspection 
                </h6>

                <h5>
                  {{ 
                    selectedAircraft.maintenanceStatus && 
                    selectedAircraft.maintenanceStatus.transponders &&
                    selectedAircraft.maintenanceStatus.transponders.date
                    ? (selectedAircraft.maintenanceStatus.transponders.date | date:'MM/dd/yyyy') : "--"  
                  }}
                </h5>
              </div>

              <div class="col-sm-12 col-lg-6 col-md-12">
                <h6 class="text-muted">
                  <i class="la la-tachometer"></i>
                  Last Altimeter Inspection 
                </h6>
              
                <h5>
                  {{ 
                    selectedAircraft.maintenanceStatus && 
                    selectedAircraft.maintenanceStatus.altimeters &&
                    selectedAircraft.maintenanceStatus.altimeters.date
                    ? (selectedAircraft.maintenanceStatus.altimeters.date | date:'MM/dd/yyyy') : "--"  
                  }}
                </h5>
              </div>

            </div>
          </div>
      </div>
    </div>
  </section>

</ng-container>
