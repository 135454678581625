import {AircraftService} from './../../../services/aircraft.service';
import {UserAircraftModel} from '../../../models/user-aircraft-model';
import {SubscriptionLike, of, Subject} from 'rxjs';
import {Component, OnInit, OnDestroy, Input, SimpleChanges} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserMenuAircraftModel } from '../../../models/user-menu-aircraft-model';
import { AircraftV2Model } from '../../../models/airfcraftV2/aircraft-v2.model';
import { UserModel } from '../../../models/user-model';
import { UserService } from '../../../services/user.service';
import { OrganizationService } from '../../../services/organization.service';
import { UserOrganizationAndRole } from '../../../models/user-organization-and-role';

@Component({
  selector: 'app-aircraft-flight-log',
  templateUrl: './aircraft-flight-log.component.html',
  styleUrls: ['./aircraft-flight-log.component.scss']
})
export class AircraftFlightLogComponent implements OnInit, OnDestroy {
  @Input() inAdminView: boolean;
  @Input() inViewOnly: boolean;
  @Input() quickViewLogs: boolean;
  @Input() aircraftTailNo: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private aircraftService: AircraftService,
    private orgService: OrganizationService,
    private userService: UserService
  ) {
  }

  isLoading: boolean;

  selectedAircraft: AircraftV2Model = new AircraftV2Model;
  selectedAircraft$: SubscriptionLike;

  selectedOrg: UserOrganizationAndRole = new UserOrganizationAndRole;
  selectedOrg$: SubscriptionLike;

  currentUser: UserModel = new UserModel;
  currentUser$: SubscriptionLike;

  aircraftTailNoParamsSubscriber$: SubscriptionLike;
  theAircraft: AircraftV2Model = new AircraftV2Model;

  tabChangeEventsSubject: Subject<void> = new Subject<void>();

  ngOnInit() {
    // init defaults
    this.isLoading = false;

    // subscribe to current user
    this.currentUser$ = this.userService.currentUser$.subscribe(
      (user: UserModel) => {
        this.currentUser = user;
      }
    );

    // subscribe to selected Org
    this.selectedOrg$ = this.orgService.selectedOrg$.subscribe(
      (org: UserOrganizationAndRole) => {
        this.selectedOrg = org;
      }
    );

    // subscribe to selected Aircraft
    this.selectedAircraft.id = undefined;
    this.selectedAircraft$ = this.aircraftService.selectedAircraft$.subscribe(
      (aircraft: AircraftV2Model) => {
        this.selectedAircraft = aircraft;

        if (this.inAdminView) {
         this.aircraftTailNo = this.route.snapshot.paramMap.get('tailNo');
         this.inViewOnly = true;
        } else {
          this.theAircraft = this.selectedAircraft  ;
          this.aircraftTailNo = null;
        }

        if (this.aircraftTailNo) {
          this.isLoading = true;
           this.getAircraftV2(this.aircraftTailNo);
          // this.theAircraft = this.selectedAircraft;
        } else {
          this.isLoading = false;
        }
      }
    );
  }

  checkLogEntryAccess() {
    if (!this.hasAircraftWriteAccess()) {
      this.router.navigate(['/cga/dashboard']);
    }
    return;
  }


  hasAircraftWriteAccess() {
    if (
      (this.selectedOrg.role && this.selectedOrg.role.toString().toUpperCase() === 'ADMIN') ||
        this.currentUser.isAdmin
    ) {
      return true;
    }

    return this.selectedAircraft.userRole
    && (
      this.selectedAircraft.userRole.toString().toUpperCase() === 'INDIVIDUAL_RW'
    );
  }

  getAircraftV2(tailNo: string) {
    if (!this.inViewOnly) {
      this.checkLogEntryAccess();
    }

    if (!tailNo) {
      return;
    }

    this.isLoading = true;
    this.aircraftService.getAircraftV2(tailNo).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.theAircraft = response;
        this.aircraftService.setSelectedAircraft(response);
      },
      (error: any) => {
        this.isLoading = false;
      }
    );
  }

  refetchAircraft() {
    this.getAircraftV2(this.theAircraft.tailNo);
  }


  onTabChange() {
    this.tabChangeEventsSubject.next();
  }

  ngOnDestroy() {
    if (this.aircraftTailNoParamsSubscriber$) {
      this.aircraftTailNoParamsSubscriber$.unsubscribe();
    }

    if (this.selectedAircraft$) {
      this.selectedAircraft$.unsubscribe();
    }

    if (this.selectedOrg$) {
      this.selectedOrg$.unsubscribe();
    }
  }
}
