<app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

<!---Manage VOR Checks -->
<section class="p-3 nav-data-updates" *ngIf="!isWorking">
    <div class="card shadow border-0">
      <div class="card-header clearfix">
        &nbsp;
        <span class="float-right" *ngIf="hasEditRole()">
          <button class="btn btn-sm btn-outline-dark rounded" (click)="toggleInNewVorCheck()">
            {{ inNewVorCheckMode? 'View All VOR Checks' : 'New VOR Check' }}
            <i class="la la-plus" *ngIf="!inNewVorCheckMode"></i>
            <i class="la la-th-list"  *ngIf="inNewVorCheckMode"></i>
          </button>
        </span>
      </div>
      <div class="card-body">

        <app-preloader-type-a *ngIf="isFetchingVorChecks"></app-preloader-type-a>

        <div *ngIf="!isFetchingVorChecks">

          <!--NEW VOR Check-->
          <div class="card mb-3 bg-light border-info rounded" *ngIf="inNewVorCheckMode">
            <!--<div class="card-header">
              New VOR Check
            </div>-->
            <div class="card-body">
              <h4>New VOR Check</h4>
              <form [formGroup]="newVorCheckForm" (ngSubmit)="addNewVorCheckForm()">
                <div>
                  <div class="row">

                    <div class="col-sm-12 col-lg-8 col-md-12">
                      <div class="form-group">
                        <small class="form-text text-muted required">VOR Source</small>
                        <div class="input-group">
                          <input class="form-control" formControlName="vorSource"  placeholder="VOR Source" name="vorSource">
                        </div>
                        <small class="text-danger d-block" *ngIf="newVorCheckForm.get('vorSource').invalid && (newVorCheckForm.get('vorSource').touched || newVorCheckForm.get('vorSource').dirty)">Enter VOR Source.</small>
                      </div>
                    </div>

                    <div class="col-sm-12 col-lg-4 col-md-6">
                      <div class="form-group">
                        <small class="form-text text-muted required">Radial</small>
                        <div class="input-group">
                          <input class="form-control" formControlName="radial"  placeholder="Radial" name="radial">
                        </div>
                        <small class="text-danger d-block" *ngIf="newVorCheckForm.get('radial').invalid && (newVorCheckForm.get('radial').touched || newVorCheckForm.get('radial').dirty)">Enter Radial value from 1 to 360.</small>
                      </div>
                    </div>

                    <div class="col-sm-12 col-lg-4 col-md-6">
                      <div class="form-group">
                        <small class="form-text text-muted required">Radial Direction</small>
                        <div class="input-group">
                          <select class="form-control" formControlName="radialDirection"  placeholder="Radial Direction" name="radialDirection">
                            <option value=""></option>
                            <option value="to">To</option>
                            <option value="from">From</option>
                          </select>
                        </div>
                        <small class="text-danger d-block" *ngIf="newVorCheckForm.get('radialDirection').invalid && (newVorCheckForm.get('radialDirection').touched || newVorCheckForm.get('radialDirection').dirty)">Select Radial value direction.</small>
                      </div>
                    </div>

                    <div class="col-sm-12 col-lg-4 col-md-6">
                      <div class="form-group">
                        <small class="form-text text-muted required">Distance (Miles)</small>
                        <div class="input-group">
                          <input class="form-control" formControlName="distanceMiles"  placeholder="Distance (Miles)" name="distanceMiles">
                        </div>
                        <small class="text-danger d-block" *ngIf="newVorCheckForm.get('distanceMiles').invalid && (newVorCheckForm.get('distanceMiles').touched || newVorCheckForm.get('distanceMiles').dirty)">Enter distance (miles).</small>
                      </div>
                    </div>

                    <div class="col-sm-12 col-lg-4 col-md-6">
                      <div class="form-group">
                        <small class="form-text text-muted required">Error Degrees</small>
                        <div class="input-group">
                          <input class="form-control" formControlName="errorDegrees"  placeholder="Error Degrees" name="errorDegrees">
                        </div>
                        <small class="text-danger d-block" *ngIf="newVorCheckForm.get('errorDegrees').invalid && (newVorCheckForm.get('errorDegrees').touched || newVorCheckForm.get('errorDegrees').dirty)">Error degrees value from 1 to 90.</small>
                      </div>
                    </div>

                    <div class="col-sm-12 col-lg-4 col-md-6">
                      <div class="form-group">
                        <small class="form-text text-muted required">Sign Initials</small>
                        <div class="input-group">
                          <input class="form-control" formControlName="signInitials"  placeholder="Sign Initials" name="signInitials">
                        </div>
                        <small class="text-danger d-block" *ngIf="newVorCheckForm.get('signInitials').invalid && (newVorCheckForm.get('signInitials').touched || newVorCheckForm.get('signInitials').dirty)">Sign in initials.</small>
                      </div>
                    </div>

                  </div>
                </div>
                <hr class="mt-1">
                <div class="text-right mt-2">
                  <button class="btn btn-outline-danger mr-3" (click)="inNewVorCheckMode = false">Cancel</button>
                  <button class="btn btn-outline-primary">Add VOR Check</button>
                </div>
              </form>

            </div>
          </div>

          <section *ngIf="!inNewVorCheckMode">

            <!--EXISTING NAV DATA UPDATED-->
            <table class="table table-striped w-100">
              <tr *ngFor="let vorCheck of allVorChecks; index as i">
                <td>
                  <div>
                    <h6 class="clearfix">
                      #{{ i + 1 }}.) {{vorCheck?.vorSource}}

                      <span class="float-right" *ngIf="vorCheck.editable && hasEditRole()">
                        <button class="btn btn-outline-primary rounded" *ngIf="editVorCheck.id !== vorCheck.id" (click)="loadVorCheckForEdit(vorCheck)">
                          <i class="la la-edit"></i>
                        </button>
                        <button class="btn btn-outline-primary rounded" *ngIf="editVorCheck.id == vorCheck.id" (click)="unloadVorCheckForEdit()">
                          <i class="la la-times"></i>
                        </button>
                      </span>
                    </h6>
                    <div class="row">
                      <div class="col-6 col-sm-6 col-md-2 my-2">
                        <small class="d-block text-muted">
                          VOR Source
                        </small>
                        {{vorCheck?.vorSource}}
                      </div>
                      <div class="col-6 col-sm-6 col-md-2 my-2">
                        <small class="d-block text-muted">
                          Radial To
                        </small>
                        {{vorCheck?.radial }}
                      </div>
                      <div class="col-6 col-sm-6 col-md-2 my-2">
                        <small class="d-block text-muted">
                          Radial Direction
                        </small>
                        {{vorCheck.radialDirection | uppercase }}
                      </div>
                      <div class="col-6 col-sm-6 col-md-2 my-2">
                        <small class="d-block text-muted">
                          Distance Miles
                        </small>
                        {{vorCheck?.distanceMiles }}
                      </div>
                      <div class="col-6 col-sm-6 col-md-2 my-2">
                        <small class="d-block text-muted">
                          Error Degrees
                        </small>
                        {{vorCheck?.errorDegrees }}
                      </div>
                      <div class="col-6 col-sm-6 col-md-2 my-2">
                        <small class="d-block text-muted">
                          Initials
                        </small>
                        {{vorCheck?.signInitials}}
                      </div>
                    </div>
                  </div>

                  <div *ngIf="editVorCheck.id == vorCheck.id">
                    <!--EDIT VOR Check-->
                    <div class="card mb-3 bg-light border-danger rounded" >
                      <div class="card-body">
                        <h4>Edit VOR Check {{vorCheck.vorSource}}</h4>
                        <form [formGroup]="editVorCheckForm" (ngSubmit)="doUpdateVorCheck()">
                          <div>
                            <div class="row">

                              <div class="col-sm-12 col-lg-8 col-md-12">
                                <div class="form-group">
                                  <small class="form-text text-muted required">Update VOR Source</small>
                                  <div class="input-group">
                                    <input class="form-control" formControlName="vorSource"  placeholder="VOR Source" name="vorSource">
                                  </div>
                                  <small class="text-danger d-block" *ngIf="editVorCheckForm.get('vorSource').invalid && (editVorCheckForm.get('vorSource').touched || editVorCheckForm.get('vorSource').dirty)">Enter VOR Source.</small>
                                </div>
                              </div>

                              <div class="col-sm-12 col-lg-4 col-md-6">
                                <div class="form-group">
                                  <small class="form-text text-muted required">Update Radial</small>
                                  <div class="input-group">
                                    <input class="form-control" formControlName="radial"  placeholder="Radial" name="radial">
                                  </div>
                                  <small class="text-danger d-block" *ngIf="editVorCheckForm.get('radial').invalid && (editVorCheckForm.get('radial').touched || editVorCheckForm.get('radial').dirty)">Enter Radial value from 1 to 360.</small>
                                </div>
                              </div>

                              <div class="col-sm-12 col-lg-4 col-md-6">
                                <div class="form-group">
                                  <small class="form-text text-muted required">Update Radial Direction</small>
                                  <div class="input-group">
                                    <select class="form-control" formControlName="radialDirection"  placeholder="Radial Direction" name="radialDirection">
                                      <option value=""></option>
                                      <option value="to">To</option>
                                      <option value="from">From</option>
                                    </select>
                                  </div>
                                  <small class="text-danger d-block" *ngIf="editVorCheckForm.get('radialDirection').invalid && (editVorCheckForm.get('radialDirection').touched || editVorCheckForm.get('radialDirection').dirty)">Select Radial Direction.</small>
                                </div>
                              </div>

                              <div class="col-sm-12 col-lg-4 col-md-6">
                                <div class="form-group">
                                  <small class="form-text text-muted required">Update Distance (Miles)</small>
                                  <div class="input-group">
                                    <input class="form-control" formControlName="distanceMiles"  placeholder="Distance (Miles)" name="distanceMiles">
                                  </div>
                                  <small class="text-danger d-block" *ngIf="editVorCheckForm.get('distanceMiles').invalid && (editVorCheckForm.get('distanceMiles').touched || editVorCheckForm.get('distanceMiles').dirty)">Enter distance (miles).</small>
                                </div>
                              </div>

                              <div class="col-sm-12 col-lg-4 col-md-6">
                                <div class="form-group">
                                  <small class="form-text text-muted required">Update Error Degrees</small>
                                  <div class="input-group">
                                    <input class="form-control" formControlName="errorDegrees"  placeholder="Error Degrees" name="errorDegrees">
                                  </div>
                                  <small class="text-danger d-block" *ngIf="editVorCheckForm.get('errorDegrees').invalid && (editVorCheckForm.get('errorDegrees').touched || editVorCheckForm.get('errorDegrees').dirty)">Error degrees value from 1 to 90.</small>
                                </div>
                              </div>

                              <div class="col-sm-12 col-lg-4 col-md-6">
                                <div class="form-group">
                                  <small class="form-text text-muted required">Update Sign Initials</small>
                                  <div class="input-group">
                                    <input class="form-control" formControlName="signInitials"  placeholder="Sign Initials" name="signInitials">
                                  </div>
                                  <small class="text-danger d-block" *ngIf="editVorCheckForm.get('signInitials').invalid && (editVorCheckForm.get('signInitials').touched || editVorCheckForm.get('signInitials').dirty)">Sign in initials.</small>
                                </div>
                              </div>

                            </div>
                          </div>
                          <hr class="mt-1">
                          <div class="text-right mt-2">
                            <button class="btn btn-outline-danger mr-3" (click)="unloadVorCheckForEdit()">Cancel</button>
                            <button class="btn btn-outline-primary">Update VOR Check</button>
                          </div>
                        </form>

                      </div>
                    </div>
                  </div>

                </td>
              </tr>
            </table>
          </section>
        </div>

      </div>
    </div>
  </section>
