import { ActivatedRoute } from '@angular/router';
import { AircraftService } from './../../../../services/aircraft.service';
import { UserMenuAircraftModel } from '../../../../models/user-menu-aircraft-model';
import { VorCheckModel } from './../../../../models/vor-check-model';
import { FlightLogsService } from './../../../../services/flight-logs.service';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, SubscriptionLike } from 'rxjs';
import { MustBeNumberValidator } from '../../../../_custom-validators/must-be-number-validator';
import { AircraftV2Model } from '../../../../models/airfcraftV2/aircraft-v2.model';
import { UserOrganizationAndRole } from '../../../../models/user-organization-and-role';
import { OrganizationService } from '../../../../services/organization.service';

@Component({
  selector: 'app-manage-vor-checks',
  templateUrl: './manage-vor-checks.component.html',
  styleUrls: ['./manage-vor-checks.component.scss']
})
export class ManageVorChecksComponent implements OnInit, OnDestroy, OnChanges {
  @Input () tabChanges: Observable<void>;
  @Input() inAdminView: boolean;
  @Input() inViewOnly: boolean;
  @Input() aircraftId: number;

  constructor(
    private aircraftService: AircraftService,
    private orgService: OrganizationService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private flightLogsService: FlightLogsService,
  ) { }

  selectedAircraft: AircraftV2Model = new AircraftV2Model;
  selectedAircraft$: SubscriptionLike;

  selectedOrg: UserOrganizationAndRole = new UserOrganizationAndRole;
  selectedOrg$: SubscriptionLike;


  tabChangesSubscription$: SubscriptionLike;

  inNewVorCheckMode: boolean;

  newVorCheck: VorCheckModel;
  newVorCheckForm: FormGroup;
  newVorCheckLoading: boolean;

  allVorChecks: Array<VorCheckModel>;
  allVorChecksCount: number;

  isWorking: boolean;

  editVorCheck: VorCheckModel;
  editVorCheckForm: FormGroup;
  editVorCheckLoading: boolean;

  isFetchingVorChecks: boolean;

  ngOnInit() {
    this.isWorking = false;
    this.isFetchingVorChecks = false;

    this.tabChangesSubscription$ = this.tabChanges.subscribe(
      () => {
        this.inNewVorCheckMode = false;
      }
    );

    this.selectedOrg$ = this.orgService.selectedOrg$.subscribe(
      (org: UserOrganizationAndRole) => {
        this.selectedOrg = org;
      }
    );

    this.selectedAircraft$ = this.aircraftService.selectedAircraft$.subscribe(
      (aircraft: AircraftV2Model) => {
        this.selectedAircraft = aircraft;

        if (!this.aircraftId) {
          this.aircraftId = this.selectedAircraft.id;
        }

        if (this.aircraftId !== null && this.aircraftId !== undefined) {
          this.initPageVariables();
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.aircraftId && changes.aircraftId.currentValue) {
      this.aircraftId = changes.aircraftId.currentValue;
      this.initPageVariables();
    }
  }

  initPageVariables() {

    this.inNewVorCheckMode = true;

    this.newVorCheck = new VorCheckModel;
    this.newVorCheckLoading = false;

    this.allVorChecks = [];
    this.allVorChecksCount = null;

    this.editVorCheck = new VorCheckModel;
    this.editVorCheckLoading = false;
    // hide New VOR Check for admin

    if (this.inViewOnly || !this.hasEditRole()) {
      this.inNewVorCheckMode = false;
    }

    this.initNewVorCheckForm();
    this.getAllAircraftVorChecks();
  }

  getAllAircraftVorChecks() {
    this.isFetchingVorChecks = true;
    this.flightLogsService.getAllAircraftVorChecks(this.aircraftId).subscribe(
      (response: any) => {
        this.isFetchingVorChecks = false;
        if (+response.status.code === 1000) {
          this.allVorChecks = response.responseData.rows;
          this.allVorChecksCount = response.responseData.count;
        }
      },
      (error: any) => {
        this.isFetchingVorChecks = false;
      }
    );
  }

  initNewVorCheckForm() {
    this.newVorCheckForm = this.formBuilder.group({
      'vorSource': new FormControl('', [Validators.required]),
      'radial': new FormControl('', [Validators.required, MustBeNumberValidator(), Validators.min(1), Validators.max(360)]),
      'radialDirection': new FormControl('', [Validators.required]),
      'distanceMiles': new FormControl('', [Validators.required, MustBeNumberValidator()]),
      'errorDegrees': new FormControl('', [Validators.required, MustBeNumberValidator(), Validators.min(1), Validators.max(90)]),
      'signInitials': new FormControl('', [Validators.required]),
    });
  }

  toggleInNewVorCheck() {
    if (!this.inNewVorCheckMode) {
      this.initNewVorCheckForm();
      this.inNewVorCheckMode = true;
      return;
    } else {
      this.inNewVorCheckMode = false;
    }
  }

  addNewVorCheckForm() {
    if (!this.newVorCheckForm.valid) {
      Swal.fire(
        'New VOR Check Form contains invalid fields!',
        'Check all fields and ensure they have been correctly filled.',
        'error'
      );

      Object.values(this.newVorCheckForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      // for(let i in this.newVorCheckForm.controls) {
      //   this.newVorCheckForm.controls[i].markAsTouched();
      // };
      return;
    }

    window.scrollTo(0, 0);
    this.isWorking = true;
    this.newVorCheck.aircraftId = this.aircraftId;
    this.newVorCheck.vorSource = this.newVorCheckForm.controls['vorSource'].value;
    this.newVorCheck.radial = this.newVorCheckForm.controls['radial'].value;
    this.newVorCheck.radialDirection = this.newVorCheckForm.controls['radialDirection'].value;
    this.newVorCheck.distanceMiles = this.newVorCheckForm.controls['distanceMiles'].value;
    this.newVorCheck.errorDegrees = this.newVorCheckForm.controls['errorDegrees'].value;
    this.newVorCheck.signInitials = this.newVorCheckForm.controls['signInitials'].value;

    this.flightLogsService.addAircraftVorCheck(this.newVorCheck).subscribe(
      (response: any) => {
        this.isWorking = false;
        if (+response.status.code === 1000) {
          this.inNewVorCheckMode = false;
          this.newVorCheckForm.reset();
          this.newVorCheck = new VorCheckModel;
          this.getAllAircraftVorChecks();
          Swal.fire(
            'Great!', 'VOR Check added.', 'success'
          );
        } else {
          Swal.fire(
            'Yikes!', 'VOR Check not added.', 'error'
          );
        }
      },
      (error: any) => {
        this.isWorking = false;

        if (error.status.message) {
          Swal.fire(
            error.status.message,
            error.responseData.join(' '),
            'error'
          );
        } else {
          Swal.fire(
            'Oops!',
            'Failed to add VOR Check.',
            'error'
          );
        }
      }
    );
  }



  loadVorCheckForEdit(vorCheck: VorCheckModel) {
    this.editVorCheck = vorCheck;
    this.editVorCheckForm = this.formBuilder.group({
      'vorSource': new FormControl(this.editVorCheck.vorSource, [Validators.required]),
      'radial': new FormControl(
        this.editVorCheck.radial,
        [
          Validators.required,
          Validators.min(1), MustBeNumberValidator(),
          Validators.max(360)
        ]
      ),
      'radialDirection': new FormControl(this.editVorCheck.radialDirection, [Validators.required]),
      'distanceMiles': new FormControl(this.editVorCheck.distanceMiles, [Validators.required, MustBeNumberValidator()]),
      'errorDegrees': new FormControl(
        this.editVorCheck.errorDegrees,
        [Validators.required, MustBeNumberValidator(),
          Validators.min(1), Validators.max(90)
        ]
      ),
      'signInitials': new FormControl(
        this.editVorCheck.signInitials,
        [
          Validators.required
        ]
      ),
    });
  }

  unloadVorCheckForEdit() {
    this.editVorCheck = new VorCheckModel;
  }


  doUpdateVorCheck() {
    if (!this.editVorCheckForm.valid) {
      Swal.fire(
        'Edited VOR Check Form contains invalid fields!',
        'Check all fields and ensure they have been correctly filled.',
        'error');

      Object.values(this.editVorCheckForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      // for (let i in this.editVorCheckForm.controls) {
      //   this.editVorCheckForm.controls[i].markAsTouched();
      // };
      return;
    }

    window.scrollTo(0, 0);
    this.isWorking = true;
    this.editVorCheck.aircraftId = this.aircraftId;
    this.editVorCheck.vorSource = this.editVorCheckForm.controls['vorSource'].value;
    this.editVorCheck.radial = this.editVorCheckForm.controls['radial'].value;
    this.editVorCheck.radialDirection = this.editVorCheckForm.controls['radialDirection'].value;
    this.editVorCheck.distanceMiles = this.editVorCheckForm.controls['distanceMiles'].value;
    this.editVorCheck.errorDegrees = this.editVorCheckForm.controls['errorDegrees'].value;
    this.editVorCheck.signInitials = this.editVorCheckForm.controls['signInitials'].value;

    this.flightLogsService.updateAircraftVorCheck(this.editVorCheck).subscribe(
      (response: any) => {
        this.isWorking = false;
        if (+response.status.code === 1000) {
          this.unloadVorCheckForEdit();
          this.editVorCheckForm.reset();
          this.editVorCheck = new VorCheckModel;
          this.getAllAircraftVorChecks();
          Swal.fire(
            'Great!', 'Updated VOR Check.', 'success'
          );
        } else {
          Swal.fire(
            'Yikes!', 'VOR check not updated.', 'error'
          );
        }
      },
      (error: any) => {
        this.isWorking = false;
        if (error.status.message) {
          Swal.fire(
            error.status.message,
            error.responseData.join(' '),
            'error'
          );
        } else {
          Swal.fire(
            'Oops!',
            'Failed to update VOR Check.',
            'error'
          );
        }
      }
    );
  }

  hasEditRole() {
    if (this.inAdminView) {
      return false;
    }

    if (
      (
        // if Pilot
        this.selectedAircraft.userRole &&
        this.selectedAircraft.userRole.toString().toUpperCase() === 'INDIVIDUAL_RW'
      ) ||
      // if ORG ADMIN
      (
        this.selectedOrg.role &&
        this.selectedOrg.role.toString().toUpperCase() === 'ADMIN'
      )
    ) {
      return true;
    }
  }

  ngOnDestroy() {
    if (this.selectedAircraft$) {
      this.selectedAircraft$.unsubscribe();
    }

    if (this.selectedOrg$) {
      this.selectedOrg$.unsubscribe();
    }

    if (this.tabChangesSubscription$) {
      this.tabChangesSubscription$.unsubscribe();
    }
  }

}

