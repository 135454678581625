import { ValidatorFn, AbstractControl } from '@angular/forms';

export function OneOfControlsIsNeededIgnoreIfAnotherControlIsValueValidator (ignoreControl: AbstractControl, otherControlIgnoreValue: any, otherControls: Array<AbstractControl>, extraControlsMustBeTrue: Array<AbstractControl> = []): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let isForbidden: boolean = null;

    //let jj = ignoreControl? ignoreControl.value : '<none set>'
    //console.log(jj, "ignoreControl.value", otherControlIgnoreValue )
    //check if this ignore control value is filled, if yes then it's not forbidden(it's valid)
    let extraControlsValid = true;
    extraControlsMustBeTrue.forEach(
      (ctrl, i)=>{
        if(!ctrl.value) extraControlsValid = null;
        //console.log(extraControlsValid, "extraControlsValid " + i)
      }
    )
    //console.log(extraControlsValid, "extraControlsValid")

    if((ignoreControl && (ignoreControl.value == otherControlIgnoreValue)) || extraControlsValid){
      isForbidden = null;
    }
    else{
      //check if this control value is filled
      isForbidden = control.value? null : true;

      otherControls.forEach(
        otherControl => {
          if(isForbidden){
            isForbidden = otherControl.value? null : true;
          }
          
          if(isForbidden) {
            otherControl.setErrors({'oneOfControlsNeededIgnoreIfOther': {value: otherControl.value}});
          }
          else{
            if (otherControl.hasError('oneOfControlsNeededIgnoreIfOther')) {
              delete otherControl.errors['oneOfControlsNeededIgnoreIfOther'];
              otherControl.updateValueAndValidity();
            }
          }
        }
      )
    }
    
    return isForbidden ? {'oneOfControlsNeededIgnoreIfOther': {value: control.value}} : null;
  };
}