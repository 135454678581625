
<section class="p-3">
  <!--AICRAFT INFORMATION-->
  <div class="row mb-3">
    <div class="col-sm-12 col-md-3">
      <h6 class="form_header uppercase mt-1">Aircraft</h6>
    </div>

    <div class="col-sm-12 col-md-9">
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div>
            <small class="form-text text-muted uppercase" >Tail No.</small>
            <div class="h4 text-primary">
              {{theAircraft?.tailNo}}
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4">
          <small class="form-text text-muted uppercase">Serial No.</small>
          <div class="h4 text-primary">{{theAircraft?.airframe?.serialNumber}}</div>
        </div>

        <div class="col-sm-6 col-md-4">
          <small class="form-text text-muted uppercase">Make</small>
          <div class="h4 text-primary">{{theAircraft?.airframe?.make?.name}}</div>
        </div>

        <div class="col-sm-6 col-md-4">
          <small class="form-text text-muted uppercase">Model</small>
          <div class="h4 text-primary">{{theAircraft?.airframe?.model?.name}}</div>
        </div>

        <div class="col-sm-6 col-md-4">
          <small class="form-text text-muted uppercase">TTIS @ Setup.</small>
          <div class="h4 text-primary">{{theAircraft?.airframe?.ttisAtSetup}}Hrs</div>
        </div>
      </div>
    </div>

  </div>

  <hr/>

  <!--ENGINE INFORMATION-->
  <div class="row mb-3">
    <div class="col-sm-12 col-md-3">
      <h6 class="form_header uppercase mt-1">Engine</h6>
    </div>

    <div class="col-sm-12 col-md-9">
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <small class="form-text text-muted uppercase">Make</small>
            <div class="h4 text-primary">
              <span *ngIf="theAircraft.engines">
                {{theAircraft.engines[0]?.make?.name}}
              </span>
              <span *ngIf="!theAircraft.engines">
                --- ---
              </span>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <small class="form-text text-muted uppercase">Model</small>
            <div class="h4 text-primary">
              <span *ngIf="theAircraft.engines">
                {{theAircraft.engines[0]?.model?.name}}
              </span>
              <span *ngIf="!theAircraft.engines">
                --- ---
              </span>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <small class="form-text text-muted uppercase">Serial No.</small>
            <div class="h4 text-primary">
              <span *ngIf="theAircraft.engines">
                {{theAircraft.engines[0]?.serialNumber}}
              </span>
              <span *ngIf="!theAircraft.engines">
                --- ---
              </span>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <small class="form-text text-muted uppercase">TTIS @ Install</small>
            <div class="h4 text-primary">
              <span *ngIf="theAircraft.engines">
                {{theAircraft.engines[0]?.initialHours}}
              </span>
              <span *ngIf="!theAircraft.engines">
                --- ---
              </span>
              Hrs
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <small class="form-text text-muted uppercase">SMOH</small>
            <div class="h4 text-primary">
              <span *ngIf="theAircraft.engines">
                {{theAircraft.engines[0]?.smoh}}
              </span>
              <span *ngIf="!theAircraft.engines">
                --- ---
              </span>
              Hrs
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <small class="form-text text-muted uppercase">Installed At</small>
            <div class="h4 text-primary">
              <span *ngIf="theAircraft.engines">
                {{theAircraft.engines[0]?.installedAirframeTime}}
              </span>
              <span *ngIf="!theAircraft.engines">
                --- ---
              </span>
              Hrs</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr/>

  <!--PROPELLER INFORMATION-->
  <div class="row mb-3">
    <div class="col-sm-12 col-md-3">
      <h6 class="form_header uppercase mt-1">Propeller</h6>
    </div>

    <div class="col-sm-12 col-md-9">
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <small class="form-text text-muted uppercase">Make</small>
            <div class="h4 text-primary">
              <span *ngIf="theAircraft.propellers">
                {{theAircraft.propellers[0]?.make?.name}}
              </span>
              <span *ngIf="!theAircraft.propellers">
                --- ---
              </span>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <small class="form-text text-muted uppercase">Model</small>
            <div class="h4 text-primary">
              <span *ngIf="theAircraft.propellers">
                {{theAircraft.propellers[0]?.model?.name}}
              </span>
              <span *ngIf="!theAircraft.propellers">
                --- ---
              </span>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <small class="form-text text-muted uppercase">Hub Serial No.</small>
            <div class="h4 text-primary">
              <span *ngIf="theAircraft.propellers">
                {{theAircraft.propellers[0]?.serialNumber}}
              </span>
              <span *ngIf="!theAircraft.propellers">
                --- ---
              </span>
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <small class="form-text text-muted uppercase">TTIS @ Install</small>
            <div class="h4 text-primary">
              <span *ngIf="theAircraft.propellers">
                {{theAircraft.propellers[0]?.initialHours}}
              </span>
              <span *ngIf="!theAircraft.propellers">
                --- ---
              </span>
              Hrs
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <small class="form-text text-muted uppercase">SPOH</small>
            <div class="h4 text-primary">
              <span *ngIf="theAircraft.propellers">
                {{theAircraft.propellers[0]?.smoh}}
              </span>
              <span *ngIf="!theAircraft.propellers">
                --- ---
              </span>
              Hrs
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <small class="form-text text-muted uppercase">Install At</small>
            <div class="h4 text-primary">
              <span *ngIf="theAircraft.propellers">
                {{theAircraft.propellers[0]?.installedAirframeTime}}
              </span>
              <span *ngIf="!theAircraft.propellers">
                --- ---
              </span>
              Hrs
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <hr/>

  <!--OTHER INFORMATION-->
  <div class="row mb-3">
    <div class="col-12 mb-3 ">
      <h6 class="form_header uppercase">Avionics Equipment</h6>
      <div class="h6 mb-3">
        {{theAircraft.avionicsEquipments? theAircraft?.avionicsEquipments : '--- ---'}}
      </div>
    </div>

    <div class="col-12 mb-3">
      <h6 class="form_header uppercase">Other Aircraft Equipment</h6>
      <div class="h6 mb-3">
        <div>{{theAircraft.otherEquipments? theAircraft.otherEquipments : '--- ---'}}</div>
      </div>
    </div>
  </div>
</section>
