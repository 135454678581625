import { UserOrganizationAndRole } from '../../models/user-organization-and-role';
import { OrganizationService } from '../../services/organization.service';
import { AircraftService } from '../../services/aircraft.service';
import { SubscriptionLike } from 'rxjs';
import { UserService } from '../../services/user.service';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UserModel } from '../../models/user-model';
import { AircraftV2Model } from '../../models/airfcraftV2/aircraft-v2.model';
import { OrganizationV2Model } from '../../models/organization/organization-v2.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-aircraft-picker-widget',
  templateUrl: './aircraft-picker-widget.component.html',
  styleUrls: ['./aircraft-picker-widget.component.scss']
})
export class AircraftPickerWidgetComponent implements OnInit, OnDestroy {
  @Output() selectedAircraftEmmitter: EventEmitter<AircraftV2Model> = new EventEmitter();

  currentUser: UserModel;
  private currentUser$: SubscriptionLike;

  private userAircraftList$: SubscriptionLike;
  userAircraftList: Array<AircraftV2Model> = [];

  private selectedAircraft$: SubscriptionLike;
  selectedAircraft: AircraftV2Model = new AircraftV2Model;

  private selectedOrg$: SubscriptionLike;
  selectedOrg: UserOrganizationAndRole = new UserOrganizationAndRole;


  isFetchingAircraftList: boolean;

  constructor(
    private orgService: OrganizationService,
    private aircraftService: AircraftService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    // init values
    this.isFetchingAircraftList = false;
    // end init values
    this.currentUser$ = this.userService.currentUser$.subscribe(
      (user) => {
        this.currentUser = user;
      }
    );

    this.userAircraftList$ = this.aircraftService.userAircraftList$.subscribe(
      (aircraftList: Array<AircraftV2Model>) => {
        this.userAircraftList = aircraftList;
      }
    );

    this.selectedAircraft$ = this.aircraftService.selectedAircraft$.subscribe(
      (aircraft: AircraftV2Model) => {
        this.selectedAircraft = aircraft;
        this.selectedAircraftEmmitter.emit(this.selectedAircraft);
        this.orgService.doAircraftOrgSelectCheck(this.selectedAircraft);
      }
    );

    this.selectedOrg$ = this.orgService.selectedOrg$.subscribe(
      (org: UserOrganizationAndRole) => {
        this.selectedOrg = org;
      }
    );
  }

  onAircraftSelect(aircraft: AircraftV2Model) {
    this.aircraftService.setSelectedAircraft(aircraft);
  }


  ngOnDestroy() {
    if (this.currentUser$) {
      this.currentUser$.unsubscribe();
    }

    if (this.userAircraftList$) {
      this.userAircraftList$.unsubscribe();
    }

    if (this.selectedAircraft$) {
      this.selectedAircraft$.unsubscribe();
    }

    if (this.selectedOrg$) {
      this.selectedOrg$.unsubscribe();
    }

  }

}
