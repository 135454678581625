import { UtilitiesService } from './../../../services/utilities.service';
import { UserAircraftModel } from './../../../models/user-aircraft-model';
import { AircraftService } from './../../../services/aircraft.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SubscriptionLike, of, Subject } from 'rxjs';
import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { NgbTabsetConfig, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { AircraftV2Model } from '../../../models/airfcraftV2/aircraft-v2.model';

@Component({
  selector: 'app-edit-aircraft-dashboard',
  templateUrl: './edit-aircraft-dashboard.component.html',
  styleUrls: ['./edit-aircraft-dashboard.component.scss'],
  providers: [NgbTabsetConfig] // add NgbTabsetConfig to the component providers,
})


export class EditAircraftDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private aircraftService: AircraftService,
    private utilitiesService: UtilitiesService,
    private _cdRef: ChangeDetectorRef
  ) { }

  @ViewChild('dashboardTabs') dashboardTabs: NgbTabset;

  urlTabName: string = null;

  tabChangeEventsSubject: Subject<void> = new Subject<void>();

  aircraftIdParamsSubscriber$: SubscriptionLike;

  theAircraft: UserAircraftModel = new UserAircraftModel;

  selectedAircraft: AircraftV2Model;
  selectedAircraft$: SubscriptionLike;


  ngOnInit() {
    // this.aircraftIdParamsSubscriber$ = this.route.paramMap
    //   .pipe(
    //     switchMap((params: ParamMap)=> of(params.get("aircraftId")))
    // ).subscribe(
    //   (aircraftId) => {
    //     this.theAircraft.id = +aircraftId;

    //     this.getAircraft();
    //   }
    // );

    this.selectedAircraft$ = this.aircraftService.selectedAircraft$.subscribe(
      (aircraft: AircraftV2Model) => {
        if (aircraft.id) {
          this.theAircraft.id = aircraft.id;
          this.getAircraft();
        }
      }
    );


    this.route.queryParams.subscribe(params => {
      if (params['t']) {
        this.urlTabName = params['t'];
      }
    });

  }

  ngAfterViewInit() {
    const prefix = 'tab_';

    if (
      this.urlTabName &&
      (this.urlTabName.toLowerCase() === 'ad' ||
      this.urlTabName.toLowerCase() === 'bulletin' ||
      this.urlTabName.toLowerCase() === 'maintenance')
     ) {
      const theTab = prefix + this.urlTabName.toLowerCase();
      if (this.dashboardTabs) {
        this.dashboardTabs.select(theTab);
      }
    }

    this._cdRef.detectChanges();
  }

  getAircraft() {
    this.aircraftService.getAircraftById(this.theAircraft.id).subscribe(
      (response: any) => {
        if (+response.status.code === 1000) {
          this.theAircraft = response.responseData;
        }
      }
    );
  }

  onTabChange() {
    this.tabChangeEventsSubject.next();
  }

  ngOnDestroy() {
    if (this.aircraftIdParamsSubscriber$) {
      this.aircraftIdParamsSubscriber$.unsubscribe();
    }
  }

}
