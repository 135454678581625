<app-aircraft-info-header-widget-v2
  [theAircraft]="theAircraft"
>
</app-aircraft-info-header-widget-v2>

<div class="row">
  <!--main left content-->
  <div class="col-sm-12 col-md-9">
    <div class="card shadow border-0 admin-create-user mt-3 rounded-0">
      <h5
        class="card-header bg-white border-0 form_header uppercase justify-content-between d-flex align-items-center mt-2"
      >
        <span>Aircraft & Equipment</span>
        <div class="ml-auto" *ngIf="currentUser.isAdmin">
          <button
            *ngIf="!editMode"
            class="btn btn-outline-primary rounded btn-sm"
            (click)="toggleEditMode()"
          >
            <i class="la la-edit"></i>
          </button>
          <button
            *ngIf="editMode"
            class="btn btn-outline-danger rounded btn-sm"
            (click)="toggleEditMode()"
          >
            <i class="la la-times-circle"></i> Cancel
          </button>
        </div>
      </h5>

      <div class="card-body">
        <app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

        <ng-container *ngIf="!isWorking">
          <app-aircraft-basic-info
            [theAircraft]="theAircraft"
            *ngIf="!editMode"
          ></app-aircraft-basic-info>

          <form
            [formGroup]="updateAircraftForm"
            (ngSubmit)="doUpdateAircraft()"
            *ngIf="editMode"
          >
            <!--AIRCRAFT INFO-->
            <div class="aircraftData">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted required">Tail No.</small>
                    <input
                      type="text"
                      formControlName="tailNo"
                      class="form-control"
                      name="tailno"
                      id="tailNo"
                      placeholder="Tail Number"
                    />
                    <small
                      class="form-text text-danger"
                      *ngIf="tailNo.invalid && (tailNo.dirty || tailNo.touched)"
                      >
                      Minimum lenght of 5 and max of 8. Numbers, letters and hyphen.
                    </small>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Airframe SN.</small>
                    <input
                      type="text"
                      formControlName="airframeSn"
                      class="form-control"
                      id="airframsn"
                      placeholder="Aircraft Serial #"
                    />
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Aircraft Make
                      <span class="spinner-border spinner-border-sm text-orange" role="status" *ngIf="aircraftMakeSearching">
                        <span class="sr-only">Loading...</span>
                      </span>
                    </small>
                    <input
                      type="text"
                      formControlName="aircraftMake"
                      class="form-control"
                      id="aircraftmake"
                      placeholder="Aircraft Make"
                      [class.is-invalid]="aircraftMakeSearchFailed"
                      [ngbTypeahead]="aircraftMakeTypeHeadSearch"
                      [editable]="false"
                      [resultTemplate]="rt"
                      [inputFormatter]="typeHeadMakeFormatter"
                      (selectItem)="onTypeHeadSelected($event, 'aircraft')"
                    />

                    <ng-template #rt let-r="result" let-t="term">
                      <ngb-highlight
                        [result]="r.makeName"
                        [term]="t"
                      ></ngb-highlight>
                    </ng-template>
                    <small
                      class="form-text text-danger"
                      *ngIf="
                        aircraftMake.invalid &&
                        (aircraftMake.dirty ||
                          aircraftMake.touched)
                      "
                      >Ensure values for Aircraft Make and Model are
                      selected.</small
                    >
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Aircraft Model
                      <span class="spinner-border spinner-border-sm text-orange" role="status" *ngIf="aircraftModelSearching">
                        <span class="sr-only">Loading...</span>
                      </span>
                    </small>
                    <input
                      type="text"
                      [readonly]="!aircraftMake.value"
                      formControlName="aircraftModel"
                      class="form-control"
                      id="aircraftmodel"
                      placeholder="Aircraft Model"
                      [class.is-invalid]="aircraftModelSearchFailed"
                      [ngbTypeahead]="aircraftModelTypeHeadSearch"
                      [editable]="false"
                      [resultTemplate]="rt_aircraftModel"
                      [inputFormatter]="typeHeadModelFormatter"
                      (selectItem)="onTypeHeadModelSelected($event, 'aircraft')"
                    />

                    <ng-template #rt_aircraftModel let-r="result" let-t="term">
                      <ngb-highlight
                        [result]="r.modelName"
                        [term]="t"
                      ></ngb-highlight>
                    </ng-template>
                    <small
                      class="form-text text-danger"
                      *ngIf="
                        aircraftModel.invalid &&
                        (aircraftModel.dirty ||
                          aircraftModel.touched)
                      "
                    >
                      Ensure values for Aircraft Make and Model are selected.
                    </small>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted"
                      >Enter Airframe TTIS at Setup.</small
                    >
                    <input
                      type="text"
                      formControlName="airframeTtisAtSetup"
                      class="form-control"
                      id="airframttis"
                      placeholder="Airframe TTIS at Setup"
                    />
                    <small
                      class="form-text text-danger"
                      *ngIf="
                        airframeTtisAtSetup.invalid &&
                        (airframeTtisAtSetup.dirty || airframeTtisAtSetup.touched)
                      "
                      >TTIS in hours (digits).</small
                    >
                  </div>
                </div>
              </div>

            </div>

            <hr />

            <!--ENGINE INFO-->
            <div class="engineData">
              <div class="row mt-2">
                <div class="col-sm-12 col-md-12">
                  <h4 class="form_header">Engine Info</h4>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Engine Make
                      <span class="spinner-border spinner-border-sm text-orange" role="status" *ngIf="engineMakeSearching">
                        <span class="sr-only">Loading...</span>
                      </span>
                    </small>
                    <input
                      type="text"
                      formControlName="engineMake"
                      class="form-control"
                      id="enginemake"
                      placeholder="Engine Make"
                      [class.is-invalid]="engineMakeSearchFailed"
                      [ngbTypeahead]="engineMakeTypeHeadSearch"
                      [editable]="false"
                      [resultTemplate]="rt_engine"
                      [inputFormatter]="typeHeadMakeFormatter"
                      (selectItem)="onTypeHeadSelected($event, 'engine')"
                    />

                    <ng-template #rt_engine let-r="result" let-t="term">
                      <ngb-highlight
                        [result]="r.makeName"
                        [term]="t"
                      ></ngb-highlight>
                    </ng-template>
                    <small
                      class="form-text text-danger"
                      *ngIf="
                        engineMake.invalid &&
                        (engineMake.dirty ||
                          engineMake.touched)
                      "
                      >
                        Ensure values for Engine Make and Model are selected.
                      </small
                    >
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Engine Model
                      <span class="spinner-border spinner-border-sm text-orange" role="status" *ngIf="engineModelSearching">
                        <span class="sr-only">Loading...</span>
                      </span>
                    </small>
                    <input
                      type="text"
                      formControlName="engineModel"
                      class="form-control"
                      id="enginemodel"
                      [readonly]="!engineMake.value"
                      placeholder="Engine Model"
                      [class.is-invalid]="engineModelSearchFailed"
                      [ngbTypeahead]="engineModelTypeHeadSearch"
                      [editable]="false"
                      [resultTemplate]="rt_engineModel"
                      [inputFormatter]="typeHeadModelFormatter"
                      (selectItem)="onTypeHeadModelSelected($event, 'engine')"
                    />

                    <ng-template #rt_engineModel let-r="result" let-t="term">
                      <ngb-highlight
                        [result]="r.modelName"
                        [term]="t"
                      ></ngb-highlight>
                    </ng-template>
                    <small
                      class="form-text text-danger"
                      *ngIf="
                      engineModel.invalid"
                      >
                        Ensure values for Engine Make and Model are selected.
                      </small
                    >
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Engine SN.</small>
                    <input
                      type="text"
                      formControlName="engineSn"
                      class="form-control"
                      id="engine_sn"
                      placeholder="Engine S/N"
                    />
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted"
                      >Enter Engine TT at Install(in engine hours)</small
                    >
                    <input
                      type="text"
                      formControlName="initialEngineHours"
                      class="form-control"
                      id="engine_hrs"
                      placeholder="Engine TT at Install"
                    />
                    <small
                      class="form-text text-danger"
                      *ngIf="
                        initialEngineHours.invalid &&
                        (initialEngineHours.dirty || initialEngineHours.touched)
                      "
                      >Engine hours at install in tenths e.g. 102.5</small
                    >
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted"
                      >Engine Install Time(in airframe hours)</small
                    >
                    <input
                      type="text"
                      formControlName="engineInstalledAirframeTime"
                      class="form-control"
                      id="engineInstalledAirframeTime"
                      placeholder="Engine Install Time"
                    />
                    <small
                      class="form-text text-danger"
                      *ngIf="
                        updateAircraftForm.controls
                          ['engineInstalledAirframeTime'].invalid &&
                        (updateAircraftForm.controls
                          ['engineInstalledAirframeTime'].dirty ||
                          updateAircraftForm.controls
                            ['engineInstalledAirframeTime'].touched)
                      "
                    >
                      Enter airframe hours at engine install in tenths e.g. 102.5
                    </small>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted"
                      >Last Engine OH(in engine hours)</small
                    >
                    <input
                      type="text"
                      formControlName="initialLastEngineOh"
                      class="form-control"
                      id="initialLastEngineOh"
                      placeholder="Last Engine OH"
                    />
                    <small
                      class="form-text text-danger"
                      *ngIf="
                        updateAircraftForm.controls
                          ['initialLastEngineOh'].invalid &&
                        (updateAircraftForm.controls
                          ['initialLastEngineOh'].dirty ||
                          updateAircraftForm.controls
                            ['initialLastEngineOh'].touched)
                      "
                      >Enter engine OH hours in tenths e.g. 102.5</small
                    >
                  </div>
                </div>
              </div>
            </div>

            <!--PROPELLER INFO-->
            <div class="propellerData">
              <hr />
              <div class="row mt-2">
                <div class="sm-12 col-md-12">
                  <h4 class="form_header">Propeller Info</h4>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Propeller Make
                      <span class="spinner-border spinner-border-sm text-orange" role="status" *ngIf="propellerMakeSearching">
                        <span class="sr-only">Loading...</span>
                      </span>
                    </small>
                    <input
                      type="text"
                      formControlName="propellerMake"
                      class="form-control"
                      id="propellermake"
                      placeholder="Propeller Make"
                      [class.is-invalid]="propellerMakeSearchFailed"
                      [ngbTypeahead]="propellerMakeTypeHeadSearch"
                      [editable]="false"
                      [resultTemplate]="rt_propeller"
                      [inputFormatter]="typeHeadMakeFormatter"
                      (selectItem)="onTypeHeadSelected($event, 'propeller')"
                    />

                    <ng-template #rt_propeller let-r="result" let-t="term">
                      <ngb-highlight
                        [result]="r.makeName"
                        [term]="t"
                      ></ngb-highlight>
                    </ng-template>
                    <small
                      class="form-text text-danger"
                      *ngIf="
                        propellerMake.invalid &&
                        (propellerMake.dirty ||
                          propellerMake.touched)
                      "
                      >
                        Ensure values for Engine Make and Model are selected.
                      </small
                    >
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">
                      Propeller Model
                      <span class="spinner-border spinner-border-sm text-orange" role="status" *ngIf="propellerModelSearching">
                        <span class="sr-only">Loading...</span>
                      </span>
                    </small>
                    <input
                      type="text"
                      formControlName="propellerModel"
                      class="form-control"
                      id="propellermodel" 
                      [readonly]="!propellerMake.value"
                      placeholder="Propeller Model"
                      [class.is-invalid]="propellerModelSearchFailed"
                      [ngbTypeahead]="propellerModelTypeHeadSearch" 
                      [editable]="false"
                      [resultTemplate]="rt_propellerModel"
                      [inputFormatter]="typeHeadModelFormatter"
                      (selectItem)="onTypeHeadModelSelected($event, 'propeller')"
                    />

                    <ng-template #rt_propellerModel let-r="result" let-t="term">
                      <ngb-highlight
                        [result]="r.modelName"
                        [term]="t"
                      ></ngb-highlight>
                    </ng-template>
                    <small
                      class="form-text text-danger"
                      *ngIf="
                        propellerModel.invalid
                      "
                      >
                        Ensure values for Engine Make and Model are selected.
                      </small
                    >
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted">Propeller SN.</small>
                    <input
                      type="text"
                      formControlName="propellerSn"
                      class="form-control"
                      id="propellersn_oh"
                      placeholder="Propeller S/N"
                    />
                    <small
                      class="form-text text-danger"
                      *ngIf="
                        propellerSn.invalid &&
                        (propellerSn.dirty ||
                          propellerSn.touched)
                      "
                      >Enter Propeller Serial Number</small
                    >
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted"
                      >Enter Propeller TT at Install(in propeller hours)</small
                    >
                    <input
                      type="text"
                      formControlName="inititialPropellerHours"
                      class="form-control"
                      id="inititialPropellerHours"
                      placeholder="Propeller TT at Install"
                    />
                    <small
                      class="form-text text-danger"
                      *ngIf="
                        updateAircraftForm.controls
                          ['inititialPropellerHours'].invalid &&
                        (updateAircraftForm.controls
                          ['inititialPropellerHours'].dirty ||
                          updateAircraftForm.controls
                            ['inititialPropellerHours'].touched)
                      "
                      >Enter propeller hours at install in tenths e.g. 102.5</small
                    >
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted"
                      >Propeller Install Time(in airframe hours)</small
                    >
                    <input
                      type="text"
                      formControlName="propellerInstalledAirframeTime"
                      class="form-control"
                      id="propellerInstalledAirframeTime"
                      placeholder="Propeller Install Time"
                    />
                    <small
                      class="form-text text-danger"
                      *ngIf="
                        updateAircraftForm.controls
                          ['propellerInstalledAirframeTime'].invalid &&
                        (updateAircraftForm.controls
                          ['propellerInstalledAirframeTime'].dirty ||
                          updateAircraftForm.controls
                            ['propellerInstalledAirframeTime'].touched)
                      "
                      >Enter airframe hours at propeller Install in tenths e.g.
                      102.5</small
                    >
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted"
                      >Last Propeller OH(in propeller hours)</small
                    >
                    <input
                      type="text"
                      formControlName="initLastPropellerOh"
                      class="form-control"
                      id="propeller_oh"
                      placeholder="Last Propeller OH"
                    />
                    <small
                      class="form-text text-danger"
                      *ngIf="
                        updateAircraftForm.controls
                          ['initLastPropellerOh'].invalid &&
                        (updateAircraftForm.controls
                          ['initLastPropellerOh'].dirty ||
                          updateAircraftForm.controls
                            ['initLastPropellerOh'].touched)
                      "
                      >Enter propeller OH hours in tenths e.g. 102.5</small
                    >
                  </div>
                </div>
              </div>
            </div>

            <!--OTHER AIRCRAFT INFO-->
            <div class="equipmentData">
              <hr />
              <div class="row mt-2">
                <div class="col-sm-12 col-md-12">
                  <h4 class="form_header">Other Info</h4>
                </div>
                <div class="col-sm-12 col-md-12">
                  <div class="form-group">
                    <small id="avionics_eq_oh" class="form-text text-muted"
                      >Avionics Equipment</small
                    >
                    <textarea
                      name="avionics_eq"
                      class="form-control"
                      formControlName="avionicsEquipments"
                      id="avionics_eq"
                      placeholder="Avionics Equipment"
                      rows="4"
                    ></textarea>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12">
                  <div class="form-group">
                    <small id="other_eq_oh" class="form-text text-muted"
                      >Other Equipment</small
                    >
                    <textarea
                      name="other_eq"
                      class="form-control"
                      formControlName="otherEquipments"
                      id="other_eq"
                      placeholder="Other Equipment"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-right mt-2">
              <button
                type="button"
                (click)="toggleEditMode()"
                class="btn btn-secondary mr-3"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-orange">
                Update Aircraft
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </div>

  <!--side content-->
  <div class="col-sm-12 col-md-3">
    <div class="card shadow border-0 bg-skyblue mt-3 rounded-0">
      <h5
        class="card-header bg-skyblue  border-0 form_header uppercase justify-content-between d-flex align-items-center mt-2"
      >

        <span class="ellipsis">
          Owning Organization
        </span>

        <!-- <div class="ml-auto">
          <button
            class="btn btn-outline-primary rounded btn-sm"
          >
            <i class="la la-edit"></i>
          </button>
        </div> -->
      </h5>

      <section class="org-info px-3" *ngIf="theAircraft.organizations && theAircraft.organizations.length > 0">
        <ng-container>
          <div class="mb-3" >
            <small class="uppercase">
              Organization Name
            </small>
            <h6 class="text-primary">
              {{ findAircraftOrgbyType('OWNER') ? findAircraftOrgbyType('OWNER').name : '----'}}
            </h6>
          </div>

          <!--Org. Admin Name-->
          <div class="mb-3"  *ngIf="orgAdminPerson.user && orgAdminPerson.user.emailAddress" >
            <small class="uppercase">
              Org. Admin
            </small>
            <h6 class="text-primary">
              {{ orgAdminPerson?.user?.firstName }}
              {{ orgAdminPerson?.user?.lastName }}
            </h6>
          </div>

          <!--Org. Email-->
          <div class="mb-3" *ngIf="orgAdminPerson.user && orgAdminPerson.user.emailAddress" >
            <small class="uppercase">
              Org. Email
            </small>
            <h6 class="text-primary">
              {{ orgAdminPerson.user.emailAddress }}
            </h6>
          </div>

          <!--Org. Primary Phone -->
          <div class="mb-3" *ngIf="orgAdminPerson.user && orgAdminPerson.user.mobilePhone">
            <small class="uppercase">
              Org. Primary Phone #
            </small>
            <h6 class="text-primary">
              {{ formatPhoneNumber(orgAdminPerson.user.mobilePhone)}}
            </h6>
          </div>
        </ng-container>
      </section>

      <hr>

      <!--SUBSCRIPTION-->
      <section class="subscription-info px-3">
        <h5 class="form_header uppercase">
          Subscription Details
        </h5>

        <!--STATUS-->
        <div class="mb-3" >
          <small class="uppercase">
            Status
          </small>
          <h6 class="text-success">
            -- --
          </h6>
        </div>

        <!--PLAN-->
        <div class="mb-3" >
          <small class="uppercase">
            Plan
          </small>
          <h6 class="text-primary">
            -- --
          </h6>
        </div>

        <!--PURCHASE DATE-->
        <div class="mb-3" >
          <small class="uppercase">
            Purchase Date
          </small>
          <h6 class="text-primary">
            -- -- --
          </h6>
          <small class="text-danger">
            -- days remaining
          </small>
        </div>
      </section>
    </div>
  </div>
</div>

<section class="mt-5" *ngIf="!currentUser.isAdmin">
  <app-aircraft-maintenance-history></app-aircraft-maintenance-history>
</section>

<section class="mt-5" *ngIf="currentUser.isAdmin">
  <app-manage-maintenance [aircraftId]="selectedAircraft.id" [aircraftTailNo]="selectedAircraft.tailNo"></app-manage-maintenance>
</section>

<section class="mt-5">
  <app-dash-dates></app-dash-dates>
</section>

<section class="mt-5">
  <app-aircraft-associated-members></app-aircraft-associated-members>
</section>

<section class="mt-5" *ngIf="theAircraft.organizations">

  <app-aircraft-org-list-table [theAircraft]="theAircraft"></app-aircraft-org-list-table>
</section>

<app-aircraft-logs-carousel class="mt-5" [theAircraft]="theAircraft"></app-aircraft-logs-carousel>
