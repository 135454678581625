import { ManufacturerModel } from './manufacturer-model';
import { BaseAircraftEnginePropellerApplianceModel } from './base-aircraft-engine-propeller-appliance-model';
import { AircraftMakeModel } from './aircraft-make-model';

export class ApplianceModel extends BaseAircraftEnginePropellerApplianceModel{
  constructor(){
    super();
    this.id = null;
    this.applianceMake = new AircraftMakeModel;
    this.applianceManufacturer = new ManufacturerModel;
  }

  applianceMake: AircraftMakeModel;
  applianceManufacturer?: ManufacturerModel
}