import { AuthService } from './../../services/auth.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService
    ) { }

  forgotPasswordForm: FormGroup;
  resetEmailSent: boolean = false;

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
        'email': new FormControl(null, [Validators.required, Validators.email]),
    })

    this.resetEmailSent = false;
  }

  doForgotPassword(){
    if(!this.forgotPasswordForm.valid){
      for(let i in this.forgotPasswordForm.controls) {
        this.forgotPasswordForm.controls[i].markAsTouched();
      };

      Swal.fire('Check important fields!', 'Email address not filled correctly!', 'error');
      return;
    }

    this.authService.forgotPassword(this.forgotPasswordForm.controls['email'].value).subscribe(
      (response:any)=>{
        if(response.status.code == 1000){
          Swal.fire(response.status.message, 'Reset password link sent to email address!', 'success');

          //show success message, hide form
          this.resetEmailSent = true;
        }
        else{
          let message = response.status?response.status.message : "Can not reset password.";
          Swal.fire('Oops...', message, 'error');
        }
      },
      (error:any)=>{
        if(error.status && error.status.message){
          Swal.fire(
            error.status.message,
            error.responseData.join(' '),
            'error'
          )
        }
        else{
          Swal.fire(
            'Oops!',
            'Failed to request password reset.',
            'error'
          )
        }
      }
    )
  }
}
