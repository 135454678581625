import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AircraftLogBookTypeOptionsModel } from './../models/aircraft-logbook-type-options-model';
import { AircraftLogBookTypeModel } from '../models/aircraft-logbook-type-model';
import { AircraftLogBookCarouselItemModel } from './../models/aircraft-logbook-carousel-item-model';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AircraftLogBookService {

  constructor(
    private httpClient: HttpClient
  ) { }

  createLogBookItemModel(type: string, tailNo: string) {
    let docUrl = null;

    // switch (type) {
    //   case 'AIRCRAFT':
    //     docKey = 'aircraft';
    //     break;

    //   case 'ENGINE':
    //     docKey = 'engine';
    //     break;

    //   case 'PROPELLER':
    //     docKey = 'propeller';
    //     break;

    //   case 'PROPELLER':
    //     docKey = 'propeller';
    //     break;

    //   case 'AD_COMPLIANCE':
    //     docKey = 'adCompliance';
    //   break;

    //   default:
    //     docKey = null;
    // }

    if (type && tailNo) {
      docUrl = `https://us-central1-crewchief-demo.cloudfunctions.net/cga-prod-api/api/v2/aircraft/${tailNo}/document/${type}`;
    }
    console.log('type docurl', type, tailNo, docUrl);

    return new AircraftLogBookCarouselItemModel(
      type as unknown as AircraftLogBookTypeModel,
      docUrl
    );
  }


  uploadLogBookbyType(formData: FormData, type: AircraftLogBookTypeModel){
    return this.httpClient.post(environment.apiBaseUrl + '/TO_BE_INSERTED', formData)
  }



  /**EXTRAS */
  getLogBookTypeLabelText(type: string){
    let optionLabels: AircraftLogBookTypeOptionsModel = new AircraftLogBookTypeOptionsModel;

    let typeLabel = '';

    optionLabels.options.some(
      (element) => {
        if(element.value.toLowerCase() === type.toLowerCase()) {
          typeLabel = element.label;
          return true;
        }
        else{
          return false;
        }
      }
    );

    return typeLabel;

  }

  getLogBookTypeDoclink(tailNo: string, docType: string) {
    var docUrl;
    if (docType) {
      docUrl =  environment.apiBaseUrl + '/v2/aircraft/' + tailNo + '/document/' + docType + '?downloadUrl=true';

      return this.httpClient.get(docUrl);
    } else {
      return of(null);
    }
  }


  finalizeUploadOnServer(tailNo: string, docType: string) {
    return this.httpClient.post(environment.apiBaseUrl + '/v2/aircraft/' + tailNo + '/document/' + docType, null);
  }

}
