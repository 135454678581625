import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../services/user.service';
import { UserModel } from '../../models/user-model';
import { AlertPopUpsService } from '../../services/alert-pop-ups.service';

@Component({
  selector: 'app-admin-edit-member-profile',
  templateUrl: './admin-edit-member-profile.component.html',
  styleUrls: ['./admin-edit-member-profile.component.scss']
})
export class AdminEditMemberProfileComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private popUpService: AlertPopUpsService
  ) { }

  userEmail: string;
  userProfile: UserModel;
  isWorking: boolean;

  ngOnInit() {
    this.isWorking = false;

    this.route.params.subscribe(
      (params: Params) => {
        this.userEmail = params['email'];

        this.getUserProfile();
      }
    );
  }

  getUserProfile() {
    if (!this.userEmail) {
      return;
    }

    this.isWorking = true;

    this.userService.getUserV2(this.userEmail).subscribe(
      (response: any) => {
        this.isWorking = false;
        this.userProfile = response;
      },
      (error: any) => {
        this.isWorking = false;
        this.popUpService.openBasicPopUp(
          'Sorry',
          'Can not get user details',
          'error'
        );
      }
    );
  }

}
