import { UtilitiesService } from './../services/utilities.service';
import { UserBaseModel } from './../models/user-base-model';
import { UserService } from './../services/user.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardService {
  constructor(
    private router: Router,
    private userService: UserService,
    private utilsService: UtilitiesService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let isAdmin = false;
    const user = this.userService.getCurrentUserInstance();

    if (user.emailAddress) {
      isAdmin = user.isAdmin;
      return this.handleIsAdmin(isAdmin);
    } else {
      this.utilsService.updateShowMainPreloader(true);
      this.userService.getMe().subscribe(
        (response: UserBaseModel) => {
          this.utilsService.updateShowMainPreloader(false);
          isAdmin = response.isAdmin;

          if (isAdmin) {
            const queryParams = next.queryParams;
            this.router.navigate([state.url.split('?')[0]], {queryParams: queryParams});
            return true;
          }

          return this.handleIsAdmin(isAdmin);
        },
        (error: any) => {
          this.utilsService.updateShowMainPreloader(false);
          return this.handleIsAdmin(isAdmin);
        }
      );
    }
  }

  handleIsAdmin(isAdmin: boolean) {
    if (isAdmin) {
      return true;
    }  else {
      console.log('not admin, redirect to dashboard');

      // navigate to login page
      this.router.navigate(['/cga']);
      // you can save redirect url so after authing we can move them back to the page they requested

      return false;
    }
  }
}
