export class PilotDatesModel{
  constructor(){
    this.medicalDueDate = null;
    this.lastMedicalDate = null;
    this.lastFlightReviewDate = null;
    this.flightReviewDueDate = null;
  }

  firstName?: string;
  lastName?: string;
  pilotLicenseNo?: string;

  medicalDueDate: string;
  lastMedicalDate: string;
  lastFlightReviewDate: string;
  flightReviewDueDate: string;
}
