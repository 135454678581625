<section class="org-profile">
  <app-organization-header-widget [organization]="theOrg" [membersList]="membersList"></app-organization-header-widget>

  <section class="mt-3">

    <app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

    <ngb-tabset #orgProfileTabs justify="fill" (tabChange)="onTabChange()">
      <!--Members-->
      <ngb-tab title="Members"  id="tab_members">

        <app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>
        <ng-template ngbTabContent  *ngIf="!isWorking">
          <!--Display Members List-->
          <app-people-manager></app-people-manager>
        </ng-template>
      </ngb-tab>

      <!-------Aircraft -->
      <ngb-tab title="Aircraft"  id="tab_aircraft">

        <ng-template ngbTabContent  *ngIf="!isWorking">
          <!---display Aircraft List -->
          <app-organization-aircraft-list [orgId]="theOrg.id"></app-organization-aircraft-list>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </section>
</section>
