<ng-container *ngIf="statusOnly">
  <span class="badge badge-pill px-3  py-2  {{getIfrStatusBadgeColor()}}">
  I
  </span>
</ng-container>

<ng-container *ngIf="!statusOnly">
  <div class="card shadow border-0 aircraft-dashboard-ifr-equipment">
    <div class="card-header">
      <div class="clearfix">
        <h6 class="float-left mt-1">
          <span class="badge rag_circle {{getIfrStatusBadgeColor()}}">&nbsp;</span>
          IFR Equipment Status <small class="text-muted">@ {{ theAircraft?.airframe?.ttis }} Hours</small>
        </h6>
      </div>
    </div>

    <div class="card-body"> 
      <app-preloader-type-a *ngIf="isLoading"></app-preloader-type-a>
          
      <div *ngIf="!isLoading">

        <table class="table table-striped w-100 rag_table" >
          <tbody>
            <!---NAV DATA-->
            <tr  *ngIf="isLoadingNavaData">
              <app-preloader-type-a></app-preloader-type-a>
            </tr>
            <tr class="{{getRagColor(navDataUpdateRagStatus)}}" *ngIf="!isLoadingNavaData">
              <td>
                <div class="row" *ngIf="!lastNavDataUpdate">
                  <div class="col-12">
                    <div class="alert alert-danger text-center" role="alert">
                      No Nav DB Data information on Aircraft.
                    </div>
                  </div>
                </div>

                <div *ngIf="lastNavDataUpdate">
                  <h6 class="clearfix">
                    <small class="text-primary d-block">
                      &nbsp;
                    </small>
                    Nav DB
                  </h6>
                  <div class="row">
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Effective Date
                      </small>
                      {{ lastNavDataUpdate.effectiveDate? (lastNavDataUpdate.effectiveDate | date:'MM/dd/yyyy' ): null }}
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Due
                      </small>
                      {{ formatMomentAsUsDateString(navDataUpdateNextDueDateMoment) }}
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <div *ngIf="navDataIsEffective">
                        <small class="text-primary d-block">
                          Remaining
                        </small>
                        {{getMomentBetweenDates(todayFlightDateMoment, navDataUpdateNextDueDateMoment)}} 
                      </div>
                      
                      <div *ngIf="!navDataIsEffective">
                        <small class="text-danger d-block">
                          NOT EFFECTIVE
                        </small>
                          -- --
                      </div>  
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <!---VOR CHECK-->
            <tr  *ngIf="isLoadingVorCheck">
              <app-preloader-type-a></app-preloader-type-a>
            </tr>
            <tr class="{{getRagColor(vorCheckRagStatus)}}" *ngIf="!isLoadingVorCheck">
              <td>
                <div class="row" *ngIf="!lastVORCheck">
                  <div class="col-12">
                    <div class="alert alert-danger text-center" role="alert">
                      No IFR VOR Check Information on Aircraft.
                    </div>
                  </div>
                </div>

                <div *ngIf="lastVORCheck">
                  <h6 class="clearfix">
                    <small class="text-primary d-block">
                      &nbsp;
                    </small>
                    VOR Check
                  </h6>
                  <div class="row">
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Last Update
                      </small>
                      {{ lastVORCheck.createdAt | date:'MM/dd/yyyy'  }}
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Due
                      </small>
                      {{ formatMomentAsUsDateString(vorCheckNextDueDateMoment) }}
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Remaining
                      </small>
                      {{getMomentBetweenDates(todayFlightDateMoment, vorCheckNextDueDateMoment)}}  
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <!---PITOT STATIC-->
            <tr class="{{getRagColor(pitotStaticRagStatus)}}">
              <td>
                <div class="row" *ngIf="!lastPitoticDate">
                  <div class="col-12">
                    <div class="alert alert-danger  text-center" role="alert">
                      No Pitot Static information on Aircraft.
                    </div>
                  </div>
                </div>

                <div *ngIf="lastPitoticDate">
                  <h6 class="clearfix">
                    <small class="text-primary d-block">
                      &nbsp;
                    </small>
                    Pitot Static
                  </h6>
                  <div class="row">
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Last Update
                      </small>
                      {{ lastPitoticDate | date:'MM/dd/yyyy' }}
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Due
                      </small>
                      {{ formatMomentAsUsDateString(pitotStaticNextDueDateMoment) }}
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Remaining
                      </small>
                      {{ getMomentBetweenDates( todayFlightDateMoment, pitotStaticNextDueDateMoment ) }}  
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <!---TRANSPONDER-->
            <tr class="{{getRagColor(transponderRagStatus)}}">

              <td>
                <div class="row" *ngIf="!lastTransponderDate">
                  <div class="col-12">
                    <div class="alert alert-danger  text-center" role="alert">
                      No Transponder information on Aircraft.
                    </div>
                  </div>
                </div>

                <div *ngIf="lastTransponderDate">
                  <h6 class="clearfix">
                    <small class="text-primary d-block">
                      &nbsp;
                    </small>
                    Transponder
                  </h6>
                  <div class="row">
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Last Update
                      </small>
                      {{ lastTransponderDate | date:'MM/dd/yyyy' }}
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Due
                      </small>
                      {{ formatMomentAsUsDateString(transponderNextDueDateMoment) }}
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Remaining
                      </small>
                      {{ getMomentBetweenDates( todayFlightDateMoment, transponderNextDueDateMoment ) }} 
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <!---ALTIMETER-->
            <tr class="{{getRagColor(altimeterRagStatus)}}">
              <td>
                <div class="row" *ngIf="!lastAltimeterDate">
                  <div class="col-12">
                    <div class="alert alert-danger  text-center" role="alert">
                      No Altimeter information on Aircraft.
                    </div>
                  </div>
                </div>

                <div *ngIf="lastAltimeterDate">
                  <h6 class="clearfix">
                    <small class="text-primary d-block">
                      &nbsp;
                    </small>
                    Altimeter
                  </h6>
                  <div class="row">
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Last Update
                      </small>
                      {{ lastAltimeterDate | date:'MM/dd/yyyy' }}
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Due
                      </small>
                      {{ formatMomentAsUsDateString(altimeterNextDueDateMoment) }}
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 my-2">
                      <small class="text-primary d-block">
                        Remaining
                      </small>
                      {{ getMomentBetweenDates( todayFlightDateMoment, altimeterNextDueDateMoment ) }} 
                    </div>
                  </div>
                </div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
      
    </div>
  </div>
</ng-container>