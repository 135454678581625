<ng-container *ngIf="statusOnly">
  <span class="badge badge-pill px-3  py-2 {{finalMaintenanceStatus}}">
  M
  </span>
</ng-container>

<ng-container *ngIf="!statusOnly">
  <div class="card shadow border-0 aircraft-dashboard-safety">
    <div class="card-header">
      <div class="clearfix">
        <h6 class="float-left mt-1">
          <span class="badge rag_circle {{finalMaintenanceStatus}}">&nbsp;</span>
          Maintenance Status <small class="text-muted">@ {{ todayFlightHours }} Hours</small>
        </h6>
        
      </div>
    </div>
  
    <div class="card-body"> 
      <app-preloader-type-a *ngIf="isLoading"></app-preloader-type-a>
          
      <div *ngIf="!isLoading">
  
        <table class="table table-striped w-100 rag_table" >
          <tbody>
  
              <!---ANNUALS-->
              <tr class="{{getRagColor(annualRagStatus)}}">
                
                <td colspan="4" *ngIf="!lastAircraftAnnualsDate">
                  <div class="alert alert-danger text-center" role="alert">
                    No Maintenance Annual Information on Aircraft.
                  </div>
                </td>
    
                <ng-container  *ngIf="lastAircraftAnnualsDate">
                  <td>
                    <small class="text-primary d-block">
                      &nbsp;
                    </small>
                    Annual
                  </td>
                  <td>
                    <small class="text-primary d-block">
                      Due
                    </small>
                    {{ formatMomentAsUsDateString(annualsNextDueDateMoment) }}
                  </td>
                  <td>
                    <small class="text-primary d-block">
                      Remaining
                    </small>
                    {{getMomentBetweenDates(todayFlightDateMoment, annualsNextDueDateMoment)}}
                  </td>
                </ng-container>
              </tr>
  
              <!---100Hours-->
              <tr class="{{getRagColor(_100HoursRagStatus)}}">
                
                <td colspan="4" *ngIf="!lastAircraft100Hrs">
                  <div class="alert alert-danger text-center" role="alert">
                    No Maintenance 100 Hours information on Aircraft.
                  </div>
                </td>
    
                <ng-container  *ngIf="lastAircraft100Hrs">
                  <td>
                    <small class="text-primary d-block">
                      &nbsp;
                    </small>
                    100 Hour
                  </td>
                  <td>
                    <small class="text-primary d-block">
                      Due
                    </small>
                    {{ _100HoursNextDueHrs }} hours
                  </td>
                  <td>
                    <small class="text-primary d-block">
                      Remaining
                    </small>
                    {{getHoursRemainingBetweenHours(todayFlightHours, _100HoursNextDueHrs)}}   
                  </td>
                </ng-container>
              </tr>
  
              <!---Oil CHanges-->
              <tr class="{{getRagColor(OilChangeRagStatus)}}">
                
                <td colspan="4" *ngIf="!lastAircraftOilChangesHrs">
                  <div class="alert alert-danger  text-center" role="alert">
                    No Maintenance Oil Change information on Aircraft.
                  </div>
                </td>
    
                <ng-container  *ngIf="lastAircraftOilChangesHrs">
                  <td>
                    <small class="text-primary d-block">
                      &nbsp;
                    </small>
                    Oil Change
                  </td>
                  <td>
                    <small class="text-primary d-block">
                      Due
                    </small>
                    {{ oilChangeNextDueHrs }} hours
                  </td>
                  <td>
                    <small class="text-primary d-block">
                      Remaining
                    </small>
                    {{getHoursRemainingBetweenHours(todayFlightHours, oilChangeNextDueHrs)}}   
                  </td>
                </ng-container>
              </tr>
  
              <!---ELTS-->
              <tr class="{{getRagColor(eltRagStatus)}}">
                
                <td colspan="4" *ngIf="!lastAircraftEltsDate">
                  <div class="alert alert-danger text-center" role="alert">
                    No Maintenance ELT Information on Aircraft.
                  </div>
                </td>
    
                <ng-container  *ngIf="lastAircraftEltsDate">
                  <td>
                    <small class="text-primary d-block">
                      &nbsp;
                    </small>
                    ELT Service
                  </td>
                  <td>
                    <small class="text-primary d-block">
                      Due
                    </small>
                    {{ eltNextDueDateMoment | date:'MM/dd/yyyy' }}
                  </td>
                  <td>
                    <small class="text-primary d-block">
                      Remaining
                    </small>
                    {{getMomentBetweenDates(todayFlightDateMoment, eltNextDueDateMoment)}}   
                  </td>
                </ng-container>
              </tr>
    
  
          </tbody>
        </table>
      </div>
      
    </div>
  </div>
</ng-container>