import { OptionLabelsAndValuesModel } from './option-labels-and-values-model';

export class adSbTypeOptionsModel {
  constructor(){
    this.options = [
      {label: 'Airframe', value: 'airframe'},
      {label: 'Engine', value: 'engine'},
      {label: 'Propeller', value: 'propeller'},
      {label: 'Appliance', value: 'appliance'},
    ]
  }
  options: Array<OptionLabelsAndValuesModel>;
}