import { AircraftAdminDates } from './../models/aircraft-admin-dates-model';
import { UserAircraftModel } from './../models/user-aircraft-model';
import { UtilitiesService } from './utilities.service';
import { OrganizationAircraftRoleTypes } from './../models/organization-aircraft-role-types';
import { ServiceBulletinModel } from './../models/service-bulletin-model';
import { AirworthinessDirectiveModel } from './../models/airworthiness-directive-model';
import { ApplianceModel } from './../models/appliance-model';
import { PropellerModel } from './../models/propeller-model';
import { AircraftModel } from './../models/aircraft-model';
import { FlightLogsService } from './flight-logs.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { EngineModel } from '../models/engine-model';
import { AicraftUserRoleTypes } from '../models/aircraft-user-role-types';
import { UserMenuAircraftModel } from '../models/user-menu-aircraft-model';
import { AircraftRoleOptionsModel } from '../models/aicraft-role-options-model';
import { AircraftV2Model } from '../models/airfcraftV2/aircraft-v2.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AircraftService {
  userAircraftList: Array<AircraftV2Model> = [];
  _userAircraftList: BehaviorSubject<
    Array<AircraftV2Model>
  > = new BehaviorSubject(this.userAircraftList);
  userAircraftList$: Observable<
    Array<AircraftV2Model>
  > = this._userAircraftList.asObservable();

  selectedAircraft: AircraftV2Model = new AircraftV2Model();
  _selectedAircraft: BehaviorSubject<AircraftV2Model> = new BehaviorSubject(
    this.selectedAircraft
  );
  selectedAircraft$: Observable<
    AircraftV2Model
  > = this._selectedAircraft.asObservable();

  constructor(
    private httpClient: HttpClient,
    private utilsService: UtilitiesService,
    private flightLogsService: FlightLogsService,
    private router: Router
  ) {}

  resetUserMenuAircraft() {
    this.userAircraftList = [];
    this.selectedAircraft = new AircraftV2Model();
    this._userAircraftList.next(this.userAircraftList);
  }

  /**
   * V2 APIs for Aircraft
   */

  /**
   * Get list of user Aircraft in menu
   * @param emailAddress
   */
  updateUserAircraftMenuList(
    selectedOrgId: number,
    updateAircraft: boolean = true
  ) {
    this.utilsService.updateShowMainPreloader(true);
    this.getAircraftByOrganizationV2(selectedOrgId).subscribe(
      (response: any) => {
        this.utilsService.updateShowMainPreloader(false);
        this.userAircraftList = response;

        if (updateAircraft) {
          if (this.userAircraftList.length > 0) {
            const storedAircraftTail = localStorage.getItem(environment.selectedAircraftKey);
            let storedAircraftIndex = null;

            if (storedAircraftTail) {
              this.userAircraftList.some(
                (aircraft, i) => {
                  if (aircraft.tailNo === storedAircraftTail) {
                    storedAircraftIndex = i;
                    return true;
                  } else {
                    return false;
                  }
                }
              );
            }

            if (storedAircraftIndex !== null) {
              this.setSelectedAircraft(this.userAircraftList[storedAircraftIndex]);
            } else {
              this.setSelectedAircraft(this.userAircraftList[0]);
            }
          } else {
            this.setSelectedAircraft(new AircraftV2Model());
          }
        }

        this._userAircraftList.next(this.userAircraftList);
      },
      (error: any) => {
        this.utilsService.updateShowMainPreloader(false);
        /**
         * TODO: HANDLE ERROR
         */
      }
    );
  }

  /**
   * Set selected Aircraft
   * @param id
   */
  setSelectedAircraft(aircraft: AircraftV2Model) {
    this.selectedAircraft = aircraft;
    this._selectedAircraft.next(this.selectedAircraft);
    localStorage.setItem(environment.selectedAircraftKey, this.selectedAircraft.tailNo);
  }

  /**
   * refresh selected aircraft
   * @param
   */
  refreshSelectedAircraft() {
    this.utilsService.updateShowMainPreloader(true);

    this.getAircraftV2(this.selectedAircraft.tailNo).subscribe(
      (response: AircraftV2Model) => {
        this.utilsService.updateShowMainPreloader(false);
        this.setSelectedAircraft(response);
      },
      (error: any) => {
        this.utilsService.updateShowMainPreloader(false);
        console.log('...Refresh Selected Aircraft failed.');
      }
    );
  }

  /*** User Roles and Aircraft  APis***/
  /**
   * Get Users on Aurcraft
   * @param aircraftId
   */
  getUsersByAircraftV2(aircraftId: number) {
    return this.httpClient.get(
      environment.apiBaseUrl + `/v2/aircraft/${aircraftId}/user`
    );
  }

  // Get Aircrafts User has access to
  getAircraftByUserV2(emailAddress: string) {
    return this.httpClient.get(
      environment.apiBaseUrl + `/v2/user/${emailAddress}/aircraft`
    );
  }

  putAircraftUserRoleV2(
    aircraftId: number,
    emailAddress: string,
    role: AicraftUserRoleTypes
  ) {
    return this.httpClient.put(
      environment.apiBaseUrl +
        `/v2/aircraft/${aircraftId}/user/${emailAddress}/role/${role}`,
      null
    );
  }

  /**
   * Remove user role on aircraft
   * @param aircraftId
   * @param emailAddress
   */
  deleteAircraftUserRoleV2(aircraftId: number, emailAddress: string) {
    return this.httpClient.delete(
      environment.apiBaseUrl + `/v2/aircraft/${aircraftId}/user/${emailAddress}`
    );
  }

  /** Organization Roles and Aircraft V2 ***/

  // Get aircraft in an organization withthe organization's roles
  getAircraftByOrganizationV2(orgId: number) {
    return this.httpClient.get(
      environment.apiBaseUrl + `/v2/organization/${orgId}/aircraft`
    );
  }

  // Get organization role access on aircraft
  getOrganizationRoleOnAircraftV2(orgId: number, aircraftId: number) {
    return this.httpClient.get(
      environment.apiBaseUrl +
        `/v2/organization/${orgId}/aircraft/${aircraftId}`
    );
  }

  // Grant organization role access to aircraft with role
  attachOrgAndRoleToAircraftV2(
    orgId: number,
    aircraftId: number,
    role: string
  ) {
    return this.httpClient.put(
      environment.apiBaseUrl +
        `/v2/organization/${orgId}/aircraft/${aircraftId}/role/${role}`,
      null
    );
  }

  // Remove Organization access to aricraft
  deleteOrgAndRoleFromAircraftV2(orgId: number, aircraftId: number) {
    return this.httpClient.delete(
      environment.apiBaseUrl +
        `/v2/organization/${orgId}/aircraft/${aircraftId}`
    );
  }

  /******AIRCRAFT MANAGEMENT V2*/

  /**
   * Get allAIrcraft
   */
  getAllAircraftV2() {
    return this.httpClient.get(environment.apiBaseUrl + `/v2/aircraft`);
    // return this.httpClient.get(environment.apiBaseUrl + `/v2/aircraft`)
  }

  /**
   * Get single aircraft
   * @param tailNumber (string)
   */
  getAircraftV2(tailNumber: string) {
    return this.httpClient.get(
      environment.apiBaseUrl + `/v2/aircraft/${tailNumber}`
    );
  }

  /**
   * Create an aircraft
   * @param tailNumber (string))
   * @param aircraft (object)
   */
  createAircraftV2(tailNumber: string, aircraft: UserAircraftModel) {
    return this.httpClient.post(
      environment.apiBaseUrl + `/v2/aircraft/${tailNumber}`,
      aircraft
    );
  }

  /**
   * Update an aircraft
   * @param tailNumber (string))
   * @param aircraft (object)
   */
  updateAircraftV2(tailNumber: string, aircraft: UserAircraftModel) {
    return this.httpClient.put(
      environment.apiBaseUrl + `/v2/aircraft/${tailNumber}`,
      aircraft
    );
  }

  /**
   * Update an aircraft
   * @param tailNumber (string)
   * @param aircraftAdminDates (object)
   */
  updateAircraftAdminDates(
    tailNumber: string,
    aircraftAdminDates: AircraftAdminDates
  ) {
    return this.httpClient.put(
      environment.apiBaseUrl + `/v2/aircraft/${tailNumber}`,
      aircraftAdminDates
    );
  }

  /**ENd V2 */

  getAircraftById(aircraftId: number) {
    return this.httpClient.get(
      environment.apiBaseUrl + '/aircraft/getAircraftById?id=' + aircraftId
    );
  }

  getUserAircraft(userId: number) {
    return this.httpClient.get(
      environment.apiBaseUrl + '/aircraft/getUserAircraft?userId=' + userId
    );
  }

  createNewAircraftForUser(newAircraft: object) {
    return this.httpClient.post(
      environment.apiBaseUrl + '/aircraft/createNewAircraftForUser',
      newAircraft
    );
  }

  updateAircraftForUser(theAircraft: object) {
    return this.httpClient.put(
      environment.apiBaseUrl + '/aircraft/updateAircraftForUser',
      theAircraft
    );
  }

  findAircraftMakeByName(queryString: string) {
    return this.httpClient.get(
      environment.apiBaseUrl +
        '/aircraft/findAircraftMakeByName?q=' +
        queryString
    );
  }

  findAircraftModelByMake(
    queryString: string,
    makeId: number,
    makeName: string
  ) {
    return this.httpClient.get(
      environment.apiBaseUrl +
        '/aircraft/findAircraftModelByMake?q=' +
        queryString +
        '&makeId=' +
        makeId +
        '&makeName=' +
        makeName
    );
  }

  findEngineModelByMake(queryString: string, makeId: number, makeName: string) {
    return this.httpClient.get(
      environment.apiBaseUrl +
        '/engine/findEngineModelByMake?q=' +
        queryString +
        '&makeId=' +
        makeId +
        '&makeName=' +
        makeName
    );
  }

  findPropellerModelByMake(
    queryString: string,
    makeId: number,
    makeName: string
  ) {
    return this.httpClient.get(
      environment.apiBaseUrl +
        '/propeller/findPropellerModelByMake?q=' +
        queryString +
        '&makeId=' +
        makeId +
        '&makeName=' +
        makeName
    );
  }

  // EXTRAS
  // getTodayFlightHours(aircraft:UserAircraftModel): Observable<any>{

  //   return this.flightLogsService.getLastAircraftFlightLog(aircraft.id)
  //   .pipe(
  //     map(
  //       (response:any) =>{
  //         let result: {todayFlightHours: number, success: boolean} = {
  //           todayFlightHours: null,
  //           success: false
  //         }

  //         let todayFlightHours = null;
  //         if(response.status.code == 1000){

  //           let lastFlightLogEntry = response.responseData? response.responseData : new AircraftFlightLogModel;
  //           let lastTimeInHours = lastFlightLogEntry.timeInHours? lastFlightLogEntry.timeInHours : 0;
  //           let lastTimeOutHours = lastFlightLogEntry.timeOutHours? lastFlightLogEntry.timeOutHours : 0;
              // check if to use last timeIn or TimeOut for todayFlightHours
  //           todayFlightHours = lastTimeInHours > 0? +lastTimeInHours : +lastTimeOutHours;

  //           result.todayFlightHours = todayFlightHours !== null? todayFlightHours : aircraft.airframeTtis;
  //           result.success = true;

  //           return result;
  //         }
  //         else{
  //           return  {todayFlightHours: null, success: false} ;
  //         }
  //       }
  //     )
  //   )

  // }

  /**
   * Get name of Manufacturer in a AircraftModel, EngineModel, PropellerModel, ApplianceModel
   * @param sbOrAd  AircraftModel, EngineModel, PropellerModel, ApplianceModel
   */
  getModelManufacturerName(
    modelObj: AircraftModel | EngineModel | PropellerModel | ApplianceModel
  ) {
    let modelName = null;
    if ((<AircraftModel>modelObj).aircraftManufacturer) {
      const model = modelObj as AircraftModel;
      modelName = model.aircraftManufacturer.manufacturerName;
    } else if ((<EngineModel>modelObj).engineManufacturer) {
      const model = modelObj as EngineModel;
      modelName = model.engineManufacturer.manufacturerName;
    } else if ((<PropellerModel>modelObj).propellerManufacturer) {
      const model = modelObj as PropellerModel;
      modelName = model.propellerManufacturer.manufacturerName;
    } else if ((<ApplianceModel>modelObj).applianceManufacturer) {
      const model = modelObj as ApplianceModel;
      modelName = model.applianceManufacturer.manufacturerName;
    } else {
      modelName = null;
    }

    return modelName ? modelName : '';
  }

  /**
   * Get List of Manufacturers of all models in Bulletin or Airworthiness Directive
   * @param sbOrAd Bulletin or Airworthiness Directive
   */
  getSbOrAdModelManufacturers(
    sbOrAd: AirworthinessDirectiveModel | ServiceBulletinModel
  ) {
    let models = [];
    const manufacturerNamesList = [];
    if (sbOrAd.adSbType == 'airframe') {
      models = sbOrAd.aircraftModels ? sbOrAd.aircraftModels : [];
    }
    if (sbOrAd.adSbType == 'engine') {
      models = sbOrAd.engineModels ? sbOrAd.engineModels : [];
    }
    if (sbOrAd.adSbType == 'propeller') {
      models = sbOrAd.propellerModels ? sbOrAd.propellerModels : [];
    }
    if (sbOrAd.adSbType == 'appliance') {
      models = sbOrAd.applianceModels ? sbOrAd.applianceModels : [];
    }

    models.forEach(el => {
      const manufacturerName = this.getModelManufacturerName(el);

      if (
        manufacturerNamesList.indexOf(manufacturerName) < 0 &&
        manufacturerName !== ''
      ) {
        manufacturerNamesList.push(manufacturerName);
      }
    });

    return manufacturerNamesList.join(', ');
  }

  // FOR AD/SB MODELS TypeAheadSearch
  getHighlightDisplayName = (
    r: AircraftModel | EngineModel | PropellerModel | ApplianceModel
  ) => {
    const modelObjName = r.modelName;
    const modelObjManufacturer = this.getModelManufacturerName(r);
    return modelObjName + modelObjManufacturer;
  }

  /**
   * Get name of Make in a AircraftModel, EngineModel, PropellerModel, ApplianceModel
   * @param sbOrAd  AircraftModel, EngineModel, PropellerModel, ApplianceModel
   */
  getModelMakeName(
    modelObj: AircraftModel | EngineModel | PropellerModel | ApplianceModel
  ) {
    let makeName = null;
    if ((<AircraftModel>modelObj).aircraftMake) {
      const model = modelObj as AircraftModel;
      makeName = model.aircraftMake.makeName;
    } else if ((<EngineModel>modelObj).engineMake) {
      const model = modelObj as EngineModel;
      makeName = model.engineMake.makeName;
    } else if ((<PropellerModel>modelObj).propellerMake) {
      const model = modelObj as PropellerModel;
      makeName = model.propellerMake.makeName;
    } else if ((<ApplianceModel>modelObj).applianceMake) {
      const model = modelObj as ApplianceModel;
      makeName = model.applianceMake.makeName;
    } else {
      makeName = null;
    }

    return makeName ? makeName : '';
  }

  /**
   * Get List of Makes of all models in Bulletin or Airworthiness Directive
   * @param sbOrAd Bulletin or Airworthiness Directive
   */
  getSbOrAdModelMakes(
    sbOrAd: AirworthinessDirectiveModel | ServiceBulletinModel
  ) {
    let models = [];
    const makeNameList = [];
    if (sbOrAd.adSbType == 'airframe') {
      models = sbOrAd.aircraftModels ? sbOrAd.aircraftModels : [];
    }
    if (sbOrAd.adSbType == 'engine') {
      models = sbOrAd.engineModels ? sbOrAd.engineModels : [];
    }
    if (sbOrAd.adSbType == 'propeller') {
      models = sbOrAd.propellerModels ? sbOrAd.propellerModels : [];
    }
    if (sbOrAd.adSbType == 'appliance') {
      models = sbOrAd.applianceModels ? sbOrAd.applianceModels : [];
    }

    models.forEach(el => {
      const makeName = this.getModelMakeName(el);

      if (makeNameList.indexOf(makeName) < 0 && makeName !== '') {
        makeNameList.push(makeName);
      }
    });

    return makeNameList.join(', ');
  }

  /**EXTRAS */
  getAircraftRoleLabelText(role: string) {
    const optionLabels: AircraftRoleOptionsModel = new AircraftRoleOptionsModel();

    let roleLabel = '';

    optionLabels.options.some(element => {
      if (element.value === role.toUpperCase()) {
        roleLabel = element.label;
        return true;
      } else {
        return false;
      }
    });

    return roleLabel;
  }

  convertV2AircraftToV1Model(v2AircraftModel: AircraftV2Model) {
    // convert to V1 UserAircraftModel
    const v1AircraftModel: UserAircraftModel = new UserAircraftModel();

    v1AircraftModel.id = v2AircraftModel.id;
    v1AircraftModel.tailNo = v2AircraftModel.tailNo;
    v1AircraftModel.airframeSn = v2AircraftModel.airframe.serialNumber;
    v1AircraftModel.airframeTtis = v2AircraftModel.airframe.ttisAtSetup;

    v1AircraftModel.engineSn = v2AircraftModel.engines[0].serialNumber;
    v1AircraftModel.initialEngineHours =
      v2AircraftModel.engines[0].initialHours;
    v1AircraftModel.engineInstalledAirframeTime =
      v2AircraftModel.engines[0].installedAirframeTime;
    v1AircraftModel.initialLastEngineOh =
      v2AircraftModel.engines[0].lastOverhaulHours;

    v1AircraftModel.propellerSn = v2AircraftModel.propellers[0].serialNumber;
    v1AircraftModel.initLastPropellerOh =
      v2AircraftModel.propellers[0].lastOverhaulHours;
    v1AircraftModel.inititialPropellerHours =
      v2AircraftModel.propellers[0].initialHours;
    v1AircraftModel.propellerInstalledAirframeTime =
      v2AircraftModel.propellers[0].installedAirframeTime;

    v1AircraftModel.avionicsEquipments = v2AircraftModel.avionicsEquipments;
    v1AircraftModel.otherEquipments = v2AircraftModel.otherEquipments;

    v1AircraftModel.propeller_model_id = v2AircraftModel.propellers[0].model.id;
    v1AircraftModel.engine_model_id = v2AircraftModel.engines[0].model.id;
    v1AircraftModel.aircraft_model_id = v2AircraftModel.airframe.model.id;

    v1AircraftModel.aircraftLogbookState =
      v2AircraftModel.airframe.logbookState;
    v1AircraftModel.engineLogbookState =
      v2AircraftModel.engines[0].logbookState;
    v1AircraftModel.propellerLogbookState =
      v2AircraftModel.propellers[0].logbookState;
    v1AircraftModel.adComplianceReportState =
      v2AircraftModel.adComplianceReportState;
    v1AircraftModel.adminApproved = v2AircraftModel.adminApproved;

    v1AircraftModel.registrationDueDate = v2AircraftModel.registrationDueDate;
    v1AircraftModel.insuranceDueDate = v2AircraftModel.insuranceDueDate;

    v1AircraftModel.engine_model = {
      id: v2AircraftModel.engines[0].model.id,
      modelName: v2AircraftModel.engines[0].model.name,
      engineMake: {
        id: v2AircraftModel.engines[0].make.id,
        makeName: v2AircraftModel.engines[0].make.name
      },
      make: null,
      manufacturer: null
    };

    v1AircraftModel.propeller_model = {
      id: v2AircraftModel.propellers[0].model.id,
      modelName: v2AircraftModel.engines[0].model.name,
      propellerMake: {
        id: v2AircraftModel.propellers[0].make.id,
        makeName: v2AircraftModel.propellers[0].make.name
      },
      make: null,
      manufacturer: null
    };

    v1AircraftModel.aircraft_model = {
      id: v2AircraftModel.airframe.model.id,
      modelName: v2AircraftModel.engines[0].model.name,
      aircraftMake: {
        id: v2AircraftModel.airframe.make.id,
        makeName: v2AircraftModel.airframe.make.name
      },
      make: null,
      manufacturer: null
    };

    return v1AircraftModel;
  }
}
