import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { MustBeSameAsControValidator } from '../../_custom-validators/must-be-same-as-control-validator';

@Component({
  selector: 'app-auth-actions',
  templateUrl: './auth-actions.component.html',
  styleUrls: ['./auth-actions.component.scss']
})
export class AuthActionsComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  authMode: string = null;
  resetOobCode: string = "";
  actionSuccessful: boolean = false;

  newPassword: string = null;

  resetPasswordForm: FormGroup;

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      if(params['mode'] == 'resetPassword'){
        this.authMode = 'resetPassword';
        this.resetOobCode = params['oobCode'];

        //Init password reset from
        this.resetPasswordForm = this.formBuilder.group({
          'password': new FormControl(null),
          'password2': new FormControl(null)
        })

        this.resetPasswordForm.get('password').setValidators([
          Validators.required,
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.[(!@#\$%\^&\*)*]?)(?=.{6,})/),
          MustBeSameAsControValidator(this.resetPasswordForm.get('password2'), false)
        ])

        this.resetPasswordForm.get('password2').setValidators([
          Validators.required,
          MustBeSameAsControValidator(this.resetPasswordForm.get('password'))
        ])
      }
      // else if(params['mode'] == 'resetPassword' && params['oobCode']){

      // }
      else{
        this.router.navigate['/login'];
      }
    });

    //TODO:
    //If no OOb code, route to login page

    this.actionSuccessful = false;
  }

  doResetPassword(){
    if(!this.resetPasswordForm.valid){
      for(let i in this.resetPasswordForm.controls) {
        this.resetPasswordForm.controls[i].markAsTouched();
      };

      Swal.fire('Check important fields!', 'Form not filled correctly!', 'error');
      return;
    }

    this.newPassword = this.resetPasswordForm.controls['password'].value;
    this.authService.resetPasswordWithCode(this.resetOobCode, this.newPassword).subscribe(
      (response:any)=>{
        if(response.status.code == 1000){
          Swal.fire(response.status.message, 'Password has been reset. Proceed to login with the new password!', 'success');

          //show success message, hide form
          this.actionSuccessful = true;
        }
        else{
          let message = response.status?response.status.message : "Can not reset password.";
          Swal.fire('Oops...', message, 'error');
        }
      },
      (error:any)=>{
        if(error.status && error.status.message){
          Swal.fire(
            error.status.message,
            error.responseData.join(' '),
            'error'
          )
        }
        else{
          Swal.fire(
            'Oops!',
            'Failed to reset password.',
            'error'
          )
        }
      }
    )
  }
}
