<h4>Customer Information</h4>

<h6 class="mb-0">
  <i class="la la-user"></i>
  {{ theUser?.firstName }} {{ theUser?.lastName }} / {{ theUser.pilotLicenseNo }}
</h6>
<small class="text-muted mt-1 mb-3">Customer Name / Pilot Certificate Number</small>

<section class="mt-3">
  <ngb-tabset>
    <!--Customer Info-->
    <ngb-tab title="Customer Info">
      <ng-template ngbTabContent>
        <!---display Info -->
        <section class="p-3 admin-view-user" *ngIf="!isInfoEditMode">
          <div class="card shadow border-0">
            <div class="card-header">
              ROLE: {{theUser.isAdmin? 'Admin' : 'Customer'}}
            </div>
            <div class="card-body">
              <div class="info_view">
                <div>
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="form-group">
                        <small id="firstname_help" class="form-text text-muted">Customer's first name.</small>
                        <div>{{theUser?.firstName}}</div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="form-group">
                        <small id="lastname_help" class="form-text text-muted">Customer's last name.</small>
                        <div>{{theUser?.lastName}}</div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="form-group">
                        <small id="mobilephone_help" class="form-text text-muted">Customer's mobile phone number.</small>
                        <div *ngIf="theUser && theUser.mobilePhone">
                          {{theUser.mobilePhone? formatPhoneNumber(theUser.mobilePhone) : '----'}}
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="form-group">
                        <small id="email_help" class="form-text text-muted">Customer's email address.</small>
                        <div>{{theUser?.emailAddress}} </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="form-group">
                        <small id="pilot_cert_help" class="form-text text-muted">Customer's Pilot Certificate #.</small>
                        <div>{{theUser?.pilotLicenseNo}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-right mt-2">
                  <button class="btn btn-outline-dark" (click)="loadEditUserForm()">
                    <i class="la la-pencil-square"></i> Edit Customer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!--- Edit info -->
        <section class="p-3 admin-edit-user" *ngIf="isInfoEditMode">
          <div class="card shadow border-0">
            <div class="card-header">
              Edit Customer {{theUser?.firstName}} {{theUser?.lastName}}
            </div>
            <div class="card-body">
              <div class="info_edit">
                <form [formGroup]="editUserForm" (ngSubmit)="doUpdateUser()">
                  <div>
                    <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                          <small id="firstname_help" class="form-text text-muted required">Enter customer's first name.</small>
                          <input type="text" formControlName="firstName" class="form-control" id="firstname" aria-describedby="firstname_help" placeholder="First Name">
                          <small class="form-text text-danger" *ngIf="editUserForm.get('firstName').invalid && (editUserForm.get('firstName').dirty || editUserForm.get('firstName').touched)">
                            Enter valid first name.
                          </small>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                          <small id="lastname_help" class="form-text text-muted required">Enter customer's last name.</small>
                          <input type="text" formControlName="lastName" class="form-control" id="lastname" aria-describedby="lastname_help" placeholder="Last Name">
                          <small class="form-text text-danger" *ngIf="editUserForm.get('lastName').invalid && (editUserForm.get('lastName').dirty || editUserForm.get('lastName').touched)">
                            Enter valid last name.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                          <small id="mobilephone_help" class="form-text text-muted">Enter customer's mobile phone number.</small>
                          <input type="text" formControlName="mobilePhone" class="form-control" id="mobile_phone" aria-describedby="mobilephone_help" placeholder="Mobile Phone">
                          <small class="form-text text-danger" *ngIf="editUserForm.get('mobilePhone').invalid && (editUserForm.get('mobilePhone').dirty || editUserForm.get('mobilePhone').touched)">
                            Enter a valid phone number with country code<b> e.g +1 201 XXX XXXX</b>.
                          </small>
                        </div>
                      </div>

                      <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                          <small id="email_help" class="form-text text-muted required">Enter customer's email address.</small>
                          <input type="text" formControlName="emailAddress" class="form-control" id="emailAddress" aria-describedby="email_help" placeholder="Email Address">
                          <small class="form-text text-danger" *ngIf="editUserForm.get('emailAddress').invalid && (editUserForm.get('emailAddress').dirty || editUserForm.get('emailAddress').touched)">
                            Enter valid email address.
                          </small>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                          <small id="pilot_cert_help" class="form-text text-muted required">Enter customer's Pilot Certificate #.</small>
                          <input type="text" formControlName="pilotLicenseNo" class="form-control" id="pilot_certificate" aria-describedby="pilot_cert_help" placeholder="Pilot Certificate #">
                          <small class="form-text text-danger" *ngIf="editUserForm.get('pilotLicenseNo').invalid && (editUserForm.get('pilotLicenseNo').dirty || editUserForm.get('pilotLicenseNo').touched)">
                            Minimum of 4 characters, maximum of 8 characters
                          </small>
                        </div>
                      </div>

                      <div class="col-sm-12 col-md-6">
                        <small *ngIf="currentUser.id === theUser.id">
                          <i>Contact another administrator to update your <b>admin status</b> if you need to.</i>
                        </small>

                        <div class="row"  *ngIf="currentUser.id !== theUser.id">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="form-check py-2">
                                <input class="form-check-input" formControlName="isAdmin"  type="checkbox" id="isadmin">
                                <label class="form-check-label" for="isadmin">
                                  User as Admin?
                                </label>
                              </div>
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="form-group">
                              <div class="form-check py-2">
                                <input class="form-check-input" formControlName="doPasswordUpdate"  type="checkbox" id="doPasswordUpdate">
                                <label class="form-check-label" for="doPasswordUpdate">
                                  Update password?
                                </label>
                              </div>
                              <small class="form-text text-danger" *ngIf="editUserForm.hasError('hasDependantIfControlIs_true', 'doPasswordUpdate')">
                                You need to enter the new password.
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row" *ngIf="editUserForm.get('doPasswordUpdate').value">
                        <div class="col-sm-12 col-md-6">
                          <div class="form-group">
                            <small class="form-text text-muted required">Password (min. of 6 characters and numbers).</small>
                            <input type="password" formControlName="password1" class="form-control" id="password1" placeholder="Password" autocomplete="new-password">
                            <div *ngIf="editUserForm.get('password1').dirty || editUserForm.get('password1').touched">
                              <small class="form-text text-danger" *ngIf="editUserForm.hasError('dependsOnControlIfControlIs', 'password1')">
                                Password is required.
                              </small>
                              <div *ngIf="!editUserForm.hasError('required', 'password1')">
                                <small class="form-text text-danger" *ngIf="editUserForm.hasError('pattern', 'password1')">
                                  Minimum of 6 characters; Must have a number, an uppercase letter, and a lowercase letter.
                                </small>
                                <small class="form-text text-danger" *ngIf="editUserForm.hasError('mustBeSame', 'password1') && !(editUserForm.hasError('pattern', 'password1'))">
                                  Password and Confirm password must be the same.
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-6">
                          <div class="form-group">
                            <small id="password2" class="form-text text-muted required">Confirm Password.</small>
                            <input type="password" formControlName="password2" class="form-control" id="password2" placeholder="Confirm Password" autocomplete="new-password">
                            <small class="form-text text-danger" *ngIf="editUserForm.hasError('required', 'password2') && (editUserForm.get('password2').dirty || editUserForm.get('password2').touched)">
                              Confirm Password Required
                            </small>
                            <small class="form-text text-danger" *ngIf="editUserForm.hasError('mustBeSame', 'password2') && !(editUserForm.hasError('required', 'password2') )">
                              <b>Confirm Password</b> must be same as <b>Password</b>.
                            </small>
                          </div>
                        </div>
                    </div>

                  </div>
                  <div class="text-right mt-2">
                      <a class="btn btn-outline-danger mr-3" href="javascript:void(0);" (click)="toggleInEditMode()">Cancel</a>
                      <button class="btn btn-outline-success">Save Edits</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <!-- Password Reset-->
        <section class="p-3 admin-password-reset" *ngIf="theUser.emailAddress">
          <div class="card shadow border-0">
            <div class="card-header">
              Set Password
            </div>
            <div class="card-body text-center">
              <app-preloader-type-a *ngIf="isWorkingPasswordReset"></app-preloader-type-a>

              <div *ngIf="!isWorkingPasswordReset">
                <button (click)="requestPasswordReset()" class="btn btn-outline-success">
                  Send Password Link to {{theUser?.firstName}}
                </button>
              </div>
            </div>
          </div>
        </section>

      </ng-template>
    </ngb-tab>


    <!-------Owned Aircraft Info -->
    <ngb-tab title="Customer Aircraft {{userAircraftCount? ': ' + userAircraftCount : ''}}">
      <ng-template ngbTabContent>
        <section class="p-3">

        <!---display Aircraft -->
        <section class="p-3 admin-view-aircraft">
            <div class="card shadow border-0">
              <div class="card-header">
                Owned Aircraft: {{userAircraftCount}}
              </div>
              <div class="card-body">
                <div class="info_view">
                  <div>
                    <div class="text-center mt-2" *ngIf="theUser.id">
                      <a class="btn btn-outline-dark w-100 mb-3" [routerLink]="['/admin/add-aircraft', theUser.id]">
                        <i class="la la-plus"></i> Add Aircraft
                      </a>
                    </div>

                    <!---CUSTOMER AIRCRAFTS-->
                    <div class="row clearfix">
                      <div class="col-sm-12 text-center col-md-6 col-lg-4 mb-3" *ngFor="let aircraft of userAircraft">
                        <a [routerLink]="['/admin/edit-aircraft', aircraft.id]">
                          <div class="card border-info rounded mx-sm-1">
                            <div class="p-3">
                              <div class="text-info mt-2"><h6><i class="la la-pane"></i>  Tail #</h6></div>
                              <div class="mt-0"><h4>
                                <i class="la la-plane"></i>
                                {{aircraft.tailNo}}
                              </h4></div>
                              <hr class="my-1">
                              <div class="mt-1">
                                <small class="d-block text-info">Make / Model</small>
                                <div>
                                  <h5>
                                    {{aircraft.aircraft_model?.aircraftMake?.makeName}} / {{aircraft.aircraft_model?.modelName}}
                                  </h5>
                                </div>
                              </div>
                            </div>

                            <div class="mt-1 bg-secondary p-1">
                              <small class="d-block text-white">
                                View Aircraft
                              </small>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>

                  </div>




                </div>
              </div>
            </div>
          </section>

        </section>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</section>
