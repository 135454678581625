import { ApplianceModel } from './../models/appliance-model';
import { PropellerModel } from './../models/propeller-model';
import { EngineModel } from './../models/engine-model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AllModelsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  /*
  getAllAdSbManufacturer(){
    return this.httpClient.get(environment.apiBaseUrl + '/adsbmanufacturer/getAllAdSbManufacturer')
  }*/

  findModelsByType(query: string, type: string, page: number = 1, pageSize: number = 50){
    return this.httpClient.get(environment.apiBaseUrl + '/allmodels/findModelsByType?query=' + query + '&type=' + type + '&page=' + page + "&pageSize=" + pageSize)
  }

  /*
  createAdSbManufacturer(adSbManufacturer: AdSbManufacturerModel){
    return this.httpClient.post(environment.apiBaseUrl + '/adsbmanufacturer/createAdSbManufacturer', adSbManufacturer)
  }

  updateAdSbManufacturer(adSbManufacturer: AdSbManufacturerModel){
    return this.httpClient.put(environment.apiBaseUrl + '/adsbmanufacturer/updateAdSbManufacturer', adSbManufacturer)
  }

  deleteAdSbManufacturer(id: number){
    return this.httpClient.get(environment.apiBaseUrl + '/adsbmanufacturer/deleteAdSbManufacturer?id=' + id)
  }*/
}

