import { ActivatedRoute } from '@angular/router';
import { AircraftService } from './../../../../services/aircraft.service';
import { UserMenuAircraftModel } from './../../../../models/user-menu-aircraft-model';
import { NgbDateCustomAndUSAParserFormatter } from './../../../../services/ngb-date-custom-and-usa-parser-formatter';
import { UtilitiesService } from './../../../../services/utilities.service';
import { MustBeNumberValidator } from '../../../../_custom-validators/must-be-number-validator';
import { NgbDateNativeAdapter, NgbDateAdapter, NgbDatepickerConfig, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NavDataUpdateModel } from './../../../../models/nav-data-update-model';
import { FlightLogsService } from './../../../../services/flight-logs.service';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { Observable, SubscriptionLike } from 'rxjs';
import Swal from 'sweetalert2';
import { AircraftV2Model } from '../../../../models/airfcraftV2/aircraft-v2.model';
import { OrganizationService } from '../../../../services/organization.service';
import { UserOrganizationAndRole } from '../../../../models/user-organization-and-role';

@Component({
  selector: 'app-manage-nav-updates',
  templateUrl: './manage-nav-updates.component.html',
  styleUrls: ['./manage-nav-updates.component.scss'],
  providers: [
    NgbDatepickerConfig,
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomAndUSAParserFormatter}
  ]
})
export class ManageNavUpdatesComponent implements OnInit, OnChanges, OnDestroy {
  @Input () tabChanges: Observable<void>;
  @Input() inAdminView: boolean;
  @Input() inViewOnly: boolean;
  @Input() aircraftId: number;

  constructor(
    private aircraftService: AircraftService,
    private orgService: OrganizationService,
    private route: ActivatedRoute,
    private ngbDatePickerConfig: NgbDatepickerConfig,
    private formBuilder: FormBuilder,
    private flightLogsService: FlightLogsService,
    private utilitiesService: UtilitiesService,
  ) {
    ngbDatePickerConfig.minDate = {year: 1900, month: 1, day: 1};
  }

  selectedAircraft: AircraftV2Model = new AircraftV2Model;
  selectedAircraft$: SubscriptionLike;

  selectedOrg: UserOrganizationAndRole = new UserOrganizationAndRole;
  selectedOrg$: SubscriptionLike;

  tabChangesSubscription$: SubscriptionLike;

  inNewNavDataUpdateMode: boolean;

  newNavDataUpdate: NavDataUpdateModel;
  newNavDataUpdateForm: FormGroup;
  newNavDataUpdateLoading: boolean;

  editNavDataUpdate: NavDataUpdateModel;
  editNavDataUpdateForm: FormGroup;
  editNavDataUpdateLoading: boolean;

  allNavDataUpdates: Array<NavDataUpdateModel>;
  allNavDataUpdatesCount: number;

  lastCycleValue: number;

  isFetchingNavUpdates: boolean;

  ngOnInit() {
    this.isFetchingNavUpdates = false;

    this.tabChangesSubscription$ = this.tabChanges.subscribe(
      () => {
        this.inNewNavDataUpdateMode = false;
        this.unloadNavDataUpdateForEdit();
      }
    );

    this.selectedOrg$ = this.orgService.selectedOrg$.subscribe(
      (org: UserOrganizationAndRole) => {
        this.selectedOrg = org;
      }
    );

    this.selectedAircraft$ = this.aircraftService.selectedAircraft$.subscribe(
      (aircraft: AircraftV2Model) => {
        this.selectedAircraft = aircraft;

        if (!this.aircraftId) {
          this.aircraftId = this.selectedAircraft.id;
        }

        if (this.aircraftId !== null && this.aircraftId !== undefined) {
          this.initPageVariables();
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.aircraftId && changes.aircraftId.currentValue) {
      this.aircraftId = changes.aircraftId.currentValue;
      this.initPageVariables();
    }
  }

  initPageVariables() {

    this.inNewNavDataUpdateMode = true;

    this.newNavDataUpdate = new NavDataUpdateModel;
    this.newNavDataUpdateLoading = false;

    this.editNavDataUpdate = new NavDataUpdateModel;
    this.editNavDataUpdateLoading = false;

    this.allNavDataUpdates = [];
    this.allNavDataUpdatesCount = null;

    this.lastCycleValue = null;

    // hide New Nav Data for admin
    if (this.inViewOnly || !this.hasEditRole()) {
      this.inNewNavDataUpdateMode = false;
    }

    this.initNewNavDataUpdateForm();
    this.initEditNavDataUpdateForm();
    this.getAllAircraftNavDataUpdates();
  }

  getAllAircraftNavDataUpdates() {
    this.isFetchingNavUpdates = true;

    this.flightLogsService.getAllAircraftNavDataUpdates(this.aircraftId).subscribe(
      (response: any) => {
        this.isFetchingNavUpdates = false;
        if (+response.status.code === 1000) {
          this.allNavDataUpdates = response.responseData.rows;
          this.allNavDataUpdatesCount = response.responseData.count;

          if (this.allNavDataUpdates.length > 0) {
            this.lastCycleValue = +this.allNavDataUpdates[0].cycle;

            if (this.newNavDataUpdateForm) {
              this.newNavDataUpdateForm.get('cycle').setValue(this.lastCycleValue + 1);
            }
          } else {
            this.lastCycleValue = null;
          }
        }
      },
      (error: any) => {
        this.isFetchingNavUpdates = false;
      }
    );
  }

  initNewNavDataUpdateForm() {
    this.newNavDataUpdateForm = this.formBuilder.group({
      'equipmentName': new FormControl(null, [Validators.required]),
      'cycle': new FormControl((this.lastCycleValue + 1), [Validators.required, Validators.pattern('[0-9]+')]),
      'expireDate': new FormControl(null, [Validators.required]),
      'effectiveDate': new FormControl(null, [Validators.required]),
      'signInitials': new FormControl(null, [Validators.required]),
    });
  }

  initEditNavDataUpdateForm() {
    this.editNavDataUpdateForm = this.formBuilder.group({
      'equipmentName': new FormControl(null, [Validators.required]),
      'cycle': new FormControl(null, [Validators.required, Validators.pattern('[0-9]+')]),
      'expireDate': new FormControl(null, [Validators.required]),
      'effectiveDate': new FormControl(null, [Validators.required]),
      'signInitials': new FormControl(null, [Validators.required]),
    });
  }

  toggleInNewNavDataUpdate() {
    if (!this.inNewNavDataUpdateMode) {
      this.initNewNavDataUpdateForm();
      this.newNavDataUpdateForm.get('cycle').setValue(this.lastCycleValue + 1);
      this.inNewNavDataUpdateMode = true;
      return;
    } else {
      this.inNewNavDataUpdateMode = false;
    }
  }

  addNewNavDataUpdateForm() {
    if (!this.newNavDataUpdateForm.valid) {
      Swal.fire(
        'Nav Update Form contains invalid fields!',
        'Check all fields and ensure they have been correctly filled.',
        'error'
      );

      Object.values(this.newNavDataUpdateForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      // for(let i in this.newNavDataUpdateForm.controls) {
      //   this.newNavDataUpdateForm.controls[i].markAsTouched();
      // };

      return;
    }
    this.newNavDataUpdate.aircraftId = this.aircraftId;
    this.newNavDataUpdate.equipmentName = this.newNavDataUpdateForm.controls['equipmentName'].value;
    this.newNavDataUpdate.cycle = this.newNavDataUpdateForm.controls['cycle'].value;
    this.newNavDataUpdate.expireDate = this.newNavDataUpdateForm.controls['expireDate'].value;
    this.newNavDataUpdate.effectiveDate = this.newNavDataUpdateForm.controls['effectiveDate'].value;
    this.newNavDataUpdate.signInitials = this.newNavDataUpdateForm.controls['signInitials'].value;

    this.flightLogsService.addAircraftNavDataUpdate(this.newNavDataUpdate).subscribe(
      (response: any) => {
        if (+response.status.code === 1000) {
          this.inNewNavDataUpdateMode = false;
          this.newNavDataUpdateForm.reset();
          this.newNavDataUpdate = new NavDataUpdateModel;
          this.getAllAircraftNavDataUpdates();
          Swal.fire(
            'Great!', 'Nav data update added.', 'success'
          );
        } else {
          Swal.fire(
            'Yikes!', 'Nav data update not added.', 'error'
          );
        }
      },
      (error: any) => {
        if (error.error && error.error.status.message) {
          Swal.fire(
            error.error.status.message,
            error.error.responseData.join(' '),
            'error'
          );
        } else {
          Swal.fire(
            'Oops!',
            'Failed to add Nav data update.',
            'error'
          );
        }
      }
    );
  }

  loadNavDataUpdateForEdit(navDataUpdate: NavDataUpdateModel) {
    this.editNavDataUpdate = navDataUpdate;
    this.editNavDataUpdateForm.get('equipmentName').setValue(this.editNavDataUpdate.equipmentName);
    this.editNavDataUpdateForm.get('cycle').setValue(this.editNavDataUpdate.cycle);
    this.editNavDataUpdateForm.get('expireDate').setValue(
      this.editNavDataUpdate.expireDate ? this.utilitiesService.parseISOtoDateObj(this.editNavDataUpdate.expireDate) : null
    );
    this.editNavDataUpdateForm.get('effectiveDate').setValue(
      this.editNavDataUpdate.effectiveDate ? this.utilitiesService.parseISOtoDateObj(this.editNavDataUpdate.effectiveDate) : null
    );
    this.editNavDataUpdateForm.get('signInitials').setValue(this.editNavDataUpdate.signInitials);
  }

  unloadNavDataUpdateForEdit() {
    this.editNavDataUpdate = new NavDataUpdateModel;
    return;
  }

  doUpdateNavDataUpdate() {
    if (!this.editNavDataUpdateForm.valid) {
      Swal.fire('Edited Nav Update Form contains invalid fields!', 'Check all fields and ensure they have been correctly filled.', 'error');

      Object.values(this.editNavDataUpdateForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      // for(let i in this.editNavDataUpdateForm.controls) {
      //   this.editNavDataUpdateForm.controls[i].markAsTouched();
      // };
      return;
    }
    this.editNavDataUpdate.aircraftId = this.aircraftId;
    this.editNavDataUpdate.equipmentName = this.editNavDataUpdateForm.controls['equipmentName'].value;
    this.editNavDataUpdate.cycle = this.editNavDataUpdateForm.controls['cycle'].value;
    this.editNavDataUpdate.expireDate = this.editNavDataUpdateForm.controls['expireDate'].value;
    this.editNavDataUpdate.effectiveDate = this.editNavDataUpdateForm.controls['effectiveDate'].value;
    this.editNavDataUpdate.signInitials = this.editNavDataUpdateForm.controls['signInitials'].value;

    this.flightLogsService.updateAircraftNavDataUpdate(this.editNavDataUpdate).subscribe(
      (response: any) => {
        if (+response.status.code === 1000) {
          this.unloadNavDataUpdateForEdit();
          this.editNavDataUpdateForm.reset();
          this.editNavDataUpdate = new NavDataUpdateModel;
          this.getAllAircraftNavDataUpdates();
          Swal.fire(
            'Great!', 'Updated Nav Data Update.', 'success'
          );
        } else {
          Swal.fire(
            'Yikes!', 'Nav Data Update not updated.', 'error'
          );
        }
      },
      (error: any) => {
        if (error.status.message) {
          Swal.fire(
            error.status.message,
            error.responseData.join(' '),
            'error'
          );
        } else {
          Swal.fire(
            'Oops!',
            'Failed to update Nav data update.',
            'error'
          );
        }
      }
    );
  }


  hasEditRole() {
    if (this.inAdminView) {
      return false;
    }

    if (
      (
        // if Pilot
        this.selectedAircraft.userRole &&
        this.selectedAircraft.userRole.toString().toUpperCase() === 'INDIVIDUAL_RW'
      ) ||
      // if ORG ADMIN
      (
        this.selectedOrg.role &&
        this.selectedOrg.role.toString().toUpperCase() === 'ADMIN'
      )
    ) {
      return true;
    }
  }

  ngOnDestroy() {
    if (this.selectedAircraft$) {
      this.selectedAircraft$.unsubscribe();
    }

    if (this.tabChangesSubscription$) {
      this.tabChangesSubscription$.unsubscribe();
    }

    if (this.selectedOrg$) {
      this.selectedOrg$.unsubscribe();
    }
  }

}
