import { AircraftServiceBulletinModel } from './../../../../models/aircraft-service-bulletin-model';
import { AircraftAirDirectiveModel } from './../../../../models/aircraft-air-directive-model';
import { AircraftFlightLogModel } from './../../../../models/aircraft-flight-log-model';
import { UtilitiesService } from './../../../../services/utilities.service';
import { AirworthinessDirectiveService } from './../../../../services/airworthiness-directive.service';
import { FlightLogsService } from './../../../../services/flight-logs.service';
import { UserAircraftModel } from './../../../../models/user-aircraft-model';
import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild, AfterViewInit } from '@angular/core';
import { AircraftDashboardReferencesModel } from '../../../../models/aircraft-dashboard-references-model';
import * as moment from 'moment';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-view-all-aircraft-ad-docs',
  templateUrl: './view-all-aircraft-ad-docs.component.html',
  styleUrls: ['./view-all-aircraft-ad-docs.component.scss']
})
export class ViewAllAircraftAdDocsComponent implements OnInit, OnChanges {
  @Input() theAircraft: UserAircraftModel;

  constructor(
    private flightLogsService: FlightLogsService,
    private adService: AirworthinessDirectiveService,
    private utilitiesService: UtilitiesService,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) { }

/***
 * NOTE: INIT VARIABLES IN THE INITPAGE FUNCTION.
 */
  viewedPdfSrc: string;
  viewedPdfAdTitle: string;

  isLoading: boolean;

  lastFlightLogEntry: AircraftFlightLogModel = new AircraftFlightLogModel;

  serverEpochTime: number;

  todayFlightDate: string ;
  todayFlightDateMoment: moment.Moment;
  todayFlightHours: number;

  page: number;
  pageSize: number;

  totalAds: number;
  allAircraftADs: Array<AircraftAirDirectiveModel>;
  allAppliesAircraftADs: Array<AircraftAirDirectiveModel>;
  allCompliedWithADs: Array<AircraftAirDirectiveModel>;
  allDoesNotApplyADs: Array<AircraftAirDirectiveModel>;

  dashReferences: AircraftDashboardReferencesModel;

  filterSearchForm: FormGroup;

  filterResult: Array<AircraftAirDirectiveModel>;

  inFilterMode: boolean;
  isFilteringAds: boolean;


  ngOnInit() {
    this.initPage();
  }


  initPage() {
    this.filterSearchForm = this.fb.group({
      'adFilter': new FormControl('')
    });

    this.filterSearchForm.controls['adFilter'].valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(400)
    ).subscribe(
      (val) => {
        this.doFilterAdSb(val);
      }
    );

    this.viewedPdfSrc = null;
    this.viewedPdfAdTitle = null;

    this.isLoading = true;

    this.lastFlightLogEntry = new AircraftFlightLogModel;

    this.serverEpochTime = (new Date).getTime();

    // today's date: when if dueDate exceeds this, becomes overdue(RED)
    this.todayFlightDate = this.utilitiesService.dateToISOString(new Date(this.serverEpochTime));
    this.todayFlightDateMoment = moment(this.todayFlightDate);
    this.todayFlightHours = null;

    this.page = 1;
    this.pageSize = 400;
    this.totalAds = null;

    this.allAircraftADs = [];
    this.allAppliesAircraftADs = [];
    this.allCompliedWithADs = [];
    this.allDoesNotApplyADs = [];

    this.dashReferences = new AircraftDashboardReferencesModel;


    this.utilitiesService.getAircraftDashboardReferences().subscribe(
      (response: any) => {

        if (+response.status.code === 1000) {
          this.dashReferences = response.responseData;
          this.todayFlightDate = this.dashReferences.serverNow;
          this.todayFlightDateMoment = moment(this.todayFlightDate);
          this.serverEpochTime = this.utilitiesService.parseISOtoDateObj(this.dashReferences.serverNow).getTime();

          this.fetchAllAircraftFlightLogsTillFlightDate();
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.theAircraft.currentValue) {
      this.theAircraft = changes.theAircraft.currentValue;
      this.initPage();
    }
  }

  clearFilter() {
    this.filterResult = [];
    this.inFilterMode = false;
    this.filterSearchForm.controls['adFilter'].setValue('');
  }

  doFilterAdSb(query: string) {
    this.filterResult = [];

    if (query.trim().length < 1) {
      this.inFilterMode = false;
      this.isFilteringAds = false;
      return;
    }

    this.inFilterMode = true;
    this.isFilteringAds = true;

    this.filterResult = this.adService.filterAdSBs(
      query,
      [
        ... this.allAppliesAircraftADs,
        ... this.allCompliedWithADs,
        ... this.allDoesNotApplyADs
      ]
    );

    this.isFilteringAds = false;
  }

  fetchAllAircraftFlightLogsTillFlightDate() {
    this.flightLogsService.fetchAllAircraftFlightLogsTillFlightDate(this.theAircraft.id, '').subscribe(
      (response: any) => {
        if (+response.status.code === 1000) {
          const allLogs: Array<AircraftFlightLogModel>  = response.responseData.rows;

          this.lastFlightLogEntry = allLogs.length > 0 ? allLogs[0] : new AircraftFlightLogModel;

          const lastTimeInHours = this.lastFlightLogEntry.timeInHours ? this.lastFlightLogEntry.timeInHours : 0;
          const lastTimeOutHours = this.lastFlightLogEntry.timeOutHours ? this.lastFlightLogEntry.timeOutHours : 0;

          // check if to use last timeIn or TimeOut for todayFlightHours
          this.todayFlightHours = lastTimeInHours > 0 ? +lastTimeInHours : +lastTimeOutHours;

          this.fetchAllAircraftAirworthinessDirectiveByName();
        }
      }
    );

  }


  fetchAllAircraftAirworthinessDirectiveByName() {
    this.isLoading = true;

    this.adService.fetchAllAircraftAirworthinessDirectiveByName(this.theAircraft.id, '', this.page, this.pageSize).subscribe(
      (response: any) => {
        this.isLoading = false;
        if (+response.status.code === 1000) {
          this.allAircraftADs = response.responseData.rows;
          this.totalAds = response.responseData.count;

          this.prepareSortingFieldsOnADsAndSBs();
        }
      }
    );
  }


  paginationPageChange($event) {
    this.page = $event;
    this.fetchAllAircraftAirworthinessDirectiveByName();
  }

  prepareSortingFieldsOnADsAndSBs() {
    this.allAircraftADs.forEach(
      (el: AircraftAirDirectiveModel) => {

        el['searchField'] = el.airworthiness_directive.directiveName +
          el.airworthiness_directive.subject +
          el.airworthiness_directive.adSbType +
          el.application ;

        el = this.adService.prepareSortingFieldsOnADsAndSBs(
          el, this.todayFlightHours, this.todayFlightDate, this.dashReferences
        ) as AircraftAirDirectiveModel;

        return el;
      }
    );

    this.sortAppliesAircraftADs();
  }


  sortAppliesAircraftADs() {
    this.allAppliesAircraftADs = this.allAircraftADs.filter(
      (ad) => {
        if (ad.application == 'Applies' || (ad.application == 'Complied' && ad.isRecurring && !ad.terminateAction)) {
          ad['adGroup'] = 'applies';
          return true;
        } else {
          return false;
        }
      }
    );
    this.allAppliesAircraftADs = this.adService.sortAllAircraftADsAndSBs(this.allAppliesAircraftADs) as Array<AircraftAirDirectiveModel>;

    this.sortCompliedAircraftADs();
  }


  sortCompliedAircraftADs() {
     this.allCompliedWithADs = this.allAircraftADs.filter(
      (ad) => {
        if ((ad.application == 'Complied' && !ad.isRecurring) || (ad.application == 'Complied' && ad.isRecurring && ad.terminateAction)) {
          ad['adGroup'] = 'complied';
          return true;
        } else {
          return false;
        }
      }
    );

    this.allCompliedWithADs = this.adService.sortAllAircraftADsAndSBs(this.allCompliedWithADs) as Array<AircraftAirDirectiveModel>;

    this.sortNotApplyAircraftADs();
  }


  sortNotApplyAircraftADs() {
    this.allDoesNotApplyADs = this.allAircraftADs.filter(
      (ad) => {
        if (ad.application == 'NotApply') {
          ad['adGroup'] = 'notapply';
          return true;
        } else {
          return false;
        }
      }
    );

    this.allDoesNotApplyADs = this.adService.sortAllAircraftADsAndSBs(this.allDoesNotApplyADs) as Array<AircraftAirDirectiveModel>;

    this.allAircraftADs = [
      ... this.allAppliesAircraftADs,
      ... this.allCompliedWithADs,
      ... this.allDoesNotApplyADs
    ];
  }

  getRowTypeColor(ad: any) {
    if (ad.adGroup == 'applies') {
      return this.getRagColor(ad.ragStatus);
    }
  }

  getRagColor(val: number) {
    return this.adService.getRagColor(val);
  }

  formatMomentAsUsDateString(momentObject: moment.Moment) {
    return this.utilitiesService.formatMomentAsUsDateString(momentObject);
  }

  getMomentBetweenDates(lowertimeMoment: moment.Moment, higherTImeMoment: moment.Moment){
    return this.utilitiesService.getMomentBetweenDates(lowertimeMoment, higherTImeMoment);

  }

  getHoursRemainingBetweenHours(lowerHours: number, higherHours: number) {
    return this.utilitiesService.getHoursRemainingBetweenHours(lowerHours, higherHours);
  }

  getConditionDisplayString(condition: string){
    if (condition == 'WhichOccursFirst') {
      return 'Whichever occurs first';
    }

    if (condition == 'WhichHappensLast') {
      return 'Whichever happens last';
    }

    if (condition == 'NA') {
      return null;
    }
  }

  getISODateAsDate(isoDate: string) {
    const dateObj: Date = this.utilitiesService.parseISOtoDateObj(isoDate);
    return dateObj;
  }



  openModal(content, pdfUrl: string, title: string = 'View Directive') {
    this.viewedPdfSrc = pdfUrl;
    this.viewedPdfAdTitle = title;

    this.modalService.open(content, { size: 'lg' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
}
