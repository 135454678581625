import { AllMembersComponent } from './admin/members-list/all-members.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { AuthInterceptor } from './guards/token-interceptor.service';
import { UtilitiesService } from './services/utilities.service';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { PublicComponent } from './public/public.component';
import { AdminComponent } from './admin/admin.component';
import { CustomerComponent } from './customer/customer.component';
import { AdminConsoleComponent } from './admin/admin-console/admin-console.component';
import { CreateUserComponent } from './admin/create-user/create-user.component';
import { AdminSearchComponent } from './admin/admin-search/admin-search.component';
import { EditCustomerComponent } from './admin/edit-customer/edit-customer.component';
import { LoginComponent } from './public/login/login.component';
import { SignupComponent } from './public/signup/signup.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { TopMenuComponent } from './shared/menu/top-menu/top-menu.component';
import { SideMenuComponent } from './shared/menu/side-menu/side-menu.component';
import { NoAuthGuardService } from './guards/no-auth-guard.service';
import { EditAircraftComponent } from './admin/aircraft/edit-aircraft/edit-aircraft.component';
import { AddAircraftComponent } from './admin/aircraft/add-aircraft/add-aircraft.component';
import { EditAircraftDashboardComponent } from './admin/aircraft/edit-aircraft-dashboard/edit-aircraft-dashboard.component';
import { ManageDirectivesComponent } from './admin/airworthinessDirectives/manage-directives/manage-directives.component';
import { ManageBulletinsComponent } from './admin/serviceBulletins/manage-bulletins/manage-bulletins.component';
import {
  ManageDashboardDirectivesComponent
} from './admin/aircraft/edit-aircraft-dashboard/manage-dashboard-directives/manage-dashboard-directives.component';
import {
  ManageDashboardBulletinsComponent
} from './admin/aircraft/edit-aircraft-dashboard/manage-dashboard-bulletins/manage-dashboard-bulletins.component';
import { ManageMaintenanceComponent } from './admin/aircraft/edit-aircraft-dashboard/manage-maintenance/manage-maintenance.component';
import { AircraftFlightLogComponent } from './customer/flight-logs/aircraft-flight-log/aircraft-flight-log.component';
import { ManageFlightLogsComponent } from './customer/flight-logs/aircraft-flight-log/manage-flight-logs/manage-flight-logs.component';
import { ManageNavUpdatesComponent } from './customer/flight-logs/aircraft-flight-log/manage-nav-updates/manage-nav-updates.component';
import { ManageVorChecksComponent } from './customer/flight-logs/aircraft-flight-log/manage-vor-checks/manage-vor-checks.component';
import { AircraftDashboardViewComponent } from './customer/dashboard/aircraft-dashboard-view/aircraft-dashboard-view.component';
import {
  DashboardSafetyStatusComponent
} from './customer/dashboard/aircraft-dashboard-view/dashboard-safety-status/dashboard-safety-status.component';
import {
  DashboardMaintenanceStatusComponent
} from './customer/dashboard/aircraft-dashboard-view/dashboard-maintenance-status/dashboard-maintenance-status.component';
import { PreloaderTypeAComponent } from './shared/preloader-type-a/preloader-type-a.component';
import {
  DashboardIfrEquipmentStatusComponent
} from './customer/dashboard/aircraft-dashboard-view/dashboard-ifr-equipment-status/dashboard-ifr-equipment-status.component';
import { DashDatesComponent } from './customer/dash-dates/dash-dates.component';
import { PilotStatusComponent } from './customer/dashboard/aircraft-dashboard-view/pilot-status/pilot-status.component';
import {
  AdministrativeStatusComponent
} from './customer/dashboard/aircraft-dashboard-view/administrative-status/administrative-status.component';
import { SideMenuAutoCloseLinksDirective } from './directives/side-menu-auto-close-links.directive';
import { Page404Component } from './public/page404/page404.component';
import { AdminAircraftDashboardComponent } from './admin/aircraft/admin-aircraft-dashboard/admin-aircraft-dashboard.component';
import { BreadcrumbsNavComponent } from './shared/breadcrumbs-nav/breadcrumbs-nav.component';
import { AdminAircraftFlightlogComponent } from './admin/aircraft/admin-aircraft-flightlog/admin-aircraft-flightlog.component';
import {
  EditFlightLogComponent
} from './customer/flight-logs/aircraft-flight-log/manage-flight-logs/edit-flight-log/edit-flight-log.component';
import { ViewAllSafetyDocumentsComponent } from './customer/dashboard/view-all-safety-documents/view-all-safety-documents.component';
import {
  ViewAllAircraftAdDocsComponent
} from './customer/dashboard/view-all-safety-documents/view-all-aircraft-ad-docs/view-all-aircraft-ad-docs.component';
import {
  ViewAllAircraftSbDocsComponent
} from './customer/dashboard/view-all-safety-documents/view-all-aircraft-sb-docs/view-all-aircraft-sb-docs.component';
import { AdminViewAllAircraftDocsComponent } from './admin/aircraft/admin-view-all-aircraft-docs/admin-view-all-aircraft-docs.component';
import { QuickViewLogsComponent } from './customer/flight-logs/quick-view-logs/quick-view-logs.component';
import { PreloaderTypeBComponent } from './shared/preloader-type-b/preloader-type-b.component';
import {
  UserAircraftInfoHeaderWidgetComponent
} from './shared/user-aircraft-info-header-widget/user-aircraft-info-header-widget.component';
import {
  AircraftMaintenanceHistoryComponent
} from './customer/maintenance-history/aircraft-maintenance-history/aircraft-maintenance-history.component';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewerComponent } from './shared/pdf-viewer/pdf-viewer.component';
import { AuthActionsComponent } from './public/auth-actions/auth-actions.component';
import { DocumentConfigurationUiComponent } from './shared/document-configuration-ui/document-configuration-ui.component';
import { FormsModule } from '@angular/forms';
import { SortableDirective } from './sortable.directive';
import { DecimalPipe } from '@angular/common';
import { NgbdTablePaginationComponent } from './shared/ngbd-table-pagination/ngbd-table-pagination.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ConfiguredDocumentsPipe } from './configured-documents.pipe';
import { BulkBulletinUploadComponent } from './admin/bulk-bulletin-upload/bulk-bulletin-upload.component';
import {
  PeopleManagerComponent
} from './admin/organizations/organization-profile/people-manager/people-manager.component';
import { AircraftAssociatedMembersComponent } from './customer/aircraft/aicraft-associated-members/aircraft-associated-memberscomponent';
import {
  OrganizationHeaderWidgetComponent
} from './admin/organizations/organization-profile/organization-header-widget/organization-header-widget.component';
import {
  OrganizationPickerWidgetComponent
} from './shared/organization-picker-widget/organization-picker-widget.component';
import { AircraftPickerWidgetComponent } from './shared/aircraft-picker-widget/aircraft-picker-widget.component';
import { CustomerProfileComponent } from './customer/customer-profile/customer-profile.component';
import { AircraftProfileComponent } from './admin/aircraft/aircraft-profile/aircraft-profile.component';
import { AircraftBasicInfoComponent } from './admin/aircraft/aircraft-profile/aircraft-basic-info/aircraft-basic-info.component';
import { PilotDatesComponent } from './customer/members/member-profile/pilot-dates/pilot-dates.component';
import { ListOrganizationsComponent } from './admin/organizations/list-organizations/list-organizations.component';
import { NewOrganizationComponent } from './admin/organizations/new-organization/new-organization.component';
import { AircraftLogsCarouselComponent } from './customer/aircraft/aircraft-logs-carousel/aircraft-logs-carousel.component';
import {NgxUsefulSwiperModule} from 'ngx-useful-swiper';
import { UploadAircraftLogBookComponent } from './admin/aicraftLogBooks/upload-aircraft-log-book/upload-aircraft-log-book.component';
import { AddAircraftV2Component } from './admin/aircraft/add-aircraft-v2/add-aircraft-v2.component';
import { ListAircraftV2Component } from './admin/aircraft/list-aircraft-v2/list-aircraft-v2.component';
import { OrganizationProfileComponent } from './admin/organizations/organization-profile/organization-profile.component';
import { MembersTableComponent } from './admin/members-list/members-table/members-table.component';
import {
  OrganizationAircraftListComponent
} from './admin/organizations/organization-profile/organization-aircraft-list/organization-aircraft-list.component';
import { SelectedOrgProfileComponent } from './customer/organizations/selected-org-profile/selected-org-profile.component';
import { AircraftTableListComponent } from './admin/aircraft/list-aircraft-v2/aircraft-table-list/aircraft-table-list.component';
import {
  OrganizationAircraftTableComponent
} from './admin/organizations/organization-profile/organization-aircraft-list/organization-aircraft-table/organization-aircraft-table.component';
import {
  AircraftInfoHeaderWidgetV2Component
} from './shared/aircraft-info-header-widget-v2/aircraft-info-header-widget-v2.component';
import { AircraftOrgListTableComponent } from './admin/aircraft/aircraft-profile/aircraft-org-list-table/aircraft-org-list-table.component';
import { NewMemberComponent } from './customer/members/new-member/new-member.component';
import { ViewEditMemberProfileComponent } from './customer/members/member-profile/view-edit-member-profile/view-edit-member-profile.component';
import { UserEditOwnProfileComponent } from './customer/members/member-profile/user-edit-own-profile/user-edit-own-profile.component';
import { AdminEditMemberProfileComponent } from './admin/admin-edit-member-profile/admin-edit-member-profile.component';
import { DropZoneDirective } from './directives/drop-zone.directive';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from './../environments/environment';
import { UploaderTaskComponent } from './admin/aircraftLogBooks/fileUploader/uploader-task/uploader-task.component';

@NgModule({
  declarations: [
    AppComponent,
    PublicComponent,
    AdminComponent,
    CustomerComponent,
    AdminConsoleComponent,
    CreateUserComponent,
    AdminSearchComponent,
    EditCustomerComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    AuthActionsComponent,
    TopMenuComponent,
    SideMenuComponent,
    EditAircraftComponent,
    AddAircraftComponent,
    EditAircraftDashboardComponent,
    ManageDirectivesComponent,
    ManageBulletinsComponent,
    ManageDashboardDirectivesComponent,
    ManageDashboardBulletinsComponent,
    ManageMaintenanceComponent,
    AircraftFlightLogComponent,
    ManageFlightLogsComponent,
    ManageNavUpdatesComponent,
    ManageVorChecksComponent,
    AircraftDashboardViewComponent,
    DashboardSafetyStatusComponent,
    DashboardMaintenanceStatusComponent,
    PreloaderTypeAComponent,
    DashboardIfrEquipmentStatusComponent,
    DashDatesComponent,
    PilotStatusComponent,
    AdministrativeStatusComponent,
    SideMenuAutoCloseLinksDirective,
    Page404Component,
    AdminAircraftDashboardComponent,
    BreadcrumbsNavComponent,
    AdminAircraftFlightlogComponent,
    EditFlightLogComponent,
    ViewAllSafetyDocumentsComponent,
    ViewAllAircraftAdDocsComponent,
    ViewAllAircraftSbDocsComponent,
    AdminViewAllAircraftDocsComponent,
    QuickViewLogsComponent,
    PreloaderTypeBComponent,
    UserAircraftInfoHeaderWidgetComponent,
    AircraftInfoHeaderWidgetV2Component,
    AircraftMaintenanceHistoryComponent,
    PdfViewerComponent,
    DocumentConfigurationUiComponent,
    SortableDirective,
    NgbdTablePaginationComponent,
    ConfiguredDocumentsPipe,
    BulkBulletinUploadComponent,
    PeopleManagerComponent,
    AircraftAssociatedMembersComponent,
    OrganizationHeaderWidgetComponent,
    OrganizationPickerWidgetComponent,
    AircraftPickerWidgetComponent,
    CustomerProfileComponent,
    AircraftProfileComponent,
    AircraftBasicInfoComponent,
    PilotDatesComponent,
    ListOrganizationsComponent,
    NewOrganizationComponent,
    AircraftLogsCarouselComponent,
    UploadAircraftLogBookComponent,
    AddAircraftV2Component,
    ListAircraftV2Component,
    AllMembersComponent,
    OrganizationProfileComponent,
    MembersTableComponent,
    OrganizationAircraftListComponent,
    SelectedOrgProfileComponent,
    AircraftTableListComponent,
    OrganizationAircraftTableComponent,
    AircraftOrgListTableComponent,
    NewMemberComponent,
    ViewEditMemberProfileComponent,
    UserEditOwnProfileComponent,
    AdminEditMemberProfileComponent,
    DropZoneDirective,
    UploaderTaskComponent
  ],
  entryComponents: [
    DocumentConfigurationUiComponent,
    NewOrganizationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    HttpClientModule,
    PdfViewerModule,
    FormsModule,
    FontAwesomeModule,
    NgxExtendedPdfViewerModule,
    NgxUsefulSwiperModule
  ],
  providers: [
    Title,
    UtilitiesService,
    DecimalPipe,
    AuthGuardService,
    NoAuthGuardService,
    AdminComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // add font awesome SVG icon library to app
    library.add(fas, far);
  }
}
