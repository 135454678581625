<!---Manage Flight Logs -->
<section class="p-5 bg-white">

    <h4 class="form_header uppercase clearfix">

      {{ inNewFlightLogMode? 'New Flight Log'  : 'All Flight Logs' }}

      <span class="float-right" *ngIf="hasEditRole()">
        <button class="btn btn-sm btn-outline-dark " (click)="toggleInNewFlightLogMode()">
          <i class="la la-plus" *ngIf="!inNewFlightLogMode"></i>
          {{ inNewFlightLogMode? 'View Logs' : 'New Flight Log' }}
        </button>
      </span>
    </h4>


    <app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

    <div *ngIf="!isWorking">
      <!--NEW FLIGHT LOG-->
      <div *ngIf="inNewFlightLogMode">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-9">
            <form [formGroup]="newFlightLogForm" (ngSubmit)="addNewFlightLog()">
              <div>
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                      <small class="form-text text-muted required">Log Date</small>
                      <div class="input-group">
                        <input class="form-control no-right-border" formControlName="newFlightDate"  placeholder="mm/dd/yyyy" name="newFlightDate" [maxDate]="serverTodayDateNgbDateStruct" ngbDatepicker #newFlightDate="ngbDatepicker">
                        <div class="input-group-append">
                          <button class="btn calendar" (click)="newFlightDate.toggle()" type="button">
                            <i class="la la-calendar-plus-o"></i>
                          </button>
                        </div>
                      </div>
                      <small class="text-danger d-block" *ngIf="newFlightLogForm.get('newFlightDate').invalid && (newFlightLogForm.get('newFlightDate').touched || newFlightLogForm.get('newFlightDate').dirty)">Enter Log Date(today as default)</small>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <div class="row">
                      <!--time out -->
                      <div class="col-md-6 pl-1">
                        <div class="form-group">
                          <small class="form-text text-muted required">Time Out (hrs)</small>
                          <div class="input-group">
                            <label>
                              <input class="form-control" formControlName="timeOutHours"  placeholder="Time Out Hours" name="timeOutHours">
                            </label>
                          </div>
                          <small class="text-danger d-block" *ngIf="newFlightLogForm.get('timeOutHours').invalid && (newFlightLogForm.get('timeOutHours').touched || newFlightLogForm.get('timeOutHours').dirty)">Enter valid Time Out Hours</small>
                          <ng-container *ngIf="!newFlightLogForm.hasError('required', 'timeOutHours') && (newFlightLogForm.get('timeOutHours').touched || newFlightLogForm.get('timeOutHours').dirty)">
                            <small class="text-danger" *ngIf="newFlightLogForm.hasError('numberToTenths', 'timeOutHours')">Enter Time In to the tenths <b>e.g 22.5, 10</b></small>
                          </ng-container>
                        </div>
                      </div>

                      <!--time in -->
                      <div class="col-md-6 pr-1">
                        <div class="form-group">
                          <small class="form-text text-muted required">Time In (hrs)</small>
                          <div class="input-group">
                            <input class="form-control" formControlName="timeInHours"  placeholder="Time In Hours" name="timeInHours">
                          </div>
                          <small class="text-danger d-block" *ngIf="newFlightLogForm.hasError('required', 'timeInHours') && (newFlightLogForm.get('timeInHours').touched || newFlightLogForm.get('timeInHours').dirty)">Enter valid Time In Hours</small>
                          <ng-container *ngIf="!newFlightLogForm.hasError('required', 'timeInHours') && (newFlightLogForm.get('timeInHours').touched || newFlightLogForm.get('timeInHours').dirty)">
                            <small class="text-danger" *ngIf="newFlightLogForm.hasError('numberToTenths', 'timeInHours')">Enter Time In to the tenths <b>e.g 22.5, 10</b></small>
                            <small class="text-danger" *ngIf="newFlightLogForm.hasError('timeInGtTimeOut', 'timeInHours')">Time In must be greater than Time Out</small>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>

                  <small class="d-block">Please enter Pre-flight orPost-flight log information.</small>

                  <!---Fuel Log -->
                  <div class="col-12 py-0 mt-3 mb-0">
                    <h4 class="form_header uppercase icon_header">
                      <i class="flaticon-fuel mb-0"></i>
                      FUEL LOG
                    </h4>
                  </div>

                  <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Pre Flight Fuel (Gallons)</small>
                      <div class="input-group">
                        <input class="form-control" type="text" formControlName="preflight_fuel_gallons"  placeholder="Gallons: Pre Flight Fuel" name="preflight_fuel_gallons">
                      </div>
                      <small class="text-danger" *ngIf="newFlightLogForm.get('preflight_fuel_gallons').invalid && (newFlightLogForm.get('preflight_fuel_gallons').touched || newFlightLogForm.get('preflight_fuel_gallons').dirty)">Enter valid Gallons value <i>e.g. 22.3</i></small>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Post Flight Fuel (Gallons)</small>
                      <div class="input-group">
                        <input class="form-control" type="text" formControlName="postflight_fuel_gallons"  placeholder="Gallons: Post Flight Fuel" name="postflight_fuel_gallons">
                      </div>
                      <small class="text-danger" *ngIf="newFlightLogForm.get('postflight_fuel_gallons').invalid && (newFlightLogForm.get('postflight_fuel_gallons').touched || newFlightLogForm.get('postflight_fuel_gallons').dirty)">Enter valid Gallons value <i>e.g. 22.3</i></small>
                    </div>
                  </div>


                  <!---Oil Log -->
                  <div class="col-sm-12 col-lg-12 col-md-12 py-0 mt-3 mb-0">
                    <h4 class="form_header uppercase icon_header">
                      <i class="flaticon-oil mb-0"></i>
                      OIL LOG
                    </h4>
                  </div>

                  <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Pre Flight Oil (Quarts)</small>
                      <div class="input-group">
                        <input class="form-control" type="text" formControlName="preflight_oil_quarts"  placeholder="Quarts: Pre Flight Oil" name="preflight_oil_quarts">
                      </div>
                      <small class="text-danger" *ngIf="newFlightLogForm.get('preflight_oil_quarts').invalid && (newFlightLogForm.get('preflight_oil_quarts').touched || newFlightLogForm.get('preflight_oil_quarts').dirty)">Enter valid Quarts value <i>e.g. 22.3</i></small>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-6 col-md-6">
                    <div class="form-group">
                      <small class="form-text text-muted">Post Flight Oil (Quarts)</small>
                      <div class="input-group">
                        <input class="form-control" type="text" formControlName="postflight_oil_quarts"  placeholder="Quarts: Post Flight Oil" name="postflight_oil_quarts">
                      </div>
                      <small class="text-danger" *ngIf="newFlightLogForm.get('postflight_oil_quarts').invalid && (newFlightLogForm.get('postflight_oil_quarts').touched || newFlightLogForm.get('postflight_oil_quarts').dirty)">Enter valid Quarts value <i>e.g. 22.3</i></small>
                    </div>
                  </div>


                  <ng-container formArrayName="squawks">
                    <!---Squawks Log -->
                    <div class="col-sm-12 col-lg-12 col-md-12 py-0 mt-3 mb-0">
                      <h4 class="form_header uppercase icon_header">
                        <i class="la la-warning"></i>
                        SQUAWKS
                      </h4>
                    </div>

                    <div class="col-sm-12 col-lg-12 col-md-12" *ngFor="let squawkItem of squawksListFormGroups.controls; let i = index;">

                      <div class="line_text_header">
                        <div class="header_text">
                          <span>Squawk Entry {{i+1}}</span>
                        </div>
                      </div>

                      <div [formGroupName]="i" class="form-group w-100">
                        <!-- Squawks Form controls Here -->
                        <div>
                          <small class="form-text clearfix">
                            <span class="float-left required">Squawk Description</span>

                            <button class="btn btn-sm btn-outline-dark float-right" *ngIf="i !== 0" (click)="removeNewSquawkLogGroup(i)">
                              <i class="la la-trash"></i>
                            </button>
                          </small>
                          <div class="input-group">
                            <textarea class="form-control w-100" formControlName="squawkEntry" placeholder="Squawk Description" name="squawkEntry" id="squawkEntry" rows="3"></textarea>
                          </div>
                          <small class="d-block text-danger" *ngIf="getOneSquawkFormGroup(i).controls['squawkEntry'].invalid">
                            <span *ngIf="getOneSquawkFormGroup(i).controls['squawk_type'].value">
                              Please type in squawk description
                            </span>

                            <span *ngIf="!getOneSquawkFormGroup(i).controls['squawk_type'].value">
                              Please select type of squawk below
                            </span>
                          </small>
                        </div>

                        <div class="mb-3 mt-1">
                          <div class="d-flex">
                            <small class="mr-2 required">Type: </small>
                            <div class="btn-group btn-group-toggle w-100" ngbRadioGroup name="squawk_type" formControlName="squawk_type">
                              <label ngbButtonLabel class="btn-outline-dark btn-sm" *ngFor="let type of squawkTypesList">
                                <input ngbButton type="radio" value="{{type.id}}"> {{type.squawkName}}
                              </label>
                              <button ngbButtonLabel class="btn-outline-dark btn-sm" [disabled]="true">
                                Appliance
                              </button>
                              <a ngbButtonLabel class="btn-outline-dark btn-sm" (click)="getOneSquawkFormGroup(i).controls['squawk_type'].setValue(null)">
                                <i class="la la-rotate-left"></i>
                              </a>
                            </div>
                          </div>
                          <small class="d-block text-danger" *ngIf="getOneSquawkFormGroup(i).controls['squawk_type'].invalid">
                            <span *ngIf="getOneSquawkFormGroup(i).controls['squawkEntry'].value">
                              Please select the squawk type
                            </span>

                            <span *ngIf="!getOneSquawkFormGroup(i).controls['squawkEntry'].value">
                              Please enter description above for this selected squawk type
                            </span>
                          </small>

                        </div>
                      </div>

                    </div>


                    <div class="col-12 my-3">
                      <div class="form-group">
                        <a href="javascript: void(0)" class="btn btn-paleblue w-100" (click)="addNewSquawkLogGroup()">
                          <i class="la la-plus"></i>
                          Add Another Squawk
                        </a>
                      </div>
                    </div>
                  </ng-container >

                  <div class="col-12 col-sm-12 col-lg-12 col-md-12">
                    <div class="form-group">
                      <small class="form-text text-muted">Note:</small>
                      <div class="input-group">
                        <textarea class="form-control w-100" formControlName="notes"  placeholder="Enter extra note here" name="notes" id="notes" rows="4"></textarea>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="text-right mt-2">
                <button class="btn btn-paleblue mr-3 " (click)="inNewFlightLogMode = false">Cancel</button>
                <button class="btn btn-orange ">Add Flight Log</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <section *ngIf="!inNewFlightLogMode">
        <form [formGroup]="flightLogSearchForm" >
          <div class="form-group form-inline">
            Flight logs till:
            <div class="input-group">
              <input class="form-control" formControlName="queryflightDate"  placeholder="mm/dd/yyyy" name="queryflightDate" [maxDate]="serverTodayDateNgbDateStruct" ngbDatepicker #queryflightDate="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="queryflightDate.toggle()" type="button">
                  <i class="la la-calendar-plus-o"></i>
                </button>
              </div>
            </div>
            <span class="ml-3" *ngIf="flightLogSearchLoading">Searching...</span>
          </div>
        </form>


        <!--EXISTING FLIGHT LOGS-->
        <div>
          <div class="card shadow mb-3" *ngFor="let flightLog of allAircraftFlightLogs; index as i">
            <div class="card-body">
              <h5 class="clearfix">
                {{ ((page-1) * pageSize) + i + 1 }}: <i class="la la-calendar"></i>
                {{flightLog.flightDate | date:'MM/dd/yyyy'}}

                <span class="float-right" *ngIf="hasEditRole()">
                  <button class="btn btn-sm {{loadedEditFlightLog.id == flightLog.id? 'btn-outline-danger': 'btn-outline-dark'}}  mr-3" (click)="toggleFlightLogForEdit(flightLog)">
                    <span *ngIf="loadedEditFlightLog.id == flightLog.id">
                      <i class="la la-times"></i> Cancel
                    </span>
                    <span *ngIf="!(loadedEditFlightLog.id == flightLog.id)">
                      <i class="la la-edit"></i> Edit
                    </span>
                  </button>

                  <button class="btn btn-sm btn-danger " (click)="initDeleteFlightLog(flightLog)">
                    <i class="la la-trash"></i> Delete
                  </button>
                </span>
              </h5>
              <section>
                <div class="row align-items-center border-bottom">
                  <!--FLIGHT TIME-->
                  <div class="col-12 col-md-12 col-lg-6">
                    <div class="row align-items-center border-bottom">
                      <div class="col-3 text-center py-2">
                        <i class="flaticon-time mb-0 h3 d-block"></i>
                        <small><span class="d-none d-xl-inline">Flight Time </span>Hrs</small>
                      </div>
                      <div class="col-3 text-center">
                        <div class="h5 mb-0">
                          <span class="d-block d-md-inline w-sm-100">
                            <i class="la la-plane rotate45"></i>
                          </span>
                          {{flightLog.timeOutHours }}
                        </div>
                        <small class="d-block">PRE<span class="d-none d-xl-inline">-FLIGHT</span></small>
                      </div>
                      <div class="col-3 text-center">
                        <div class="h5 mb-0">
                          <span class="d-block d-md-inline w-sm-100">
                            <i class="la la-plane rotate135"></i>
                          </span>
                          {{flightLog.timeInHours }}
                        </div>
                        <small class="d-block">POST<span class="d-none d-xl-inline">-FLIGHT</span></small>
                      </div>
                      <div class="col-3 text-center">
                        <div class="h5 mb-0">
                          <span class="d-block d-md-inline w-sm-100">
                            <i class="la la-hourglass-1"></i>
                          </span>
                          {{ roundNumberToTenths(flightLog.timeInHours - flightLog.timeOutHours) }}
                        </div>
                        <small class="d-block">DUR<span class="d-none d-xl-inline">ATION</span></small>
                      </div>
                    </div>
                  </div>

                  <!--FUEL LOG-->
                  <div class="col-6 col-md-6 col-lg-3">
                    <div class="row align-items-center border-bottom">

                      <div class="col-1 border-left d-none p-0 py-3 d-lg-inline">
                        &nbsp;
                      </div>

                      <div class="col-5 text-center py-2 pr-0">
                        <i class="flaticon-fuel mb-0 h3 d-block"></i>
                        <small><span class="d-none d-xl-inline">Fuel </span>Gallons</small>
                      </div>

                      <div class="col-1 text-left p-0">
                        <span class="h3">:</span>
                      </div>

                      <div class="col-5 text-left pl-0">
                        <!--if flight fuel log available-->
                        <div *ngIf="flightLog.flight_fuel_log">
                          <!--if post flight fuel log available-->
                          <div *ngIf="flightLog.flight_fuel_log.postflightGallons">
                            <div class="h5 mb-0">
                              <span class="d-block d-md-inline w-sm-100">
                                <i class="la la-plane rotate135"></i>
                              </span>
                              {{flightLog.flight_fuel_log?.postflightGallons}}
                            </div>
                            <small class="d-block">POST<span class="d-none d-xl-inline">-FLIGHT</span></small>
                          </div>

                          <!--if no post flight fuel log available, show pre flight fuel log-->
                          <div *ngIf="!flightLog.flight_fuel_log.postflightGallons">
                            <div class="h5 mb-0">
                              <span class="d-block d-md-inline w-sm-100">
                                <i class="la la-plane rotate45"></i>
                              </span>
                              {{flightLog.flight_fuel_log?.preflightGallons}}
                            </div>
                            <small class="d-block">PRE<span class="d-none d-xl-inline">-FLIGHT</span></small>
                          </div>
                        </div>

                        <!--if no flight fuel log available-->
                        <div *ngIf="!flightLog.flight_fuel_log">
                          <div class="h5 mb-0">
                            <span class="d-block d-md-inline w-sm-100">
                              <i class="la la-plane rotate45"></i>
                            </span>
                            -- --
                          </div>
                          <small class="d-block">NA</small>
                        </div>

                      </div>
                    </div>
                  </div>


                  <!--OIL LOG-->
                  <div class="col-6 col-md-6 col-lg-3 border-md-left border-sm-left">
                    <div class="row align-items-center border-bottom">

                      <div class="col-1 border-left d-none p-0 py-3 d-lg-inline">
                        &nbsp;
                      </div>

                      <div class="col-5 text-center py-2 pr-0">
                        <i class="flaticon-oil mb-0 h3 d-block"></i>
                        <small><span class="d-none d-xl-inline">Oil </span>Qts</small>
                      </div>

                      <div class="col-1 text-left p-0">
                        <span class="h3">:</span>
                      </div>

                      <div class="col-5 text-left pl-0">
                        <!--if flight oil log available-->
                        <div *ngIf="flightLog.flight_oil_log">
                          <!--if post flight oil log available-->
                          <div *ngIf="flightLog.flight_oil_log.postflightOilQuarts">
                            <div class="h5 mb-0">
                              <span class="d-block d-md-inline w-sm-100">
                                <i class="la la-plane rotate135"></i>
                              </span>
                              {{flightLog.flight_oil_log?.postflightOilQuarts}}
                            </div>
                            <small class="d-block">POST<span class="d-none d-xl-inline">-FLIGHT</span></small>
                          </div>

                          <!--if no post flight oil log available, show pre flight oil log-->
                          <div *ngIf="!flightLog.flight_oil_log.postflightOilQuarts">
                            <div class="h5 mb-0">
                              <span class="d-block d-md-inline w-sm-100">
                                <i class="la la-plane rotate45"></i>
                              </span>
                              {{flightLog.flight_oil_log?.preflightOilQuarts}}
                            </div>
                            <small class="d-block">PRE<span class="d-none d-xl-inline">-FLIGHT</span></small>
                          </div>
                        </div>

                        <!--if no flight oil log available-->
                        <div *ngIf="!flightLog.flight_oil_log">
                          <div class="h5 mb-0">
                            <span class="d-block d-md-inline w-sm-100">
                              <i class="la la-plane rotate45"></i>
                            </span>
                            -- --
                          </div>
                          <small class="d-block">NA</small>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <!--SQUAWK LOG-->
                <div class="px-3 py-2">
                  <button disabled class="btn btn-dark  btn-block clearfix" *ngIf="flightLog.flight_squawk_logs.length < 1">
                    <i class="la la-thumbs-o-up mr-2"></i>
                    No Squawk!
                  </button>

                  <button class="btn btn-dark  btn-block clearfix"  (click)="toggleShowFlightLogSquawk(flightLog)" *ngIf="flightLog.flight_squawk_logs.length > 0">
                    View Squawks ({{flightLog.flight_squawk_logs.length}})
                    <span class="float-right" >
                      <i class="la la-chevron-up" *ngIf="flightLog.id == viewedFlightlogSquawk.id"></i>
                      <i class="la la-chevron-down" *ngIf="!(flightLog.id == viewedFlightlogSquawk.id)"></i>
                    </span>
                  </button>
                  <div class="border p-3 bg-light" *ngIf="flightLog.id == viewedFlightlogSquawk.id">
                    <p class="squawk_wrap" *ngFor="let squawk of flightLog.flight_squawk_logs; let i = index">
                      <b>{{i+1}}: {{squawk.squawk_type?.squawkName}} </b> <br />
                      {{squawk.squawkEntry}}
                      <br />
                    </p>
                  </div>
                </div>


                <!--Log note-->
                <div class="col-12" *ngIf="flightLog.notes">
                  <small class="d-block p-2 text-dark bg-light border-gray border  m-2 allow_wrap">
                    <b>NOTE:</b> {{flightLog.notes}}
                  </small>
                </div>

                <!---EDIT-->
                <div *ngIf="loadedEditFlightLog.id == flightLog.id">
                  <app-edit-flight-log [flightLog]="flightLog" (cancelEdit)="toggleFlightLogForEdit($event)" (savedSuccessFlightLog)="savedEditedFlightLog($event)"></app-edit-flight-log>
                </div>
              </section>
            </div>
          </div>

          <div class="d-flex justify-content-between p-2">
            <ngb-pagination
              [collectionSize]="allAircraftFlightLogsCount" [(page)]="page"
                  [pageSize]="pageSize" (pageChange)="onPaginationPageChange($event)">
            </ngb-pagination>
          </div>
        </div>
      </section>
    </div>

</section>
