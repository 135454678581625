export class UserBaseModel {
  constructor() {
    this.id = null;
    this.isAdmin = false;
  }
  id: number;
  isAdmin?: boolean;
  firstName: string;
  lastName: string;
  emailAddress: string;
  password?: string;
  mobilePhone?: string;
  pilotLicenseNo?: string;
  createdAt?: string;
  updatedAt?: string;
  medicalDueDate: string;
  lastMedicalDate: string;
  lastFlightReviewDate: string;
  flightReviewDueDate: string;
}
