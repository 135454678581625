import { BreadcrumbNavModel } from '../../../models/breadcrumb-nav-model';
import { UserAircraftModel } from './../../../models/user-aircraft-model';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-aircraft-flightlog',
  templateUrl: './admin-aircraft-flightlog.component.html',
  styleUrls: ['./admin-aircraft-flightlog.component.scss']
})
export class AdminAircraftFlightlogComponent implements OnInit {
  @Input() aircraftId: number;

  constructor(
    private route: ActivatedRoute,
  ) { }

  theAircraft: UserAircraftModel = new UserAircraftModel;
  navItems: Array<BreadcrumbNavModel> = [
    {navLabel: 'Admin Console', navRouterLink: ['/cga/admin/console']},
    {navLabel: 'Aircraft', navRouterLink: ['/cga/admin/edit-aircraft', this.aircraftId]},
    {navLabel: 'Aircraft Dashboard', navRouterLink: null}
  ]


  ngOnInit() {
    let routeAircraftId = +this.route.snapshot.paramMap.get('aircraftId');

    this.aircraftId = this.aircraftId? this.aircraftId : routeAircraftId;
  }
}
