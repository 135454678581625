
<section class="admin-maintenance">
  <div>
    <div class="form_header clearfix" *ngIf="!isCustomerView">
      <h5 class="uppercase form_header float-left">
        Manage Aircraft Maintenance History
      </h5>
      &nbsp;
      <span class="float-right">
        <ng-container  *ngIf="!inUpdateMode">
          <button class="btn btn-sm btn-outline-primary rounded" (click)="initPostAllMaintenanceStats()">
            <i class="la la-edit"></i>
          </button>
          Update Dates
        </ng-container>

      <ng-container *ngIf="inUpdateMode">
        <button class="btn btn-sm btn-outline-danger rounded" (click)="cancelPostAllMaintenanceStats()">
          <i class="la la-times"></i> Cancel
        </button>
      </ng-container>
      </span>
    </div>

    <div>

      <h5 class="form_header uppercase" *ngIf="isCustomerView">
        Aircraft Maintenance History
      </h5>

      <div class="card my-3 shadow rounded-0 {{inUpdateMode? 'border-danger': ''}}">
        <div class="card-body">

          <div class="card-body">

            <form [formGroup]="updateAllMaintenanceStatsForm" (ngSubmit)="postAllMaintenanceStats()">
              <div>
                <div class="row">

                  <div class="col-sm-12 col-lg-6 col-md-12">
                    <div class="form-group">
                      <h6 class="form-text text-muted">
                        <i class="la la-calendar-check-o"></i>
                        Last Annual Date{{mAnnualsWorking? '...searching...' : ''}}
                        <span class="required" *ngIf="inUpdateMode"></span>
                      </h6>
                      <app-preloader-type-b *ngIf="mAnnualsWorking"></app-preloader-type-b >
                      <div *ngIf="!mAnnualsWorking" class="w-50">
                        <ng-container *ngIf="inUpdateMode">
                          <div class="input-group">
                            <input class="form-control" formControlName="annuals"  placeholder="mm/dd/yyyy" name="annuals" ngbDatepicker #annualsDate="ngbDatepicker">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="annualsDate.toggle()" type="button">
                                <i class="la la-calendar-plus-o"></i>
                              </button>
                            </div>
                          </div>
                          <small class="form-text text-danger d-block"
                          *ngIf="updateAllMaintenanceStatsForm.get('annuals').invalid && (updateAllMaintenanceStatsForm.get('annuals').touched || updateAllMaintenanceStatsForm.get('annuals').dirty)">
                            Enter a valid date for Last Annual Date
                          </small>
                        </ng-container>

                        <ng-container *ngIf="!inUpdateMode">
                          <div class="input-group">
                            <h5>{{ mAnnuals.lastAnnualDate ? (mAnnuals.lastAnnualDate | date:'MM/dd/yyyy') : "--"  }}</h5>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-6 col-md-12">
                    <div class="form-group">
                      <h6 class="form-text text-muted">
                        <i class="la la-hourglass-end"></i>
                        Last Annual Hours{{mAnnualHoursWorking? '...searching...' : ''}}
                        <span class="required" *ngIf="inUpdateMode"></span>
                      </h6>
                      <app-preloader-type-b *ngIf="mAnnualHoursWorking"></app-preloader-type-b >
                      <div *ngIf="!mAnnualHoursWorking" class="w-50">
                        <ng-container *ngIf="inUpdateMode">
                          <div class="input-group">
                            <input class="form-control" formControlName="annualHours"  placeholder="Airframe TT e.g 120.32" name="annualHours">
                          </div>
                          <small class="form-text text-danger d-block"
                          *ngIf="updateAllMaintenanceStatsForm.get('annualHours').invalid && (updateAllMaintenanceStatsForm.get('annualHours').touched || updateAllMaintenanceStatsForm.get('annualHours').dirty)">
                            Enter a valid value for Last Annual Hours
                          </small>
                        </ng-container>

                        <ng-container *ngIf="!inUpdateMode">
                          <div class="input-group">
                            <h5>{{ mAnnualsHours ? (mAnnualsHours.lastAnnualHours ) : "--"  }}</h5>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-6 col-md-12">
                    <div class="form-group">
                      <h6 class="form-text text-muted">
                        <i class="la la-hourglass-end"></i>
                        Last 100 Hour  {{mLast100HoursWorking? '...searching...' : ''}}
                      </h6>
                      <app-preloader-type-b *ngIf="mLast100HoursWorking"></app-preloader-type-b >

                      <div *ngIf="!mLast100HoursWorking">
                        <ng-container *ngIf="inUpdateMode">
                          <div class="input-group">
                            <input type="text" formControlName="last100Hours" class="form-control" id="last100Hours" placeholder="Hours">
                          </div>
                          <!--<small class="form-text text-danger d-block"
                          *ngIf="updateAllMaintenanceStatsForm.get('last100Hours').invalid && (updateAllMaintenanceStatsForm.get('last100Hours').touched || updateAllMaintenanceStatsForm.get('last100Hours').dirty)">
                            Enter a valid Last 100 Hour (e.g. 300.5)
                          </small>-->
                        </ng-container>

                        <ng-container *ngIf="!inUpdateMode">
                          <div>
                            <h5>{{ mLast100Hours.last100Hours? mLast100Hours.last100Hours : "--" }} <small>Hours</small></h5>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-6 col-md-12">
                    <div class="form-group">
                      <h6 class="form-text text-muted">
                        <i class="la la-filter"></i>
                        Last Oil Change (Hrs)  {{mOilChangesWorking? '...searching...' : ''}}
                        <span class="required" *ngIf="inUpdateMode"></span>
                      </h6>
                      <app-preloader-type-b *ngIf="mOilChangesWorking"></app-preloader-type-b >

                      <div *ngIf="!mOilChangesWorking">
                        <ng-container *ngIf="inUpdateMode">
                          <div class="input-group">
                            <input type="text" formControlName="oilChanges" class="form-control" id="oilChanges" placeholder="Hours">
                          </div>
                          <small class="form-text text-danger d-block"
                          *ngIf="updateAllMaintenanceStatsForm.get('oilChanges').invalid && (updateAllMaintenanceStatsForm.get('oilChanges').touched || updateAllMaintenanceStatsForm.get('oilChanges').dirty)">
                            Enter a valid Oil Change Hours (e.g. 300.5)
                          </small>
                        </ng-container>

                        <ng-container *ngIf="!inUpdateMode">
                          <div>
                            <h5>{{ mOilChanges.lastOilChangeHours? mOilChanges.lastOilChangeHours : "--" }} <small>Hours</small></h5>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-6 col-md-12">
                    <div class="form-group">
                      <h6 class="form-text text-muted">
                        <i class="la la-warning"></i>
                        Last ELT Inspection {{mEltsWorking? '...searching...' : ''}}
                      </h6>
                      <app-preloader-type-b *ngIf="mEltsWorking"></app-preloader-type-b >

                      <div *ngIf="!mEltsWorking">
                        <ng-container *ngIf="inUpdateMode">
                          <div class="input-group">
                            <input type="text" formControlName="elts" class="form-control" id="elts" placeholder="mm/dd/yyyy" ngbDatepicker #elts="ngbDatepicker">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="elts.toggle()" type="button">
                                <i class="la la-calendar-plus-o"></i>
                              </button>
                            </div>
                          </div>
                          <small class="form-text text-danger d-block"
                          *ngIf="updateAllMaintenanceStatsForm.get('elts').invalid && (updateAllMaintenanceStatsForm.get('elts').touched || updateAllMaintenanceStatsForm.get('elts').dirty)">
                            Enter a valid ELTs Inspection Date
                          </small>
                        </ng-container>

                        <ng-container *ngIf="!inUpdateMode">
                          <div>
                            <h5>{{ mElts.lastEltDate?( mElts.lastEltDate | date:'MM/dd/yyyy') : "--" }}</h5>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-6 col-md-12">
                    <div class="form-group">
                      <h6 class="form-text text-muted">
                        <i class="la la-dot-circle-o"></i>
                        Last Pitot-static Inspection {{mPitoticsWorking? '...searching...' : ''}}
                        <!--<span class="required" *ngIf="inUpdateMode"></span>-->
                      </h6>
                      <app-preloader-type-b *ngIf="mPitoticsWorking"></app-preloader-type-b >

                      <div *ngIf="!mPitoticsWorking">
                        <ng-container *ngIf="inUpdateMode">
                          <div class="input-group">
                            <input class="form-control" formControlName="pitotics"  placeholder="mm/dd/yyyy" name="pitotics" ngbDatepicker #pitoticsDate="ngbDatepicker">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="pitoticsDate.toggle()" type="button">
                                <i class="la la-calendar-plus-o"></i>
                              </button>
                            </div>
                          </div>
                          <small class="form-text text-danger d-block"
                          *ngIf="updateAllMaintenanceStatsForm.get('pitotics').invalid && (updateAllMaintenanceStatsForm.get('pitotics').touched || updateAllMaintenanceStatsForm.get('pitotics').dirty)">
                            Enter a valid Piti-static Inspection Date
                          </small>
                        </ng-container>

                        <ng-container *ngIf="!inUpdateMode">
                          <div class="input-group">
                            <h5>{{ mPitotics.lastPitoticDate ? (mPitotics.lastPitoticDate | date:'MM/dd/yyyy') : "--"  }}</h5>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-6 col-md-12">
                    <div class="form-group">
                      <h6 class="form-text text-muted">
                        <i class="la la-headphones"></i>
                        Last Transponder Inspection {{mTranspondersWorking? '...searching...' : ''}}
                        <span class="required" *ngIf="inUpdateMode"></span>
                      </h6>
                      <app-preloader-type-b *ngIf="mTranspondersWorking"></app-preloader-type-b >

                      <div *ngIf="!mTranspondersWorking">
                        <ng-container *ngIf="inUpdateMode">
                          <div class="input-group">
                            <input class="form-control" formControlName="transponders"  placeholder="mm/dd/yyyy" name="transponders" ngbDatepicker #transpondersDate="ngbDatepicker">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="transpondersDate.toggle()" type="button">
                                <i class="la la-calendar-plus-o"></i>
                              </button>
                            </div>
                          </div>
                          <small class="form-text text-danger d-block"
                          *ngIf="updateAllMaintenanceStatsForm.get('transponders').invalid && (updateAllMaintenanceStatsForm.get('transponders').touched || updateAllMaintenanceStatsForm.get('transponders').dirty)">
                            Enter a valid Transponder Inspection Date
                          </small>
                        </ng-container>

                        <ng-container *ngIf="!inUpdateMode">
                          <div class="input-group">
                            <h5>{{ mTransponders.lastTransponderDate ? (mTransponders.lastTransponderDate | date:'MM/dd/yyyy') : "--"  }}</h5>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-lg-6 col-md-12">
                    <div class="form-group">
                      <h6 class="form-text text-muted">
                        <i class="la la-tachometer"></i>
                        Last Altimeter Inspection {{mAltimetersWorking? '...searching...' : ''}}
                        <span class="required" *ngIf="inUpdateMode"></span>
                      </h6>
                      <app-preloader-type-b *ngIf="mAltimetersWorking"></app-preloader-type-b >

                      <div *ngIf="!mAltimetersWorking">
                        <ng-container *ngIf="inUpdateMode">
                          <div class="input-group">
                            <input class="form-control" formControlName="altimeters"  placeholder="mm/dd/yyyy" name="altimeters" ngbDatepicker #altimetersDate="ngbDatepicker">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="altimetersDate.toggle()" type="button">
                                <i class="la la-calendar-plus-o"></i>
                              </button>
                            </div>
                          </div>
                          <small class="form-text text-danger d-block"
                          *ngIf="updateAllMaintenanceStatsForm.get('altimeters').invalid && (updateAllMaintenanceStatsForm.get('altimeters').touched || updateAllMaintenanceStatsForm.get('altimeters').dirty)">
                            Enter a valid Altimeter Inspection Date
                          </small>
                        </ng-container>

                        <ng-container *ngIf="!inUpdateMode">
                          <div>
                          <h5>{{ mAltimeters.lastAltimeterDate ? (mAltimeters.lastAltimeterDate | date:'MM/dd/yyyy') : "--"  }}</h5>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="text-right mt-2" *ngIf="inUpdateMode">
                <button class="btn btn-outline-danger mr-3" (click)="cancelPostAllMaintenanceStats()">Cancel</button>
                <button type="submit" class="btn btn-outline-primary">Update All Maintenance Stats</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
