import { ValidatorFn, AbstractControl } from '@angular/forms';

export function GreaterThanControlsValidator (allowEmpty: boolean, [... otherControls]: Array<AbstractControl>): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if(allowEmpty && !control.value) return null; //allow emopty fields, if definedas allowEmpty

    let forbidden: boolean = null;
    otherControls.forEach(
      otherControl => {
        forbidden = control.value < (+otherControl.value)? true : forbidden
      }
    )

    return forbidden ? {'timeInGtTimeOut': {value: control.value}} : null;
  };
}
