export class UserAircraftCurrentStatusModel{
  constructor(){
    this.airframeTTIS = null;
    this.engineTTIS = null;
    this.propellerTTIS = null;
    this.lastAnnuals = null;
    this.lastAnnualsHours = null;
    this.engineSMOH = null;
    this.propellerSMOH = null;
  }

  airframeTTIS:number;
  engineTTIS: number;
  propellerTTIS: number;
  lastAnnuals: string;
  lastAnnualsHours: number;
  engineSMOH: number;
  propellerSMOH: number;
}
