

  <table class="table spaced_rows table-responsive" *ngIf="aircraftList.length > 0">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Tail No.</th>
        <th scope="col">Make</th>
        <th scope="col">Model</th>
        <th scope="col">Role</th>
        <th scope="col">Assigned Date.</th>
        <th scope="col">Organization</th>
        <th scope="col">Last Logged Entry</th>
        <th scope="col">Status</th>
        <th scope="col">Actions</th>
        
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let aircraft of aircraftList; let i = index;">
        <tr>
          <th scope="row">{{i+1}}</th>
          <td >
            {{aircraft?.tailNo}}
          </td>
          <td>{{ aircraft?.airframe.make?.name}}</td>
          <td>{{ aircraft?.airframe.model?.name}}</td>
          <td>{{ aircraft?.organizationRole }}</td>
          <td>{{ getOwnerOrg(aircraft).assignmentDate | date }}</td>
          <td>{{ getOwnerOrg(aircraft).name }}</td>
          <td>{{ aircraft?.lastFlightLogDate | date}}</td>
          <td>
            <div class="{{checkAllStatusDone(aircraft.id) ? 'd-none' : 'd-block'}}" style="min-width: 100px;">
              <app-preloader-type-a></app-preloader-type-a>
            </div>

            <div class="{{checkAllStatusDone(aircraft.id) ? 'd-block' : 'd-none'}}">
              <!--SAFETY STATUS-->
              <app-dashboard-safety-status
                [aircraftId]="aircraft.id" 
                [theAircraft]="aircraft" 
                [statusOnly]="true"
                (isCompleted)="updateSafetyCompleted(aircraft.id, $event)"
              ></app-dashboard-safety-status>

              <!--MAINTENANCE STATUS-->
              <app-dashboard-maintenance-status 
                [aircraftId]="aircraft.id" 
                [theAircraft]="aircraft" 
                [statusOnly]="true"
                (isCompleted)="updateMaintenanceCompleted(aircraft.id, $event)"
              ></app-dashboard-maintenance-status>

              <!--IFR EQUIPMENT-->
              <app-dashboard-ifr-equipment-status
                [theAircraft]="aircraft" 
                [statusOnly]="true"
                (isCompleted)="updateIfrCompleted(aircraft.id, $event)"
              ></app-dashboard-ifr-equipment-status>

              <!--ADMINISTRATIVE STATUS-->
              <app-administrative-status
                [theAircraft]="aircraft" 
                [statusOnly]="true"
                (isCompleted)="updateAdminStatCompleted(aircraft.id, $event)"
              ></app-administrative-status>
            </div>

          </td>
          <td>
            <a href="javascript: void(0);" (click)="viewAsSelectedAircraft(aircraft)" class="btn btn-sm mr-1 btn-orange rounded">
             <img class="imgicon" width="30" src="/assets/images/visual_assets/menu_icons/Aircraft Icon - White-01.png" alt="Profile" title="Profile" />
            </a>
            
            <a *ngIf="currentUser.isAdmin" href="javascript: void(0);" (click)="viewAsSelectedAircraft(aircraft, 'dashboard')" class="btn btn-sm mr-1 btn-danger rounded">
              <img class="imgicon" width="30" src="/assets/images/visual_assets/menu_icons/Dashboard Icon - White-01.png" alt="Dashboard" title="Dashboard" />
            </a>
          </td>
        </tr>
      </ng-container>

    </tbody>
  </table>
