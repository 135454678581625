import { AlertPopUpsService } from './../../../services/alert-pop-ups.service';
import { SubscriptionLike } from 'rxjs';
import { NewOrganizationComponent } from './../new-organization/new-organization.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from './../../../services/utilities.service';
import { OrganizationTypeOptionsModel } from './../../../models/organization-type-options-model';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { OrganizationTypes } from './../../../models/organization-types';
import { OrganizationBaseModel } from './../../../models/organization-base-model';
import { OrganizationService } from './../../../services/organization.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserOrganizationAndRole } from '../../../models/user-organization-and-role';
import { OrganizationUserRoleTypes } from '../../../models/organization-user-role-types';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-organizations',
  templateUrl: './list-organizations.component.html',
  styleUrls: ['./list-organizations.component.scss']
})
export class ListOrganizationsComponent implements OnInit, OnDestroy {

  constructor(
    private orgService: OrganizationService,
    private utilsService: UtilitiesService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private alertPopUpService: AlertPopUpsService,
    private router: Router,
  ) { }

  isWorking: boolean = false;

  allOrgs: Array<OrganizationBaseModel> = [];
  updatedOrg: OrganizationBaseModel = new OrganizationBaseModel;
  isUpdatingOrg: boolean = false;

  newOrgSubsciber$: SubscriptionLike;

  page: number = 0;
  pageSize: number = 50;

  updateOrgForm: FormGroup;

  orgTypeOptions: OrganizationTypeOptionsModel = new OrganizationTypeOptionsModel;

  ngOnInit() {
    this.listOrganizations();
    this.initUpdateOrgForm();
  }

  listOrganizations() {
    this.isWorking = true;
    this.orgService.listOrganizationsV2().subscribe(
      (response: any) => {
        this.isWorking = false;
        this.allOrgs = response;
      },
      (error: any) => {
        this.isWorking = false;
        this.alertPopUpService.openBasicPopUp(
          'Sorry!',
          'Can not fetch list of Organizations'
        );
      }
    );
  }

  initUpdateOrgForm() {
    this.updateOrgForm = this.fb.group({
      'orgName': new FormControl(null, [Validators.required, Validators.nullValidator]),

      // No Org type for now
      // 'orgType': new FormControl(null, [Validators.required])
    });
  }

  getOrgTypeLabelText(type: OrganizationTypes){
    return this.orgService.getOrgTypeLabelText(type.toString());
  }

  loadOrgForUpdate(org: OrganizationBaseModel){
    this.updatedOrg.id = org.id;
    this.updatedOrg.organizationType = org.organizationType;
    this.updatedOrg.organizationName = org.organizationName;

    this.updateOrgForm.get('orgName').setValue(this.updatedOrg.organizationName);

    // No org type for now
    // this.updateOrgForm.get('orgType').setValue(this.updatedOrg.organizationType);

  }

  unloadUpdatedOrg() {
    this.updatedOrg = new OrganizationBaseModel;
    this.updateOrgForm.reset();
  }

  updateOrganization() {
    let formValidate: boolean = this.utilsService.generalFormValidate(this.updateOrgForm);

    if (!formValidate) {
      return;
    }

    this.isUpdatingOrg = true;
    this.updatedOrg.organizationName = this.updateOrgForm.get('orgName').value;

    // No org type for now
    // this.updatedOrg.organizationType = this.updateOrgForm.get('orgType').value;

    this.orgService.updateOrganizationV2(
      this.updatedOrg.id,
      this.updatedOrg.organizationName,
      this.updatedOrg.organizationType
      ).subscribe(
        (response: any)=>{
          this.listOrganizations();
          this.unloadUpdatedOrg();
          this.isUpdatingOrg = false;
        },
        (error: any) => {

        }
      );
  }

  deleteOrg(org: OrganizationBaseModel) {

  }

  orgBeingUpdated(org: OrganizationBaseModel) {
    return this.updatedOrg.id && +this.updatedOrg.id === +org.id;
  }

  openCreateOrgModal() {
    const modalRef = this.modalService.open(NewOrganizationComponent);
    this.newOrgSubsciber$ = modalRef.componentInstance.newOrgEmmitter.subscribe(
      (newOrg: OrganizationBaseModel) => {
        this.listOrganizations();

        this.alertPopUpService.openBasicPopUp(
          `Organization created!`,
          `Organization with name "${newOrg.organizationName}" ` +

          // No org type for now
          // and type "${newOrg.organizationType}" ` +

          ` has been created!`,
          'success'
        );

      }
    );
  }

  loadAndViewOrgAsSelectedOrg(org: OrganizationBaseModel) {
    const menuOrg: UserOrganizationAndRole = {
      role : 'ADMIN' as unknown as OrganizationUserRoleTypes,
      organization : {
        id: org.id,
        organizationType: org.organizationType ? org.organizationType : null,
        organizationName: org.organizationName ? org.organizationName : null,
        createdAt: org.createdAt ? org.createdAt : null,
        updatedAt: org.updatedAt ? org.updatedAt : null,
      }
    };

    this.orgService.setSelectedOrganization(menuOrg);

    this.router.navigate(['/cga/organization-profile']);
  }

  ngOnDestroy() {
    if (this.newOrgSubsciber$) {
      this.newOrgSubsciber$.unsubscribe();
    }
  }

}
