import { UserAircraftModel } from './user-aircraft-model';

export class MAircraftPitoticsModel{
  constructor(){
    this.id = null;
    this.aircraftId = null;
    this.lastPitoticDate = null;
    this.aircraft  = new UserAircraftModel;
  }
  id: number;
  aircraftId: number;
  lastPitoticDate: string;
  aircraft: UserAircraftModel;
}