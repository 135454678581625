import { filter, map } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { UtilitiesService } from './services/utilities.service';
import { Component, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterContentChecked {
  constructor(
    private utilitiesService: UtilitiesService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title
    ){
  }

  isProduction: boolean = false;
  envColor: string = environment.environmentColor;
  envName: string = environment.environmentName;
  envTime: number = +(environment.buildTime.split('T').join(''));
  envBuildDate: Date = new Date(this.envTime);

  title = 'Crewchief GA';

  showMainPreloader: boolean = false;
  private showMainPreloader$: SubscriptionLike;

  showActivityPreloader: boolean = false;
  private showActivityPreloader$: SubscriptionLike;

  ngOnInit() {
    this.isProduction = environment.production;

    this.showActivityPreloader$ = this.utilitiesService.showActivityPreloader$.subscribe(
      (val)=>{
        this.showActivityPreloader = val;
      }
    )

    this.showMainPreloader$ = this.utilitiesService.showMainPreloader$.subscribe(
      (val)=>{
        this.showMainPreloader = val;
      }
    )

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
          let child = this.route.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return null;
            }
          }
          return null;
        })
      ).subscribe( (customTitle: any) => {

        window.scrollTo(0, 0);

        let pageTitle = customTitle? customTitle : '';
        this.utilitiesService.setPagetitle(pageTitle);
        this.titleService.setTitle(`Crewchief::: ${pageTitle}`);
       });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    if(this.showMainPreloader$) this.showMainPreloader$.unsubscribe();
  }
}
