<section class="my-3">
  <h5 class="form_header uppercase pt-3 clearfix">
    Associated Documents
    <sup class="text-danger"></sup>

    <a [routerLink]="['/cga/admin/upload-logbook']" class="float-right btn btn-sm btn-danger">
      Upload
    </a>
  </h5>

  <div class="card p-1 border-0 shadow my-3">
    <!-- Add Arrows -->
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>

    <swiper [config]="config" #$usefulSwiper>
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let item of logCarouselItems">
          <div class="card text-center p-3 my-3 mx-2 border-light shadow">
            <a href="javascript:void(0);" (click)="getLogBookTypeDoclink(item.type)" >
              <img
                class="item_icon"
                [src]="
                  'assets/images/logbooks/' +
                  item.type.toString().toLowerCase() +
                  '.png'
                "
                alt="{{ getLogBookTypeLabelText(item.type) }}"
              />

              <div class="d-block">
                <small class="text-paleblue">View</small>
                <h6 class="text-primary">
                  {{ getLogBookTypeLabelText(item.type) }}
                </h6>
              </div>
            </a>
          </div>
        </div>
      </div>

      <!-- Add Pagination -->
      <!-- <div class="swiper-pagination"></div> -->
    </swiper>
  </div>
</section>
