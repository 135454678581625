import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preloader-type-a',
  templateUrl: './preloader-type-a.component.html',
  styleUrls: ['./preloader-type-a.component.scss']
})
export class PreloaderTypeAComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
