<section class="px-1 pb-3">
  <app-user-aircraft-info-header-widget [headerTitle]="'View/Edit Aircraft'" [userAircraft]="theAircraft">
  </app-user-aircraft-info-header-widget>
</section>

<ng-container *ngIf="this.theAircraft.id">
  <ngb-tabset #dashboardTabs justify="fill" (tabChange)="onTabChange()">
    <!--Air Directives-->
    <ngb-tab title="ADs" id="tab_ad">
      <ng-template ngbTabContent>
        <app-manage-dashboard-directives [aircraftId]="this.theAircraft.id" [tabChanges]="tabChangeEventsSubject.asObservable()"></app-manage-dashboard-directives>
      </ng-template>
    </ngb-tab>


    <!-------Service Bulletins -->
    <ngb-tab title="Service Bulletins" id="tab_bulletin">
      <ng-template ngbTabContent>
        <!---display Service Bulletinss -->
        <app-manage-dashboard-bulletins [aircraftId]="this.theAircraft.id" [tabChanges]="tabChangeEventsSubject.asObservable()"></app-manage-dashboard-bulletins>
      </ng-template>
    </ngb-tab>


      <!-------Maintenance -->
      <ngb-tab title="Maintenance" id="tab_maintenance">
        <ng-template ngbTabContent>
          <!---display Maintenance -->
          <app-manage-maintenance 
          [aircraftId]="theAircraft.id"
          [aircraftTailNo]="theAircraft.tailNo" 
          [tabChanges]="tabChangeEventsSubject.asObservable()">
        </app-manage-maintenance>
        </ng-template>
      </ngb-tab>
  </ngb-tabset>
</ng-container>
