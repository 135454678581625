import { OptionLabelsAndValuesModel } from './option-labels-and-values-model';

export class AircraftRoleOptionsModel {
  constructor(){
    this.options = [
      {label: '--Select aircraft role--', value: ''},
      {label: 'VIEWER', value: 'INDIVIDUAL_RO'},
      {label: 'PILOT', value: 'INDIVIDUAL_RW'}
    ]
  }
  options: Array<OptionLabelsAndValuesModel>;
}
