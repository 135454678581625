<ng-container *ngIf="statusOnly">

  <span class="badge badge-pill px-3 py-2 {{finalSafetyStatus}}">
  S
  </span>

  <!--
  <span *ngIf="sbsDone && adsDone">
    <ng-container *ngIf="(allAircraftADs.length > 0) && (allAircraftSBs.length > 0)">
      <span class="badge badge-pill px-3  py-2 
      {{allAircraftADs[0].ragStatus < allAircraftSBs[0].ragStatus? getRagColor(allAircraftADs[0].ragStatus) : getRagColor(allAircraftSBs[0].ragStatus)}}
      "> 
      S
      </span>
    </ng-container>

    <ng-container *ngIf="(allAircraftADs.length < 1) && (allAircraftSBs.length > 0)">
      <span class="badge badge-pill px-3 py-2  {{getRagColor(allAircraftSBs[0].ragStatus)}}">
        S
      </span>
    </ng-container>

    <ng-container *ngIf="(allAircraftADs.length > 0) && (allAircraftSBs.length < 1)">
      <span class="badge badge-pill px-3 py-2  {{getRagColor(allAircraftADs[0].ragStatus)}}">
        S
      </span>
    </ng-container>

    <ng-container *ngIf="(allAircraftADs.length < 1) && (allAircraftSBs.length < 1)">
      <span class="badge badge-pill px-3 py-2 ">
      S
      </span>
    </ng-container>
  </span>

  <span  *ngIf="!(sbsDone && adsDone)">
    <span class="badge badge-pill px-3 py-2 ">
    S
    </span>
  </span>

-->
</ng-container>

<ng-container *ngIf="!statusOnly">
  <div class="card shadow border-0 aircraft-dashboard-safety">
    <div class="card-header">
      <div class="clearfix">
        <h6 class="float-left mt-1">
          <ng-container *ngIf="allAircraftADs[0] && allAircraftSBs[0]">
            <span class="badge rag_circle
            {{allAircraftADs[0] < allAircraftSBs[0]? getRagColor(allAircraftADs[0].ragStatus) : getRagColor(allAircraftSBs[0].ragStatus)}}
            ">&nbsp;</span>
          </ng-container>

          <ng-container *ngIf="!allAircraftADs[0] && allAircraftSBs[0]">
            <span class="badge rag_circle
            {{getRagColor(allAircraftSBs[0].ragStatus)}}
            ">&nbsp;</span>
          </ng-container>

          <ng-container *ngIf="allAircraftADs[0] && !allAircraftSBs[0]">
            <span class="badge rag_circle
            {{getRagColor(allAircraftADs[0].ragStatus)}}
            ">&nbsp;</span>
          </ng-container>

          <span *ngIf="!(allAircraftADs[0] && allAircraftSBs[0])" class="a_red">&nbsp;</span>

          Safety Status <small class="text-muted">@ {{ todayFlightHours }} Hours</small>
        </h6>
        <a *ngIf="!isAdminView" [routerLink]="['/cga/all-aircraft-safety-documents/']" class="btn-outline-primary btn float-right rounded btn-sm">
          View Documents
        </a>

        <a *ngIf="isAdminView" [routerLink]="['/cga/admin/view-all-aircraft-safety-documents/', aircraftId]" class="btn-outline-primary btn float-right rounded btn-sm">
          View Documents as Admin
        </a>
      </div>
    </div>

    <div class="card-body">
      <app-preloader-type-a *ngIf="isLoading"></app-preloader-type-a>

      <div *ngIf="!isLoading">

        <div class="alert alert-warning text-center" role="alert" *ngIf="(allAircraftADs.length < 1) && (allAircraftSBs.length < 1)">
          No AD or SB Safety Status {{useLookAhead? ' due in the next ' + dashReferences.safetyRefLookAheadDays + ' days or ' + dashReferences.safetyRefLookAheadHours + ' hours'  : ' to view.' }}. <br/>
          Complied/Perfomed documents will be displayed in "View Documents".
        </div>

        <table class="table table-striped w-100 rag_table" *ngIf="allAircraftADs.length > 0 || allAircraftSBs.length > 0">
          <tbody>
            <!----Dashboard ADS-->
            <tr>
              <td class="text-light text-center p-0 bg-secondary rounded">
                <small>Airworthiness Directives</small>
              </td>
            </tr>

            <!--If NO ADS to show-->
            <tr *ngIf="allAircraftADs.length < 1">
              <td>
                <div class="alert alert-info text-center">
                  No Airworthiness Directive {{useLookAhead? ' due in the next ' + dashReferences.safetyRefLookAheadDays + ' days or ' + dashReferences.safetyRefLookAheadHours + ' hours'  : ' to view.' }}. <br/>
                  Complied and Not Apply ADs will be displayed in "View Documents".
                </div>
              </td>
            </tr>

            <!--List of Ads-->
            <tr *ngFor="let ads of allAircraftADs; let i = index;" class="{{getRagColor(ads.ragStatus)}} border-bottom">
              <td>
                <div class="h6 clearfix">
                    {{ getAdbOrSbName(ads) }} <small class="text-primary"> | {{ getAdbOrSbDisplayName(ads) | uppercase }}</small>

                    <small class="float-right text-muted">{{getAdbOrSbTypeName(ads) | uppercase}}</small>
                </div>
                <div class="row">
                  <div class="col-12 mb-1 text-muted ">
                    <small class="pr-2">Subject: </small>
                    {{getAdbOrSbSubject(ads)? getAdbOrSbSubject(ads) : '----'}}
                  </div>

                  <div class="col-6">
                    <small class="text-primary d-block">
                      Due
                    </small>
                    {{ ads.sortingColumnName == 'Date'? formatMomentAsUsDateString(ads.dueDateAsMoment) : '' }}
                    {{ ads.sortingColumnName == 'Hours'? (ads.dueHours + 'Hrs') : '' }}

                    <span *ngIf="ads.isRecurring" class="text-success font-weight-bold" ngbPopover="This is a recurring {{getAdbOrSbDisplayName(ads)}}." triggers="mouseenter:mouseleave" popoverTitle="{{getAdbOrSbDisplayName(ads)}}">
                      <i class="la la-refresh"></i>
                    </span>
                  </div>
                  <div class="col-6 border-left">
                    <small class="text-primary d-block">
                      Remaining
                    </small>
                    <span *ngIf="ads.ragStatus == 0">
                      Overdue
                    </span>

                    <span *ngIf="ads.ragStatus !== 0">
                      {{ ads.sortingColumnName == 'Date'? getMomentBetweenDates(todayFlightDateMoment, ads.dueDateAsMoment) : '' }}
                      {{ ads.sortingColumnName == 'Hours'? getHoursRemainingBetweenHours(todayFlightHours, ads.dueHours) : '' }}
                    </span>
                  </div>
                </div>
              </td>
            </tr>

            <!----Dashboard SBS-->
            <tr>
              <td class="text-light text-center p-0 bg-secondary rounded">
                <small>Service Bulletins</small>
              </td>
            </tr>

            <!--If NO SBS to show-->
            <tr *ngIf="allAircraftSBs.length < 1">
              <td>
                <div class="alert alert-info text-center">
                  No Service Bulletin{{useLookAhead? ' due in the next ' + dashReferences.safetyRefLookAheadDays + ' days or ' + dashReferences.safetyRefLookAheadHours + ' hours'  : ' to view.' }}. <br/>
                  Complied and Not Apply SBs will be displayed in "View Documents".
                </div>
              </td>
            </tr>

            <!--List of SBs-->
            <tr *ngFor="let sbs of allAircraftSBs; let i = index;" class="{{getRagColor(sbs.ragStatus)}} border-top" style="boder-top: solid 3px #444444">
              <td>
                <div class="h6 clearfix">
                    {{ getAdbOrSbName(sbs) }} <small class="text-primary"> | {{ getAdbOrSbDisplayName(sbs) | uppercase }}</small>

                    <small class="float-right text-muted">{{getAdbOrSbTypeName(sbs) | uppercase}}</small>
                </div>
                <div class="row">
                  <div class="col-12 mb-1 text-muted ">
                    <small class="pr-2">Subject: </small>
                    {{getAdbOrSbSubject(sbs)? getAdbOrSbSubject(sbs) : '----'}}
                  </div>

                  <div class="col-6">
                    <small class="text-primary d-block">
                      Due
                    </small>
                    {{ sbs.sortingColumnName == 'Date'? formatMomentAsUsDateString(sbs.dueDateAsMoment) : '' }}
                    {{ sbs.sortingColumnName == 'Hours'? (sbs.dueHours + 'Hrs') : '' }}

                    <span *ngIf="sbs.isRecurring" class="text-success font-weight-bold" ngbPopover="This is a recurring {{getAdbOrSbDisplayName(sbs)}}." triggers="mouseenter:mouseleave" popoverTitle="{{getAdbOrSbDisplayName(sbs)}}">
                      <i class="la la-refresh"></i>
                    </span>
                  </div>
                  <div class="col-6 border-left">
                    <small class="text-primary d-block">
                      Remaining
                    </small>
                    <span *ngIf="sbs.ragStatus == 0">
                      Overdue
                    </span>

                    <span *ngIf="sbs.ragStatus !== 0">
                      {{ sbs.sortingColumnName == 'Date'? getMomentBetweenDates(todayFlightDateMoment, sbs.dueDateAsMoment) : '' }}
                      {{ sbs.sortingColumnName == 'Hours'? getHoursRemainingBetweenHours(todayFlightHours, sbs.dueHours) : '' }}
                    </span>
                  </div>
                </div>
              </td>
            </tr>


          </tbody>
        </table>
      </div>

    </div>
  </div>
</ng-container>
