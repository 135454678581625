<app-preloader-type-a *ngIf="isLoading"></app-preloader-type-a>

<ng-container *ngIf="!isLoading">
  <!--NO AIRCRAFT VIEW LOG-->
  <div class="m-1" *ngIf="theAircraft.id === null">
    <h5 class="border-bottom mx-2 mb-3">Aircraft Flight Log</h5>

    <div class="row">
      <div class="col-sm-12 text-center col-md-6 col-lg-4">
        <div class="card border-light mx-sm-1 p-3">
          <div class="text-muted mt-3">
            <h6><i class="la la-pane"></i></h6>
          </div>
          <div class="mt-2 text-muted"><h4>No Aircraft</h4></div>
          <!--<hr>-->
          <div class="row">
            <div class="col-6">
              <small class="d-block text-muted">&nbsp;</small>
              <div>&nbsp;</div>
            </div>
            <div class="col-6">
              <small class="d-block text-muted">&nbsp;</small>
              <div>&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="theAircraft.id !== null">
    <app-aircraft-info-header-widget-v2
      [headerTitle]="'Aircraft Flight Log'"
      [theAircraft]="theAircraft" 
    >
    </app-aircraft-info-header-widget-v2>

    <section class="mt-3">
      <ngb-tabset justify="fill" (tabChange)="onTabChange()">
        <!--Log History-->
        <ngb-tab title="Log Update">
          <ng-template ngbTabContent>
            <app-manage-flight-logs
              [inAdminView]="inAdminView"
              [inViewOnly]="inViewOnly"
              [aircraftId]="theAircraft.id"
              [tabChanges]="tabChangeEventsSubject.asObservable()" 
              (emmitAircraftRefresh)="refetchAircraft()"
            ></app-manage-flight-logs>
          </ng-template>
        </ngb-tab>

        <!-------Nav Updates -->
        <ngb-tab title="Nav Data Updates">
          <ng-template ngbTabContent>
            <!---display Nav Updates -->
            <app-manage-nav-updates
              [inAdminView]="inAdminView"
              [inViewOnly]="inViewOnly"
              [aircraftId]="theAircraft.id"
              [tabChanges]="tabChangeEventsSubject.asObservable()" 
            ></app-manage-nav-updates>
          </ng-template>
        </ngb-tab>

        <!-------Maintenance -->
        <ngb-tab title="VOR Check">
          <ng-template ngbTabContent>
            <!---display VOR Updates -->
            <app-manage-vor-checks
              [inAdminView]="inAdminView"
              [inViewOnly]="inViewOnly"
              [aircraftId]="theAircraft.id"
              [tabChanges]="tabChangeEventsSubject.asObservable()" 
            ></app-manage-vor-checks>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </section>
  </ng-container>
</ng-container>
