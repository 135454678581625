import { AircraftServiceBulletinModel } from './../../../../models/aircraft-service-bulletin-model';
import { AircraftFlightLogModel } from './../../../../models/aircraft-flight-log-model';
import { UtilitiesService } from './../../../../services/utilities.service';
import { AirworthinessDirectiveService } from './../../../../services/airworthiness-directive.service';
import { ServiceBulletinService } from './../../../../services/service-bulletin.service';
import { FlightLogsService } from './../../../../services/flight-logs.service';
import { UserAircraftModel } from './../../../../models/user-aircraft-model';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { AircraftDashboardReferencesModel } from '../../../../models/aircraft-dashboard-references-model';
import * as moment from 'moment';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-view-all-aircraft-sb-docs',
  templateUrl: './view-all-aircraft-sb-docs.component.html',
  styleUrls: ['./view-all-aircraft-sb-docs.component.scss']
})
export class ViewAllAircraftSbDocsComponent implements OnInit {
  @Input() theAircraft: UserAircraftModel;

  constructor(
    private flightLogsService: FlightLogsService,
    private sbService: ServiceBulletinService,
    private adService: AirworthinessDirectiveService,
    private utilitiesService: UtilitiesService,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) { }

  /***
   * NOT: INIT ALL PAGE VAIRABLESIN INITPAGE FUNCTION
   */
  viewedPdfSrc: string;
  viewedPdfSbTitle: string;

  isLoading: boolean;

  lastFlightLogEntry: AircraftFlightLogModel;

  serverEpochTime: number;

  todayFlightDate: string;
  todayFlightDateMoment: moment.Moment;
  todayFlightHours: number;

  page: number;
  pageSize: number;

  totalSbs: number;
  allAircraftSBs: Array<AircraftServiceBulletinModel>;
  allAppliesAircraftSBs: Array<AircraftServiceBulletinModel>;
  allPerformedSBs: Array<AircraftServiceBulletinModel>;
  allDoesNotApplySBs: Array<AircraftServiceBulletinModel>;

  dashReferences: AircraftDashboardReferencesModel;

  filterSearchForm: FormGroup;

  filterResult: Array<AircraftServiceBulletinModel>;

  inFilterMode: boolean;
  isFilteringSbs: boolean;

  ngOnInit() {
    this.initPage();
  }

  initPage(){
    this.filterSearchForm = this.fb.group({
      'sbFilter': new FormControl('')
    });

    this.filterSearchForm.controls['sbFilter'].valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(400)
    ).subscribe(
      (val) => {
        this.doFilterAdSb(val);
      }
    );

    this.viewedPdfSrc = null;
    this.viewedPdfSbTitle = null;

    this.isLoading = false;

    this.lastFlightLogEntry = new AircraftFlightLogModel;

    this.serverEpochTime = (new Date).getTime();

    this.todayFlightDate = this.utilitiesService.dateToISOString(new Date(this.serverEpochTime)); //today's date: when if dueDate exceeds this, becomes overdue(RED)
    this.todayFlightDateMoment = moment(this.todayFlightDate);
    this.todayFlightHours = null; //From last flight Hours: If due hours exceeds this, becomes overdue(RED)

    this.page = 1;
    this.pageSize = 400;

    this.allAircraftSBs = [];
    this.totalSbs = null;
    this.allAppliesAircraftSBs =  [];
    this.allPerformedSBs = [];
    this.allDoesNotApplySBs = [];

    this.dashReferences = new AircraftDashboardReferencesModel;

    this.utilitiesService.getAircraftDashboardReferences().subscribe(
      (response: any)=>{

        if(response.status.code == 1000){
          this.dashReferences = response.responseData;
          this.todayFlightDate = this.dashReferences.serverNow;
          this.todayFlightDateMoment = moment(this.todayFlightDate);
          this.serverEpochTime = this.utilitiesService.parseISOtoDateObj(this.dashReferences.serverNow).getTime();

          this.fetchAllAircraftFlightLogsTillFlightDate();
        }
      }
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.theAircraft.currentValue){
      this.theAircraft = changes.theAircraft.currentValue;
      this.initPage();
    }
  }



  clearFilter() {
    this.filterResult = [];
    this.inFilterMode = false;
    this.filterSearchForm.controls['sbFilter'].setValue('');
  }

  doFilterAdSb(query: string) {
    this.filterResult = [];

    if (query.trim().length < 1) {
      this.inFilterMode = false;
      this.isFilteringSbs = false;
      return;
    }

    this.inFilterMode = true;
    this.isFilteringSbs = true;

    this.filterResult = this.adService.filterAdSBs(
      query,
      [
        ... this.allAppliesAircraftSBs,
        ... this.allPerformedSBs,
        ... this.allDoesNotApplySBs
      ]
    );

    this.isFilteringSbs = false;
  }

  fetchAllAircraftFlightLogsTillFlightDate(){
    this.flightLogsService.fetchAllAircraftFlightLogsTillFlightDate(this.theAircraft.id, '').subscribe(
      (response: any) => {
        if (+response.status.code === 1000) {
          let allLogs: Array<AircraftFlightLogModel>  = response.responseData.rows;

          this.lastFlightLogEntry = allLogs.length > 0? allLogs[0] : new AircraftFlightLogModel;

          let lastTimeInHours = this.lastFlightLogEntry.timeInHours? this.lastFlightLogEntry.timeInHours : 0;
          let lastTimeOutHours = this.lastFlightLogEntry.timeOutHours? this.lastFlightLogEntry.timeOutHours : 0;

          this.todayFlightHours = lastTimeInHours > 0? +lastTimeInHours : +lastTimeOutHours; //check if to use last timeIn or TimeOut for todayFlightHours

          this.findAircraftServiceBulletinByName();
        }
      }
    )

  }


  findAircraftServiceBulletinByName(){
    this.isLoading = true;

    this.sbService.findAircraftServiceBulletinByName(this.theAircraft.id, '', this.page, this.pageSize).subscribe(
      (response: any)=>{
        this.isLoading = false;
        if(response.status.code == 1000){
          this.allAircraftSBs = response.responseData.rows;
          this.totalSbs = response.responseData.count

          this.prepareSortingFieldsOnADsAndSBs()
        }
      }
    )
  }

  prepareSortingFieldsOnADsAndSBs(){
    this.allAircraftSBs.forEach(
      (el: AircraftServiceBulletinModel)=>{

        el['searchField'] = el.service_bulletin.bulletinName +
          el.service_bulletin.subject +
          el.service_bulletin.adSbType +
          el.application ;

        el = this.adService.prepareSortingFieldsOnADsAndSBs(el, this.todayFlightHours, this.todayFlightDate, this.dashReferences) as AircraftServiceBulletinModel;

        return el;
      }
    )

    this.sortAppliesAircraftSBs();
  }
  

  sortAppliesAircraftSBs(){
    this.allAppliesAircraftSBs = this.allAircraftSBs.filter(
      (sb)=>{
        if (sb.application == "Applies" || (sb.application == "Performed" && sb.isRecurring && !sb.terminateAction)) {
          sb['sbGroup'] = 'applies';
          return true;
        } else {
          return false;
        };
      }
    )
    this.allAppliesAircraftSBs = this.adService.sortAllAircraftADsAndSBs(this.allAppliesAircraftSBs) as Array<AircraftServiceBulletinModel>;

    this.sortPerformedAircraftSBs();

  }


  sortPerformedAircraftSBs(){
     this.allPerformedSBs = this.allAircraftSBs.filter(
      (sb)=>{
        if ((sb.application == "Performed" && !sb.isRecurring) || (sb.application == "Performed" && sb.isRecurring && sb.terminateAction)) {
          sb['sbGroup'] = 'performed';
          return true;
        } else {
          return false;
        }
      }
    )

    this.allPerformedSBs = this.adService.sortAllAircraftADsAndSBs(this.allPerformedSBs) as Array<AircraftServiceBulletinModel>;

    this.sortNotApplyAircraftSBs();
  }


  sortNotApplyAircraftSBs(){
    this.allDoesNotApplySBs = this.allAircraftSBs.filter(
      (sb) => {
        if (sb.application == "NotApply") {
          sb['sbGroup'] = 'notapply';
          return true;
        } else {
          return false;
        }
      }
    )

    this.allDoesNotApplySBs = this.adService.sortAllAircraftADsAndSBs(this.allDoesNotApplySBs) as Array<AircraftServiceBulletinModel>;

    this.allAircraftSBs = [
      ... this.allAppliesAircraftSBs,
      ... this.allPerformedSBs,
      ... this.allDoesNotApplySBs
    ];
  }

  getRowTypeColor(sb: any) {
    if (sb.sbGroup == 'applies') {
      return this.getRagColor(sb.ragStatus);
    }
  }

  getRagColor(val: number){
    return this.adService.getRagColor(val);
  }

  formatMomentAsUsDateString(momentObject: moment.Moment){
    return this.utilitiesService.formatMomentAsUsDateString(momentObject);
  }

  getMomentBetweenDates(lowertimeMoment: moment.Moment, higherTImeMoment: moment.Moment){
    return this.utilitiesService.getMomentBetweenDates(lowertimeMoment, higherTImeMoment);

  }


  paginationPageChange($event) {
    this.page = $event;
    this.findAircraftServiceBulletinByName();
  }

  getHoursRemainingBetweenHours(lowerHours: number, higherHours: number){
    return this.utilitiesService.getHoursRemainingBetweenHours(lowerHours, higherHours);
  }

  getConditionDisplayString(condition: string){
    if(condition == "WhichOccursFirst"){
      return "Whichever occurs first";
    }

    if(condition == "WhichHappensLast"){
      return "Whichever happens last";
    }

    if(condition == "NA"){
      return null;
    }
  }

  getISODateAsDate(isoDate: string){
    let dateObj: Date = this.utilitiesService.parseISOtoDateObj(isoDate);
    return dateObj;
  }

  openModal(content, pdfUrl: string, title: string = "View Bulletin") {
    this.viewedPdfSrc = pdfUrl;
    this.viewedPdfSbTitle = title;

    this.modalService.open(content,{ size: 'lg' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }


}
