
<!--NO AIRCRAFT DASHBOARD-->
<div class="m-1" *ngIf="theAircraft.id === null">
  <h5 class="border-bottom mx-2 mb-3">My Aircraft Dashboard</h5>

  <div class="row">
    <div class="col-sm-12 text-center col-md-6 col-lg-4">
      <div class="card border-light mx-sm-1 p-3">
        <div class="text-muted mt-3"><h6><i class="la la-pane"></i></h6></div>
        <div class="mt-2 text-muted"><h4>No Aircraft</h4></div>
        <!--<hr>-->
        <div class="row">
            <div class="col-6">
              <small class="d-block text-muted">&nbsp;</small>
              <div>&nbsp;</div>
            </div>
            <div class="col-6">
              <small class="d-block text-muted">&nbsp;</small>
              <div>&nbsp;</div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="theAircraft.id !== null">
  <app-user-aircraft-info-header-widget [headerTitle]="'All Aircraft Safety Documents'" [userAircraft]="theAircraft">
  </app-user-aircraft-info-header-widget>

  <section class="mt-3">

    <ngb-tabset justify="fill">
      <!--Airworthiness Directives Documents-->
      <ngb-tab title="Airworthiness Directives">
        <ng-template ngbTabContent>
          <app-view-all-aircraft-ad-docs [theAircraft]="theAircraft"></app-view-all-aircraft-ad-docs>
        </ng-template>
      </ngb-tab>

      <!--Service Bulletin Documents-->
      <ngb-tab title="Service Bulletins">
        <ng-template ngbTabContent>
          <app-view-all-aircraft-sb-docs [theAircraft]="theAircraft"></app-view-all-aircraft-sb-docs>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </section>
</ng-container>
