import { ManufacturerModel } from './manufacturer-model';
import { AircraftMakeModel } from './aircraft-make-model';

export class BaseAircraftEnginePropellerApplianceModel{
  constructor(){
    this.id = null;
    this.modelName = null;
  }
  make = ()=>{
    return null;
  }

  manufacturer = ()=>{
    return null;
  }
  
  id: number;
  modelName: string;
}