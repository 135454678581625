import { ManufacturerModel } from './manufacturer-model';
import { BaseAircraftEnginePropellerApplianceModel } from './base-aircraft-engine-propeller-appliance-model';
import { AircraftMakeModel } from './aircraft-make-model';

export class AircraftModel extends BaseAircraftEnginePropellerApplianceModel{
  constructor(){
    super();
    this.id = null;
    this.aircraftMake = new AircraftMakeModel;
  }
    
  aircraftMake: AircraftMakeModel
  aircraftManufacturer?: ManufacturerModel
}