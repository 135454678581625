import { AlertPopUpsService } from "./../../../services/alert-pop-ups.service";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { OrganizationTypeOptionsModel } from "./../../../models/organization-type-options-model";
import { UtilitiesService } from "./../../../services/utilities.service";
import { OrganizationService } from "./../../../services/organization.service";
import { OrganizationBaseModel } from "./../../../models/organization-base-model";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { OrganizationTypes } from '../../../models/organization-types';

@Component({
  selector: "app-new-organization",
  templateUrl: "./new-organization.component.html",
  styleUrls: ["./new-organization.component.scss"]
})
export class NewOrganizationComponent implements OnInit {
  @Output() newOrgEmmitter: EventEmitter<
    OrganizationBaseModel
  > = new EventEmitter();

  constructor(
    private orgService: OrganizationService,
    private utilsService: UtilitiesService,
    public activeModal: NgbActiveModal,
    private alertPopUpService: AlertPopUpsService
  ) {}

  orgTypeOptions: OrganizationTypeOptionsModel = new OrganizationTypeOptionsModel();
  newOrgForm: FormGroup;
  newOrg: OrganizationBaseModel = new OrganizationBaseModel();

  isCreatingOrg: boolean = false;

  ngOnInit() {
    this.initOrgForm();

    this.newOrg = new OrganizationBaseModel();
  }

  initOrgForm() {
    this.newOrgForm = new FormGroup({
      orgName: new FormControl(null, [Validators.required]),

      //No Org type for now
      //orgType: new FormControl(null, [Validators.required])
    });
  }

  createNewOrg() {
    let formValidate = this.utilsService.generalFormValidate(this.newOrgForm);

    if (!formValidate) return;

    this.isCreatingOrg = true;

    this.newOrg.organizationName = this.newOrgForm.get("orgName").value;

    //No Org type for now
    //this.newOrg.organizationType = this.newOrgForm.get("orgType").value;
    this.newOrg.organizationType = "INDIVIDUAL" as unknown as OrganizationTypes;

    this.orgService
      .createOrganizationV2(
        this.newOrg.organizationType,
        this.newOrg.organizationName
      )
      .subscribe(
        (response: any) => {
          this.isCreatingOrg = false;
          this.newOrgEmmitter.emit(this.newOrg);

          this.closeCreatOrgModal();
        },
        (error: any) => {
          this.isCreatingOrg = true;
          this.alertPopUpService.openBasicPopUp(
            "Sorry!",
            "Create new organization Failed.",
            "error"
          );
        }
      );
  }

  closeCreatOrgModal(org: OrganizationBaseModel = null) {
    this.activeModal.close(org);
  }
}
