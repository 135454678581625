import { startWith, tap } from 'rxjs/operators';
import { SubscriptionLike } from 'rxjs';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AlertPopUpsService } from './../../../services/alert-pop-ups.service';
import { AircraftListV2Model } from './../../../models/aircraft-list-v2-model';
import { AircraftService } from './../../../services/aircraft.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

interface FilterSearchListI {
  concatText: string;
  index: number;
}


@Component({
  selector: 'app-list-aircraft-v2',
  templateUrl: './list-aircraft-v2.component.html',
  styleUrls: ['./list-aircraft-v2.component.scss']
})
export class ListAircraftV2Component implements OnInit, OnDestroy {

  constructor(
    private aircraftService: AircraftService,
    private popUpService: AlertPopUpsService,
    private formBuilder: FormBuilder,
  ) { }

  allAircraft: Array<AircraftListV2Model> = [];
  isWorking: boolean;

  filterSearchForm: FormGroup;
  filterQuery: string;

  filterUserListSubscriber$: SubscriptionLike;
  filterResultsLoading: boolean;

  filterSearchList: Array<FilterSearchListI>  = [];
  filterResult: Array<AircraftListV2Model> = [];


  ngOnInit() {

    this.isWorking = false;
    this.filterResultsLoading = false;

    this.getAllAircraft();

    this.filterSearchForm = this.formBuilder.group({
      'filterQuery': new FormControl(this.filterQuery)
    });

    this.filterUserListSubscriber$ = this.filterSearchForm.controls['filterQuery'].valueChanges.pipe(
      startWith(''),
      tap((x) => this.filterResultsLoading = true)
    ).subscribe(
      (query: string) => {
        this.filterQuery = query;
        if (!query) {
          // this.getAllAircraft();
        } else {
          this.filterAircraftListWithQuery();
        }
      }
    );
  }

  getAllAircraft() {
    this.isWorking = true;

    this.aircraftService.getAllAircraftV2().subscribe(
      (response: any) => {
        this.isWorking = false;
        this.allAircraft = response;

        this.filterSearchList = [];
        this.allAircraft.forEach(
          (el, i) => {
            let c = el.tailNo + ' '
              + ( el.aircraft_model ? el.aircraft_model.modelName : '' ) + ' '
              + ( el.aircraft_model && el.aircraft_model.aircraftMake ?
                  el.aircraft_model.aircraftMake.makeName : ''
                );

            let itm: FilterSearchListI = {
              concatText : c.toLowerCase(),
              index : i
            };

            this.filterSearchList.push(itm);
          }
        );
      },
      (error: any) => {
        this.isWorking = false;
        this.popUpService.openBasicPopUp(
          'Sorry!',
          'Can not fetch Aircraft list.',
          'error'
        );
      }
    );
  }

  filterAircraftListWithQuery() {
    this.filterResult = [];

    this.filterSearchList.forEach(
      el => {
        if (el.concatText.includes(this.filterQuery.toLowerCase())) {
          this.filterResult.push(this.allAircraft[el.index]);
        }
      }
    );

    // console.log(this.filterResult);
  }

  ngOnDestroy() {
    if (this.filterUserListSubscriber$) {
      this.filterUserListSubscriber$.unsubscribe();
    }
  }

}
