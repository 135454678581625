import { UtilitiesService } from './../../../services/utilities.service';
import { AircraftService } from './../../../services/aircraft.service';
import { ApplianceModel } from './../../../models/appliance-model';
import { PropellerModel } from './../../../models/propeller-model';
import { AircraftModel } from './../../../models/aircraft-model';
import { AllModelsService } from './../../../services/all-models.service';
import { NgbDateCustomAndUSAParserFormatter } from './../../../services/ngb-date-custom-and-usa-parser-formatter';
import { SubscriptionLike, of, Observable } from 'rxjs';
import { ServiceBulletinService } from './../../../services/service-bulletin.service';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ServiceBulletinModel } from './../../../models/service-bulletin-model';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { startWith, tap, debounceTime, distinctUntilChanged, switchMap, catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NgbDateNativeAdapter, NgbDateAdapter, NgbDatepickerConfig, NgbTypeaheadSelectItemEvent, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { adSbTypeOptionsModel } from '../../../models/ad-sb-type-options-model';
import { EngineModel } from '../../../models/engine-model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-manage-bulletins',
  templateUrl: './manage-bulletins.component.html',
  styleUrls: ['./manage-bulletins.component.scss'],
  providers: [
    NgbDatepickerConfig,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomAndUSAParserFormatter }
  ]
})
export class ManageBulletinsComponent implements OnInit, OnDestroy {

  constructor(
    private ngbDatePickerConfig: NgbDatepickerConfig,
    private formBuilder: FormBuilder,
    private serviceBulletinService: ServiceBulletinService,
    private allModelsService: AllModelsService,
    private aircraftService: AircraftService,
    private utilitiesService: UtilitiesService,
    private modalService: NgbModal,
    private elRef: ElementRef
  ) {
    ngbDatePickerConfig.minDate = { year: 1900, month: 1, day: 1 };
  }

  @ViewChild('modelFinderNew') modelFinderNew;
  @ViewChild('modelFinderEdit') modelFinderEdit;

  viewedPdfSrc: string;
  viewedPdfSbTitle: string;

  adSbTypeOptions: adSbTypeOptionsModel = new adSbTypeOptionsModel

  isWorking: boolean = false;

  inCreateNewBulletinMode: boolean = false;

  searchSubscriber$: SubscriptionLike;

  bulletinsLoading: boolean = false;

  newServiceBulletin: ServiceBulletinModel = new ServiceBulletinModel;
  newServiceBulletinForm: FormGroup;

  editServiceBulletin: ServiceBulletinModel = new ServiceBulletinModel;
  editServiceBulletinForm: FormGroup;

  allServiceBulletins: Array<ServiceBulletinModel> = [];
  page = 1;
  pageSize = 20;
  total = 0;

  serviceBulletinSearchForm: FormGroup;

  modelSearchFailed: boolean = false;
  modelSearching: boolean = false;


  sbNewModels: Array<AircraftModel | EngineModel | PropellerModel | ApplianceModel> = [];
  sbEditModels: Array<AircraftModel | EngineModel | PropellerModel | ApplianceModel> = [];

  ngOnInit() {
    this.newServiceBulletinForm = this.formBuilder.group({
      'bulletinName': new FormControl('', [Validators.required]),
      'subject': new FormControl('', [Validators.required]),
      'publishedDate': new FormControl(null, [Validators.required]),
      'adSbType': new FormControl(null, [Validators.required]),
      'mandatory': new FormControl(0),
      'isRecurring': new FormControl(0),
      'modelFinder': new FormControl(null)
    });

    this.serviceBulletinSearchForm = this.formBuilder.group({
      'queryString': new FormControl('')
    });

    this.searchSubscriber$ = this.serviceBulletinSearchForm.controls['queryString'].valueChanges.pipe(
      startWith(''),
      tap((x) => this.bulletinsLoading = true)
    ).subscribe(
      (query) => {
        this.resetPage();
        this.findServiceBulletinByName(query);
      }
    );
  }

  changePageSize($event: any) {
    this.pageSize = +($event.target.value) > 1 ? +($event.target.value) : this.pageSize;
    this.findServiceBulletinByName('');
  }

  get queryString() {
    return this.serviceBulletinSearchForm.controls['queryString'];
  }

  findServiceBulletinByName(query) {
    this.unLoadBulletinForEdit();
    this.bulletinsLoading = true;
    this.serviceBulletinService.findServiceBulletinByName(query, this.page, this.pageSize).subscribe(
      (response: any) => {
        if (+response.status.code === 1000) {
          this.allServiceBulletins = response.responseData.rows;
          this.total = response.responseData.count;
          this.bulletinsLoading = false;
        }
      }
    );
  }


  //// Type heads search functions for manufacturer
  modelTypeHeadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.modelSearching = true),
      switchMap(term => this.getModel(term, this.inCreateNewBulletinMode ?
        this.newServiceBulletinForm.get('adSbType').value : this.editServiceBulletinForm.get('adSbType').value)
      ),
      tap(() => this.modelSearching = false)
    )

  getModel(term, type: string) {

    const modelTypes = ['airframe', 'engine', 'propeller', 'appliance'];

    if (type && modelTypes.indexOf(type) < 0) {
      return of([]);
    }

    return this.allModelsService.findModelsByType(term, type).pipe(
      tap(() => this.modelSearchFailed = false),
      catchError(() => {
        this.modelSearchFailed = true;
        return of([]);
      }),
      map((res: any) => {
        const arr: Array<any> = [];

        // send new term as new Model
        let termModel = null;
        if (type == 'airframe') {
          termModel = new AircraftModel;
        }
        if (type == 'engine') {
          termModel = new EngineModel;
        }
        if (type == 'propeller') {
          termModel = new PropellerModel;
        }
        if (type == 'appliance') {
          termModel = new ApplianceModel;
        }

        term = term.trim();
        if (term) {
          termModel.id = null;
          termModel.modelName = term;
          arr.push(termModel);
        }
        ///////

        res.responseData.rows.forEach(element => {
          // set id of newly added item if it's in result
          if (termModel && (element.modelName == termModel.modelName)) {
            arr[0].id = element.id;
            termModel = null;
          }

          arr.push(element);
        });

        return arr;
      }),
    );
  }

  // format typeheads data received,  before display
  typeHeadModelFormatter = (modelObj: AircraftModel | EngineModel | PropellerModel | ApplianceModel) => {
    const modelObjName = modelObj.modelName;
    // let modelObjManufacturer = modelObj.aircraftMake? ': ' + modelObj.aircraftMake.makeName : '';
    return modelObjName;
  }

  getHighlightDisplayName = (r: AircraftModel | EngineModel | PropellerModel | ApplianceModel) => {
    return this.aircraftService.getHighlightDisplayName(r);
  }


  /**
   * @description get the bulletin Make names defined in its models by make return
   * @param {any} bulletin
   * @returns {string}
   */
   getBulletinModelMakes(bulletin: ServiceBulletinModel) {
    return this.aircraftService.getSbOrAdModelMakes(bulletin);
  }

  getModelManufacturerName(modelObj: AircraftModel | EngineModel | PropellerModel | ApplianceModel) {
    return this.aircraftService.getModelManufacturerName(modelObj);
  }

  getBulletinModelManufacturers(bulletin: ServiceBulletinModel) {
    return this.aircraftService.getSbOrAdModelManufacturers(bulletin);
  }

  // On Select typehead(Manufacturer) options...
  onTypeHeadSelected(ev: NgbTypeaheadSelectItemEvent) {
    /*this.addModelToNewList({
      id: ev.item.id,
      modelName: ev.item.modelName
    })*/
    this.addModelToNewList(ev.item);

    this.newServiceBulletinForm.controls['modelFinder'].setValue(null);
  }

  addModelToNewList(model) {
    if (!(this.sbNewModels.some(e => e.modelName === model.modelName))) {
      this.sbNewModels.push(model);
    }
    this.newServiceBulletinForm.get('modelFinder').setValue({modelName: null, id: null});
    this.modelFinderNew.nativeElement.value = {modelName: null, id: null};
  }

  removeModelFromNewList(modelIndex: number) {
    this.sbNewModels.splice(modelIndex, 1);
  }


  addModelToEditList(model) {
    if (!(this.sbEditModels.some(e => e.modelName === model.modelName))) {
      this.sbEditModels.push(model);
    }
    this.editServiceBulletinForm.get('modelFinder').setValue({modelName: null, id: null});
    this.modelFinderEdit.nativeElement.value = {modelName: null, id: null};
  }

  removeModelFromEditList(modelIndex: number) {
    this.sbEditModels.splice(modelIndex, 1);
  }


  onSbTypeChange(ev, mode: 'new' | 'edit') {
    const listLength: boolean = mode == 'new' ? (this.sbNewModels.length > 0) : this.sbEditModels.length > 0;

    if (listLength) {
      Swal.fire({
        title: 'Clear all selected models?',
        text: 'Changing the type will clear all selected models.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes. Change Type!',
        cancelButtonText: 'No, cancel action.'
      }).then((descision) => {
        if (descision.value) {
          if (mode == 'new') {
            this.sbNewModels = [];
          }
          if (mode == 'edit') {
            this.sbEditModels = [];
          }
        } else if (descision.dismiss === Swal.DismissReason.cancel) {
          // todo: KEEP ADSBTYPE UNCHANGED
        }
      });
    }
  }


  // On Select typehead(Model) options...
  onTypeHeadEditSelected(ev: NgbTypeaheadSelectItemEvent) {
    /*
    this.addModelToEditList({
      id: ev.item.id,
      modelName: ev.item.modelName
    })
    */
    this.addModelToEditList(ev.item);

  }

  toggleInCreateNewBulletinMode() {
    this.inCreateNewBulletinMode = !this.inCreateNewBulletinMode;
    if (!this.inCreateNewBulletinMode) {
      this.newServiceBulletinForm.reset();
      this.sbNewModels = [];

      // All Magneto Equipped Engines //MSB 94-8C
    }
    this.sbNewModels = [];
  }


  doCreateServiceBulletin() {
    if (!this.newServiceBulletinForm.valid) {
      Object.values(this.newServiceBulletinForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      Swal.fire('Check important fields!', 'Form not filled correctly!', 'error');
      return;
    }

    this.isWorking = true;

    this.newServiceBulletin.bulletinName = this.newServiceBulletinForm.controls['bulletinName'].value;
    this.newServiceBulletin.subject = this.newServiceBulletinForm.controls['subject'].value;
    this.newServiceBulletin.publishedDate = this.newServiceBulletinForm.controls['publishedDate'].value;

    this.newServiceBulletin.adSbType = this.newServiceBulletinForm.controls['adSbType'].value;
    this.newServiceBulletin.mandatory = this.newServiceBulletinForm.controls['mandatory'].value;
    this.newServiceBulletin.isRecurring = this.newServiceBulletinForm.controls['isRecurring'].value;


    if (this.newServiceBulletin.adSbType == 'airframe') {
      this.newServiceBulletin.aircraftModels = JSON.parse(JSON.stringify(this.sbNewModels)) as Array<AircraftModel>;
    }
    if (this.newServiceBulletin.adSbType == 'engine') {
      this.newServiceBulletin.engineModels = JSON.parse(JSON.stringify(this.sbNewModels)) as Array<EngineModel>;
    }
    if (this.newServiceBulletin.adSbType == 'propeller') {
      this.newServiceBulletin.propellerModels = JSON.parse(JSON.stringify(this.sbNewModels)) as Array<PropellerModel>;
    }
    if (this.newServiceBulletin.adSbType == 'appliance') {
      this.newServiceBulletin.applianceModels = JSON.parse(JSON.stringify(this.sbNewModels)) as Array<ApplianceModel>;
    }

    this.serviceBulletinService.createServiceBulletin(this.newServiceBulletin).subscribe(
      (response: any) => {
        this.isWorking = false;

        const newBulletinName = this.newServiceBulletin.bulletinName;

        if (+response.status.code === 1000) {
          this.newServiceBulletinForm.reset(); // reset create form
          this.inCreateNewBulletinMode = false; // exit create bulletin mode
          this.resetPage();
          this.sbNewModels = [];

          // filter list ny new bulletin name, to show new bulletin
          this.serviceBulletinSearchForm.controls['queryString'].setValue(newBulletinName);

          // this.findServiceBulletinByName(''); // re-fetch all service bulletins
          // this.serviceBulletinSearchForm.controls['queryString'].setValue(''); // set search filter field to empty.just incase
          Swal.fire('Success!', 'New Service bulletin created!', 'success');
        }  else {
          Swal.fire(
            'Oops!', 'Failed to create Service Bulletin!', 'warning'
          );
        }
      },
      (error: any) => {
        this.isWorking = false;

        if (error.status.message) {
          Swal.fire('Yikes!', error.status.message, 'error');
        } else {
          Swal.fire('Error!', 'Failed to create Service bulletin.', 'error');
        }
      }
    );
  }

  doUpdateServiceBulletin() {
    if (!this.editServiceBulletinForm.valid) {
      Object.values(this.editServiceBulletinForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      Swal.fire('Check important fields!', 'Form not filled correctly!', 'error');
      return;
    }

    this.isWorking = true;

    this.editServiceBulletin.bulletinName = this.editServiceBulletinForm.controls['bulletinName'].value;
    this.editServiceBulletin.subject = this.editServiceBulletinForm.controls['subject'].value;
    this.editServiceBulletin.publishedDate = this.editServiceBulletinForm.controls['publishedDate'].value;

    this.editServiceBulletin.adSbType = this.editServiceBulletinForm.controls['adSbType'].value;
    this.editServiceBulletin.mandatory = this.editServiceBulletinForm.controls['mandatory'].value;
    this.editServiceBulletin.isRecurring = this.editServiceBulletinForm.controls['isRecurring'].value;

    if (this.editServiceBulletin.adSbType == 'airframe') {
      this.editServiceBulletin.aircraftModels = JSON.parse(JSON.stringify(this.sbEditModels ))as Array<AircraftModel>;
    }
    if (this.editServiceBulletin.adSbType == 'engine') {
      this.editServiceBulletin.engineModels = JSON.parse(JSON.stringify(this.sbEditModels)) as Array<EngineModel>;
    }
    if (this.editServiceBulletin.adSbType == 'propeller') {
      this.editServiceBulletin.propellerModels = JSON.parse(JSON.stringify(this.sbEditModels)) as Array<PropellerModel>;
    }
    if (this.editServiceBulletin.adSbType == 'appliance') {
      this.editServiceBulletin.applianceModels = JSON.parse(JSON.stringify(this.sbEditModels)) as Array<ApplianceModel>;
    }

    this.serviceBulletinService.updateServiceBulletin(this.editServiceBulletin).subscribe(
      (response: any) => {
        this.isWorking = false;

        if (+response.status.code === 1000) {
          // this.findServiceBulletinByName(''); // re-fetch all service bulletins
          // set search filter field to sb name. just incase
          // if (!this.serviceBulletinSearchForm.controls['queryString'].value) {
          //   this.resetPage();
          //   this.serviceBulletinSearchForm.controls['queryString'].setValue(this.editServiceBulletin.bulletinName);
          // }

          this.editServiceBulletinForm.reset(); // reset create form
          this.editServiceBulletin = new ServiceBulletinModel; // exit create bulletin mode
          Swal.fire('Great!', 'Service bulletin updated!', 'success');
        } else {
          Swal.fire(
            'Oops!', 'Failed to update Service Bulletin!', 'warning'
          );
        }
      },
      (error: any) => {
        // filter list to show current edited item
        this.serviceBulletinSearchForm.get('queryString').setValue(this.editServiceBulletin.bulletinName);

        this.isWorking = false;
        if (error.status.message) {
          Swal.fire('Yikes!', error.status.message, 'error');
        } else {
          Swal.fire('Error!', 'Failed to update Service bulletin.', 'error');
        }
      }
    );
  }

  loadBulletinForEdit(bulletin: ServiceBulletinModel) {
    this.editServiceBulletin = JSON.parse(JSON.stringify(bulletin));

    if (bulletin.adSbType == 'airframe') {
      this.sbEditModels = JSON.parse(JSON.stringify(bulletin.aircraftModels));
    }
    if (bulletin.adSbType == 'engine') {
      this.sbEditModels = JSON.parse(JSON.stringify(bulletin.engineModels));
    }
    if (bulletin.adSbType == 'propeller') {
      this.sbEditModels = JSON.parse(JSON.stringify(bulletin.propellerModels));
    }
    if (bulletin.adSbType == 'appliance') {
      this.sbEditModels = JSON.parse(JSON.stringify(bulletin.applianceModels));
    }

    this.editServiceBulletin.aircraftModels = [];
    this.editServiceBulletin.engineModels = [];
    this.editServiceBulletin.propellerModels = [];
    this.editServiceBulletin.applianceModels = [];


    this.editServiceBulletinForm = new FormGroup({});

    this.editServiceBulletinForm = this.formBuilder.group({
      'bulletinName': new FormControl(
        this.editServiceBulletin.bulletinName, [Validators.required]
      ),
      'subject': new FormControl(
        this.editServiceBulletin.subject, [Validators.required]
      ),
      'publishedDate': new FormControl(
        this.editServiceBulletin.publishedDate ? new Date(this.editServiceBulletin.publishedDate) : null,
        [Validators.required]
      ),
      'adSbType': new FormControl(
        this.editServiceBulletin.adSbType ? this.editServiceBulletin.adSbType : null,
        [Validators.required]
      ),
      'modelFinder': new FormControl(null),
      'mandatory': new FormControl(
        this.editServiceBulletin.mandatory
      ),
      'isRecurring': new FormControl(
        this.editServiceBulletin.isRecurring
      )
    });
  }

  unLoadBulletinForEdit() {
    this.editServiceBulletin = new ServiceBulletinModel;
    if (this.editServiceBulletinForm) {
      this.editServiceBulletinForm.reset();
    }
    this.sbEditModels = [];
  }

  resetPage() {
    this.page = 1;
  }

  onPaginationChange($event) {
    this.page = $event;
    this.findServiceBulletinByName(this.serviceBulletinSearchForm.controls['queryString'].value);
  }



  openModal(content, pdfUrl: string, title: string = 'View Bulletin') {
    this.viewedPdfSrc = pdfUrl;
    this.viewedPdfSbTitle = title;

    this.modalService.open(content, { size: 'lg' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  ngOnDestroy() {
    if (this.searchSubscriber$) {
      this.searchSubscriber$.unsubscribe();
    }
  }

  deleteServiceBulletin(bulletin: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: `Please confirm you want to delete bulletin ${bulletin['id']}?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes. I know what I\'m doing!',
      cancelButtonText: 'No, take meback.'
    }).then((decision) => {
      if (decision.value) {
        this.serviceBulletinService.deleteGlobalServiceBulletin(bulletin['id'])
          .subscribe((response) => {
            // console.log(response);
            if (+response['status']['code'] === 1000) {
              this.editServiceBulletin = new ServiceBulletinModel();
              this.resetPage();
              this.findServiceBulletinByName('');
              Swal.fire(
                'Deleted!', 'Global Service Bulletin deleted from.', 'success'
              );
            } else {
              Swal.fire('Yikes!', 'Global Service Bulletin not deleted.', 'error');
            }
          }, (error) => {
            if (error['status']['message']) {
              Swal.fire(
                error['status']['message'],
                error['responseData'].join(' '),
                'error'
              );
            } else {
              Swal.fire(
                'Oops!',
                'Failed to delete Service Bulletin.',
                'error'
              );
            }
          });
      } else if (decision.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });
  }

  getSixMonthsPrior(publishedDate: string) {
    return this.utilitiesService.sixMonthsPrior(publishedDate);
  }

}
