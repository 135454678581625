<section class="p-3 admin-maintenance">

  <div class="card shadow border-0">

    <app-preloader-type-a *ngIf="isLoading"></app-preloader-type-a>

    <div *ngIf="!isLoading">
      <div class="card-header clearfix">
        <h6 class="mb-0">
          <i class="la la-calendar"></i>
          Pilot Dates

          <span class="float-right">
            <button *ngIf="!inUpdateMode" class="btn btn-sm btn-outline-dark rounded" (click)="loadForUpdateDashDatesForm()">
              Update Pilot Dates  <i class="la la-edit"></i>
            </button>

            <button *ngIf="inUpdateMode" class="btn btn-sm btn-outline-dark rounded" (click)="cancelPostUserPilotDates()">
              Cancel  <i class="la la-times"></i>
            </button>
          </span>
        </h6>

      </div>
      <div class="card-body">
        <div class="card my-3 bg-light {{inUpdateMode? 'border-danger': ''}} rounded">
          <div class="card-body">
            <div class="card-body">
              <form [formGroup]="pilotDatesForm" (ngSubmit)="postUserPilotDates()">
                <div>
                  <div class="row">
                    <div class="col-sm-12 col-lg-6 col-md-12">
                      <div class="form-group">
                        <h6 class="form-text text-muted {{inUpdateMode? 'required' : ''}}">
                          <i class="la la-heart-o"></i> Last Medical
                        </h6>
                        <ng-container *ngIf="inUpdateMode">
                          <div class="input-group">
                            <input type="text" formControlName="lastMedicalDate" class="form-control" id="lastMedicalDate" placeholder="mm/dd/yyyy" ngbDatepicker #lastMedicalDate="ngbDatepicker">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="lastMedicalDate.toggle()" type="button">
                                <i class="la la-calendar-plus-o"></i>
                              </button>
                            </div>
                          </div>
                          <small class="form-text text-danger d-block"
                            *ngIf="pilotDatesForm.get('lastMedicalDate').invalid && (pilotDatesForm.get('lastMedicalDate').touched || pilotDatesForm.get('lastMedicalDate').dirty)">
                            Enter Last Medical
                          </small>

                          <small class="form-text text-danger d-block"
                            *ngIf="pilotDatesForm.hasError('hasDependant', 'lastMedicalDate')">
                            Please fill the Medical Due Date.
                          </small>
                        </ng-container>

                        <ng-container *ngIf="!inUpdateMode">
                          <div>
                            <h5 class="ml-3">{{ pilotDates.lastMedicalDate?( pilotDates.lastMedicalDate | date:'MM/dd/yyyy') : "--" }}</h5>
                          </div>
                        </ng-container>
                      </div>
                    </div>


                    <div class="col-sm-12 col-lg-6 col-md-12">
                      <div class="form-group">
                        <h6 class="form-text text-muted {{inUpdateMode? 'required' : ''}}">
                          <i class="la la-heart-o"></i> Medical Due Date
                        </h6>
                        <ng-container *ngIf="inUpdateMode">
                          <div class="input-group">
                            <input type="text" formControlName="medicalDueDate" class="form-control" id="medicalDueDate" placeholder="mm/dd/yyyy" ngbDatepicker #medicalDate="ngbDatepicker">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="medicalDate.toggle()" type="button">
                                <i class="la la-calendar-plus-o"></i>
                              </button>
                            </div>
                          </div>
                          <small class="form-text text-danger d-block"
                            *ngIf="pilotDatesForm.get('medicalDueDate').invalid && (pilotDatesForm.get('medicalDueDate').touched || pilotDatesForm.get('medicalDueDate').dirty)">
                            Enter Medical Due Date
                          </small>

                          <small class="form-text text-danger d-block"
                            *ngIf="pilotDatesForm.hasError('hasDependant', 'medicalDueDate')">
                            Please fill the Last Medical Date.
                          </small>

                          <small class="form-text text-danger d-block"
                            *ngIf="pilotDatesForm.hasError('timeInGtTimeOut', 'medicalDueDate')">
                            Due Date must be greater than Medical Date.
                          </small>
                        </ng-container>

                        <ng-container *ngIf="!inUpdateMode">
                          <div>
                            <h5 class="ml-3">{{ pilotDates.medicalDueDate?( pilotDates.medicalDueDate | date:'MM/dd/yyyy') : "--" }}</h5>
                          </div>
                        </ng-container>
                      </div>
                    </div>

                    <hr>

                    <div class="col-sm-12 col-lg-6 col-md-12">
                      <div class="form-group">
                        <h6 class="form-text text-muted {{inUpdateMode? 'required' : ''}}">
                          <i class="la la-plane"></i> Last Flight Review
                        </h6>
                        <ng-container *ngIf="inUpdateMode">
                          <div class="input-group">
                            <input class="form-control" formControlName="lastFlightReviewDate"  placeholder="mm/dd/yyyy" name="lastFlightReviewDate" ngbDatepicker #flightCheckDate="ngbDatepicker">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="flightCheckDate.toggle()" type="button">
                                <i class="la la-calendar-plus-o"></i>
                              </button>
                            </div>
                          </div>
                          <small class="form-text text-danger d-block"
                            *ngIf="pilotDatesForm.get('lastFlightReviewDate').invalid && (pilotDatesForm.get('lastFlightReviewDate').touched || pilotDatesForm.get('lastFlightReviewDate').dirty)">
                            Enter Last  Flight Review
                          </small>

                          <small class="form-text text-danger d-block"
                            *ngIf="pilotDatesForm.hasError('hasDependant', 'lastFlightReviewDate')">
                            Please fill the  Flight Review Date.
                          </small>
                        </ng-container>

                        <ng-container *ngIf="!inUpdateMode">
                          <div class="input-group">
                            <h5 class="ml-3">{{ pilotDates.lastFlightReviewDate ? (pilotDates.lastFlightReviewDate | date:'MM/dd/yyyy') : "--"  }}</h5>
                          </div>
                        </ng-container>
                      </div>
                    </div>

                    <div class="col-sm-12 col-lg-6 col-md-12">
                      <div class="form-group">
                        <h6 class="form-text text-muted {{inUpdateMode? 'required' : ''}}">
                          <i class="la la-plane"></i> Flight Review Due
                        </h6>
                        <ng-container *ngIf="inUpdateMode">
                          <div class="input-group">
                            <input class="form-control" formControlName="flightReviewDueDate"  placeholder="mm/dd/yyyy" name="flightReviewDueDate" ngbDatepicker #flightReviewDate="ngbDatepicker">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="flightReviewDate.toggle()" type="button">
                                <i class="la la-calendar-plus-o"></i>
                              </button>
                            </div>
                          </div>
                          <small class="form-text text-danger d-block"
                            *ngIf="pilotDatesForm.get('flightReviewDueDate').invalid && (pilotDatesForm.get('flightReviewDueDate').touched || pilotDatesForm.get('flightReviewDueDate').dirty)">
                            Enter Flight Review Due
                          </small>

                          <small class="form-text text-danger d-block"
                            *ngIf="pilotDatesForm.hasError('hasDependant', 'flightReviewDueDate')">
                            Please fill the Last Flight Review Date.
                          </small>

                          <small class="form-text text-danger d-block"
                            *ngIf="pilotDatesForm.hasError('timeInGtTimeOut', 'flightReviewDueDate')">
                            Review Due Date must be greater than Last Flight Review Date.
                          </small>
                        </ng-container>

                        <ng-container *ngIf="!inUpdateMode">
                          <div class="input-group">
                            <h5 class="ml-3">{{ pilotDates.flightReviewDueDate ? (pilotDates.flightReviewDueDate | date:'MM/dd/yyyy') : "--"  }}</h5>
                          </div>
                        </ng-container>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="text-right mt-2" *ngIf="inUpdateMode">
                  <button class="btn btn-outline-danger mr-3" (click)="cancelPostUserPilotDates()">Cancel</button>
                  <button type="submit" class="btn btn-outline-primary">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
