<section id="login-form" class="container-fluid vh-100 pt-3">
    <div class="login_form centered-forms align-middle mt-3 ml-auto mr-auto form-group">

      <div class="card shadow border-0 rounded">
        <div class="card-body">
          <div class="text-center my-2">
            <img class="mw-100" src="assets/images/crew-chief-full-color.jpg" alt="Crewchief Logo">
          </div>

          <ng-container *ngIf="resetEmailSent">
            <div class="text-center px-3 py-2">
              A reset email link has been sent to your email. <br/>
              Kindly check your email and follow the link to reset your password.
            </div>
          </ng-container>

          <ng-container *ngIf="!resetEmailSent">
            <h6 class="text-center text-muted required">Forgot password</h6>

            <form [formGroup]="forgotPasswordForm" (ngSubmit)="doForgotPassword()">
              <div class="form-group">
                <!--<small class="text-muted d-block">Email address</small>-->
                <input type="email" name="email" formControlName="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email address">
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-primary w-100 rounded">Reset Password</button>
              </div>
            </form>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="text-center mt-3">
      Return to <a [routerLink]="['/login']">Login</a>
    </div>
  </section>
