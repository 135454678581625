import { VorCheckModel } from './../../../../models/vor-check-model';
import { FlightLogsService } from './../../../../services/flight-logs.service';
import { AircraftMaintenanceService } from './../../../../services/aircraft-maintenance.service';
import { AircraftDashboardReferencesModel } from './../../../../models/aircraft-dashboard-references-model';
import { UtilitiesService } from './../../../../services/utilities.service';
import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { RagType } from './../../../../models/rag-type';
import { NavDataUpdateModel } from '../../../../models/nav-data-update-model';
import * as moment from 'moment';
import { AircraftV2Model } from '../../../../models/airfcraftV2/aircraft-v2.model';

@Component({
  selector: 'app-dashboard-ifr-equipment-status',
  templateUrl: './dashboard-ifr-equipment-status.component.html',
  styleUrls: ['./dashboard-ifr-equipment-status.component.scss']
})
export class DashboardIfrEquipmentStatusComponent implements OnInit, OnChanges {
  @Input() theAircraft: AircraftV2Model;
  @Input() statusOnly: boolean;

  @Output() isCompleted: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    private utilitiesService: UtilitiesService,
    private flightLogsService: FlightLogsService
  ) { }

  isLoading: boolean;
  dashReferences: AircraftDashboardReferencesModel;

  todayFlightDateMoment: moment.Moment = moment(new Date());
  serverEpochTime: number = new Date().getTime();

  isLoadingNavaData: boolean;
  lastNavDataUpdate: NavDataUpdateModel = new NavDataUpdateModel;
  navDataIsEffective: boolean;
  navDataUpdateNextDueDateMoment: moment.Moment = null;
  navDataUpdateRagStatus: RagType = null;
  navDataDone: boolean;

  isLoadingVorCheck: boolean;
  lastVORCheck: VorCheckModel = new VorCheckModel;
  vorCheckNextDueDateMoment: moment.Moment = null;
  vorCheckRagStatus: RagType = null;
  vorCheckDone: boolean;

  isLoadingPitotic: boolean;
  lastPitoticDate: string;
  pitotStaticNextDueDateMoment: moment.Moment = null;
  pitotStaticRagStatus: RagType = null;
  pitoticDone: boolean;

  isLoadingTransponder: boolean;
  lastTransponderDate: string;
  transponderNextDueDateMoment: moment.Moment = null;
  transponderRagStatus: RagType = null;
  transponderDone: boolean;

  isLoadingAltimeter: boolean;
  lastAltimeterDate: string;
  altimeterNextDueDateMoment: moment.Moment = null;
  altimeterRagStatus: RagType = null;
  altimeterDone: boolean;


  ngOnInit() {
    this.getReferenceData();
  }


  ngOnChanges (changes: SimpleChanges) {
    if (changes.theAircraft && changes.theAircraft.currentValue) {
      const newAircraft = changes.theAircraft.currentValue;

      if (newAircraft.id && newAircraft.id !== this.theAircraft.id) {
        this.theAircraft = newAircraft;
        this.getReferenceData();
      }
    }
  }


  getReferenceData() {
    this.emitCompleteStatus();
  
    this.isLoading = true;
    this.navDataIsEffective = false;

    this.isLoadingNavaData = true;
    this.navDataDone = false;

    this.isLoadingVorCheck = true;
    this.vorCheckDone = false;

    this.isLoadingPitotic = true;
    this.pitoticDone = false;

    this.isLoadingTransponder = true;
    this.transponderDone = false;

    this.isLoadingAltimeter = true;
    this.altimeterDone = false;

    if (this.theAircraft.id) {
      this.utilitiesService.getAircraftDashboardReferences().subscribe(
        (response: any) => {
          // this.isLoading = true;

          if (+response.status.code === 1000) {
            this.dashReferences = response.responseData;
            this.todayFlightDateMoment = moment(this.dashReferences.serverNow);
            this.serverEpochTime = this.utilitiesService.parseISOtoDateObj(this.dashReferences.serverNow).getTime();

            this.getAllMaintenanceStats();
          }
        }
      );
    }
  }


  emitCompleteStatus() {
    this.isCompleted.emit(
      this.vorCheckDone && this.transponderDone && this.pitoticDone && this.navDataDone && this.altimeterDone
    );
  }


  getAllMaintenanceStats() {
    this.isLoading = false;
    this.getAllAircraftNavDataUpdates();
    this.getAllAircraftVorChecks();
    this.getLastMAircraftPitotics();
    this.getLastMAircraftTransponders();
    this.getLastMAircraftAltimeters();
  }

  getAllAircraftNavDataUpdates() {
    this.isLoadingNavaData = true;

    this.navDataDone = false;
    this.flightLogsService.getAllAircraftNavDataUpdates(this.theAircraft.id).subscribe(
      (response: any) => {
        this.isLoadingNavaData = false;

        if (+response.status.code === 1000) {
          const allNavDataUpdates = response.responseData.rows;

          if (allNavDataUpdates.length > 0) {
            this.lastNavDataUpdate = allNavDataUpdates[0];
          } else {
            this.lastNavDataUpdate = null;

            this.navDataDone = true;
            return;
          }

          const navEffectDateMoment = this.lastNavDataUpdate.effectiveDate ? moment(this.lastNavDataUpdate.effectiveDate) : null;

          const effectiveDateDiff = this.lastNavDataUpdate.effectiveDate ?
            navEffectDateMoment.diff(this.todayFlightDateMoment, 'days') : null;

          if (!this.lastNavDataUpdate.effectiveDate || effectiveDateDiff > 0) {
            // effective date has not started; nav data is not effective
            this.navDataIsEffective = false;
          } else {
            // effective date has started; nav data is effective
            this.navDataIsEffective = true;
          }

          /*let nextDueDateManipulator = moment(this.lastNavDataUpdate.expireDate);
          //Get due date
          nextDueDateManipulator.add(this.dashReferences.ifrRefNavDataUpdateDueDays, 'days');
          */
         const nextDueDateManipulator = this.lastNavDataUpdate.expireDate ? moment(this.lastNavDataUpdate.expireDate) : null;

          this.navDataUpdateNextDueDateMoment = nextDueDateManipulator ? nextDueDateManipulator.clone() : null;

          if (!(this.navDataIsEffective || nextDueDateManipulator)) {
            this.navDataUpdateRagStatus = RagType.a_red;

            this.navDataDone = true;
            return;
          }
          // Warning
          const nextWarningManipulator = nextDueDateManipulator.clone();
          nextWarningManipulator.subtract(this.dashReferences.ifrRefNavDataUpdateWarningDays, 'days');

          const dueDiff = this.todayFlightDateMoment.diff(nextDueDateManipulator, 'seconds');
          const warningDiff = this.todayFlightDateMoment.diff(nextWarningManipulator, 'seconds');

          if ( dueDiff >= 0) {
            this.navDataUpdateRagStatus = RagType.a_red;
          } else if (warningDiff >= 0) {
            this.navDataUpdateRagStatus = RagType.b_amber;
          } else {
            this.navDataUpdateRagStatus = RagType.c_green;
          }

          this.navDataDone = true;
        }
      }
    );
  }

  getAllAircraftVorChecks() {
    this.isLoadingVorCheck = true;

    this.vorCheckDone = false;
    this.flightLogsService.getAllAircraftVorChecks(this.theAircraft.id).subscribe(
      (response: any) => {
        this.isLoadingVorCheck  = false;

        if (+response.status.code === 1000) {
          const allVORChecks: Array<VorCheckModel> = response.responseData.rows;

          if (allVORChecks.length > 0) {
            this.lastVORCheck = allVORChecks[0];
          } else {
            this.lastVORCheck = null;

            this.vorCheckDone = true;
            return;
          }

          const nextDueDateManipulator = moment(this.lastVORCheck.createdAt);

          // set date due date
          nextDueDateManipulator.add(this.dashReferences.ifrRefVORCheckDueDays, 'days');

          this.vorCheckNextDueDateMoment = nextDueDateManipulator.clone();

          // Warning
          const nextWarningManipulator = nextDueDateManipulator.clone();
          nextWarningManipulator.subtract(this.dashReferences.ifrRefVORCheckWarningDays, 'days');

          const dueDiff = this.todayFlightDateMoment.diff(nextDueDateManipulator, 'seconds');
          const warningDiff = this.todayFlightDateMoment.diff(nextWarningManipulator, 'seconds');

          if (dueDiff >= 0) {
            this.vorCheckRagStatus = RagType.a_red;
          } else if (warningDiff >= 0) {
            this.vorCheckRagStatus = RagType.b_amber;
          } else {
            this.vorCheckRagStatus = RagType.c_green;
          }

          this.vorCheckDone = true;

        }
      }
    );
  }

  getLastMAircraftPitotics() {
    this.isLoadingPitotic = false;

    this.pitoticDone = false;

    this.lastPitoticDate = this.theAircraft.maintenanceStatus.pitotics.date;

    if (!this.lastPitoticDate) {

      this.pitoticDone = true;
      return;
    }

    const nextDueDateManipulator = moment(this.lastPitoticDate);

    // Get Due date
    nextDueDateManipulator.add(this.dashReferences.ifrRefPitotStaticDueMonths, 'month');
    //
    nextDueDateManipulator.endOf('month');

    this.pitotStaticNextDueDateMoment = nextDueDateManipulator.clone();

    // Warning
    const nextWarningManipulator = nextDueDateManipulator.clone();
    nextWarningManipulator.subtract(this.dashReferences.ifrRefPitotStaticWarningDays, 'days');

    const dueDiff = this.todayFlightDateMoment.diff(nextDueDateManipulator, 'seconds');
    const warningDiff = this.todayFlightDateMoment.diff(nextWarningManipulator, 'seconds');

    if (dueDiff >= 0) {
      this.pitotStaticRagStatus = RagType.a_red;
    } else if (warningDiff >= 0) {
      this.pitotStaticRagStatus = RagType.b_amber;
    } else {
      this.pitotStaticRagStatus = RagType.c_green;
    }

    this.pitoticDone = true;
  }

  getLastMAircraftTransponders() {
    this.isLoadingTransponder = false;

    this.transponderDone = false;

    this.lastTransponderDate = this.theAircraft.maintenanceStatus.transponders.date;

    if (!this.lastTransponderDate) {

      this.transponderDone = true;
      return;
    }

    const nextDueDateManipulator = moment(this.lastTransponderDate);

    // Get due date
    nextDueDateManipulator.add(this.dashReferences.ifrRefTransponderDueMonths, 'months');
    //
    nextDueDateManipulator.endOf('month');

    this.transponderNextDueDateMoment = nextDueDateManipulator.clone();

    // Warning
    const nextWarningManipulator = nextDueDateManipulator.clone();
    nextWarningManipulator.subtract(this.dashReferences.ifrRefTransponderWarningDays, 'days');

    const dueDiff = this.todayFlightDateMoment.diff(nextDueDateManipulator, 'seconds');
    const warningDiff = this.todayFlightDateMoment.diff(nextWarningManipulator, 'seconds');

    if (dueDiff >= 0) {
      this.transponderRagStatus = RagType.a_red;
    } else if (warningDiff >= 0) {
      this.transponderRagStatus = RagType.b_amber;
    } else {
      this.transponderRagStatus = RagType.c_green;
    }

    this.transponderDone = true;
  }

  getLastMAircraftAltimeters() {
    this.isLoadingAltimeter = false;

    this.altimeterDone = false;

    this.lastAltimeterDate = this.theAircraft.maintenanceStatus.altimeters.date;

    if (!this.lastAltimeterDate) {

      this.altimeterDone = true;
      return;
    }

    const nextDueDateManipulator = moment(this.lastAltimeterDate);

    // Get due date
    nextDueDateManipulator.add(this.dashReferences.ifrRefAltimeterDueMonths, 'months');
    //
    nextDueDateManipulator.endOf('month');

    this.altimeterNextDueDateMoment = nextDueDateManipulator.clone();

    // Warning
    const nextWarningManipulator = nextDueDateManipulator.clone();
    nextWarningManipulator.subtract(this.dashReferences.ifrRefAltimeterDueMonths, 'days');

    const dueDiff = this.todayFlightDateMoment.diff(nextDueDateManipulator, 'seconds');
    const warningDiff = this.todayFlightDateMoment.diff(nextWarningManipulator, 'seconds');

    if ( dueDiff >= 0) {
      this.altimeterRagStatus = RagType.a_red;
    } else if (warningDiff >= 0) {
      this.altimeterRagStatus = RagType.b_amber;
    } else {
      this.altimeterRagStatus = RagType.c_green;
    }

    this.altimeterDone = true;
  }

  getRagColor(val: number) {
    if (val === null) {
      return ' ';
      // return RagType[RagType.a_red];
    }

    return RagType[val];
  }

  getIfrStatusBadgeColor() {
    this.emitCompleteStatus();

    if (this.vorCheckDone && this.transponderDone && this.pitoticDone && this.navDataDone && this.altimeterDone ) {
      const statusArray = [
        this.vorCheckRagStatus,
        this.transponderRagStatus,
        this.pitotStaticRagStatus,
        this.navDataUpdateRagStatus,
        this.altimeterRagStatus
      ];

      statusArray.sort();

      return this.getRagColor(statusArray[0]);
    } else {
      return '';
    }
  }

  formatMomentAsUsDateString(momentObject: moment.Moment) {
    return this.utilitiesService.formatMomentAsUsDateString(momentObject);
  }

  getMomentBetweenDates(lowerTimeMoment: moment.Moment, higherTimeMoment: moment.Moment) {
    return this.utilitiesService.getMomentBetweenDates(lowerTimeMoment, higherTimeMoment);
  }

  getHoursRemainingBetweenHours(lowerHours: number, higherHours: number) {
    return this.utilitiesService.getHoursRemainingBetweenHours(lowerHours, higherHours);
  }


}

