import { AuthService } from './../../../services/auth.service';
import { UserService } from './../../../services/user.service';
import { UserModel } from '../../../models/user-model';
import { UtilitiesService } from './../../../services/utilities.service';
import { SubscriptionLike } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserOrganizationAndRole } from '../../../models/user-organization-and-role';
import { OrganizationService } from '../../../services/organization.service';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private utilitiesService: UtilitiesService,
    private userService: UserService,
    private orgService: OrganizationService,
    private authService: AuthService,
  ) { }

  isSideBarCollapsed: boolean;
  currentUser: UserModel;

  selectedOrg: UserOrganizationAndRole = new UserOrganizationAndRole;
  selectedOrg$: SubscriptionLike;


  private isSideBarCollapsed$: SubscriptionLike;
  private currentUser$: SubscriptionLike;

  topHeaderTitle: string = '';
  private topHeaderTitle$: SubscriptionLike;

  ngOnInit() {
    this.orgService.resetUserMenuOrg();

    /**top menu observer */
    this.topHeaderTitle$ = this.utilitiesService.topHeaderTitle$.subscribe(
      (val) => {
        this.topHeaderTitle = val;
      }
    );

    /**Side bar observer */
    this.isSideBarCollapsed$ = this.utilitiesService.isSideBarCollapsed$.subscribe(
      (val) => {
        this.isSideBarCollapsed = val;
      }
    );

    this.currentUser$ = this.userService.currentUser$.subscribe(
      (user) => {
        this.currentUser = user;

        if (this.currentUser.emailAddress) {
          this.orgService.getUserOrganizationsMenuList(this.currentUser.emailAddress);
        }
      }
    );

    this.selectedOrg$ = this.orgService.selectedOrg$.subscribe(
      (org: UserOrganizationAndRole) => {
        this.selectedOrg = org;
      }
    );
  }

  toggleSideBar() {
    this.utilitiesService.toggleSideBar();
  }

  logOutUser() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  ngOnDestroy() {
    if (this.isSideBarCollapsed$) {
      this.isSideBarCollapsed$.unsubscribe();
    }

    if (this.currentUser$) {
      this.currentUser$.unsubscribe();
    }

    if (this.topHeaderTitle$) {
      this.topHeaderTitle$.unsubscribe();
    }
  }

}

