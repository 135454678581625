<div class="card shadow border-0 aircraft-all-safety-ad-documents">
  <div class="card-header">
    <div class="clearfix">
      <h6 class="float-left mt-1">
        Airworthiness Directive Documents <small class="text-muted">@ {{ todayFlightHours }} Hours</small>
      </h6>
    </div>
  </div>

  <div class="card-body">
    <app-preloader-type-a *ngIf="isLoading"></app-preloader-type-a>
    <ng-container *ngIf="!isLoading">
      <div class="alert alert-warning" role="alert" *ngIf="allAircraftADs.length < 1">
        No Airworthiness Directive assigned to Aircraft.
      </div>

      <!-- Filter form -->
      <div class="py-2">
        <form [formGroup]="filterSearchForm" (ngSubmit)="filterAircraftAds()">
          <div class="form-group">
            <small class="form-text text-muted d-block clearfix">
              Filter

              <span class="text-danger float-right cursor-pointer" (click)="clearFilter()" *ngIf="adFilter.value">
                <i class="las la-times-circle"></i>
                Clear Filter
              </span>
            </small>
            <div class="input-group">
              <input class="form-control" type="text"  #adFilter formControlName="adFilter"
                placeholder="Filter by directive name, subject, type, application" name="adFilter">
            </div>
          </div>
        </form>
      </div>

      <div *ngIf="inFilterMode">
        <h4 class="clearfix">
          Filter Matches:
        </h4>
        <table class="table table-striped w-100 rag_table" *ngIf="filterResult.length > 0">
          <tbody>
            <!--FILTERED ADs-->
            <app-preloader-type-a *ngIf="isFilteringAds"></app-preloader-type-a>
            <ng-container *ngIf="!isFilteringAds">
              <tr  *ngFor="let adFiltered of filterResult; let i = index;" 
                class="{{getRowTypeColor(adFiltered)}}"
              >
                <td>
                  <h5 class="py-2 clearfix font-weight-normal">
                    <span class="badge rag_circle mr-2
                      {{getRowTypeColor(adFiltered)}}
                    "
                    *ngIf="adFiltered.adGroup == 'applies'"
                    >&nbsp;</span>
                    {{ adFiltered.airworthiness_directive?.directiveName }}
  
                    <!--AD Document View Link-->
                    <ng-container>
                      <button (click)="openModal(content, adFiltered.airworthiness_directive.fileUrl, adFiltered.airworthiness_directive.directiveName)"
                       target="_blank" class="mr-2 btn btn-danger btn-sm rounded text-white" *ngIf="adFiltered.airworthiness_directive.fileUrl">
                        <i class="la la-file-pdf-o"></i>
                        View Document
                      </button>
  
                      <span class="badge badge-light rounded p-2 text-white" *ngIf="!adFiltered.airworthiness_directive.fileUrl">
                        <i class="la la-file-pdf-o"></i>
                        No Document
                      </span>
                    </ng-container>
  
                    <span class="text-muted float-right">
                      {{ adFiltered.airworthiness_directive?.adSbType | uppercase }}
                    </span>
                  </h5>
  
                  <div class="row">
                    <div class="col-6">
                      <small class="text-dark d-block font-weight-bold">
                        {{adFiltered.airworthiness_directive?.subject}}
                      </small>
                      <small class="text-muted d-block mt-2">
                        Effective Date
                      </small>
                      {{adFiltered.airworthiness_directive? (getISODateAsDate(adFiltered.airworthiness_directive.effectiveDate) | date:'MM/dd/yyyy') : ''}}
  
                      <!-- <span *ngIf="adFiltered.isRecurring"> -->
                        <span  *ngIf="!adFiltered.terminateAction && adFiltered.isRecurring" class="text-info font-weight-bold d-block" ngbPopover="This is a recurring Airworthiness Directive." triggers="mouseenter:mouseleave" popoverTitle="RECURRING AD">
                          <i class="la la-refresh font-weight-bold"></i> RECURRING
                        </span>
                        <span  *ngIf="adFiltered.terminateAction" class="text-success font-weight-bold d-block" ngbPopover="This is a terminated Airworthiness Directive." triggers="mouseenter:mouseleave" popoverTitle="TERMINATED AD">
                          <i class="la la-ban font-weight-bold"></i> TERMINATED
                        </span>
                      <!-- </span> -->
                    </div>

                    <div class="col-6 border-left {{getRowTypeColor(adFiltered)}} rag-div">
                      <!--rag div for applies-->
                      <ng-container *ngIf="adFiltered.adGroup === 'applies'">
                        <div *ngIf="adFiltered.ragStatus == 0">
                          <span class="d-block" >
                            Overdue
                          </span>
    
                          <small class="d-block">
                            Due:
                            {{ adFiltered.sortingColumnName == 'Date'? getMomentBetweenDates(adFiltered.dueDateAsMoment, todayFlightDateMoment) + ' ago' : '' }}
                            {{ adFiltered.sortingColumnName == 'Hours'? getHoursRemainingBetweenHours(adFiltered.dueHours, todayFlightHours) + '  ago' : '' }}
    
                            <span class="d-block">
                              <em *ngIf="adFiltered.sortingColumnName == 'Date'">On {{ formatMomentAsUsDateString(adFiltered.dueDateAsMoment) }}</em>
                              <em *ngIf="adFiltered.sortingColumnName == 'Hours'">@{{ adFiltered.dueHours }}Hrs</em>
                            </span>
    
                            <span class="d-block text-muted"> <em>{{adFiltered.condition? getConditionDisplayString(adFiltered.condition) : ''}}</em> </span>
                          </small>
                        </div>
    
                        <div *ngIf="adFiltered.ragStatus !== 0">
                          Due in:
                          <span *ngIf="adFiltered.dueHours && adFiltered.dueDate">
                            {{ getMomentBetweenDates(todayFlightDateMoment, adFiltered.dueDateAsMoment) }}  or
                            {{  getHoursRemainingBetweenHours(todayFlightHours, adFiltered.dueHours) }}
                          </span>
    
                          <span *ngIf="!(adFiltered.dueHours && adFiltered.dueDate)">
                            {{ adFiltered.sortingColumnName == 'Date'? getMomentBetweenDates(todayFlightDateMoment, adFiltered.dueDateAsMoment)  : '' }}
                            {{ adFiltered.sortingColumnName == 'Hours'? getHoursRemainingBetweenHours(todayFlightHours, adFiltered.dueHours) : '' }}
                          </span>
    
                          <div>
                            <small class="d-block">
                              <em *ngIf="adFiltered.sortingColumnName == 'Date'">On {{ formatMomentAsUsDateString(adFiltered.dueDateAsMoment) }}</em>
                              <em *ngIf="adFiltered.sortingColumnName == 'Hours'">@{{ adFiltered.dueHours }}Hrs</em>
                            </small>
    
                            <span class="text-muted d-block">
                              <em>{{adFiltered.condition? getConditionDisplayString(adFiltered.condition) : ''}}</em>
                            </span>
                          </div>
    
                        </div>
                      </ng-container>


                      <!--rag div for complied-->
                      <ng-container *ngIf="adFiltered.adGroup === 'complied'">
                        
                        Complied With:
                        <small class="text-muted d-block">
                          {{ adFiltered.lastCompliedDate? 'on ' + (getISODateAsDate(adFiltered.lastCompliedDate)  | date:'MM/dd/yyyy') : '' }}
                          {{ adFiltered.lastCompliedHour ? (' / @' + adFiltered.lastCompliedHour + 'Hrs') : '' }}
                        </small>
                      </ng-container>


                      <!--rag div for notapply-->
                      <ng-container *ngIf="adFiltered.adGroup === 'notapply'">
                        Does not apply:<br/>
                        <small class="d-block">
                          Reason:<br/>
                          <small class="d-block">
                            {{ adFiltered?.notApplyReason }}
                          </small>
                        </small>
                      </ng-container>
                      
                    </div>
                  </div>
  
                  <div class="col-12" *ngIf="adFiltered.complianceNote">
                    <small class="d-block p-2 text-dark bg-light border-gray border rounded m-2 allow_wrap">
                      <b>NOTE:</b> {{adFiltered.complianceNote}}
                    </small>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <div *ngIf="!inFilterMode">
        <table class="table table-striped w-100 rag_table" *ngIf="allAircraftADs.length > 0">
          <tbody>
            
          <tr  *ngFor="let aircraftAd of allAircraftADs; let i = index;" 
            class="{{getRowTypeColor(aircraftAd)}}"
          >
            <td>
              <h5 class="py-2 clearfix font-weight-normal">
                <span class="badge rag_circle mr-2
                  {{getRowTypeColor(aircraftAd)}}
                "
                *ngIf="aircraftAd.adGroup == 'applies'"
                >&nbsp;</span>
                {{ aircraftAd.airworthiness_directive?.directiveName }}

                <!--AD Document View Link-->
                <ng-container>
                  <button (click)="openModal(content, aircraftAd.airworthiness_directive.fileUrl, aircraftAd.airworthiness_directive.directiveName)"
                   target="_blank" class="mr-2 btn btn-danger btn-sm rounded text-white" *ngIf="aircraftAd.airworthiness_directive.fileUrl">
                    <i class="la la-file-pdf-o"></i>
                    View Document
                  </button>

                  <span class="badge badge-light rounded p-2 text-white" *ngIf="!aircraftAd.airworthiness_directive.fileUrl">
                    <i class="la la-file-pdf-o"></i>
                    No Document
                  </span>
                </ng-container>

                <span class="text-muted float-right">
                  {{ aircraftAd.airworthiness_directive?.adSbType | uppercase }}
                </span>
              </h5>

              <div class="row">
                <div class="col-6">
                  <small class="text-dark d-block font-weight-bold">
                    {{aircraftAd.airworthiness_directive?.subject}}
                  </small>
                  <small class="text-muted d-block mt-2">
                    Effective Date
                  </small>
                  {{aircraftAd.airworthiness_directive? (getISODateAsDate(aircraftAd.airworthiness_directive.effectiveDate) | date:'MM/dd/yyyy') : ''}}

                  <!-- <span *ngIf="aircraftAd.isRecurring"> -->
                    <span  *ngIf="!aircraftAd.terminateAction && aircraftAd.isRecurring" class="text-info font-weight-bold d-block" ngbPopover="This is a recurring Airworthiness Directive." triggers="mouseenter:mouseleave" popoverTitle="RECURRING AD">
                      <i class="la la-refresh font-weight-bold"></i> RECURRING
                    </span>
                    <span  *ngIf="aircraftAd.terminateAction" class="text-success font-weight-bold d-block" ngbPopover="This is a terminated Airworthiness Directive." triggers="mouseenter:mouseleave" popoverTitle="TERMINATED AD">
                      <i class="la la-ban font-weight-bold"></i> TERMINATED
                    </span>
                  <!-- </span> -->
                </div>

                <div class="col-6 border-left {{getRowTypeColor(aircraftAd)}} rag-div">
                  <!--rag div for applies-->
                  <ng-container *ngIf="aircraftAd.adGroup === 'applies'">
                    <div *ngIf="aircraftAd.ragStatus == 0">
                      <span class="d-block" >
                        Overdue
                      </span>

                      <small class="d-block">
                        Due:
                        {{ aircraftAd.sortingColumnName == 'Date'? getMomentBetweenDates(aircraftAd.dueDateAsMoment, todayFlightDateMoment) + ' ago' : '' }}
                        {{ aircraftAd.sortingColumnName == 'Hours'? getHoursRemainingBetweenHours(aircraftAd.dueHours, todayFlightHours) + '  ago' : '' }}

                        <span class="d-block">
                          <em *ngIf="aircraftAd.sortingColumnName == 'Date'">On {{ formatMomentAsUsDateString(aircraftAd.dueDateAsMoment) }}</em>
                          <em *ngIf="aircraftAd.sortingColumnName == 'Hours'">@{{ aircraftAd.dueHours }}Hrs</em>
                        </span>

                        <span class="d-block text-muted"> <em>{{aircraftAd.condition? getConditionDisplayString(aircraftAd.condition) : ''}}</em> </span>
                      </small>
                    </div>

                    <div *ngIf="aircraftAd.ragStatus !== 0">
                      Due in:
                      <span *ngIf="aircraftAd.dueHours && aircraftAd.dueDate">
                        {{ getMomentBetweenDates(todayFlightDateMoment, aircraftAd.dueDateAsMoment) }}  or
                        {{  getHoursRemainingBetweenHours(todayFlightHours, aircraftAd.dueHours) }}
                      </span>

                      <span *ngIf="!(aircraftAd.dueHours && aircraftAd.dueDate)">
                        {{ aircraftAd.sortingColumnName == 'Date'? getMomentBetweenDates(todayFlightDateMoment, aircraftAd.dueDateAsMoment)  : '' }}
                        {{ aircraftAd.sortingColumnName == 'Hours'? getHoursRemainingBetweenHours(todayFlightHours, aircraftAd.dueHours) : '' }}
                      </span>

                      <div>
                        <small class="d-block">
                          <em *ngIf="aircraftAd.sortingColumnName == 'Date'">On {{ formatMomentAsUsDateString(aircraftAd.dueDateAsMoment) }}</em>
                          <em *ngIf="aircraftAd.sortingColumnName == 'Hours'">@{{ aircraftAd.dueHours }}Hrs</em>
                        </small>

                        <span class="text-muted d-block">
                          <em>{{aircraftAd.condition? getConditionDisplayString(aircraftAd.condition) : ''}}</em>
                        </span>
                      </div>

                    </div>
                  </ng-container>


                  <!--rag div for complied-->
                  <ng-container *ngIf="aircraftAd.adGroup === 'complied'">
                    
                    Complied With:
                    <small class="text-muted d-block">
                      {{ aircraftAd.lastCompliedDate? 'on ' + (getISODateAsDate(aircraftAd.lastCompliedDate)  | date:'MM/dd/yyyy') : '' }}
                      {{ aircraftAd.lastCompliedHour ? (' / @' + aircraftAd.lastCompliedHour + 'Hrs') : '' }}
                    </small>
                  </ng-container>


                  <!--rag div for notapply-->
                  <ng-container *ngIf="aircraftAd.adGroup === 'notapply'">
                    Does not apply:<br/>
                    <small class="d-block">
                      Reason:<br/>
                      <small class="d-block">
                        {{ aircraftAd?.notApplyReason }}
                      </small>
                    </small>
                  </ng-container>
                  
                </div>
              </div>

              <div class="col-12" *ngIf="aircraftAd.complianceNote">
                <small class="d-block p-2 text-dark bg-light border-gray border rounded m-2 allow_wrap">
                  <b>NOTE:</b> {{aircraftAd.complianceNote}}
                </small>
              </div>
            </td>
          </tr>

          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</div>


<!---pagination-->
<!--
<div class="d-flex justify-content-between p-2">
  <ngb-pagination [collectionSize]="totalAds" [(page)]="page" [pageSize]="pageSize"
  [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
    (pageChange)="paginationPageChange($event)">
  </ngb-pagination>
</div>-->


<!--PDF VIEWER--->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{viewedPdfAdTitle}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-pdf-viewer [pdfSrc]="viewedPdfSrc"></app-pdf-viewer>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>
