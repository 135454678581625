import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { PilotDatesModel } from '../models/pilot-dates-model';

@Injectable({
  providedIn: 'root'
})
export class PilotStatusService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getUserPilotStatuses(userId: number, limit: number = 1){
    return this.httpClient.get(environment.apiBaseUrl + '/pilotstatus/getUserPilotStatuses?userId=' + userId + "&limit=" + limit)
  }

  postUserPilotStatuses(pilotStatus: PilotDatesModel){
    return this.httpClient.post(environment.apiBaseUrl + '/pilotstatus/postUserPilotStatuses', pilotStatus)
  }

//V2
  updateUserPilotDatesV2(email: string, pilotData: PilotDatesModel){
    return this.httpClient.put(environment.apiBaseUrl + `/v2/user/${email}`, pilotData);
  }
}
