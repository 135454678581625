import { UserService } from '../../../../../services/user.service';
import { SubscriptionLike } from 'rxjs';
import { UserModel } from '../../../../../models/user-model';
import { Component, OnInit, Input, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { AircraftV2Model } from '../../../../../models/airfcraftV2/aircraft-v2.model';
import { OrganizationService } from '../../../../../services/organization.service';
import { AircraftService } from '../../../../../services/aircraft.service';
import { Router } from '@angular/router';

interface AircraftStatusI {
  aircraftId: number;
  status: AircraftStatusListI;
}

interface AircraftStatusListI {
  safetyComplete: boolean;
  maintenanceComplete: boolean;
  ifrComplete: boolean;
  adminStatComplete: boolean;
}

@Component({
  selector: 'app-organization-aircraft-table',
  templateUrl: './organization-aircraft-table.component.html',
  styleUrls: ['./organization-aircraft-table.component.scss']
})
export class OrganizationAircraftTableComponent implements OnInit, OnDestroy, OnChanges {
  @Input() aircraftList: Array<AircraftV2Model>;
  currentUser: UserModel;
  private currentUser$: SubscriptionLike;

  aircraftStatuses: Array<AircraftStatusI>;

  constructor(
    private userService: UserService,
    private aircraftService: AircraftService,
    private orgService: OrganizationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.currentUser$ = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.aircraftList && changes.aircraftList.currentValue) {
      this.aircraftList = changes.aircraftList.currentValue;

      this.aircraftStatuses = [];

      if (this.aircraftList) {
        this.aircraftList.forEach(
          (aircraft: AircraftV2Model) => {
            const s: AircraftStatusI = {
              aircraftId: aircraft.id,
              status: {
                safetyComplete: false,
                maintenanceComplete: false,
                ifrComplete: false,
                adminStatComplete: false
              }
            };

            this.aircraftStatuses.push(s);
          }
        );
      }
    }
  }

  viewAsSelectedAircraft(aircraft: AircraftV2Model, routeTo: 'profile' | 'dashboard' = 'profile') {
    this.aircraftService.setSelectedAircraft(aircraft);
    this.router.navigate(['/cga/aircraft-profile']);

    if (routeTo.toLowerCase() === 'profile') {
      this.router.navigate(['/cga/aircraft-profile']);
    }

    if (routeTo.toLowerCase() === 'dashboard') {
      this.router.navigate(['/cga/dashboard']);
    }
  }

  ngOnDestroy() {
    if (this.currentUser$) {
      this.currentUser$.unsubscribe();
    }
  }

  findAircraftStatus (aircraftId: number) {
    let statusIndex: number = null;

    if (this.aircraftStatuses) {
      this.aircraftStatuses.some(
        (status: AircraftStatusI, i) => {
          if (status.aircraftId === aircraftId) {
            statusIndex = i;
            return true;
          } else {
            return false;
          }
        }
      );
    }

    return statusIndex;
  }

  updateSafetyCompleted(aircraftId: number, val: boolean) {
    const statusIndex: number = this.findAircraftStatus(aircraftId);

    if (statusIndex !== null) {
      this.aircraftStatuses[statusIndex].status.safetyComplete = val;
    }
  }

  updateMaintenanceCompleted(aircraftId: number, val: boolean) {
    const statusIndex: number = this.findAircraftStatus(aircraftId);

    if (statusIndex !== null) {
      this.aircraftStatuses[statusIndex].status.maintenanceComplete = val;
    }
  }

  updateIfrCompleted(aircraftId: number, val: boolean) {
    const statusIndex: number = this.findAircraftStatus(aircraftId);

    if (statusIndex !== null) {
      this.aircraftStatuses[statusIndex].status.ifrComplete = val;
    }
  }

  updateAdminStatCompleted(aircraftId: number, val: boolean) {
    const statusIndex: number = this.findAircraftStatus(aircraftId);

    if (statusIndex !== null) {
      this.aircraftStatuses[statusIndex].status.adminStatComplete = val;
    }
  }

  checkAllStatusDone(aircraftId: number) {
    const statusIndex: number = this.findAircraftStatus(aircraftId);

    if (statusIndex !== null) {
      return this.aircraftStatuses[statusIndex].status.safetyComplete  &&
      this.aircraftStatuses[statusIndex].status.maintenanceComplete  &&
      this.aircraftStatuses[statusIndex].status.ifrComplete  &&
      this.aircraftStatuses[statusIndex].status.adminStatComplete;
    } else {
      return false;
    }
  }

  getOwnerOrg(el: AircraftV2Model) {
    return this.orgService.filterAircraftOrgsForOwnerV2Model(el.organizations);
  }
}
