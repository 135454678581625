import { ActivatedRoute } from '@angular/router';
import { BreadcrumbNavModel } from '../../../models/breadcrumb-nav-model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-view-all-aircraft-docs',
  templateUrl: './admin-view-all-aircraft-docs.component.html',
  styleUrls: ['./admin-view-all-aircraft-docs.component.scss']
})
export class AdminViewAllAircraftDocsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute
  ) { }

  aircraftId: number;
  navItems: Array<BreadcrumbNavModel> = [];


  ngOnInit() {
    this.aircraftId = +this.route.snapshot.paramMap.get('aircraftId');
    
    this.navItems = [
      {navLabel: 'Admin Console', navRouterLink: ['/cga/admin/console']},
      {navLabel: 'Aircraft', navRouterLink: ['/cga/admin/edit-aircraft', this.aircraftId]},
      {navLabel: 'Aircraft Dashboard', navRouterLink: null}
    ]
  }

}
