import { AircraftAdminDates } from './../../models/aircraft-admin-dates-model';
import { UserMenuAircraftModel } from './../../models/user-menu-aircraft-model';
import { UserAircraftModel } from './../../models/user-aircraft-model';
import { AircraftService } from './../../services/aircraft.service';
import { NgbDateCustomAndUSAParserFormatter } from './../../services/ngb-date-custom-and-usa-parser-formatter';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionLike } from 'rxjs';
import { PilotStatusService } from './../../services/pilot-status.service';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { NgbDatepickerConfig, NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UserService } from '../../services/user.service';
import Swal from 'sweetalert2';
import { AircraftV2Model } from '../../models/airfcraftV2/aircraft-v2.model';

@Component({
  selector: 'app-dash-dates',
  templateUrl: './dash-dates.component.html',
  styleUrls: ['./dash-dates.component.scss'],
  providers: [
    NgbDatepickerConfig,
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomAndUSAParserFormatter}
  ]
})
export class DashDatesComponent implements OnInit, OnDestroy {
  @Input() theAircraft: AircraftV2Model;
  @Input() viewupdateMode: boolean;

  constructor(
    private ngbDatePickerConfig: NgbDatepickerConfig,
    private aircraftService: AircraftService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) {
    ngbDatePickerConfig.minDate = {year: 1900, month: 1, day: 1};
  }
  isLoading: boolean;

  inUpdateMode: boolean;

  selectedAircraft : AircraftV2Model;
  selectedAircraft$ : SubscriptionLike;

  pilotDatesForm: FormGroup;

  ngOnInit() {
    this.isLoading = false;
    this.inUpdateMode = false;

    this.route.queryParams.subscribe(params => {
      if (params['viewupdateMode']) {
        this.viewupdateMode = true;
      }
    });

    this.selectedAircraft$ = this.aircraftService.selectedAircraft$.subscribe(
      (aircraft: AircraftV2Model) => {
        this.selectedAircraft = aircraft;
        // if (this.selectedAircraft.id) {
        //   this.loadForUpdateDashDatesForm();
        // }
      }
    );

    this.initDashDatesForm();
  }


  initDashDatesForm(){
    this.pilotDatesForm = this.formBuilder.group({
      'registrationDueDate': new FormControl (null, [Validators.required]),
      'insuranceDueDate': new FormControl (null, [Validators.required])
    })
  }

  loadForUpdateDashDatesForm() {
    this.pilotDatesForm.get('registrationDueDate').setValue(
      this.selectedAircraft.registrationDueDate ?  new Date(this.selectedAircraft.registrationDueDate) : null
      ),
    this.pilotDatesForm.get('insuranceDueDate').setValue(
      this.selectedAircraft.insuranceDueDate ? new Date(this.selectedAircraft.insuranceDueDate) : null
    );

    this.inUpdateMode = true;
  }


  cancelPostUserPilotSTatus() {
    this.pilotDatesForm.reset();
    this.inUpdateMode = false;
  }

  postUserPilotSTatus() {
    if (!this.pilotDatesForm.valid) {
      Swal.fire(
        'Form contains invalid fields!',
        'Check all fields and ensure they have been correctly filled.',
        'error'
      );

      Object.values(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );
      return;
    }

    this.isLoading = true;

    let adminDates: AircraftAdminDates = new AircraftAdminDates;

    adminDates.registrationDueDate  = this.pilotDatesForm.get('registrationDueDate').value;
    adminDates.insuranceDueDate = this.pilotDatesForm.get('insuranceDueDate').value;

    this.aircraftService.updateAircraftAdminDates(this.selectedAircraft.tailNo, adminDates).subscribe(
      (response: any) => {
        this.isLoading = false;

        this.inUpdateMode = false;
        this.reloadAircraft();

        Swal.fire(
          'Great!',
          'Administrative Dates updated',
          'success'
        );
      },
      (error) => {
        this.isLoading = false;
        Swal.fire(
          'Oops!',
          'Update Administrative Dates failed.',
          'error'
        );
      }
    );
  }

  reloadAircraft() {
    this.aircraftService.getAircraftV2(this.selectedAircraft.tailNo).subscribe(
      (response: any) => {
        const aircraft: AircraftV2Model = response;
        this.aircraftService.setSelectedAircraft(aircraft);
      },
      (error: any ) => {
        Swal.fire(
          'Can not fetch aircraft data!',
          'Please reload ths page.',
          'error'
        );
      }
    );
  }

  ngOnDestroy() {
    if (this.selectedAircraft$) {
      this.selectedAircraft$.unsubscribe();
    }
  }

}
