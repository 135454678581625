import { ServiceBulletinService } from './../../../../services/service-bulletin.service';
import { AircraftDashboardReferencesModel } from './../../../../models/aircraft-dashboard-references-model';
import { RagType } from './../../../../models/rag-type';
import { UtilitiesService } from './../../../../services/utilities.service';
import { AirworthinessDirectiveService } from './../../../../services/airworthiness-directive.service';
import { FlightLogsService } from './../../../../services/flight-logs.service';
import { AircraftServiceBulletinModel } from './../../../../models/aircraft-service-bulletin-model';
import { AircraftAirDirectiveModel } from './../../../../models/aircraft-air-directive-model';
import { AircraftFlightLogModel } from './../../../../models/aircraft-flight-log-model';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { AircraftV2Model } from '../../../../models/airfcraftV2/aircraft-v2.model';

@Component({
  selector: 'app-dashboard-safety-status',
  templateUrl: './dashboard-safety-status.component.html',
  styleUrls: ['./dashboard-safety-status.component.scss']
})
export class DashboardSafetyStatusComponent implements OnInit, OnChanges {
  @Input() aircraftId: number;
  @Input() theAircraft: AircraftV2Model;
  @Input() isAdminView: boolean;
  @Input() statusOnly: boolean;

  @Output() isCompleted: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    private flightLogsService: FlightLogsService,
    private adService: AirworthinessDirectiveService,
    private sbService: ServiceBulletinService,
    private utilitiesService: UtilitiesService
  ) { }

  isLoading: boolean;
  adsDone: boolean;
  sbsDone: boolean;

  finalSafetyStatus: string;

  lastFlightLogEntry: AircraftFlightLogModel = new AircraftFlightLogModel;

  serverEpochTime: number = (new Date).getTime();

  // From last flight Hours: If due hours exceeds this, becomes overdue(RED)
  todayFlightHours: number = null;
  // today's date: when if dueDate exceeds this, becomes overdue(RED)
  todayFlightDate: string = this.utilitiesService.dateToISOString(new Date(this.serverEpochTime));
  todayFlightDateMoment: moment.Moment = moment(this.todayFlightDate);

  // Using days ago, what SB/AD due dates to look back to
  safetyRefLookAheadDate: number = null;

  allAircraftADs: Array<AircraftAirDirectiveModel > = [];
  allAircraftSBs: Array< AircraftServiceBulletinModel> = [];

  dashReferences: AircraftDashboardReferencesModel = new AircraftDashboardReferencesModel;

  queryLookAheadDate: string = null;
  queryLookAheadHours: number;

  useLookAhead: boolean;

  ngOnInit() {
    this.initVariables();
  }

  initVariables() {

    this.adsDone = false;
    this.sbsDone = false;

    this.getSafetyRagStatus();

    this.queryLookAheadHours = 0;
    this.isLoading = false;
    this.safetyRefLookAheadDate = null;
    this.lastFlightLogEntry = new AircraftFlightLogModel;

    this.allAircraftADs = [];
    this.allAircraftSBs = [];

    this.queryLookAheadDate = null;
    this.queryLookAheadHours = 0;

    this.useLookAhead = true;
    this.todayFlightHours = null;

    this.utilitiesService.getAircraftDashboardReferences().subscribe(
      (response: any) => {

        this.isLoading = true;

        if (+response.status.code === 1000) {
          this.dashReferences = response.responseData;
          this.todayFlightDate = this.dashReferences.serverNow;
          this.todayFlightDateMoment = moment(this.todayFlightDate);
          this.serverEpochTime = this.utilitiesService.parseISOtoDateObj(this.dashReferences.serverNow).getTime();


          this.getLastAircraftFlightLog();
        }
      }
    );
  }

  ngOnChanges($event) {
    if ($event.theAircraft) {
      const newAircraft = $event.theAircraft.currentValue;
      if (newAircraft.id && newAircraft.id !== this.theAircraft.id) {
        this.theAircraft = newAircraft;
        this.initVariables();
      }
    }

    if ($event.isAdminView) {
      this.isAdminView = $event.isAdminView.currentValue;
    }

    if ($event.aircraftId) {
      this.aircraftId = $event.aircraftId.currentValue;
    }

  }

  getLastAircraftFlightLog() {
    this.isLoading = true;

    this.flightLogsService.getLastAircraftFlightLog(this.aircraftId).subscribe(
      (response: any) => {
        if (+response.status.code === 1000) {
          this.lastFlightLogEntry = response.responseData ? response.responseData : new AircraftFlightLogModel;
          const lastTimeInHours = this.lastFlightLogEntry.timeInHours ? this.lastFlightLogEntry.timeInHours : 0;
          const lastTimeOutHours = this.lastFlightLogEntry.timeOutHours ? this.lastFlightLogEntry.timeOutHours : 0;

          // check if to use last timeIn or TimeOut for todayFlightHours
          this.todayFlightHours = lastTimeInHours > 0 ? +lastTimeInHours : +lastTimeOutHours;

          this.fetchAllAircraftDashboardADs();
        }
      }
    );
  }

  fetchAllAircraftDashboardADs() {

    this.adsDone = false;
    this.sbsDone = false;

    this.allAircraftADs = [];
    this.allAircraftSBs = [];

    this.adService.fetchAllAircraftDashboardADs(this.aircraftId, this.todayFlightHours).subscribe(
      (response: any) => {
        if (+response.status.code === 1000) {
          response.responseData.directives.forEach(
            (ad: AircraftAirDirectiveModel ) => {
              this.allAircraftADs.push(ad);
            }
          );
        }

        this.fetchAllAircraftDashboardSBs();
      },
      (error: any) => {
        this.fetchAllAircraftDashboardSBs();
      }
    );
  }

  fetchAllAircraftDashboardSBs() {
    this.sbService.fetchAllAircraftDashboardSBs(this.aircraftId, this.todayFlightHours).subscribe(
      (response: any) => {
        if (+response.status.code === 1000) {
          response.responseData.bulletins.forEach(
            (sb: AircraftServiceBulletinModel) => {
              this.allAircraftSBs.push(sb);
            }
          );

        this.isLoading = false;

        this.sbsDone = true;
        this.adsDone = true;
        this.getSafetyRagStatus();
        }
      },
      (error: any) => {

      }
    );
  }

  emitCompleteStatus() {
    this.isCompleted.emit(this.sbsDone && this.adsDone);
  }

  getRagColor(val: number) {
    if (val === null) {
      return ' ';
    }
    return RagType[val];
  }

  getSafetyRagStatus() {
    let rag;

    if(this.sbsDone && this.adsDone) {
      if ((this.allAircraftADs.length > 0) && (this.allAircraftSBs.length > 0)) {
        rag = this.allAircraftADs[0].ragStatus < this.allAircraftSBs[0].ragStatus ?
          this.getRagColor(this.allAircraftADs[0].ragStatus) : 
            this.getRagColor(this.allAircraftSBs[0].ragStatus);
      } else if ((this.allAircraftADs.length < 1) && (this.allAircraftSBs.length > 0)) {
        rag = this.getRagColor(this.allAircraftSBs[0].ragStatus);
      } else if ((this.allAircraftADs.length > 0) && (this.allAircraftSBs.length < 1)) {
        rag = this.getRagColor(this.allAircraftADs[0].ragStatus);
      } else if ((this.allAircraftADs.length < 1) && (this.allAircraftSBs.length < 1)) {
        rag = '';
      }  else {
        rag = '';
      }
    } else {
      rag = '';
    }

    this.finalSafetyStatus = rag;
    
    this.emitCompleteStatus();
  }

  getAdbOrSbName(adOrSb: any) {
    if ((<AircraftAirDirectiveModel>adOrSb).airworthiness_directive) {
      return adOrSb.airworthiness_directive.directiveName;
    } else {
      return (<AircraftServiceBulletinModel>adOrSb).service_bulletin.bulletinName;
    }
  }

  getAdbOrSbDisplayName(adOrSb: AircraftAirDirectiveModel | AircraftServiceBulletinModel) {
    if ((<AircraftAirDirectiveModel>adOrSb).airworthiness_directive) {
      return 'FAA Directive';
    } else {
      return 'Service Bulletin';
    }
  }

  getAdbOrSbSubject(adOrSb: AircraftAirDirectiveModel | AircraftServiceBulletinModel) {
    if ((<AircraftAirDirectiveModel>adOrSb).airworthiness_directive) {
      const ad = adOrSb as AircraftAirDirectiveModel;
      return ad.airworthiness_directive.subject;
    } else {
      const ad = adOrSb as AircraftServiceBulletinModel;
      return ad.service_bulletin.subject;
    }
  }

  getAdbOrSbTypeName(adOrSb: AircraftAirDirectiveModel | AircraftServiceBulletinModel) {
    if ((<AircraftAirDirectiveModel>adOrSb).airworthiness_directive) {
      const ad  = adOrSb as AircraftAirDirectiveModel;
      const type = ad.airworthiness_directive.adSbType ? ad.airworthiness_directive.adSbType : null;

      return type;
    } else {
      const sb  = adOrSb as AircraftServiceBulletinModel;
      const type = sb.service_bulletin.adSbType ? sb.service_bulletin.adSbType : null;

      return type;
    }
  }

  formatMomentAsUsDateString(momentObject: moment.Moment) {
    return this.utilitiesService.formatMomentAsUsDateString(momentObject);
  }

  getMomentBetweenDates(lowertimeMoment: moment.Moment, higherTImeMoment: moment.Moment) {
    return this.utilitiesService.getMomentBetweenDates(lowertimeMoment, higherTImeMoment);
  }

  getHoursRemainingBetweenHours(lowerHours: number, higherHours: number) {
    return this.utilitiesService.getHoursRemainingBetweenHours(lowerHours, higherHours);
  }

}
