import { UserOrganizationAndRole } from '../../models/user-organization-and-role';
import { UserMenuAircraftModel } from '../../models/user-menu-aircraft-model';
import { UserService } from '../../services/user.service';
import { SubscriptionLike } from 'rxjs';
import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { UserModel } from '../../models/user-model';
import { OrganizationService } from '../../services/organization.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-organization-picker-widget',
  templateUrl: './organization-picker-widget.component.html',
  styleUrls: ['./organization-picker-widget.component.scss']
})
export class OrganizationPickerWidgetComponent implements OnInit, OnDestroy {
  @Output() successFetchEmmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() fetchingEmmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() organizationListEmmitter: EventEmitter<
    Array<UserOrganizationAndRole>
  > = new EventEmitter();

  currentUser: UserModel;
  private currentUser$: SubscriptionLike;

  private userOrganizations$: SubscriptionLike;
  userOrganizations: Array<UserOrganizationAndRole> = [];

  private selectedOrg$: SubscriptionLike;
  selectedOrg: UserOrganizationAndRole = new UserOrganizationAndRole();

  isFetchingOrgList: boolean = false;

  constructor(
    private orgService: OrganizationService,
    private userService: UserService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.currentUser$ = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });

    this.userOrganizations$ = this.orgService.userOrganizations$.subscribe(
      (orgs: Array<UserOrganizationAndRole>) => {
        this.userOrganizations = orgs;
      }
    );

    this.selectedOrg$ = this.orgService.selectedOrg$.subscribe(
      (org: UserOrganizationAndRole) => {
        this.selectedOrg = org;
      }
    );
  }

  setSelectedOrganization(org: UserOrganizationAndRole) {
    this.orgService.setSelectedOrganization(org);
    this.router.navigate(['/cga/organization-profile']);
  }

  organizationListEmmit() {
    this.organizationListEmmitter.emit(this.userOrganizations);
  }

  organizationFetchStatusEmmit(val: boolean) {
    this.successFetchEmmitter.emit(val);
  }

  ngOnDestroy() {
    if (this.currentUser$) {
      this.currentUser$.unsubscribe();
    }
  }
}
