<nav class="navbar navbar-expand p-0 navbar-dark bg-primary text-white clearfix">
  <div class="toggler-box d-lg-none d-md-none d-xl-none bg-white border-0 form-inline {{isSideBarCollapsed? '': 'hide'}}">
    <button class="btn btn-lg p-2" type="button"  (click)="toggleSideBar()" [attr.aria-expanded]="!isSideBarCollapsed" aria-controls="sidebar">
      <i [attr.class]="isSideBarCollapsed? 'la la-bars' : 'la la-close'" class="fa"></i>
    </button>
  </div>

  <div class="d-flex flex-fill">
    <div class="collapse navbar-collapse " id="navbarSupportedContent" >
      <ul class="navbar-nav mr-auto w-100 justify-content-end align-items-center">
        <li class="nav-item  mr-auto">
          <h4 class="top_menu_title ml-3 pl-3 ellipsis">
            {{topHeaderTitle}}
          </h4>
        </li>

        <li class="nav-item notificationlink belled">
          <a href="javascript: void(0);">
            <i class="la la-bell-o text-white"></i>
          </a>
        </li>

        <li class="nav-item dropdown" ngbDropdown >
          <a class="nav-link dropdown-toggle d-flex mr-2" href="javascript: void(0);" id="userProfileDropdown" role="button" ngbDropdownToggle>
            <span class="profile_avatar">
              <img src="assets/images/avatar_45.png" alt="">
            </span>

            <!-- <i class="la la-user"></i>  -->
            <span class="profile_name">
              <small class="text-white d-block">Hi {{currentUser?.firstName}}</small>
              <small class="d-block text-orange font-weight-bold">
                @{{selectedOrg?.organization?.organizationName}}
              </small>
            </span>
          </a>

          <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu  aria-labelledby="userProfileDropdown">
            
            <!--ORGANISATION PICKER-->
            <div class="org-picker px-0">
              <app-organization-picker-widget></app-organization-picker-widget>
            </div>
            
            <a class="dropdown-item" [routerLink]="['/cga/profile']" ngbDropdownItem >Profile</a>
            
            <!--
              <a class="dropdown-item" ngbDropdownItem href="#">Security</a>
            -->
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="logOutUser()" ngbDropdownItem href="javascript: void(0);">Log Out</a>
          </div>
        </li>
      </ul>
    </div>

  </div>
</nav>
