<div class=" bg-transparent border-0 form_header mt-6 mb-0 pb-0 clearfix">
  <h5 class="uppercase form_header float-left">
      Associated Organizations
  </h5>

  <span class="float-right" *ngIf="currentUser.isAdmin">
    <button (click)="initAttachOrgModal(attachOrganizationContent)"
      class="btn btn-outline-primary rounded btn-sm"
    >
      <i class="la la-plus"></i> 
    </button>
    Attach Org.
  </span>
</div>

<div class="card rounded-0 shadow p-3">
  <table class="table spaced_rows table-striped table-responsive">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Org. Name</th>
        <th scope="col">Org. Role</th>
        <!-- <th scope="col">People Assigned RO</th>
        <th scope="col">People Assigned RW</th> -->
        <th scope="col">Contact</th>
        <th scope="col">Email</th>
        <th scope="col">Phone</th>
        <th scope="col">Assignment Date</th>
        <th scope="col" *ngIf="currentUser.isAdmin">Actions</th>
      </tr>
    </thead>
  
    <tbody>
      <ng-container *ngFor="let org of theAircraft.organizations; let i = index;">
        <tr>
          <th scope="row">{{i+1}}</th>
          <td class="align-middle">
            {{org?.name}} {{org.id}}
          </td>
          <td>{{ org?.role }}</td>
          <!-- 
            <td> --- ---</td>
            <td> --- ---</td> 
          -->
          <td> {{org?.adminName}} </td>
          <td>  {{org?.adminEmail}} </td>
          <td> {{ org.adminPhone? formatPhoneNumber(org.adminPhone) : '--- ---' }} </td>
          <td>{{ org?.assignmentDate | date }}</td>
          <td *ngIf="currentUser.isAdmin"> 
            <!--ENABLED-->
            <button (click)="detachOrg(org)"
              class="btn btn-danger btn-sm rounded btn-sm"
              *ngIf="org.role && (org.role !== 'OWNER')"
              title="Remove Org."
            >
              <i class="la la-trash"></i> 
            </button>

            <!--DISABLED-->
            <button
              class="btn btn-dark btn-sm rounded btn-sm disabled" disabled
              *ngIf="org.role && (org.role === 'OWNER')" 
              title="Disabled: Remove Org."
            >
              <i class="la la-trash"></i> 
            </button>
          </td>
        </tr>
      </ng-container>
  
    </tbody>
  
  </table>
</div>




<!--Attach organization modal-->
<ng-template #attachOrganizationContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Attach Organization to Aircraft</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <app-preloader-type-a *ngIf="isAttachingOrg"></app-preloader-type-a>

    <form [formGroup]="attachOrgForm" (ngSubmit)="doAttachOrgToAircraft()" *ngIf="!isAttachingOrg">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <small class="form-text text-muted required">Select Organization</small>
            <select
              formControlName="attachOrgId"
              class="form-control"
              name="attachOrgId"
              id="attachOrgId"
            >
              <option [ngValue]="null">Select Organization from List</option>
              <ng-container *ngFor="let org of allOrgsAndRoles;">
                <option [value]="org.id" *ngIf="checkIfInAircraftOrgs(org)">
                  {{org.organizationName}} {{org.id}}
                </option>
              </ng-container>
            </select>
            
            <small
              class="form-text text-danger"
              *ngIf="attachOrgForm.get('attachOrgId').invalid && (attachOrgForm.get('attachOrgId').touched || attachOrgForm.get('attachOrgId').dirty)"
              >Select and Organization from the list .</small
            >
          </div>
        </div>
      </div>

      <div class="form-group">
        <small class="form-text text-muted required">Select Role</small>
        <div class="input-group">
          <select class="form-control" formControlName="attachOrgRole" id="attachOrgRole"  placeholder="Select role">
            <option *ngFor="let role of orgRoleOptions; let i = index" [value]="role.value">
              {{role.label}}
            </option>
          </select>
        </div>
        <small class="text-danger d-block" *ngIf="attachOrgForm.get('attachOrgRole').invalid && (attachOrgForm.get('attachOrgRole').touched || attachOrgForm.get('attachOrgRole').dirty)">
          Select a role
        </small>
      </div>
    </form>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="doAttachOrgToAircraft()">Attach Organization</button>
    <button type="button" class="btn btn-outline-danger" (click)="modal.close('Save click')">Cancel</button>
  </div>
</ng-template>
<!--End Attach organization modal-->