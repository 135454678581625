import { MAircraftTranspondersModel } from './m-aircraft-transponders-model';
import { MAircraftPitoticsModel } from './m-aircraft-pitotics-model';
import { MAircraftOilChangesModel } from './m-aircraft-oil-changes-model';
import { MAircraftEltsModel } from './m-aircraft-elts-model';
import { MAircraftAnnualsModel } from './m-aircraft-annuals-model';
import { MAircraftAltimetersModel } from './m-aircraft-altimeters-model';
import { MAircraft100hoursModel } from './m-aircraft-100hours-model';

export class FullAircraftMaintenanceStatModel{
  constructor(){
    this.aircraftId = null;
    this.last100Hours = null;
    this.lastAltimeterDate = null;
    this.lastAnnualDate = null;
    this.lastEltDate = null;
    this.lastOilChangeHours = null;
    this.lastPitoticDate = null;
    this.lastTransponderDate = null;
  }
  aircraftId: number;
  last100Hours: number;
  lastAltimeterDate: string;
  lastAnnualDate: string;
  lastAnnualHours: number;
  lastEltDate: string;
  lastOilChangeHours: number;
  lastPitoticDate: string;
  lastTransponderDate: string;
}
