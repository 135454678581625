import { ValidatorFn, AbstractControl } from '@angular/forms';

export function DependsOnControlIfControlIsValidator (otherControl: AbstractControl, ifValue: any): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let forbidden: boolean = null;

    forbidden = ((otherControl.value == ifValue)  && !control.value)? true : null;

    if(!forbidden){
      let otherErrorKey = "hasDependantIfControlIs_" + ifValue;
      if (otherControl.hasError(otherErrorKey)) {
        delete otherControl.errors[otherErrorKey];
        otherControl.updateValueAndValidity();
      }
    }

    control.markAsTouched()
    
    return forbidden ? {'dependsOnControlIfControlIs' : {value: control.value}} : null;
  };
}