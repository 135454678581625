import { OptionLabelsAndValuesModel } from './option-labels-and-values-model';

export class AircraftLogBookTypeOptionsModel {
  constructor() {
    this.options = [
      { label: '--Select log book type--', value: '' },
      { label: 'Aircraft', value: 'aircraft' },
      { label: 'Engine', value: 'engine' },
      { label: 'Propeller', value: 'propeller' },
      { label: 'Ad Compliance Report', value: 'adCompliance' }
    ];
  }

  options: Array<OptionLabelsAndValuesModel>;
}
