import { AllMembersComponent } from './admin/members-list/all-members.component';
import { ListAircraftV2Component } from './admin/aircraft/list-aircraft-v2/list-aircraft-v2.component';
import { ListOrganizationsComponent } from './admin/organizations/list-organizations/list-organizations.component';
import { CustomerProfileComponent } from './customer/customer-profile/customer-profile.component';
import { AuthActionsComponent } from './public/auth-actions/auth-actions.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import {
  AircraftMaintenanceHistoryComponent
} from './customer/maintenance-history/aircraft-maintenance-history/aircraft-maintenance-history.component';
import { QuickViewLogsComponent } from './customer/flight-logs/quick-view-logs/quick-view-logs.component';
import { AdminViewAllAircraftDocsComponent } from './admin/aircraft/admin-view-all-aircraft-docs/admin-view-all-aircraft-docs.component';
import { ViewAllSafetyDocumentsComponent } from './customer/dashboard/view-all-safety-documents/view-all-safety-documents.component';
import { AdminAircraftFlightlogComponent } from './admin/aircraft/admin-aircraft-flightlog/admin-aircraft-flightlog.component';
import { AdminAircraftDashboardComponent } from './admin/aircraft/admin-aircraft-dashboard/admin-aircraft-dashboard.component';
import { AircraftDashboardViewComponent } from './customer/dashboard/aircraft-dashboard-view/aircraft-dashboard-view.component';
import { AircraftFlightLogComponent } from './customer/flight-logs/aircraft-flight-log/aircraft-flight-log.component';
import { ManageDirectivesComponent } from './admin/airworthinessDirectives/manage-directives/manage-directives.component';
import { AddAircraftComponent } from './admin/aircraft/add-aircraft/add-aircraft.component';
import { EditAircraftComponent } from './admin/aircraft/edit-aircraft/edit-aircraft.component';
import { EditCustomerComponent } from './admin/edit-customer/edit-customer.component';
import { AdminGuardService } from './guards/admin-guard.service';
import { AuthGuardService } from './guards/auth-guard.service';
import { LoginComponent } from './public/login/login.component';
import { CreateUserComponent } from './admin/create-user/create-user.component';
import { AdminSearchComponent } from './admin/admin-search/admin-search.component';
import { AdminConsoleComponent } from './admin/admin-console/admin-console.component';
import { AdminComponent } from './admin/admin.component';
import { CustomerComponent } from './customer/customer.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicComponent } from './public/public.component';
import { NoAuthGuardService } from './guards/no-auth-guard.service';
import { EditAircraftDashboardComponent } from './admin/aircraft/edit-aircraft-dashboard/edit-aircraft-dashboard.component';
import { ManageBulletinsComponent } from './admin/serviceBulletins/manage-bulletins/manage-bulletins.component';
import { DashDatesComponent } from './customer/dash-dates/dash-dates.component';
import { Page404Component } from './public/page404/page404.component';
import { BulkBulletinUploadComponent } from './admin/bulk-bulletin-upload/bulk-bulletin-upload.component';
import { AddAircraftV2Component } from './admin/aircraft/add-aircraft-v2/add-aircraft-v2.component';
import { AircraftProfileComponent } from './admin/aircraft/aircraft-profile/aircraft-profile.component';
import { OrganizationProfileComponent } from './admin/organizations/organization-profile/organization-profile.component';
import { NewMemberComponent } from './customer/members/new-member/new-member.component';
import { AdminEditMemberProfileComponent } from './admin/admin-edit-member-profile/admin-edit-member-profile.component';
import { UserEditOwnProfileComponent } from './customer/members/member-profile/user-edit-own-profile/user-edit-own-profile.component';
import { UploadAircraftLogBookComponent } from './admin/aicraftLogBooks/upload-aircraft-log-book/upload-aircraft-log-book.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'cga',
    component: CustomerComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: AircraftDashboardViewComponent,
        data: {  title: 'Dashboard'},
        children: [

        ]
      },
      {
        path: 'profile',
        component: UserEditOwnProfileComponent,
        data: {  title: 'Profile'},
        children: [

        ]
      },
      {
        path: 'organization-profile',
        component: OrganizationProfileComponent,
        data: {  title: 'Organization Profile'},
        children: [

        ]
      },
      {
        path: 'aircraft-profile',
        component: AircraftProfileComponent,
        data: {  title: 'Aircraft Profile'},
        children: [

        ]
      },
      {
        path: 'dash-dates',
        component: DashDatesComponent,
        data: {  title: 'Admin Dates'},
        children: [

        ]
      },
      {
        path: 'all-aircraft-safety-documents',
        component: ViewAllSafetyDocumentsComponent,
        data: {  title: 'Safety Docs'},
        children: [

        ]
      },
      {
        path: 'view-logs',
        component: QuickViewLogsComponent,
        data: {  title: 'View Logs'},
        children: [

        ]
      },
      {
        path: 'aircraft-flightlog',
        component: AircraftFlightLogComponent,
        data: {  title: 'Flight Log'},
        children: [

        ]
      },
      // {
      //   path: 'aircraft-maintenance',
      //   component: AircraftMaintenanceHistoryComponent,
      //   data: {  title: 'Maintenance History'},
      //   children: [

      //   ]
      // },
      {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AdminGuardService],
        children: [
          {
            path: '',
            redirectTo: 'list-aircraft',
            pathMatch: 'full'
          },
          {
            path: 'console',
            component: AdminConsoleComponent,
            data: {  title: 'Admin: Members'},
            children: [

            ]
          },
          {
            path: 'list-aircraft',
            component: ListAircraftV2Component,
            data: {  title: 'Admin: Aircraft'},
            children: [

            ]
          },
          {
            path: 'new-aircraft-v2',
            component: AddAircraftV2Component,
            data: {  title: 'Admin: New Aircraft'},
          },
          {
            path: 'search',
            component: AdminSearchComponent,
            data: {  title: 'Admin: Search'},
            children: [

            ]
          },
          {
            path: 'new-customer',
            component: CreateUserComponent,
            data: {  title: 'Admin: New Customer (Legacy)'},
            children: [

            ]
          },
          {
            path: 'new-member',
            component: NewMemberComponent,
            data: {  title: 'Admin: New Member'},
            children: [

            ]
          },
          {
            path: 'list-members',
            component: AllMembersComponent,
            data: {  title: 'Admin: All Members'},
            children: [

            ]
          },
          {
            path: 'edit-customer/:customerId',
            component: EditCustomerComponent,
            data: {  title: 'Admin: Edit Member(Legacy)'},
            children: [

            ]
          },
          {
            path: 'edit-member/:email',
            component: AdminEditMemberProfileComponent,
            data: {  title: 'Admin: Edit Member'},
            children: [

            ]
          },
          {
            path: 'list-orgs',
            component: ListOrganizationsComponent,
            data: {  title: 'Admin: All Organizations'},
            children: [

            ]
          },
          {
            path: 'add-aircraft/:userId',
            component: AddAircraftComponent,
            data: {  title: 'Admin: New Aircraft (Legacy)'},
          },
          {
            path: 'edit-aircraft/:aircraftId',
            component: EditAircraftComponent,
            data: {  title: 'Admin: Edit Aircraft  (Legacy)'},
          },
          {
            path: 'view-aircraft-dashboard/:aircraftId',
            component: AdminAircraftDashboardComponent,
            data: {  title: 'Admin: Aircraft Dashboard'},
          },
          {
            path: 'edit-aircraft-dashboard',
            component: EditAircraftDashboardComponent,
            data: {  title: 'Admin: Edit Aircraft Dashboard'},
          },
          {
            path: 'view-aircraft-flightlog/:tailNo',
            component: AdminAircraftFlightlogComponent,
            data: {  title: 'Admin: Aircraft Flight Log'},
          },
          {
            path: 'view-all-aircraft-safety-documents/:aircraftId',
            component: AdminViewAllAircraftDocsComponent,
            data: {  title: 'Admin: Aircraft SBs'},
            children: [

            ]
          },
          {
            path: 'manage-bulletins',
            component: ManageBulletinsComponent,
            data: {  title: 'Admin: All Global SBs'},
          },
          {
            path: 'manage-directives',
            component: ManageDirectivesComponent,
            data: {  title: 'Admin: All Global ADs'},
          },
          {
            path: 'bulkbulletinsuploads',
            component: BulkBulletinUploadComponent,
            data: {  title: 'Admin: Bulk SB Upload'},
          },
          {
            path: 'upload-logbook',
            component: UploadAircraftLogBookComponent,
            data: {  title: 'Admin: Upload Aircraft LogBook'},
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: PublicComponent,
    canActivate: [NoAuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent,
        data: {  title: 'Login'},
        children: [

        ]
      },
      // {
      //   path: 'signup',
      //   component: SignupComponent,
      //   data: {  title: 'SignUp'},
      //   children: [

      //   ]
      // },
      {
        path: 'forgotpassword',
        component: ForgotPasswordComponent,
        data: {  title: 'Forgot Password' },
        children: [

        ]
      },
      {
        path: 'auth_action',
        component: AuthActionsComponent,
        data: {  title: 'Auths'},
        children: [

        ]
      }
    ]
  },
  {
    path: '**',
    component: Page404Component,
    data: {  title: 'Page 404!'},
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
