import Swal from 'sweetalert2';
import { UserBaseModel } from '../../models/user-base-model';
import { UserService } from './../../services/user.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MustBeSameAsControValidator } from '../../_custom-validators/must-be-same-as-control-validator';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
  ) { }

  createUserForm: FormGroup;

  newUser: UserBaseModel = new UserBaseModel;

  emailAddress: string;
  password: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  mobilePhone: string;
  pilotLicenseNo: string;

  ngOnInit() {
    this.createUserForm = this.formBuilder.group({
      'firstName': new FormControl(this.newUser.firstName, [
        Validators.required,
        Validators.pattern(/^[a-zA-Z][A-Za-z ,.'-]+$/)
      ]),
      'lastName': new FormControl(this.newUser.lastName, [
        Validators.required,
        Validators.pattern(/^[a-zA-Z][A-Za-z ,.'-]+$/)
      ]),
      'emailAddress': new FormControl(this.newUser.emailAddress, [
        Validators.required,
        Validators.email
      ]),
      'mobilePhone': new FormControl(this.newUser.mobilePhone, [
        // Validators.required,
        //Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
        Validators.pattern(/^\+?[1-9]\d{1,14}$/)
      ]),
      'password1': new FormControl(null),
      'password2': new FormControl(null),
      'isAdmin': new FormControl(this.newUser.isAdmin),
      'pilotLicenseNo': new FormControl(this.newUser.pilotLicenseNo, [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(8)
      ]),
    });

    this.createUserForm.get('password1').setValidators([
      Validators.required,
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.[(!@#\$%\^&\*)*]?)(?=.{6,})/),
      MustBeSameAsControValidator(this.createUserForm.get('password2'), false)
    ])

    this.createUserForm.get('password2').setValidators([
      Validators.required,
      MustBeSameAsControValidator(this.createUserForm.get('password1'))
    ])
  }

  doCreateUser(){
    if(!this.createUserForm.valid){
      for(let i in this.createUserForm.controls) {
        this.createUserForm.controls[i].markAsTouched();
      };

      Swal.fire('Check important fields!', 'Form not filled correctly!', 'error');
      return;
    }

    this.newUser.firstName = this.createUserForm.controls['firstName'].value;
    this.newUser.lastName = this.createUserForm.controls['lastName'].value;
    this.newUser.emailAddress = this.createUserForm.controls['emailAddress'].value;
    this.newUser.mobilePhone = this.createUserForm.controls['mobilePhone'].value;
    this.newUser.isAdmin = this.createUserForm.controls['isAdmin'].value;
    this.newUser.pilotLicenseNo = this.createUserForm.controls['pilotLicenseNo'].value;

    let password: string = this.createUserForm.controls['password1'].value

    this.userService.signUpNewUser({userData: this.newUser, password: password}).subscribe(
      (response: any)=>{
        if(response.status.code == 1000){
          let userId = response.responseData.id;
          this.afterCustomerCreate(userId)
        }
        else{
          if(response.status.message){
            Swal.fire(
              response.status.message,
              response.responseData.join(' '),
              'warning'
            )
          }
        }

      },
      (error)=>{
        if(error.status.message){
          Swal.fire(
            error.status.message,
            error.responseData.join(' '),
            'error'
          )
        }
        else{
          Swal.fire(
            'Sorry!',
            'User sign up failed.',
            'error'
          )
        }
      }
    )
  }

  afterCustomerCreate(userId = null){
    Swal.fire({
      title: 'Customer Created!',
      text: 'Want to view this customer or assign Aircraft?',
      type: 'success',
      showCancelButton: true,
      confirmButtonText: 'Yes. View new customer!',
      cancelButtonText: 'No, create another one.'
    }).then((descision) => {
      if (descision.value) {
        this.router.navigate(['/cga/admin/edit-customer', userId])
      }
      else if (descision.dismiss === Swal.DismissReason.cancel) {
        this.newUser = new UserBaseModel;
        this.createUserForm.reset({});
      }
    })
  }

}
