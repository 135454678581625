import { AircraftServiceBulletinModel } from './../models/aircraft-service-bulletin-model';
import { ServiceBulletinModel } from './../models/service-bulletin-model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceBulletinService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getServiceBulletinById(bulletinId: number){
    return this.httpClient.get(environment.apiBaseUrl + '/servicebulletins/getServiceBulletinById?bulletinId=' + bulletinId)
  }

  findServiceBulletinByName(bulletinName: string, page: number = 1, pageSize: number = 25){
    page = (+page) < 1 ? 1 : page;
    return this.httpClient.get(environment.apiBaseUrl + `/servicebulletins/findServiceBulletinByName?bulletinName=${bulletinName}&page=${page}&pageSize=${pageSize}`)
  }

  getAllServiceBulletins(page: number = 1, pageSize: number = 25){
    page = (+page) < 1 ? 1 : page;
    return this.httpClient.get(environment.apiBaseUrl + '/servicebulletins/getAllServiceBulletins?page=' + page + '&pageSize=' + pageSize)
  }

  createServiceBulletin(serviceBulletin: ServiceBulletinModel){
    return this.httpClient.post(environment.apiBaseUrl + '/servicebulletins/createServiceBulletin', serviceBulletin)
  }

  updateServiceBulletin(serviceBulletin: ServiceBulletinModel){
    return this.httpClient.put(environment.apiBaseUrl + '/servicebulletins/updateServiceBulletin', serviceBulletin)
  }

  ////////////////////////

  getAircraftServiceBulletinById(serviceBulletinId: number){
    return this.httpClient.get(environment.apiBaseUrl + '/servicebulletins/getAircraftServiceBulletinById?id=' + serviceBulletinId)
  }

  findAircraftServiceBulletinByName(aircraftId: number, query, page: number = 1, pageSize: number = 25){
    page = (+page) < 1 ? 1 : page;
    return this.httpClient.get(environment.apiBaseUrl + '/servicebulletins/findAircraftServiceBulletinByName?aircraftId=' + aircraftId + '&query=' + query + '&page=' + page + '&pageSize=' + pageSize)
  }

  addAircraftServiceBulletin(serviceBulletin: AircraftServiceBulletinModel){
    return this.httpClient.post(environment.apiBaseUrl + '/servicebulletins/addAircraftServiceBulletin', serviceBulletin)
  }

  updateAircraftServiceBulletin(serviceBulletin: AircraftServiceBulletinModel){
    return this.httpClient.put(environment.apiBaseUrl + '/servicebulletins/updateAircraftServiceBulletin', serviceBulletin)
  }

  deleteAircraftServiceBulletin(bulletinId: number){
    return this.httpClient.delete(environment.apiBaseUrl + '/servicebulletins/deleteAircraftServiceBulletin?bulletinId=' + bulletinId)
  }


  deleteGlobalServiceBulletin(bulletinId: number){
    return this.httpClient.delete(environment.apiBaseUrl + '/servicebulletins/deleteGlobalServiceBulletin?bulletinId=' + bulletinId)
  }


  fetchAllAircraftDashboardSBs(aircraftId: number, todayFlightHours: number){
    return this.httpClient.get(environment.apiBaseUrl + '/servicebulletins/fetchAllAircraftDashboardSBs?aircraftId=' + aircraftId + "&todayFlightHours=" + todayFlightHours )
  }

}
