import { OptionLabelsAndValuesModel } from './option-labels-and-values-model';

export class OrganizationTypeOptionsModel {
  constructor() {
    this.options = [
      {label: 'Individual', value: 'INDIVIDUAL'},
      {label: 'School', value: 'SCHOOL'},
      {label: 'Partnership', value: 'PARTNERSHIP'},
    ];
  }
  options: Array<OptionLabelsAndValuesModel>;
}
