import { AircraftLogBookTypeModel } from "./aircraft-logbook-type-model";

export class AircraftLogBookCarouselItemModel {
  constructor(type: AircraftLogBookTypeModel, logBookUrl: string) {
    this.type = type;
    this.logBookUrl = logBookUrl;
  }

  type: AircraftLogBookTypeModel;
  logBookUrl: string;
}
