import { Router } from '@angular/router';
import { ServiceBulletinModel } from './../../../../models/service-bulletin-model';
import { NgbDateCustomAndUSAParserFormatter } from './../../../../services/ngb-date-custom-and-usa-parser-formatter';
import { AdSbConditionOptionsModel } from './../../../../models/ad-sb-condition-options-model';
import { UtilitiesService } from './../../../../services/utilities.service';
import { AircraftServiceBulletinModel } from './../../../../models/aircraft-service-bulletin-model';
import { UserAircraftModel } from './../../../../models/user-aircraft-model';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { AircraftService } from './../../../../services/aircraft.service';
import { ServiceBulletinService } from './../../../../services/service-bulletin.service';
import { Component, OnInit, Input, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { Observable, SubscriptionLike, of } from 'rxjs';
import { startWith, tap, debounceTime, distinctUntilChanged, switchMap, catchError, map } from 'rxjs/operators';
import {
  NgbTypeaheadSelectItemEvent,
  NgbDateAdapter, NgbDateNativeAdapter,
  NgbDatepickerConfig,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { NumberToTenths } from '../../../../_custom-validators/number-to-tenths-validator';
import { AllControlsNeededIfValueIsValidator } from '../../../../_custom-validators/all-controls-needed-if-value-is-validator';
import {
  OneOfControlsIsNeededIgnoreIfAnotherControlIsValueValidator
} from '../../../../_custom-validators/one-of-controls-is-needed-ignore-if-another-control-is-value-validator';
import {
  SbApplicationOptionsModel
} from '../../../../models/sb-application-options-model';
import {
  DependsOnControlIfControlIsValidator
} from '../../../../_custom-validators/depends-on-control-if-control-is-validator';
import {
  HasDependantIfControlIsControlValidator
} from '../../../../_custom-validators/has-dependant-if-control-is-validator';
import {
  NgbModal,
  NgbModalOptions,
  ModalDismissReasons
} from '@ng-bootstrap/ng-bootstrap';
import { DocumentConfigurationUiComponent } from '../../../../shared/document-configuration-ui/document-configuration-ui.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-manage-dashboard-bulletins',
  templateUrl: './manage-dashboard-bulletins.component.html',
  styleUrls: ['./manage-dashboard-bulletins.component.scss'],
  providers: [
    NgbDatepickerConfig,
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomAndUSAParserFormatter}
  ]
})
export class ManageDashboardBulletinsComponent implements OnInit, OnDestroy, OnChanges {
  @Input () aircraftId: number;
  @Input () tabChanges: Observable<void>;

  constructor(
    private ngbDatePickerConfig: NgbDatepickerConfig,
    private aircraftService: AircraftService,
    private serviceBulletinService: ServiceBulletinService,
    private formBuilder: FormBuilder,
    private utilitiesService: UtilitiesService,
    private modalService: NgbModal,
    private location: Location,
    private router: Router
  ) {
    ngbDatePickerConfig.minDate = {year: 1900, month: 1, day: 1};
  }


  viewedPdfSrc: string;
  viewedPdfSbTitle: string;

  isWorking = false;

  tabChangesSubscription$: SubscriptionLike;

  conditionOptions: AdSbConditionOptionsModel = new AdSbConditionOptionsModel;

  applicationOptions: SbApplicationOptionsModel = new SbApplicationOptionsModel;

  theAircraft: UserAircraftModel = new UserAircraftModel;

  allAircraftServiceBulletins: Array<AircraftServiceBulletinModel> = [];

  aircraftAsSearchSubscriber$: SubscriptionLike;
  aircraftServiceBulletinSearchForm: FormGroup;
  aircraftServiceBulletinFilter: string;
  ServiceBulletinsLoading = false;

  newAircraftServiceBulletin: AircraftServiceBulletinModel = new AircraftServiceBulletinModel;
  newAircraftServiceBulletinForm: FormGroup;
  newAircraftServiceBulletinSearchFailed = false;
  newAircraftServiceBulletinSearching = false;
  inAddNewServiceBulletinMode = false;

  editAircraftServiceBulletin: AircraftServiceBulletinModel = new AircraftServiceBulletinModel;
  editAircraftServiceBulletinForm: FormGroup;
  editAircraftServiceBulletinSearchFailed = false;
  editAircraftServiceBulletinSearching = false;
  inEditServiceBulletinMode = false;

  newDueHoursSubscriber$: SubscriptionLike;
  newDueDateSubscriber$: SubscriptionLike;
  newApplicationSubscriber$: SubscriptionLike;
  newTerminatingActionSubscriber$: SubscriptionLike;
  newServiceBulletinFormSubscriber$: SubscriptionLike;

  editDueHoursSubscriber$: SubscriptionLike;
  editDueDateSubscriber$: SubscriptionLike;
  editApplicationSubscriber$: SubscriptionLike;
  editTerminatingActionSubscriber$: SubscriptionLike;
  editServiceBulletinFormSubscriber$: SubscriptionLike;

  totalAircraftSb: number = null;
  page = 1;
  pageSize = 25;

  ngOnInit() {
    // init aircraft object
    this.getAircraft();

    // add tab parameter in url
    const fullUrl: Array<string> = this.router.url.split('?');
    if (fullUrl[0]) {
      this.location.go( fullUrl[0] + '?t=bulletin');
    }

    this.tabChangesSubscription$ = this.tabChanges.subscribe(
      () => {
        this.inAddNewServiceBulletinMode = false;
      }
    );

    this.aircraftServiceBulletinSearchForm = this.formBuilder.group({
      'queryString': new FormControl('')
    });

    this.aircraftAsSearchSubscriber$ = this.aircraftServiceBulletinSearchForm.controls['queryString'].valueChanges.pipe(
      startWith(''),
      tap((x) => this.ServiceBulletinsLoading = true)
    ).subscribe(
      (query) => {
        this.resetPage();
        this.findAircraftServiceBulletinByName(query);
      }
    );

    this.initNewBulletinForm();
    this.initEditBulletinForm();
  }

  resetPage() {
    this.page = 1;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.aircraftId && changes.aircraftId.currentValue) {
      this.aircraftId = changes.aircraftId.currentValue;

      if (this.aircraftId) {
        this.getAircraft();

        this.inEditServiceBulletinMode = false;

        this.initNewBulletinForm();
        this.initEditBulletinForm();

        this.resetPage();

        this.findAircraftServiceBulletinByName();
      }
    }
  }

  getHoursOrNA(val) {
    return val === null ? 'N/A' : `${val} Hours`;
  }



  initNewBulletinForm() {
    this.newAircraftServiceBulletinForm = this.formBuilder.group({
      'bulletin': new FormControl(null, [Validators.required]),
      'publishedDate': new FormControl(null, [Validators.required]),
      'dueDate': new FormControl(null),
      'dueHours': new FormControl(null),
      'condition': new FormControl('NA'),
      'application': new FormControl('Applies'),
      'isRecurring': new FormControl(null),
      'notApplyReason': new FormControl(null, [Validators.maxLength(1000)]),
      'performedDate': new FormControl(null),
      'performedHour': new FormControl(null),
      'isTerminated': new FormControl(false),
      'performanceNote': new FormControl(null, [Validators.maxLength(3000)]),
    });

    this.newAircraftServiceBulletinForm.get('performedDate').setValidators([
      DependsOnControlIfControlIsValidator(this.newAircraftServiceBulletinForm.get('application'), 'Performed')
    ]);

    this.newAircraftServiceBulletinForm.get('application').setValidators([
      Validators.required,
      HasDependantIfControlIsControlValidator(this.newAircraftServiceBulletinForm.get('performedDate'), 'Performed'),
      HasDependantIfControlIsControlValidator(this.newAircraftServiceBulletinForm.get('notApplyReason'), 'NotApply')
    ]);

    this.newAircraftServiceBulletinForm.get('notApplyReason').setValidators([
      DependsOnControlIfControlIsValidator(this.newAircraftServiceBulletinForm.get('application'), 'NotApply')
    ]);

    this.newAircraftServiceBulletinForm.get('dueDate').setValidators([
      OneOfControlsIsNeededIgnoreIfAnotherControlIsValueValidator(
        this.newAircraftServiceBulletinForm.get('application'),
        'NotApply',
        [this.newAircraftServiceBulletinForm.get('dueHours')],
        [this.newAircraftServiceBulletinForm.get('isTerminated')]
      )
    ]);

    this.newAircraftServiceBulletinForm.get('dueHours').setValidators([
      OneOfControlsIsNeededIgnoreIfAnotherControlIsValueValidator(
        this.newAircraftServiceBulletinForm.get('application'),
        'NotApply',
        [this.newAircraftServiceBulletinForm.get('dueDate')],
        [this.newAircraftServiceBulletinForm.get('isTerminated')]
      ),
      NumberToTenths(true)
    ]);

    this.newAircraftServiceBulletinForm.get('performedHour').setValidators([,
      NumberToTenths(true)
    ]);

    this.newAircraftServiceBulletinForm.get('condition').setValidators([
      Validators.required,
      AllControlsNeededIfValueIsValidator(
        [
          this.newAircraftServiceBulletinForm.get('dueDate'),
          this.newAircraftServiceBulletinForm.get('dueHours')
        ],
        'WhichOccursFirst'
      ),
      AllControlsNeededIfValueIsValidator(
        [
          this.newAircraftServiceBulletinForm.get('dueDate'),
          this.newAircraftServiceBulletinForm.get('dueHours')
        ],
        'WhichHappensLast'
      )
    ]);

    this.newDueHoursSubscriber$ = this.newAircraftServiceBulletinForm.get('dueHours').valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(
      (value: any) => {
        this.newAircraftServiceBulletinForm.get('condition').updateValueAndValidity();
      }
    );

    this.newDueDateSubscriber$ = this.newAircraftServiceBulletinForm.get('dueDate').valueChanges
    .pipe(
      distinctUntilChanged()
    ).subscribe(
      (value: any) => {
        this.newAircraftServiceBulletinForm.get('condition').updateValueAndValidity();
      }
    );

    this.newTerminatingActionSubscriber$ = this.newAircraftServiceBulletinForm.get('isTerminated').valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(
      (value: any) => {
        this.newAircraftServiceBulletinForm.get('dueHours').updateValueAndValidity();
        this.newAircraftServiceBulletinForm.get('dueDate').updateValueAndValidity();
      }
    );

    this.newApplicationSubscriber$ = this.newAircraftServiceBulletinForm.get('application').valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(
      (value: any) => {
        this.newAircraftServiceBulletinForm.get('dueHours').updateValueAndValidity();
        this.newAircraftServiceBulletinForm.get('dueDate').updateValueAndValidity();

        if (value == 'NotApply') {
          this.newAircraftServiceBulletinForm.get('condition').disable();
          this.newAircraftServiceBulletinForm.get('isRecurring').disable();
          this.newAircraftServiceBulletinForm.get('isRecurring').setValue(false);
        } else {
          this.newAircraftServiceBulletinForm.get('condition').enable();
          this.newAircraftServiceBulletinForm.get('isRecurring').enable();
        }

        if (value !== 'Performed') {
          this.newAircraftServiceBulletinForm.get('isTerminated').value ? 
              this.newAircraftServiceBulletinForm.get('isTerminated').setValue(false) : null;
          this.newAircraftServiceBulletinForm.get('performedDate').value ? 
              this.newAircraftServiceBulletinForm.get('performedDate').setValue(null) : null;
          this.newAircraftServiceBulletinForm.get('performedHour').value ? 
              this.newAircraftServiceBulletinForm.get('performedHour').setValue(null) : null;
          this.newAircraftServiceBulletinForm.get('performanceNote').value ? 
              this.newAircraftServiceBulletinForm.get('performanceNote').setValue(null) : null;
        }
      }
    );

    this.newServiceBulletinFormSubscriber$ = this.newAircraftServiceBulletinForm.valueChanges
    .pipe(
      distinctUntilChanged()
    )
    .subscribe(
      (value: any) => {

        if (this.newAircraftServiceBulletinForm.get('isTerminated').value 
          && (this.newAircraftServiceBulletinForm.get('condition').value !== 'NA')
        ) {
          this.newAircraftServiceBulletinForm.get('condition').setValue('NA');
        }

        if ((this.newAircraftServiceBulletinForm.get('application').value == 'NotApply') &&
          (this.newAircraftServiceBulletinForm.get('condition').value !== 'NA')
        ) {
          this.newAircraftServiceBulletinForm.get('condition').setValue('NA');
        }
      }
    );
  }

  openAddServiceBulletinsModal() {
    // open modal with document config component
    const addServiceBulletinsModal = this.modalService.open(DocumentConfigurationUiComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'document-config-ui',
      size: 'lg'
    });

    // add instance name
    addServiceBulletinsModal.componentInstance.name = 'Service Bulletins Selector';

    // air craft variable
    addServiceBulletinsModal.componentInstance.aircraft = this.theAircraft;

    // add  the document type
    addServiceBulletinsModal.componentInstance.type = 'service bulletins';

    addServiceBulletinsModal.result.then((result) => {
      console.log(`Closed with: ${result}`);
      this.ServiceBulletinsLoading = true;
      this.findAircraftServiceBulletinByName();
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
      this.ServiceBulletinsLoading = true;
      this.findAircraftServiceBulletinByName();
    });
  }

  initEditBulletinForm() {
    this.editAircraftServiceBulletinForm = this.formBuilder.group({
      'editDueDate': new FormControl(null),
      'editDueHours': new FormControl(null),
      'editCondition': new FormControl(null),
      'editApplication': new FormControl(null),
      'editIsRecurring': new FormControl(null),
      'editNotApplyReason': new FormControl(null, [Validators.maxLength(1000)]),
      'editPerformedDate': new FormControl(null),
      'editPerformedHour': new FormControl(null),
      'editIsTerminated': new FormControl(false),
      'editPerformanceNote': new FormControl(false, [Validators.maxLength(3000)])
    });

    this.editAircraftServiceBulletinForm.get('editPerformedDate').setValidators([
      DependsOnControlIfControlIsValidator(this.editAircraftServiceBulletinForm.get('editApplication'), 'Performed')
    ]);

    this.editAircraftServiceBulletinForm.get('editApplication').setValidators([
      Validators.required,
      HasDependantIfControlIsControlValidator(this.editAircraftServiceBulletinForm.get('editPerformedDate'), 'Performed'),
      HasDependantIfControlIsControlValidator(this.editAircraftServiceBulletinForm.get('editNotApplyReason'), 'NotApply')
    ]);

    this.editAircraftServiceBulletinForm.get('editNotApplyReason').setValidators([
      DependsOnControlIfControlIsValidator(this.editAircraftServiceBulletinForm.get('editApplication'), 'NotApply')
    ]);

    this.editAircraftServiceBulletinForm.get('editDueDate').setValidators([
      OneOfControlsIsNeededIgnoreIfAnotherControlIsValueValidator(
        this.editAircraftServiceBulletinForm.get('editApplication'),
        'NotApply',
        [this.editAircraftServiceBulletinForm.get('editDueHours')],
        [this.editAircraftServiceBulletinForm.get('editIsTerminated')]
      )
    ]);

    this.editAircraftServiceBulletinForm.get('editDueHours').setValidators([
      OneOfControlsIsNeededIgnoreIfAnotherControlIsValueValidator(
        this.editAircraftServiceBulletinForm.get('editApplication'),
        'NotApply',
        [this.editAircraftServiceBulletinForm.get('editDueDate')],
        [this.editAircraftServiceBulletinForm.get('editIsTerminated')]
      ),
      NumberToTenths(true)
    ]);

    this.editAircraftServiceBulletinForm.get('editPerformedHour').setValidators([
      NumberToTenths(true)
    ]);

    this.editAircraftServiceBulletinForm.get('editCondition').setValidators([
      Validators.required,
      AllControlsNeededIfValueIsValidator(
        [
          this.editAircraftServiceBulletinForm.get('editDueDate'),
          this.editAircraftServiceBulletinForm.get('editDueHours')
        ],
        'WhichOccursFirst'
      ),
      AllControlsNeededIfValueIsValidator(
        [
          this.editAircraftServiceBulletinForm.get('editDueDate'),
          this.editAircraftServiceBulletinForm.get('editDueHours')
        ],
        'WhichHappensLast'
      )
    ]);

    this.editDueHoursSubscriber$ = this.editAircraftServiceBulletinForm.get('editDueHours').valueChanges.subscribe(
      (value: any) => {
        this.editAircraftServiceBulletinForm.get('editCondition').updateValueAndValidity();
      }
    );

    this.editDueDateSubscriber$ = this.editAircraftServiceBulletinForm.get('editDueDate').valueChanges.subscribe(
      (value: any) => {
        this.editAircraftServiceBulletinForm.get('editCondition').updateValueAndValidity();
      }
    );

    // subscribe to TerminatingAction: Update Duehours & DueDateon valueChange
    this.editTerminatingActionSubscriber$ = this.editAircraftServiceBulletinForm.get('editIsTerminated').valueChanges.subscribe(
      (value: any) => {
        this.editAircraftServiceBulletinForm.get('editDueHours').updateValueAndValidity();
        this.editAircraftServiceBulletinForm.get('editDueDate').updateValueAndValidity();
      }
    );

    // Subscribe to Application Dropdown for valueChange...
    this.editApplicationSubscriber$ = this.editAircraftServiceBulletinForm.get('editApplication').valueChanges.subscribe(
      (value: any) => {
        this.editAircraftServiceBulletinForm.get('editDueHours').updateValueAndValidity();
        this.editAircraftServiceBulletinForm.get('editDueDate').updateValueAndValidity();

        if (value == 'NotApply') {
          this.editAircraftServiceBulletinForm.get('editCondition').disable();
          this.editAircraftServiceBulletinForm.get('editIsRecurring').disable();
          this.editAircraftServiceBulletinForm.get('editIsRecurring').setValue(false);
        } else {
          this.editAircraftServiceBulletinForm.get('editCondition').enable();
          this.editAircraftServiceBulletinForm.get('editIsRecurring').enable();
        }

        if (value !== 'Performed') {
          if (this.editAircraftServiceBulletinForm.get('editIsTerminated').value) {
            this.editAircraftServiceBulletinForm.get('editIsTerminated').setValue(false);
          }

          if (this.editAircraftServiceBulletinForm.get('editPerformedDate').value) {
            this.editAircraftServiceBulletinForm.get('editPerformedDate').setValue(null);
          }

          if (this.editAircraftServiceBulletinForm.get('editPerformedHour').value) {
            this.editAircraftServiceBulletinForm.get('editPerformedHour').setValue(null);
          }

          if (this.editAircraftServiceBulletinForm.get('editPerformanceNote').value) {
            this.editAircraftServiceBulletinForm.get('editPerformanceNote').setValue(null);
          }
        }
      }
    );

    // Subscribe to EditBuletinForm for valueChange...
    this.editServiceBulletinFormSubscriber$ = this.editAircraftServiceBulletinForm.valueChanges.subscribe(
      (value: any) => {

        // if isRecurring and isTerminateAction are both true, set condition to NA
        if (
          this.editAircraftServiceBulletinForm.get('editIsRecurring').value && 
          this.editAircraftServiceBulletinForm.get('editIsTerminated').value && 
          (this.editAircraftServiceBulletinForm.get('editCondition').value !== 'NA')
        ) {
          this.editAircraftServiceBulletinForm.get('editCondition').setValue('NA');
        }

        // if Application is NOTAPPLY, set condition to NA
        if (
          (this.editAircraftServiceBulletinForm.get('editApplication').value == 'NotApply') 
          && (this.editAircraftServiceBulletinForm.get('editCondition').value !== 'NA')
        ) {
          this.editAircraftServiceBulletinForm.get('editCondition').setValue('NA');
        }
      }
    );

  }

  toggleInAddNewBulletinMode() {
    if (!this.inAddNewServiceBulletinMode) {
      this.newAircraftServiceBulletin = new AircraftServiceBulletinModel;
      this.newAircraftServiceBulletinForm.reset();
      this.newAircraftServiceBulletinForm.get('condition').setValue('NA');
      this.newAircraftServiceBulletinForm.get('application').setValue('Applies');

      this.inAddNewServiceBulletinMode = true;
      return;
    } else {
      this.newAircraftServiceBulletin = null;
      this.inAddNewServiceBulletinMode = false;
    }
  }

  getAircraft() {
    this.aircraftService.getAircraftById(this.aircraftId).subscribe(
      (response: any) => {
        if (response.status.code == 1000) {
          this.theAircraft = response.responseData;
        }
      }
    );
  }

  findAircraftServiceBulletinByName(query: string = '') {
    this.ServiceBulletinsLoading = true;
    this.serviceBulletinService.findAircraftServiceBulletinByName(this.aircraftId, query, this.page, this.pageSize).subscribe(
      (response: any) => {
        if (+response.status.code === 1000) {
          this.ServiceBulletinsLoading = false;
          this.allAircraftServiceBulletins = response.responseData.rows;
          this.totalAircraftSb = response.responseData.count;
        }
      }
    );
  }

  ////////// ADD DIRECTIVE SEARCH TYPEHEAD ///////////
  newAircraftServiceBulletinTypeHeadSearch = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.newAircraftServiceBulletinSearching = true),
    switchMap(term => {
      this.newAircraftServiceBulletin = new AircraftServiceBulletinModel;
      return this.serviceBulletinService.findServiceBulletinByName(term).pipe(
        tap(() => this.newAircraftServiceBulletinSearchFailed = false),
        catchError(() => {
          this.newAircraftServiceBulletinSearchFailed = true;
          return of([]);
        }),
        map((res: any) => {
        const arr: Array<any> = [];
          res.responseData.rows.forEach(element => {
            arr.push(element);
          });
          return arr;
        }),
      );
    }
  ),
  tap(() => this.newAircraftServiceBulletinSearching = false)
  )
  //

  // from getters for validations
  get bulletin() { return this.newAircraftServiceBulletinForm.controls['bulletin']; }
  // from getters for validations
  get application() { return this.newAircraftServiceBulletinForm.controls['application']; }

  // On Select typehead(make) options...
  onTypeHeadSelected(ev: NgbTypeaheadSelectItemEvent){
    this.newAircraftServiceBulletin.service_bulletin = ev.item as ServiceBulletinModel;
    this.newAircraftServiceBulletinForm.controls['publishedDate'].setValue(ev.item.publishedDate);
  }
  // format typeheads data received,  before display
  typeHeadBulletinFormatter = (bulletinObj: {id: string, bulletinName: string, publishedDate: string, subject: string}) => {
    return bulletinObj.bulletinName ? bulletinObj.bulletinName : '';
  }

  getAdSbTypeText(r: ServiceBulletinModel) {
    return this.utilitiesService.getAdSbTypeText(r);
  }

  getAdSbModels(sb: ServiceBulletinModel) {
    return this.utilitiesService.getAdSbModels(sb);
  }

  getBulletinModels(r: ServiceBulletinModel) {
    const bulletinType = r.adSbType ? ' : for ' + (r.adSbType.toLowerCase()) + 's' : '';
    return bulletinType ? bulletinType : '';
  }

  addAircraftServiceBulletin() {
    if (this.newAircraftServiceBulletinForm.controls['application'].value == 'Applies') {
      this.newAircraftServiceBulletinForm.controls['performedDate'].setValue(null);
      this.newAircraftServiceBulletinForm.controls['notApplyReason'].setValue(null);
    }

    if (this.newAircraftServiceBulletinForm.controls['application'].value == 'Performed') {
      this.newAircraftServiceBulletinForm.controls['notApplyReason'].setValue(null);
    }

    if (this.newAircraftServiceBulletinForm.controls['application'].value == 'NotApply') {
      this.newAircraftServiceBulletinForm.controls['performedDate'].setValue(null);
    }

    if (this.newAircraftServiceBulletinForm.controls['application'].value !==  'Performed') {
      this.newAircraftServiceBulletinForm.controls['isTerminated'].setValue(false);
      this.newAircraftServiceBulletinForm.controls['performedDate'].setValue(null);
      this.newAircraftServiceBulletinForm.controls['performedHour'].setValue(null);
      this.newAircraftServiceBulletinForm.controls['performanceNote'].setValue(null);
    }

    if (!this.newAircraftServiceBulletinForm.valid) {
      Object.values(this.newAircraftServiceBulletinForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );


      Swal.fire('Check important fields!', 'Form not filled correctly!', 'error');
      return;
    }

    this.isWorking = true;

    this.newAircraftServiceBulletin.dueDate = this.newAircraftServiceBulletinForm.controls['dueDate'].value;
    this.newAircraftServiceBulletin.dueHours = this.newAircraftServiceBulletinForm.controls['dueHours'].value;
    this.newAircraftServiceBulletin.condition = this.newAircraftServiceBulletinForm.controls['condition'].value;
    this.newAircraftServiceBulletin.application = this.newAircraftServiceBulletinForm.controls['application'].value;
    this.newAircraftServiceBulletin.isRecurring = this.newAircraftServiceBulletinForm.controls['isRecurring'].value;
    this.newAircraftServiceBulletin.terminateAction = this.newAircraftServiceBulletinForm.controls['isTerminated'].value;
    this.newAircraftServiceBulletin.notApplyReason = this.newAircraftServiceBulletin.application == 'NotApply' ? 
          this.newAircraftServiceBulletinForm.controls['notApplyReason'].value : null;
    this.newAircraftServiceBulletin.lastPerformedDate = this.newAircraftServiceBulletin.application == 'Performed' ? 
          this.newAircraftServiceBulletinForm.controls['performedDate'].value : null;
    this.newAircraftServiceBulletin.lastPerformedHour = this.newAircraftServiceBulletin.application == 'Performed' ? 
          this.newAircraftServiceBulletinForm.controls['performedHour'].value : null;
    this.newAircraftServiceBulletin.performanceNote = this.newAircraftServiceBulletin.application == 'Performed' ? 
          this.newAircraftServiceBulletinForm.controls['performanceNote'].value : null;
    this.newAircraftServiceBulletin.aircraftId = this.aircraftId;


    this.serviceBulletinService.addAircraftServiceBulletin(this.newAircraftServiceBulletin).subscribe(
      (response: any) => {

        this.isWorking = false;
        if (+response.status.code === 1000) {
          this.inAddNewServiceBulletinMode = false;
          this.newAircraftServiceBulletinForm.reset();
          this.editAircraftServiceBulletin = new AircraftServiceBulletinModel;
          this.resetPage();
          this.findAircraftServiceBulletinByName();
          Swal.fire(
            'Great!', 'Service Bulletin added to dashboard.', 'success'
          );
        } else {
          Swal.fire(
            'Yikes!', 'Service Bulletin not added to dashboard.', 'error'
          );
        }

      },
      (error: any) => {

        this.isWorking = false;
        const errorResponse = error.error ? error.error : error;
        if (errorResponse.status.message) {
          Swal.fire(
            errorResponse.status.message,
            errorResponse.responseData.join(' '),
            'error'
          );
        } else {
          Swal.fire(
            'Oops!',
            'Failed to add Service Bulletin to Aircraft Dashboard.',
            'error'
          );
        }
      }
    );
  }



  // Edit
  loadAircraftBulletinForEdit(bulletin: AircraftServiceBulletinModel) {
    this.editAircraftServiceBulletin = bulletin;

    this.editAircraftServiceBulletinForm.get('editDueDate').setValue(
      bulletin.dueDate ? this.utilitiesService.parseISOtoDateObj(bulletin.dueDate) : null
    );

    this.editAircraftServiceBulletinForm.get('editDueHours').setValue(
      bulletin.dueHours === null ? null : bulletin.dueHours
    );

    this.editAircraftServiceBulletinForm.get('editCondition').setValue(
      (bulletin.condition ? bulletin.condition : 'NA'),
      [Validators.required]
    );

    this.editAircraftServiceBulletinForm.get('editApplication').setValue(
      (bulletin.application ? bulletin.application : 'Applies'),
      [Validators.required]
    );

    this.editAircraftServiceBulletinForm.get('editIsRecurring').setValue(bulletin.isRecurring);

    this.editAircraftServiceBulletinForm.get('editIsTerminated').setValue(bulletin.terminateAction);

    this.editAircraftServiceBulletinForm.get('editNotApplyReason').setValue(bulletin.notApplyReason);

    this.editAircraftServiceBulletinForm.get('editPerformedDate').setValue(
      bulletin.lastPerformedDate ? this.utilitiesService.parseISOtoDateObj(bulletin.lastPerformedDate) : null
    );

    this.editAircraftServiceBulletinForm.get('editPerformedHour').setValue(
      bulletin.lastPerformedHour ? bulletin.lastPerformedHour : null
    );

    this.editAircraftServiceBulletinForm.get('editPerformanceNote').setValue(
      bulletin.performanceNote ? bulletin.performanceNote : null
    );

  }


  unLoadBulletinForEdit() {
    this.editAircraftServiceBulletin = new AircraftServiceBulletinModel;
    this.editAircraftServiceBulletinForm.reset();
  }

  updateAircraftServiceBulletin() {
    if (this.editAircraftServiceBulletinForm.controls['editApplication'].value == 'Applies') {
      this.editAircraftServiceBulletinForm.controls['editPerformedDate'].setValue(null);
      this.editAircraftServiceBulletinForm.controls['editNotApplyReason'].setValue(null);
    }

    if (this.editAircraftServiceBulletinForm.controls['editApplication'].value == 'Performed') {
      this.editAircraftServiceBulletinForm.controls['editNotApplyReason'].setValue(null);
    }

    if (this.editAircraftServiceBulletinForm.controls['editApplication'].value == 'NotApply') {
      this.editAircraftServiceBulletinForm.controls['editDueDate'].setValue(null);
      this.editAircraftServiceBulletinForm.controls['editDueHours'].setValue(null);
      this.editAircraftServiceBulletinForm.controls['editPerformedDate'].setValue(null);
    }

    if (this.editAircraftServiceBulletinForm.controls['editApplication'].value !== 'Performed') {
      this.editAircraftServiceBulletinForm.controls['editIsTerminated'].setValue(false);
      this.editAircraftServiceBulletinForm.controls['editPerformedDate'].setValue(null);
      this.editAircraftServiceBulletinForm.controls['editPerformedHour'].setValue(null);
      this.editAircraftServiceBulletinForm.controls['editPerformanceNote'].setValue(null);
    }

    if (!this.editAircraftServiceBulletinForm.valid) {
      Object.values(this.editAircraftServiceBulletinForm.controls).forEach(
        (control: AbstractControl) => {
          control.markAsTouched();
        }
      );

      Swal.fire('Check important fields!', 'Form not filled correctly!', 'error');
      return;
    }

    this.isWorking = true;

    this.editAircraftServiceBulletin.dueDate = this.editAircraftServiceBulletinForm.controls['editDueDate'].value;

    this.editAircraftServiceBulletin.dueHours = this.editAircraftServiceBulletinForm.controls['editDueHours'].value === null ? null :
        (
          this.editAircraftServiceBulletinForm.controls['editDueHours'].value === '' ?
          null : this.editAircraftServiceBulletinForm.controls['editDueHours'].value
        );

    if (this.editAircraftServiceBulletin.application.toLowerCase() === 'notapply') {
      this.editAircraftServiceBulletin.dueHours = null;
    }

    this.editAircraftServiceBulletin.condition = this.editAircraftServiceBulletinForm.controls['editCondition'].value;
    this.editAircraftServiceBulletin.isRecurring = this.editAircraftServiceBulletinForm.controls['editIsRecurring'].value;
    this.editAircraftServiceBulletin.terminateAction = this.editAircraftServiceBulletinForm.controls['editIsTerminated'].value;
    this.editAircraftServiceBulletin.application = this.editAircraftServiceBulletinForm.controls['editApplication'].value;

    this.editAircraftServiceBulletin.notApplyReason = this.editAircraftServiceBulletin.application == 'NotApply' ? 
      this.editAircraftServiceBulletinForm.controls['editNotApplyReason'].value : null;

    this.editAircraftServiceBulletin.lastPerformedDate = this.editAircraftServiceBulletin.application == 'Performed' ? 
      this.editAircraftServiceBulletinForm.controls['editPerformedDate'].value : null;

    this.editAircraftServiceBulletin.lastPerformedHour = this.editAircraftServiceBulletin.application == 'Performed' ? 
      this.editAircraftServiceBulletinForm.controls['editPerformedHour'].value : null;

    this.editAircraftServiceBulletin.performanceNote = this.editAircraftServiceBulletin.application == 'Performed' ? 
      this.editAircraftServiceBulletinForm.controls['editPerformanceNote'].value : null;

    this.editAircraftServiceBulletin.aircraftId = this.aircraftId;

    this.serviceBulletinService.updateAircraftServiceBulletin(this.editAircraftServiceBulletin).subscribe(
      (response: any) => {

        this.isWorking = false;
        if (+response.status.code === 1000) {
          this.inEditServiceBulletinMode = false;
          this.editAircraftServiceBulletinForm.reset();
          this.editAircraftServiceBulletin = new AircraftServiceBulletinModel;

          /**
           * retain page, but filter with current query.
           * This will keep same list in view
          **/

          this.findAircraftServiceBulletinByName(
            this.aircraftServiceBulletinSearchForm.controls['queryString'].value
          );
          Swal.fire(
            'Great!', 'Service Bulletin updated on dashboard.', 'success'
          );
        } else {
          Swal.fire(
            'Yikes!', 'Service Bulletin not updated.', 'error'
          );
        }

      },
      (error: any) => {

        this.isWorking = false;
        const errorResponse = error.error ? error.error : error;
        if (errorResponse.status.message) {
          Swal.fire(
            errorResponse.status.message,
            errorResponse.responseData.join(' '),
            'error'
          );
        } else {
          Swal.fire(
            'Oops!',
            'Failed to update Service Bulletin.',
            'error'
          );
        }
      }
    );
  }

  onTabChange() {
    this.inAddNewServiceBulletinMode = false;
    this.newAircraftServiceBulletinForm.reset();
  }

  deleteAircraftServiceBulletin(bulletin: AircraftServiceBulletinModel) {
    Swal.fire({
      title: 'Are you sure?',
      text: `Please confirm you want to delete bulletin ${bulletin.service_bulletin.bulletinName} ?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes. I know what I\'m doing!',
      cancelButtonText: 'No, take me back.'
    }).then((descision) => {
      if (descision.value) {

        this.serviceBulletinService.deleteAircraftServiceBulletin(bulletin.id).subscribe(
          (response: any) => {
            if (response.status.code == 1000) {
              this.inEditServiceBulletinMode = false;
              this.editAircraftServiceBulletin = new AircraftServiceBulletinModel;
              this.resetPage();
              this.findAircraftServiceBulletinByName();
              Swal.fire(
                'Deleted!', 'Service Bulletin deleted from dashboard.', 'success'
              );
            } else {
              Swal.fire(
                'Yikes!', 'Service Bulletin not deleted.', 'error'
              );
            }

          },
          (error: any) => {

            if (error.status.message) {
              Swal.fire(
                error.status.message,
                error.responseData.join(' '),
                'error'
              );
            } else {
              Swal.fire(
                'Oops!',
                'Failed to delete Service Bulletin.',
                'error'
              );
            }
          }
        );
      } else if (descision.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });

  }

  paginationPageChange($event) {
    this.page = $event;
    this.findAircraftServiceBulletinByName(this.aircraftServiceBulletinSearchForm.get('queryString').value);
  }


  openModal(content, pdfUrl: string, title: string = 'View Bulletin') {
    this.viewedPdfSrc = pdfUrl;
    this.viewedPdfSbTitle = title;

    this.modalService.open(content, { size: 'lg' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  getBulletinModelManufacturers(bulletin: ServiceBulletinModel) {
    return this.aircraftService.getSbOrAdModelManufacturers(bulletin);
  }

  /**
   * @description sets due date to today's date for the current document config
   */
  dueNowSBNew(event) {
    // get current date time
    const _date = new Date();
    this.newAircraftServiceBulletinForm.get('dueDate').setValue(_date);
  }

  dueNowSBUpdate(event) {
    // get current date time
    const _date = new Date();
    this.editAircraftServiceBulletinForm.get('editDueDate').setValue(_date);
  }

  ngOnDestroy() {
    if (this.tabChangesSubscription$) {
      this.tabChangesSubscription$.unsubscribe();
    }

    if (this.aircraftAsSearchSubscriber$) {
      this.aircraftAsSearchSubscriber$.unsubscribe();
    }


    if (this.newDueHoursSubscriber$) {
      this.newDueHoursSubscriber$.unsubscribe();
    }

    if (this.newDueDateSubscriber$) {
      this.newDueDateSubscriber$.unsubscribe();
    }

    if (this.newApplicationSubscriber$) {
      this.newApplicationSubscriber$.unsubscribe();
    }

    if (this.newTerminatingActionSubscriber$) {
      this.newTerminatingActionSubscriber$.unsubscribe();
    }

    if (this.editDueHoursSubscriber$) {
      this.editDueHoursSubscriber$.unsubscribe();
    }

    if (this.editDueDateSubscriber$) {
      this.editDueDateSubscriber$.unsubscribe();
    }

    if (this.editApplicationSubscriber$) {
      this.editApplicationSubscriber$.unsubscribe();
    }

    if (this.editTerminatingActionSubscriber$) {
      this.editTerminatingActionSubscriber$.unsubscribe();
    }

    if (this.editServiceBulletinFormSubscriber$) {
      this.editServiceBulletinFormSubscriber$.unsubscribe();
    }
  }

}
