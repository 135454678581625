import { NameAndIdModel } from './name-and-id.model';

export class EngineV2Model {
  constructor() {
    this.make = new NameAndIdModel;

    this.model = new NameAndIdModel;

    this.smoh = null;
  }

  position: string;
  serialNumber: string;
  smoh?: number;
  logbookState: string;
  initialHours: number;
  lastOverhaulHours: number;
  installedAirframeTime: number;
  make: NameAndIdModel;
  model: NameAndIdModel;
}
