import { ValidatorFn, AbstractControl } from '@angular/forms';

export function MustBeSameAsControValidator (otherControl: AbstractControl, triggerSelf: boolean = true): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let forbidden: boolean = null;

    forbidden = control.value !== otherControl.value? true : null; 
    
    if(forbidden){
      otherControl.setErrors({'mustBeSame': {value: otherControl.value}});
    }
    else{
      if (otherControl.hasError('mustBeSame')) {
        delete otherControl.errors['mustBeSame'];
        otherControl.updateValueAndValidity();
      }
    }

    if(triggerSelf){
      return forbidden ? {'mustBeSame': {value: control.value}} : null;
    }
    else{
      return null;
    }
  };
}