
  <div class="card mb-3 bg-light border-danger rounded">
    <div class="card-body">

      <app-preloader-type-a *ngIf="isLoading"></app-preloader-type-a>

      <div *ngIf="!isLoading">
        <h4>Edit Flight Log</h4>
        <form [formGroup]="editFlightLogForm" (ngSubmit)="saveEditFlightLog()">
          <div>
            <div class="row">
              <div class="col-sm-12 col-lg-4 col-md-12">
                <div class="form-group">
                  <small class="form-text text-muted required">Log Date</small>
                  <div class="input-group">
                    <input class="form-control" formControlName="editFlightDate"  placeholder="mm/dd/yyyy" name="editFlightDate" [maxDate]="serverTodayDateNgbDateStruct" ngbDatepicker #editFlightDate="ngbDatepicker">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="editFlightDate.toggle()" type="button">
                        <i class="la la-calendar-plus-o"></i>
                      </button>
                    </div>
                  </div>
                  <small class="text-danger d-block" *ngIf="editFlightLogForm.get('editFlightDate').invalid && (editFlightLogForm.get('editFlightDate').touched || editFlightLogForm.get('editFlightDate').dirty)">Enter Log Date(today as default)</small>
                </div>
              </div>

              <div class="col-sm-12 col-lg-4 col-md-6">
                <div class="form-group">
                  <small class="form-text text-muted required">Time Out (hrs)</small>
                  <div class="input-group">
                    <input class="form-control" formControlName="timeOutHours"  placeholder="Time Out Hours" name="timeOutHours">
                  </div>
                  <small class="text-danger d-block" *ngIf="editFlightLogForm.get('timeOutHours').invalid && (editFlightLogForm.get('timeOutHours').touched || editFlightLogForm.get('timeOutHours').dirty)">Enter valid Time Out Hours</small>
                  <ng-container *ngIf="!editFlightLogForm.hasError('required', 'timeOutHours') && (editFlightLogForm.get('timeOutHours').touched || editFlightLogForm.get('timeOutHours').dirty)">
                    <small class="text-danger" *ngIf="editFlightLogForm.hasError('numberToTenths', 'timeOutHours')">Enter Time In to the tenths <b>e.g 22.5, 10</b></small>
                  </ng-container>
                </div>
              </div>

              <div class="col-sm-12 col-lg-4 col-md-6">
                <div class="form-group">
                  <small class="form-text text-muted required">Time In (hrs)</small>
                  <div class="input-group">
                    <input class="form-control" formControlName="timeInHours"  placeholder="Time In Hours" name="timeInHours">
                  </div>
                  <small class="text-danger d-block" *ngIf="editFlightLogForm.hasError('required', 'timeInHours') && (editFlightLogForm.get('timeInHours').touched || editFlightLogForm.get('timeInHours').dirty)">Enter valid Time In Hours</small>
                  <ng-container *ngIf="!editFlightLogForm.hasError('required', 'timeInHours') && (editFlightLogForm.get('timeInHours').touched || editFlightLogForm.get('timeInHours').dirty)">
                    <small class="text-danger" *ngIf="editFlightLogForm.hasError('numberToTenths', 'timeInHours')">Enter Time In to the tenths <b>e.g 22.5, 10</b></small>
                    <small class="text-danger" *ngIf="editFlightLogForm.hasError('timeInGtTimeOut', 'timeInHours')">Time In must be greater than Time Out</small>
                  </ng-container>
                </div>
              </div>

              <!---Fuel Log -->
              <div class="col-sm-12 col-lg-12 col-md-12 py-0 mt-3 mb-0">
                <h6>Fuel Log</h6>
              </div>

              <div class="col-sm-12 col-lg-6 col-md-6">
                <div class="form-group">
                  <small class="form-text text-muted">Pre Flight Fuel (Gallons)</small>
                  <div class="input-group">
                    <input class="form-control" type="text" formControlName="preflight_fuel_gallons"  placeholder="Gallons: Pre Flight Fuel" name="preflight_fuel_gallons">
                  </div>
                  <small class="text-danger" *ngIf="editFlightLogForm.get('preflight_fuel_gallons').invalid && (editFlightLogForm.get('preflight_fuel_gallons').touched || editFlightLogForm.get('preflight_fuel_gallons').dirty)">Enter valid Gallons value <i>e.g. 22.3</i></small>
                </div>
              </div>

              <div class="col-sm-12 col-lg-6 col-md-6">
                <div class="form-group">
                  <small class="form-text text-muted">Post Flight Fuel (Gallons)</small>
                  <div class="input-group">
                    <input class="form-control" type="text" formControlName="postflight_fuel_gallons"  placeholder="Gallons: Post Flight Fuel" name="postflight_fuel_gallons">
                  </div>
                  <small class="text-danger" *ngIf="editFlightLogForm.get('postflight_fuel_gallons').invalid && (editFlightLogForm.get('postflight_fuel_gallons').touched || editFlightLogForm.get('postflight_fuel_gallons').dirty)">Enter valid Gallons value <i>e.g. 22.3</i></small>
                </div>
              </div>


              <!---Oil Log -->
              <div class="col-sm-12 col-lg-12 col-md-12 py-0 mt-3 mb-0">
                <h6>Oil Log</h6>
              </div>

              <div class="col-sm-12 col-lg-6 col-md-6">
                <div class="form-group">
                  <small class="form-text text-muted">Pre Flight Oil (Quarts)</small>
                  <div class="input-group">
                    <input class="form-control" type="text" formControlName="preflight_oil_quarts"  placeholder="Quarts: Pre Flight Oil" name="preflight_oil_quarts">
                  </div>
                  <small class="text-danger" *ngIf="editFlightLogForm.get('preflight_oil_quarts').invalid && (editFlightLogForm.get('preflight_oil_quarts').touched || editFlightLogForm.get('preflight_oil_quarts').dirty)">Enter valid Quarts value <i>e.g. 22.3</i></small>
                </div>
              </div>

              <div class="col-sm-12 col-lg-6 col-md-6">
                <div class="form-group">
                  <small class="form-text text-muted">Post Flight Oil (Quarts)</small>
                  <div class="input-group">
                    <input class="form-control" type="text" formControlName="postflight_oil_quarts"  placeholder="Quarts: Post Flight Oil" name="postflight_oil_quarts">
                  </div>
                  <small class="text-danger" *ngIf="editFlightLogForm.get('postflight_oil_quarts').invalid && (editFlightLogForm.get('postflight_oil_quarts').touched || editFlightLogForm.get('postflight_oil_quarts').dirty)">Enter valid Quarts value <i>e.g. 22.3</i></small>
                </div>
              </div>


              <ng-container formArrayName="squawks">
                <!---Squawks Log -->
                <div class="col-sm-12 col-lg-12 col-md-12 py-0 mt-3 mb-0">
                  <h6>Squawks</h6>
                </div>
                <div class="col-sm-12 col-lg-12 col-md-12" *ngFor="let squawkItem of squawksListFormGroups.controls; let i = index;">
                  <div class="clearfix">
                    <div class="border-top mt-3 py-2 clearfix">
                      <h6 class="float-left">Squawk Entry {{i+1}}</h6>
                      <button class="btn-outline-danger btn-sm rounded float-right" *ngIf="i !== 0" (click)="removeNewSquawkLogGroup(i)">
                        <i class="la la-trash"></i>
                      </button>
                    </div>
                  </div>

                  <div [formGroupName]="i" class="row w-100 form-group">
                    <!-- Squawks Form controls Here -->
                    <div class="col-sm-12 col-md-6 col-lg-6">
                      <small class="form-text text-muted required">Squawk Type</small>
                      <div class="input-group">
                        <select class="form-control" formControlName="squawk_type" name="squawk_type" id="squawk_type">
                          <option value="">Select</option>
                          <option *ngFor="let type of squawkTypesList" value="{{type.id}}">{{type.squawkName}}</option>
                        </select>
                      </div>
                      <small class="d-block text-danger" *ngIf="getOneSquawkFormGroup(i).controls['squawk_type'].invalid">
                          <span *ngIf="getOneSquawkFormGroup(i).controls['squawkEntry'].value">
                            Please select the squawk type
                          </span>
                          <span *ngIf="!getOneSquawkFormGroup(i).controls['squawkEntry'].value">
                            Type in a squawk description for selected type
                          </span>
                      </small>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                      <small class="form-text text-muted required">Squawk Description</small>
                      <div class="input-group">
                        <textarea class="form-control" formControlName="squawkEntry" placeholder="Squawk Description" name="squawkEntry" id="squawkEntry" class="w-100" rows="3"></textarea>
                      </div>
                      <small class="d-block text-danger" *ngIf="getOneSquawkFormGroup(i).controls['squawkEntry'].invalid">
                        <span *ngIf="getOneSquawkFormGroup(i).controls['squawk_type'].value">
                          Please type in squawk description
                        </span>
                        <span *ngIf="!getOneSquawkFormGroup(i).controls['squawk_type'].value">
                          Select a squawk type for this description
                        </span>
                      </small>
                    </div>
                  </div>
                </div>
              </ng-container >

              <div class="col-sm-12 col-lg-4 col-md-6">
                <div class="form-group">
                  <a href="javascript: void(0)" (click)="addNewSquawkLogGroup()">
                    <i class="la la-plus"></i>
                    Add Another Squawk
                  </a>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-lg-12 col-md-12">
                <div class="form-group">
                  <small class="form-text text-muted">Note:</small>
                  <div class="input-group">
                    <textarea class="w-100" formControlName="notes"  placeholder="Enter extra note here" name="notes" id="notes" rows="4"></textarea>
                  </div>
                </div>
              </div>




            </div>
          </div>
          <hr class="mt-1">
          <div class="text-right mt-2">
            <button class="btn btn-outline-danger mr-3" (click)="cancelEditFlightLog()">Cancel</button>
            <button type="submit" class="btn btn-outline-primary">Save Flight Log</button>
          </div>
        </form>
      </div>

    </div>
  </div>
