<app-preloader-type-a *ngIf="isWorking"></app-preloader-type-a>

<ng-container *ngIf="!isWorking">

  <div class="m-3 clearfix w-100">
    <button
      *ngIf="!isInEditMode"
      (click)="loadEditForm(userProfile)"
      class="btn btn-sm btn-primary mr-1"
    >
      <i class="la la-edit"></i> Edit Info & Password
    </button>

    <button
      *ngIf="isInEditMode"
      (click)="cancelEditForm()"
      class="btn btn-sm btn-danger mr-1"
    >
      <i class="la la-edit"></i> Cancel Edit
    </button>
  
    <button
      *ngIf="currentUser.isAdmin"
      (click)="requestPasswordReset()" class="btn btn-outline-success"
      class="btn btn-sm btn-orange mr-1"
    >
      <i class="la la-email"></i> Send Password Link
    </button>
  </div>


  <!---display Info -->
  <section class="p-3 admin-view-user" *ngIf="!isInEditMode">
    <div class="form_header h5 uppercase">
      Account Profile
    </div>

    <div class="card shadow border-0">
      <div class="card-body">
        <div class="info_view">
          <div>
            <div class="py-3 clearfix " *ngIf="userProfile.isAdmin">
              <span class="badge badge-success h5">
                <i class="la la-user-secret"></i>
                CrewChief Admin
              </span>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <small class="form-text text-muted">Customer's first name.</small>
                  <div>{{userProfile?.firstName}}</div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <small class="form-text text-muted">Customer's last name.</small>
                  <div>{{userProfile?.lastName}}</div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <small class="form-text text-muted">Customer's mobile phone number.</small>
                  <div *ngIf="userProfile && userProfile.mobilePhone">
                    {{userProfile.mobilePhone? formatPhoneNumber(userProfile.mobilePhone) : '----'}}
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <small class="form-text text-muted">Customer's email address.</small>
                  <div>{{userProfile?.emailAddress}} </div>
                </div>
              </div>
            </div>



            <div class="w-50" *ngIf="currentUser.isAdmin || (currentUser.emailAddress == userProfile.emailAddress)">
              <div class="form-group">
                <small class="form-text text-muted">Pilot Licence #.</small>
                <div>{{userProfile?.pilotLicenseNo}} </div>
              </div>
            </div>

          </div>
          <div class="text-center mt-2">
            <button class="btn btn-outline-dark w-100" (click)="loadEditForm(userProfile)">
              <i class="la la-pencil-square"></i> Update Profile and Password
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--- Edit info -->
  <section class="p-3 admin-edit-user" *ngIf="isInEditMode">
    <div class="form_header h5 uppercase">
      Edit Account Profile
    </div>

    <div class="card shadow border-0">
      <div class="card-body">
        <div class="info_edit">
          <form [formGroup]="editUserForm" (ngSubmit)="doUpdateUser()">
            <div>
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small id="firstname_help" class="form-text text-muted required">Enter customer's first name.</small>
                    <input type="text" formControlName="firstName" class="form-control" id="firstname" aria-describedby="firstname_help" placeholder="First Name">
                    <small class="form-text text-danger" *ngIf="editUserForm.get('firstName').invalid && (editUserForm.get('firstName').dirty || editUserForm.get('firstName').touched)">
                      Enter valid first name.
                    </small>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small id="lastname_help" class="form-text text-muted required">Enter customer's last name.</small>
                    <input type="text" formControlName="lastName" class="form-control" id="lastname" aria-describedby="lastname_help" placeholder="Last Name">
                    <small class="form-text text-danger" *ngIf="editUserForm.get('lastName').invalid && (editUserForm.get('lastName').dirty || editUserForm.get('lastName').touched)">
                      Enter valid last name.
                    </small>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small id="mobilephone_help" class="form-text text-muted">Enter customer's mobile phone number.</small>
                    <input type="text" formControlName="mobilePhone" class="form-control" id="mobile_phone" aria-describedby="mobilephone_help" placeholder="Mobile Phone">
                    <small class="form-text text-danger" *ngIf="editUserForm.get('mobilePhone').invalid && (editUserForm.get('mobilePhone').dirty || editUserForm.get('mobilePhone').touched)">
                      Enter a valid phone number with country code<b> e.g +1 201 XXX XXXX</b>.
                    </small>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small id="email_help" class="form-text text-muted required">Enter customer's email address.</small>
                    <input type="text" formControlName="emailAddress" class="form-control" id="emailAddress" aria-describedby="email_help" placeholder="Email Address">
                    <small class="form-text text-danger" *ngIf="editUserForm.get('emailAddress').invalid && (editUserForm.get('emailAddress').dirty || editUserForm.get('emailAddress').touched)">
                      Enter valid email address.
                    </small>
                  </div>
                </div>
              </div>

              <div class="w-50"  *ngIf="currentUser.isAdmin || (currentUser.emailAddress == userProfile.emailAddress)">
                <div class="form-group">
                  <small id="lastname_help" class="form-text text-muted">Enter Pilot Licence #.</small>
                  <input type="text" formControlName="pilotLicenseNo" class="form-control" id="pilotLicenseNo" aria-describedby="lastname_help" placeholder="Pilot Licence #">
                  <small class="form-text text-danger" *ngIf="editUserForm.get('pilotLicenseNo').invalid && (editUserForm.get('pilotLicenseNo').dirty || editUserForm.get('pilotLicenseNo').touched)">
                    Enter Licence number (between 4 to 8 Characters).
                  </small>
                </div>
              </div>
            </div>

            <!--SET USER AS CREW CHIEF ADMIN-->
            <div class="my-1" *ngIf="currentUser.isAdmin">
              <small *ngIf="currentUser.id === userProfile.id">
                <i>Contact another administrator to update your <b>admin status</b> if you need to.</i>
              </small>

              <div class="mb-1"  *ngIf="currentUser.id !== userProfile.id">
                <div class="form-group">
                  <div class="form-check py-2">
                    <input class="form-check-input" formControlName="isCrewChiefAdmin"  type="checkbox" id="isadmin">
                    <label class="form-check-label" for="isadmin">
                      Check to set user as CrewChief Admin?
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <div class="form-check py-2">
                    <input class="form-check-input" formControlName="doPasswordUpdate"  type="checkbox" id="doPasswordUpdate">
                    <label class="form-check-label" for="doPasswordUpdate">
                      Update password?
                    </label>
                  </div>
                  <!-- <small class="form-text text-danger" *ngIf="editUserForm.hasError('hasDependantIfControlIs_true', 'doPasswordUpdate')">
                    You need to enter the new password.
                  </small> -->
                </div>
              </div>
            </div>

            <div class="row" *ngIf="editUserForm.get('doPasswordUpdate').value">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small class="form-text text-muted required">Password (min. of 6 characters and numbers).</small>
                    <input type="password" formControlName="password1" class="form-control" id="password1" placeholder="Password" autocomplete="new-password">
                    <div *ngIf="editUserForm.get('password1').dirty || editUserForm.get('password1').touched">
                      <small class="form-text text-danger" *ngIf="editUserForm.hasError('dependsOnControlIfControlIs', 'password1')">
                        Password is required.
                      </small>
                      <div *ngIf="!editUserForm.hasError('required', 'password1')">
                        <small class="form-text text-danger" *ngIf="editUserForm.hasError('pattern', 'password1')">
                          Minimum of 6 characters; Must have a number, an uppercase letter, and a lowercase letter.
                        </small>
                        <small class="form-text text-danger" *ngIf="editUserForm.hasError('mustBeSame', 'password1') && !(editUserForm.hasError('pattern', 'password1'))">
                          Password and Confirm password must be the same.
                        </small>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <small id="password2" class="form-text text-muted required">Confirm Password.</small>
                    <input type="password" formControlName="password2" class="form-control" id="password2" placeholder="Confirm Password" autocomplete="new-password">
                    <small class="form-text text-danger" *ngIf="editUserForm.hasError('required', 'password2') && (editUserForm.get('password2').dirty || editUserForm.get('password2').touched)">
                      Confirm Password Required
                    </small>
                    <small class="form-text text-danger" *ngIf="editUserForm.hasError('mustBeSame', 'password2') && !(editUserForm.hasError('required', 'password2') )">
                      <b>Confirm Password</b> must be same as <b>Password</b>.
                    </small>
                  </div>
                </div>
            </div>

            <div class="text-right mt-2">
                <a class="btn btn-outline-danger mr-3" href="javascript:void(0);" (click)="cancelEditForm()">Cancel</a>
                <button class="btn btn-outline-success">Save Profile</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

  <app-pilot-dates [userProfile]="userProfile"></app-pilot-dates>

</ng-container>
