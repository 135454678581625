import { CustomHttpErrorHandler } from './custom-http-error-handler';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BulkImportsService {

  constructor(
    private httpClient: HttpClient
  ) { }


  bulkCsvServiceBulletinImport(bulletinsCsv){
    return this.httpClient.post(environment.apiBaseUrl + '/servicebulletins/bulkCsvServiceBulletinImport', bulletinsCsv)
  }


  bulkCsvServiceBulletinImportUsingUrl(csvUrl: string){
    return this.httpClient.get(environment.apiBaseUrl + '/servicebulletins/bulkCsvServiceBulletinImportUsingUrl?url=' + csvUrl)
  }
}
