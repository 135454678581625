import { ManufacturerModel } from './manufacturer-model';
import { BaseAircraftEnginePropellerApplianceModel } from './base-aircraft-engine-propeller-appliance-model';
import { AircraftMakeModel } from './aircraft-make-model';

export class PropellerModel extends BaseAircraftEnginePropellerApplianceModel{
  constructor(){
    super();
    this.id = null;
    this.propellerMake = new AircraftMakeModel;
    this.propellerManufacturer = new ManufacturerModel;
  }
  
  propellerMake: AircraftMakeModel;
  propellerManufacturer?: ManufacturerModel;
}