import { UtilitiesService } from './../services/utilities.service';
import { SubscriptionLike } from 'rxjs';
import { Directive, HostListener, OnInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appSideMenuAutoCloseLinks]'
})
export class SideMenuAutoCloseLinksDirective implements OnInit, OnDestroy {

  constructor(
    private utilitiesService: UtilitiesService
  ) { }

  isSideBarCollapsed: boolean;
  private isSideBarCollapsed$: SubscriptionLike;

  windowWidth: number;

  ngOnInit(){
    this.isSideBarCollapsed$ = this.utilitiesService.isSideBarCollapsed$.subscribe(
      (val)=>{
        this.isSideBarCollapsed = val;
      }
    )
  }

  @HostListener('click', ['$event'])
  onclick(event: Event){
    this.windowWidth = window.innerWidth;
    if(this.windowWidth < 780 && (this.isSideBarCollapsed == false)){
      console.log("Auto Close Side Menu ")
      this.forceCloseSidebar();
    }
  }


  forceCloseSidebar(){
    if(this.isSideBarCollapsed == false){
      this.utilitiesService.toggleSideBar();
    }
  }

  ngOnDestroy(){
    if(this.isSideBarCollapsed$) this.isSideBarCollapsed$.unsubscribe()
  }

}
