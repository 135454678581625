import { AircraftService } from './../../../services/aircraft.service';
import { UtilitiesService } from './../../../services/utilities.service';
import { PropellerModel } from './../../../models/propeller-model';
import { ApplianceModel } from './../../../models/appliance-model';
import { AircraftModel } from './../../../models/aircraft-model';
import { AllModelsService } from './../../../services/all-models.service';
import { adSbTypeOptionsModel } from './../../../models/ad-sb-type-options-model';
import { NgbDateCustomAndUSAParserFormatter } from './../../../services/ngb-date-custom-and-usa-parser-formatter';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AirworthinessDirectiveService } from './../../../services/airworthiness-directive.service';
import { Observable, of, SubscriptionLike } from 'rxjs';
import { AirworthinessDirectiveModel } from './../../../models/airworthiness-directive-model';
import { Component, OnInit } from '@angular/core';
import {
  ModalDismissReasons,
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDateParserFormatter,
  NgbDatepickerConfig,
  NgbModal,
  NgbTypeaheadSelectItemEvent
} from '@ng-bootstrap/ng-bootstrap';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { EngineModel } from '../../../models/engine-model';
import { AircraftAirDirectiveModel } from '../../../models/aircraft-air-directive-model';

@Component({
  selector: 'app-manage-directives',
  templateUrl: './manage-directives.component.html',
  styleUrls: ['./manage-directives.component.scss'],
  providers: [
    NgbDatepickerConfig,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomAndUSAParserFormatter }
  ]
})
export class ManageDirectivesComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private ngbDatePickerConfig: NgbDatepickerConfig,
    private airworthinessDirectiveService: AirworthinessDirectiveService,
    private allModelsService: AllModelsService,
    private utilitiesService: UtilitiesService,
    private aircraftService: AircraftService,
    private modalService: NgbModal
  ) {
    ngbDatePickerConfig.minDate = { year: 1900, month: 1, day: 1 };
  }

  viewedPdfSrc: string;
  viewedPdfAdTitle: string;

  adSbTypeOptions: adSbTypeOptionsModel = new adSbTypeOptionsModel;

  isWorking: boolean = false;

  inCreateNewDirectiveMode: boolean = false;

  searchSubscriber$: SubscriptionLike;

  directivesLoading: boolean = false;

  newAirworthinessDirective: AirworthinessDirectiveModel = new AirworthinessDirectiveModel;
  newAirworthinessDirectiveForm: FormGroup;

  editAirworthinessDirective: AirworthinessDirectiveModel = new AirworthinessDirectiveModel;
  editAirworthinessDirectiveForm: FormGroup;

  allAirworthinessDirectives: Array<AirworthinessDirectiveModel> = [];
  page = 1;
  pageSize = 20;
  totalADs = 0;

  airworthinessDirectiveSearchForm: FormGroup;

  modelSearchFailed: boolean = false;
  modelSearching: boolean = false;

  adNewModels: Array<AircraftModel | EngineModel | PropellerModel | ApplianceModel> = [];
  adEditModels: Array<AircraftModel | EngineModel | PropellerModel | ApplianceModel> = [];

  ngOnInit() {
    this.newAirworthinessDirectiveForm = this.formBuilder.group({
      'directiveName': new FormControl('', [Validators.required]),
      'effectiveDate': new FormControl(null, [Validators.required]),
      'adSbType': new FormControl(null, [Validators.required]),
      'subject': new FormControl(null, [Validators.required]),
      'isRecurring': new FormControl(0),
      'modelFinder': new FormControl(null),
    });

    this.airworthinessDirectiveSearchForm = this.formBuilder.group({
      'queryString': new FormControl('')
    });

    this.searchSubscriber$ = this.airworthinessDirectiveSearchForm.controls['queryString'].valueChanges.pipe(
      startWith(''),
      tap((x) => this.directivesLoading = true)
    ).subscribe(
      (query) => {
        this.resetPage();
        this.findAirworthinessDirectiveByName(query);
      }
    );
  }

  changePageSize($event: any){
    this.pageSize = +($event.target.value) > 1? +($event.target.value) : this.pageSize;
    this.findAirworthinessDirectiveByName('');
  }

  get queryString() {
    return this.airworthinessDirectiveSearchForm.controls['queryString'];
  }

  findAirworthinessDirectiveByName(query) {
    this.unLoadDirectiveForEdit();
    this.directivesLoading = true;
    this.airworthinessDirectiveService.findAirworthinessDirectiveByName(query, this.page, this.pageSize).subscribe(
      (response: any) => {
        if (response.status.code == 1000) {
          this.allAirworthinessDirectives = response.responseData.rows;
          this.totalADs = response.responseData.count;
          this.directivesLoading = false;
        }
      }
    );
  }

  //// Type heads search functions for model
  modelTypeHeadSearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.modelSearching = true),
      switchMap(
        (term) => this.getModel(
          term,
          this.inCreateNewDirectiveMode ?
            this.newAirworthinessDirectiveForm.get('adSbType').value :
              this.editAirworthinessDirectiveForm.get('adSbType').value)
      ),
      tap(() => this.modelSearching = false)
    )

  getModel(term, type: string) {
    if (!(type == 'airframe' || type == 'engine' || type == 'propeller' || type == 'appliance')) {
      return of([]);
    }

    return this.allModelsService.findModelsByType(term, type).pipe(
      tap(() => this.modelSearchFailed = false),
      catchError(() => {
        this.modelSearchFailed = true;
        return of([]);
      }),
      map((res: any) => {
        let arr: Array<any> = [];

        // send new term as new Model
        let termModel = null;
        if (type == 'airframe') {
          termModel = new AircraftModel;
        }
        if (type == 'engine') {
          termModel = new EngineModel;
        }
        if (type == 'propeller') {
          termModel = new PropellerModel;
        }
        if (type == 'appliance') {
          termModel = new ApplianceModel;
        }

        term = term.trim();
        if (term) {
          termModel.id = null;
          termModel.modelName = term;
          arr.push(termModel);
        }
        ///////

        res.responseData.rows.forEach(element => {
          // set id of newly added item if it's in result
          if (termModel && (element.modelName == termModel.modelName)) {
            arr[0].id = element.id;
            termModel = null;
          }

          arr.push(element);
        });

        return arr;
      }),
    );
  }

  // format typeheads data received,  before display
  typeHeadModelFormatter = (modelObj: AircraftModel | EngineModel | PropellerModel | ApplianceModel) => {
    const modelObjName = modelObj.modelName;
    // let modelObjManufacturer = modelObj.aircraftMake? ': ' + modelObj.aircraftMake.makeName : '';

    return modelObjName;
  }


  getHighlightDisplayName = (r: AircraftModel | EngineModel | PropellerModel | ApplianceModel) => {
    return this.aircraftService.getHighlightDisplayName(r);
  }

  getModelManufacturerName(modelObj: AircraftModel | EngineModel | PropellerModel | ApplianceModel) {
    return this.aircraftService.getModelManufacturerName(modelObj);
  }

  getDirectiveModelManufacturers(directive: AirworthinessDirectiveModel) {
    return this.aircraftService.getSbOrAdModelManufacturers(directive);
  }

  /**
   * @description get directive make name by make id and return
   * @param {any} directive
   * @returns {string}
   */
   getDirectiveModelMakes(directive: AirworthinessDirectiveModel) {
    return this.aircraftService.getSbOrAdModelMakes(directive)
  }

  // On Select typehead(Manufacturer) options...
  onTypeHeadSelected(ev: NgbTypeaheadSelectItemEvent) {
    this.addModelToNewList(ev.item);

    const modelFinderValue = this.newAirworthinessDirectiveForm.get('modelFinder').value;

    if (modelFinderValue) {
      this.newAirworthinessDirectiveForm.get('modelFinder').setValue(new AircraftModel);
    }
  }

  addModelToNewList(model) {
    if (!(this.adNewModels.some(e => e.modelName === model.modelName))) {
      this.adNewModels.push(model);
    }
  }

  removeModelFromNewList(modelIndex: number) {
    this.adNewModels.splice(modelIndex, 1);
  }


  addModelToEditList(model) {
    if (!(this.adEditModels.some(e => e.modelName === model.modelName))) {
      this.adEditModels.push(model);
    }
  }

  removeModelFromEditList(modelIndex: number) {
    this.adEditModels.splice(modelIndex, 1);
  }

  onAdTypeChange(ev, mode: 'new' | 'edit') {
    const listLength: boolean = mode == 'new' ? (this.adNewModels.length > 0) : this.adEditModels.length > 0;

    if (listLength) {
      Swal.fire({
        title: 'Clear all selected models?',
        text: 'Changing the type will clear all selected models.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes. Change Type!',
        cancelButtonText: 'No, cancel action.'
      }).then((descision) => {
        if (descision.value) {
          if (mode == 'new') {
            this.adNewModels = [];
          }
          if (mode == 'edit') {
            this.adEditModels = [];
          }
        } else if (descision.dismiss === Swal.DismissReason.cancel) {
          // todo: KEEP ADSBTYPE UNCHANGED
        }
      });
    }
  }


  // On Select typehead(Manufacturer) options...
  onTypeHeadEditSelected(ev: NgbTypeaheadSelectItemEvent) {
    this.addModelToEditList(ev.item);

    this.editAirworthinessDirectiveForm.controls['modelFinder'].setValue(null);
  }

  toggleInCreateNewDirectiveMode() {
    this.inCreateNewDirectiveMode = !this.inCreateNewDirectiveMode;
    if (!this.inCreateNewDirectiveMode) {
      this.newAirworthinessDirectiveForm.reset();
    }
    this.adNewModels = [];
  }

  doCreateDirective() {

    const validateForm = this.utilitiesService.generalFormValidate(
      this.newAirworthinessDirectiveForm
    );
    if (!validateForm) {
      return;
    }

    this.isWorking = true;

    this.newAirworthinessDirective.directiveName = this.newAirworthinessDirectiveForm.controls['directiveName'].value;
    this.newAirworthinessDirective.subject = this.newAirworthinessDirectiveForm.controls['subject'].value;
    this.newAirworthinessDirective.effectiveDate = this.newAirworthinessDirectiveForm.controls['effectiveDate'].value;

    this.newAirworthinessDirective.adSbType = this.newAirworthinessDirectiveForm.controls['adSbType'].value;
    this.newAirworthinessDirective.isRecurring = this.newAirworthinessDirectiveForm.controls['isRecurring'].value;

    if (this.newAirworthinessDirective.adSbType == 'airframe') {
      this.newAirworthinessDirective.aircraftModels = JSON.parse(JSON.stringify(this.adNewModels)) as Array<AircraftModel>;
    }
    if (this.newAirworthinessDirective.adSbType == 'engine') {
      this.newAirworthinessDirective.engineModels = JSON.parse(JSON.stringify(this.adNewModels)) as Array<EngineModel>;
    }
    if (this.newAirworthinessDirective.adSbType == 'propeller') {
      this.newAirworthinessDirective.propellerModels = JSON.parse(JSON.stringify(this.adNewModels)) as Array<PropellerModel>;
    }
    if (this.newAirworthinessDirective.adSbType == 'appliance') {
      this.newAirworthinessDirective.applianceModels = JSON.parse(JSON.stringify(this.adNewModels)) as Array<ApplianceModel>;
    }

    this.airworthinessDirectiveService.createAirworthinessDirective(this.newAirworthinessDirective).subscribe(
      (response: any) => {
        this.isWorking = false;

        const newDirectiveName = this.newAirworthinessDirective.directiveName;

        if (response.status.code == 1000) {
          this.newAirworthinessDirectiveForm.reset(); // reset create form
          this.newAirworthinessDirective = new AirworthinessDirectiveModel;
          this.inCreateNewDirectiveMode = false; // exit create directive mode
          this.resetPage();
          this.adNewModels = [];
          // this.findAirworthinessDirectiveByName(''); // re-fetch all Airworthiness Directives
          // set search filter field to new name to show in list
          this.airworthinessDirectiveSearchForm.controls['queryString'].setValue(newDirectiveName);
          Swal.fire('Success!', 'New Airworthiness Directive created!', 'success');
        } else {
          Swal.fire(
            'Oops!', 'Can\'t create Airworthiness Directive!', 'warning'
          );
        }
      },
      (error: any) => {
        this.isWorking = false;

        if (error.status.message) {
          Swal.fire(
            error.status.message,
            error.responseData.join(' '),
            'error'
          );
        } else {
          Swal.fire('Error!', 'Failed to create Airworthiness Directive.', 'error');
        }
      }
    );
  }

  doUpdateDirective(directive: AirworthinessDirectiveModel) {
    const validateForm = this.utilitiesService.generalFormValidate(
      this.editAirworthinessDirectiveForm
    );

    if (!validateForm) {
      return;
    }

    this.isWorking = true;

    this.editAirworthinessDirective.directiveName = this.editAirworthinessDirectiveForm.controls['directiveName'].value;
    this.editAirworthinessDirective.subject = this.editAirworthinessDirectiveForm.controls['subject'].value;
    this.editAirworthinessDirective.effectiveDate = this.editAirworthinessDirectiveForm.controls['effectiveDate'].value;

    this.editAirworthinessDirective.adSbType = this.editAirworthinessDirectiveForm.controls['adSbType'].value;
    this.editAirworthinessDirective.isRecurring = this.editAirworthinessDirectiveForm.controls['isRecurring'].value;

    if (this.editAirworthinessDirective.adSbType == 'airframe') {
      this.editAirworthinessDirective.aircraftModels = JSON.parse(JSON.stringify(this.adEditModels)) as Array<AircraftModel>;
    }
    if (this.editAirworthinessDirective.adSbType == 'engine') {
      this.editAirworthinessDirective.engineModels = JSON.parse(JSON.stringify(this.adEditModels)) as Array<EngineModel>;
    }
    if (this.editAirworthinessDirective.adSbType == 'propeller') {
      this.editAirworthinessDirective.propellerModels = JSON.parse(JSON.stringify(this.adEditModels)) as Array<PropellerModel>;
    }
    if (this.editAirworthinessDirective.adSbType == 'appliance') {
      this.editAirworthinessDirective.applianceModels = JSON.parse(JSON.stringify(this.adEditModels)) as Array<ApplianceModel>;
    }

    this.airworthinessDirectiveService.updateAirworthinessDirective(this.editAirworthinessDirective).subscribe(
      (response: any) => {
        this.isWorking = false;

        if (response.status.code == 1000) {
          // this.findAirworthinessDirectiveByName(''); // re-fetch all Airworthiness Directives
          // set search filter field to ad name. just incase
          // this.airworthinessDirectiveSearchForm.controls['queryString'].setValue(this.editAirworthinessDirective.directiveName);
          // this.resetPage();

          this.editAirworthinessDirectiveForm.reset(); // reset create form
          this.editAirworthinessDirective = new AirworthinessDirectiveModel;
          Swal.fire('Great!', 'Airworthiness Directive updated!', 'success');
        } else {
          Swal.fire(
            'Oops!', 'Failed to update Airworthiness Directive!', 'warning'
          );
        }
      },
      (error: any) => {
        this.isWorking = false;
        // filter list to show current edited item
        this.airworthinessDirectiveSearchForm.get('queryString').setValue(this.editAirworthinessDirective.directiveName);

        if (error.status.message) {
          Swal.fire('Yikes!', error.status.message, 'error');
        } else {
          Swal.fire('Error!', 'Failed ot update Airworthiness Directive.', 'error');
        }
      }
    );
  }

  loadDirectiveForEdit(directive: AirworthinessDirectiveModel) {
    this.editAirworthinessDirective = directive;

    if (directive.adSbType == 'airframe') {
      this.adEditModels = JSON.parse(JSON.stringify(directive.aircraftModels));
    }
    if (directive.adSbType == 'engine') {
      this.adEditModels = JSON.parse(JSON.stringify(directive.engineModels));
    }
    if (directive.adSbType == 'propeller') {
      this.adEditModels = JSON.parse(JSON.stringify(directive.propellerModels));
    }
    if (directive.adSbType == 'appliance') {
      this.adEditModels = JSON.parse(JSON.stringify(directive.applianceModels));
    }

    this.editAirworthinessDirective.aircraftModels = [];
    this.editAirworthinessDirective.engineModels = [];
    this.editAirworthinessDirective.propellerModels = [];
    this.editAirworthinessDirective.applianceModels = [];

    this.editAirworthinessDirectiveForm = new FormGroup({});

    this.editAirworthinessDirectiveForm = this.formBuilder.group({
      'directiveName': new FormControl(
        this.editAirworthinessDirective.directiveName,
        [Validators.required]
      ),
      'subject': new FormControl(
        this.editAirworthinessDirective.subject,
        [Validators.required]
      ),
      'effectiveDate': new FormControl(
        this.editAirworthinessDirective.effectiveDate ? new Date(this.editAirworthinessDirective.effectiveDate) : null,
        [Validators.required]
      ),
      'adSbType': new FormControl(
        this.editAirworthinessDirective.adSbType ? this.editAirworthinessDirective.adSbType : null,
        [Validators.required]
      ),
      'modelFinder': new FormControl(null),
      'isRecurring': new FormControl(
        this.editAirworthinessDirective.isRecurring ? this.editAirworthinessDirective.isRecurring : null
      )
    });
  }

  unLoadDirectiveForEdit() {
    this.editAirworthinessDirective = new AirworthinessDirectiveModel;
    if (this.editAirworthinessDirectiveForm) {
      this.editAirworthinessDirectiveForm.reset();
    }
  }


  resetPage() {
    this.page = 1;
  }

  onPaginationChange($event) {
    this.page = $event;
    this.findAirworthinessDirectiveByName(this.airworthinessDirectiveSearchForm.controls['queryString'].value);
  }


  openModal(content, pdfUrl: string, title: string = 'View Directive') {
    this.viewedPdfSrc = pdfUrl;
    this.viewedPdfAdTitle = title;

    this.modalService.open(content, { size: 'lg' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  ngOnDestroy = () => {
    if (this.searchSubscriber$) {
      this.searchSubscriber$.unsubscribe();
    }
  }

  /**
   * @description deletes Airworthiness Directive
   * @param {AircraftAirDirectiveModel} directive
   */
  deleteAirworthinessDirective(directive: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: `Please confirm you want to delete directive ${directive['directiveName']}?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes. I know what I\'m doing!',
      cancelButtonText: 'No, take me back.'
    }).then((decision) => {

      console.log(decision)

      if (decision.value) {
        this.airworthinessDirectiveService.deleteGlobalAirworthinessDirective(directive['id'])
          .subscribe(response => {

            console.log(response)

            if (response['status']['code'] == 1000) {
              this.editAirworthinessDirective = new AirworthinessDirectiveModel();
              this.resetPage();
              this.findAirworthinessDirectiveByName('');
              Swal.fire(
                'Deleted!', 'Airworthiness Directive deleted.', 'success'
              );
            } else {
              Swal.fire(
                'Yikes!', 'Airworthiness Directive not deleted.', 'error'
              );
            }
          }, (error) => {
            if (error['status']['message']) {
              Swal.fire(
                error['status']['message'],
                error['responseData'].join(' '),
                'error'
              );
            } else {
              Swal.fire(
                'Oops!',
                'Failed to delete Airworthiness Directive.',
                'error'
              );
            }
          });
      } else if (decision.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });
  }

  getSixMonthsPrior(effectiveDate: string){
    return this.utilitiesService.sixMonthsPrior(effectiveDate)
  }
}
