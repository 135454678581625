<div class="infinite_loader"  *ngIf="showActivityPreloader">
  <div class="loaderBar"></div>
</div>


<small class="text-light d-block bg-{{envColor}} m-0 text-center px-3 py-1" *ngIf="!isProduction">
  {{envName}} ENV. @ {{envBuildDate | date :'yyyy-MMM-dd h:mm:ss a z'}}
</small>
<router-outlet></router-outlet>

<div class="preloader fullscreen-preloader" *ngIf="showMainPreloader">
  <div class="crewchief-preloader"><div></div><div></div><div></div><div></div></div>
</div>
