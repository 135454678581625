import { ValidatorFn, AbstractControl } from '@angular/forms';

export function DependsOnControlValidator (otherControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let forbidden: boolean = null;

    forbidden = !control.value && otherControl.value? true : null; 
    
    
    if(forbidden){
      otherControl.setErrors({'hasDependant': {value: otherControl.value}});
    }
    else{
      if (otherControl.hasError('hasDependant')) {
        delete otherControl.errors['hasDependant'];
        otherControl.updateValueAndValidity();
      }
    }
    
    return forbidden ? {'dependsOnControl': {value: control.value}} : null;
  };
}