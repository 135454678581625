import { FlightSquawkLogsModel } from './flight-squawk-logs-model';
import { FlightOilLogsModel } from './flight-oil-logs-model';
import { FlightFuelLogsModel } from './flight-fuel-logs-model';
import { UserAircraftModel } from './user-aircraft-model';

export class AircraftFlightLogModel{
  constructor(){
    this.id = null;
    this.aircraftId = null;
    this.flightDate = null;
    this.timeOutHours = null;
    this.timeInHours = null;
    this.flight_fuel_log = new FlightFuelLogsModel;
    this.flight_oil_log = new FlightOilLogsModel;
    this.flight_squawk_logs = [];
    this.notes = null
  }
  id?: number;
  aircraftId: number;  
  flightDate: string;
  timeOutHours: number;
  timeInHours: number;
  aircraft: UserAircraftModel;
  flight_fuel_log: FlightFuelLogsModel;
  flight_oil_log: FlightOilLogsModel;
  flight_squawk_logs: Array<FlightSquawkLogsModel>;
  notes: string;
}