export class VorCheckModel{
  constructor(){
    this.id = null;
    this.vorSource = null;
    this.aircraftId= null;
    this.editable= false;
  }

  id: number;
  vorSource: string;
  radial: number;
  radialDirection: string;
  distanceMiles: number;
  errorDegrees: number;
  signInitials: string;
  aircraftId: number;
  createdAt: string;
  editable: boolean;
}
